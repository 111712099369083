var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableFiltersRenderless',{attrs:{"entities":_vm.transactionHistoriesFormatted},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var allFilters = ref.allFilters;
            var filteredEntities = ref.filteredEntities;
            var onFilterAdded = ref.onFilterAdded;
            var removeFilter = ref.removeFilter;
            var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.transactionHistory.billingTransactionHistory'))+" ")]),_c('div',{staticClass:"display-flex"},[(_vm.isViasatDemo)?_c('ViasatMakePayment',{staticClass:"mr-3"}):_vm._e(),(_vm.isViasatDemo)?_c('ViasatPaymentMethod'):_vm._e(),_c('ResponseModalButton',{attrs:{"response":_vm.transactionHistoriesApiResponse,"title":_vm.$i18n.t('customerCare.transactionHistory.billingTransactionHistory')}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.columnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{staticClass:"billing-transaction-history",attrs:{"entities":filteredEntities,"selectedEntityId":_vm.selectedEntityId,"isDefaultHoverEnabled":true,"isCustomButtonPassed":true,"disableBoxShadow":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.columnsData,"tableKey":"account/billing-transaction-history"},on:{"selectEntity":function (e) { return [_vm.selectTransaction(e), _vm.showModal()]; }},scopedSlots:_vm._u([{key:"state",fn:function(ref){
            var entity = ref.entity;
return [_c('EntityStatusIndicator',{staticClass:"row-value",attrs:{"stateMap":_vm.CHARGE_STATES_TO_STATUS_NAME_MAP,"stateMapColor":_vm.CHARGE_STATES_INDICATOR_MAP,"status":entity.state}})]}},{key:"customRowButtons",fn:function(ref){
            var entity = ref.entity;
return [(_vm.areInvoiceButtonsVisible(entity))?[_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.generateInvoice'),"icon":_vm.ICON_TYPES.PLUS,"data-test-id":"billing-tx-history-generate-invoice-btn"},on:{"iconClick":function($event){return _vm.onGenerateInvoiceButtonClicked(entity)}}}),_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.download'),"icon":_vm.ICON_TYPES.DOWNLOAD,"data-test-id":"billing-tx-history-download-btn"},on:{"iconClick":function($event){return _vm.onDownloadButtonClicked(entity)}}})]:_vm._e(),(_vm.isRefundAllowed && entity.refundAction)?_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.isEditAllowed || !entity.canRefund)?_c('AppTooltip',{attrs:{"tooltipPosition":_vm.refundTooltipPosition}},[_c('template',{slot:"label"},[_c('div',{staticClass:"info-icon ml-3"})]),_c('template',{slot:"content"},[_vm._v(" "+_vm._s(_vm.refundNotAllowedLabel)+" ")])],2):_vm._e(),_c('IconButton',{attrs:{"label":_vm.$i18n.t('customerCare.paymentStatuses.refund'),"icon":_vm.ICON_TYPES.REFUND,"disabled":!_vm.isEditAllowed || !entity.canRefund,"data-test-id":"billing-tx-history-refund-btn"},on:{"iconClick":function($event){return _vm.refundPayment(entity)}}})],1):_vm._e()]}}],null,true)})],1)]}}])}),_c('TransactionHistoryV4Sidebar',{attrs:{"selectedTransactionHistory":_vm.selectedTransactionHistory,"showSidebar":_vm.showAditionalSidebar},on:{"showSidebarChange":function($event){_vm.showAditionalSidebar = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }