<template>
    <div data-id="fiber-order-status">
        <TableFiltersRenderless :entities="fiberOrders">
            <template #default="{ filteredEntities }">
                <div>
                    <div class="d-flex justify-content-between align-items-center position-relative">
                        <div class="table-title">
                            {{ $i18n.t('customerCare.userInformation.fiberStatus') }}
                        </div>
                    </div>

                    <AppTable
                        :entities="filteredEntities"
                        :isDefaultHoverEnabled="false"
                        :isPaginationEnabled="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="columnsData"
                        :isSearchEnabled="true"
                        :innerSearchQuery="searchQuery"
                        tableKey="customer-care-new/associated-roles"
                    >
                        <template #createTimestamp="{ entity }">
                            {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.createTimestamp) }}
                        </template>

                        <template #expireDate="{ entity }">
                            {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.expireDate) }}
                        </template>

                        <template #state="{ entity }">
                            <EntityStatusIndicator
                                v-if="!isUndefined(entity.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="entity.state"
                                :isInTable="true"
                            />

                            <i v-else>{{ $i18n.t('generic.empty') }}</i>
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
    </div>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import { isUndefined } from 'lodash';

import {
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';

// helpers
import tableColumnType from '@/common/filterTable';

export default {
    name: 'FiberStatus',
    components: {
        AppTable,
        TableFiltersRenderless,
        EntityStatusIndicator,
    },
    props: {
        fiberOrders: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            searchQuery: '',
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
        };
    },
    computed: {
        columnsData() {
            const cols = [
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.modemFriendlyId'),
                    key: 'friendlyOrderId',
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.expireDate'),
                    key: 'expireDate',
                    field: 'expireDate',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
            return cols;
        },
    },
    methods: {
        isUndefined,
    },
};
</script>

<style lang="scss" scoped>
.table-title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5;
    height: 2.5rem;
}
</style>
