













// Vue
import Vue from 'vue';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { USER_MANAGER_HIERARCHY, GROUP_UM_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { GroupResponse, Group } from '@/__new__/features/customerCareSuite/common/group';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

export default Vue.extend({
    name: 'GroupsTile',
    components: {
        AbstractTableTile,
    },
    props: {
        overrideUserData: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            rawResponse: {},
            entities: [],

            // proxy
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        umHierarchyId() {
            return this.$route.params.id || '';
        },
        userData() {
            if (this.overrideUserData) {
                return this.overrideUserData;
            }
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.umHierarchyId,
            });
        },
        groups() {
            if (this.userData && this.userData.groups) {
                return Object.keys(this.userData.groups);
            }
            return [];
        },
        formattedEntities() {
            if (this.entities.length) {
                return this.entities.map(entity => ({
                    ...entity,
                    nestedRows: entity.members,
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.groupTile.groupName'),
                    key: 'name',
                },
                {
                    name: this.$i18n.t('customerCareSuite.groupTile.groupId'),
                    key: 'id',
                },
                {
                    name: this.$i18n.t('customerCareSuite.groupTile.groupType'),
                    key: 'typeLabel',
                },
                {
                    name: this.$i18n.t('customerCareSuite.groupTile.memberUserId'),
                    key: 'userId',
                },
                {
                    name: this.$i18n.t('customerCareSuite.groupTile.memberMSISDN'),
                    key: 'msisdn',
                },
            ];
        },
    },
    watch: {
        userData(newVal) {
            if (newVal) {
                this.fetchTileData();
            }
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.entities = [];
                    if (this.groups && this.groups.length) {
                        const promises = [];
                        await this.groups.forEach((groupId: string) => {
                            promises.push(customerCareHTTP.getGroup(groupId, GROUP_UM_HIERARCHY, true));
                        });
                        const groupRespones = await Promise.all(promises);
                        groupRespones.forEach((groupResponse: GroupResponse) => {
                            this.entities.push(new Group(groupResponse));
                        });
                    }
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
