import _ from 'lodash';
import * as Sentry from '@sentry/vue';
import i18n from '@/i18n';
import { getTemplates } from '@/__new__/services/dno/pc/http/templates';
import { getEntities } from '@/__new__/services/dno/pc/entities';
import { ProductCatalogEntityTemplate } from '@/__new__/services/dno/pc/models/ProductCatalogEntityTemplate';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/features/pc/common/entitiesTypesToModel';
import { PROPERTY_FORMATTERS } from '@/components/partials/entityOverview/ListDropdownSection.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export async function fetchTemplate(entityType, templateId) {
    if (templateId) {
        try {
            const response = await getTemplates(entityType, [templateId]);
            const templates = response?.data?.data.map(x => new ProductCatalogEntityTemplate(x));
            const template = templates?.[0];
            if (!template) {
                return null;
            }

            return template;
        } catch (ex) {
            Sentry.captureException(ex);
        }
    }

    return null;
}

export function toDisplayValue(value) {
    if (value === null || value === undefined) {
        return `(${i18n.t('generic.notSpecified')})`;
    }

    if (value === '') {
        return `(${i18n.t('generic.empty')})`.toLowerCase();
    }

    return value;
}

export function getOverviewForGeneralProperties(entity, template) {
    const generalProperties = {
        name: i18n.t('generic.general'),
        isCollapsed: false,
        rows: [
            {
                value: toDisplayValue(entity.description),
                name: i18n.t('generic.description'),
            },
            {
                value: toDisplayValue(entity.id),
                name: i18n.t('generic.id'),
            },
            {
                value: toDisplayValue(entity.externalId),
                name: i18n.t('generic.externalId'),
            },
            {
                value: toDisplayValue(entity.templateId),
                name: i18n.t('productCatalog.templates.templateId'),
            },
            {
                value: toDisplayValue(template?.name),
                name: i18n.t('productCatalog.templates.templateName'),
            },
        ],
    };
    if (entity.entityType === ENTITY_TYPES.OFFER && isBundleOffer(entity)) {
        generalProperties.rows.push({
            name: i18n.t('productCatalog.templates.bundleType'),
            value: i18n.t('productCatalog.templates.bundleOffer'),
        });
    }
    return generalProperties;
}

function isBundleOffer(offer) {
    const hasChildOffer = offer.childEntities.some(child => {
        return child.type === ENTITY_TYPES.OFFER;
    });
    return hasChildOffer;
}

export function getOverviewForTemplateProperties(entityType, entity, template) {
    if (!template) {
        return [];
    }

    // Build up the required overview data structure from the template and the entity.
    //
    const propertyDefs = _.groupBy(template.properties, p => p.groupId);
    const groups = template.groups
        .map(group => ({
            name: group.name,
            isCollapsed: false,
            rows: propertyDefs[group.id]
                .sort(p => p.sortWeight)
                .map(p => ({
                    name: p.name,
                    value: toDisplayValue(entity.properties[p.id]),
                    formatter: PROPERTY_FORMATTERS.TEMPLATE_PROPERTY,
                    externalId: p.id,
                })),
        }))
        .sort(g => g.sortWeight);

    return groups;
}

async function getEntitiesByIds(entityType, ids) {
    const response = await getEntities(entityType, ids);
    const rawEntitiesById = response.data[`${entityType}_by_id`];

    const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
    if (!EntityTypeModel) {
        return {};
    }

    return { [entityType]: _.mapValues(rawEntitiesById, e => new EntityTypeModel({ ...e, entityType })) };
}

export async function getOverviewForRelationships(entity) {
    const entityIdsByType = _.chain(entity.childEntities)
        .groupBy(e => e.type)
        .mapValues(entities => entities.map(e => e.id))
        .value();

    const entitiesByTypeAndId = _.merge(
        ...(await Promise.all(
            Object.entries(entityIdsByType).map(([entityType, entitiesIds]) =>
                getEntitiesByIds(entityType, entitiesIds),
            ),
        )),
    );

    const entityNamesByType = _.mapValues(entityIdsByType, (ids, entityType) =>
        ids.map(id => entitiesByTypeAndId[entityType][id].name),
    );

    const properties = Object.entries(entityNamesByType).map(([entityType, names]) => ({
        value: toDisplayValue(names.join(', ')),
        name: i18n.tc(`productCatalog.entities.${entityType}`, 2),
    }));

    if (properties.length === 0) {
        return [];
    }

    return [
        {
            name: i18n.tc('productCatalog.childEntities', 2),
            isCollapsed: false,
            rows: properties,
        },
    ];
}

export async function defaultOverviewContentBuilder(entityType, entity) {
    const [template, relationshipProps] = await Promise.all([
        fetchTemplate(entityType, entity.templateId),
        getOverviewForRelationships(entity),
    ]);

    const generalProps = getOverviewForGeneralProperties(entity, template);
    const templateProps = getOverviewForTemplateProperties(entityType, entity, template);

    return [generalProps, ...relationshipProps, ...templateProps];
}

export default {};
