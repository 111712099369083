



























// Components
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import orchestrationEngineVisualizerHelper from '@/__new__/features/orchestrationengine/common/orchestrationEngineVisualizerHelper';
import importMonacoHelper from '@/common/importMonacoHelper';

const CANVAS_ID = 'planDefinitionVisualizerID';

// helper for error "Property 'monaco' does not exist on type 'Window & typeof globalThis'"
declare const window: any;

export default {
    name: 'PlanDefinition',

    components: {
        IconButton,
    },

    props: {
        planId: {
            required: true,
            type: String,
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
        planDetails: {
            type: Object,
            default: () => undefined,
        },
    },

    data() {
        return {
            canvas: null as any,
            planDefinitionData: [] as Record<string, any>,

            ICON_TYPES,
            CANVAS_ID,
        };
    },

    watch: {
        isVisible(value) {
            if (value) {
                this.renderEditor();
                this.renderDiagram();
            }
        },
        planDetails(value) {
            if (Object.keys(value)?.length) {
                this.planDefinitionData = value;
            }
        },
    },

    methods: {
        renderDiagram(): void {
            this.$nextTick(() => {
                if (this.planDefinitionData) {
                    this.canvas = orchestrationEngineVisualizerHelper.drawPlanDiagram(
                        CANVAS_ID,
                        this.canvas,
                        this.planDefinitionData.states,
                        this.planDefinitionData.start_at,
                    );
                }
            });
        },
        async renderEditor(): Promise<any> {
            await importMonacoHelper.importMonaco();

            if (!this.editor) {
                this.editor = window.monaco.editor.create(document.getElementById('planDefinition'), {
                    value: JSON.stringify(this.planDefinitionData, null, '  '),
                    language: 'json',
                    readOnly: true,
                    automaticLayout: true,
                });
            } else {
                this.editor.setValue(JSON.stringify(this.planDefinitionData, null, '  '));
            }
        },
        onZoomIn(): void {
            if (this.canvas) orchestrationEngineVisualizerHelper.canvasZoomIn(this.canvas);
        },
        onZoomOut(): void {
            if (this.canvas) orchestrationEngineVisualizerHelper.canvasZoomOut(this.canvas);
        },
    },
};
