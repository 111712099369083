import { every, flatMap, groupBy, isEmpty, map, uniq } from 'lodash';
import moment from 'moment';
import { Modules } from '@/store/store';
import Actions, { Getters, Mutations, State } from '@/store/mutation-types';
import { getTemplateHelpers } from '@/__new__/services/dno/campaigns/http/templateHelpers';
import { createComplexProp } from '@/__new__/features/pc/templates/common/templateHelper';

const state = {
    [State.TEMPLATE_HELPERS_TTL]: moment.utc().subtract(1, 'h'),
    [State.TEMPLATE_HELPERS_FORMATTERS]: [],
};

const mutations = {
    [Mutations.SET_TEMPLATE_HELPERS]: (_state, { formatters }) => {
        _state[State.TEMPLATE_HELPERS_FORMATTERS] = formatters;
        _state[State.TEMPLATE_HELPERS_TTL] = moment.utc().add(1, 'h');
    },
};

const getters = {
    [Getters.GET_TEMPLATE_HELPERS_AUTOCOMPLETE]: (_state, _getters) => eventIDs =>
        [
            ..._getters[Getters.GET_TEMPLATE_HELPERS_FORMATTERS],
            ..._getters[Getters.GET_TEMPLATE_HELPERS_EVENT_FIELDS](eventIDs),
        ],

    [Getters.GET_TEMPLATE_HELPERS_FORMATTERS]: _state =>
        flatMap(_state[State.TEMPLATE_HELPERS_FORMATTERS], ({ name, ...formatter }) =>
            (formatter.params || ['']).map(param => ({
                kind: 'TypeParameter',
                label: `#${name} ${param}`,
                detail: formatter.sourceFieldType,
                insertText: param.length > 0 ? `#${name} "${param}"}}$0{{/${name}}}` : `#${name}}}$0{{/${name}}}`,
            })),
        ),

    [Getters.GET_TEMPLATE_HELPERS_EVENT_FIELDS]: (_state, _getters, rootState, rootGetters) => eventIDs => {
        const events = rootGetters[[Modules.events, Getters.GET_MAPPED_EVENTS].join('/')];
        let fields = flatMap(
            events.filter(({ id }) => eventIDs.includes(id)),
            event => event.properties,
        );

        fields.forEach(field => {
            if (!isEmpty(field.rawSchema?.properties)) {
                fields = fields.concat(createComplexProp(field));
            }
        });

        return flatMap(
            groupBy(fields, event => event.name),
            (variants, label) => {
                const modifiers = ['', '#', '^'];
                const nullable = !every(variants, variant => variant.mandatory) ? 'nullable ' : '';
                const types = uniq(map(variants, variant => variant.type));

                return modifiers.map(modifier => ({
                    kind: 'Module',
                    label: `${modifier}${label}`,
                    details: `${nullable}${types.join(' | ')}`,
                    insertText: modifier.length > 0 ? `${modifier}${label}}}$0{{/${label}}}` : `${label}}}$0`,
                }));
            },
        );
    },
};

const actions = {
    [Actions.FETCH_TEMPLATE_HELPERS]: context =>
        new Promise(async resolve => {
            if (
                context.state[State.TEMPLATE_HELPERS_TTL].isAfter(moment.utc()) &&
                context.rootState[Modules.events][State.ALL_EVENTS].length > 0
            ) {
                resolve();
                return;
            }

            const { data } = await getTemplateHelpers();
            context.commit(Mutations.SET_TEMPLATE_HELPERS, data);

            await context.dispatch([Modules.events, Actions.LOAD_ALL_EVENTS].join('/'), null, { root: true });

            resolve();
        }),
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
