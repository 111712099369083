var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-layout"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var allFilters = ref.allFilters;
            var filteredEntities = ref.filteredEntities;
            var onFilterAdded = ref.onFilterAdded;
            var removeFilter = ref.removeFilter;
            var removeAllFilters = ref.removeAllFilters;
            var computedFilterValue = ref.computedFilterValue;
return [_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between position-relative mb-2"},[_c('h2',{staticClass:"component-title-um text--md mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.rewardsHistory'))+" ")]),_c('div',{staticClass:"d-flex"},[_c('ResponseModalButton',{attrs:{"response":_vm.payoutsApiResponse,"title":_vm.$i18n.t('customerCare.rewardsHistory')}}),_c('FilterTable',{attrs:{"columns":_vm.tableColumns,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER,"data-test-id":"filter-icon-btn"},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)]),_c('div',{staticClass:"position-relative mb-2"},[_c('div',{staticClass:"tags-container"},_vm._l((allFilters),function(filter,index){return _c('Tag',{key:index,staticClass:"tag-margins",attrs:{"label":_vm.getFilterLabel(filter, computedFilterValue)},on:{"closeIconClick":function($event){return removeFilter(index)}}})}),1),(allFilters.length > 1)?_c('span',{directives:[{name:"t",rawName:"v-t",value:('generic.clearAll'),expression:"'generic.clearAll'"}],staticClass:"clear-all-filters m-2 ml-4",on:{"click":removeAllFilters}}):_vm._e()]),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":true,"isCustomButtonPassed":true,"disableBoxShadow":true,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.tableColumns,"tableKey":"user/rewards-history"},on:{"selectedEntityModel":_vm.openSidebar},scopedSlots:_vm._u([{key:"state",fn:function(ref){
            var entity = ref.entity;
return [_c('div',{staticClass:"d-table"},[_c('AppLabel',{attrs:{"title":entity.stateLabel,"color":entity.stateColor}})],1)]}}],null,true)})],1)]}}])}),_c('AppAditionalSidebar',{attrs:{"visible":_vm.showSidebar},on:{"input":_vm.closeSidebar},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"lf-table-title mb-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.rewardHistoryDetails'))+" ")])]},proxy:true},{key:"content",fn:function(){return [(_vm.selectedItem)?_c('dl',{staticClass:"sidebar-data"},[_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.rewardRuleId')))]),_c('dd',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{
                            name: _vm.RouteNames.REWARD_EDITOR,
                            params: {
                                id: _vm.selectedItem.rewardRule.id,
                            },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.selectedItem.rewardRule.id)+" ")])],1),_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.rewardRuleDetails')))]),_c('dd',[(_vm.selectedItem.rewardRule.data)?_c('AppJSON',{attrs:{"value":_vm.selectedItem.rewardRule.data,"textareaHeight":"20rem"}}):[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")]],2),_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.payoutProps')))]),_c('dd',[_c('AppJSON',{attrs:{"value":_vm.selectedItem.props,"textareaHeight":"20rem"}})],1),(_vm.isOrchestrationEngineEnabled)?[_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.orchestrationExecId')))]),_c('dd',[(_vm.selectedItem.executionId && _vm.selectedItem.executionPlan)?_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                params: {
                                    id: _vm.selectedItem.executionPlan,
                                    execId: _vm.selectedItem.executionId,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.selectedItem.executionId)+" ")]):[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.none'))+" ")]],2)]:_vm._e()],2):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }