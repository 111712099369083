var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-fluid wrapper-container background-color"},[_c('AppHeader',{attrs:{"pageTitle":_vm.$i18n.t('customerCare.GORPaymentStatus')}}),_c('Breadcrumbs',{staticClass:"ml-4",attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('AppCustomerHeader',{attrs:{"infoData":_vm.infoData}}),_c('div',{staticClass:"section-layout"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.paymentList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filteredEntities = ref.filteredEntities;
return [_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center position-relative"},[_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.GORPaymentHistory'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQueryHasAccessTable),callback:function ($$v) {_vm.searchQueryHasAccessTable=$$v},expression:"searchQueryHasAccessTable"}}),_c('ResponseModalButton',{attrs:{"response":_vm.paymentListAPIResponse,"title":_vm.$i18n.t('customerCare.userInformation.loggedInDevices')}})],1)]),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.tablePaymentListColumnsData,"isSearchEnabled":true,"innerSearchQuery":_vm.searchQueryHasAccessTable,"isDataLoading":_vm.isDataLoading},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" ₱"+_vm._s(entity.amount)+" ")]}},{key:"transaction_status",fn:function(ref){
var entity = ref.entity;
return [_c('PaymentStatusIndicator',{attrs:{"status":entity.transaction_status}})]}},{key:"payment_method",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.getPaymentMethod(entity.payment_method))+" ")]}},{key:"create_timestamp",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision( entity.create_timestamp ))+" ")]}}],null,true)})],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }