




























import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// HTTP

import { getBillingAccountById } from '@/__new__/services/portal/postpaid/billingAccount/billingAccount';

import { updateInvoice, getInvoiceById } from '@/__new__/services/portal/postpaid/invoices/invoices';

import { getTemplate } from '@/__new__/services/dno/documents/http/templates';
import { previewDocument } from '@/__new__/services/dno/documents/http/documents';

// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';

import isEmpty from 'lodash/isEmpty';

import { DOCUMENT_GENERATE_TYPES } from '@/__new__/services/dno/documents/models/DocumentInterfaces';

import { ALERT_TYPES } from '@/common/alerts/Alert';

const MAX_NUMBER_OF_ITERATIONS = 12;
const currentDate = new Date();

export default Vue.extend({
    name: 'BillingAccountViewBillDialog',

    components: {
        AppButton,
        AppDialogV2,
        AppSpinner,
    },
    props: {
        isBillingAccountViewBillModalVisible: {
            type: Boolean,
            default: false,
        },
        invoiceId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            ALERT_TYPES,
            DOCUMENT_GENERATE_TYPES,
            runHotBillTitle: this.$i18n.t('billingAccount.viewBill'),
            billingAccount: {},
            startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString().substring(0, 10),
            endDate: currentDate.toISOString().substring(0, 10),
            processItems: false,
            template: {},
            templateName: 'Invoice',
            billJSON: '',
            generatedInvoiceId: 0,
            documenttPollers: {} as any,
            isBillAvailable: false,
            billUrl: '',
            isDataLoading: false,
            currentInvoice: {},
        };
    },
    watch: {
        invoiceId: {
            deep: true,
            handler() {
                if (this.invoiceId > 0) {
                    this.lookupBill();
                }
            },
        },
    },
    async created() {
        const response = await getBillingAccountById(this.$route.params.id);
        this.billingAccount = response.data;

        // Invoice

        const templateResponse = await getTemplate(this.templateName);
        const { template_definition: templateDefinition = '' } = templateResponse?.data?.data;
        this.template = templateDefinition;
    },
    methods: {
        async lookupBill() {
            const invoiceResponse = await getInvoiceById(this.invoiceId);

            this.currentInvoice = invoiceResponse.data;

            try {
                this.numberOfIterations += 1;
                const responce = await previewDocument({
                    payload: JSON.parse(this.currentInvoice.billJSON),
                    fileName: `${this.templateName}-${this.invoiceId}`,
                    templateDefinition: this.template,
                });

                const { status, url_data: urlData = {} } = responce.data.data;

                if (status !== DOCUMENT_GENERATE_TYPES.COMPLETED && isEmpty(urlData)) {
                    const poller = setInterval(() => {
                        if (this.numberOfIterations >= MAX_NUMBER_OF_ITERATIONS) {
                            this.clearDocumentInterval();
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('documents.maxNumberOfIterations'),
                                type: ALERT_TYPES.error,
                            });
                            return;
                        }

                        this.checkAvailabilityOfDocument();
                    }, 5000);
                    this.$set(this.documenttPollers, this.templateName, poller);
                } else {
                    const invoicePayload = {
                        url: urlData.signed_url,
                    };
                    this.billUrl = urlData.signed_url;
                    await updateInvoice(this.invoiceId, invoicePayload);
                    this.documentGenerationSucceeded();
                }
            } catch (e: any) {
                this.clearDocumentInterval();
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: e?.response?.data?.message ?? this.$i18n.t('documents.documentGeneratedError'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        async checkAvailabilityOfDocument(): Promise<void> {
            try {
                this.numberOfIterations += 1;
                const responce = await previewDocument({
                    payload: JSON.parse(this.currentInvoice.billJSON),
                    fileName: `${this.templateName}-${this.invoiceId}}`,
                    templateDefinition: this.template,
                });

                const { status, url_data: urlData = {} } = responce.data.data;

                if (status === DOCUMENT_GENERATE_TYPES.COMPLETED && !isEmpty(urlData)) {
                    const payload = {
                        url: urlData.signed_url,
                    };
                    this.billUrl = urlData.signed_url;
                    await updateInvoice(this.invoiceId, payload);
                    this.documentGenerationSucceeded();
                }
            } catch (e: any) {
                this.clearDocumentInterval();
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: e?.response?.data?.message ?? this.$i18n.t('documents.documentGeneratedError'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        clearDocumentInterval(): void {
            this.$eventBus.$emit('closeAllAlerts');
            clearInterval(this.documenttPollers[this.templateName]);
            this.$delete(this.documenttPollers, this.templateName);
            this.isDataLoading = false;
            this.numberOfIterations = 0;
        },
        documentGenerationSucceeded(): void {
            this.clearDocumentInterval();
            this.isBillAvailable = true;
        },
        onCloseViewBillModal() {
            this.$emit('closeViewBillModal');
        },
        viewBill() {
            window.open(this.billUrl, '_blank');
        },
    },
});
