














































































import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import { ICON_TYPES } from '@/common/iconHelper';

const emptyCard = {
    name: '',
    number: '',
    expiry: '',
    cvv: '',
    zip: '',
};

export default {
    name: 'ViasatPaymentMathod',
    components: {
        AppButton,
        AppDialog,
        AppInputV3,
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            isModalVisibile: false,
            isLoading: false,
            newCard: { ...emptyCard },
        };
    },
    methods: {
        makePayment() {
            // fake loading
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false;
                this.newCard = { ...emptyCard };
                this.isModalVisibile = false;
            }, 500);
        },
    },
};
