








































































import Vue from 'vue';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { getTenantUMSearchOptions } from '@/services/permissions/permissions.service';
import SearchBanner from '@/components/partials/SearchBanner.vue';

// Vuex
import { mapState, mapGetters, mapMutations } from 'vuex';
import { Modules } from '@/store/store';
import { Getters, Mutations } from '@/store/mutation-types';

// Router related files
import RouteNames from '@/router/routeNames';

// Helpers and libraries
import ccsSearchHelper, {
    SEARCH_QUERY,
    SEARCH_QUERY_FILTER_OPERATOR,
    SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP,
} from '@/__new__/features/customerCareSuite/common/ccsSearchHelper';
import { SEARCH_QUERY_OPTION } from '@/__new__/features/customerCare/common/userInfoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { TranslateResult } from 'vue-i18n';
import luaErrorCodes, { modules } from '@/common/luaErrors';

// 3rd party libs
import * as Sentry from '@sentry/vue';

type SearchOption = {
    key: string;
    label: string;
    validator: (val: string, disableValidator?: boolean) => boolean;
    placeholder: string;
    errorMessage?: string;
    supportedOperators?: SEARCH_QUERY_FILTER_OPERATOR[];
};

type SearchOperator = {
    key: string;
    label: TranslateResult | undefined;
    $isDisabled: boolean;
};

export default Vue.extend({
    name: 'UserManagement',

    components: {
        AppHeader,
        AppButton,
        AppMultiselectV3,
        AppInputV3,
        SearchBanner,
    },

    data() {
        return {
            RouteNames,
            ICON_TYPES,
            BUTTON_TYPES,
            searchValue: '',
            selectedSearchOption: null as SearchOption | null,
            selectedSearchOperator: null as SearchOperator | null,
        };
    },

    computed: {
        ...mapState(Modules.config, ['preferences']),
        ...mapGetters(Modules.endUserAuthorization, [Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]),
        searchOptions(): Record<string, SearchOption> {
            return this.getCCSSearchOptions();
        },
        searchOperations(): SearchOperator[] {
            return Object.values(SEARCH_QUERY_FILTER_OPERATOR)
                .filter(key => key !== SEARCH_QUERY_FILTER_OPERATOR.TERM)
                .map(key => ({
                    key,
                    label: SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP.get(key),
                    $isDisabled: !(this.selectedSearchOption?.supportedOperators || []).includes(key),
                }));
        },
        getInputPlaceholder(): string {
            return this.selectedSearchOption?.placeholder || '';
        },
    },

    mounted() {
        this.setSelectedSearchOption();
        this.refresh2faSuccessfulStatusForCurrentUserId();
    },

    methods: {
        ...mapMutations(Modules.endUserAuthorization, [
            Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS,
            Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING,
        ]),
        async onSearch() {
            const disableValidator =
                this.selectedSearchOption?.key === SEARCH_QUERY_OPTION.MAC_ADDRESS &&
                this.selectedSearchOperator?.key === SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH;
            if (
                this.selectedSearchOption &&
                typeof this.selectedSearchOption?.validator === 'function' &&
                this.selectedSearchOption.validator(this.searchValue, disableValidator)
            ) {
                this.$Progress.start();

                let searchIdValue;

                if (this.selectedSearchOption?.key === this.searchOptions.Email?.key) {
                    searchIdValue = permissionsService.userManagementCaseSensitiveEmailsEnabled()
                        ? this.searchValue
                        : this.searchValue.toLowerCase();
                } else {
                    searchIdValue = this.searchValue;
                }

                const searchParams = {
                    queryOption: this.selectedSearchOption?.key,
                    queryOperator: this.selectedSearchOperator?.key,
                    id: searchIdValue,
                };

                try {
                    const response = await ccsSearchHelper.searchAndLoadAccount(
                        searchParams.queryOption,
                        searchParams.id,
                        searchParams.queryOperator,
                    );
                    this.$Progress.finish();

                    const path = ccsSearchHelper.getSearchQueryUrlPath(
                        searchParams.queryOption,
                        this.$route.params.companyId,
                        response,
                    );

                    this.$router.push({ path });
                } catch (error) {
                    const { module: moduleName, code } = { ...error.response?.data };

                    this.$Progress.fail();
                    if (
                        moduleName === modules.USER &&
                        (code === luaErrorCodes.USER.USER_NOT_EXIST.code ||
                            code === luaErrorCodes.USER.ACCOUNT_NOT_FOUND.code ||
                            code === luaErrorCodes.USER.SUBSCRIBER_NOT_FOUND.code)
                    ) {
                        const searchQuery = ccsSearchHelper.getSearchQueryLabel(searchParams.queryOption);
                        const searchQueryEntity = ccsSearchHelper.getSearchQueryEntityType(searchParams.queryOption);
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.entityNotFound', {
                                entityId: searchQuery,
                                entityType: searchQueryEntity,
                            }),
                        );
                    } else if (
                        moduleName === modules.COMMON &&
                        code === luaErrorCodes.COMMON.INVALID_MSISDN_FORMAT.code
                    ) {
                        this.$alert(this.$i18n.t('customerCare.errorAlertMessages.msisdnInvalidFormat'));
                    } else {
                        // capturing errors only here intentionaly since "user not found" is not really an issue
                        Sentry.captureException(error);
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                        );
                    }
                }
            } else {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: `${this.selectedSearchOption.errorMessage}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        getCCSSearchOptions() {
            const options = getTenantUMSearchOptions();
            const filteredOptions = {};
            for (const key in options) {
                if ([...SEARCH_QUERY.keys()].includes(options[key]?.key)) {
                    filteredOptions[key] = options[key];
                }
            }
            return filteredOptions;
        },
        setSelectedSearchOption() {
            try {
                const searchOptions = Object.values(this.getCCSSearchOptions());

                const { userSearchParam } = this.preferences;

                if (userSearchParam && searchOptions.some(el => el?.key === userSearchParam)) {
                    this.selectedSearchOption = searchOptions.find(el => el?.key === userSearchParam);
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                });
            }
        },
        onOptionSelect(o) {
            if (o?.supportedOperators?.length === 1) {
                this.selectedSearchOperator = this.searchOperations.find(({ key }) => key === o.supportedOperators[0]);
            }
        },
        refresh2faSuccessfulStatusForCurrentUserId(): void {
            this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
            });
            this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]('');
        },
    },
});
