






















































































































































// Components
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalOrchestrationEngine from '@/components/partials/ResponseModalOrchestrationEngine.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import { dateToEpoch } from '@/common/formatting';
import { ICON_TYPES } from '@/common/iconHelper';
import moment from 'moment';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { TranslateResult } from 'vue-i18n';
import tableColumnType from '@/common/filterTable';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import {
    NOTES_ID_TYPE,
    oeNoteTag,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';

// Http
import httpNotes from '@/__new__/services/dno/crm/http/crm';

// Validation
import { required } from 'vuelidate/lib/validators';

interface SelectedNote {
    crmUserId?: string;
    epoch?: string;
    noteKey?: number;
    noteText?: string;
    timeMoment?: any;
}

interface ColumnData {
    name?: TranslateResult;
    key?: string;
    field?: string;
    sortBy?: any;
    filterType?: any;
}

export default {
    name: 'ExecutionNotes',
    components: {
        AppTable,
        AppButton,
        AppTextareaV3,
        AppDialogV2,
        AppAditionalSidebar,
        FilterTable,
        IconButton,
        ResponseModalOrchestrationEngine,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    mixins: [supportButtonMixin],
    props: {
        executionId: {
            required: true,
            type: String,
            default: '',
        },
        notes: {
            required: true,
            type: Array,
            default: () => [],
        },
        notesLoading: {
            type: Boolean,
            default: false,
        },
        notesApiResponse: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            notesSearchQuery: '' as string,
            isSidebarVisible: false as boolean,
            isAddNoteModalVisible: false as boolean,
            noteInput: '' as string,
            selectedNote: {} as SelectedNote,
            addNoteClicked: false as boolean,
            responseModalVisible: false as boolean,

            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
        };
    },
    computed: {
        notesColumnsData(): ColumnData[] {
            return [
                {
                    name: this.$i18n.t('orchestrationEngine.crmUserId'),
                    key: 'crmUserId',
                    field: 'crmUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'epoch',
                    sortBy: entity => entity.epoch,
                    field: 'timeMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.note'),
                    key: 'noteText',
                    field: 'noteText',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        formattedNotes(): SelectedNote[] {
            return this.notes.map((entity, index) => ({
                noteKey: index,
                epoch: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.epoch),
                timeMoment: moment(entity.epoch * 1000),
                crmUserId: entity.crm_user_id || '',
                noteText: getMultiLangFieldValueByLocale(entity.note),
            }));
        },
        selectedNoteId(): string {
            return this.selectedNote?.noteKey ?? '';
        },
    },
    async mounted() {
        this.$Progress.start();
        await this.fetchNotes();
        this.$Progress.finish();
    },
    validations: {
        noteInput: {
            required,
        },
    },
    methods: {
        fetchNotes(): void {
            this.$emit('fetchExecutionNotes');
        },
        async addExecutionNote(): Promise<any> {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    this.addNoteClicked = true;

                    this.$Progress.start();

                    await httpNotes.addNote({
                        id: this.executionId,
                        idType: NOTES_ID_TYPE.OE_EXECUTION,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.noteInput,
                        tags: [oeNoteTag],
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('orchestrationEngine.addNoteSuccess'),
                    });

                    this.$Progress.finish();

                    this.onCloseAddNoteModal();

                    this.fetchNotes();
                } catch (error: any) {
                    this.$Progress.fail();
                    Sentry.captureException(error);
                    this.$showErrorAlert('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.addNoteFail'),
                    });
                } finally {
                    this.addNoteClicked = false;
                }
            }
        },
        onCloseAddNoteModal(): void {
            this.isAddNoteModalVisible = false;
            this.noteInput = '';
            this.$v.$reset();
        },
        addNewNote(): void {
            this.isAddNoteModalVisible = true;
        },
        showSidebar(): void {
            this.isSidebarVisible = true;
        },
        selectedRow(id: string): void {
            this.selectedNote = this.formattedNotes.find(note => note.noteKey === id);
        },
    },
};
