

















import Vue from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

// helpers
import luaErrors from '@/common/luaErrors';

// Vuex
import { Modules } from '@/store/store';
import { mapActions } from 'vuex';
import Actions from '@/store/mutation-types';

export const CHANGE_TYPE = {
    ACTIVE: 1,
    PENDING: 2,
};

export default Vue.extend({
    name: 'OrderCancelPlanChangeModal',
    components: {
        AppButton,
        AppDialogV2,
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        accountId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            // proxy
            BUTTON_TYPES,
        };
    },
    methods: {
        ...mapActions(Modules.orders, [Actions.CANCEL_REPLACE_ORDER]),
        emitClose() {
            this.$emit('close');
        },
        async onSaveCancelPlanChange() {
            await this.$withProgressBar(
                async () => {
                    await this[Actions.CANCEL_REPLACE_ORDER]({ accountId: this.accountId });
                },
                {
                    errorHandler: error => {
                        if (error.response?.data?.code === luaErrors.ORDEROE.TIME_CONSTRAINTS_NOT_SATISFIED.code) {
                            this.showBlackoutDialogCancel = true;
                            this.emitClose();
                        } else {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrong'),
                            });
                            this.emitClose();
                        }
                    },
                },
            );
        },
    },
});
