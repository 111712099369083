var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-id":"fiber-order-status"}},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.fiberOrders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filteredEntities = ref.filteredEntities;
return [_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center position-relative"},[_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.userInformation.fiberStatus'))+" ")])]),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.columnsData,"isSearchEnabled":true,"innerSearchQuery":_vm.searchQuery,"tableKey":"customer-care-new/associated-roles"},scopedSlots:_vm._u([{key:"createTimestamp",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.createTimestamp))+" ")]}},{key:"expireDate",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.expireDate))+" ")]}},{key:"state",fn:function(ref){
var entity = ref.entity;
return [(!_vm.isUndefined(entity.state))?_c('EntityStatusIndicator',{attrs:{"stateMap":_vm.ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,"stateMapColor":_vm.ORDER_STATUS_INDICATOR_MAP,"status":entity.state,"isInTable":true}}):_c('i',[_vm._v(_vm._s(_vm.$i18n.t('generic.empty')))])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }