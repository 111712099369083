



















import Vue from 'vue';
// vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// mixins
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';

// helpers
import { ORD_CONFIG_STATUS } from '@/common/sinkConfigHelper';
import isEmpty from 'lodash/isEmpty';

// http
import { upsertReportConfig } from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';

// models
import ORDSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ORDSinkConfig';

export default Vue.extend({
    name: 'ORDConfigsEditor',
    mixins: [SinkConfigEditorMixin],
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            ORDConfigsSettings: Getters.GET_ORD_CONFIGS_SETTINGS,
            allORDConfigs: Getters.GET_ORD_CONFIGS,
            getConfigById: Getters.GET_ORD_CONFIG_BY_ID,
            getSinkFormatters: Getters.GET_SINK_FORMATTERS,
            typeCasters: Getters.GET_SINK_TYPE_CASTERS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),
        shouldShowContent(): boolean {
            if (this.$route.params.id) {
                return !!this.editableConfig && this.events.length;
            }
            return !isEmpty(this.ORDConfigsSettings) && this.triggerDefinitions && this.events;
        },
        editableConfig(): ORDSinkConfig {
            const config = this.getConfigById(this.$route.params.id) as ORDSinkConfig;

            if (this.$route.params.clone) {
                return new ORDSinkConfig({
                    ...config,
                    state: ORD_CONFIG_STATUS.DRAFT,
                });
            }

            return config;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this[Actions.LOAD_ORD_CONFIGS_SETTINGS](),
                    this.fetchTriggers(),
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchSegments(),
                    this.fetchSinkTypeCasters(),
                ];

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                if (this.$route.params.id && this.allORDConfigs.length === 0) {
                    await this[Actions.LOAD_ORD_CONFIGS]();
                }
            },
            {
                errorHandler: () => this.$alert(this.$t('events.alerts.failedToLoadReportsConfig')),
            },
        );
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_ORD_CONFIGS_SETTINGS, Actions.LOAD_ORD_CONFIGS]),
        upsertReportConfig,
    },
});
