var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('tr',{staticClass:"entity-row text--sm no-gutters align-items-center row no-gutters w-100",class:{ selected: _vm.selectedEntity, 'default-hover': _vm.activateHover() },on:{"mouseover":function($event){_vm.isRowHovered = true},"mouseleave":function($event){_vm.isRowHovered = false}}},[(_vm.isCheckboxEnabled)?_c('td',[_c('AppCheckbox',{staticClass:"font-weight-bold checkbox-pad",attrs:{"value":_vm.isChecked,"small":true},on:{"input":_vm.onUpdateChecked}})],1):_vm._e(),_vm._l((_vm.columnsData),function(column,index){return [(_vm.showTableCell(_vm.entity[column.key]))?_c('td',{key:column.key,staticClass:"table-cell truncate-text-all pl-4",class:[
                    column.classes,
                    { 'pr-5': index + 1 === _vm.columnsData.length },
                    { 'lf-table-title': index === 0 },
                    { 'lf-table-title-blue': index === 0 && _vm.selectedEntity },
                    { 'overflow-visible': column.overflowVisible } ],style:(_vm.getStyles(column.width, index === _vm.columnsData.length - 1)),on:{"mouseover":function($event){return _vm.setHoveredColumn(index)},"mouseleave":function($event){return _vm.setHoveredColumn(null)},"click":function($event){return _vm.$emit('clicked')}}},[(_vm.$slots[column.key])?_c('div',{staticClass:"w-100"},[_vm._t(column.key)],2):(_vm.isEmptyCell(_vm.entity[column.key]))?_c('span',{attrs:{"title":_vm.$i18n.t('generic.empty')}},[_c('i',[_vm._v(_vm._s(_vm.$i18n.t('generic.empty')))])]):(Array.isArray(_vm.entity[column.key]))?_c('span',{attrs:{"title":_vm.entity[column.key]}},[_vm._v(" "+_vm._s(_vm.entity[column.key].join(', '))+" ")]):_c('span',{attrs:{"title":_vm.entity[column.key]}},[_vm._v(" "+_vm._s(_vm.entity[column.key])+" ")])]):_vm._e()]}),(_vm.entity.nestedRows && _vm.entity.nestedRows.length)?_c('td',{style:(_vm.getNestedRowsWidth(_vm.entity))},[_c('table',{staticClass:"d-flex flex-column"},_vm._l((_vm.entity.nestedRows),function(nestedData,k){return _c('tr',{key:k},_vm._l((Object.entries(nestedData)),function(ref){
                var columnName = ref[0];
                var val = ref[1];
return _c('td',{key:columnName,staticClass:"table-cell truncate-text-all pl-4",style:(_vm.getNestedRowColumnWidth(columnName)),on:{"mouseover":function($event){_vm.setHoveredColumn(_vm.columnsData.indexOf(columnName))},"mouseleave":function($event){return _vm.setHoveredColumn(null)},"click":function($event){return _vm.$emit('clicked')}}},[(_vm.$slots[columnName])?_c('div',{staticClass:"w-100"},[_vm._t(columnName)],2):(_vm.isEmptyCell(val))?_c('span',{attrs:{"title":_vm.$i18n.t('generic.empty')}},[_c('i',[_vm._v(_vm._s(_vm.$i18n.t('generic.empty')))])]):(Array.isArray(val))?_c('span',{attrs:{"title":val}},[_vm._v(" "+_vm._s(val.join(', '))+" ")]):_c('span',{attrs:{"title":val}},[_vm._v(" "+_vm._s(val)+" ")])])}),0)}),0)]):_vm._e(),(_vm.isRowHovered || _vm.alwaysShowRowButtons)?_c('div',{staticClass:"hover-row-buttons",style:({ right: _vm.controlsOffset + 'px' })},[_c('div',{staticClass:"button-wrapper"},[_vm._t("customRowButtons"),(_vm.$slots.details)?_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.show'),"icon":_vm.showDetails ? _vm.ICON_TYPES.ARROW_UP : _vm.ICON_TYPES.ARROW_DOWN,"data-test-id":"table-details-button"},on:{"iconClick":_vm.toggleDetailsVisibility}}):_vm._e()],2),_c('div',{staticClass:"transitioning-area"})]):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}]},[_vm._t("details")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }