








// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export const NUMBER_STATES = {
    AVAILABLE: 1,
    RESERVED: 2,
    COOL_DOWN: 3,
    IN_USE: 4,
    UNAVAILABLE: 5,
};

const NUMBER_STATE_TO_TEXT_MAP = new Map([
    [NUMBER_STATES.AVAILABLE, i18n.t('operator.available')],
    [NUMBER_STATES.RESERVED, i18n.t('operator.reserved')],
    [NUMBER_STATES.COOL_DOWN, i18n.t('operator.numberManagement.downloadOptions.cooldown')],
    [NUMBER_STATES.IN_USE, i18n.t('generic.stateMap.inUse')],
    [NUMBER_STATES.UNAVAILABLE, i18n.t('generic.stateMap.unavailable')],
]);

// all $gray-800 for now unless more precise mapping is specified
const NUMBER_STATE_TO_COLOR_MAP = new Map([
    [NUMBER_STATES.AVAILABLE, LABEL_COLOR.gray],
    [NUMBER_STATES.RESERVED, LABEL_COLOR.gray],
    [NUMBER_STATES.COOL_DOWN, LABEL_COLOR.gray],
    [NUMBER_STATES.IN_USE, LABEL_COLOR.gray],
    [NUMBER_STATES.UNAVAILABLE, LABEL_COLOR.gray],
]);

export default {
    name: 'NumberStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: [String, Number],
        },
    },
    computed: {
        statusConverter(): string {
            if (typeof this.status === 'number') {
                const statusText = NUMBER_STATE_TO_TEXT_MAP.get(this.status);
                return statusText || this.$i18n.t('generic.N/A');
            }
            return this.status;
        },
        colorConverter(): string | undefined {
            if (typeof this.status === 'number') {
                const statusColor = NUMBER_STATE_TO_COLOR_MAP.get(this.status);
                return statusColor || LABEL_COLOR.gray;
            }

            // fallback to default
            return undefined;
        },
    },
};
