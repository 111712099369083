import { AxiosPromise } from 'axios';
import http from '@/http/index';
import {
    AddTemplatePayload,
    DocumentTemplatesApiResponse,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';

/**
 * Get templates.
 *
 * @returns {AxiosPromise}
 */
export function getTemplates(): AxiosPromise<DocumentTemplatesApiResponse<string[]>> {
    return http({
        method: 'GET',
        url: `/v3documents/gettemplates`,
    });
}

/**
 * Get template.
 *
 * @returns {AxiosPromise}
 */
export function getTemplate(templateId: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: `/v3documents/gettemplate?template_id=${templateId}`,
    });
}

/**
 * Add template.
 *
 * @returns {AxiosPromise}
 */
export function addTemplate({ templateId, templateDefinition }: AddTemplatePayload): AxiosPromise<any> {
    return http({
        method: 'PUT',
        url: `/v3documents/addtemplate?template_id=${templateId}`,
        data: {
            template_definition: templateDefinition,
        },
    });
}

export default {
    getTemplates,
    getTemplate,
    addTemplate,
};
