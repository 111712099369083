





























































// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Http
import {
    getApplications,
    getApplicationOwners,
    createApiProductOrder,
} from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

interface Multiselect {
    name: string;
    value: string;
}

export default {
    name: 'ApiProductOrdersEditorPage',

    components: {
        AppHeader,
        AbstractEditPageWrapper,
        AppButton,
        AppMultiselectV3,
    },

    data() {
        return {
            applicationOwner: { name: '', value: '' } as Multiselect,
            product: { name: '', value: '' } as Multiselect,
            application: { name: '', value: '' } as Multiselect,
            applicationOwnerOptions: [] as Multiselect[],
            productOptions: [] as any[],
            applicationOptions: [] as Multiselect[],

            BUTTON_TYPES,
            ICON_TYPES,
            PLURALIZATION,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OperateAPIsProductOrdersWrite');
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            try {
                const applicationOwnersResponse = await getApplicationOwners();
                const applicationsResponse = await getApplications();

                this.applicationOwnerOptions = (applicationOwnersResponse.data || []).map((appOwner: any) => ({
                    name: appOwner.name,
                    value: appOwner.id,
                }));

                this.applicationOptions = (applicationsResponse.data || []).map((app: any) => ({
                    name: app.name,
                    value: app.id,
                }));

                this.productOptions = [
                    {
                        id: '06ee1823-d09c-4c09-b4cf-105f3ac8d9e1',
                        version: '1.0',
                        name: 'POC API Test Offer - Service ID 1',
                        lifecycleStatus: 'inDraft',
                        createOn: '2024-06-26T15:21:07.485Z',
                        lastUpdate: '2024-06-26T00:00:00.000Z',
                        allowedAction: [],
                        productOfferingRelationship: [],
                        productOfferingCharacteristic: [],
                        bundledProductOffering: [],
                        bundledGroupProductOffering: [],
                        channel: [],
                        marketSegment: [],
                        place: [],
                        category: [],
                        productOfferingTerm: [],
                        policy: [],
                        agreement: [],
                        externalIdentifier: [
                            {
                                id: 'ba6c0b22bb5549e99dd9394c23f93c59',
                                '@type': 'ExternalIdentifier',
                            },
                        ],
                        prodSpecCharValueUse: [],
                        '@type': 'ProductOffering',
                        href: '/ProductOffering/06ee1823-d09c-4c09-b4cf-105f3ac8d9e1',
                    },
                    {
                        id: '8e24c9b8-ebcc-4032-a256-b663ca2953b2',
                        version: '1.0',
                        name: 'POC API Test Offer - Service ID 2',
                        lifecycleStatus: 'inDraft',
                        createOn: '2024-06-26T15:20:33.172Z',
                        lastUpdate: '2024-06-26T00:00:00.000Z',
                        allowedAction: [],
                        productOfferingRelationship: [],
                        productOfferingCharacteristic: [],
                        bundledProductOffering: [],
                        bundledGroupProductOffering: [],
                        channel: [],
                        marketSegment: [],
                        place: [],
                        category: [],
                        productOfferingTerm: [],
                        policy: [],
                        agreement: [],
                        externalIdentifier: [
                            {
                                id: 'b09a55e2a4704ca58c2d245a1e1f8fe7',
                                '@type': 'ExternalIdentifier',
                            },
                        ],
                        prodSpecCharValueUse: [],
                        '@type': 'ProductOffering',
                        href: '/ProductOffering/8e24c9b8-ebcc-4032-a256-b663ca2953b2',
                    },
                ];

                if (this.applicationOwnerOptions.length) {
                    [this.applicationOwner] = this.applicationOwnerOptions;
                }

                if (this.applicationOptions.length) {
                    [this.application] = this.applicationOptions;
                }

                [this.product] = this.productOptions;
            } catch {
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
            }
        });
    },
    methods: {
        async onSave(): Promise<any> {
            try {
                await createApiProductOrder({
                    '@type': 'ApiProductOrder',
                    '@baseType': 'DCSProductOrder',
                    externalId: [
                        {
                            '@type': 'ExternalIdentifier',
                            externalIdentifierType: 'ChannelPartner',
                            owner: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
                        },
                        {
                            '@type': 'ExternalIdentifier',
                            externalIdentifierType: 'ProductOrderId',
                            owner: this.product,
                        },
                        {
                            '@type': 'ExternalIdentifier',
                            externalIdentifierType: 'ApplicationId',
                            owner: this.application,
                        },
                    ],
                    description: 'Camara API order for an Application Owner',
                    productOrderItem: [
                        {
                            '@type': 'ApiProductOrderItemAdd',
                            '@baseType': 'ProductOrderItem',
                        },
                    ],
                });

                this.$showSuccessAlert({
                    message: this.$i18n.t('operateAPIs.successfullyCreatedApiProductOrder'),
                });

                this.redirectToListPage();
            } catch {
                this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongCreateApiProductOrder'), {
                    type: ALERT_TYPES.error,
                });
            }
        },
        redirectToListPage(): void {
            this.$router.push({
                name: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_LIST_PAGE,
            });
        },
    },
};
