








// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { getRewardStatus, getRewardStatusColor } from '@/modules/rewards/common/rewardsRulesHelper';

export default {
    name: 'RewardStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            type: Number,
            required: true,
        },
        startTime: {
            type: [String, Number],
            default: null,
        },
        endTime: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        statusConverter() {
            return getRewardStatus(this.status, this.startTime, this.endTime);
        },
        colorConverter() {
            return getRewardStatusColor(this.status, this.startTime, this.endTime);
        },
    },
};
