import { DnoResponseBase } from '@/http/index';
import { Entity } from '@/http';

export const enum SEARCH_INDEX {
    CHANNEL = 1,
    COMMERCIAL_OFFER_NAME = 2,
    MSISDN = 3,
}

/**
 * Manually creating consts for enum values due to unit testing obstacles
 * when using enums in TypeScript
 */
export const CHANNEL_INDEX = SEARCH_INDEX.CHANNEL;
export const COMMERCIAL_OFFER_NAME_INDEX = SEARCH_INDEX.COMMERCIAL_OFFER_NAME;
export const MSISDN_INDEX = SEARCH_INDEX.MSISDN;

export const enum ACTION {
    ADD = 1,
    REMOVE = 2,
}

export const enum STATUS {
    ACCEPTED_RECORD = 0,
    NAAS_REJECT_REQUEST = 1,
    NAAS_ACCEPTED_REQUEST = 2,
    CALLBACK_WITH_FAILED_STATUS = 3,
    CALLBACK_WITH_SUCCESS_STATUS = 4,
    CALLBACK_NEVER_HAPPENED = 5,
}

export interface Msisdn {
    app_client_id: string;
    commercial_offer_name: string;
    customer_ban: string;
    naas_provisioning_status: STATUS;
    paying_customer_id: string;
    create_portal_id: string;
    update_timestamp: number;
    bulk_upload_id: string;
    msisdn: string;
    action: ACTION;
    channel: string;
}

export interface QodMsisdn extends Msisdn {
    customer_name: string;
    qod_profiles: string[];
}

export interface DeviceStatusMsisdn extends Msisdn {
    approver_name: string;
    service_ids: string[];
}

/**
 * Payload returned by `getRegisteredEntities`
 */
export interface RegisteredEntities {
    data: {
        total_pages: number;
        total_elements: number;
        page_number: number;
        size: number;
        items: RegisteredEntityItem[];
    };
}

/**
 * Structure of single item inside payload from `getRegisteredEntities`
 */
export interface RegisteredEntityItem {
    entity_name: string;
    created_at: MillisecondsSinceEpoch;
    last_updated: MillisecondsSinceEpoch;
}

/**
 * Payload returned by `getEntityDetailsByName`
 */
export interface EntityDetailsByName {
    data: {
        expected_count: number;
        started_processing_at: MillisecondsSinceEpoch;
        last_updated_at: MillisecondsSinceEpoch;
        event_category_counters: EventCategoryCounters;
        status: string;
    };
}

export type EventCategoryCounters = Record<string, number>;

/**
 * This enum contains keys seen inside the `EventCategoryCounters` record.
 * There is only one known fixed key 'successful' which indicates number of successful events.
 * Other event categories are not listed since they are dynamic.
 * For example failure keys have the following structure `failed_<reason>`.
 * A commonly seen error code is `failed_undefined`.
 */
export enum EVENT_CATEGORY {
    SUCCESSFUL = 'successful',
}

/**
 * Payload returned by `getSignedURL`
 */
export interface SignedUrl {
    data: {
        upload_url: string;
        entity_id: string;
        required_request_headers: Record<string, string>;
    };
}

/**
 * Payload returned by `getSignedURLForDownload`
 */
export interface SignedUrlForDownloadEntity {
    signed_entity: {
        url: string;
        entity_id: string;
        required_request_headers: object;
    };
}

export type SignedUrlForDownloadResponse = Entity<SignedUrlForDownloadEntity>;

/**
 * Parameters given to the `getSignedURL` request.
 */
export interface GetSignedUrlParams {
    name: string; //filename being uploaded
}

/**
 * Payload returned by `queryMsisdn` request
 */
export interface QueryMsisdnResponse extends DnoResponseBase {
    customer_lines: QodMsisdn[] | DeviceStatusMsisdn[];
}

export type MillisecondsSinceEpoch = number;
