<template>
    <div>
        <div
            v-if="!isEmpty"
            class="flex-container"
        >
            <div class="input-block">
                <AppInputV3
                    v-model="statusZero"
                    :label="$i18n.t('charging.policyCounters.status')"
                    :explanationText="statusZeroExplanationText"
                    :placeholder="$i18n.t('charging.policyCounters.addStatus')"
                    :invalid="requiredErrorsData.statusZeroError"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :disabled="disabledStatus(statusZero)"
                    class="input-content"
                    data-test-id="statusZero"
                    @change="
                        onchange();
                        onStatusZeroInput();
                    "
                />
            </div>
            <div class="input-block">
                <template v-if="isQuota">
                    <div class="input-title">
                        {{ $i18n.t('charging.policyCounters.threshold') }}
                    </div>
                    <UnitPicker
                        v-model="threshold"
                        :searchable="false"
                        :error="requiredErrorsData.thresholdError"
                        :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                        :isEditorDesign="true"
                        :type="definitions"
                        :disabled="disabled"
                        class="input-content"
                        data-test-id="threshold-quota"
                        @input="
                            onchange();
                            onThresholdInput($event);
                        "
                    />
                </template>
                <template v-if="isPercentage">
                    <div class="flex-container">
                        <AppInputV3
                            v-model="threshold"
                            :explanationText="$i18n.t('charging.policyCounters.lessThan100')"
                            :placeholder="$i18n.t('charging.policyCounters.addTP')"
                            :label="$i18n.t('charging.policyCounters.thresholdPercentage')"
                            :type="'number'"
                            :invalid="requiredErrorsData.thresholdError"
                            :errorMessage="$i18n.t('charging.policyCounters.reqAndLim')"
                            :disabled="disabled"
                            class="input-content"
                            data-test-id="threshold-percentage"
                            @change="
                                onchange();
                                onThresholdInput($event);
                            "
                        />
                    </div>
                </template>
            </div>
            <div class="input-block">
                <AppInputV3
                    v-model="statusOne"
                    :explanationText="disabledStatusExplanationText(statusOne)"
                    :label="$i18n.t('charging.policyCounters.status')"
                    :placeholder="$i18n.t('charging.policyCounters.addStatus')"
                    :invalid="requiredErrorsData.statusOneError"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :disabled="disabledStatus(statusOne)"
                    class="input-content"
                    :class="{ 'status-one': isQuota }"
                    data-test-id="statusOne"
                    @change="
                        onchange();
                        onStatusOneInput();
                    "
                />
            </div>
        </div>
        <div
            v-for="(el, index) in thresholdsData"
            :key="index"
        >
            <div class="flex-container">
                <div class="input-block mt-2">
                    <template v-if="isQuota">
                        <div class="input-title">
                            {{ $i18n.t('charging.policyCounters.threshold') }}
                        </div>
                        <UnitPicker
                            v-model="thresholdsData[index]"
                            :searchable="false"
                            :error="requiredErrorsData.thresholdsError[index]"
                            :errorMessage="$i18n.t('charging.policyCounters.reqAndBig')"
                            :isEditorDesign="true"
                            :type="definitions"
                            :disabled="disabled"
                            class="input-content"
                            :data-test-id="`threshold-quota-${index}`"
                            @input="
                                onchange();
                                onThresholdsInput(index);
                            "
                        />
                    </template>
                    <template v-if="isPercentage">
                        <div class="flex-container">
                            <AppInputV3
                                v-model="thresholdsData[index]"
                                :explanationText="$i18n.t('charging.policyCounters.lessThan100')"
                                :placeholder="$i18n.t('charging.policyCounters.addTP')"
                                :label="$i18n.t('charging.policyCounters.thresholdPercentage')"
                                :type="'number'"
                                :invalid="requiredErrorsData.thresholdsError[index]"
                                :errorMessage="$i18n.t('charging.policyCounters.reqAndBig')"
                                :disabled="disabled"
                                class="input-content"
                                :data-test-id="`threshold-percentage-${index}`"
                                @change="
                                    onchange();
                                    onThresholdsInput(index);
                                "
                            />
                        </div>
                    </template>
                </div>
                <div class="input-block mt-2">
                    <AppInputV3
                        v-model="statusesData[index]"
                        :explanationText="disabledStatusExplanationText(statusesData[index])"
                        :label="$i18n.t('charging.policyCounters.status')"
                        :placeholder="$i18n.t('charging.policyCounters.addStatus')"
                        :invalid="requiredErrorsData.statusesError[index]"
                        :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                        :disabled="disabledStatus(statusesData[index])"
                        class="input-content"
                        :class="{ 'status-one': isQuota }"
                        :data-test-id="`status-${index}`"
                        @change="
                            onchange();
                            onStatusesInput(index);
                        "
                    />
                </div>
                <IconButton
                    :label="$i18n.t('generic.delete')"
                    :icon="ICON_TYPES.DELETE"
                    class="delete-btn"
                    :data-test-id="`delete-threshold-${index}`"
                    @iconClick="deleteInput(entity)"
                />
            </div>
        </div>
        <AppButton
            v-if="!isEmpty"
            :label="$i18n.t('charging.policyCounters.newThreshold')"
            :iconType="ICON_TYPES.PLUS"
            :disabled="disableAdd || disabled"
            :isSmall="true"
            data-test-id="new-threshold"
            class="ml-2 mt-3"
            @click="addInput"
        />
    </div>
</template>
<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { emptyType, TYPES_OPTIONS } from '@/__new__/services/dno/charging/common/policyCounterHelper';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'Thresholds',
    components: {
        AppInputV3,
        UnitPicker,
        AppButton,
        IconButton,
    },
    props: {
        statuses: {
            type: Array,
            require: true,
            default: () => [],
        },
        thresholds: {
            type: Array,
            require: true,
            default: () => [],
        },
        thresholdType: {
            type: String,
            require: true,
            default: '',
        },
        definitions: {
            type: String,
            require: true,
            default: '',
        },
        requiredErrors: {
            type: Object,
            require: true,
            default: () => ({
                multiselectError: false,
                nameError: false,
                statusZeroError: false,
                statusOneError: false,
                statusesError: [],
                thresholdError: false,
                thresholdsError: [],
                usageCounterError: false,
            }),
        },
        statusesToDisable: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            statusesData: [],
            thresholdsData: [],
            requiredErrorsData: {},
            statusZero: '',
            statusOne: '',
            threshold: 0,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        isEmpty() {
            return this.thresholdType === emptyType.value;
        },
        isQuota() {
            return this.thresholdType === TYPES_OPTIONS.QUOTA;
        },
        isPercentage() {
            return this.thresholdType === TYPES_OPTIONS.PERCENTAGE;
        },
        statusZeroExplanationText() {
            return this.disabledStatus(this.statusZero)
                ? this.$i18n.t('charging.policyCounters.statusesDisabledDisabled')
                : null;
        },
        disableAdd() {
            if (this.statusesData.length === 0) {
                return this.threshold <= 0 || this.statusZero === '' || this.statusOne === '';
            }

            return (
                this.statusesData[this.statusesData.length - 1] === '' ||
                this.thresholdsData[this.thresholdsData.length - 1] === 0
            );
        },
    },
    watch: {
        statuses: {
            immediate: true,
            handler(value) {
                const tempVal = cloneDeep(value);
                this.statusZero = tempVal.shift() || '';
                this.statusOne = tempVal.shift() || '';
                this.statusesData = tempVal;
            },
        },
        thresholds: {
            immediate: true,
            handler(value) {
                const tempVal = cloneDeep(value);
                this.threshold = tempVal.shift() || 0;
                this.thresholdsData = tempVal;
            },
        },
        requiredErrors: {
            immediate: true,
            handler(value) {
                this.requiredErrorsData = value;
            },
        },
    },
    methods: {
        disabledStatus(status) {
            return this.statusesToDisable.includes(status) || this.disabled;
        },
        disabledStatusExplanationText(status) {
            return this.disabledStatus(status)
                ? this.$i18n.t('charging.policyCounters.statusesDisabledDisabled')
                : null;
        },
        addInput() {
            this.thresholdsData.push(0);
            this.statusesData.push('');
            this.onchange();

            this.requiredErrorsData.thresholdsError.push(false);
            this.requiredErrorsData.statusesError.push(false);
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        onchange() {
            const statuses = [...this.statusesData];
            statuses.unshift(this.statusOne);
            statuses.unshift(this.statusZero);

            const thresholds = [...this.thresholdsData];
            thresholds.unshift(this.threshold);

            this.$emit('change', {
                statuses,
                thresholds,
            });
        },
        onStatusZeroInput() {
            this.requiredErrorsData.statusZeroError = false;
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        onStatusOneInput() {
            this.requiredErrorsData.statusOneError = false;
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        onThresholdInput(value) {
            this.requiredErrorsData.thresholdError = false;

            if (this.isQuota && value <= 0) {
                this.requiredErrorsData.thresholdError = true;
            }

            if (this.isPercentage && (value <= 0 || value > 100)) {
                this.requiredErrorsData.thresholdError = true;
            }

            if (this.requiredErrorsData.thresholdsError.length) {
                this.requiredErrorsData.thresholdsError[0] = this.thresholdsData[0] <= value;
            }

            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        onThresholdsInput(index) {
            this.requiredErrorsData.thresholdsError[index] = false;
            this.isThresholdInArrayValid(index);
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        isThresholdInArrayValid(index) {
            if (index < 0) {
                return;
            }

            const percentageError =
                this.isPercentage && (this.thresholdsData[index] <= 0 || this.thresholdsData[index] > 100);

            if (percentageError) {
                this.$set(this.requiredErrorsData.thresholdsError, index, percentageError);
                return;
            }

            const quotaError = this.isQuota && this.thresholdsData[index] <= 0;
            if (quotaError) {
                this.$set(this.requiredErrorsData.thresholdsError, index, quotaError);
                return;
            }

            if (index === 0) {
                const error = this.thresholdsData.length && this.thresholdsData[index] <= this.threshold;
                this.$set(this.requiredErrorsData.thresholdsError, index, error);
            } else if (index > 0) {
                const error = this.thresholdsData[index] <= this.thresholdsData[index - 1];
                this.$set(this.requiredErrorsData.thresholdsError, index, error);
            }
        },
        onStatusesInput(index) {
            this.requiredErrorsData.statusesError[index] = false;
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
        deleteInput(index) {
            this.statusesData.splice(index, 1);
            this.thresholdsData.splice(index, 1);

            this.requiredErrorsData.thresholdsError.splice(index, 1);
            this.requiredErrorsData.statusesError.splice(index, 1);
            this.$emit('errorsDataChange', this.requiredErrorsData);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.flex-container {
    display: flex;
    flex-direction: row;

    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}

.input-block {
    position: relative;
    margin-right: 1rem;
    margin-left: 1rem;

    .input-title {
        width: 12.5rem;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }

    .input-content {
        width: 12.5rem;
    }
}

.delete-btn {
    top: 1.8rem;
    opacity: 0;
}

.status-one {
    margin-left: 2rem;
    margin-top: -0.2rem;
}
</style>
