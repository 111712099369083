<template>
    <div class="container-fluid p-0 h-100 d-flex flex-column">
        <AppHeader :pageTitle="pageTitle" />

        <div class="panel steps d-flex align-items-center py-3 mt-1">
            <Stepper
                :steps="steps"
                :currentStepKey="currentStepKey"
                :maxStepAvailableIndex="maxStepReachedIndex"
                class="w-100 px-4 pt-2"
                @back="goToStep"
            />
        </div>

        <div class="panel page-header d-flex justify-content-between align-items-center">
            <NameInput
                :value="campaign.name"
                :invalidMessage="campaignNameInvalidMessage"
                placeholder="Campaign Name"
                class="d-flex flex-column flex-grow-1 overflow-hidden mr-4"
                @nameChanged="campaignNameChanged"
            />
            <div :class="`status d-flex ${campaign.status.cssClass}`">
                {{ campaign.status.title }}
            </div>
        </div>

        <div class="row flex-grow-1 mx-0">
            <!-- Delivery - details -->
            <div
                v-show="currentStepKey === Steps.delivery"
                class="panel col campaign-delivery display-flex flex-column align-items-center main-step-panel"
            >
                <div class="section">
                    <div class="section-header"><img src /><span class="img" />Choose Campaign Delivery Type</div>

                    <!-- Delivery - details - type selection -->
                    <div class="d-flex">
                        <div
                            v-for="deliveryType in filteredDeliveryTypes"
                            :key="deliveryType.name"
                        >
                            <div class="flex-grow-1 selector col ml-5 row">
                                <AppRadioButton
                                    :value="campaign.delivery.type"
                                    :inputValue="deliveryType.name"
                                    name="delivery-type"
                                    @input="selectDelivery"
                                >
                                    <div class="label">
                                        <div>{{ deliveryType.label }}</div>
                                        <div>{{ deliveryType.description }}</div>
                                    </div>
                                </AppRadioButton>
                            </div>
                        </div>

                        <div v-if="!isScheduledDelivery && !isActionBasedDelivery && !isApiTriggeredDelivery">
                            {{ $t('campaigns.deliveryTypes.noDeliveryTypesSelected') }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="availableCampaignPriorities.length || !!campaign.selectedPriority"
                    class="section mt-4 mb-2"
                >
                    <div class="section-header">{{ $t('campaigns.priority.chooseCampaignPriority') }}</div>

                    <AppMultiselectV3
                        v-model="campaign.selectedPriority"
                        :additionalLabel="$t('campaigns.priority.campaignPriority')"
                        :small="true"
                        :disabled="availableCampaignPriorities.length === 1"
                        :options="availableCampaignPriorities"
                        :allowEmpty="false"
                        :multiple="false"
                    />
                </div>

                <hr class="my-3" />

                <!-- Delivery - details - scheduled -->
                <div
                    v-if="isScheduledDelivery"
                    class="section scheduled-delivery"
                >
                    <div class="section-header"><img src /><span class="img" />Time-Based Scheduling Options</div>

                    <div class="font-weight-bold mb-2">Campaign launch configuration:</div>

                    <div class="ml-5 selector mb-3">
                        <AppRadioButton
                            :value="campaign.delivery.immediate"
                            :inputValue="true"
                            name="campaign-launch"
                            @input="selectLaunchTimeType"
                        >
                            <div class="label">
                                <div>Send at start time</div>
                                <div>Send message as soon as campaign starts</div>
                            </div>
                        </AppRadioButton>
                    </div>

                    <div class="ml-5 selector mb-4">
                        <AppRadioButton
                            :value="campaign.delivery.immediate"
                            :inputValue="false"
                            name="campaign-launch"
                            @input="selectLaunchTimeType"
                        >
                            <div class="label">
                                <div>Send a recurring campaign</div>
                                <div>Deliver message on a recurring basis</div>
                            </div>
                        </AppRadioButton>
                    </div>

                    <template v-if="!isDeliveredImmediately">
                        <!-- Delivery - details - scheduled - recurring -->
                        <div
                            v-if="campaign.delivery.recurring"
                            class="repeat-on-container"
                        >
                            Repeat:
                            <div class="repeat-on font-weight-bold d-flex flex-column mt-2">
                                <!-- row1 - period selection -->
                                <div class="align-items-center mb-3 flex-wrap">
                                    <AppMultiselect
                                        v-model="repeat.type"
                                        :options="repeatPeriods"
                                        :isSmall="true"
                                        :searchable="false"
                                        class="mr-4 delay-dropdown"
                                    />

                                    <!-- if weekly - select day of week -->
                                    <div v-show="repeat.type === RepeatTypes.weekly">
                                        <DayOfWeekSelector
                                            v-model="repeatDayOfWeek"
                                            class="day-of-week-selector mr-4"
                                        />
                                    </div>

                                    <!-- if monthly - select day -->
                                    <div
                                        v-show="repeat.type === RepeatTypes.monthly"
                                        class="display-flex align-items-center"
                                    >
                                        <div class="mr-2">
                                            {{ $i18n.t('generic.on') }}
                                        </div>
                                        <AppInputV3
                                            v-model.number="$v.repeat.dayOfMonth.$model"
                                            :invalid="$v.repeat.dayOfMonth.$error"
                                            :disabled="repeat.lastDayOfMonth"
                                            :min="1"
                                            :max="31"
                                            type="number"
                                            class="ml-1 day-input"
                                        />
                                        <span class="pl-1 mr-4 font-weight-normal">
                                            <sup>{{ repeat.dayOfMonth | ordinalSuffix }}</sup>
                                        </span>
                                    </div>

                                    <!-- select time - common part -->
                                    <div class="mr-2">
                                        {{ $i18n.t('generic.at') }}
                                    </div>
                                    <Dropdown
                                        :selected="[repeatTimeString]"
                                        :searchable="true"
                                        :editable="true"
                                        :items="timeStringOptions"
                                        :editValidationFunc="validateTimeStringFunc"
                                        class="d-inline-block dropdown time-string-dropdown"
                                        @selected="inputRepeatTime"
                                    />
                                </div>

                                <!-- row2 - daily/weekly -->
                                <!-- Feature is not working ATM. The code will be uncommented when BE part is ready-->
                                <!--                                <div-->
                                <!--                                    v-show="[RepeatTypes.daily, RepeatTypes.weekly].includes(repeat.type)"-->
                                <!--                                    class="align-items-center mb-3"-->
                                <!--                                >-->
                                <!--                                    <AppCheckbox-->
                                <!--                                        :value="repeat.every !== null"-->
                                <!--                                        labelRight="Every"-->
                                <!--                                        @input="checked => (checked ? (repeat.every = 1) : (repeat.every = null))"-->
                                <!--                                    />-->
                                <!--                                    <CustomInput-->
                                <!--                                        v-model.number="repeat.every"-->
                                <!--                                        :disabled="repeat.every === null"-->
                                <!--                                        class="mx-2 day-input"-->
                                <!--                                    />-->
                                <!--                                    <span v-show="repeat.type === RepeatTypes.daily">-->
                                <!--                                        {{ 'day' | plural(repeat.every) }}-->
                                <!--                                    </span>-->
                                <!--                                    <span v-show="repeat.type === RepeatTypes.weekly">-->
                                <!--                                        {{ 'week' | plural(repeat.every) }}-->
                                <!--                                    </span>-->
                                <!--                                </div>-->

                                <!-- row2 - monthly -->
                                <div
                                    v-show="repeat.type === RepeatTypes.monthly"
                                    class="mb-3"
                                >
                                    <AppCheckbox
                                        v-model="repeat.lastDayOfMonth"
                                        labelRight="Deliver on the last day of the month"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <!-- Campaign Delivery - Action based -->
                <div
                    v-if="isActionBasedDelivery"
                    class="section action-based-delivery"
                >
                    <div class="section-header">
                        <img src /><span class="img" />{{ $t('campaigns.deliveryTypes.actionBased') }}
                    </div>

                    <!-- Campaign Delivery - Action based - Trigger -->
                    <div class="font-weight-bold mb-2">User produced trigger:</div>
                    <div class="position-relative">
                        <div class="triggers px-3 pb-3 pt-2">
                            <Filters
                                :selectFilterInvalid="selectFilterInvalid"
                                :showStats="false"
                                :hideHeader="true"
                                :allowSingleFilter="true"
                                topLevelCombinator="or"
                                entityType="Trigger"
                                :conditionInstancesJson="campaign.triggerCondition.filters"
                                :conditionDefinitionsById="conditionDefinitionsById"
                                @updatedConditionInstancesJson="updatedConditionInstancesJson"
                                @onConditionsValidationError="onConditionsValidationError"
                            />
                        </div>
                    </div>
                    <!-- Campaign Delivery - Action based - launch time (delay) -->
                    <div class="font-weight-bold mt-4">Deliver the first message:</div>
                    <div class="ml-5 selector delay mt-2 mb-3">
                        <AppRadioButton
                            :value="campaign.delivery.immediate"
                            :inputValue="true"
                            name="action-based-campaign-delay"
                            @input="selectLaunchTimeType"
                        >
                            <div class="label">
                                <div>Immediately</div>
                                <div>{{ $i18n.t('campaigns.actionBasedMessageSentImmediately') }}</div>
                            </div>
                        </AppRadioButton>

                        <AppRadioButton
                            :value="campaign.delivery.immediate"
                            :inputValue="false"
                            name="action-based-campaign-delay"
                            class="mt-3"
                            @input="selectLaunchTimeType"
                        >
                            <div class="label">
                                <div class="mb-1 mb-md-0">Delayed</div>
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="mr-2">The first message will be sent</span>
                                    <CustomInput
                                        v-model="campaign.delivery.delay.amount"
                                        :disabled="isDeliveredImmediately"
                                        class="day-input mr-2"
                                    />
                                    <AppMultiselect
                                        v-model="campaign.delivery.delay.unit"
                                        :options="delayUnits"
                                        :disabled="isDeliveredImmediately"
                                        :isSmall="true"
                                        class="delay-dropdown mr-2"
                                    />
                                    after user initiates the trigger
                                </div>
                            </div>
                        </AppRadioButton>
                    </div>
                </div>

                <!-- scheduled - campaign duration -->
                <div class="section campaign-duration">
                    <div class="font-weight-bold mb-2">Campaign Duration</div>

                    <!-- campaign duration - row 1 -->
                    <div class="mb-2 d-flex align-items-center selector start-time flex-wrap">
                        <span class="pr-2">{{ $i18n.t('generic.startTime') }}</span>

                        <DateTimePicker
                            v-model="campaign.startDate"
                            :clearable="false"
                            class="datepicker mr-4"
                            type="date"
                        />
                        <span class="font-weight-bold mr-2">at</span>

                        <Dropdown
                            :selected="[campaign.startTimeString]"
                            :searchable="true"
                            :editable="true"
                            :items="timeStringOptions"
                            :editValidationFunc="validateTimeStringFunc"
                            class="d-inline-block dropdown time-string-dropdown"
                            @selected="inputStartTimeString"
                        />
                    </div>

                    <!-- campaign duration - row 2 -->
                    <div class="ml-5 d-flex align-items-center selector flex-wrap">
                        <AppCheckbox
                            v-model="campaign.hasEndTime"
                            labelRight="End time"
                            class="mr-2"
                        />

                        <DateTimePicker
                            v-model="campaign.endDate"
                            :disabled="!campaign.hasEndTime"
                            class="datepicker mr-4"
                            type="date"
                        />
                        <span class="font-weight-bold mr-2">at</span>

                        <Dropdown
                            :disabled="!campaign.hasEndTime"
                            :selected="[campaign.endTimeString]"
                            :searchable="true"
                            :editable="true"
                            :items="timeStringOptions"
                            :editValidationFunc="validateTimeStringFunc"
                            class="d-inline-block dropdown time-string-dropdown"
                            @selected="inputEndTimeString"
                        />
                    </div>
                </div>

                <!-- Action-based delivery - make user re-eligible -->
                <div
                    v-if="isActionBasedDelivery || isApiTriggeredDelivery"
                    class="section tooltip-window mb-3 d-flex align-items-center"
                >
                    <span class="mr-1 ml-5">Make user re-eligible for this campaign again in</span>
                    <CustomInput
                        v-model="campaign.delivery.userReEligibleDays"
                        class="day-input mr-1"
                    />
                    days
                    <AppTooltip :tooltipPosition="TOOLTIP_POSITION.right">
                        <template slot="label">
                            <div class="questionmark" />
                        </template>

                        <template slot="content">
                            <div class="hovered-tooltip">
                                <div
                                    v-t="'campaigns.whatMean'"
                                    class="tooltip-title font-weight-bold mb-3"
                                />
                                <div
                                    v-t="'campaigns.userProduceTriggersSelected'"
                                    class="tooltip-text"
                                />
                            </div>
                        </template>
                    </AppTooltip>
                </div>

                <!-- campaign options -->

                <!-- disabled for now -->
                <AppCheckbox
                    v-if="false"
                    v-model="campaign.delivery.useLocalTimezone"
                    :disabled="true"
                    labelRight="Send campaign to users in their local time zone"
                    class="font-weight-bold mt-3"
                />

                <AppCheckbox
                    v-model="campaign.delivery.ignoreGlobalMsgLimit"
                    :labelRight="$i18n.t('campaigns.ignoreGlobalMsgLimit')"
                    class="font-weight-bold"
                />

                <AppCheckbox
                    v-model="campaign.delivery.dryRun"
                    :labelRight="$i18n.t('campaigns.dryRun')"
                    class="font-weight-bold mt-3"
                />

                <template v-if="campaignFeatures.quietHoursRulesEnabled">
                    <div class="row mt-3">
                        <AppCheckbox
                            v-model="campaign.delivery.respectQuietHoursRules"
                            :labelRight="$i18n.t('campaigns.respectQuietHoursRules')"
                            class="font-weight-bold"
                        />
                        <img
                            v-if="!campaign.delivery.respectQuietHoursRules"
                            src="~@/assets/icons/alert-red.svg"
                            alt="alert"
                        />
                    </div>
                    <p
                        v-t="'campaigns.quietHoursNote'"
                        class="gray-note pl-5 mb-1"
                    />
                    <p
                        v-if="!campaign.delivery.respectQuietHoursRules"
                        v-t="'campaigns.notRespectingQuietHoursRules'"
                        class="small-red-note pl-5 mb-2"
                    />
                    <div
                        v-else
                        class="quiet-hours-end"
                    >
                        <AppCheckbox
                            :value="campaign.delivery.sendAfterQuietHoursEnd"
                            :labelRight="$t('campaigns.sendAfterQuietHoursEnd')"
                            class="font-weight-bold"
                            @input="v => (campaign.delivery.sendAfterQuietHoursEnd = v)"
                        />
                        <AppCheckbox
                            :value="campaign.delivery.respectUserLocalTimezone"
                            :labelRight="$t('campaigns.respectUserLocalTimezone')"
                            class="font-weight-bold mt-1"
                            @input="v => (campaign.delivery.respectUserLocalTimezone = v)"
                        />
                    </div>
                    <div class="quiet-hours-list">
                        <div
                            class="quiet-hours-list-title mt-1"
                            @click="isCollapsed = !isCollapsed"
                        >
                            <img
                                :class="{ collapsed: isCollapsed }"
                                src="~@/assets/icons/arrow-up-blue.svg"
                                alt="arrow"
                                class="arrow"
                            />
                            {{ $i18n.t('campaigns.quietHours') }}
                        </div>
                        <QuietHoursDisplayRules
                            v-if="!isCollapsed"
                            :rules="quietHoursRules"
                            class="mt-1"
                        />
                    </div>
                </template>
            </div>

            <div
                v-if="campaignFeatures.campaignTimeline.enabled && currentStepKey === Steps.delivery"
                class="panel col-md-4 delivery-campaign-timeline-container"
            >
                <div class="section">
                    <div class="section-header"><img src /><span class="img" />Campaign Timeline</div>

                    <div>Once the campaign starts, users receive message specified here:</div>

                    <DeliveryCampaignTimeline
                        :messages="messagesArray"
                        :delivery="selectedDeliveryMessage"
                        class="mt-3"
                        @updateMessageDelay="updateMessageDelay"
                    />
                </div>
            </div>

            <!-- Compose - message details -->
            <div
                v-if="currentStepKey === Steps.compose"
                class="panel col message-info display-flex flex-column align-items-center main-step-panel"
            >
                <div class="message-types d-flex p-0">
                    <div
                        v-for="(type, index) in MessageTypes"
                        :key="index"
                        :class="[
                            'd-flex message-type-container flex-column',
                            'align-items-center justify-content-center text-center',
                            { selected: selectedMessageType === type },
                            { disabled: isDisabledMessageType(type) },
                        ]"
                    >
                        <div
                            class="d-flex flex-column message-type"
                            @click="toggleType(type)"
                        >
                            <span :class="['icon', type]" />
                            <div>{{ MessageTypeLabels[type] }}</div>
                        </div>

                        <div class="d-flex checkbox-container">
                            <AppRadioButton
                                :disabled="isDisabledMessageTypeCheckbox(type)"
                                :inputValue="type"
                                :value="selectedMessageTypes[0]"
                                name="message-type"
                                @input="toggleType"
                            />
                        </div>
                    </div>
                </div>

                <NameInput
                    :disabled="!selectedMessage"
                    :value="selectedMessage && selectedMessage.name"
                    placeholder="Message Name"
                    class="mt-4"
                    @nameChanged="messageNameChanged"
                />

                <hr class="p-0" />

                <MessageDetails
                    ref="messageDetails"
                    :messageTitleMissing="messageTitleMissing"
                    :messageTextMissing="messageTextMissing"
                    :selectedType="selectedMessageType"
                    :pushOpenAction="selectedMessage.openAction"
                    :messageTitle="selectedMessage.title"
                    :message="selectedMessage.text"
                    :enhancedViewMessagePushSummary="selectedMessage.enhancedViewMessagePushSummary"
                    :deepLink="selectedMessage.deepLink"
                    :smsSenderText="selectedMessage.smsSenderText"
                    :emailSenderItems="emailSenderItems"
                    :sender="selectedMessage.sender"
                    :emailSenderName="selectedMessage.emailSenderName"
                    :bccEmails="selectedMessage.bccEmails"
                    :emailQRProperty="selectedMessage.emailQRProperty"
                    :enhancedViewTitle="selectedMessage.enhancedViewTitle"
                    :enhancedViewMessage="selectedMessage.enhancedViewMessage"
                    :enhancedViewMessageOtt="selectedMessage.enhancedViewMessageOtt"
                    :enhancedOttExpiryTimeDays="selectedMessage.enhancedOttExpiryTimeDays"
                    :backOfficeTargets="selectedMessage.backOfficeTargets"
                    :triggerEventsIDs="triggerEventsIDs"
                    :campaignFeatures="campaignFeatures"
                    :campaignType="campaign.campaignType"
                    :isActionBasedDelivery="isActionBasedDelivery"
                    class="pb-3"
                    @selectPushOpenAction="selectPushOpenAction"
                    @inputMessageTitle="inputMessageTitle"
                    @selectEmailSender="selectEmailSender"
                    @inputDeepLink="inputDeepLink"
                    @inputBccEmail="inputBccEmail"
                    @inputMessage="inputMessage"
                    @inputEnhancedViewMessagePushSummary="inputEnhancedViewMessagePushSummary"
                    @inputSMSSenderText="inputSMSSenderText"
                    @inputEnhancedViewTitle="inputEnhancedViewTitle"
                    @inputEnhancedViewMessage="inputEnhancedViewMessage"
                    @inputEnhancedViewMessageOtt="inputEnhancedViewMessageOtt"
                    @inputOttExpiryTimeDays="inputEnhancedOttExpiryTimeDays"
                    @inputBackofficeTargets="inputBackofficeTargets"
                    @inputMessageQRData="inputMessageQRData"
                />
            </div>

            <!-- Target users tab -->
            <div
                v-show="currentStepKey === Steps.targetUsers"
                class="container-fluid panel target-users-tab"
            >
                <div class="section">
                    <div
                        v-t="'campaigns.targetAudience'"
                        class="lf-subtitle"
                    />
                    <CampaignTargetTypeSelect
                        v-model="campaign.target"
                        :campaign="campaign"
                        :messageType="selectedMessage.type"
                    />
                    <TargetingStrategySelect
                        v-model="campaign.delivery.advancedTargeting"
                        :cohorts="Object.values(campaign.target)[0]"
                        :messageType="selectedMessage.type"
                        :isScheduledDelivery="isScheduledDelivery"
                        :isUnregisteredAllowed="campaign.delivery.sendToUnregisteredUsers"
                        @allowUnregistered="campaign.delivery.sendToUnregisteredUsers = $event"
                    />
                </div>
                <div
                    v-if="shouldShowCarrierAppsBlock"
                    class="section"
                >
                    <h3 class="font-weight-bold mb-2">
                        {{ $i18n.t('campaigns.carrierAppsTitle') }}
                    </h3>

                    <div
                        v-for="carrierApp in campaignCarrierApp"
                        :key="carrierApp.id"
                    >
                        <AppCheckbox
                            :value="campaign.delivery.carrierAppIds.includes(carrierApp.id)"
                            :labelRight="`${carrierApp.label} (${carrierApp.platform.label})`"
                            @input="onCarrierAppSelect(carrierApp)"
                        />
                    </div>
                </div>
            </div>

            <!-- Confirm step -->
            <div
                v-if="currentStepKey === Steps.confirm"
                class="container-fluid campaign-overview-tab panel"
            >
                <CampaignSummary
                    :campaign="campaign"
                    :triggerEvents="updatedConditionsJson"
                    :editableSteps="!isRunningCampaign"
                    :campaignFeatures="campaignFeatures"
                    @goToStep="goToStep"
                />
            </div>
        </div>

        <!-- Bottom navigation buttons -->
        <div class="my-3 mx-5 d-flex align-items-center justify-content-end">
            <div
                v-show="currentStepIndex > 0"
                class="mr-auto"
            >
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :iconType="ICON_TYPES.ARROW_LEFT"
                    :label="$i18n.t('generic.back')"
                    @click="back"
                />
            </div>
            <div
                v-if="!isRunningCampaign"
                class="d-flex py-3"
            >
                <AppButton
                    v-if="permissionsService.cepCampaignsWriteEnabled() && campaignWritePermissions"
                    data-test-id="save-btn"
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('generic.save')"
                    class="mr-3"
                    @click="runOperationIfPossible(onSave)"
                />
                <AppButton
                    v-if="currentStepKey !== Steps.confirm"
                    data-test-id="next-step-btn"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="nextButtonLabel"
                    :iconType="ICON_TYPES.ARROW_RIGHT"
                    :isIconRight="true"
                    @click="nextStep"
                />
                <AppButton
                    v-else-if="permissionsService.cepCampaignsWriteEnabled() && campaignWritePermissions"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.CHECK"
                    :label="$i18n.t('campaigns.launchCampaign')"
                    @click="onLaunchCampaignClick"
                />
            </div>
            <div
                v-else
                class="d-flex py-3"
            >
                <AppButton
                    v-if="currentStepKey !== Steps.confirm"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="nextButtonLabel"
                    :iconType="ICON_TYPES.ARROW_RIGHT"
                    @click="nextStep"
                />
                <AppButton
                    v-else-if="permissionsService.cepCampaignsWriteEnabled() && campaignWritePermissions"
                    data-test-id="save-and-update-btn"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.CHECK"
                    :label="$i18n.t('generic.saveAndUpdate')"
                    @click="saveRunningCampaign"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { intersection, uniq, difference, isEmpty, upperFirst } from 'lodash';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { between } from 'vuelidate/lib/validators';
import filters from '@/common/filters';
import NameInput from '@/__new__/features/campaigns/NameInput.vue';
import Stepper from '@/__new__/features/campaigns/Stepper.vue';
import { EntityType } from '@/components/partials/filters/FiltersRenderer.vue';
import DeliveryCampaignTimeline from '@/__new__/features/campaigns/DeliveryCampaignTimeline.vue';
import Message, { EMAIL_SENDER_OPTIONS_MAPPING } from '@/__new__/services/dno/campaigns/models/Message';
import MessageDetails from '@/__new__/features/campaigns/MessageDetails.vue';
import CampaignSummary from '@/__new__/features/campaigns/CampaignSummary.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import QuietHoursDisplayRules from '@/__new__/features/settings/quietHours/QuietHoursDisplayRules.vue';
import CustomInput from '@/__new__/features/campaigns/CustomInput.vue';
import Dropdown from '@/components/partials/Dropdown.vue';
import DayOfWeekSelector from '@/__new__/features/campaigns/DayOfWeekSelector.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import TargetingStrategySelect from '@/__new__/features/campaigns/TargetingStrategySelect.vue';
import store, { Modules } from '@/store/store';
import Actions, { State } from '@/store/mutation-types';
import DaysOfWeekLabels, { DaysOfWeekFromUI } from '@/__new__/features/campaigns/common/DaysOfWeek';
import RouteNames from '@/router/routeNames';
import Campaign, {
    CampaignType,
    CampaignFeaturesByType,
    DeliveryType,
    DeliveryTypeNames,
    Steps,
    CampaignStatuses,
    CampaignTargetType,
    DeliveryTypeFromAnyMapping,
    DeliveryTypeServerMapping,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import { Repeat, RepeatTypes } from '@/__new__/services/dno/campaigns/models/Repeat';
import Delay, { ChronoUnit } from '@/__new__/services/dno/campaigns/models/Delay';
import {
    getCampaign,
    startCampaign,
    storeCampaign,
    updateCampaign,
} from '@/__new__/services/dno/campaigns/http/campaigns';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    CAMPAIGN_PRIORITIES_LABELS,
    CAMPAIGN_PRIORITIES_LABELS_INVERT,
    extractErrorInfo,
    getErrorMessage,
} from '@/common/cepHelper';
import MessageNames, { MessageCodes } from '@/__new__/features/campaigns/common/Messages';
import { constructTimeString, getTimePickerOptions, parseTimeString, validateTimeString } from '@/common/formatting';
import AppMultiselect from '@/components/partials/inputs/AppMultiselect.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import Button from '@/common/button/Button';
import entityEditorMixin from '@/common/entityEditorMixin';
import OperationInProcessMixin from '@/components/partials/OperationInProcessMixin.vue';
import * as Sentry from '@sentry/vue';
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';
import {
    messagePropertyTypes,
    validateCampaign,
    validateCampaignMessages,
} from '@/__new__/features/campaigns/common/campaignsHelper';
import { MessageTypes, MessageTypeLabels } from '@/common/CampaignMessage';
import Filters from '@/components/partials/filters/Filters.vue';
import remoteConfigHttp from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import { ICON_TYPES } from '@/common/iconHelper';
import CampaignTargetTypeSelect from './CampaignTargetTypeSelect.vue';
import { LOGICAL_OPERATORS } from '@/common/segments';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

const campaignsHelpers = createNamespacedHelpers(Modules.campaigns);
const triggersHelpers = createNamespacedHelpers(Modules.triggers);
const staticFiltersHelpers = createNamespacedHelpers(Modules.staticFilters);

export default {
    name: 'CampaignEditor',
    filters,
    components: {
        AppMultiselectV3,
        AppHeader,
        AppButton,
        DayOfWeekSelector,
        Dropdown,
        CustomInput,
        DateTimePicker,
        AppCheckbox,
        AppRadioButton,
        NameInput,
        Stepper,
        DeliveryCampaignTimeline,
        AppMultiselect,
        AppTooltip,
        AppInputV3,
        QuietHoursDisplayRules,
        Filters,
        MessageDetails,
        CampaignSummary,
        TargetingStrategySelect,
        CampaignTargetTypeSelect,
    },
    mixins: [validationMixin, entityEditorMixin, OperationInProcessMixin],
    beforeRouteEnter(to, from, next) {
        const promises = [store.dispatch(`${Modules.templateHelpers}/${Actions.FETCH_TEMPLATE_HELPERS}`)];

        const areTriggersLoaded = !isEmpty(store.state[Modules.triggers][State.TRIGGER_DEFINITIONS_BY_ID]);
        const areFiltersLoaded = !isEmpty(store.state[Modules.segments][State.FILTER_DEFINITIONS_BY_ID]);
        const areEventsLoaded = !isEmpty(store.state[Modules.events][State.ALL_EVENTS]);
        if (!areTriggersLoaded) {
            promises.push(store.dispatch(`${Modules.triggers}/${Actions.LOAD_TRIGGER_DEFINITIONS}`));
        }
        if (!areFiltersLoaded && permissionsService.dynamicSegmentsEnabled() && isUserAllowed('DynamicSegmentsWrite')) {
            promises.push(store.dispatch(`${Modules.segments}/${Actions.LOAD_FILTER_DEFINITIONS}`));
        }
        if (!areEventsLoaded) {
            promises.push(store.dispatch(`${Modules.events}/${Actions.LOAD_ALL_EVENTS}`));
        }
        if (permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
            promises.push(store.dispatch(`${Modules.segments}/${Actions.FETCH_SEGMENTS}`));
        }
        next(page => {
            page.$Progress.start();

            if (to.params.campaignType != null) {
                page.selectCampaignType(to.params.campaignType);
            }

            if (to.params.id) {
                getCampaign(to.params.id)
                    .then(res => {
                        const campaign = Campaign.fromJson(res.data);
                        page.campaign = to.params.clone ? campaign.clone() : campaign.editableData();
                        page.currentStepKey = page.isRunningCampaign ? Steps.compose : Steps.delivery;
                    })
                    .catch(e => {
                        Sentry.captureException(e);
                        const message = page.$i18n.t('alertMessage.failedToLoadNecessaryData');
                        page.$eventBus.$emit('showAlert', { message });
                    });
            }

            Promise.all(promises).then(() => page.$Progress.finish());
        });
    },
    data() {
        return {
            EntityType,
            ICON_TYPES,
            BUTTON_TYPES,
            MessageTypes,
            MessageTypeLabels,
            Steps,
            DeliveryTypeNames,
            RepeatTypes,
            campaign: Campaign.emptyData(),
            selectedMessageTimelineIndex: 0, // todo rn timeline is not supported
            currentStepKey: Steps.delivery,
            maxStepReachedIndex: 0,
            validateTimeStringFunc: validateTimeString,
            campaignNameInvalidMessage: null,
            messageTitleMissing: false,
            messageTextMissing: false,
            emailSenderItems: null,
            // message that is selected in UI only, not added to timeline
            dummyMessage: new Message(),
            TOOLTIP_POSITION,
            entityType: EntityType.Campaign,
            isCollapsed: true,
            quietHoursRules: [],
            updatedConditionsJson: [],
            conditionsValidationError: null,
            selectFilterInvalid: null,
            permissionsService,
        };
    },
    validations() {
        let repeatMonthlyDayValidator = {};
        if (
            this.isScheduledDelivery &&
            !this.isDeliveredImmediately &&
            this.campaign.delivery.recurring &&
            this.repeat.type === RepeatTypes.monthly &&
            !this.campaign.lastDayOfMonth
        ) {
            repeatMonthlyDayValidator = {
                between: between(1, 31),
            };
        }
        return {
            repeat: {
                dayOfMonth: repeatMonthlyDayValidator,
            },
        };
    },
    computed: {
        ...campaignsHelpers.mapState([State.CAMPAIGN_CARRIER_APPS, State.CAMPAIGN_PRIORITIES]),
        ...triggersHelpers.mapState([State.TRIGGER_DEFINITIONS_BY_ID]),
        availableCampaignPriorities() {
            const deliveryType = DeliveryTypeServerMapping[this.campaign.delivery.type];
            return (
                this[State.CAMPAIGN_PRIORITIES][this.campaign.campaignType]?.[deliveryType].map(
                    p => CAMPAIGN_PRIORITIES_LABELS[p],
                ) || []
            );
        },
        conditionDefinitionsById() {
            return this[State.TRIGGER_DEFINITIONS_BY_ID];
        },
        isActionBasedDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.ActionBased;
        },
        isApiTriggeredDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.ApiTriggered;
        },
        isDeliveredImmediately() {
            return this.campaign.delivery.immediate;
        },
        isRunningCampaign() {
            return [CampaignStatuses.Running, CampaignStatuses.Upcoming].includes(this.campaign.status);
        },
        campaignCarrierApp() {
            return this[State.CAMPAIGN_CARRIER_APPS];
        },
        campaignWritePermissions() {
            switch (this.campaign.campaignType) {
                case CampaignType.MarketingCampaign:
                    return isUserAllowed('MarketingCampaignsWrite');
                case CampaignType.ServiceNotificationCampaign:
                    return isUserAllowed('ServiceCampaignsWrite');
                case CampaignType.BackOfficeCampaign:
                    return isUserAllowed('BackofficeCampaignsWrite');
                default:
                    return false;
            }
        },
        campaignDeliveryTypes() {
            switch (this.campaign.campaignType) {
                case CampaignType.MarketingCampaign:
                    return this.getDeliveryPermissions('MarketingCampaign');
                case CampaignType.ServiceNotificationCampaign:
                    return this.getDeliveryPermissions('ServiceNotificationCampaign');
                case CampaignType.BackOfficeCampaign:
                    return this.getDeliveryPermissions('BackofficeCampaign');
                default:
                    return [];
            }
        },
        filteredDeliveryTypes() {
            return [
                {
                    name: DeliveryTypeNames.Scheduled,
                    label: this.$t('campaigns.deliveryTypes.scheduled'),
                    description: this.$t('campaigns.deliveryTypes.scheduledDescription'),
                },
                {
                    name: DeliveryTypeNames.ActionBased,
                    label: this.$t('campaigns.deliveryTypes.actionBased'),
                    description: this.$t('campaigns.deliveryTypes.actionBasedDescription'),
                },
                {
                    name: DeliveryTypeNames.ApiTriggered,
                    label: this.$t('campaigns.deliveryTypes.ApiTriggered'),
                    description: this.$t('campaigns.deliveryTypes.ApiTriggeredDescription'),
                },
            ].filter(deliveryType => this.campaignDeliveryTypes.includes(deliveryType.name));
        },
        campaignFeatures() {
            return CampaignFeaturesByType[this.campaign.campaignType];
        },
        isMarketingCampaign() {
            return this.campaign.campaignType === CampaignType.MarketingCampaign;
        },
        disabledMessageTypes() {
            const enabledTypes = intersection(
                this.campaignFeatures.messageTypes,
                permissionsService.messageTypesAvailable(),
                Object.values(MessageTypes).filter(type => isViewEnabled(`CampaignMessageType${upperFirst(type)}`)),
            );
            return difference(Object.values(MessageTypes), enabledTypes);
        },
        isScheduledDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.Scheduled;
        },
        repeatPeriods() {
            return [RepeatTypes.daily, RepeatTypes.weekly, RepeatTypes.monthly];
        },
        repeat() {
            return this.campaign.delivery.repeat;
        },
        repeatTimeString() {
            return constructTimeString(this.repeat.hours, this.repeat.minutes);
        },
        repeatDayOfWeek: {
            get() {
                return DaysOfWeekLabels[this.repeat.dayOfWeek];
            },
            set(value) {
                this.repeat.dayOfWeek = DaysOfWeekFromUI[value];
            },
        },
        messagesArray() {
            // flatten messages array, [{id: msg, id2: msg2}] => [[msg, msg2]]
            return this.campaign.messages.map(msgs => Object.values(msgs));
        },
        shouldShowCarrierAppsBlock() {
            return this.isMarketingCampaign && this.selectedMessageTypes.includes(MessageTypes.PUSH);
        },
        selectedMessagesById() {
            return this.campaign.messages[this.selectedMessageTimelineIndex] ?? [];
        },
        selectedMessagesArray() {
            return Object.values(this.selectedMessagesById);
        },
        selectedMessageTypes() {
            return this.selectedMessagesArray.map(msg => msg.type);
        },
        selectedMessage() {
            return this.selectedMessagesById[this.campaign.selectedMessageUniqueId] || this.dummyMessage;
        },
        selectedMessageType() {
            return this.selectedMessage.type;
        },
        timeStringOptions: () => getTimePickerOptions(),
        steps() {
            return this.campaignFeatures.steps.enabled
                .filter(step => !this.isRunningCampaign || [Steps.compose, Steps.confirm].includes(step))
                .map(step => ({ key: step, title: step }));
        },
        delayUnits() {
            return [ChronoUnit.minute, ChronoUnit.hour, ChronoUnit.day];
        },
        currentStepIndex() {
            return this.steps.findIndex(step => step.key === this.currentStepKey);
        },
        nextButtonLabel() {
            return (this.steps[this.currentStepIndex + 1] || {}).title;
        },
        selectedDeliveryMessage() {
            if (this.isActionBasedDelivery) {
                return 'Action-based delivery';
            }
            if (this.isApiTriggeredDelivery) {
                return 'API triggered delivery';
            }
            if (this.isScheduledDelivery && !this.isDeliveredImmediately) {
                return 'Scheduled delivery: recurring';
            }
            if (this.isScheduledDelivery && this.isDeliveredImmediately) {
                return 'Scheduled delivery: at start time';
            }
            return null;
        },
        triggerEventsIDs() {
            return uniq(this.updatedConditionsJson.map(condition => condition.filterDefinitionId));
        },
    },
    watch: {
        messagesArray: {
            handler() {
                // reset checked carrier app values because user can select the values, go back to the
                // 1 step and choose another message type
                if (!this.shouldShowCarrierAppsBlock) {
                    this.campaign.delivery.carrierAppIds = [];
                }
            },
        },
    },
    created() {
        this.$withLoadingSpinner(async () => {
            this.addWatcher('campaign');
            this.selectCampaignType(CampaignType.MarketingCampaign);

            const promises = [getQuietHours(), this.fetchEmailSenderItems(), this[Actions.FETCH_CAMPAIGN_PRIORITIES]()];

            if (this.isMarketingCampaign) {
                // todo this conflicts with next() hook where the real type is set
                promises.push(this[Actions.FETCH_CAMPAIGN_CARRIER_APPS]());
            }

            try {
                const [qh] = await Promise.all(promises);
                this.quietHoursRules =
                    qh.data.length && Object.prototype.hasOwnProperty.call(qh.data[0], 'rules') ? qh.data[0].rules : [];
                this.selectDelivery(this.campaign.delivery.type);
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadQuietHoursData'),
                });
            }
            this.addMessage();
        });
    },
    methods: {
        ...campaignsHelpers.mapActions([Actions.FETCH_CAMPAIGN_CARRIER_APPS, Actions.FETCH_CAMPAIGN_PRIORITIES]),
        ...triggersHelpers.mapActions([Actions.LOAD_TRIGGER_DEFINITIONS]),
        ...staticFiltersHelpers.mapActions([Actions.FETCH_STATIC_FILTER_COUNT_STATS]),
        getDeliveryPermissions(campaignType) {
            const viewConfigEnabledTypes = Object.keys(DeliveryTypeNames)
                .filter(deliveryType => isViewEnabled(`${campaignType}Delivery${deliveryType}`))
                .map(type => DeliveryTypeNames[type]);
            const operatorConfigEnabledTypes = permissionsService
                .availableCampaignDeliveryTypes(campaignType)
                .map(type => DeliveryTypeFromAnyMapping[type]);
            return intersection(viewConfigEnabledTypes, operatorConfigEnabledTypes);
        },
        updatedConditionInstancesJson(json) {
            this.updatedConditionsJson = json;
            this.selectFilterInvalid = false;
        },
        onConditionsValidationError(conditionsValidationError) {
            this.conditionsValidationError = conditionsValidationError;
        },
        onCarrierAppSelect(carrierApp) {
            this.$eventBus.$emit('closeAllAlerts');
            const { carrierAppIds } = this.campaign.delivery;
            const isAlreadyChecked = carrierAppIds.includes(carrierApp.id);
            if (isAlreadyChecked) {
                this.campaign.delivery.carrierAppIds = carrierAppIds.filter(id => id !== carrierApp.id);
            } else {
                this.campaign.delivery.carrierAppIds = [...carrierAppIds, carrierApp.id];
            }
        },
        isDisabledMessageType(type) {
            return this.disabledMessageTypes.includes(type);
        },
        isDisabledMessageTypeCheckbox(type) {
            // disallow unselecting type if this is the only one type selected
            return (
                this.disabledMessageTypes.includes(type) ||
                (this.selectedMessageTypes.length <= 1 && this.selectedMessageTypes.includes(type))
            );
        },
        back() {
            this.currentStepKey = this.steps[this.currentStepIndex - 1].key;
        },
        nextStep() {
            if (this.currentStepIndex < this.steps.length - 1) {
                if (!this.validateStepAndShowError()) {
                    this.currentStepKey = this.steps[this.currentStepIndex + 1].key;
                    if (this.currentStepIndex > this.maxStepReachedIndex) {
                        this.maxStepReachedIndex = this.currentStepIndex;
                    }
                }
            }
        },
        goToStep(stepIndex) {
            this.currentStepKey = this.steps[stepIndex].key;
        },
        validateStepAndShowError() {
            // special handling for compose step in order to highlight inputs with errors properly
            if (this.currentStepKey === Steps.compose && this.validateMessages(this.messagesArray)) {
                // touch children to trigger highlighting if there are non-dirty $v inputs
                this.$refs.messageDetails.$v.$touch();
            }

            return this.validateCampaign(this.constructCampaign(), this.currentStepKey);
        },
        validateMessages(messages) {
            const messagesValidationResult = validateCampaignMessages(messages, this.campaign.campaignType);
            if (!messagesValidationResult) {
                return null;
            }
            this.selectMessage(messagesValidationResult.messageWithErrorId);

            if (messagesValidationResult.propertyType === messagePropertyTypes.text) {
                this.messageTextMissing = true;
            } else if (messagesValidationResult.propertyType === messagePropertyTypes.title) {
                this.messageTitleMissing = true;
            }

            return messagesValidationResult.alertMessage;
        },
        campaignNameChanged(name) {
            this.campaign.name = name;
            this.$eventBus.$emit('closeAllAlerts');
            this.toggleCampaignNameError();
        },
        addMessage() {
            if (!this.campaign.selectedMessageUniqueId) {
                const allowedType = Object.values(MessageTypes).find(
                    messageType => !this.disabledMessageTypes.includes(messageType),
                );
                const newMessage = new Message('', allowedType);
                Vue.set(this.campaign.messages, [this.selectedMessageTimelineIndex], {
                    [newMessage.uniqueId]: newMessage,
                });
                this.selectMessage(newMessage.uniqueId);
            }
        },
        selectMessage(uniqueId) {
            this.campaign.selectedMessageUniqueId = uniqueId;
        },
        messageNameChanged(name) {
            // mutate all messages because name field is shared
            this.selectedMessagesArray.forEach(msg => {
                const mutatedMessage = msg.withName(name);
                Vue.set(this.selectedMessagesById, msg.uniqueId, mutatedMessage);
            });
            this.dummyMessage = this.dummyMessage.withName(name);
        },
        toggleType(type) {
            if (type !== this.selectedMessageType) {
                // Reset cohorts since selected cohorts may not be available for the new message type
                this.campaign.target = {
                    [CampaignTargetType.CohortTarget]: {
                        whitelist: { logical_operator: LOGICAL_OPERATORS.or },
                        blacklist: { logical_operator: LOGICAL_OPERATORS.or },
                    },
                };
            }
            const newMessage = this.dummyMessage.withType(type);
            newMessage.newUniqueId();
            Vue.set(this.campaign.messages, this.selectedMessageTimelineIndex, { [newMessage.uniqueId]: newMessage });
            this.selectMessage(newMessage.uniqueId);
        },
        selectPushOpenAction(pushOpenAction) {
            const mutatedMessage = this.selectedMessage.withOpenAction(pushOpenAction);
            this.mutateMessage(mutatedMessage);
        },
        mutateMessage(mutatedMessage) {
            Vue.set(this.selectedMessagesById, this.campaign.selectedMessageUniqueId, mutatedMessage);
        },
        inputMessageTitle(messageTitle) {
            const mutatedMessage = this.selectedMessage.withTitle(messageTitle);
            this.mutateMessage(mutatedMessage);
            // todo move to vuex
            this.messageTitleMissing = false;
            this.$eventBus.$emit('closeAllAlerts');
        },
        inputDeepLink(deepLink) {
            const mutatedMessage = this.selectedMessage.withDeepLink(deepLink);
            this.mutateMessage(mutatedMessage);
        },
        async fetchEmailSenderItems() {
            try {
                this.$Progress.start();
                const response = await remoteConfigHttp.getRemoteConfig();

                this.emailSenderItems = response.data.data[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_ADDRESS_AND_NAME]?.value
                    ? JSON.parse(response.data.data[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_ADDRESS_AND_NAME].value)
                    : [];

                if (this.emailSenderItems.length && !this.isEditing) {
                    this.selectEmailSender(this.emailSenderItems[0]);
                }
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: getErrorMessage(error),
                });
            } finally {
                this.$Progress.finish();
            }
        },
        selectEmailSender(sender) {
            const mutatedMessageEmailSender = this.selectedMessage.withEmailSender(
                sender[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_ADDRESS],
            );
            this.mutateMessage(mutatedMessageEmailSender);

            const mutatedMessageEmailSenderName = this.selectedMessage.withEmailSenderName(
                sender[EMAIL_SENDER_OPTIONS_MAPPING.SENDER_NAME] || null,
            );
            this.mutateMessage(mutatedMessageEmailSenderName);

            this.$eventBus.$emit('closeAllAlerts');
        },
        inputBccEmail(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withBccEmails(input);
            this.mutateMessage(mutatedMessage);
        },
        inputMessage(message) {
            const mutatedMessage = this.selectedMessage.withText(message);
            this.mutateMessage(mutatedMessage);
            // todo move to vuex
            this.messageTextMissing = false;
            this.$eventBus.$emit('closeAllAlerts');
        },
        inputEnhancedViewMessagePushSummary(message) {
            const mutatedMessage = this.selectedMessage.withEnhancedViewMessagePushSummary(message);
            this.mutateMessage(mutatedMessage);
        },
        inputSMSSenderText(smsSenderText) {
            this.$eventBus.$emit('closeAllAlerts'); // not like this
            const mutatedMessage = this.selectedMessage.withSMSSenderText(smsSenderText);
            this.mutateMessage(mutatedMessage);
        },
        inputEnhancedViewTitle(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withEnhancedViewTitle(input);
            this.mutateMessage(mutatedMessage);
        },
        inputEnhancedViewMessage(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withEnhancedViewMessage(input);
            this.mutateMessage(mutatedMessage);
        },
        inputEnhancedViewMessageOtt(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withEnhancedViewMessageOtt(input);
            this.mutateMessage(mutatedMessage);
        },
        inputEnhancedOttExpiryTimeDays(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withEnhancedOttExpiryTimeDays(input);
            this.mutateMessage(mutatedMessage);
        },
        inputBackofficeTargets(input) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withBackOfficeTargets(input);
            this.mutateMessage(mutatedMessage);
        },
        inputMessageQRData(fieldName) {
            this.$eventBus.$emit('closeAllAlerts');
            const mutatedMessage = this.selectedMessage.withEmailQRProperty(fieldName);
            this.mutateMessage(mutatedMessage);
        },
        selectDelivery(type) {
            if (this.campaign.delivery.type !== type) {
                this.campaign.delivery.sendToUnregisteredUsers = false;
                this.campaign.delivery.type = type;
            }
            [this.campaign.selectedPriority] = this.availableCampaignPriorities;
        },
        selectLaunchTimeType(type) {
            this.campaign.delivery.immediate = type;
            if (this.isScheduledDelivery) {
                this.campaign.delivery.recurring = !type;
            }
        },
        inputRepeatTime(string) {
            const time = parseTimeString(string);
            this.campaign.delivery.repeat.hours = time.hours();
            this.campaign.delivery.repeat.minutes = time.minutes();
        },
        inputStartTimeString(startTimeString) {
            this.campaign.startTimeString = startTimeString;
        },
        inputEndTimeString(endTimeString) {
            this.campaign.endTimeString = endTimeString;
        },
        selectCampaignType(campaignType) {
            this.campaign.campaignType = campaignType;
            this.campaign.delivery.ignoreGlobalMsgLimit = this.campaignFeatures.ignoreGlobalMsgLimitDefault;
            if (!this.campaignDeliveryTypes.includes(this.campaign.delivery.type)) {
                this.selectDelivery(this.campaignDeliveryTypes[0]);
            }
        },
        updateMessageDelay(uniqueId, delay) {
            Vue.set(this.campaign.messages, uniqueId, [this.campaign.messages[uniqueId].withDelay(delay)]);
        },
        constructCampaign() {
            let endTime = null;
            let repeat;
            let delay;

            // this doesn't make sense, figure out why and refactor
            const startTime = Campaign.constructDate(this.campaign.startDate, this.campaign.startTimeString);

            if (this.campaign.hasEndTime) {
                endTime = Campaign.constructDate(this.campaign.endDate, this.campaign.endTimeString);
            }

            if (!this.isDeliveredImmediately) {
                if (this.isScheduledDelivery) {
                    repeat = new Repeat(
                        this.repeat.type,
                        this.repeat.hours,
                        this.repeat.minutes,
                        [RepeatTypes.weekly, RepeatTypes.daily].includes(this.repeat.type) ? this.repeat.every : null,
                        this.repeat.type === RepeatTypes.weekly ? this.repeat.dayOfWeek : null,
                        this.repeat.type === RepeatTypes.monthly ? this.repeat.dayOfMonth : null,
                        this.repeat.lastDayOfMonth,
                    );
                } else {
                    delay = new Delay(this.campaign.delivery.delay.amount, this.campaign.delivery.delay.unit);
                }
            }

            const deliveryType = new DeliveryType(
                this.campaign.delivery.type,
                this.campaign.delivery.ignoreGlobalMsgLimit,
                // ToDo: uncomment after server part implemented
                // this.isScheduledDelivery
                //     ? this.campaign.delivery.useLocalTimezone
                //     : null,
                this.campaign.delivery.useLocalTimezone,
                this.isDeliveredImmediately,
                !this.isDeliveredImmediately ? this.campaign.delivery.recurring : false,
                repeat,
                delay,
                (this.isActionBasedDelivery || this.isApiTriggeredDelivery) &&
                Number.isInteger(parseInt(this.campaign.delivery.userReEligibleDays, 10))
                    ? parseInt(this.campaign.delivery.userReEligibleDays, 10)
                    : null,
                this.campaign.delivery.dryRun,
                this.campaign.delivery.respectQuietHoursRules,
                this.campaign.delivery.sendToUnregisteredUsers,
                this.campaign.delivery.carrierAppIds,
                this.campaign.delivery.advancedTargeting,
                this.campaign.delivery.respectQuietHoursRules ? this.campaign.delivery.sendAfterQuietHoursEnd : false,
                this.campaign.delivery.respectQuietHoursRules ? this.campaign.delivery.respectUserLocalTimezone : false,
            );

            const triggerCondition = this.updatedConditionsJson.length ? { filters: this.updatedConditionsJson } : null;

            return new Campaign(
                this.isEditing ? this.$route.params.id : undefined,
                this.campaign.name,
                moment().unix(),
                startTime,
                endTime,
                deliveryType,
                this.campaign.target,
                this.messagesArray,
                null,
                null,
                this.campaign.campaignType,
                triggerCondition,
                null,
                null,
                this.campaign.version,
                Number(CAMPAIGN_PRIORITIES_LABELS_INVERT[this.campaign.selectedPriority]),
            );
        },
        toggleCampaignNameError(e) {
            const errorInfo = e ? extractErrorInfo(e) : null;
            if (
                errorInfo === MessageCodes[MessageNames.CAMPAIGN_NAME_EMPTY].toString() ||
                errorInfo === MessageCodes[MessageNames.CAMPAIGN_NAME_INVALID].toString()
            ) {
                this.campaignNameInvalidMessage = getErrorMessage(e);
            } else {
                this.campaignNameInvalidMessage = null;
            }
        },
        saveRunningCampaign() {
            const confirmButton = new Button({ label: this.$i18n.t('generic.proceed') });
            this.$showWarningAlert({
                message: this.$i18n.t('campaigns.confirmUpdateRunningCampaign'),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === confirmButton.id) {
                    this.runOperationIfPossible(this.onSave);
                }
            });
        },
        async onSave() {
            const campaign = this.constructCampaign();
            if (this.isActionBasedDelivery) {
                let isTriggerValid = true;

                if (this.updatedConditionsJson.length === 0) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('triggers.errors.chooseAtLeastOneTrigger'),
                    });
                    this.selectFilterInvalid = true;
                    isTriggerValid = false;
                }
                if (this.conditionsValidationError) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.conditionsValidationError,
                    });
                    this.selectFilterInvalid = true;
                    isTriggerValid = false;
                }
                if (!isTriggerValid) {
                    return;
                }
            }

            const errorMessage = this.validateCampaign(campaign, null, true);
            if (!errorMessage) {
                try {
                    this.$Progress.start();
                    if (this.isEditing) {
                        await updateCampaign(campaign);
                    } else {
                        await storeCampaign(campaign);
                    }
                    this.entityEditorMixin.successfullySaved = true;

                    this.$Progress.finish();
                    this.$router.push({
                        name: RouteNames.CAMPAIGNS_VIEW,
                        params: { companyId: this.$route.params.companyId },
                    });
                } catch (e) {
                    this.toggleCampaignNameError(e);
                    this.$Progress.fail();
                    this.$eventBus.$emit('showAlert', {
                        message: getErrorMessage(e),
                    });
                }
            }
        },
        onLaunchCampaignClick() {
            const campaign = this.constructCampaign();
            const errorMessage = this.validateCampaign(campaign, this.currentStepKey, true);

            if (!errorMessage) {
                const messages = [];

                if (this.campaign.lastRunTimestamp > moment.utc().subtract(24, 'h').unix()) {
                    messages.push(this.$t('campaigns.confirmStartRecentlyStarted'));
                }

                if (moment.utc(this.campaign.startDate).isBefore(moment.utc())) {
                    messages.push(this.$t('campaigns.confirmStartWithStartDateInThePast'));
                }

                if (messages.length > 0) {
                    this.$alert(this.$t('campaigns.areYouSureLaunchTheCampaign'), {
                        type: ALERT_TYPES.warning,
                        description: messages.join('\n'),
                        buttons: [
                            new Button({
                                label: this.$i18n.t('generic.proceed'),
                                handler: () => {
                                    this.runOperationIfPossible(() => this.launchCampaign(campaign));
                                },
                            }),
                        ],
                    });
                    return;
                }

                this.runOperationIfPossible(() => this.launchCampaign(campaign));
            }
        },
        validateCampaign(campaign, currentStepKey, isSaving) {
            if (this.isActionBasedDelivery) {
                if (this.updatedConditionsJson.length === 0) {
                    this.selectFilterInvalid = true;
                }

                if (this.conditionsValidationError) {
                    this.selectFilterInvalid = true;
                }
            }
            const errorMessage = validateCampaign(campaign, currentStepKey, isSaving);
            if (errorMessage) {
                this.$eventBus.$emit('showAlert', {
                    message: errorMessage,
                });
            }
            return errorMessage;
        },
        async launchCampaign(campaign) {
            try {
                this.$Progress.start();
                let campaignId;
                if (this.isEditing) {
                    campaignId = campaign.id;
                    await updateCampaign(campaign);
                } else {
                    campaignId = (await storeCampaign(campaign)).data.id;
                }
                this.entityEditorMixin.successfullySaved = true;
                await startCampaign(campaignId);

                this.$Progress.finish();

                this.$router.push({
                    name: RouteNames.CAMPAIGNS_VIEW,
                    params: { companyId: this.$route.params.companyId },
                });
            } catch (e) {
                this.toggleCampaignNameError(e);
                this.$Progress.fail();
                const message = getErrorMessage(e);
                this.$eventBus.$emit('showAlert', { message });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_icons.scss';
@import '~@/assets/scss/_colors.scss';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_mixins.scss';
@import '~@/assets/scss/_typography.scss';
@import '~@/assets/scss/_outlines.scss';
@import '~@/assets/scss/_animations.scss';
@import '~@/assets/scss/_z-indexes.scss';

$icon-path: '~@/assets/icons/';

// 20rem (320px) is total height for the steps and header bars at the top
// and buttons bar at the bottom of the page
$fixed-height: 20rem;

.panel {
    margin: 4px 4px 0 4px;
}

hr {
    border-top: 1px solid $dirty-white;
}

.triggers {
    background: $dirty-white;
}

.page-header {
    padding: 13px 20px 13px 24px;

    ::v-deep .name-input-wrapper {
        font-size: 23px;
    }

    @include campaignStatus($icon-path);

    .status {
        width: 130px;
        font-size: 12px;
    }
}

.section {
    font-size: 14px;
    color: $gray60;
    background-color: $white;

    ::v-deep .section-header {
        display: flex;
        align-items: center;

        padding-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
        color: $navy;

        img + span.img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
    }

    .selector {
        font-size: 14px;

        .label {
            margin-left: 18px;

            div {
                line-height: 20px;
            }

            div:first-child {
                font-weight: bold;
            }
        }
    }
}

.campaign-delivery {
    padding: 24px 31px;

    img:first-child + span.img:before {
        content: url($icon-path + $rocket);
    }

    .delay-dropdown {
        width: 200px;
    }

    .dropdown {
        @include custom-width-dropdown(80px);
        @include custom-height-dropdown(32px);

        &.day-period-dropdown {
            width: 80px;
        }

        &.time-string-dropdown {
            width: 150px;
        }
    }

    .day-input {
        width: 60px;
        text-align: right;
    }

    .campaign-duration {
        margin-bottom: 32px;

        .start-time {
            margin-left: 66px;
        }
    }

    .datepicker {
        @include custom-height-datepicker(32px);
    }

    .scheduled-delivery {
        img:first-child + span.img:before {
            content: url($icon-path + $time);
        }

        .day-of-week-selector {
            height: 32px;
        }

        .send-on {
            margin-left: 66px;
        }

        .repeat-on-container {
            margin-left: 66px;

            .repeat-on > div {
                display: flex;
            }
        }
    }

    .action-based-delivery {
        &.disabled {
            pointer-events: none;

            div,
            label {
                opacity: 0.5;
            }
        }

        img:first-of-type + span.img:before {
            content: url($icon-path + $action);
        }

        .placeholder-panel {
            opacity: 1;
            height: 40px;
            line-height: 40px;

            padding: 0 25px;
            margin-left: -26px;
            margin-right: -26px;

            color: $navy;
            font-size: 14px;
            background-color: $gray5;

            &:before {
                margin-top: 7px;
                margin-right: 10px;
                content: url($icon-path + $pie-chart-icon);
            }
        }

        // fix single line with inputs
        .selector.delay label:last-of-type > .label > div:last-of-type {
            margin-top: -6px;
        }
    }
}

.delivery-campaign-timeline-container {
    padding: 26px 35px 25px 30px;

    img:first-child + span.img:before {
        content: url($icon-path + $paper-plane);
    }
}

.steps {
    margin-top: -4px;
    height: 73px;
}
.main-step-panel > * {
    width: 80%;
}

.main-step-panel.message-info > * {
    width: 60%;
}

.message-info {
    max-height: calc(100vh - #{$fixed-height});
    overflow-y: auto;

    & > * {
        padding: 0 24px;
    }

    ::v-deep .name-input-wrapper {
        font-size: 20px;
    }

    .message-types {
        border: solid 1px $dirty-white;
        border-top: 0;

        .message-type-container {
            flex-grow: 1;
            flex-basis: 0;

            &.selected .message-type {
                background-color: transparent;
                border-top-color: $orange;

                div {
                    color: $orange;
                }
            }

            @include messageIcons($icon-path, 40px);

            &:nth-child(n + 2) {
                border-left: 1px solid $dirty-white;
            }

            &.disabled:not(.selected) {
                pointer-events: none;

                span.icon {
                    opacity: 0.33;
                }

                div {
                    color: $gray30;
                }
            }

            .message-type {
                align-items: center;
                width: 100%;
                padding-top: 12px;
                padding-bottom: 18px;

                background-color: $dirty-white;
                border-top: 4px solid $dirty-white;
                border-bottom: 1px solid $dirty-white;
                cursor: pointer;

                div {
                    margin-top: 7px;
                    font-size: 12px;
                    color: $navy;
                }
            }

            .checkbox-container {
                padding: 10px;
            }
        }
    }
}

.target-users-tab {
    .section {
        max-width: 50rem;
        margin: 2.5rem auto;
    }
}

.campaign-overview-tab {
    height: calc(100vh - #{$fixed-height});
    overflow-y: auto;
}

.tooltip-window {
    @include messageIcons($icon-path, 22px);

    .hovered-tooltip {
        width: 480px;
    }
}

.tooltip-title {
    font-size: 14px;
    color: $gray60;
}

.tooltip-text {
    font-size: 13px;
    color: $gray30;
}
.quiet-hours-end {
    margin-left: 3rem;
}
.quiet-hours-list {
    margin-left: 4rem;

    .quiet-hours-list-title {
        position: relative;
        cursor: pointer;
        color: $blue;
        line-height: 1.625rem;
        font-size: 0.875rem;
        font-weight: 600;

        .arrow {
            transition: $fast-animation-time ease-in-out;

            &.collapsed {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
