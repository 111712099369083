























// components
import Filters from '@/components/partials/filters/Filters.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
// helpers
import { isEmpty } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { EntityType as entityType, EntityTypeRouteName } from '@/components/entityTracker/entityTracker';
import { copyToClipboard } from '@/common/utils';
import i18n from '@/i18n';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export const EntityType = {
    Filter: 'filter',
    Trigger: 'trigger',
    Campaign: 'campaign',
};

export default {
    name: 'FiltersRenderer',
    components: { AppOverviewBlock, Filters },
    props: {
        title: {
            type: String,
            default: i18n.t('generic.filters'),
        },
        popupTitle: {
            type: String,
            default: '',
        },
        isDynamicSegment: {
            type: Boolean,
            default: false,
        },
        maxFiltersDisplayed: {
            type: Number,
            default: 5,
        },
        filters: {
            type: Array,
            default: () => [],
        },
        entityType: {
            type: String,
            default: EntityType.Filter,
        },
        topLevelCombinator: {
            type: String,
            default: 'and', // 'or' is possible, this is just a label
        },
        conditionDefinitionsById: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            copyToClipboard,
            ICON_TYPES,
            filtersComponent: null,
        };
    },
    computed: {
        uiComponentInstances(): any {
            return this.filtersComponent?.conditionInstances;
        },
        groupedFilters(): CollapsibleListItem[] {
            return (
                this.uiComponentInstances
                    ?.map((el: any) =>
                        Object.entries(el).map(([id, conditions]: any) => {
                            const shouldShowNavigateIcon = this.isDynamicSegment
                                ? isUserAllowed('TableConfigurationsRead')
                                : isUserAllowed('EventsRead');

                            return {
                                isCollapsed: false,
                                name: this.conditionDefinitionsById[id]?.name,
                                idToCopy: id,
                                ...(shouldShowNavigateIcon && { navigateToId: id }),
                                entityType: this.isDynamicSegment ? entityType.DWHConfig : entityType.CustomEvent,
                                rows: conditions
                                    .map((condition: any, conditionIndex: number) =>
                                        (isEmpty(condition) ? [[]] : condition).map(
                                            (conditionProperty: any, propertyIndex: number) => ({
                                                rowCombinator: conditionIndex !== 0 && propertyIndex === 0 ? 'or' : '',
                                                name: isEmpty(conditionProperty)
                                                    ? this.$t('events.noConditionsSelected')
                                                    : `${conditionProperty.propertyDefinition.label} ${
                                                          conditionProperty.uiComponentClassInstance.toString() || ''
                                                      }`,
                                            }),
                                        ),
                                    )
                                    .flat(),
                            };
                        }),
                    )
                    .flat() || []
            );
        },
    },
    mounted() {
        this.filtersComponent = this.$refs.filtersComponent;
    },
    methods: {
        navigateToEntity({ entityType: entity, id }): void {
            this.$router.push({
                name: EntityTypeRouteName[entity],
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
