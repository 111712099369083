import {
    ACTION,
    EVENT_CATEGORY,
    EventCategoryCounters,
    SEARCH_INDEX,
    STATUS,
    DeviceStatusMsisdn as DeviceStatusMsisdnDno,
    QodMsisdn as QodMsisdnDno,
    QueryMsisdnResponse,
    RegisteredEntityItem,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    FILE_UPLOAD_STATUS,
    DeviceStatusMsisdn as DeviceStatusMsisdnPortal,
    QodMsisdn as QodMsisdnPortal,
    UploadedFileDetails,
    Msisdn,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import { AxiosResponse } from 'axios';
import {
    getEntityDetailsByName,
    getRegisteredEntities,
    queryMsisdn,
} from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { DEVICE_LINE_AUTH_API_TYPE } from '../ossdevedge/models/DeveloperLineAuthorizationDno';
import { capitalize } from 'lodash';

export interface QodMsisdnsEntitiesAndResponse {
    entities: QodMsisdnPortal[] | DeviceStatusMsisdnPortal[];
    response: AxiosResponse<QueryMsisdnResponse>;
}

export const getQodMsisdns = async (
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): Promise<QodMsisdnsEntitiesAndResponse> => {
    const response = await queryMsisdn(apiType, searchIndex, searchString);
    const entities = convertMsisdnDnoToPortal(apiType, response.data.customer_lines);
    return {
        entities,
        response,
    };
};

const convertMsisdnDnoToPortal = (
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    records: QodMsisdnDno[] | DeviceStatusMsisdnDno[],
): QodMsisdnPortal[] | DeviceStatusMsisdnPortal[] => {
    switch (apiType) {
        case DEVICE_LINE_AUTH_API_TYPE.QOD:
            return convertQoDMsisdnDnoToPortal(records as QodMsisdnDno[]);
        case DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS:
            return convertDeviceStatusMsisdnDnoToPortal(records as DeviceStatusMsisdnDno[]);
        default:
            throw new Error(`Unrecognized API type=${apiType}`);
    }
};

const convertQoDMsisdnDnoToPortal = (records: QodMsisdnDno[]): QodMsisdnPortal[] => {
    return records.map(record => ({
        ...convertBaseMsisdnDnoToPortal(record),
        customerName: record.customer_name,
        qodProfiles: record.qod_profiles?.join(';') ?? '',
    }));
};

const convertDeviceStatusMsisdnDnoToPortal = (records: DeviceStatusMsisdnDno[]): DeviceStatusMsisdnPortal[] => {
    return records.map(record => ({
        ...convertBaseMsisdnDnoToPortal(record),
        approverName: record.approver_name,
        serviceIds: record.service_ids?.join(';') ?? '',
    }));
};

/**
 * Utility function to convert common properties in both QodMsisdnDno / DeviceStatusMsisdnDno to Portal format
 * @param record
 * @returns
 */
const convertBaseMsisdnDnoToPortal = (record: QodMsisdnDno | DeviceStatusMsisdnDno): Msisdn => {
    return {
        msisdn: record.msisdn,
        appClientId: record.app_client_id,
        channel: record.channel,
        customerBan: record.customer_ban,
        payingCustomerId: record.paying_customer_id,
        commercialOfferName: record.commercial_offer_name,
        action: record.action,
        actionStr: convertActionToString(record.action),
        status: record.naas_provisioning_status,
        statusStr: convertNaasProvisioningStatusToString(record.naas_provisioning_status),
        lastUpdatedAt: record.update_timestamp,
        lastUpdatedBy: record.create_portal_id,
        bulkUploadId: record.bulk_upload_id,
    };
};

const convertActionToString = (action: ACTION): string => {
    switch (action) {
        case ACTION.ADD:
            return i18n.t('generic.add').toString();
        case ACTION.REMOVE:
            return i18n.t('generic.remove').toString();
        default:
            return '';
    }
};

/**
 * Gets list of all possible action strings
 * Why? Iterating over a large number of records to determine all options is expensive
 */
export const getAllActionStrings = (): string[] => [
    convertActionToString(ACTION.ADD),
    convertActionToString(ACTION.REMOVE),
];

const convertNaasProvisioningStatusToString = (status: STATUS): string => {
    switch (status) {
        case STATUS.ACCEPTED_RECORD:
            return i18n.t('qodNumberManagement.acceptedRecord').toString();
        case STATUS.NAAS_REJECT_REQUEST:
            return i18n.t('qodNumberManagement.rejected').toString();
        case STATUS.NAAS_ACCEPTED_REQUEST:
            return i18n.t('qodNumberManagement.inProgress').toString();
        case STATUS.CALLBACK_WITH_FAILED_STATUS:
            return i18n.t('generic.error').toString();
        case STATUS.CALLBACK_WITH_SUCCESS_STATUS:
            return i18n.t('qodNumberManagement.provisioned').toString();
        case STATUS.CALLBACK_NEVER_HAPPENED:
            return i18n.t('qodNumberManagement.timeout').toString();
        default:
            return '';
    }
};

/**
 * Gets list of all possible naas strings
 * Why? Iterating over a large number of records to determine all options is expensive
 */
export const getAllNaasStatusStrings = (): string[] => [
    convertNaasProvisioningStatusToString(STATUS.ACCEPTED_RECORD),
    convertNaasProvisioningStatusToString(STATUS.NAAS_REJECT_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.NAAS_ACCEPTED_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_FAILED_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_SUCCESS_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_NEVER_HAPPENED),
];

/**
 * Get list of uploaded files (aka bulk upload history)!
 */
export const getUploadHistory = async (apiType: DEVICE_LINE_AUTH_API_TYPE): Promise<UploadedFileDetails[]> => {
    const response = await getRegisteredEntities(apiType);
    const entities = response.data.data.items;
    return entities.map(entity => uploadFileDetailsFromEntity(apiType, entity));
};

/**
 * Transforms backend `RegisteredEntityItem` into Portal friendly `UploadedFileDetails`
 */
const uploadFileDetailsFromEntity = (
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    entity: RegisteredEntityItem,
): UploadedFileDetails => {
    /**
     * Build details object
     * Explicitly leaving fields undefined since:
     *  - they will be updated asynchronously
     *  - they need to be reactive
     */
    const fileDetails: UploadedFileDetails = {
        entityName: entity.entity_name,
        createdAt: entity.created_at,
        lastUpdated: entity.last_updated,
        fileName: undefined,
        bulkUploadId: undefined,
        createdBy: undefined,
        recordCount: undefined,
    };

    /**
     * When we upload our file we append data to the filename.
     * So if you upload `<original filename>` it gets transformed into an entity name `<bulk upload id>-<username>-<portal user id>-<original filename>`.
     * Eg: Uploading `arfaiouasdfu.csv` actually uploads `a01e4383-d45b-4eeb-a86a-d36dfe89b0c5-Donkey Kong-1235-arfaiouasdfu.csv`.
     * In this step we go backwards to transform the entity name into a filename, bulk upload id, username and portal user id (user who uploaded the file).
     * If the filename doesn't match our expected pattern then we cannot determine bulk upload id and upload user id.
     */
    const filenameRegex =
        /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:-([A-Za-z ]+))?-(\d+)-(.*)$/;
    const match = entity.entity_name.match(filenameRegex);
    if (match) {
        fileDetails.bulkUploadId = match[1];
        fileDetails.createdBy = match[2]; //username is optional for backward compatibility (files that were uploaded without it)
        fileDetails.createdByPortalId = Number(match[3]);
        fileDetails.fileName = match[4];
    } else {
        fileDetails.fileName = entity.entity_name;
    }

    /**
     * Determine file upload status
     * Attempt to load this async as we need an additional request
     */
    getEntityDetailsByName(apiType, entity.entity_name)
        .then(details => {
            const recordCount = details.data.data.expected_count;
            const eventCategoryCounters = details.data.data.event_category_counters;
            fileDetails.recordCount = recordCount;
            fileDetails.eventCategoryCounters = eventCategoryCounters;
            fileDetails.fileUploadStatus = getFileUploadStatus(recordCount, eventCategoryCounters);
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
        })
        .catch(() => {
            fileDetails.recordCount = 0;
            fileDetails.fileUploadStatus = FILE_UPLOAD_STATUS.UNKNOWN;
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
        });
    return fileDetails;
};

/**
 * Determines a generalized status for the entire file based on the individual event counters
 * @param recordCount total number of records in the file
 * @param eventCategoryCounters counts for each individual event
 * @returns
 *      FILE_UPLOAD_STATUS.IN_PROGRESS (file is still being processed) if sum of all counts (in eventCategoryCounters) is less than recordCount
 *      FILE_UPLOAD_STATUS.SUCCESS if all the records were successfully processed
 *      FILE_UPLOAD_STATUS.PARTIAL_SUCCESS if there's atleast 1 success and the file is not currently being processed
 *      FILE_UPLOAD_STATUS.FAILED if there are no successful records and the file is not currently being processed
 */
const getFileUploadStatus = (recordCount: number, eventCategoryCounters: EventCategoryCounters): FILE_UPLOAD_STATUS => {
    const successCount = eventCategoryCounters[EVENT_CATEGORY.SUCCESSFUL] ?? 0;
    const sumOfCounts = Object.values(eventCategoryCounters).reduce((sum, count) => sum + count, 0);

    // Check if in progress
    if (sumOfCounts < recordCount) {
        return FILE_UPLOAD_STATUS.IN_PROGRESS;
    } else if (sumOfCounts > recordCount) {
        return FILE_UPLOAD_STATUS.ERROR; // this shouldn't be possible and indicates a bug
    }

    // Check for success or partial success
    if (successCount === sumOfCounts) {
        return FILE_UPLOAD_STATUS.SUCCESS;
    } else if (successCount > 0 && successCount < sumOfCounts) {
        return FILE_UPLOAD_STATUS.PARTIAL_SUCCESS;
    }

    // Success count equals 0
    return FILE_UPLOAD_STATUS.FAILED;
};

export const fileUploadStatusToStr = (status: FILE_UPLOAD_STATUS): string => {
    switch (status) {
        case FILE_UPLOAD_STATUS.IN_PROGRESS:
            return i18n.t('generic.stateMap.inProgress').toString();
        case FILE_UPLOAD_STATUS.SUCCESS:
            return i18n.t('generic.stateMap.success').toString();
        case FILE_UPLOAD_STATUS.PARTIAL_SUCCESS:
            return i18n.t('generic.stateMap.partialSuccess').toString();
        case FILE_UPLOAD_STATUS.FAILED:
            return i18n.t('generic.stateMap.failed').toString();
        case FILE_UPLOAD_STATUS.UNKNOWN:
            return i18n.t('generic.stateMap.unknown').toString();
        case FILE_UPLOAD_STATUS.ERROR:
            return i18n.t('generic.stateMap.error').toString();
        default:
            return '';
    }
};

export const getAllFileUploadStatusStrings = (): string[] => {
    return [
        FILE_UPLOAD_STATUS.IN_PROGRESS,
        FILE_UPLOAD_STATUS.SUCCESS,
        FILE_UPLOAD_STATUS.PARTIAL_SUCCESS,
        FILE_UPLOAD_STATUS.FAILED,
        FILE_UPLOAD_STATUS.UNKNOWN,
        FILE_UPLOAD_STATUS.ERROR,
    ].map(status => fileUploadStatusToStr(status));
};

export const FILE_UPLOAD_STATUS_TO_COLOR_MAP: Map<string, string | undefined> = new Map([
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.IN_PROGRESS), LABEL_COLOR.blue],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.SUCCESS), LABEL_COLOR.green],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.PARTIAL_SUCCESS), LABEL_COLOR.yellow],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.FAILED), LABEL_COLOR.red],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.UNKNOWN), LABEL_COLOR.gray],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.ERROR), LABEL_COLOR.red],
]);

/**
 * Formats event category from `progress-tracker` to be user friendly
 */
export const formatEventCategory = (eventCategory: string): string => {
    // Look up event category in i18n file
    const key = `qodNumberManagement.eventCategory.${eventCategory}`;
    const value = i18n.t(key)?.toString();
    if (value && value !== key) {
        return value;
    }
    // Entry for `key` didn't exist in i18n file
    // Fallback to default formatting
    const failedPrefixRemoved = eventCategory.replace(/^failed_/g, '');
    const underscoresRemoved = failedPrefixRemoved.replace(/_/g, ' ');
    return capitalize(underscoresRemoved);
};
