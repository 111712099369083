<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="val => hideSidebar(val)"
    >
        <template #header>
            <div class="modal-row d-flex align-items-center">
                <div class="all-caps-text">
                    {{ $i18n.t('customerCareSuite.subscriptionDetails') }}
                </div>
            </div>
        </template>
        <template #content>
            <section>
                <!-- Characteristics-->
                <AppOverviewBlock
                    v-if="subscriptionCharacteristics"
                    :isRowTitleCounterVisible="false"
                    :items="subscriptionCharacteristics"
                    data-test-id="characteristics"
                    class="my-3"
                />
                <AppTable
                    :entities="orders"
                    :isDefaultHoverEnabled="false"
                    :forbidHideColumn="true"
                    :disableBoxShadow="true"
                    :newDesign="true"
                    :canSelectColumns="false"
                    :columnsData="ordersColumnsData"
                    tableKey="customer-care-suite/subscription-sidebar"
                    keyName="executionId"
                    class="auto-height"
                >
                    <template #state="{ entity }">
                        <EntityStatusIndicator
                            :stateMap="ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP"
                            :stateMapColor="ORDER_NEXTGEN_STATUS_INDICATOR_MAP"
                            :status="entity.state"
                            :isInTable="true"
                        />
                    </template>
                </AppTable>
                <AppCollapsibleList
                    v-if="suspensionReasonsFormatted"
                    :items="suspensionReasonsFormatted"
                    :isTitleCounterVisible="true"
                    :isRowTitleCounterVisible="true"
                />
            </section>
        </template>
    </AppAditionalSidebar>
</template>
<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppCollapsibleList from '@/components/partials/AppCollapsibleList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';

// HELPERS
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import permissionsService from '@/services/permissions/permissions.service';
import {
    ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
    ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/orderNextGenStateHelper';

// HTTP
import coreOMHTTP from '@/__new__/services/dno/coreom/http/coreom';
import OrderData from '@/__new__/services/dno/coreom/models/OrderData';

export default {
    name: 'AdditionalSidebarForSubscriptions',
    components: {
        AppAditionalSidebar,
        AppOverviewBlock,
        EntityStatusIndicator,
        AppTable,
        AppCollapsibleList,
    },
    props: {
        selectedSubscription: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isSidebarVisible: false,
            RouteNames,
            orders: [],

            // proxy
            ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        ordersColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.orders.map(entity => entity.state))),
                },
            ];
        },
        isOePlansVisible() {
            return this.oePlansEnabled && permissionsService.isUserLotusFlareAdmin();
        },
        suspensionReasonsFormatted() {
            return this.selectedSubscription?.suspensionReasons
                ? [
                      {
                          isCollapsed: false,
                          name: this.$i18n.t('customerCareSuite.subscriptionsTile.suspensionReasons'),
                          label: '',
                          rows: this.selectedSubscription.suspensionReasons.map(suspensionReason => ({
                              name: suspensionReason.reason,
                              value: suspensionReason.timeFormatted,
                          })),
                      },
                  ]
                : null;
        },
        subscriptionCharacteristics() {
            const subscription = this.selectedSubscription;
            if (!subscription) {
                return null;
            }
            const characteristics = subscription.characteristics || {};
            if (Object.keys(characteristics).length === 0) {
                return null;
            }
            return [
                {
                    name: this.$i18n.t('customerCareSuite.characteristics'),
                    rows: Object.entries(characteristics).map(([name, value]) => ({ name, value })),
                },
            ];
        },
    },
    watch: {
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
        selectedSubscription: {
            async handler() {
                if (
                    this.selectedSubscription &&
                    this.selectedSubscription.orderRefs &&
                    this.selectedSubscription.orderRefs.length > 0
                ) {
                    await this.fetchOrders();
                }
            },
        },
    },
    methods: {
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
        async fetchOrders() {
            const ordersIdsArray = [];
            const orders = [];
            if (this.selectedSubscription?.orderRefs) {
                for (const value of this.selectedSubscription.orderRefs) {
                    ordersIdsArray.push(value.orderId);
                }
            }

            const response = await coreOMHTTP.getOrdersByIds(
                this.$route.params.id,
                this.userManagerHierarchy,
                ordersIdsArray,
            );

            if (response?.data?.orders) {
                this.returnedOrders = Object.values(response.data.orders).map(el => {
                    return new OrderData(OrderData.mapOrdersFromBE(el));
                });
            }

            if (this.selectedSubscription?.orderRefs) {
                for (const orderRef of this.selectedSubscription.orderRefs) {
                    orders.push({
                        targetId: orderRef.targetId,
                        targetType: orderRef.targetType,
                        orderId: orderRef.orderId,
                        state: this.returnedOrders.find(item => item.id === orderRef.orderId)?.state,
                    });
                }
            }
            this.orders = orders;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
