<template>
    <div :class="className">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
export default {
    name: 'RouteWrapper',
    props: {
        className: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped></style>
