import ENTITY_TYPES from '@/common/entities/entityTypes';
import { EntityState, LocalizedString } from '@/http';
import {
    PricingRuleActionPortal,
    PricingRuleConditionPortal,
} from '@/__new__/services/dno/pricing/models/pricingPortal';
import {
    AMOUNT_CALCULATION_METHOD,
    COMPARISON_OP,
    MULTIPLIER,
    TARGET,
} from '@/__new__/services/dno/pricing/models/pricingDno';

/**
 * Portal Entity for storing *Pricing Rule* data
 */
export interface PricingRuleEntity {
    id: string;
    name: string;
    description: string;
    misc?: object;
    state: EntityState;
    stateLabel: string;
    updateTimeEpoch: number;
    conditions: PricingRuleConditionPortal;
    actions: PricingRuleActionPortal[];
    version: number;
    /**
     * A map from language code (eg 'en') to the name string.
     * Preserved from the DNO.
     */
    nameByLanguage: LocalizedString;
    /**
     * A map from language code (eg 'en') to the description string.
     * Preserved from the DNO.
     */
    descriptionByLanguage: LocalizedString;
    /**
     * If true indicates this is a simple pricing rule.
     * Otherwise indicates this is a complex pricing rule.
     */
    isSimplePricingRule: boolean;
    simplePricingRule?: SimplePricingRule;
    /**
     * Human readable string indicating if pricing rule is 'Simple' or 'Advanced'
     */
    viewLabel: string;
    priority: number;
}

export type LineItemType = ENTITY_TYPES.OFFER | ENTITY_TYPES.CATEGORY;

export enum DISCOUNT_TARGET {
    TOTAL_BEFORE_TAX,
    LINE_ITEMS_BEFORE_TAX,
    SPECIFIC_LINE_ITEM_BEFORE_TAX,
}

export interface SimplePricingRule {
    lineItemType: LineItemType;
    lineItems: string[];
    spendingConditionEnabled: boolean;
    spendingAmountOperator?: COMPARISON_OP;
    spendingAmount?: number;
    spendingAmountTarget?: TARGET;
    allLineItemsMandatoryConditionEnabled: boolean;
    lineItemQuantityConditionEnabled: boolean;
    lineItemQuantityOperator?: COMPARISON_OP;
    lineItemQuantity?: number;
    minLineItemAndSubscriptionQuantityConditionEnabled: boolean;
    minLineItemAndSubscriptionQuantity?: number;
    discountTarget: DISCOUNT_TARGET;
    discountTargetEntityType?: LineItemType;
    discountTargetEntityIds?: string[];
    discountAmount: number;
    discountAmountType: AMOUNT_CALCULATION_METHOD;
    incrementalDiscountAmount?: number;
    incrementalDiscountAmountType?: AMOUNT_CALCULATION_METHOD;
    incrementalDiscountMultiplier?: MULTIPLIER;
}
