import { render, staticRenderFns } from "./LayoutPage.vue?vue&type=template&id=8ac21200&scoped=true"
import script from "./LayoutPage.vue?vue&type=script&lang=ts"
export * from "./LayoutPage.vue?vue&type=script&lang=ts"
import style0 from "./LayoutPage.vue?vue&type=style&index=0&id=8ac21200&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ac21200",
  null
  
)

export default component.exports