import { LABEL_COLOR } from '@/common/labelsHelper';
import { EntityActions } from '@/common/commonEntityStateMapper';
import { SinkConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { JOB_TYPE } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

export class SinkConfigStatus {
    title: string;
    cssClass: string;

    constructor(title: string, cssClass: string) {
        this.title = title;
        this.cssClass = cssClass;
    }
}

// Enum values referr to capitalized version of supported states.
// Refactor and define proper state maps in `sinkConfigHelper.ts` file.
export enum SINK_CONFIG_STATUS {
    UNAPPROVED = 'Unapproved',
    APPROVED = 'Approved',
    ENABLED = 'Enabled',
    PAUSED = 'Paused',
    DRAFT = 'Draft',
}

export const SINK_CONFIG_STATUS_TO_COLOR_MAP = {
    [SINK_CONFIG_STATUS.UNAPPROVED]: LABEL_COLOR.yellow,
    [SINK_CONFIG_STATUS.APPROVED]: LABEL_COLOR.green,
    [SINK_CONFIG_STATUS.ENABLED]: LABEL_COLOR.green,
    [SINK_CONFIG_STATUS.PAUSED]: LABEL_COLOR.red,
    [SINK_CONFIG_STATUS.DRAFT]: LABEL_COLOR.gray,
};

export const SINK_CONFIG_STATUS_SERVER_CODE_MAP = {
    [SINK_CONFIG_STATUS.UNAPPROVED]: 1,
    [SINK_CONFIG_STATUS.APPROVED]: 2,
    [SINK_CONFIG_STATUS.PAUSED]: 3,
};

export const SINK_CONFIG_STATUS_FROM_SERVER_CODE_MAP = {
    1: SINK_CONFIG_STATUS.UNAPPROVED,
    2: SINK_CONFIG_STATUS.APPROVED,
    3: SINK_CONFIG_STATUS.PAUSED,
    [SINK_CONFIG_STATUS.DRAFT]: SINK_CONFIG_STATUS.DRAFT,
};

export function mapORDConfigActions(config: SinkConfig): number[] {
    const actions = [EntityActions.CLONE, EntityActions.DETAILS];

    if (config.allowFieldsConfigurationEdit) {
        actions.push(EntityActions.EDIT);
    }

    if (config.jobType !== JOB_TYPE.STATIC) {
        actions.push(EntityActions.DELETE);
    }

    return actions;
}
export function mapRedshiftConfigActions(state: string): number[] {
    let actions = [];
    switch (state) {
        case SINK_CONFIG_STATUS.UNAPPROVED:
        case SINK_CONFIG_STATUS.PAUSED:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.APPROVE, EntityActions.DELETE];
            break;
        case SINK_CONFIG_STATUS.APPROVED:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.PAUSE, EntityActions.DELETE];
            break;
        case SINK_CONFIG_STATUS.DRAFT:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.DELETE];
            break;
        default:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.APPROVE, EntityActions.DELETE];
    }
    return actions;
}
export function mapKafkaSinkConfigActions(state: string): number[] {
    let actions = [];
    switch (state) {
        case SINK_CONFIG_STATUS.UNAPPROVED:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.APPROVE, EntityActions.DELETE];
            break;
        case SINK_CONFIG_STATUS.APPROVED:
        case SINK_CONFIG_STATUS.DRAFT:
            actions = [EntityActions.DETAILS, EntityActions.DELETE, EntityActions.EDIT];
            break;
        default:
            actions = [EntityActions.DETAILS, EntityActions.EDIT, EntityActions.APPROVE, EntityActions.DELETE];
    }
    return actions;
}
