














































































































































































































// components
import ListDropdownSectionItem from '@/components/partials/entityOverview/ListDropdownSectionItem.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import IconButton from '@/components/partials/IconButton.vue';
// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { isNumber } from 'lodash';

export default {
    name: 'AppCollapsibleList',
    components: {
        AppButton,
        AppIcon,
        IconButton,
        ListDropdownSectionItem,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        titleIcon: {
            type: String,
            default: '',
        },
        isTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        isRowTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        topLevelCombinator: {
            type: String,
            default: '',
        },
        /**
         * @param - array of objects like:
         * [{  isCollapsed: false,
         * name: 'Item name',
         * label: 'Item label',
         * rows: [
         *     {
         *         name: 'Item row name',
         *         value: 'Item row value',
         *     }]
         * }]
         */
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
        maxItems: {
            type: Number,
            default: 5,
        },
        maxItemRows: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            collapsedItemsIndexes: [],
            itemIndexForPopupDisplay: null,
        };
    },

    computed: {
        wrapperPaddingClasses(): string {
            if (!this.items.length) {
                return 'custom-paddings';
            }
            if (this.maxItemsExceeded) {
                return 'px-3 py-1';
            }
            return this.title ? 'px-3 pt-2 pb-3' : 'no-title';
        },
        isTopLevelCombinatorVisible(): boolean {
            return !!this.topLevelCombinator && this.items.length > 1;
        },
        maxItemsExceeded(): boolean {
            return this.items.length > this.maxItems;
        },
        slicedItems(): CollapsibleListItem[] {
            return this.items
                .map(item => ({
                    ...item,
                    rows: item.rows.slice(0, this.maxItemRows),
                }))
                .slice(0, this.maxItems);
        },
        changeAllItemsStateBtnLabel(): string {
            return this.collapsedItemsIndexes.length
                ? this.$i18n.t('generic.expandAll')
                : this.$i18n.t('generic.collapseAll');
        },
    },
    watch: {
        items: {
            immediate: true,
            handler(items: CollapsibleListItem[]) {
                this.collapsedItemsIndexes = items
                    .map(({ isCollapsed }, index) => (isCollapsed ? index : null))
                    .filter(isNumber);
            },
        },
    },
    methods: {
        onRouterIconClick(entityType, id): void {
            this.$emit('routerIconClick', { entityType, id });
        },
        isItemCollapsed(index: number): boolean {
            return this.collapsedItemsIndexes.includes(index);
        },
        changeAllItemsState(): void {
            if (this.collapsedItemsIndexes.length) {
                this.collapsedItemsIndexes = [];
            } else {
                this.collapsedItemsIndexes = Array.from(this.items.keys());
            }
        },
        changeItemState(index: number): void {
            if (this.isItemCollapsed(index)) {
                this.collapsedItemsIndexes = this.collapsedItemsIndexes.filter((idx: number) => idx !== index);
            } else {
                this.$set(this.collapsedItemsIndexes, this.collapsedItemsIndexes.length, index);
            }
        },
        isLowLevelCombinatorVisible(rows): boolean {
            return rows.some(row => !!row.rowCombinator);
        },
    },
};
