
































import Vue from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { chargingSpecPushVersion } from '@/__new__/services/dno/charging/http/chargingSpecifications';
import { isPushedVersionAtCurrentVersion } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default Vue.extend({
    name: 'ChargingSpecPushVersionModal',
    components: {
        AppDialogV2,
        AppTable,
        AppButton,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        entites: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            searchQueryForTable: '',
        };
    },
    computed: {
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.update_time),
                    sortBy: entity => entity.update_time,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charging.chargingSpecifications.lastPushetVersion'),
                    key: 'pushedVersion',
                    field: 'pushedVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        getFiltrateEntities() {
            return this.entites.filter((el: any) => isPushedVersionAtCurrentVersion(el));
        },
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
        },
        confirmEnityPushVersion() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();

                    const data = this.getFiltrateEntities.map((el: any) => ({
                        id: el.id,
                        charging_version: el.chargingVersion,
                    }));

                    await chargingSpecPushVersion(data);

                    this.$Progress.finish();

                    this.$showSuccessAlert({
                        message: this.$i18n.t('charging.updatedReloadEntities', {
                            entity: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                        }),
                    });

                    setTimeout(() => {
                        const { companyId } = this.$route.params;
                        this.$router
                            .push({
                                name: RouteNames.HOME_VIEW,
                                params: { companyId },
                            })
                            .then(() => {
                                this.$router.push({
                                    name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                                    params: {
                                        companyId,
                                    },
                                });
                            });
                    }, 1000);
                },
                {
                    errorHandler: (e: any) => {
                        this.$Progress.fail();
                        Sentry.captureException(e);

                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
    },
});
