





































































































































































import Vue from 'vue';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Generic Vue Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import Button from '@/common/button/Button';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import Draggable from 'vuedraggable';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// Mixins
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import { validationMixin } from 'vuelidate';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Validations
import { required, minValue } from 'vuelidate/lib/validators';

// HTTP
import { getTariffSpecGroupEntityDraft } from '@/__new__/services/dno/charging/http/tariffSpecificationGroups';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Helpers
import { languageMap } from '@/common/locale/language';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { EDITOR_MODE, getProperlyFormattedMultilangFieldValue } from '@/common/entities/entityHelper';
import { TariffSpecification, TariffSpecGroup } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { TariffSpecGroupDraft } from '@/__new__/services/dno/charging/models/TariffSpecificationGroup';
import { STATUS_CODES } from '@/common/commonHelper';
import cloneDeep from 'lodash/cloneDeep';
import { LABEL_COLOR } from '@/common/labelsHelper';

type TariffSpecsOptions = {
    id: string;
    name: string | Record<string, string>;
};

export default Vue.extend({
    name: 'TariffSpecificationGroupEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        EditorButtons,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        Draggable,
        EntityStatusIndicator,
        AppLabel,
        AppButton,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            // global
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            ALERT_TYPES,
            EDITOR_MODE,
            PLURALIZATION,
            LABEL_COLOR,
            selectedLanguage: '' as string,

            // entity
            entityId: null as string | null,
            name: {} as Record<string, string>,
            description: {} as Record<string, string>,
            priority: 0 as number,
            tariffSpecs: [] as TariffSpecsOptions[],
            approveOnCreate: false as boolean,
            data: {},
            version: null as string | null,

            // draft
            updateTime: null as string | null,
            entityType: ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP,
            readonly: false as boolean,
            isOnlyDraft: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            currentVersion: null as string | null,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            state: EntityStateMapping.UNAPPROVED,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
        };
    },
    validations() {
        return {
            name: {
                required,
            },
            tariffSpecs: {
                required,
            },
            priority: {
                required,
                minValue: minValue(1),
            },
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_TARIFF_SPECIFICATION_BY_ID,
            Getters.GET_APPROVED_TARIFF_SPECIFICATIONS,
            Getters.GET_APPROVED_TARIFF_SPECIFICATION_GROUPS,
            Getters.GET_TARIFF_SPECIFICATION_GROUP_BY_ID,
        ]),
        tariffSpecifications(): TariffSpecification[] {
            return [...this[Getters.GET_APPROVED_TARIFF_SPECIFICATIONS]] || [];
        },
        tariffSpecsOptions(): TariffSpecsOptions[] {
            return this.tariffSpecifications?.map((ts: TariffSpecification) => ({
                name: ts.name,
                id: ts.id,
            }));
        },
        tariffSpecificationGroups(): TariffSpecGroup[] {
            return [...this[Getters.GET_APPROVED_TARIFF_SPECIFICATION_GROUPS]] || [];
        },
        tariffSpecificationGroupById(): TariffSpecGroup | null {
            return this[Getters.GET_TARIFF_SPECIFICATION_GROUP_BY_ID](this.entityId) || null;
        },
        alreadyUsedName(): boolean {
            if (this.isEditingEntityExisting) {
                if (this.name.en !== this.tariffSpecificationGroupById?.name) {
                    return Object.values(this.tariffSpecificationGroups).some(
                        (entity: TariffSpecGroup) => entity.name === this.name.en,
                    );
                }
                return false;
            }
            return Object.values(this.tariffSpecificationGroups).some(
                (entity: TariffSpecGroup) => entity.name === this.name.en,
            );
        },
        isEditingEntityExisting(): boolean {
            return Boolean(this.entityId && !this.$route.params.clone);
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.tc('charging.tariffSpecGroup.name', PLURALIZATION.SINGULAR);
            }
            if (this.entityId) {
                return this.$i18n.t('charging.tariffSpecGroup.editTsg');
            }
            return this.$i18n.t('charging.tariffSpecGroup.addnewTsg');
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
        dontShowOnPublishedAndDraft(): boolean {
            return !this.isDraft && !this.isPublished;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                const promises = [
                    this[Actions.REQUEST_TARIFF_SPECIFICATIONS](),
                    this[Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS](),
                ];

                if (!clone) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (this.entityId) {
                    this.initData();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_TARIFF_SPECIFICATIONS,
            Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS,
        ]),
        tariffSpecificationById(id: string): TariffSpecification | null {
            return this[Getters.GET_TARIFF_SPECIFICATION_BY_ID](id) || null;
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.nameMustBeUnique', {
                        entity: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUPS,
                    }),
                });
                return false;
            }

            return true;
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.data = {
                name: this.name || null,
                description: this.description || null,
                tariff_specs: this.tariffSpecs?.map(el => el.id) || [],
                priority: this.priority || 0,
            };

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getTariffSpecGroupEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async initData(forceInitPublished = false): Promise<void> {
            if (this.entityId) {
                const tsg = this.tariffSpecificationGroupById;
                let tsgData: any;

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new TariffSpecGroupDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = tsg.version;
                    tsgData = draftData;
                    this.isOnlyDraft = tsg?.state === STATUS_CODES.NA;

                    if (tsg?.update_time <= tsgData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else {
                    tsgData = cloneDeep(tsg);
                }

                const {
                    name,
                    description,
                    priority,
                    tariffSpecs,
                    version,
                    update_time: updateTime,
                    state,
                    updatePortalId,
                } = tsgData;

                const properlyFormattedName = getProperlyFormattedMultilangFieldValue(name);
                if (this.$route.params.clone) {
                    Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                        properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                    });
                }

                this.name = properlyFormattedName;
                this.description = getProperlyFormattedMultilangFieldValue(description);
                this.priority = priority;
                this.tariffSpecs = tariffSpecs?.map((id: string) => this.tariffSpecificationById(id));

                this.version = version;
                this.updateTime = updateTime;
                this.state = state;
                await this.getUpdateUserName(updatePortalId);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUPS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUP_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
    },
});
