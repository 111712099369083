










































































































































































import Vue, { PropType } from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import BucketUsageCountersData from '@/__new__/features/charging/bucketWizard/BucketUsageCountersData.vue';
import BucketPolicyCountersData from '@/__new__/features/charging/bucketWizard/BucketPolicyCountersData.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { Bucket } from '@/__new__/features/customerCare/common/balanceHelper';
import { unitTypes } from '@/common/formatting';
import { parseExpressionsFromBe, parseExpressionToTree } from '@/__new__/services/dno/charging/common/expression';
import { PolicyCounterConstructorArgs } from '@/__new__/services/dno/charging/models/PolicyCounter';
import { UsageCounterConstructorArgs } from '@/__new__/services/dno/charging/models/UsageCounter';
import { CHARGING_SPEC_TYPE, getCSType } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import { setChargingBucketWizardBuckets } from '@/__new__/services/dno/charging/http/bucketWizard';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { checkObjectPath } from '@/common/utils';

// Validations
import { validationMixin } from 'vuelidate';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

/* eslint-disable camelcase */
type BucketWizardBucket = Bucket & {
    policy_counters: PolicyCounterConstructorArgs[];
    condition_expr: any;
    usage_counters: {
        bucket_counters: Record<
            string,
            UsageCounterConstructorArgs & {
                amount_used: number;
                expiration_timestamp: number;
                name: string;
            }
        >;
    };
    surcharges: {
        condition_expr: any;
        rate: string;
        rate_period: number;
    };
};

export default Vue.extend({
    name: 'BucketData',
    components: {
        AppButton,
        AppInputV3,
        UnitPicker,
        AppCustomerHeader,
        ConditionsExpressionEditor,
        BucketUsageCountersData,
        BucketPolicyCountersData,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object as PropType<BucketWizardBucket>,
            required: true,
        },
        bucketId: {
            type: String,
            required: true,
        },
        conditionsList: {
            type: Array,
            default: () => [],
        },
        targetId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
            unitTypes,
            collapseSection: true,
            bucketType: getCSType(this.value.service_types?.[0]),
            alertButtons: {
                updateBucker: new Button({
                    label: this.$i18n.t('generic.update'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,
        };
    },

    validations() {
        return {
            value: {
                amount_available: {
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(this.getAmountInitial),
                },
            },
        };
    },
    computed: {
        expression: {
            get() {
                return this.value?.condition_expr ? parseExpressionsFromBe(this.value?.condition_expr) : [];
            },
            set(value) {
                this.value.condition_expr = parseExpressionToTree(value);
            },
        },
        surchargesExpression: {
            get() {
                return this.value?.surcharges?.condition_expr
                    ? parseExpressionsFromBe(this.value?.surcharges?.condition_expr)
                    : [];
            },
            set(value) {
                this.value.surcharges.condition_expr = parseExpressionToTree(value);
            },
        },
        displaySmsMmsLabel() {
            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                return this.$i18n.t('charging.chargingSpecifications.editor.perSMS');
            }
            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                return this.$i18n.t('charging.chargingSpecifications.editor.perMMS');
            }

            return '';
        },
        showCsSurchargeRatePeriod() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) ||
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)
            );
        },
        getFormulaCalculatedThreshold() {
            return this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) ? unitTypes.DURATION : unitTypes.DATA;
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.bucketId,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.initialAmount'),
                            value: `${this.scaleBytes(this.value.amount_initial)} ${this.$i18n.t('generic.mb')}`,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.amountUsed'),
                            value: `${this.scaleBytes(this.value.amount_used)} ${this.$i18n.t('generic.mb')}`,
                        },
                        {
                            name: this.$i18n.t('generic.endTime'),
                            value: localeLibrary.getFormattedDateAndTime(this.value.end_time),
                        },
                    ],
                },
                {
                    title: this.$i18n.t('generic.payoutTypeMap.offer'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('customerCare.transactionHistory.offerId'),
                            value: this.value.offer_id,
                        },
                        {
                            name: this.$i18n.t('customerCare.transactionHistory.offerName'),
                            value: this.value.offer_name,
                        },
                    ],
                },
                {
                    title: this.$i18n.t('customerCare.orderDetails'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('customerCare.orderHistory.orderId'),
                            value: this.value.order_id,
                        },
                        {
                            name: this.$i18n.t('charging.policyCounters.chargingSpecificationId'),
                            value: this.value.cs_group_id,
                        },
                        {
                            name: this.$i18n.t('charging.chargingSpecifications.editor.chargingSpecificationName'),
                            value: this.value.cs_group_name,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.csgPriority'),
                            value: this.value.cs_group_priority,
                        },
                        {
                            name: this.$i18n.t('charging.policyCounters.chargingSpecificationId'),
                            value: this.value.charging_spec_id,
                        },
                    ],
                },
            ];
        },
        getAmountInitial(): number {
            return this.value?.amount_initial || 0;
        },
    },
    methods: {
        scaleBytes(bytes: number): number {
            return bytes ? Math.floor(bytes / (1024 * 1024)) : 0;
        },
        bucketDataHasKey(data: BucketWizardBucket, key: string) {
            return checkObjectPath(data, key);
        },
        isSelectedCSTypeEqualTo(type: string) {
            return this.bucketType?.id === type;
        },
        updatePolicyCounter(index: number, val: any): void {
            this.value.policy_counters[index] = val;
        },
        updateBucketData(): void {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.updateBucketDatainfo'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.updateBucker],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.updateBucker.id) {
                    this.$withLoadingSpinner(async () => {
                        const bucketData: any = {
                            [this.bucketId]: {
                                amount_available: this.value.amount_available,
                                condition_expr: this.expression ? parseExpressionToTree(this.expression) : [],
                            },
                        };

                        if (this.value?.policy_counters) {
                            const policyCountersError = this.value?.policy_counters.some(el => el === null) || false;
                            if (policyCountersError) {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('alertMessage.helpers.invalidStringPassed'),
                                });
                            }

                            bucketData[this.bucketId].policy_counters = this.value?.policy_counters.map(
                                ({ policy_counter_id, statuses, thresholds_percentage, thresholds }) => ({
                                    policy_counter_id,
                                    statuses,
                                    thresholds,
                                    thresholds_percentage,
                                }),
                            );
                        }

                        if (this.value?.surcharges) {
                            bucketData[this.bucketId].surcharges = {
                                ...this.value.surcharges,
                                rate: this.value.surcharges.rate.toString(),
                            };
                        }

                        if (this.value?.usage_counters) {
                            bucketData[this.bucketId].usage_counters = {};
                            if (this.value?.usage_counters?.bucket_counters) {
                                bucketData[this.bucketId].usage_counters.bucket_counters = this.createCounterObject(
                                    this.value?.usage_counters.bucket_counters,
                                );
                            }
                            if (this.value?.usage_counters?.policy_counters) {
                                bucketData[this.bucketId].usage_counters.policy_counters = this.createCounterObject(
                                    this.value?.usage_counters.policy_counters,
                                );
                            }
                        }

                        await setChargingBucketWizardBuckets(this.targetId, this.targetType, bucketData);

                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.bucketWizard.updateBucketDataUpdateSuccess'),
                            type: ALERT_TYPES.success,
                        });
                    });
                }
            });
        },
        createCounterObject(counterData) {
            return Object.keys(counterData)?.reduce((data: any, current: string) => {
                const { amount_used, reset_period, expiration_timestamp, condition_expr } =
                    this.value?.usage_counters.bucket_counters[current];
                data[current] = {
                    amount_used,
                    reset_period,
                    expiration_timestamp,
                    condition_expr,
                };
                return data;
            }, {});
        },
    },
});
