























































import Vue from 'vue';

// Components
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppField from '@/components/partials/AppField.vue';
import AdditionalSidebarForOrders from '@/__new__/features/customerCare/AdditionalSidebarForOrders.vue';
import AccountSidebarMixin, { sectionKeys } from '@/__new__/features/customerCare/mixins/AccountSidebarMixin.vue';
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { cloneDeep } from 'lodash';
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import Order from '@/__new__/services/dno/orders/models/Order';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

export default Vue.extend({
    name: 'LatestOrderTile',
    components: {
        EntityStatusIndicator,
        AppField,
        AdditionalSidebarForOrders,
        AssociatedUMEntityPicker,
    },
    mixins: [AccountSidebarMixin],
    props: {
        userId: {
            type: String,
            default: 'e8420e983fcd462d88f5cd8d0ad45f86',
        },
    },
    data() {
        return {
            selectedAssociatedUMEntity: null,
            // proxy
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            sectionKeys,
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        ...mapGetters(Modules.orders, [Getters.GET_ORDERS]),
        showAdditionalSidebar() {
            return this.getAdditionalSidebarStateBySectionKey(sectionKeys.ORDER_DETAILS);
        },
        latestOrder() {
            return Object.values(cloneDeep(this[Getters.GET_ORDERS])).sort(
                (a: Order, b: Order) => a.createTimestamp - b.createTimestamp,
            )[0];
        },
        chargeAmountFormatted() {
            return this.latestOrder?.totalAmountFormatted;
        },
        suborders() {
            return this.latestOrder?.suborers?.length;
        },
        orderId() {
            return this.latestOrder?.orderId;
        },
        orderDate() {
            return this.$localeLibrary.getFormattedDateAndTime(this.latestOrder?.createTimestamp);
        },
        mainDataFormatted() {
            return [
                {
                    name: this.$i18n.t('generic.chargeAmount'),
                    value: this.chargeAmountFormatted,
                },
                {
                    name: this.$i18n.t('productCatalog.entities.plural.subOrders'),
                    value: this.suborders,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    value: this.orderId,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    value: this.orderDate,
                },
            ];
        },
        orderStatus() {
            if (this.latestOrder?.state) {
                return this.latestOrder.replaceInfo && this.latestOrder.state === ORDER_ENTITY_STATES.ACTIVE
                    ? ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE
                    : this.latestOrder.state;
            }
            return this.$i18n.t('generic.N/A');
        },
    },
    watch: {
        selectedAssociatedUMEntity: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('orders', [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.selectedAssociatedUMEntity.id);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
