












































import Vue from 'vue';

// Components
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import ServiceIdConditionRow from '@/__new__/features/charging/conditionParameters/conditions/ServiceIdConditionRow.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import ServiceIdConditionModel from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/ServiceIdConditionModel';

import {
    ServiceIdConditionDataObject,
    ServiceIdOptions,
    MAP_SERVICE_ID_TYPE_TO_OPTION_VALUES,
} from '@/__new__/services/dno/charging/common/serviceIdConditionHelper';

export default Vue.extend({
    name: 'ServiceIdCondition',
    components: {
        AppToggle,
        AppButton,
        ServiceIdConditionRow,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            serviceIdFormatted: {} as ServiceIdConditionDataObject,
            serviceIdTypeOptions: Object.values(MAP_SERVICE_ID_TYPE_TO_OPTION_VALUES),
        };
    },
    computed: {
        serviceIdTypeOptionsNotInUse() {
            if (!this.serviceIdFormatted.data.src.length) {
                return this.serviceIdTypeOptions;
            }

            const optionsInUse: string[] = this.serviceIdFormatted.data.src.map((el: any) => el.serviceIdType?.id);
            return this.serviceIdTypeOptions.filter(el => (!optionsInUse.includes(el.id) ? el : null));
        },
        getAllServiceIdTypeOptionsInUse(): string[] {
            return this.serviceIdFormatted.data.src.map((el: any) => el.serviceIdType?.id);
        },
        serviceIdFormattedData() {
            return this.serviceIdFormatted?.data;
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value !== this.serviceIdFormatted) {
                    this.serviceIdFormatted = new ServiceIdConditionModel(this.value);
                }
            },
        },
        serviceIdFormatted: {
            handler() {
                this.emitData();
            },
            deep: true,
        },
    },
    created() {
        this.serviceIdFormatted = new ServiceIdConditionModel(this.value);
    },
    methods: {
        emitData() {
            this.serviceIdFormatted.isDataValid();
            this.$emit('input', this.serviceIdFormatted);
        },
        addSlot(type?: ServiceIdOptions, values: string[] = []) {
            const [firstEL] = this.serviceIdTypeOptionsNotInUse;
            const serviceIdType = firstEL || this.serviceIdTypeOptions[0];
            this.serviceIdFormatted.data.src.push({
                serviceIdType: type || serviceIdType,
                values,
            });
        },
        deleteSlot(index: number) {
            this.serviceIdFormatted.data.src.splice(index, 1);
        },
    },
});
