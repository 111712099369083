































import Vue from 'vue';

import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// HTTP
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import DeviceInfo from '@/__new__/services/dno/osstmofiber/models/DeviceInfo';
import { getTemplate } from '@/__new__/services/dno/documents/http/templates';
import { previewDocument } from '@/__new__/services/dno/documents/http/documents';

// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { formatAddressLines } from '@/common/formatting';
import isEmpty from 'lodash/isEmpty';
import { DOCUMENT_GENERATE_TYPES } from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import {
    USER_MANAGER_HIERARCHY,
    subscriberStateToText,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { DEVICE_INFO_TYPE } from '@/__new__/features/customerCare/common/deviceInfoHelper';

const MAX_NUMBER_OF_ITERATIONS = 12;

export default Vue.extend({
    name: 'ExportCustomerInfoDialog',

    components: {
        AppButton,
        AppDialogV2,
        AppInfoBlock,
        AppSpinner,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ontMacAddress: undefined,
            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            ALERT_TYPES,
            DOCUMENT_GENERATE_TYPES,
            template: {},
            templateName: 'customer_info',
            poller: null,
            isPdfAvailable: false,
            pdfUrl: '',
            isDataLoading: false,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementAccount', ['getStateHistoryByIdAscending']),
        accountData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this.$route.params.id,
            });
        },
        userInfo() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this?.accountData?.userId,
            });
        },
        getAccountStateText() {
            if (Number.isInteger(this.accountData?.state)) {
                return subscriberStateToText(this.accountData.state);
            }
            return undefined;
        },
        getAddressInfoDataItems() {
            const name = this.$i18n.t('customerCareSuite.exportCustomerInfoDialog.serviceAddress');

            if (!this.accountData?.addresses?.length) {
                return [{ name }];
            }

            return this.accountData?.addresses?.map(address => ({
                name,
                value: formatAddressLines(address).lines,
            }));
        },
        infoData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.exportCustomerInfoDialog.firstName'),
                    value: this.userInfo?.name,
                },
                {
                    name: this.$i18n.t('customerCareSuite.exportCustomerInfoDialog.lastName'),
                    value: this.userInfo?.surname,
                },
                ...this.getAddressInfoDataItems,
                {
                    name: this.$i18n.t('customerCareSuite.exportCustomerInfoDialog.subscriberStatus'),
                    value: this.getAccountStateText,
                },
                {
                    name: this.$i18n.t('customerCareSuite.exportCustomerInfoDialog.macAddress'),
                    value: this.ontMacAddress,
                },
            ].filter(info => info.value);
        },
        pdfInfoData() {
            return this.infoData.map(({ name, value }) => ({
                name,
                value: Array.isArray(value) ? value.join(', ') : value,
            }));
        },
    },

    watch: {
        isVisible: {
            immediate: true,
            async handler(isVisible) {
                this.resetComponentState();
                if (isVisible) {
                    await this.loadData();
                }
            },
        },
    },
    methods: {
        beforeDestroy() {
            this.resetComponentState();
        },
        async loadData() {
            this.isDataLoading = true;
            await this.fetchDeviceInfo();
            await this.fetchTemplate();
            this.isDataLoading = false;
        },
        async fetchDeviceInfo() {
            await this.$withProgressBar(
                async () => {
                    const deviceInfoResponse = await osstmofiberHTTP.getDeviceInfo(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    if (deviceInfoResponse?.data?.device_info) {
                        this.ontMacAddress = deviceInfoResponse.data.device_info
                            ?.map(info => new DeviceInfo(info))
                            .find(device => device.deviceType === DEVICE_INFO_TYPE.ONT)?.macAddress;
                    }
                },
                {
                    errorHandler: (e: any) => {
                        this.deviceInfoApiResponse = e.response;
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingDeviceData'));
                    },
                },
            );
        },
        async fetchTemplate() {
            await this.$withProgressBar(
                async () => {
                    const templateResponse = await getTemplate(this.templateName);
                    const { template_definition: templateDefinition = '' } = templateResponse?.data?.data;
                    this.template = templateDefinition;
                },
                {
                    errorHandler: (e: any) => {
                        this.deviceInfoApiResponse = e.response;
                        this.$alert(this.$i18n.t('documents.loadError'));
                    },
                },
            );
        },
        fetchPreviewDocument() {
            return previewDocument({
                payload: {
                    lines: this.pdfInfoData,
                },
                fileName: `${this.templateName}-${this.$route.params.id}`,
                templateDefinition: this.template,
            });
        },
        async handleDocumentGeneration() {
            this.numberOfIterations += 1;
            const response = await this.fetchPreviewDocument();
            const { status, url_data: urlData = {} } = response.data.data;

            if (status !== DOCUMENT_GENERATE_TYPES.COMPLETED && isEmpty(urlData)) {
                if (this.numberOfIterations >= MAX_NUMBER_OF_ITERATIONS) {
                    this.clearDocumentInterval();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('documents.maxNumberOfIterations'),
                        type: ALERT_TYPES.error,
                    });
                    return true; // completed, max iterations reached
                }
                return false; // incomplete, document not yet available
            }

            this.pdfUrl = urlData.signed_url;
            this.clearDocumentInterval();
            this.isPdfAvailable = true;
            this.openPdf();

            return true; // completed, document available
        },
        onSave() {
            this.$withProgressBar(
                async () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('documents.documentGenerated'),
                        type: ALERT_TYPES.info,
                    });
                    this.$Progress.start();
                    this.isDataLoading = true;
                    try {
                        const isCompleted = await this.handleDocumentGeneration();
                        if (!isCompleted) {
                            this.poller = setInterval(async () => {
                                const isPollerCompleted = await this.handleDocumentGeneration();
                                if (isPollerCompleted) clearInterval(this.poller);
                            }, 5000);
                        }
                    } catch (e: any) {
                        this.clearDocumentInterval();
                        this.$Progress.fail();
                        this.$eventBus.$emit('showAlert', {
                            message: e?.response?.data?.message ?? this.$i18n.t('documents.documentGeneratedError'),
                            type: ALERT_TYPES.error,
                        });
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        clearDocumentInterval(): void {
            this.$eventBus.$emit('closeAllAlerts');
            clearInterval(this.poller);
            this.poller = null;
            this.isDataLoading = false;
            this.numberOfIterations = 0;
        },
        onClose() {
            this.clearDocumentInterval();
            this.isPdfAvailable = false;
            this.$emit('close');
        },
        openPdf() {
            window.open(this.pdfUrl, '_blank');
        },
        resetComponentState() {
            this.isPdfAvailable = false;
            this.clearDocumentInterval();
        },
    },
});
