var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EntityListPageLayout',{attrs:{"entityType":_vm.entityType,"listViewColumns":_vm.listViewColumns,"overviewContentBuilder":_vm.overviewContentBuilder,"writePermission":"OffersWrite"},scopedSlots:_vm._u([{key:"modals",fn:function(ref){
var selectedEntity = ref.selectedEntity;
var entities = ref.entities;
return [(_vm.isCompatibilityModalVisible)?_c('CompatibilityModal',{attrs:{"currentOffer":selectedEntity,"offers":entities},on:{"close":_vm.onCloseCompatibilityModal,"cancel":_vm.onCancelCompatibilityModal}}):_vm._e()]}},{key:"operations",fn:function(ref){
var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.operation,"stateMap":_vm.statusesOperation,"stateMapColor":_vm.STATUS_OPERATIONS_INDICATOR_MAP}})]}},{key:"overviewButtons",fn:function(ref){
var selectedEntity = ref.selectedEntity;
return [(
                _vm.permissionsService.productCatalogWrite() &&
                _vm.isUserAllowed('OffersWrite') &&
                !_vm.isDraft(selectedEntity) &&
                !_vm.permissionsService.isFeatureFlagEnabled('validation-rules')
            )?_c('AppButton',{staticClass:"pl-0",attrs:{"data-test-id":"compatibilityModalBtn","iconType":_vm.ICON_TYPES.PLUS,"label":_vm.$i18n.t('productCatalog.compatibilityRules.editCompatibilityRules')},on:{"click":_vm.onEditCompatibilityRulesButtonClicked}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }