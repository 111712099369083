//
import values from 'lodash/values';
import invert from 'lodash/invert';
import i18n from '@/i18n';

const Present = 'Present';
const Absent = 'Absent';
const IsEmptyObject = 'IsEmptyObject';
const IsNonEmptyObject = 'IsNonEmptyObject';
const IsEmptyArray = 'IsEmptyArray';
const IsNonEmptyArray = 'IsNonEmptyArray';
const isNull = 'isNull';
const isNotNull = 'isNotNull';

export const NoArgOperations = {
    Present,
    Absent,
    IsEmptyObject,
    IsNonEmptyObject,
    IsEmptyArray,
    IsNonEmptyArray,
    isNull,
    isNotNull,
};

export const ObjectOperations = {
    Present,
    Absent,
    IsEmptyObject,
    IsNonEmptyObject,
    isNull,
    isNotNull,
};

export const ObjectOperationsToLabels = {
    [ObjectOperations.IsEmptyObject]: i18n.t('segments.filters.operations.isEmpty'),
    [ObjectOperations.IsNonEmptyObject]: i18n.t('segments.filters.operations.isNotEmpty'),
    [ObjectOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [ObjectOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [ObjectOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [ObjectOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const ObjectOperationLabels = values(ObjectOperationsToLabels);
export const ObjectOperationLabelsToType = invert(ObjectOperationsToLabels);

export const ArrayOperations = {
    Present,
    Absent,
    IsEmptyArray,
    IsNonEmptyArray,
    isNull,
    isNotNull,
};

export const ArrayOperationsToLabels = {
    [ArrayOperations.IsEmptyArray]: i18n.t('segments.filters.operations.isEmpty'),
    [ArrayOperations.IsNonEmptyArray]: i18n.t('segments.filters.operations.isNotEmpty'),
    [ArrayOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [ArrayOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [ArrayOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [ArrayOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const ArrayOperationLabels = values(ArrayOperationsToLabels);
export const ArrayOperationLabelsToType = invert(ArrayOperationsToLabels);

export const JsonPathOperations = {
    Present,
    Absent,
    isNull,
    isNotNull,
};

export const JsonPathOperationsToLabels = {
    [JsonPathOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [JsonPathOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [JsonPathOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [JsonPathOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const JsonPathOperationLabels = values(JsonPathOperationsToLabels);
export const JsonPathOperationLabelsToType = invert(JsonPathOperationsToLabels);

// todo ENUM
// values are mapped on server side
export const NumberOperations = {
    LessThan: 'LessThan',
    GreaterThan: 'GreaterThan',
    NotEquals: 'NotEquals',
    Between: 'Between',
    Equals: 'Equals',
    Present,
    Absent,
    isNull,
    isNotNull,
};

export const NumberOperationsToLabels = {
    [NumberOperations.LessThan]: 'Less than',
    [NumberOperations.GreaterThan]: 'More than',
    [NumberOperations.Between]: 'Between',
    [NumberOperations.Equals]: 'Exactly',
    [NumberOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [NumberOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [NumberOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [NumberOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const PropFilterNumberOperationToLabel = {
    [NumberOperations.LessThan]: 'Lesser than',
    [NumberOperations.GreaterThan]: 'Greater than',
    [NumberOperations.NotEquals]: 'Not equals',
    [NumberOperations.Between]: 'Between',
    [NumberOperations.Equals]: 'Equals',
    [NumberOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [NumberOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [NumberOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [NumberOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const PropFilterNumberOperationLabels = values(PropFilterNumberOperationToLabel);
export const PropFilterNumberOperationLabelToType = invert(PropFilterNumberOperationToLabel);

// values are mapped on server side
export const StringOperations = {
    Contains: 'Contains',
    NotContains: 'NotContains',
    StartsWith: 'StartsWith',
    EndsWith: 'EndsWith',
    Equals: 'Equals',
    NotEquals: 'NotEquals',
    Present: 'Present',
    Absent: 'Absent',
    isNull,
    isNotNull,
};

/** Map string operations to labels */
export const StringOperationsToLabels = {
    [StringOperations.Contains]: 'Contains',
    [StringOperations.NotContains]: 'Not contains',
    [StringOperations.StartsWith]: 'Starts with',
    [StringOperations.EndsWith]: 'Ends with',
    [StringOperations.Equals]: 'Equals',
    [StringOperations.NotEquals]: 'Not equals',
    [StringOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [StringOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [StringOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [StringOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const StringOperationLabels = values(StringOperationsToLabels);

/** Maps labels to string operations */
export const StringOperationLabelsToType = invert(StringOperationsToLabels);

export const DropdownOperationToLabels = {
    [StringOperations.Equals]: StringOperationsToLabels[StringOperations.Equals],
    [StringOperations.NotEquals]: StringOperationsToLabels[StringOperations.NotEquals],
    [StringOperations.Present]: StringOperationsToLabels[StringOperations.Present],
    [StringOperations.Absent]: StringOperationsToLabels[StringOperations.Absent],
    [StringOperations.isNull]: StringOperationsToLabels[StringOperations.isNull],
    [StringOperations.isNotNull]: StringOperationsToLabels[StringOperations.isNotNull],
};

export const DropdownOperationLabelsToType = invert(DropdownOperationToLabels);

// values should be unique, to separate it from other ops
export const DateOperations = {
    After: 'date_after',
    Before: 'date_before',
    Between: 'date_between',
    Present,
    Absent,
    isNull,
    isNotNull,
};

export const DateOperationsToLabels = {
    [DateOperations.After]: 'After',
    [DateOperations.Before]: 'Before',
    [DateOperations.Between]: 'Between',
    [DateOperations.Present]: i18n.t('segments.filters.operations.isPresent'),
    [DateOperations.Absent]: i18n.t('segments.filters.operations.isAbsent'),
    [DateOperations.isNull]: i18n.t('segments.filters.operations.isNull'),
    [DateOperations.isNotNull]: i18n.t('segments.filters.operations.isNotNull'),
};

export const DateOperationLabels = values(DateOperationsToLabels);

export const DateOperationLabelsToTypes = invert(DateOperationsToLabels);

/** This is a combination of different operations */
export const PeriodOperations = {
    LessThan: NumberOperations.LessThan,
    GreaterThan: NumberOperations.GreaterThan,
    Between: NumberOperations.Between,
    Equals: NumberOperations.Equals,
    AfterDate: DateOperations.After,
    BeforeDate: DateOperations.Before,
    BetweenDate: DateOperations.Between,
    InMonth: 'InMonth',
    Present,
    Absent,
    isNull,
    isNotNull,
};

export const PeriodOperationLabels = {
    [PeriodOperations.GreaterThan]: NumberOperationsToLabels[NumberOperations.GreaterThan],
    [PeriodOperations.LessThan]: NumberOperationsToLabels[NumberOperations.LessThan],
    [PeriodOperations.Equals]: NumberOperationsToLabels[NumberOperations.Equals],
    [PeriodOperations.Between]: NumberOperationsToLabels[NumberOperations.Between],
    [PeriodOperations.AfterDate]: 'After date',
    [PeriodOperations.BeforeDate]: 'Before date',
    [PeriodOperations.BetweenDate]: 'Between dates',
    [PeriodOperations.InMonth]: 'In (month)',
    [PeriodOperations.Present]: NumberOperationsToLabels[NumberOperations.Present],
    [PeriodOperations.Absent]: NumberOperationsToLabels[NumberOperations.Absent],
    [PeriodOperations.isNull]: NumberOperationsToLabels[NumberOperations.isNull],
    [PeriodOperations.isNotNull]: NumberOperationsToLabels[NumberOperations.isNotNull],
};

export const PeriodLabelsToOperations = invert(PeriodOperationLabels);
