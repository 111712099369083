












































import AppButton from '@/components/partials/inputs/AppButton.vue';

const DEFAULT_DESCRIPTION_MAX_HEIGHT = 55;

export default {
    name: 'ListDropdownSectionItem',
    components: { AppButton },
    props: {
        descriptionMaxHeight: {
            type: Number,
            default: DEFAULT_DESCRIPTION_MAX_HEIGHT, // px
        },
        verticalAlignTop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isCollapsed: true,
            isExceed: false,
            mutationObserver: null,
        };
    },
    computed: {
        isElipsisEnabled() {
            return this.isExceed && this.isCollapsed && this.descriptionMaxHeight === DEFAULT_DESCRIPTION_MAX_HEIGHT;
        },
    },
    mounted(): void {
        if (this?.$refs?.descriptionContent) {
            this.mutationObserver = new MutationObserver(this.checkHeightExceedsMaxHeight);
            this.mutationObserver.observe(this.$refs.descriptionContent, { characterData: true, subtree: true });
            this.checkHeightExceedsMaxHeight();
        }
    },
    beforeDestroy(): void {
        if (this?.$refs?.descriptionContent) {
            this.mutationObserver.disconnect(this.$refs.descriptionContent);
        }
    },
    methods: {
        checkHeightExceedsMaxHeight(): void {
            this.isExceed = this.$refs.descriptionContent?.clientHeight + 1 > this.descriptionMaxHeight;
        },
    },
};
