


































































// Vue components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers & Misc
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    DURATION_OPTIONS,
    DurationOption,
} from '@/__new__/features/orchestrationengine/common/orchestrationPausedExecutionsHelper';
import RouteNames from '@/router/routeNames';

// HTTP
import { updateScheduledExecutionTime } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Error handling
import * as Sentry from '@sentry/vue';

export default {
    name: 'UpdateScheduledTimeSidebar',

    components: {
        AppDialogV2,
        AppMultiselectV3,
        DateTimePicker,
        AppToggleV2,
        AppButton,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        executionId: {
            required: true,
            type: String,
        },
        planId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            // Proxy imports
            BUTTON_TYPES,
            ICON_TYPES,
            DURATION_OPTIONS: DURATION_OPTIONS as DurationOption[],
            RouteNames: RouteNames as any,

            calendarEnabled: false as boolean,
            selectedTime: null as any,
            selectedDuration: null as null | string,
            updateTimestampButtonClicked: false as boolean,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    created() {
        this.selectedDuration = DURATION_OPTIONS[0].id;
    },

    methods: {
        async updateScheduledTimestamp(): Promise<any> {
            try {
                this.updateTimestampButtonClicked = true;

                this.$Progress.start();

                await updateScheduledExecutionTime({
                    executionId: this.executionId,
                    ...(this.calendarEnabled
                        ? { scheduleToTimestamp: this.selectedTime.valueOf() }
                        : { duration: this.selectedDuration }),
                });

                this.$showSuccessAlert({
                    message: this.$i18n.t('orchestrationEngine.updateScheduledTimeSuccess'),
                });

                this.$Progress.finish();

                this.$emit('updateScheduledTime');
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$showErrorAlert('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.updateScheduledTimeFail'),
                });
            } finally {
                this.updateTimestampButtonClicked = false;
            }
        },
        onEnableCalendarClick(value: boolean): void {
            this.calendarEnabled = value;
        },
    },
};
