var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAditionalSidebar',{attrs:{"visible":_vm.showSidebar},on:{"input":function (val) { return _vm.hideSidebar(val); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"modal-row d-flex align-items-center"},[_c('div',{staticClass:"all-caps-text"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.orderDetails'))+" ")])])]},proxy:true},{key:"content",fn:function(){return [_c('section',[_c('AppTable',{staticClass:"auto-height",attrs:{"entities":_vm.items,"isDefaultHoverEnabled":false,"forbidHideColumn":true,"disableBoxShadow":true,"newDesign":true,"canSelectColumns":false,"columnsData":_vm.itemsColumnsData,"tableKey":"customer-care-suite/orders-sidebar","keyName":"executionId"},scopedSlots:_vm._u([{key:"planId",fn:function(ref){
var entity = ref.entity;
return [(entity.planId)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                params: {
                                    id: entity.planId,
                                    execId: entity.executionId,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.planId)+" ")])],1):_vm._e()]}},{key:"status",fn:function(ref){
                            var entity = ref.entity;
return [_c('ExecutionStatusIndicator',{attrs:{"status":entity.status}})]}}])})],1),(_vm.isOePlansVisible)?[_c('section',[_c('div',{staticClass:"modal-row d-flex align-items-center"},[_c('div',{staticClass:"all-caps-text"},[_vm._v(" "+_vm._s(_vm.$i18n.t('orchestrationEngine.oeWorkflow'))+" ")])]),_c('AppTable',{staticClass:"auto-height",attrs:{"entities":_vm.oeMappingsList,"isDefaultHoverEnabled":false,"forbidHideColumn":true,"disableBoxShadow":true,"newDesign":true,"canSelectColumns":false,"isDataLoading":_vm.isOeLoading,"columnsData":_vm.oeColumnsData,"tableKey":"customer-care-suite/orders-sidebar","keyName":"executionId"},scopedSlots:_vm._u([{key:"planId",fn:function(ref){
                            var entity = ref.entity;
return [(entity.planId)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                    name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: entity.planId,
                                        execId: entity.executionId,
                                    },
                                },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.planId)+" ")])],1):_vm._e()]}},{key:"status",fn:function(ref){
                                var entity = ref.entity;
return [_c('ExecutionStatusIndicator',{attrs:{"status":entity.status}})]}}],null,false,216890783)})],1)]:_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }