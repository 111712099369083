























































































































































































import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// HTTP
import { getContentTypeEntityDraft } from '@/__new__/services/dno/charging/http/contentType';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// helpers
import { languageMap } from '@/common/locale/language';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getProperlyFormattedMultilangFieldValue, EDITOR_MODE } from '@/common/entities/entityHelper';
import cloneDeep from 'lodash/cloneDeep';
import { ContentType, ChargingEntityVersion } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { ContentTypeDraft } from '@/__new__/services/dno/charging/models/ContentType';
import { LABEL_COLOR } from '@/common/labelsHelper';
import Button from '@/common/button/Button';
import { STATUS_CODES } from '@/common/commonHelper';
import { loadVersionHistory, CHARGING_ENTITY_TYPES } from '@/__new__/services/dno/charging/common/versioningHelper';

export default Vue.extend({
    name: 'ContentTypeEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppButton,
        AppLabel,
        EditorButtons,
        AppIcon,
        EntityStatusIndicator,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            name: {} as string | Record<string, string>,
            description: {} as string | Record<string, string>,
            remark: '' as string,
            appName: '' as string,
            isTransferable: false as boolean,
            approveOnCreate: false as boolean,
            data: null as any,
            entityType: ENTITY_TYPES.CONTENT_TYPES as string,
            version: null as number | null,
            selectedLanguage: '' as string,
            entityId: null as string | null,
            readonly: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            isOnlyDraft: false as boolean,
            updateTime: null as number | null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            LABEL_COLOR,
            EDITOR_MODE,
            STATUS_CODES,
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as string | null,
            state: EntityStateMapping.UNAPPROVED,
        };
    },
    validations: {
        name: {
            en: {
                required,
            },
        },
        appName: {
            required,
        },
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_APPROVED_CONTENT_TYPES,
            Getters.GET_CONTENT_TYPE_BY_ID,
            Getters.GET_CONTENT_TYPE_BY_VERSION,
        ]),
        isNewElement(): boolean {
            return !this.entityId || this.$route.params.clone;
        },
        contentTypeData(): ContentType {
            return this[Getters.GET_CONTENT_TYPE_BY_ID](this.entityId);
        },
        contentTypes(): ContentType[] {
            return this[Getters.GET_APPROVED_CONTENT_TYPES];
        },
        contentTypeByVersion(): ContentType | null {
            return this[Getters.GET_CONTENT_TYPE_BY_VERSION];
        },
        alreadyUsedName(): boolean {
            if (this.isEditingEntityExisting) {
                if (this.name.en !== this.contentTypeData?.name) {
                    return Object.values(this.contentTypes).some((uc: any) => uc.name === this.name.en);
                }
                return false;
            }
            return Object.values(this.contentTypes).some((uc: any) => uc.name === this.name.en);
        },
        isEditingEntityExisting(): boolean {
            return Boolean(this.entityId && !this.$route.params.clone);
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('charging.contentTypes.name');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.contentTypes.editCy');
            }
            return this.$i18n.t('charging.contentTypes.addnewCy');
        },
        dontShowOnPublishedAndDraft(): boolean {
            return !this.isDraft && !this.isPublished;
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone, chargingVersion } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                if (chargingVersion) {
                    this.currentVersion = chargingVersion;
                }

                const promises = [this[Actions.REQUEST_CONTENT_TYPES]()];

                if (!clone && !this.readonly) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (this.entityId) {
                    this.initData(false, !this.currentVersion);
                    if (this.readonly) {
                        this.loadVersionHistory(CHARGING_ENTITY_TYPES.CONTENT_TYPE, this.entityId, chargingVersion);
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_CONTENT_TYPES, Actions.REQUEST_CONTENT_TYPE_BY_VERSION]),
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            if (this.entityId) {
                const ct = this.contentTypeData;
                let ctData: any;

                if (initVersion) {
                    this.currentVersion = ct?.chargingVersion || null;
                }

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new ContentTypeDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = ct.version;
                    ctData = draftData;
                    this.isOnlyDraft = ct?.state === STATUS_CODES.NA;

                    if (ct?.update_time <= ctData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityId, this.currentVersion);
                    ctData = cloneDeep(this.contentTypeByVersion);
                } else {
                    ctData = cloneDeep(ct);
                }

                const {
                    name,
                    description,
                    appName,
                    isTransferable,
                    version,
                    update_time: updateTime,
                    updatePortalId,
                    state,
                } = ctData;

                const properlyFormattedName = getProperlyFormattedMultilangFieldValue(name);
                if (this.$route.params.clone) {
                    Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                        properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                    });
                }

                this.name = properlyFormattedName;
                this.description = getProperlyFormattedMultilangFieldValue(description);
                this.appName = appName;
                this.isTransferable = isTransferable;
                this.version = version;
                this.updateTime = updateTime;
                this.state = state;
                this.getUpdateUserName(updatePortalId);
            }
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.contentTypes.uniqueName'),
                });
                return false;
            }

            return true;
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.data = {
                name: this.name,
                description: this.description,
                remark: this.remark,
                app_name: this.appName,
                is_transferable: this.isTransferable,
            };

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getContentTypeEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_CONTENT_TYPES,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_CONTENT_TYPE_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        async loadVersionHistory(
            entityType: CHARGING_ENTITY_TYPES,
            id: string,
            initChargingVersion?: string,
        ): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_CONTENT_TYPE_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
    },
});
