





















































import Vue, { type PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
// types???
import { type ReportArchive } from '@/__new__/features/reports/common/reportArchiveHelper';
// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

export default Vue.extend({
    name: 'ReportsArchiveDialog',
    components: {
        AppDialogV2,
        AppIcon,
        AppButton,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        archives: {
            type: Array as PropType<ReportArchive[]>,
            default: () => [],
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    computed: {
        isVisible(): boolean {
            return this.visible && !!this.archives.length;
        },
    },
});
