import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import * as Utils from '@/common/utils';
import { DnoResponseBase } from '@/http/index';

export enum TABLES_TABS_IDS {
    VOICE_SMS_USAGE = 'voiceSmsUsage',
    DATA_USAGE = 'dataUsage',
}

export enum RECORD_CATEGORY {
    VOICE_AND_SMS = 1,
    GPRS_AND_MMS = 2,
}

export const MAP_TABLES_TABS_IDS_TO_RECORD_CATEGORY: Record<TABLES_TABS_IDS, RECORD_CATEGORY> = {
    [TABLES_TABS_IDS.VOICE_SMS_USAGE]: RECORD_CATEGORY.VOICE_AND_SMS,
    [TABLES_TABS_IDS.DATA_USAGE]: RECORD_CATEGORY.GPRS_AND_MMS,
};

export interface DailyUsageApiResponse extends DnoResponseBase {
    records: DailyUsageConstructorArgs[] | Record<string, never>;
    page_token?: string;
}

export const USAGE_SUB_TYPE_VOICE = new Map([
    ['1', 'voiceOnNet'],
    ['2', 'airtimeDomesticRoaming'],
    ['3', 'airtimeInternationalRoaming'],
    ['4', 'SMSOnNetSMS'],
    ['5', 'SMSDomesticRoaming'],
    ['6', 'SMSInternationalRoaming'],
]);

export const USAGE_CALL_DIRECTION_VOICE = new Map([
    ['0', 'mobileOriginating'],
    ['1', 'mobileTerminating'],
    ['2', 'roamingCallForward'],
]);

export const USAGE_SUB_TYPE_DATA = new Map([
    ['1', 'GPRSVolume'],
    ['2', 'GPRSDomesticRoamingUsage'],
    ['3', 'GPRSInternationalRoamingUsage'],
    ['4', 'MMSEvent'],
    ['5', 'MMSDomesticRoamingEvent'],
    ['6', 'MMSInternationalRoamingEvent'],
    ['7', 'homeRoutedInternationalRoamingEvent'],
    ['8', 'whitelistedGPRSOnNetUsage'],
    ['9', 'whitelistedGPRSDomesticRoamingUsageReserved'],
    ['10', 'whiteListedGPRSInternationalRoamingUsageReservedWalmart'],
]);

function getUsageSubTypeLabel(usageSubType: string, usageType: string) {
    if ((usageType === 'voice' || usageType === 'sms') && USAGE_SUB_TYPE_VOICE.get(usageSubType)) {
        return i18n.t('customerCare.serviceTransactionsTile.' + USAGE_SUB_TYPE_VOICE.get(usageSubType));
    }
    if (usageType === 'data' && USAGE_SUB_TYPE_DATA.get(usageSubType)) {
        return i18n.t('customerCare.serviceTransactionsTile.' + USAGE_SUB_TYPE_DATA.get(usageSubType));
    }
    return '';
}

function getCallDirection(callDirection: string) {
    if (USAGE_CALL_DIRECTION_VOICE.get(callDirection)) {
        return i18n.t('customerCare.serviceTransactionsTile.' + USAGE_CALL_DIRECTION_VOICE.get(callDirection));
    }
    return '';
}

export type DailyUsageConstructorArgs = {
    usage_type: string;
    subscriber_imsi: string;
    msisdn: string;
    start_time_of_usage: string;
    subscriber_imei: string;
    usage_sub_type: string;
    on_net_usages: string;
    country_name: string;
    technology_used: string;
    call_terminating_number?: string;
    call_terminating_location?: string;
    call_terminating_state?: string;
    duration_rounded_min?: string;
    duration_rounded_sec?: string;
    call_direction?: string;
    total_volume?: string;
    session_duration?: string;
    mms_indicator?: string;
    uplink_volume?: string;
    downlink_volume?: string;
    service_type?: number;
};

export class DailyUsage {
    id: string;
    usageType: string;
    subscriberImsi: string;
    msisdn: string;
    startTimeOfUsage: string;
    subscriberImei: string;
    usageSubType: string | TranslateResult;
    onNetUsages: string | TranslateResult;
    countryName: string | TranslateResult;
    technologyUsed: string;
    callTerminatingNumber: string | TranslateResult;
    callTerminatingLocation: string | TranslateResult;
    callTerminatingState: string | TranslateResult;
    durationRoundedMin: string | TranslateResult;
    durationRoundedSec: string | TranslateResult;
    callDirection: string | TranslateResult;
    totalVolume: string | TranslateResult;
    sessionDuration: string | TranslateResult;
    mmsIndicator: string | TranslateResult;
    uplinkVolume: string | TranslateResult;
    downlinkVolume: string | TranslateResult;
    serviceType: number | null;

    constructor(data: DailyUsageConstructorArgs) {
        this.id = Utils.uuidV4();
        this.usageType = data.usage_type;
        this.subscriberImsi = data.subscriber_imsi;
        this.msisdn = data.msisdn;
        this.startTimeOfUsage = data.start_time_of_usage;
        this.subscriberImei = data.subscriber_imei;
        this.usageSubType = getUsageSubTypeLabel(data.usage_sub_type, data.usage_type) || i18n.t('generic.empty');
        this.onNetUsages = data.on_net_usages || i18n.t('generic.empty');
        this.countryName = data.country_name || i18n.t('generic.empty');
        this.technologyUsed = data.technology_used;
        this.callTerminatingNumber = data.call_terminating_number || i18n.t('generic.empty');
        this.callTerminatingLocation = data.call_terminating_location || i18n.t('generic.empty');
        this.callTerminatingState = data.call_terminating_state || i18n.t('generic.empty');
        this.durationRoundedMin = data.duration_rounded_min || i18n.t('generic.empty');
        this.durationRoundedSec = data.duration_rounded_sec || i18n.t('generic.empty');
        this.callDirection = getCallDirection(data.call_direction || '') || i18n.t('generic.empty');
        this.totalVolume = data.total_volume || i18n.t('generic.empty');
        this.sessionDuration = data.session_duration || i18n.t('generic.empty');
        this.mmsIndicator = data.mms_indicator || i18n.t('generic.empty');
        this.uplinkVolume = data.uplink_volume || i18n.t('generic.empty');
        this.uplinkVolume = data.uplink_volume || i18n.t('generic.empty');
        this.downlinkVolume = data.downlink_volume || i18n.t('generic.empty');
        this.serviceType = data.service_type || null;
    }
}
