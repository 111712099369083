// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SINK_CONFIG_STATUS_FROM_SERVER_CODE_MAP } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import i18n from '@/i18n';
import { validateName } from '@/common/events/eventsHelper';
import {
    configColumnDefaultTypeIsValid,
    configColumnHasCorrectDefaultValue,
    configColumnHasDifferentEventSources,
    configColumnHasNoRelatedEvent,
    configColumnPropertiesHaveTheSameType,
    isMixedColumnTypeWithoutJsonPathValue,
    SinkConfigCol,
    sinkConfigColFromJson,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import SinkFormatter from '@/__new__/services/dno/sinkConfigs/models/SinkFormatter';
import { SinkConfigSettings } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigSettings';
import { TranslateResult } from 'vue-i18n';
import { type JOB_TYPE } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';
import { groupBy, isEmpty, omit, some } from 'lodash';
import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import { isNonEmptyCohortExpression } from '@/common/cohortExpressionHelper';
import CohortConfig, {
    configCohortsDataFromJson,
    configCohortsDataToJson,
} from '@/__new__/services/dno/sinkConfigs/models/CohortConfig';

// types
export type SinkSpecificConfig = {
    dependsOnSinkEventName: boolean;
    propsData: {
        value: string;
        label: string;
        placeholder: string;
    };
    shouldSendIfEmpty: false;
    type: 'AppInputV3' | 'AppMultiselectV3' | 'AppToggle';
    validationFunctions: ['required' | 'validateSnakeCase' | 'isNumber'];
    name: string;
    visibleForAll: boolean;
    isValid: boolean;
    errorMsg: string | TranslateResult;
};
export type SinkRequiredFields = {
    casting_settings: {
        name: string;
        final_field_type: CDP_PROPERTY_TYPE;
    };
    sink_event_field_name: string;
    sink_event_field_type: CDP_PROPERTY_TYPE.INTEGER | CDP_PROPERTY_TYPE.STRING;
    formatting_settings?: {
        name: string;
        final_field_type: CDP_PROPERTY_TYPE;
        params: string[];
    };
    custom_event_field_name: string;
    custom_event_field_type: CDP_PROPERTY_TYPE.INTEGER | CDP_PROPERTY_TYPE.STRING;
};
export type SinkConfigJsonPath = {
    jsonPath: string;
    type: string;
};
export class SinkConfig {
    name: string;
    id: string | null;
    filters?: any[];
    specificConfig: SinkSpecificConfig;
    version: number | null;
    state: number | string | null;
    fieldsConfiguration: any[];
    updateTime: string | null | number;
    jobType: JOB_TYPE | null;
    allowFieldsConfigurationEdit: boolean;
    cohortsData: Record<string, CohortConfig>;
    constructor({
        name,
        id,
        filters,
        specificConfig,
        version,
        state,
        fieldsConfiguration,
        updateTime,
        jobType,
        allowFieldsConfigurationEdit = true,
        cohortsData,
    }: Partial<SinkConfig> = {}) {
        this.name = name || '';
        this.id = id || null;
        this.filters = filters || [];
        this.specificConfig = specificConfig || {};
        this.version = version || null;
        this.state = state || null;
        this.fieldsConfiguration = fieldsConfiguration || [];
        this.updateTime = updateTime || null;
        this.jobType = jobType || null;
        this.allowFieldsConfigurationEdit = allowFieldsConfigurationEdit;
        this.cohortsData = cohortsData || {};
    }

    toJson(isEditing = false) {
        return {
            data: {
                name: this.name,
                specific_configuration: this.specificConfig,
                trigger_condition: {
                    id_type: null,
                    filters: this.filters.map(f => omit(f, 'filterDefinitionId')),
                },
                fields_configuration: this.fieldsConfiguration,
                state: this.state,
                // Value sent to the BE needs to be an array!
                cohorts_configuration: configCohortsDataToJson(this.cohortsData),
            },
            id: isEditing ? this.id : undefined,
            version: isEditing ? this.version : undefined,
        };
    }
}
export const sinkConfigColumnTypes = {
    BOOLEAN: 'boolean',
    INT: 'int',
    STRING: 'string',
    LONG: 'long',
    DOUBLE: 'double',
    DATE: 'date',
    TIMESTAMP: 'timestamp',
};

export const ORD_SINK_MAPPING = {
    HEADER_VALUES: {
        YES: 'Yes',
        NO: 'No',
    },
};

export function replaceIdByEventTypeInTriggerCondition(triggerCondition) {
    return {
        ...triggerCondition,
        filters: triggerCondition?.filters.map(f => ({
            ...f,
            filterDefinitionId: Object.keys(f.values[0])[0],
        })),
    };
}

export function createSinkConfigFromResponse(
    response: any,
    events: any[],
    formatters: SinkFormatter[],
    uiSettings: SinkConfigSettings,
    segments: any[],
): SinkConfig {
    return response.map((entity: any) => {
        const filters = replaceIdByEventTypeInTriggerCondition(entity.data.trigger_condition)?.filters;
        return new SinkConfig({
            name: entity.data.name,
            id: entity.data?.specific_configuration?.table_name || entity.id,
            filters,
            specificConfig: entity.data.specific_configuration,
            version: entity.version,
            state:
                SINK_CONFIG_STATUS_FROM_SERVER_CODE_MAP[entity.state] ||
                SINK_CONFIG_STATUS_FROM_SERVER_CODE_MAP[entity.data.state],
            fieldsConfiguration: sinkConfigColFromJson(
                entity.data.fields_configuration,
                entity.state,
                events,
                formatters,
                uiSettings,
            ),
            updateTime: entity.update_time,
            allowFieldsConfigurationEdit: true,
            cohortsData: isEmpty(entity.data.cohorts_configuration)
                ? undefined
                : configCohortsDataFromJson(entity.data.cohorts_configuration, filters, segments),
        });
    });
}

export function changeConfigDefaultValueType(value: string, type: string): boolean | number | string {
    if (type === sinkConfigColumnTypes.BOOLEAN) {
        return value === 'true';
    }
    if (
        type === sinkConfigColumnTypes.DOUBLE ||
        type === sinkConfigColumnTypes.LONG ||
        type === sinkConfigColumnTypes.INT
    ) {
        return Number(value);
    }
    return value;
}
export function configColumnRowsToJson(rowValues: any[]): any {
    const result: Record<string, any> = {};

    rowValues.forEach((rowValue: any) => {
        result[rowValue.eventType] = {
            // todo: remove custom_event prefix
            custom_event_field_name: rowValue.property.name,
            ...(rowValue.isJsonPathEnabled && {
                custom_event_field_jsonpath: rowValue.jsonPath.jsonPath,
                field_jsonpath_type: rowValue.jsonPath.type,
            }),
        };
    });
    return result;
}

export function configColumnPropertiesHasDuplications(column: SinkConfigCol): boolean {
    const mappedRowProperties = column.rowValues.map(row => row.eventName + row.property);
    return mappedRowProperties.length !== new Set(mappedRowProperties).size;
}

export function checkNamesDuplicates(columns: SinkConfigCol[]): string[] {
    return Object.values(groupBy(columns, 'name'))
        .filter(({ length }) => length > 1)
        .flat()
        .map(({ id }) => id);
}

export function getIdsOfMixedColumns(columns: SinkConfigCol[]): string[] {
    return columns.filter(col => col.defaultValueType === CDP_PROPERTY_TYPE.MIXED).map(({ id }) => id);
}

export enum STEP_NAMES {
    GENERAL = 'General',
    EVENT_SELECTION = 'Event selection',
    FIELDS_CONFIG = 'Fields config',
    COHORT_EXPRESSION = 'Cohort expression',
    REVIEW = 'Review',
}
type ValidationErrorObject = {
    errMsg: TranslateResult | string;
    id?: string;
};
export function validateFieldsConfigStep(
    columnsConfigData: SinkConfigCol[],
    uiSettings: SinkConfigSettings,
): ValidationErrorObject | void {
    if (!columnsConfigData.length) {
        return { errMsg: i18n.t('sinkConfigs.alerts.addAtLeastOneColumn') };
    }

    function createErrorObj(
        msg: TranslateResult,
        ids?: string[],
    ): { errMsg: TranslateResult; errColumnIds?: string[] } {
        return {
            errMsg: msg,
            ...(ids ? { errColumnIds: ids } : {}),
        };
    }
    for (let i = 0; i < columnsConfigData.length; i++) {
        const column = columnsConfigData[i];
        if (!column.name) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnNameShouldNotBeEmpty'), [column.id]);
        }
        if (!validateName(column.name)) {
            if (!uiSettings.dontUseSnakeCaseInColumnNames) {
                return createErrorObj(i18n.t('sinkConfigs.alerts.failedNameValidation'), [column.id]);
            }
        }
        if (configColumnHasNoRelatedEvent(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnHasNoRelatedEvent'), [column.id]);
        }
        if (configColumnHasDifferentEventSources(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnCanContainOneFieldFromEvent'), [column.id]);
        }
        if (configColumnPropertiesHasDuplications(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnFieldsShouldNotBeDuplicated'), [column.id]);
        }
        if (!configColumnPropertiesHaveTheSameType(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnFieldsShouldHaveTheSameType'), [column.id]);
        }
        if (isMixedColumnTypeWithoutJsonPathValue(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnMixedTypeIsNotAllowed'), [column.id]);
        }
        if (!configColumnHasCorrectDefaultValue(column)) {
            return createErrorObj(i18n.t('sinkConfigs.alerts.columnDefaultNameShouldNotBeEmpty'), [column.id]);
        }
        if (column.isDefaultValueEnabled && column.defaultValue) {
            const defValValidResultMsg = configColumnDefaultTypeIsValid(column);
            if (defValValidResultMsg) {
                return createErrorObj(defValValidResultMsg, [column.id]);
            }
        }
        if ((column.doc || '').length > 300) {
            return createErrorObj(i18n.t('events.alerts.descriptionMaxLength', { max: 300 }), [column.id]);
        }
    }
    const duplicatedColumnIds = checkNamesDuplicates(columnsConfigData);
    if (duplicatedColumnIds.length !== 0) {
        return createErrorObj(i18n.t('sinkConfigs.alerts.columnsShouldHaveUniqueNames'), duplicatedColumnIds);
    }
    const colsWithMixedType = getIdsOfMixedColumns(columnsConfigData);
    if (colsWithMixedType.length !== 0) {
        return createErrorObj(i18n.t('sinkConfigs.alerts.columnMixedTypeIsNotAllowed'), colsWithMixedType);
    }
}
export function isCohortExpressionsStepValid(cohortsData) {
    return some(cohortsData, cohort => {
        return isNonEmptyCohortExpression(cohort.cohortsObj);
    });
}
// is used to validate dynamic components sent from BE
export const sinkValidationFunctions = {
    required: {
        fn: (str = '') => str.length > 0,
        errorMsg: i18n.t('generic.validations.fieldIsRequired'),
    },
    validateSnakeCase: {
        fn: (str = '') => {
            const regexp = new RegExp('^([a-z][a-z0-9]*)(_[a-z0-9]+)*$');
            return regexp.test(str) || str.length === 0;
        },
        errorMsg: i18n.t('generic.validations.snakeCaseValidationError'),
    },
    isNumber: {
        fn: (str = '') => {
            const regexp = new RegExp('^[0-9]+$');
            return regexp.test(str);
        },
        errorMsg: i18n.t('generic.validations.valueShouldBeNumber'),
    },
};
export const specificConfigurationJson = (specificConfiguration: SinkSpecificConfig) => {
    const result = {};
    const fieldsToSend = specificConfiguration.filter(config => config.shouldSendIfEmpty || config.propsData.value);
    fieldsToSend.forEach(config => {
        result[config.name] = config.propsData.value;
    });
    return result;
};
export const transformSpecificConfigLabel = (configName, uiParams) => {
    const relatedConfig = uiParams?.specificConfig?.find(uiConfig => uiConfig.name === configName);
    return relatedConfig?.propsData?.label || relatedConfig?.propsData?.additionalLabel || configName;
};
export const isAdditionalConfig = (configName, uiParams) => {
    const relatedConfig = uiParams?.specificConfig?.find(uiConfig => uiConfig.name === configName);
    return relatedConfig?.isAdditionalConfig;
};
export const groupEventPropertiesByType = properties => {
    return Object.entries(groupBy(properties, 'type')).map(([type, props]) => ({
        groupName: type,
        groupValues: props,
    }));
};
export default {
    SinkConfig,
};
