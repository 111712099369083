<template>
    <div
        class="auth-container"
        :class="{ 'app-in-iframe': isAppInIframe }"
    >
        <!-- Sidebar -->
        <Sidebar class="sidebar-container" />

        <!-- Main content -->
        <AppLoading
            :class="{ 'compact-sidebar': compactSidebar }"
            class="page-content"
        >
            <router-view class="view h-100" />
            <Notification />
        </AppLoading>
    </div>
</template>

<script>
import { mapState } from 'vuex';

// Vue Components
import Sidebar from '@/components/layout/Sidebar.vue';
import Notification from '@/components/partials/Notification.vue';
import AppLoading from '@/components/partials/AppLoading.vue';

// Models
import Account from '@/models/Account';

// libraries and helpers
import * as Sentry from '@sentry/vue';

export default {
    name: 'AuthorizedContainer',

    components: {
        Sidebar,
        Notification,
        AppLoading,
    },

    computed: {
        ...mapState('operators', ['availableOperators']),
        currentCompany() {
            return this.availableOperators?.[this.$route.params.companyId];
        },
        compactSidebar() {
            return this.$store.state.compactSidebar;
        },
        isAppInIframe() {
            return window !== window.parent;
        },
    },

    watch: {
        currentCompany: {
            handler() {
                this.updateSentryContext();
            },
            immediate: true,
        },
        $route: {
            handler() {
                this.updateSentryContext();
            },
            immediate: true,
        },
    },

    created() {
        this.$store.dispatch('operators/setAllOperators');
        this.$store.dispatch('operators/setActiveOperator');

        // Adding user info to Sentry payload
        Sentry.configureScope(scope => {
            scope.setUser(Account.storedInstance);
        });
    },

    methods: {
        updateSentryContext() {
            if (!this.currentCompany) {
                return;
            }

            this.$store.dispatch('operators/setLanguages');

            Sentry.configureScope(scope => {
                scope.setTag('route', this.$route.fullPath);
                scope.setTag('tenant.name', this.currentCompany.caption);
                scope.setExtra('tenant', this.currentCompany.dnoIdentifier);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/sidebar.scss';
@import '~@/assets/scss/z-indexes';

.auth-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar-container {
    z-index: $page-layout-item-z-index;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.page-content {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    margin-left: $sidebarFullWidth;
    transition: margin-left 0.5s;

    &.compact-sidebar {
        margin-left: $sidebarCompactWidth;
    }
}
</style>
