


















































import Vue from 'vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import ConditionsTree from '@/modules/rewards/components/conditions-tree/ConditionsTree.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';

export default Vue.extend({
    name: 'Conditions',
    components: {
        AppButton,
        ConditionsTree,
    },
    model: {
        prop: 'condition',
        event: 'conditionChange',
    },
    props: {
        /**
         * Server side (dno) condition data.
         * This is a model property which is updated
         * when the condition tree is modified.
         * Will be null if condition has error.
         */
        condition: {
            type: Object,
            default: null,
        },
        /**
         * Defines the preset condition when a new condition is created.
         * This can be used to preset the default operation, data type, and key.
         */
        newConditionPreset: {
            type: Object,
            default: null,
        },
        /**
         * Boolean indicating if the component should display validation errors
         */
        displayError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            conditionsTreeHasError: false,
            conditionsTreeIsCollapsed: false,
        };
    },
    computed: {
        ...mapGetters('conditionsTree', {
            gConditionsTree: 'conditionsTree',
        }),
    },
    watch: {
        async condition(condition, oldCondition) {
            // Detect if there are any changes in condition
            if (_.isEqual(condition, oldCondition)) {
                return;
            }
            // Transform our server side condition into the format digestable by the
            // ConditionTree component
            try {
                await this.buildClientConditionsTree({
                    root: condition,
                });
            } catch {
                this.clearConditionsMap();
            }
        },
        async gConditionsTree() {
            // gConditionTree updates whenever there are any state changes
            // When the state changes, emit an event indicating changes back to parent
            // Ultimately this keeps the `condition` prop in sync with any changes
            // made to the condition tree.
            let condition;
            try {
                condition = await this.buildServerConditionsTree(this.gConditionsTree);
            } catch {
                condition = null;
            }
            this.$emit('conditionChange', condition);
        },
    },
    created() {
        // The condition state is currently in Vuex store.
        // For now manually clear this state.
        this.clearConditionsMap();
    },
    methods: {
        ...mapActions('conditionsTree', ['buildClientConditionsTree', 'buildServerConditionsTree']),
        ...mapMutations('conditionsTree', ['clearConditionsMap']),
        onError(treeHasError: boolean) {
            this.conditionsTreeHasError = treeHasError;
            this.$emit('error', treeHasError);
        },
    },
});
