<script>
// helpers
import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';
import * as Sentry from '@sentry/vue';
import store, { Modules } from '@/store/store';
import Actions, { State } from '@/store/mutation-types';
import Segment from '@/__new__/services/dno/segments/models/Segment';
import {
    SEGMENT_CREATION_SOURCES,
    SEGMENT_CREATION_SOURCES_MAP,
    SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS,
    SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES,
} from '@/common/segments';
// http
import { getSegment, updateSegment } from '@/__new__/services/dno/segments/http/segments';
// components
import AbstractSegmentPage from '@/__new__/features/segments/dynamic/AbstractSegmentPage.vue';

export default {
    name: 'EditSegment',
    extends: AbstractSegmentPage,
    beforeRouteUpdate(to, from, next) {
        this.$Progress.start();
        this.fetchAndSetOperatorStats();
        this.resetSegment();
        this.displaySegment(to.params.id, true);
        next();
    },
    props: {
        id: { type: String, required: true },
    },
    data() {
        return {
            segmentName: ' ', // a single whitespace will prevent displaying input placeholder,
            segmentDateCreated: moment().unix(), // dummy
        };
    },
    async created() {
        await this.loadInitialData();
        this.addWatcher('$data');
    },
    methods: {
        async loadInitialData() {
            try {
                this.$Progress.start();
                [this.segmentIdType] = this.segmentIdTypeOptions;
                const promises = [this.displaySegment(this.$route.params.id, true)];
                await Promise.all(promises);
                this.$Progress.finish();
            } catch (err) {
                Sentry.captureException(err);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        saveSegment() {
            if (this.saveInProcess) {
                return;
            }

            const { segmentDescription } = this.$refs;
            segmentDescription.$v.name.$touch();
            if (segmentDescription.$v.name.$invalid) {
                this.$alert(segmentDescription.errorMessage);
                return;
            }
            // creation can be avoid if we store the whole segment in state,
            // but it's easier to work with separate fields than with one entity
            const segment = new Segment(
                this.id,
                this.segmentName,
                moment().unix(),
                this.updatedFiltersJson,
                this.segmentDescription,
                SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType],
                this.generationType,
                this.updatedAddConditionsJson,
                this.updatedRemoveConditionsJson,
                Number(SEGMENT_CREATION_SOURCES_MAP[SEGMENT_CREATION_SOURCES.USER_MADE]),
                null,
                null,
                null,
                this.version,
            );
            try {
                this.validateSegment(segment);
                if (!this.isSegmentValid) {
                    return;
                }

                this.$Progress.start();
                this.saveInProcess = true;
                updateSegment(segment).then(() => {
                    this[Actions.FETCH_SEGMENT_STATISTICS]({
                        segmentId: segment.id,
                        segmentIdType: segment.segmentIdType,
                        flush: true,
                    });
                    this.entityEditorMixin.successfullySaved = true;
                    this.$Progress.finish();
                    this.$eventBus.$emit('showAlert', {
                        message: this.successSaveMessage,
                        type: this.$ALERT_TYPES.success,
                    });
                    this.navigateToSegmentsListPageWithTimeout();
                });
            } catch (e) {
                this.saveInProcess = false;
                Sentry.captureException(e);
                this.$Progress.fail();
                // ToDo: handle segment invalid name highlight when server implements error codes
                this.$eventBus.$emit('showAlert', {
                    message: e.message,
                });
            }
        },
        setSegment(segment) {
            const cloneSegment = cloneDeep(segment);

            this.segmentName = cloneSegment.name;
            this.version = cloneSegment.version;
            this.segmentDateCreated = cloneSegment.updateTime;
            this.segmentDescription = cloneSegment.description;
            this.segmentIdType = SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES[cloneSegment.segmentIdType];
            this.initialFiltersJson = cloneSegment.filtersJson;
            this.generationType = cloneSegment.segmentType;
            this.initialAddConditionsJson = cloneSegment.addCondition;
            this.initialRemoveConditionsJson = cloneSegment.removeCondition;
        },
        resetSegment() {
            this.segmentName = ' ';
        },
        async displaySegment(segmentId, refresh) {
            // display cached
            const cachedSegment = this[State.CACHED_SEGMENTS][segmentId];
            if (cachedSegment) {
                this.setSegment(cachedSegment);
            }

            // fetch fresh one and cache
            if (refresh) {
                try {
                    const areTriggersLoaded = !isEmpty(store.state[Modules.triggers][State.TRIGGER_DEFINITIONS_BY_ID]);
                    const areFiltersLoaded = !isEmpty(store.state[Modules.segments][State.FILTER_DEFINITIONS_BY_ID]);
                    const promises = [];
                    if (!areFiltersLoaded || !areTriggersLoaded) {
                        promises.push(
                            this[Actions.LOAD_FILTER_DEFINITIONS](),
                            this[Actions.LOAD_TRIGGER_DEFINITIONS](),
                        );
                    }
                    await Promise.all(promises);
                    const { data } = await getSegment(segmentId);
                    const segment = Segment.fromJson(data);
                    this.setSegment(segment);
                } catch (e) {
                    // ToDo: display erorrs
                }
            }
            this.operatorStats = await this[Actions.FETCH_SEGMENT_STATISTICS]({
                segmentId: this.id,
                segmentIdType: SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType],
                flush: true,
            });

            this.updateStats(true);
        },
    },
};
</script>
