<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.applications')"
        :entityType="'application'"
        :entityResponse="applicationsApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApplication')"
        :writePermission="isUserAllowed('OperateAPIsApplicationsWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectEntity"
    >
        <template slot="sidebarOverview">
            <h2 class="lf-title mb-2">
                {{ $i18n.t('operateAPIs.transactions') }}
            </h2>
            <template v-if="transactionsToDisplay.length">
                <div
                    v-for="(transaction, index) in transactionsToDisplay"
                    :key="`transaction-${index}`"
                    class="mb-4"
                >
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('customerCare.transactionHistory.offerName')}:` }}</p>
                        <p>{{ transaction.offerName }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('operateAPIs.chargingSpecName')}:` }}</p>
                        <p>{{ transaction.chargingSpecName }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('operateAPIs.amountUsed')}:` }}</p>
                        <p>{{ transaction.amountUsed }}</p>
                    </div>
                </div>
            </template>
            <p
                v-else
                class="body-sm"
            >
                {{ $i18n.t('generic.empty') }}
            </p>

            <OverviewList :lists="detailsSections" />
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';

// HTTP
import { getApplications, getApiTransactions } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';

export default {
    name: 'ApplicationsListPage',

    components: {
        OperateApisListPageLayout,
        OverviewList,
    },

    data() {
        return {
            applicationsApiResponse: {},
            entityData: [],
            transactions: [],
            transactionsToDisplay: [],
            addNewRouteName: RouteNames.OPERATE_APIS_APPLICATIONS_EDITOR,
            selectedApplication: null,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApplication ? this.selectedApplication.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApplication?.id || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApplication?.commercialName || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.commercialName'),
                        },
                        {
                            value: this.selectedApplication?.category || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('productCatalog.entities.category'),
                        },
                        {
                            value: this.selectedApplication?.operationalState || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.operationalState'),
                        },
                        {
                            value: this.selectedApplication?.approvalStatus || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.operateAPIs'),
                        },
                        {
                            value: this.selectedApplication?.clientId || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('orderResume.clientId'),
                        },
                        {
                            value: this.selectedApplication?.redirectURL || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.redirectUrl'),
                        },
                    ],
                },
            ];
        },
    },

    async created() {
        await this.$withProgressBar(
            async () => {
                await getApplications().then(response => {
                    this.applicationsApiResponse = response;

                    this.entityData = response.data.map(application => ({
                        id: application?.id || '',
                        name: application?.name || '',
                        description: application?.description || '',
                        commercialName: application?.commercialName || '',
                        category: application?.category || '',
                        operationalState: application?.operationalState || '',
                        approvalStatus: application?.approvalStatus || '',
                        clientId: application?.digitalIdentity?.clientId || '',
                        redirectURL: application?.digitalIdentity?.redirectURL || '',
                    }));
                });

                await getApiTransactions({
                    start_timestamp: 0,
                    end_timestamp: Math.round(new Date().getTime() / 1000),
                    target_id: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
                    target_type: 1,
                }).then(resp => {
                    this.transactions = (resp.data.api_transactions || []).map(transaction => ({
                        offerName: transaction?.offer_name || this.$i18n.t('generic.empty'),
                        chargingSpecName: transaction?.charging_spec_name || this.$i18n.t('generic.empty'),
                        amountUsed: transaction?.amount_used || this.$i18n.t('generic.empty'),
                        appId: transaction?.app_id || null,
                    }));
                });
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            this.transactionsToDisplay = this.transactions.filter(transaction => transaction.appId === entity);
            [this.selectedApplication] = this.entityData.filter(data => data.id === entity);
        },
    },
};
</script>
