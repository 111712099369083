












































































import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';

// Components
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import TransactionHistoryStatusIndicator from '@/__new__/features/customerCare/account/TransactionHistoryStatusIndicator.vue';

// Helpers
import get from 'lodash/get';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { getScaledAmount, DATA_DEFINITIONS } from '@/common/formatting';
import RouteNames from '@/router/routeNames';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// Https
import paymentHTTP from '@/http/payment';

const PAYMENT_TRANSACTION_TYPE_CODES = {
    AUTO_PAYMENT: 1,
    MANUAL_PAYMENT: 3,
    PAYMENT_RETURN: 5,
    TRANSFER_DATA_SEND: 6,
    TRANSFER_DATA_RECEIVE: 7,
};

interface ColumnData {
    name: TranslateResult;
    key: string;
    field: string;
    filterType: any;
    mapper?: any;
    sortBy?: any;
}

interface TransactionHistory {
    amount: number;
    amountFormatted: string;
    connectedAcount: string;
    connectedMSISDN: string;
    transactionTimestamp: string;
    transactionId: string;
}

export default Vue.extend({
    name: 'DataTransfersTable',
    components: {
        AppTable,
        IconButton,
        ResponseModalButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        TransactionHistoryStatusIndicator,
    },
    props: {
        accountId: {
            required: true,
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ICON_TYPES,
            RouteNames,

            isDataLoading: false as boolean,
            transactionHistoryApiResponse: {} as { data: any },
        };
    },

    computed: {
        transactionHistoriesFormatted(): TransactionHistory[] {
            if (this.transactionHistoryApiResponse?.data?.transactions?.length) {
                return this.transactionHistoryApiResponse.data.transactions.map((transaction: any) => {
                    const amount = getScaledAmount(DATA_DEFINITIONS[0], transaction.amount);
                    const transactionType = transaction?.transaction_type || null;
                    let connectedAccount = '';
                    let connectedMSISDN = '';

                    if (transactionType) {
                        connectedAccount =
                            transactionType === PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE
                                ? transaction?.transfer_info?.sender_info?.target_id
                                : transaction?.transfer_info?.receiver_info?.target_id;
                        connectedMSISDN = PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE
                            ? transaction?.transfer_info?.sender_info?.msisdn
                            : transaction?.transfer_info?.receiver_info?.msisdn;
                    }

                    return {
                        amount,
                        amountFormated: `${
                            transactionType === PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE ? '+' : '-'
                        } ${amount}${DATA_DEFINITIONS[0].label}`,
                        connectedAccount,
                        connectedMSISDN,
                        transactionTimestamp: transaction?.transaction_timestamp || '',
                        transactionId: transaction?.transaction_id || '',
                        state: transaction?.state || '',
                    };
                });
            }

            return [];
        },
        columnsData(): ColumnData[] {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'transactionId',
                    field: 'transactionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'transactionTimestamp',
                    mapper: (entity: any) => {
                        if (entity) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                entity.transactionTimestamp,
                            );
                        }

                        return '';
                    },
                    sortBy: (entity: any) => entity.transaction_timestamp,
                    field: 'transactionTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormated',
                    sortBy: (entity: any) => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.receivedFromSentTo'),
                    key: 'connectedAccount',
                    mapper: (entity: any) => get(entity, 'connectedAccount', this.$i18n.t('generic.N/A')),
                    field: 'connectedAccount',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'connectedMSISDN',
                    mapper: (entity: any) => get(entity, 'connectedMSISDN', this.$i18n.t('generic.N/A')),
                    field: 'connectedMSISDN',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
            ];
        },
    },
    created() {
        this.fetchDataTransfer();
    },
    methods: {
        async fetchDataTransfer(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;

                    this.transactionHistoryApiResponse = await paymentHTTP.getTransactionHistory({
                        target_id: this.accountId,
                        target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                        filters: [
                            {
                                field_name: 'transaction_type',
                                operator: 'IS_GREATER_THAN',
                                value: 5,
                            },
                            {
                                field_name: 'transaction_type',
                                operator: 'IS_LESS_THAN',
                                value: 8,
                            },
                        ],
                        include_payment_items: false,
                    });

                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        Sentry.captureException(e);
                        this.transactionHistoryApiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.dataTransferFetchError'));
                        this.isDataLoading = false;
                    },
                },
            );
        },
        get,
    },
});
