














































































































import Vue from 'vue';

// Components
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import VlrIdConditionRow from '@/__new__/features/charging/conditionParameters/conditions/VlrIdConditionRow.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import VlrIdConditionModel from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/VlrIdConditionModel';

import {
    VlrIdConditionDataObject,
    VlrIdOptions,
    VlrIdConditionValues,
    MAP_VLR_ID_TYPE_TO_OPTION_VALUES,
    VLR_ID_TYPES,
} from '@/__new__/services/dno/charging/common/vlrIdConditionHelper';

interface CsvRowObject {
    key: VLR_ID_TYPES;
    values: string[];
}

export default Vue.extend({
    name: 'VlrIdCondition',
    components: {
        AppToggle,
        AppButton,
        AppTooltip,
        VlrIdConditionRow,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            vlrIdFormatted: {} as VlrIdConditionDataObject,
            isDragOn: false,
            acceptType: '.csv',
            vlrIdTypeOptions: Object.values(MAP_VLR_ID_TYPE_TO_OPTION_VALUES),
        };
    },
    computed: {
        vlrIdTypeOptionsNotInUse() {
            if (!this.vlrIdFormatted.data.src.length) {
                return this.vlrIdTypeOptions;
            }

            const optionsInUse: string[] = this.vlrIdFormatted.data.src.map((el: any) => el.vlrIdType?.id);
            return this.vlrIdTypeOptions.filter(el => (!optionsInUse.includes(el.id) ? el : null));
        },
        getAllVlrIdTypeOptionsInUse(): string[] {
            return this.vlrIdFormatted.data.src.map((el: any) => el.vlrIdType?.id);
        },
        showAllTypesOfVlrIdOptions(): string {
            return this.vlrIdTypeOptions.map((el: VlrIdOptions) => el.id).join('|');
        },
        getAllTypesOfVlrIdOptions(): string[] {
            return this.vlrIdTypeOptions.map((el: VlrIdOptions) => el.id);
        },
    },
    watch: {
        vlrIdFormatted: {
            handler() {
                this.emitData();
            },
            deep: true,
        },
    },
    created() {
        this.vlrIdFormatted = new VlrIdConditionModel(this.value);
    },
    methods: {
        emitData() {
            this.vlrIdFormatted.isDataValid();
            this.$emit('input', this.vlrIdFormatted);
        },
        addSlot(type?: VlrIdOptions, values: string[] = []) {
            const [firstEL] = this.vlrIdTypeOptionsNotInUse;
            const vlrIdType = firstEL || this.vlrIdTypeOptions[0];
            this.vlrIdFormatted.data.src.push({
                vlrIdType: type || vlrIdType,
                values,
            });
        },
        deleteSlot(index: number) {
            this.vlrIdFormatted.data.src.splice(index, 1);
        },
        preventAndStop(e: any) {
            e.preventDefault();
            e.stopPropagation();
        },
        addDragOnClass() {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass() {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        onDrop(e: any) {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            const appropriateFileType = !Object.values(e.dataTransfer.files).some(
                ({ name }) => !this.isAppropriateFileType(name),
            );

            if (appropriateFileType) {
                this.addFilesToList(e.dataTransfer.files);
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: `${this.$i18n.t('partials.fileUploader.invalidFileTypeText')}
                            ${this.$i18n.t('partials.fileUploader.youCanUpload')} ${this.acceptType}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        onSelect(e: any) {
            const files = e.target.files || e.dataTransfer.files;
            this.addFilesToList(files);
        },
        isAppropriateFileType(name: string) {
            const types = this.acceptType
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
        addFilesToList(files: File[]) {
            if (!files.length) {
                return;
            }
            const reader = new FileReader();
            reader.readAsText(files[0]);
            reader.onload = (res: any) => {
                const fileData = res.target.result;
                const csvData = this.csvToArray(fileData);
                this.addCsvData(csvData);
            };
            reader.onerror = err => {
                this.$eventBus.$emit('showAlert', {
                    message: err,
                    type: ALERT_TYPES.warning,
                });
            };
        },
        csvToArray(str: string): CsvRowObject[] {
            const rows = str.slice(str.indexOf('\n') + 1).split('\n');
            let dataInvalid = false;
            const csvData: any[] = [];
            rows.forEach((el: string, index: number) => {
                if (el !== '') {
                    // array starts with 0 and first row are headers
                    const lineNumber = index + 2;
                    const [elValue, elKey] = el.split(',');

                    if (!el.includes(',')) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.conditionParameters.fileNotValid', {
                                line: lineNumber,
                            }),
                            type: ALERT_TYPES.warning,
                        });
                        dataInvalid = true;
                    } else if (!this.getAllTypesOfVlrIdOptions.includes(elKey)) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.conditionParameters.vlrIdFileNotValidOptionType', {
                                line: lineNumber,
                            }),
                            type: ALERT_TYPES.warning,
                        });
                        dataInvalid = true;
                    } else if (!/^\d+$/.test(elValue.trim())) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.conditionParameters.fileValueNotValid', {
                                line: lineNumber,
                            }),
                            type: ALERT_TYPES.warning,
                        });
                        dataInvalid = true;
                    } else {
                        csvData.push({
                            key: elKey,
                            values: [elValue.trim()],
                        });
                    }
                }
            });

            return dataInvalid ? [] : csvData;
        },
        addCsvData(data: CsvRowObject[]): void {
            data.forEach((el: CsvRowObject) => {
                if (this.getAllVlrIdTypeOptionsInUse.includes(el.key)) {
                    const getTheIndexOfKey = this.vlrIdFormatted.data.src.findIndex(
                        (e: VlrIdConditionValues) => e?.vlrIdType?.id === el.key,
                    );

                    const newValues = new Set([...this.vlrIdFormatted.data.src[getTheIndexOfKey].values, ...el.values]);
                    this.vlrIdFormatted.data.src[getTheIndexOfKey].values = [...newValues];
                } else {
                    this.addSlot(MAP_VLR_ID_TYPE_TO_OPTION_VALUES[el.key], el.values);
                }
            });
        },
    },
});
