






































































































































// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
// HTTP
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';
import { createCampaignCategory, updateCampaignCategory } from '@/__new__/services/dno/campaigns/http/campaigns';
// Helpers
import toUpper from 'lodash/toUpper';
import { ICON_TYPES } from '@/common/iconHelper';
import entityEditorMixin from '@/common/entityEditorMixin';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { CampaignCategory } from '@/__new__/features/settings/categories/CampaignCategory';
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';
import { chain, find, isEmpty } from 'lodash';
import { CampaignType, CampaignTypes } from '@/__new__/services/dno/campaigns/models/Campaign';
import { CAMPAIGN_PRIORITIES_LABELS } from '@/common/cepHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export default {
    name: 'CampaignCategoriesEditor',
    components: {
        AppToggle,
        AppButton,
        AppInputV3,
        AppSpinner,
        AppMultiselectV3,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },

    mixins: [entityEditorMixin, SubSidebarMixin, SettingsSubSidebarMixin],

    data() {
        return {
            entityType: ENTITY_TYPES.CAMPAIGN_CATEGORY,
            toUpper,
            ICON_TYPES,
            BUTTON_TYPES,
            isDataLoading: false,
            name: '',
            inboxName: '',
            campaignType: '',
            campaignPriority: '',
            campaignPriorityOptions: [],
            qhRules: [],
            qhRulesResponse: null,
            qhRulesOptions: [],
            applyLimits: false,
            ignoreDailyLimits: false,
            limitNumber: 0,
            limitPerDay: 0,
            isAPITriggered: false,
            editableCategoryId: null,
            editableCategoryVersion: null,
        };
    },
    computed: {
        ...mapGetters(Modules.campaigns, [Getters.GET_CAMPAIGN_PRIORITIES, Getters.GET_CAMPAIGN_CATEGORIES]),

        campaignTypeOptions() {
            return chain(CampaignTypes)
                .omit(CampaignType.BackOfficeCampaign)
                .filter(type => type.isAllowed())
                .map(type => type.label)
                .value();
        },
    },
    created() {
        this.$Progress.start();
        this.isDataLoading = true;
        const promises = [getQuietHours(), this[Actions.FETCH_CAMPAIGN_PRIORITIES]()];
        if (this.isEditing && isEmpty(this[Getters.GET_CAMPAIGN_CATEGORIES])) {
            promises.push(this[Actions.FETCH_CAMPAIGN_CATEGORIES]());
        }
        Promise.all(promises)
            .then(response => {
                this.$Progress.finish();
                this.qhRulesResponse = response[0].data;
                this.qhRulesOptions = this.qhRulesResponse.map(qh => ({
                    ...qh,
                    name: qh.profile_name || this.$t('settings.campaignCategories.defaultQH'),
                }));
                this.setPriorityOptions(this.campaignType);
                if (this.isEditing) {
                    this.initializeEditPage();
                }
            })
            .catch(e => {
                this.$Progress.fail();
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                Sentry.captureException(e);
            })
            .finally(() => {
                this.isDataLoading = false;
            });
    },
    methods: {
        ...mapActions(Modules.campaigns, [Actions.FETCH_CAMPAIGN_CATEGORIES, Actions.FETCH_CAMPAIGN_PRIORITIES]),
        initializeEditPage() {
            const editableCategory = this[Getters.GET_CAMPAIGN_CATEGORIES].find(c => c.id === this.$route?.params?.id);

            if (editableCategory) {
                this.name = editableCategory.name;
                this.inboxName = editableCategory.inboxName;
                this.campaignType = editableCategory.campaignType;
                this.campaignPriority = editableCategory.campaignPriority;
                this.applyLimits = editableCategory.applyLimits;
                this.limitNumber = editableCategory.limitNumber;
                this.limitPerDay = editableCategory.limitPerDay;
                this.ignoreDailyLimits = editableCategory.ignoreDailyLimits;
                this.editableCategoryId = editableCategory.id;
                this.editableCategoryVersion = editableCategory.version;

                if (!isEmpty(editableCategory.qhRules)) {
                    this.qhRules = this.qhRulesResponse
                        .filter(qhr => editableCategory.qhRules.includes(qhr.id))
                        .map(qhr => ({
                            ...qhr,
                            name: qhr.profile_name || this.$t('settings.campaignCategories.defaultQH'),
                        }));
                }

                this.setPriorityOptions(this.campaignType);
            }
        },
        setPriorityOptions(campaignType: string): any {
            const selectedCampaignType = find(CampaignTypes, campaign => campaign.label === campaignType)?.value;
            this.campaignPriorityOptions =
                this[Getters.GET_CAMPAIGN_PRIORITIES][selectedCampaignType]?.[1].map(
                    (p: number) => CAMPAIGN_PRIORITIES_LABELS[p],
                ) || [];
        },
        onCampaignTypeInput(campaignType: string) {
            this.setPriorityOptions(campaignType);
            [this.campaignPriority] = this.campaignPriorityOptions;
            if (campaignType === CampaignTypes[CampaignType.MarketingCampaign]?.label) {
                this.ignoreDailyLimits = false;
            }
            if (campaignType === CampaignTypes[CampaignType.ServiceNotificationCampaign]?.label) {
                this.ignoreDailyLimits = true;
            }
        },
        isCategoryValid(): boolean {
            this.$v.name.$touch();
            this.$v.inboxName.$touch();
            if (this.applyLimits) {
                this.$v.limitPerDay.$touch();
                this.$v.limitNumber.$touch();
            }
            return !this.$v.$anyError;
        },
        async onSave() {
            if (!this.isCategoryValid()) return;

            this.$Progress.start();
            this.isAPITriggered = true;

            const category = new CampaignCategory(
                this.isEditing ? this.editableCategoryId : null,
                this.name,
                this.inboxName,
                this.campaignType,
                this.campaignPriority,
                this.qhRules,
                this.applyLimits,
                this.ignoreDailyLimits,
                this.limitNumber,
                this.limitPerDay,
                this.isEditing ? this.editableCategoryVersion : null,
            );
            try {
                const APIFunction = this.isEditing ? updateCampaignCategory : createCampaignCategory;
                await APIFunction(category.buildJson());

                this.$Progress.finish();

                const successMsg = this.isEditing
                    ? this.$t('settings.campaignCategories.successfullyUpdated')
                    : this.$t('settings.campaignCategories.successfullyCreated');

                this.$showSuccessAlert({
                    message: successMsg,
                });

                setTimeout(() => {
                    this.$router.push({
                        name: RouteNames.CAMPAIGN_CATEGORIES,
                    });
                }, 2000);
            } catch (e) {
                Sentry.captureException(e);
                const failMsg = this.isEditing
                    ? this.$t('settings.campaignCategories.failedToUpdate')
                    : this.$t('settings.campaignCategories.failedToCreate');
                this.$alert(failMsg);
                this.$Progress.fail();
            } finally {
                this.isAPITriggered = false;
            }
        },
    },
    validations: {
        name: {
            required,
        },
        inboxName: {
            required,
        },
        limitPerDay: {
            maxValue: maxValue(20),
            minValue: minValue(1),
        },
        limitNumber: {
            maxValue: maxValue(20),
            minValue: minValue(1),
        },
    },
};
