








































































































import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import FilterTable from '@/components/partials/FilterTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import { deleteUsageCounteDraft, updateUsageCounterState } from '@/__new__/services/dno/charging/http/usageCounters';

// Routers
import RouteNames from '@/router/routeNames';

// Entities and Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    getMultiLangFieldValueByLocale,
    getMutationDialogTitleKey,
    formatMutationDialogEntities,
    getEntityConsumers,
} from '@/common/entities/entityHelper';
import { checkObjectPath, onlyFirstLetterUppercase } from '@/common/utils';
import { entityStateReverseMap } from '@/common/commonEntityStateMapper';
import { languageMap } from '@/common/locale/language';
import * as Sentry from '@sentry/vue';
import { upperFirst } from 'lodash';
import { UsageCounter, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import localeLibrary from '@/common/locale/localeLibrary';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

export default Vue.extend({
    name: 'UsageCounter',
    components: {
        AbstractListPageWrapper,
        AppTable,
        EntityStatusIndicator,
        AppButton,
        FilterTable,
        EntityOverview,
        OverviewHeader,
        LanguageSwitcher,
        OverviewList,
        MutationDialog,
        TableFiltersTags,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            PLURALIZATION,
            entityType: ENTITY_TYPES.USAGE_COUNTER as string,
            editorRoute: RouteNames.CHARGING_USAGE_COUNTERS_EDITOR as string,
            isDataLoading: false as boolean,
            selectedLanguage: '' as string,
            searchQueryForTable: '' as string,
            selectedEntity: null as UsageCounter | null,
            isOverviewEnabled: false as boolean,
            isMutationModalVisible: false as boolean,
            mutationDialogDataType: '' as string,
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_USAGE_COUNTERS,
            Getters.GET_USAGE_COUNTERS_API_RESPONSE,
            Getters.GET_USAGE_COUNTER_BY_ID,
        ]),
        ...mapGetters('operators', [Getters.languageDefault]),
        isEditEnabled(): boolean {
            return permissionsService.chargingUsageCountersEnabled() && isUserAllowed('UsageCountersReadWrite');
        },
        payoutsApiResponse(): any[] {
            return this[Getters.GET_USAGE_COUNTERS_API_RESPONSE];
        },
        getUsageCountersApiPayload(): UsageCounter[] {
            return this[Getters.GET_USAGE_COUNTERS] || [];
        },
        usageCounters(): UsageCounter[] {
            return this.getUsageCountersApiPayload
                .map((uc: UsageCounter) => ({
                    ...uc,
                    name: checkObjectPath(uc, 'name') ? uc?.name : null,
                }))
                .sort((entity1: UsageCounter, entity2: UsageCounter) =>
                    (entity1.name as any).localeCompare(entity2.name),
                );
        },
        sortedFilteredUsageCounters(): any[] {
            return this.filteredEntitiesMixin(this.usageCounters);
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    mapper: (uc: UsageCounter) => getMultiLangFieldValueByLocale(uc.name),
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charging.usageCounters.resetPeriod'),
                    forbidHideColumn: true,
                    key: 'resetPeriodLabel',
                    field: 'resetPeriodLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredUsageCounters.map((e: UsageCounter) => e.resetPeriodLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('charging.usageCounters.resetPeriodDuration'),
                    forbidHideColumn: true,
                    key: 'resetPeriodDuration',
                    field: 'resetPeriodDuration',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    mapper: (entity: UsageCounter) => localeLibrary.getFormattedDateAndTime(entity.update_time),
                    sortBy: (entity: UsageCounter) => entity.update_time,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredUsageCounters.map((e: UsageCounter) => e.entityStatusCodeLabel)),
                    ],
                },
            ];
        },
        selectedEntityId(): string | null {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedEntity?.description
                                ? this.selectedEntity.description[this.selectedLanguage]
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.selectedEntity.resetPeriodData.label ?? this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('charging.usageCounters.resetPeriod'),
                        },
                        {
                            value: this.selectedEntity.resetPeriodData.duration ?? this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('charging.usageCounters.resetPeriodDuration'),
                        },
                        {
                            value: this.selectedEntity.resetPeriodData.startDay ?? '-',
                            label: this.$i18n.t('charging.usageCounters.resetPeriodStartingDay'),
                        },
                    ],
                },
                this.usageCounterInUseByUpperLevelEntities,
            ];
        },
        usageCounterInUseByUpperLevelEntities(): DetailsSections | object {
            const {
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
                [ENTITY_TYPES.POLICY_COUNTER]: inUseByPolicyCounter = [],
                [ENTITY_TYPES.WALLET_COUNTERS]: inUseByWalletCounters = [],
            } = this.upperEntities();

            if (!inUseByCs.length && !inUseByPolicyCounter.length && !inUseByWalletCounters.length) {
                return {};
            }

            const data: DetailsSections = {
                name: `${upperFirst(this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.PLURAL))} ${this.$i18n.t(
                    'generic.usedBy',
                )}`,
                properties: [],
            };

            if (inUseByCs.length) {
                data.properties.push({
                    label: `${inUseByCs.length} ${this.$i18n.t('charging.entities.plural.chargingSpecifications')}`,
                    value: inUseByCs.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.CHARGING_SPECIFICATION,
                });
            }

            if (inUseByPolicyCounter.length) {
                data.properties.push({
                    label: `${inUseByPolicyCounter.length} ${this.$i18n.t('charging.entities.plural.policyCounters')}`,
                    value: inUseByPolicyCounter.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.POLICY_COUNTER,
                });
            }

            if (inUseByWalletCounters.length) {
                data.properties.push({
                    label: `${inUseByWalletCounters.length} ${this.$i18n.t('charging.entities.plural.walletCounters')}`,
                    value: inUseByWalletCounters.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.WALLET_COUNTERS,
                });
            }

            return data;
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_USAGE_COUNTERS,
            Actions.REQUEST_WALLET_COUNTERS,
            Actions.REQUEST_POLICY_COUNTERS,
        ]),
        ...mapActions('charging', [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        getMultiLangFieldValueByLocale,
        getMutationDialogTitleKey,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    await Promise.all([
                        this[Actions.REQUEST_USAGE_COUNTERS](),
                        this[Actions.REQUEST_POLICY_COUNTERS](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                        this[Actions.REQUEST_WALLET_COUNTERS](),
                    ]);

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        onSelectEntity(id: string): void {
            this.selectedEntity = this[Getters.GET_USAGE_COUNTER_BY_ID](id);
            this.isOverviewEnabled = true;
        },
        onNewUsageCounter(): void {
            this.$router.push({
                name: RouteNames.CHARGING_USAGE_COUNTERS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s: number): string {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        showMutationDialog(type: string): void {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value: string): void {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getAffectedEntities(mutationDialogDataType: string): any {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        upperEntities(entityId: string = this.selectedEntityId): object {
            return getEntityConsumers(entityId, this.entityType);
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_USAGE_COUNTER_BY_ID](entityId);
            const {
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
                [ENTITY_TYPES.POLICY_COUNTER]: inUseByPc = [],
                [ENTITY_TYPES.WALLET_COUNTERS]: inUseByWc = [],
            } = this.upperEntities(entityId);

            this.showDeleteAlert(
                entityId,
                Boolean(inUseByCs.length || inUseByPc.length || inUseByWc.length),
                state,
                version,
                [updateUsageCounterState, deleteUsageCounteDraft, this.initData],
            );
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
