

























































































// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { TranslateResult } from 'vue-i18n';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// Https
import {
    multiRestartState,
    getPlanDetails,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Validation
import { validationMixin } from 'vuelidate';

interface ExecutionForResume {
    executionId: string;
    planId: string;
    lastState: string;
    changeLastState: boolean;
}

interface ColumnData {
    name: TranslateResult;
    key: string;
    field: string;
}

export default {
    name: 'BatchResumeExecutionsModal',
    components: {
        AppButton,
        AppDialogV2,
        AppInputV3,
        AppToggleV2,
    },
    mixins: [validationMixin],
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        executionsForResume: {
            required: true,
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ALERT_TYPES,

            planStates: [] as string[],
            selectedExecutionsForResume: [] as ExecutionForResume[],
            isPlanDetailsAlreadyProvided: false as boolean,
            isBatchResumeButtonClicked: false as boolean,
            changeLastStateTriggered: false as boolean,
        };
    },
    computed: {
        columnsData(): ColumnData[] {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.executionId'),
                    key: 'executionId',
                    field: 'executionId',
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    field: 'planId',
                },
            ];
        },
        getStylesForColumn(): string {
            const currentWidth = 100 / (this.columnsData.length + 2);

            return `width: ${currentWidth}%`;
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('PausedExecutionsWrite') &&
                permissionsService.orchestrationEnginePausedExecutionsEnabled()
            );
        },
    },
    watch: {
        selectedExecutionsForResume: {
            handler(value) {
                // helper to call get_plan endpoint only when user change continue state in modal
                this.changeLastStateTriggered = value.some(execution => execution.changeLastState);

                this.$emit('onChangeSelectedExecutions', value);
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.selectedExecutionsForResume = this.remapExecutionsForResume(this.executionsForResume);
    },
    validations() {
        return {
            selectedExecutionsForResume: {
                $each: {
                    lastState: {
                        validation: value => this.planStates.includes(value),
                    },
                },
                immediate: true,
                deep: true,
            },
        };
    },
    methods: {
        remapExecutionsForResume(executions: any[]): ExecutionForResume[] {
            return executions.map(execution => ({
                executionId: execution.executionId,
                planId: execution.planId,
                lastState: execution.lastState,
                changeLastState: false,
            }));
        },
        async onBatchResume(): Promise<any> {
            try {
                this.isBatchResumeButtonClicked = true;

                // If button is already clicked and we already have get_plan response
                // then check did user resolve invalid inputs
                if (this.isPlanDetailsAlreadyProvided) {
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                }

                this.$Progress.start();

                const resumeData = this.selectedExecutionsForResume.map(execution => ({
                    execution_id: execution.executionId,
                    continue_state: execution.lastState,
                }));

                // If all executions which need to be resumed are from same plan, then we need to validate
                // do last states for all executions exist in that plan or not

                const uniquePlanNames = [
                    ...new Set(this.selectedExecutionsForResume.map(execution => execution.planId)),
                ];

                if (uniquePlanNames.length === 1 && this.changeLastStateTriggered) {
                    // this is to avoid calling endpoint everytime user clicks
                    // because we already have states for that plan
                    if (!this.isPlanDetailsAlreadyProvided) {
                        this.isPlanDetailsAlreadyProvided = true;

                        const planDetails = await getPlanDetails({ id: uniquePlanNames[0] });

                        // eslint-disable-next-line camelcase
                        const states =
                            planDetails?.data?.orchestration_plan_by_id[uniquePlanNames[0]]?.data?.states || [];

                        this.planStates = Object.keys(states);
                    }

                    // Check input of entered continue states
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                }

                await multiRestartState(resumeData);

                this.$Progress.finish();
                this.onCloseModal();
                this.$emit('onBatchResumed');

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.batchResumeSuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (e: any) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.batchResumeFailure'),
                });
                this.$Progress.fail();
            } finally {
                this.isBatchResumeButtonClicked = false;
            }
        },
        onCodeButtonClick() {
            this.$emit('onCodeButtonClicked');
        },
        onCloseModal(): void {
            this.selectedExecutionsForResume = [];
            this.planStates = [];
            this.isPlanDetailsAlreadyProvided = false;
            this.changeLastStateTriggered = false;
            this.$emit('onCloseModal');
        },
    },
};
