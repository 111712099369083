import { type TranslateResult } from 'vue-i18n';
import store from '@/store/store';
import customerCareAjax from '@/__new__/services/dno/user/http/customer-care';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import { getBillingAccountById } from '@/__new__/services/portal/postpaid/billingAccount/billingAccount';

import {
    ADDRESS_TYPE,
    getFormattedStateHistory,
    USER_MANAGER_HIERARCHY,
    ADDRESS_FIELDS,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import i18n from '@/i18n';
import { isValidUuid } from '@/common/uuidHelper';
import { CREDENTIALS_TYPE } from '@/__new__/services/dno/user/models/CredentialChange';
import permissionsService, { getOperatorConfigValue, isViewEnabled } from '@/services/permissions/permissions.service';

export enum SEARCH_QUERY_OPTION {
    ACCOUNT_ID = 'account_id',
    ADDRESS = 'address',
    EMAIL = 'email',
    FIRST_NAME = 'first_name',
    IMSI = 'imsi',
    LAST_NAME = 'last_name',
    MSISDN = 'msisdn',
    PHONE_NUMBER = 'phone',
    ORGANIZATION_ID = 'organization_id',
    SUBSCRIBER_ID = 'subscriber_id',
    USER_ID = 'user_id',
    USERNAME = 'username',
    MAC_ADDRESS = 'mac_addresses.keyword',
    BILLING_ACCOUNT_ID = 'billing_account_id',
}

export enum SEARCH_QUERY_SOURCE {
    ADDRESSES = 'addresses',
    NOTIFICATION_INFO = 'notification_info',
    PROPERTIES = 'properties',
}

export enum SEARCH_QUERY_FILTER_OPERATOR {
    // case-sensitive
    MATCH = 'match',
    // case-insensitive
    REGULAR = 'regular',
    STARTS_WITH = 'starts_with',
    TERM = 'term',
}

export const SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP = new Map([
    [SEARCH_QUERY_FILTER_OPERATOR.MATCH, i18n.t('customerCare.search.equalTo')],
    [SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH, i18n.t('customerCare.search.startsWith')],
]);

export enum ASSET_TYPE {
    PROFILE = 1,
    ID = 2,
}

export enum UM_SUBSCRIPTION_TYPES {
    UNDEFINED = 0,
    NOTSET = 1,
    PREPAID = 2,
    POSTPAID = 3,
    HYBRID = 4,
}

export const UM_SUBSCRIPTION_TYPES_MAP = new Map([
    [UM_SUBSCRIPTION_TYPES.UNDEFINED, i18n.t('customerCare.subscriptionTypes.undefined')],
    [UM_SUBSCRIPTION_TYPES.NOTSET, i18n.t('customerCare.subscriptionTypes.notSet')],
    [UM_SUBSCRIPTION_TYPES.PREPAID, i18n.t('customerCare.subscriptionTypes.prepaid')],
    [UM_SUBSCRIPTION_TYPES.POSTPAID, i18n.t('customerCare.subscriptionTypes.postpaid')],
    [UM_SUBSCRIPTION_TYPES.HYBRID, i18n.t('customerCare.subscriptionTypes.hybrid')],
]);

const isMultipleResultsEnabled = () =>
    permissionsService.userManagementSearchMultipleResultsEnabled() && isViewEnabled('UMSearchMultipleResults');

type SearchQuery = {
    i18nLabel: string;
    i18nEntityType: string;
    urlPath: (response: any) => string;
};

export const SEARCH_QUERY: Map<SEARCH_QUERY_OPTION, SearchQuery> = new Map([
    [
        SEARCH_QUERY_OPTION.MSISDN,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.msisdn',
            i18nEntityType: 'generic.subscriber',
            urlPath: response => `/customer-care-suite/subscriber/${response.data.subscriber_info.subscriber_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.IMSI,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.imsi',
            i18nEntityType: 'generic.subscriber',
            urlPath: response => `/customer-care-suite/subscriber/${response.data.subscriber_info.subscriber_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.SUBSCRIBER_ID,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.subscriberId',
            i18nEntityType: 'generic.subscriber',
            urlPath: response => `/customer-care-suite/subscriber/${response.data.subscriber_info.subscriber_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.ACCOUNT_ID,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.accountId',
            i18nEntityType: 'generic.account',
            urlPath: response => `/customer-care-suite/account/${response.data.account_info.account_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.USER_ID,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.userId',
            i18nEntityType: 'generic.user',
            urlPath: response => `/customer-care-suite/user/${response.data.user_info.user_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.USERNAME,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.username',
            i18nEntityType: 'generic.user',
            urlPath: response => `/customer-care-suite/user/${response.data.user_info.user_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.EMAIL,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.email',
            i18nEntityType: 'generic.user',
            urlPath: getEmailUrlPath,
        },
    ],
    [
        SEARCH_QUERY_OPTION.ORGANIZATION_ID,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.organizationId',
            i18nEntityType: 'generic.organization',
            urlPath: response => `/customer-care-suite/organization/${response.data.organization_info.organization_id}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.PHONE_NUMBER,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.phoneNumber',
            i18nEntityType: 'generic.user',
            urlPath: getPhoneTableUrlPath,
        },
    ],
    [
        SEARCH_QUERY_OPTION.FIRST_NAME,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.firstName',
            i18nEntityType: 'generic.user',
            urlPath: response =>
                `/customer-care-suite/user-table/${response.option}/${response.operator}/${response.value}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.LAST_NAME,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.lastName',
            i18nEntityType: 'generic.user',
            urlPath: response =>
                `/customer-care-suite/user-table/${response.option}/${response.operator}/${response.value}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.ADDRESS,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.address',
            i18nEntityType: 'generic.user',
            urlPath: response =>
                `/customer-care-suite/user-table/${response.option}/${response.operator}/${response.value}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.MAC_ADDRESS,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.macAddress',
            i18nEntityType: 'generic.user',
            urlPath: response =>
                `/customer-care-suite/user-table/${response.option}/${response.operator}/${response.value}`,
        },
    ],
    [
        SEARCH_QUERY_OPTION.BILLING_ACCOUNT_ID,
        {
            i18nLabel: 'customerCare.formattedQueryOptionsForSearch.billingAccountId',
            i18nEntityType: 'generic.user',
            urlPath: response => `/billing/billing-account/${response.data.id}`,
        },
    ],
]);

function getSearchQueryEntityType(queryOption: SEARCH_QUERY_OPTION) {
    const searchQuery = SEARCH_QUERY.get(queryOption);
    return searchQuery ? i18n.t(searchQuery.i18nEntityType) : '';
}

function getSearchQueryLabel(queryOption: SEARCH_QUERY_OPTION) {
    const searchQuery = SEARCH_QUERY.get(queryOption);
    return searchQuery ? i18n.t(searchQuery.i18nLabel) : '';
}

function getSearchQueryUrlPath(queryOption: SEARCH_QUERY_OPTION, companyId: number, response: any) {
    const searchQuery = SEARCH_QUERY.get(queryOption);
    return `/${companyId}${searchQuery?.urlPath(response)}`;
}

function getEmailUrlPath(response: any): string {
    return response.option
        ? `/customer-care-suite/user-table/${response.option}/${response.operator}/${response.value}`
        : `/customer-care-suite/user/${response.data.user_info.user_id}`;
}

function getPhoneTableUrlPath(response: any): string {
    if (isMultipleResultsEnabled()) {
        return `/customer-care-suite/user-table/msisdn/${response.operator}/${response.value}`;
    }

    const subscriberInfo = response.data.search_result;
    return subscriberInfo?.length > 1
        ? `/customer-care-suite/phone-table/${response.data.index_value}`
        : `/customer-care-suite/user/${subscriberInfo[0].target_id}`;
}

export const addressRegex = /^[\w\W]+$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const imsiRegex = /^[0-9]\d{3,14}$/;
export const msisdnRegex = /^[0-9]{6,}$/;
export const phoneRegex = /^[0-9]{10,}$/;
export const textRegex = /^[^\d,.]+$/;
export const usernameRegex = /^(\d*[a-zA-Z]+\d*)+$/;
export const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export const billingAccountIdRegex = /^\d+$/;

export function msisdnToPhoneNumber(msisdn: string) {
    return msisdn.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

export function getLoginTypeForUsernameSearch(queryString: string) {
    if (emailRegex.test(queryString)) {
        return CREDENTIALS_TYPE.EMAIL_SECRET;
    }
    if (msisdnRegex.test(queryString)) {
        return CREDENTIALS_TYPE.MSISDN;
    }
    if (usernameRegex.test(queryString)) {
        return CREDENTIALS_TYPE.USERNAME_SECRET;
    }

    return -1;
}

/**
 * Search for user account based on one of available params.
 */
async function searchAndLoadAccount(
    option: SEARCH_QUERY_OPTION,
    value: string,
    operator: SEARCH_QUERY_FILTER_OPERATOR,
): Promise<any> {
    const multipleSearchResultsParams = {
        operator,
        option,
        value,
    };

    switch (option) {
        case SEARCH_QUERY_OPTION.ACCOUNT_ID:
            return searchAndLoadAccountData(value);

        case SEARCH_QUERY_OPTION.ORGANIZATION_ID:
            return customerCareAjax.getOrganization(value, USER_MANAGER_HIERARCHY.ORGANIZATION);

        case SEARCH_QUERY_OPTION.USER_ID:
            return userManagementHTTP.getUser(value, USER_MANAGER_HIERARCHY.USER);

        case SEARCH_QUERY_OPTION.USERNAME:
            return userManagementHTTP.getUserByLoginId(value, getLoginTypeForUsernameSearch(value));

        case SEARCH_QUERY_OPTION.SUBSCRIBER_ID:
            const subscriberResponse: any = await userManagementHTTP.getSubscriber(
                value,
                USER_MANAGER_HIERARCHY.SUBSCRIBER,
            );
            await store.dispatch('customerCare/setSubscriberData', subscriberResponse.subscriber_info);
            return subscriberResponse;

        case SEARCH_QUERY_OPTION.IMSI:
        case SEARCH_QUERY_OPTION.MSISDN:
            const subByParamsResponse = await userManagementHTTP.getSubscriberByParams({ [option]: value });
            await store.dispatch('customerCare/setSubscriberData', subByParamsResponse.subscriber_info);
            return subByParamsResponse;

        case SEARCH_QUERY_OPTION.EMAIL:
            if (isMultipleResultsEnabled() && getOperatorConfigValue('userSearchEmailMultipleResults', false)) {
                return multipleSearchResultsParams;
            }
            return userManagementHTTP.getUserByParams({ [option]: value });

        case SEARCH_QUERY_OPTION.PHONE_NUMBER:
            if (isMultipleResultsEnabled()) {
                return multipleSearchResultsParams;
            }
            const phoneNumber = msisdnToPhoneNumber(value);
            return customerCareAjax.getSearchByIndex(phoneNumber, option);
        case SEARCH_QUERY_OPTION.BILLING_ACCOUNT_ID:
            return getBillingAccountById(parseInt(value));
        case SEARCH_QUERY_OPTION.ADDRESS:
        case SEARCH_QUERY_OPTION.FIRST_NAME:
        case SEARCH_QUERY_OPTION.LAST_NAME:
        case SEARCH_QUERY_OPTION.MAC_ADDRESS:
            return {
                ...multipleSearchResultsParams,
                operator:
                    operator === SEARCH_QUERY_FILTER_OPERATOR.MATCH ? SEARCH_QUERY_FILTER_OPERATOR.REGULAR : operator,
            };
        default:
            return;
    }
}

export async function searchAndLoadAccountData(accountId: string): Promise<any> {
    const response = await customerCareAjax.getAccount(accountId, USER_MANAGER_HIERARCHY.ACCOUNT);
    // Set account data
    await store.dispatch('customerCare/setAccountData', response.data.account_info);

    // Set each subscribers state history. Due to fact that response does not include what ID it is we need to look
    // for our request information.
    const subIds = response.data.account_info.subscribers;
    const subStateHistoryPromises = subIds.map((subscriberId: string) =>
        customerCareAjax.getStateHistory(subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER),
    );

    const subStateHistories = await Promise.all(subStateHistoryPromises);

    // Set subscriber data for all subscriber
    const subscriberData = response.data.account_info.subscribers;

    // Injecting subscriber state history into sub model.
    subStateHistories.forEach(sub => {
        const id = JSON.parse(sub.config.data).subscriberId;
        subscriberData[id] = {
            ...subscriberData[id],
            ...getFormattedStateHistory(sub.data.state_history),
        };
    });

    await store.dispatch('customerCare/setSubscriberData', subscriberData);

    return response;
}

export function isQueryOptionValid(queryOption: SEARCH_QUERY_OPTION) {
    return Object.values(SEARCH_QUERY_OPTION).includes(queryOption);
}

export function isValidUsername(queryString: string) {
    return emailRegex.test(queryString) || msisdnRegex.test(queryString) || usernameRegex.test(queryString);
}

export function isValidSearchByAccountIdData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.ACCOUNT_ID && isValidUuid(id);
}

export function isValidSearchBySubscriberIdData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.SUBSCRIBER_ID && isValidUuid(id);
}

export function isValidSearchByMsisdnData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.MSISDN && msisdnRegex.test(id);
}

export function isValidSearchByImsiData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.IMSI && imsiRegex.test(id);
}

export function isValidSearchByOrganizationIdData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.ORGANIZATION_ID && imsiRegex.test(id);
}

export function isValidSearchByUsernameData(queryOption: SEARCH_QUERY_OPTION, id: string) {
    return queryOption === SEARCH_QUERY_OPTION.USERNAME && isValidUsername(id);
}

export function isUserSearchDataValid(queryOption: SEARCH_QUERY_OPTION, id: string) {
    if (isQueryOptionValid(queryOption)) {
        return (
            isValidSearchByAccountIdData(queryOption, id) ||
            isValidSearchBySubscriberIdData(queryOption, id) ||
            isValidSearchByMsisdnData(queryOption, id) ||
            isValidSearchByImsiData(queryOption, id) ||
            isValidSearchByOrganizationIdData(queryOption, id) ||
            isValidSearchByUsernameData(queryOption, id)
        );
    }
    return false;
}

export function getSearchOptionSource(option: SEARCH_QUERY_OPTION): SEARCH_QUERY_SOURCE {
    switch (option) {
        case SEARCH_QUERY_OPTION.ADDRESS:
            return SEARCH_QUERY_SOURCE.ADDRESSES;

        case SEARCH_QUERY_OPTION.EMAIL:
        case SEARCH_QUERY_OPTION.MSISDN:
        case SEARCH_QUERY_OPTION.PHONE_NUMBER:
            return SEARCH_QUERY_SOURCE.NOTIFICATION_INFO;

        default:
            return SEARCH_QUERY_SOURCE.PROPERTIES;
    }
}

const addressSearchFieldMap = new Map([
    [ADDRESS_FIELDS.HOUSE_NUMBER, { pattern: '<street number>', placeholder: '23' }],
    [ADDRESS_FIELDS.ADDRESS, { pattern: '<street name>', placeholder: 'Oak st' }],
    [ADDRESS_FIELDS.STREET_NAME, { pattern: '<street name>', placeholder: 'Oak st' }],
    [ADDRESS_FIELDS.STREET, { pattern: '<street name>', placeholder: 'Oak st' }],
    [ADDRESS_FIELDS.CITY, { pattern: '<city>', placeholder: 'New York' }],
    [ADDRESS_FIELDS.POST_CODE, { pattern: '<post code>', placeholder: '100001' }],
    [ADDRESS_FIELDS.ZIP, { pattern: '<post code>', placeholder: '100001' }],
    [ADDRESS_FIELDS.ZIP_CODE, { pattern: '<post code>', placeholder: '100001' }],
    [ADDRESS_FIELDS.BARANGAY, { pattern: '<barangay>', placeholder: 'Talomo' }],
    [ADDRESS_FIELDS.PROVINCE, { pattern: '<province>', placeholder: 'Davao del Sur' }],
    [ADDRESS_FIELDS.COUNTY, { pattern: '<county>', placeholder: 'Madison' }],
    [ADDRESS_FIELDS.STATE, { pattern: '<state>', placeholder: 'NY' }],
    [ADDRESS_FIELDS.UNIT_NUMBER, { pattern: '<unit number>', placeholder: '123' }],
]);

/**
 * IMPORTANT: Order of the returned fields affects UI and the order of address propeties.
 *
 * Multiple address props need to be supported for street name and post code as they differ
 * per operator.
 */
export function getAddressSearchFieldsOrder(): ADDRESS_FIELDS[] {
    const fields = permissionsService.getOperatorsAddressFields();
    const params: ADDRESS_FIELDS[] = [];
    const supportedFields: Array<ADDRESS_FIELDS[]> = [
        [ADDRESS_FIELDS.HOUSE_NUMBER],
        [ADDRESS_FIELDS.ADDRESS, ADDRESS_FIELDS.STREET_NAME, ADDRESS_FIELDS.STREET],
        [ADDRESS_FIELDS.UNIT_NUMBER],
        [ADDRESS_FIELDS.CITY],
        [ADDRESS_FIELDS.POST_CODE, ADDRESS_FIELDS.ZIP, ADDRESS_FIELDS.ZIP_CODE],
        [ADDRESS_FIELDS.BARANGAY],
        [ADDRESS_FIELDS.PROVINCE, ADDRESS_FIELDS.COUNTY],
        [ADDRESS_FIELDS.STATE],
    ];

    supportedFields.forEach(supported => {
        let param;
        if ((param = supported.find(f => fields.includes(f)))) {
            params.push(param);
        }
    });

    return params;
}

export function getAddressSearchPlaceholder(): string {
    const fields = getAddressSearchFieldsOrder();
    return fields.map(f => addressSearchFieldMap.get(f)?.placeholder).join(', ');
}

export function getAddressSearchSuggestionPattern(): string {
    const fields = getAddressSearchFieldsOrder();
    return fields.map(f => addressSearchFieldMap.get(f)?.pattern).join(', ');
}

export function getQuerySearchFilterParams({
    field,
    operator,
    query,
}: {
    field: SEARCH_QUERY_OPTION;
    operator: SEARCH_QUERY_FILTER_OPERATOR;
    query: string;
}): SearchUsersByReverseIndexRequest {
    const filter = {
        source: getSearchOptionSource(field),
        operator,
        field_name: field,
        value: query,
    };

    if (field === SEARCH_QUERY_OPTION.ADDRESS) {
        const values = query.split(',').map(a => a.trim());
        const fields = getAddressSearchFieldsOrder();
        const filters = fields
            .map((field, n) => values[n] && { ...filter, field_name: field, value: values[n] })
            .filter(<T>(val: T | string | undefined): val is T => Boolean(val));

        return { filters };
    }

    return {
        filters: [filter],
    };
}

interface SearchUsersByReverseIndexParam {
    source: SEARCH_QUERY_SOURCE;
    field_name: SEARCH_QUERY_OPTION | ADDRESS_FIELDS;
}

interface SearchUsersByReverseIndexFilterParam extends SearchUsersByReverseIndexParam {
    value: string;
    operator: SEARCH_QUERY_FILTER_OPERATOR;
}

interface SearchUsersByReverseIndexSortParam extends SearchUsersByReverseIndexParam {
    operator: 'asc' | 'desc';
}

export type SearchUsersByReverseIndexRequest = {
    filters: SearchUsersByReverseIndexFilterParam[];
    sort?: SearchUsersByReverseIndexSortParam[];
    pageNum?: number;
    pageSize?: number;
};

export type UserSearchResult = {
    user_id: string;
    operator_name: string;
    properties: {
        first_name?: string;
        last_name?: string;
    };
    notification_info?: {
        emails: string[];
        msisdns: string[];
    };
    addresses: Array<Partial<Record<'type', ADDRESS_TYPE> & Record<ADDRESS_FIELDS, string>>>;
    flags?: Record<string, boolean>;
};
export type UserSearchResultMapped = {
    userId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    address: string;
};

export type UserManagerSearchOption = Record<
    string,
    {
        key: SEARCH_QUERY_OPTION;
        label: TranslateResult;
        validator: (value: any) => boolean;
        placeholder: TranslateResult | string;
        errorMessage?: TranslateResult;
        supportedOperators: SEARCH_QUERY_FILTER_OPERATOR[];
    }
>;

export const UserManagerSearchOptions: UserManagerSearchOption = {
    AccountID: {
        key: SEARCH_QUERY_OPTION.ACCOUNT_ID,
        label: i18n.t('customerCare.accountId'),
        validator: (value: string) => isValidUuid(value),
        placeholder: '94caf1984e6e41a88ea7cce35bee9a24',
        errorMessage: i18n.t('customerCare.errorAlertMessages.accountIdValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    SubscriberID: {
        key: SEARCH_QUERY_OPTION.SUBSCRIBER_ID,
        label: i18n.t('customerCare.subscriberId'),
        validator: (value: string) => isValidUuid(value),
        placeholder: 'e392ca1b929242ca9f7b557158411e31',
        errorMessage: i18n.t('customerCare.errorAlertMessages.subscriberIdValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    MSISDN: {
        key: SEARCH_QUERY_OPTION.MSISDN,
        label: i18n.t('customerCare.msisdn'),
        validator: (value: string) => msisdnRegex.test(value),
        placeholder: '123456789012',
        errorMessage: i18n.t('customerCare.errorAlertMessages.msisdnValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    IMSI: {
        key: SEARCH_QUERY_OPTION.IMSI,
        label: i18n.t('customerCare.imsi'),
        validator: (value: string) => imsiRegex.test(value),
        placeholder: '310150123456789',
        errorMessage: i18n.t('customerCare.errorAlertMessages.imsiValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    Email: {
        key: SEARCH_QUERY_OPTION.EMAIL,
        label: i18n.t('customerCare.userInformation.email'),
        validator: (value: string) => emailRegex.test(value),
        placeholder: 'user@example.com',
        errorMessage: i18n.t('customerCare.errorAlertMessages.emailValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    OrganizationID: {
        key: SEARCH_QUERY_OPTION.ORGANIZATION_ID,
        label: i18n.t('customerCare.organizationId'),
        validator: (value: string) => isValidUuid(value),
        placeholder: '20v29uvn2393d022i3nf023in2',
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    UserID: {
        key: SEARCH_QUERY_OPTION.USER_ID,
        label: i18n.t('customerCare.userId'),
        validator: (value: string) => isValidUuid(value),
        placeholder: '94caf1984e6e41a88ea7cce35bee9a24',
        errorMessage: i18n.t('customerCare.errorAlertMessages.userIdValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    Username: {
        key: SEARCH_QUERY_OPTION.USERNAME,
        label: i18n.t('customerCare.userInformation.username'),
        validator: (value: string) => isValidUsername(value),
        placeholder: i18n.t('customerCare.search.usernamePlaceholder'),
        errorMessage: i18n.t('customerCare.errorAlertMessages.usernameValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    PhoneNumber: {
        key: SEARCH_QUERY_OPTION.PHONE_NUMBER,
        label: i18n.t('customerCare.userInformation.phoneNumberUser'),
        validator: (value: string) => phoneRegex.test(value),
        placeholder: i18n.t('customerCare.userInformation.insertPhoneNumber'),
        errorMessage: i18n.t('customerCare.errorAlertMessages.phoneNumberValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
    FirstName: {
        key: SEARCH_QUERY_OPTION.FIRST_NAME,
        label: i18n.t('customerCare.userInformation.firstName'),
        validator: (value: string) => textRegex.test(value),
        placeholder: 'John',
        errorMessage: i18n.t('customerCare.errorAlertMessages.firstNameValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH],
    },
    LastName: {
        key: SEARCH_QUERY_OPTION.LAST_NAME,
        label: i18n.t('customerCare.userInformation.lastName'),
        validator: (value: string) => textRegex.test(value),
        placeholder: 'Doe',
        errorMessage: i18n.t('customerCare.errorAlertMessages.lastNameValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH],
    },
    Address: {
        key: SEARCH_QUERY_OPTION.ADDRESS,
        label: i18n.t('customerCare.userInformation.address'),
        validator: (value: string) => addressRegex.test(value),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH],
        get placeholder() {
            return getAddressSearchPlaceholder();
        },
        get errorMessage() {
            return i18n.t('customerCare.errorAlertMessages.addressValidation', {
                pattern: getAddressSearchSuggestionPattern(),
            });
        },
    },
    MACAddress: {
        key: SEARCH_QUERY_OPTION.MAC_ADDRESS,
        label: i18n.t('customerCare.formattedQueryOptionsForSearch.macAddress'),
        validator: (value: string, disableValidator = false) => {
            if (disableValidator) {
                return true;
            }
            return macAddressRegex.test(value);
        },
        placeholder: i18n.t('customerCare.formattedQueryOptionsForSearch.macAddress'),
        errorMessage: i18n.t('customerCare.errorAlertMessages.macAddressValidation'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH],
    },
    BillingAccountID: {
        key: SEARCH_QUERY_OPTION.BILLING_ACCOUNT_ID,
        label: i18n.t('customerCare.formattedQueryOptionsForSearch.billingAccountId'),
        validator: (value: string) => billingAccountIdRegex.test(value),
        placeholder: i18n.t('customerCare.formattedQueryOptionsForSearch.billingAccountId'),
        errorMessage: i18n.t('billingAccount.billingAccountNotFound'),
        supportedOperators: [SEARCH_QUERY_FILTER_OPERATOR.MATCH],
    },
};

export enum MODAL_CONTENT {
    DEFAULT = 0,
    ADDRESS = 1,
    TOP_UP = 2,
    DEDUCT = 3,
    EMAIL = 4,
    CANCEL_TERMINATE = 5,
    SUSPEND = 6,
}

export default {
    searchAndLoadAccount,
    getSearchQueryEntityType,
    getSearchQueryLabel,
    getSearchQueryUrlPath,
};
