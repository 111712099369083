









































































































import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import {
    deletePolicyCounterDraft,
    updatePolicyCountersState,
} from '@/__new__/services/dno/charging/http/policyCounters';

// Routers
import RouteNames from '@/router/routeNames';

// Entities
import { entityStateReverseMap } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    getMultiLangFieldValueByLocale,
    getEntityConsumers,
    formatMutationDialogEntities,
} from '@/common/entities/entityHelper';

// Helpers
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { onlyFirstLetterUppercase } from '@/common/utils';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import { languageMap } from '@/common/locale/language';
import { getBestUnit, getUnitDefinitions, unitTypes } from '@/common/formatting';
import { upperFirst } from 'lodash';
import { PolicyCounter, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default Vue.extend({
    name: 'PolicyCounters',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        MutationDialog,
        ResponseModalButton,
        IconButton,
    },
    mixins: [FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            entityType: ENTITY_TYPES.POLICY_COUNTER as string,
            editorRoute: RouteNames.CHARGING_POLICY_COUNTER_EDITOR,
            searchQueryForTable: '' as string,
            selectedLanguage: '' as string,
            isDataLoading: false as boolean,
            ICON_TYPES,
            BUTTON_TYPES,
            PLURALIZATION,
            selectedEntity: null as PolicyCounter | null,
            isOverviewEnabled: false as boolean,
            isMutationModalVisible: false as boolean,
            mutationDialogDataType: '' as string,
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.charging, [Getters.GET_CHARGING_SPECIFICATION_BY_ID]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_POLICY_COUNTERS,
            Getters.GET_POLICY_COUNTERS_API_RESPONSE,
            Getters.GET_USAGE_COUNTER_BY_ID,
            Getters.GET_POLICY_COUNTER_BY_ID,
        ]),
        isEditEnabled() {
            return permissionsService.chargingPolicyCountersEnabled() && isUserAllowed('PolicyCountersReadWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_POLICY_COUNTERS_API_RESPONSE];
        },
        policyCounters(): PolicyCounter[] {
            if (!this[Getters.GET_POLICY_COUNTERS]) return [];
            return this[Getters.GET_POLICY_COUNTERS].map((pc: PolicyCounter) => ({
                ...pc,
                chargingSpec: this.findChargingSpecName(pc),
                usageCounterName: this.findUsageCounterName(pc),
            }));
        },
        sortedFilteredPolicyCounters(): any[] {
            return this.filteredEntitiesMixin(this.policyCounters);
        },
        selectedEntityId(): string {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            const unitDefinitions = getUnitDefinitions(unitTypes.DATA);
            let thresholds = [];

            if (this.selectedEntity?.thresholds?.length) {
                thresholds = this.selectedEntity.thresholds.map((value: number) => {
                    const selectedUnitDefinition = getBestUnit(unitDefinitions, value);
                    return `${value / selectedUnitDefinition.multiplier}${selectedUnitDefinition.label}`;
                });
            } else {
                thresholds = this.selectedEntity.thresholdsPercentage.map((value: number) => `${value}%`);
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: 'ID',
                        },
                        {
                            value: this.selectedEntity.name,
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity.description,
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.selectedEntity.statuses
                                ? this.selectedEntity.statuses.toString()
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.statuses'),
                        },
                        {
                            value: thresholds.toString(),
                            label: this.selectedEntity.thresholds
                                ? this.$i18n.t('charging.policyCounters.thresholds')
                                : this.$i18n.t('charging.policyCounters.thresholdsPercentage'),
                        },
                        {
                            value: this.selectedEntity.updateTime,
                            label: this.$i18n.t('charging.policyCounters.updatedTime'),
                        },
                    ],
                },
                {
                    name: this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR),
                    properties: [
                        {
                            value: this.selectedEntity.usageCounterId
                                ? this.selectedEntity.usageCounterId
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('charging.usageCounters.usageCounterID'),
                        },
                        {
                            value: this.findUsageCounterName(this.selectedEntity),
                            label: this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR),
                        },
                    ],
                },
                this.policyCounterInUseByUpperLevelEntities,
            ];
        },
        policyCounterInUseByUpperLevelEntities(): DetailsSections | object {
            const {
                [ENTITY_TYPES.POLICY_RULE]: inUseByPolicyRules = [],
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
            } = this.upperEntities();

            if (!inUseByCs.length && !inUseByPolicyRules.length) {
                return {};
            }

            const data: DetailsSections = {
                name: `${upperFirst(this.$i18n.t('charging.entities.plural.policyCounters'))} ${this.$i18n.t(
                    'generic.usedBy',
                )}`,
                properties: [],
            };

            if (inUseByCs.length) {
                data.properties.push({
                    label: `${inUseByCs.length} ${this.$i18n.t('charging.entities.plural.chargingSpecifications')}`,
                    value: inUseByCs.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.CHARGING_SPECIFICATION,
                });
            }

            if (inUseByPolicyRules.length) {
                data.properties.push({
                    label: `${inUseByPolicyRules.length} ${this.$i18n.t('charging.entities.plural.policyRules')}`,
                    value: inUseByPolicyRules.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.POLICY_RULE,
                });
            }

            return data;
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR),
                    key: 'usageCounterName',
                    field: 'usageCounterName',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'formattedDate',
                    field: 'update_time',
                    sortBy: (entity: PolicyCounter) => entity.update_time,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(
                            this.sortedFilteredPolicyCounters.map((e: PolicyCounter) => e.entityStatusCodeLabel),
                        ),
                    ],
                },
            ];
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_POLICY_COUNTERS,
            Actions.REQUEST_POLICY_RULES,
            Actions.REQUEST_USAGE_COUNTERS,
        ]),
        ...mapActions(Modules.charging, [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    const promises = [this[Actions.REQUEST_POLICY_COUNTERS]()];

                    if (permissionsService.chargingPolicyRulesEnabled() && isUserAllowed('PolicyRulesReadOnly')) {
                        promises.push(this[Actions.REQUEST_POLICY_RULES]());
                    }

                    if (
                        permissionsService.chargingChargingSpecificationsEnabled() &&
                        isUserAllowed('ChargingSpecificationsReadOnly')
                    ) {
                        promises.push(this[Actions.REQUEST_CHARGING_SPECIFICATIONS]());
                    }

                    if (permissionsService.chargingUsageCountersEnabled() && isUserAllowed('UsageCountersReadOnly')) {
                        promises.push(this[Actions.REQUEST_USAGE_COUNTERS]());
                    }

                    await Promise.all(promises);

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        findChargingSpecName(pc: PolicyCounter): string {
            if (pc.usageCounterId) {
                const chargingSpec = this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](pc.usageCounterId);
                if (Object.values(chargingSpec).length) {
                    return getMultiLangFieldValueByLocale(chargingSpec.name);
                }
            }
            return this.$i18n.t('generic.N/A');
        },
        findUsageCounterName(pc: PolicyCounter): string {
            if (pc.usageCounterId) {
                const usageCounter = this[Getters.GET_USAGE_COUNTER_BY_ID](pc.usageCounterId);
                if (usageCounter?.name) {
                    return usageCounter.name;
                }
            }
            return this.$i18n.t('generic.N/A');
        },
        onSelectEntity(id: string): void {
            this.selectedEntity = this[Getters.GET_POLICY_COUNTER_BY_ID](id);
            this.isOverviewEnabled = true;
        },
        onNewPolicyCounter(): void {
            this.$router.push({
                name: RouteNames.CHARGING_POLICY_COUNTER_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s: number): string {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        showMutationDialog(type: string): void {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value: string): void {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getAffectedEntities(mutationDialogDataType: string): object {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        upperEntities(entityId: string = this.selectedEntityId): object {
            return getEntityConsumers(entityId, this.entityType);
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_POLICY_COUNTER_BY_ID](entityId);
            const {
                [ENTITY_TYPES.POLICY_RULE]: inUseByPolicyRules = [],
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
            } = this.upperEntities(entityId);

            this.showDeleteAlert(entityId, Boolean(inUseByPolicyRules.length || inUseByCs.length), state, version, [
                updatePolicyCountersState,
                deletePolicyCounterDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
