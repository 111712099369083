/**
 * The API types currently supported by Device Line Authorization.
 * Enum values here are recognized by the DNO.
 */
export enum DEVICE_LINE_AUTH_API_TYPE {
    QOD = 1,
    DEVICE_STATUS = 2,
    WPS_BULK = 3,
    LOCATION_VERIFICATION = 4,
}

/**
 * A mapping from API types to dataflow ids.
 * Dataflow ids are needed to determine the URL for many device line auth requests.
 */
export const API_TYPE_TO_DATAFLOW_ID = new Map([
    [DEVICE_LINE_AUTH_API_TYPE.QOD, 'qod_msisdn_bulk_import_flow'],
    [DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS, 'device_status_bulk_import_flow'],
    [DEVICE_LINE_AUTH_API_TYPE.WPS_BULK, 'wps_bulk_import'],
    [DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION, 'location_verification_bulk_import_flow'],
]);
