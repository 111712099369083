









// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { CAMPAIGN_STATUS_TO_COLOR_MAPPING } from '@/__new__/services/dno/campaigns/models/Campaign';
import { TranslateResult } from 'vue-i18n';

export default {
    name: 'CampaignStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            default: '',
            type: [String, Number],
        },
    },

    computed: {
        colorConverter(): string | undefined {
            return CAMPAIGN_STATUS_TO_COLOR_MAPPING.get(this.status as TranslateResult);
        },
    },
};
