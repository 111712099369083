


















































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Vue components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import PricingRulesActions from '@/__new__/features/pricingAndFees/pricingRules/PricingRulesActions.vue';
import SimplePricingRuleForm from '@/__new__/features/pricingAndFees/pricingRules/SimplePricingRuleForm.vue';

// Mixins
import { validationMixin } from 'vuelidate';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

// Misc
import RouteNames from '@/router/routeNames';
import { EDITOR_MODE } from '@/common/entities/entityHelper';

// Permissions
import { isUserAllowed, isViewConfig, isViewEnabled } from '@/services/permissions/permissions.service';
import {
    getPricingRuleById,
    updatePricingRule,
    createPricingRule,
    getPricingRules,
} from '@/__new__/services/dno/pricing/pricingRulesService';
import { PricingRuleEntity } from '@/__new__/services/dno/pricing/pricingRulesPortal';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import {
    PreprocessorPortal,
    PricingRuleActionPortal,
    PricingRuleConditionPortal,
    SelectorPortal,
} from '@/__new__/services/dno/pricing/models/pricingPortal';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { getUserGMTString } from '@/common/utils';
import { EntityState } from '@/http';

export default Vue.extend({
    name: 'PricingRulesEditor',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        AppInputV3,
        AppJSON,
        AppTextareaV3,
        EditorButtons,
        PricingRulesActions,
        SimplePricingRuleForm,
    },
    mixins: [validationMixin],
    data() {
        return {
            BUTTON_TYPES,
            PLURALIZATION,
            isExistingPricingRule: false,
            isSimpleEditor: true,
            editorMode: EDITOR_MODE.VIEW,
            setPriorityValue: 0,
            entity: {
                actions: [] as PricingRuleActionPortal[],
                conditions: {
                    selectors: [] as SelectorPortal[],
                    preprocessors: [] as PreprocessorPortal[],
                    condition: {
                        args: {},
                    },
                } as PricingRuleConditionPortal,
                isSimplePricingRule: true,
                simplePricingRule: undefined,
                priority: 0,
            } as PricingRuleEntity,
            showValidation: false,
            simplePricingRuleHasError: false,
            readonly: false,
            hasEndTime: false,
            userGMTString: getUserGMTString(),
            allPricingRules: [] as PricingRuleEntity[],
        };
    },
    validations() {
        return {
            setPriorityValue: {
                required,
                minValue: minValue(1),
                numeric,
            },
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('pricingAndFees.viewPricingRule');
            }
            return this.isExistingPricingRule
                ? this.$i18n.t('pricingAndFees.editPricingRuleTitle')
                : this.$i18n.t('pricingAndFees.newPricingRulePageTitle');
        },
        compareCurrentPriorityWithOtherEntities(): boolean {
            return this.allPricingRules.some(({ priority }) => priority === Number(this.setPriorityValue));
        },
        alreadyUsedPriority(): boolean {
            return this.isExistingPricingRule
                ? Number(this.setPriorityValue) !== this.entity.priority && this.compareCurrentPriorityWithOtherEntities
                : this.compareCurrentPriorityWithOtherEntities;
        },
    },
    async mounted() {
        await this.$withLoadingSpinner(
            async () => {
                // Route params:
                const { id, clone, mode } = this.$route.params;

                // Determine if we're looking at an existing pricing rule
                this.isExistingPricingRule = Boolean(id) && !clone;

                // Set editor mode
                this.editorMode = mode;

                // Determine if page is readonly
                this.readonly = this.editorMode === EDITOR_MODE.VIEW || !this.userHasWriteAccess();

                // Initialize form data
                if (id) {
                    await this.loadEditor(id, clone);
                }

                // Set editor mode
                this.isSimpleEditor = id ? this.entity.isSimplePricingRule : true;
                await this.loadPricingRules();
            },
            {
                errorHandler: () => {
                    this.$alert(this.$t('pricingAndFees.failedToLoadPricingRule'));
                    setTimeout(() => {
                        this.routeToListPage();
                    }, 2000);
                },
            },
        );
    },
    methods: {
        /**
         * Loads up the editors fields.
         * Contents determined by entity of given `id`.
         * Append ('cloned') to name if we're cloning from given `id`.
         */
        async loadEditor(id: string, clone: boolean) {
            this.entity = await getPricingRuleById(id, this.selectedLanguage);
            this.setPriorityValue = this.entity.priority;
            if (clone) {
                this.entity.name += ' (cloned)';
            }
        },
        async onSave() {
            this.$v.$touch();
            // Exit on validation error
            if ((this.isSimpleEditor && this.simplePricingRuleHasError) || this.$v.$invalid) {
                this.showValidation = true;
                this.$alert(this.$i18n.t('generic.pleaseFixValidation'));
                return;
            }

            if (this.alreadyUsedPriority) {
                this.showValidation = true;
                this.$alert(this.$t('pricingAndFees.pricingRulePriorityError'));
                return;
            }

            // Determine if conversion from simple -> complex rule is occurring
            const isConvertingFromSimpleToComplex =
                this.isExistingPricingRule && !this.isSimpleEditor && this.entity.isSimplePricingRule;

            this.entity.priority = this.setPriorityValue;
            // For simple -> complex conversion user must confirm action
            if (isConvertingFromSimpleToComplex) {
                this.$alert(this.$i18n.t('pricingAndFees.savingAPricingRuleInAdvanced'), {
                    type: ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$i18n.t('generic.yes'),
                            handler: () => {
                                this.saveExistingPricingRule();
                            },
                        }),
                    ],
                });
                return;
            }

            // Regular save logic
            if (this.isExistingPricingRule) {
                await this.saveExistingPricingRule();
            } else {
                await this.saveNewPricingRule();
            }
        },
        async saveNewPricingRule() {
            await this.$withLoadingSpinner(
                async () => {
                    this.entity.isSimplePricingRule = this.isSimpleEditor; // Set entity mode based on editor state
                    await createPricingRule(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('pricingAndFees.successfullyCreatedPricingRule'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('pricingAndFees.failedToCreatePricingRule'));
                    },
                },
            );
        },
        async saveExistingPricingRule() {
            await this.$withLoadingSpinner(
                async () => {
                    this.entity.isSimplePricingRule = this.isSimpleEditor; // Set entity mode based on editor state
                    await updatePricingRule(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('pricingAndFees.successfullyUpdatedPricingRule'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('pricingAndFees.failedToUpdatePricingRule'));
                    },
                },
            );
        },
        routeToListPage() {
            this.$router.push({ name: RouteNames.PRICING_RULES, params: { companyId: this.$route.params.companyId } });
        },
        userHasWriteAccess(): boolean {
            return isViewConfig() && isViewEnabled('PricingRules') && isUserAllowed('PricingRulesWrite');
        },
        async loadPricingRules(): Promise<void> {
            const data = await getPricingRules(this.selectedLanguage);
            this.allPricingRules = data.pricingRules.filter(e => e.state !== EntityState.DELETED);
        },
    },
});
