






















































































































import Vue from 'vue';

// store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import QuickFilterTooltip from '@/components/partials/fileUploader/QuickFilterTooltip.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';

// http
import { getAssetUploadUrl, uploadAssetToSignedURL } from '@/__new__/services/dno/documents/http/assets';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    FILE_UPLOAD_STATES,
    FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP,
    FILE_UPLOAD_INDICATOR_MAP,
} from '@/common/commonHelper';
import { formatDataAmount } from '@/common/formatting';
import Button from '@/common/button/Button';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { type DocumentAsset } from '@/__new__/services/dno/documents/models/DocumentInterfaces';

export default Vue.extend({
    name: 'DocumentAssetsEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        QuickFilterTooltip,
        EntityStatusIndicator,
    },
    mixins: [entityEditorMixin],
    data() {
        return {
            FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP,
            FILE_UPLOAD_INDICATOR_MAP,
            FILE_UPLOAD_STATES,
            BUTTON_TYPES,
            ICON_TYPES,
            entityType: ENTITY_TYPES.DOCUMENT_ASSETS as string,
            isDragOn: false,
            acceptTypes: '.jpg,.jpeg,.png,.svg,.css,.ttf',
            fileContent: null as File | null,
            fileStatus: FILE_UPLOAD_STATES.PENDING,
            saveConfirmBtn: new Button({
                label: this.$t('generic.save'),
                handler: () => {
                    this.fileStatus = FILE_UPLOAD_STATES.UPLOADING;
                    this.saveConfirmed();
                },
            }) as Button,
        };
    },
    computed: {
        ...mapGetters(Modules.documents, { documentAssets: Getters.GET_DOCUMENT_ASSETS }),
        fileSize(): string {
            return this.fileContent?.size
                ? this.$t('operator.numberManagement.fileSize', {
                      size: formatDataAmount(this.fileContent?.size),
                  })
                : '';
        },
        isAssetReplace(): boolean {
            return this.documentAssets.map((d: DocumentAsset) => d.name).includes(this.fileContent?.name);
        },
    },
    created() {
        if (!this.documentAssets.length) {
            this.fetchAssets();
        }
    },
    methods: {
        ...mapActions(Modules.documents, [Actions.REQUEST_DOCUMENT_ASSETS]),
        preventAndStop(e: any): void {
            e.preventDefault();
            e.stopPropagation();
        },
        addDragOnClass(): void {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass(): void {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        isAppropriateFileType(name: string): boolean {
            const types = this.acceptTypes
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
        onSelect(e: any): void {
            const files = e.target.files || e.dataTransfer.files;
            this.addFilesToList(files);
        },
        onDrop(e: any): void {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            const appropriateFileType = !Object.values(e.dataTransfer.files).some(
                ({ name }) => !this.isAppropriateFileType(name),
            );

            if (appropriateFileType) {
                this.addFilesToList(e.dataTransfer.files);
            } else {
                this.$showWarningAlert({
                    message: `${this.$t('partials.fileUploader.invalidFileTypeText')}
                            ${this.$t('partials.fileUploader.youCanUpload')} ${this.acceptTypes}.`,
                });
            }
        },
        addFilesToList(files: File[]): void {
            if (!files.length) {
                return;
            }

            this.fileStatus = FILE_UPLOAD_STATES.PENDING;
            const [file] = files;
            this.fileContent = file;
        },
        removeFile(): void {
            this.fileContent = null;
        },
        onSave(): void {
            const message = this.isAssetReplace
                ? this.$t('documents.assets.confirmReplace', { asset: this.fileContent?.name })
                : this.$t('documents.assets.confirmSave');

            this.$alert(message, {
                type: ALERT_TYPES.warning,
                buttons: [this.saveConfirmBtn],
            });
        },
        async saveConfirmed(): Promise<void> {
            if (!this.fileContent?.name) {
                this.$alert(this.$t('documents.assets.notValidFileError'));
                throw new Error('Not valid file');
            }

            try {
                this.$Progress.start();
                const fileName = this.formatedName(this.fileContent.name);

                const {
                    data: {
                        data: { signed_url: signedUrl },
                    },
                } = await getAssetUploadUrl(fileName);

                if (!signedUrl) {
                    this.$alert(this.$t('documents.assets.notValidSignedUrl'));
                    return;
                }

                this.uploadAssetToSignedURL(signedUrl);
            } catch (e: any) {
                Sentry.captureException(e);
                this.$alert(e?.message ?? this.$t('documents.assets.saveError'));
                this.$Progress.fail();
                this.fileStatus = FILE_UPLOAD_STATES.UPLOADING_FAILED;
            }
        },
        async uploadAssetToSignedURL(signedUrl: string): Promise<void> {
            if (!this.fileContent) {
                this.$alert(this.$t('documents.assets.notValidFileError'));
                throw new Error('Not valid file');
            }

            try {
                await uploadAssetToSignedURL(this.fileContent, signedUrl);

                this.$showSuccessAlert({
                    message: this.$t('documents.assets.added'),
                });

                this.fileStatus = FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED;
                this.$Progress.finish();
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.DOCUMENT_ASSETS,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    1000,
                );
            } catch (e: any) {
                Sentry.captureException(e);
                this.$alert(e?.message ?? this.$t('documents.assets.saveError'));
                this.$Progress.fail();
                this.fileStatus = FILE_UPLOAD_STATES.UPLOADING_FAILED;
            }
        },
        async fetchAssets() {
            await this.$withProgressBar(this[Actions.REQUEST_DOCUMENT_ASSETS](), {
                errorHandler: e => this.$alert(e.message),
            });
        },
        formatedName(name: string): string {
            return name.replace(/[^a-zA-Z0-9.]/g, '_');
        },
    },
});
