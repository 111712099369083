


































import Vue from 'vue';

// Components
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';
import AppField from '@/components/partials/AppField.vue';

// Helpers
import { BENEFITS_TYPES, getBenefits } from '@/http/benefits';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Benefit from '@/models/Benefit';
import { PROMOTION_TYPES, amountByTypeFormatting } from '@/modules/promotions/common/promotionsHelper';

export default Vue.extend({
    name: 'CurrentBenefitsTile',
    components: {
        AssociatedUMEntityPicker,
        AppField,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.USER,
        },
        userOwnerId: {
            type: String,
            default: 'bd7641847ab04c4fb56a6bb1dd5afde1',
        },
        isDeductEnabled: {
            type: Boolean,
            default: true,
        },
        isTopUpEnabled: {
            type: Boolean,
            default: true,
        },
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedAssociatedAccount: null,
            benefits: [],
            benefitsApiResponse: null,
            latestBenefit: null,

            // proxy
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        promotionTypeToDisplay() {
            return this.latestBenefit
                ? Object.values(PROMOTION_TYPES).find(
                      promotionType => promotionType.id === this.latestBenefit.promotionTypeId,
                  )?.label
                : this.$i18n.t('generic.N/A');
        },
        formattedAmount() {
            return this.latestBenefit
                ? amountByTypeFormatting(this.latestBenefit.amount, this.latestBenefit.promotionTypeId)
                : this.$i18n.t('generic.N/A');
        },
        remainingUses() {
            return this.latestBenefit
                ? this.latestBenefit.recurrenceLimit - this.latestBenefit.recurrenceCounter
                : this.$i18n.t('generic.N/A');
        },
        benefitId() {
            return this.latestBenefit ? this.latestBenefit.benefitId : this.$i18n.t('generic.N/A');
        },
    },
    watch: {
        selectedAssociatedAccount: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.benefitsApiResponse = await getBenefits(
                        BENEFITS_TYPES.AVAILABLE,
                        this.selectedAssociatedAccount.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    // eslint-disable-next-line camelcase
                    const rawBenefits = this.benefitsApiResponse?.data?.benefits_by_id;

                    if (rawBenefits) {
                        this.latestBenefit = Benefit.remapBenefitFromBe(
                            Object.values(rawBenefits)
                                .sort((a, b) => a.grant_date - b.grant_date)
                                .slice(-1)[0],
                        );
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
