




























// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

// HTTP
import { getDiscountsForBillingAccount } from '@/__new__/services/portal/postpaid/discounts/discounts';

export default {
    name: 'BillingAccountDiscountsTile',
    components: {
        AbstractTableTile,
        AppToggle,
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            account: null,
            showOnlyUnprocessedDiscounts: false,
            ICON_TYPES,
        };
    },
    computed: {
        formattedEntities() {
            const discountsInfoCopy = cloneDeep(this.rawResponse);
            const discounts = discountsInfoCopy?.data;
            if (discounts?.length) {
                return discounts.map(item => ({
                    id: item.id,
                    dateCreated: this.$localeLibrary.getFormattedDateAndTime(item.dateCreated),
                    amount: formatAmountBasedOnCurrency(item.amount),
                    discountType: item.discountType,
                    description: item.description,
                    invoiceId: item.invoiceId ?? this.$i18n.t('billingAccount.unprocessed'),

                    dateApplied: this.$localeLibrary.getFormattedDateAndTime(item.dateApplied),
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('billingAccount.id'),
                    key: 'id',
                },
                {
                    name: this.$i18n.t('billingAccount.dateCreated'),
                    key: 'dateCreated',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amount',
                },
                {
                    name: this.$i18n.t('billingAccount.discountType'),
                    key: 'discountType',
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                },
                {
                    name: this.$i18n.t('billingAccount.invoiceId'),
                    key: 'invoiceId',
                },

                {
                    name: this.$i18n.t('billingAccount.dateApplied'),
                    key: 'dateApplied',
                },
            ];
        },
    },
    watch: {
        showOnlyUnprocessedDiscounts: {
            deep: true,
            handler() {
                this.fetchTileData();
            },
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.rawResponse = await getDiscountsForBillingAccount(
                        this.$route.params.id,
                        this.showOnlyUnprocessedDiscounts,
                    );
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectedRow(entity: object): void {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
    },
};
