


























import Vue, { PropType } from 'vue';

// Components
import TaxAttributionTable from './TaxAttributionTable.vue';

// Helpers
import { TaxAttributionData } from './common/taxAttribution';
import { taxAttributionDnoToFe, taxAttributionFeToDno } from './common/taxAttributionUtils';
import { TaxAttributionDataDno } from './common/taxAttributionDno';

import ENTITY_TYPES from '@/common/entities/entityTypes';

/**
 * Structure of the `value` prop for this component.
 */
interface TaxAttributionTableFormBuilderWrapperValue {
    /**
     * The child entities that can be selected by the TaxAttributionTable
     * It's been crammed in the value since the formbuilder doesn't give
     * us a way to pass in additional props.
     */
    childEntities: any[] | undefined;
    /**
     * The actual tax attribution data from the DNO. This will be updated
     * as the user makes changes on the front end table.
     */
    tax: TaxAttributionDataDno | undefined;
    /**
     * Flag indicating if table has errors.
     */
    error: boolean;
}

export default Vue.extend({
    name: 'TaxAttributionTableFormBuilderWrapper',
    components: {
        TaxAttributionTable,
    },
    props: {
        /**
         * See docs for `TaxAttributionTableFormBuilderWrapperValue`
         */
        value: {
            type: Object as PropType<TaxAttributionTableFormBuilderWrapperValue>,
            default: () => ({
                childEntities: undefined,
                tax: undefined,
                error: false,
            }),
        },
        /**
         * Disables all child components. Used for read only mode.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Flag indicating if tax attribution table will show validation errors
         */
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            childEntities: [] as unknown[],
            taxAttributionTableData: undefined as TaxAttributionData | undefined,
        };
    },
    watch: {
        value: {
            handler() {
                // Determine child entities to provide to the tax component
                // Note: Only supporting *Products* that have a *tax_component_id*
                const childProducts =
                    this.value.childEntities?.filter(e => e.entityType === ENTITY_TYPES.PRODUCT) ?? [];
                this.childEntities = childProducts.filter(
                    e => e.properties?.tax_component_id && typeof e.properties.tax_component_id === 'string',
                );

                // Convert backend tax data to front end structure
                if (this.value.tax) {
                    this.taxAttributionTableData = taxAttributionDnoToFe(this.value.tax);
                }
            },
            immediate: true,
        },
    },
    methods: {
        onTaxAttributionTableError(tableHasError: boolean) {
            this.value.error = tableHasError;
        },
        onTaxAttributionTableUpdate(taxData: TaxAttributionData) {
            // Convert front end model into that expected by backend
            this.value.tax = taxAttributionFeToDno(taxData, this.childEntities);
        },
    },
});
