var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-page d-flex flex-column p-0"},[_c('div',[_c('AppHeader',{staticClass:"mb-2",attrs:{"pageTitle":_vm.$i18n.t('customerCare.breadcrumbs.searchResults')}}),_c('TableFiltersRenderless',{staticClass:"section-layout",attrs:{"entities":_vm.users},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var allFilters = ref.allFilters;
                var filteredEntities = ref.filteredEntities;
                var onFilterAdded = ref.onFilterAdded;
                var removeFilter = ref.removeFilter;
                var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"d-flex position-relative align-items-center"},[_c('Breadcrumbs',{attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('div',{staticClass:"d-flex align-items-center ml-auto"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.apiResponse,"title":_vm.$i18n.t('customerCare.outageHistory')}}),_c('FilterTable',{attrs:{"columns":_vm.columnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"columnsData":_vm.columnsData,"innerSearchQuery":_vm.searchQuery,"isDataLoading":_vm.isDataLoading,"isDefaultHoverEnabled":true,"isSearchEnabled":true,"useExternalPagination":true,"rangeAmount":{ start: 1, end: _vm.pageSize },"canSelectColumns":true,"newDesign":true,"noEntitiesMockText":_vm.$i18n.t('generic.noResultsFound'),"tableKey":"user/search-results"},scopedSlots:_vm._u([{key:"userId",fn:function(ref){
                var entity = ref.entity;
return [_c('router-link',{attrs:{"to":{
                                    name: _vm.userRoute,
                                    params: {
                                        companyId: _vm.$route.params.companyId,
                                        id: entity.userId,
                                    },
                                },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.userId)+" ")])]}}],null,true)}),(_vm.users.length)?_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-3"},[_c('AppPaginationLoadMore',{attrs:{"hasMore":_vm.hasMorePages},on:{"input":_vm.fetchUserData},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('AppMultiselectV3',{staticClass:"pagination-amount ml-2",attrs:{"searchable":false,"showLabels":false,"options":_vm.pageAmountOptions,"borderNone":true,"blueArrow":true,"placeholder":_vm.$i18n.t('table.amountPerPage'),"label":"label","trackBy":"value"},on:{"input":_vm.updatePageSize},model:{value:(_vm.pageAmount),callback:function ($$v) {_vm.pageAmount=$$v},expression:"pageAmount"}})],1):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"section-layout"},[_c('AppButton',{staticClass:"mr-4",attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"label":_vm.$t('generic.cancel'),"data-test-id":"cancel-button"},on:{"click":_vm.onCancel}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }