import http from '@/http';
import set from 'lodash/set';
import { textToLowerCase } from '@/common/formatting';
import { SINK_CONFIG_STATUS } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';

const redshiftPrefix = '/cepdew/dwhconfig/api/v3/dwhconfig/';
const kafkaPrefix = '/cepdew/kafkasinkconfig/api/v3/kafkasinkconfig/';
const apiInvokerJcmPathPrefix = '/apiinvoker/api-invoker/management/configs/operators/$operator_name/jobs';

export function getFormatters() {
    return http({
        method: 'GET',
        url: '/cepdew/customevent/api/v3/cdp/events/schema/get_formatter_settings',
    });
}

export function getTypeCasters() {
    return http({
        method: 'GET',
        url: '/cepdew/customevent/api/v3/cdp/events/schema/get_caster_settings',
    });
}

export function getEventJsonPaths(eventType) {
    return http({
        method: 'POST',
        url: 'ceapi/cep-site-backend/eventSchema/dereference/toJsonPaths',
        data: {
            eventType,
        },
    });
}
// redshift
export function getRedshiftFormatters() {
    return http({
        method: 'GET',
        url: `${redshiftPrefix}get_formatter_settings`,
    });
}
export function getRedshiftTypeCasters() {
    return http({
        method: 'GET',
        url: `${redshiftPrefix}get_caster_settings`,
    });
}
export function getAllRedshiftConfigs() {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}get_all`,
    });
}
export function getAllRedshiftDrafts() {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}draft/get_all`,
    });
}
export function fetchRedshiftConfigUIParams() {
    return http({
        method: 'GET',
        url: `${redshiftPrefix}get_sink_ui_config`,
    });
}

export function fetchRedshiftConfigRequiredFields() {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}get_required_fields`,
        data: {
            enable_casters: true,
        },
    });
}

export function createRedshiftConfig(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}add_entity`,
        data,
    });
}
export function updateRedshiftConfig(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}update_entity`,
        data,
    });
}
export function createRedshiftDraft(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}draft/set`,
        data,
    });
}
export function deleteRedshiftConfig(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}delete_entity`,
        data,
    });
}
export function deleteRedshiftDraft(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}draft/delete`,
        data,
    });
}
export function updateRedshiftConfigState(data) {
    return http({
        method: 'POST',
        url: `${redshiftPrefix}update_entity_state`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data,
    });
}
// ORD

/**
 * @param {SINK_CONFIG_STATUS[]} states - States to be filtered by api call
 */
export function getAllReportConfigs(states) {
    // TODO POR-4911 refactor JCM PHP controller and remove apiinvoker prefix
    return http({
        method: 'GET',
        url: '/apiinvoker/operational-reports-daemon/management/configs/operators/$operator_name/jobs',
        params: {
            state: states?.join(','),
        },
    });
}

export function fetchReportConfigUIParams() {
    // TODO POR-4911 refactor JCM PHP controller and remove apiinvoker prefix
    return http({
        method: 'GET',
        url: '/apiinvoker/operational-reports-daemon/api/v3/operational_reports_daemon/get_sink_ui_config',
    });
}
export function upsertReportConfig(payload) {
    const { state } = payload.data;
    // TODO POR-4911 refactor JCM PHP controller and remove apiinvoker prefix
    return http({
        method: 'POST',
        url: '/apiinvoker/operational-reports-daemon/management/configs/operators/$operator_name/jobs:apply',
        data: {
            job_definition: {
                ...payload.data.specific_configuration,
                sink_entity_properties: {
                    trigger_condition: payload.data.trigger_condition,
                    fields_configuration: payload.data.fields_configuration,
                    fields_configuration_version: payload.data.fields_configuration++ || 1,
                    cohorts_configuration: payload.data.cohorts_configuration,
                },
            },
            revision_number: payload.version ?? 1,
            state: state ? textToLowerCase(state) : textToLowerCase(SINK_CONFIG_STATUS.ENABLED),
        },
    });
}
export function deleteReportConfig(payload) {
    const reportId = payload.id;
    // TODO POR-4911 refactor JCM PHP controller and remove apiinvoker prefix
    return http({
        method: 'DELETE',
        url: `/apiinvoker/operational-reports-daemon/management/configs/operators/$operator_name/jobs/${reportId}`,
        data: {
            id: payload.id,
            version: payload.version,
        },
    });
}

// kafka
export function getAllKafkaConfigs() {
    return http({
        method: 'GET',
        url: `${kafkaPrefix}get_all`,
    });
}
export function getAllKafkaDrafts() {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}draft/get_all`,
    });
}
export function fetchKafkaConfigUIParams() {
    return http({
        method: 'GET',
        url: `${kafkaPrefix}get_sink_ui_config`,
    });
}
export function createKafkaConfig(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}add_entity`,
        data,
    });
}
export function updateKafkaConfig(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}update_entity`,
        data,
    });
}
export function createKafkaDraft(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}draft/set`,
        data,
    });
}
export function deleteKafkaConfig(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}delete_entity`,
        data,
    });
}
export function deleteKafkaDraft(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}draft/delete`,
        data,
    });
}
export function updateKafkaConfigState(data) {
    return http({
        method: 'POST',
        url: `${kafkaPrefix}update_entity_state`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data,
    });
}
// api invoker
export function deleteApiInvokerConfig({ id }) {
    return http({
        method: 'DELETE',
        url: `${apiInvokerJcmPathPrefix}/${id}`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}
export function upsertApiInvokerConfig(payload) {
    const body = {
        job_definition: {
            id: payload.data.specific_configuration.id,
            name: payload.data.name,
            description: payload.data.specific_configuration.description,
            cluster_name: payload.data.specific_configuration.cluster_name,
            ...set({}, 'request_params.uri.value', payload.data.specific_configuration['request_params.uri.value']),
            topic: payload.data.specific_configuration.topic,
            topic_auto_creation: { enabled: true },
            sink_entity_properties: {
                ...payload.data,
                should_send_raw_event: payload.data.specific_configuration.should_send_raw_event,
            },
        },
        revision_number: payload.version ?? 1,
    };
    return http({
        method: 'POST',
        url: `${apiInvokerJcmPathPrefix}:apply`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: body,
    });
}
export function fetchApiInvokerSinkUIParams() {
    return http({
        method: 'GET',
        url: '/apiinvoker/api-invoker/api/v3/api_invoker/get_sink_ui_config',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    });
}
export function getAllApiInvokerConfigs() {
    return http({
        method: 'GET',
        url: apiInvokerJcmPathPrefix,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
            state: textToLowerCase(SINK_CONFIG_STATUS.ENABLED),
        },
    });
}

export default {
    getFormatters,
    getTypeCasters,
    getRedshiftTypeCasters,
    getAllRedshiftConfigs,
    fetchRedshiftConfigUIParams,
    createRedshiftConfig,
    updateRedshiftConfig,
    getAllReportConfigs,
    fetchReportConfigUIParams,
    createReportConfig: upsertReportConfig,
    getAllKafkaConfigs,
    fetchKafkaConfigUIParams,
    createKafkaConfig,
    updateKafkaConfig,
};
