import { isNaN } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';
import { parseNumberFromString } from '@/common/utils';
import localeLibrary from '@/common/locale/localeLibrary';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';

type ColumnOptions = {
    i18nLabel: string;
    key: string;
    isRange?: boolean;
    getDisplayValue?(values: { [key: string]: any }): string;
    filterFn(entity: { [key: string]: any }, values: { [key: string]: any }, field: string): boolean;
};

type ColumnDefinition = {
    id: number;
    key: string;
    options: { [operationId: string]: ColumnOptions };
    sortBy?(key: string): (entity: { [key: string]: any }) => any;
};

type Entity = {
    [key: string]: any;
};

export type TableColumn = {
    name: string;
    key: string;
    field: string;
    mapper?: (e: Entity) => any;
    formatter?: (e: Entity[keyof Entity]) => any;
    sortBy?: string;
    filterType: ColumnDefinition;
    limitedOptions?: any[];
    forbidHideColumn?: boolean;
    initiallyHidden?: boolean;
    classes?: string[];
    width?: string;
};

export type Filter = {
    column: {
        field: string;
        filterType: {
            key: string;
        };
        limitedOptions?: string[];
    };
    condition: {
        key: string;
    };
    values: string[];
};

export const optionsEnum = {
    contains: {
        i18nLabelKey: 'tableFilter.contains',
        key: 'contains',
    },
    doesNotContain: {
        i18nLabelKey: 'tableFilter.doesNotContain',
        key: 'does_not_contain',
    },
    is: {
        i18nLabelKey: 'tableFilter.is',
        key: 'is',
    },
    isNot: {
        i18nLabelKey: 'tableFilter.isNot',
        key: 'is_not',
    },
    startsWith: {
        i18nLabelKey: 'tableFilter.startsWith',
        key: 'starts_with',
    },
    endsWith: {
        i18nLabelKey: 'tableFilter.endsWith',
        key: 'ends_with',
    },
    isBefore: {
        i18nLabelKey: 'tableFilter.isBefore',
        key: 'is_before',
    },
    isAfter: {
        i18nLabelKey: 'tableFilter.isAfter',
        key: 'is_after',
    },
    isBetween: {
        i18nLabelKey: 'tableFilter.isBetween',
        key: 'is_between',
    },
    isEqual: {
        i18nLabelKey: 'tableFilter.isEqual',
        key: 'isEqual',
    },
    isNotEqual: {
        i18nLabelKey: 'tableFilter.isNotEqual',
        key: 'isNotEqual',
    },
    isGreater: {
        i18nLabelKey: 'tableFilter.isGreater',
        key: 'isGreater',
    },
    isLess: {
        i18nLabelKey: 'tableFilter.isLess',
        key: 'isLess',
    },
};

const tableColumnType: { [key: ColumnDefinition['key']]: ColumnDefinition } = {
    GENERAL_TEXT: {
        id: 1,
        key: 'GENERAL_TEXT',
        options: {
            contains: {
                i18nLabel: optionsEnum.contains.i18nLabelKey,
                key: optionsEnum.contains.key,
                filterFn: (entity, values, field) =>
                    entity[field] && entity[field].toLowerCase().includes(values.value.toLowerCase()),
            },
            is: {
                i18nLabel: optionsEnum.is.i18nLabelKey,
                key: optionsEnum.is.key,
                filterFn: (entity, values, field) =>
                    entity[field] && entity[field].toLowerCase() === values.value.toLowerCase(),
            },
            is_not: {
                i18nLabel: optionsEnum.isNot.i18nLabelKey,
                key: optionsEnum.isNot.key,
                filterFn: (entity, values, field) =>
                    entity[field] && entity[field].toLowerCase() !== values.value.toLowerCase(),
            },
            starts_with: {
                i18nLabel: optionsEnum.startsWith.i18nLabelKey,
                key: optionsEnum.startsWith.key,
                filterFn: (entity, values, field) =>
                    entity[field] && entity[field].toLowerCase().startsWith(values.value.toLowerCase()),
            },
            ends_with: {
                i18nLabel: optionsEnum.endsWith.i18nLabelKey,
                key: optionsEnum.endsWith.key,
                filterFn: (entity, values, field) =>
                    entity[field] && entity[field].toLowerCase().endsWith(values.value.toLowerCase()),
            },
        },
        sortBy(key) {
            return entity => entity[key]?.toLowerCase();
        },
    },
    DATE: {
        id: 2,
        key: 'DATE',
        options: {
            is: {
                i18nLabel: optionsEnum.is.i18nLabelKey,
                key: optionsEnum.is.key,
                getDisplayValue: values =>
                    `${localeLibrary.normalizeTimezone(values.value.getTime()).format(localeLibrary.getDateFormat())}`,
                filterFn: (entity, values, field) => {
                    if (!entity[field]) return false;
                    const entityTimestamp = moment.isMoment(entity[field]) ? entity[field].valueOf() : entity[field];
                    const entityDateNormalized = localeLibrary.normalizeTimezone(entityTimestamp);
                    const filterDateNormalized = localeLibrary.normalizeTimezone(values.value.getTime());
                    return entityDateNormalized.isSame(filterDateNormalized, 'day');
                },
            },
            is_not: {
                i18nLabel: optionsEnum.isNot.i18nLabelKey,
                key: optionsEnum.isNot.key,
                getDisplayValue: values =>
                    `${localeLibrary.normalizeTimezone(values.value.getTime()).format(localeLibrary.getDateFormat())}`,
                filterFn: (entity, values, field) => {
                    if (!entity[field]) return false;
                    const entityTimestamp = moment.isMoment(entity[field]) ? entity[field].valueOf() : entity[field];
                    const entityDateNormalized = localeLibrary.normalizeTimezone(entityTimestamp);
                    const filterDateNormalized = localeLibrary.normalizeTimezone(values.value.getTime());
                    return !entityDateNormalized.isSame(filterDateNormalized, 'day');
                },
            },
            is_before: {
                i18nLabel: optionsEnum.isBefore.i18nLabelKey,
                key: optionsEnum.isBefore.key,
                getDisplayValue: values =>
                    `${localeLibrary.normalizeTimezone(values.value.getTime()).format(localeLibrary.getDateFormat())}`,
                filterFn: (entity, values, field) => {
                    if (!entity[field]) return false;
                    const entityTimestamp = moment.isMoment(entity[field]) ? entity[field].valueOf() : entity[field];
                    const entityDateNormalized = localeLibrary.normalizeTimezone(entityTimestamp);
                    const filterDateNormalized = localeLibrary.normalizeTimezone(values.value.getTime());
                    return entityDateNormalized.isBefore(filterDateNormalized.add(1, 'day'));
                },
            },
            is_after: {
                i18nLabel: optionsEnum.isAfter.i18nLabelKey,
                key: optionsEnum.isAfter.key,
                getDisplayValue: values =>
                    `${localeLibrary.normalizeTimezone(values.value.getTime()).format(localeLibrary.getDateFormat())}`,
                filterFn: (entity, values, field) => {
                    if (!entity[field]) return false;
                    const entityTimestamp = moment.isMoment(entity[field]) ? entity[field].valueOf() : entity[field];
                    const entityDateNormalized = localeLibrary.normalizeTimezone(entityTimestamp);
                    const filterDateNormalized = localeLibrary.normalizeTimezone(values.value.getTime());
                    return entityDateNormalized.isAfter(filterDateNormalized);
                },
            },
            is_between: {
                i18nLabel: optionsEnum.isBetween.i18nLabelKey,
                key: optionsEnum.isBetween.key,
                isRange: true,
                getDisplayValue: values =>
                    `${localeLibrary.normalizeTimezone(values.value.getTime()).format(localeLibrary.getDateFormat())}
                    ${i18n.t('generic.and')}
                    ${localeLibrary
                        .normalizeTimezone(values.endValue.getTime())
                        .format(localeLibrary.getDateFormat())}`,
                filterFn: (entity, values, field) => {
                    if (!entity[field]) return false;
                    const entityTimestamp = moment.isMoment(entity[field]) ? entity[field].valueOf() : entity[field];
                    const entityDateNormalized = localeLibrary.normalizeTimezone(entityTimestamp);
                    const filterStartDateNormalized = localeLibrary.normalizeTimezone(values.value.getTime());
                    const filterEndDateNormalized = localeLibrary.normalizeTimezone(values.endValue.getTime());
                    return (
                        entityDateNormalized.isBefore(filterEndDateNormalized.add(1, 'day')) &&
                        entityDateNormalized.isAfter(filterStartDateNormalized)
                    );
                },
            },
        },
    },
    NUMBER: {
        id: 3,
        key: 'NUMBER',
        options: {
            isEqual: {
                i18nLabel: optionsEnum.isEqual.i18nLabelKey,
                key: optionsEnum.isEqual.key,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) && entity[field] && parseFloat(entity[field]) === parseFloat(values.value),
            },
            isNotEqual: {
                i18nLabel: optionsEnum.isNotEqual.i18nLabelKey,
                key: optionsEnum.isNotEqual.key,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) && entity[field] && parseFloat(entity[field]) !== parseFloat(values.value),
            },
            isGreater: {
                i18nLabel: optionsEnum.isGreater.i18nLabelKey,
                key: optionsEnum.isGreater.key,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) && entity[field] && parseFloat(entity[field]) > parseFloat(values.value),
            },
            isLess: {
                i18nLabel: optionsEnum.isLess.i18nLabelKey,
                key: optionsEnum.isLess.key,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) && entity[field] && parseFloat(entity[field]) < parseFloat(values.value),
            },
        },
        sortBy(key) {
            return entity => parseNumberFromString(entity[key]);
        },
    },
    NUMBER_UNITS: {
        id: 4,
        key: 'NUMBER_UNITS',
        options: {
            isEqual: {
                i18nLabel: optionsEnum.isEqual.i18nLabelKey,
                key: optionsEnum.isEqual.key,
                getDisplayValue: valueObj => `${valueObj.value} ${valueObj.unit.label}`,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) &&
                    entity[field] &&
                    parseFloat(entity[field]) === parseFloat(values.value) * values.unit.multiplier,
            },
            isNotEqual: {
                i18nLabel: optionsEnum.isNotEqual.i18nLabelKey,
                key: optionsEnum.isNotEqual.key,
                getDisplayValue: valueObj => `${valueObj.value} ${valueObj.unit.label}`,
                filterFn: (entity, values, field) =>
                    !isNaN(values.value) &&
                    entity[field] &&
                    parseFloat(entity[field]) !== parseFloat(values.value) * values.unit.multiplier,
            },
            isGreater: {
                i18nLabel: optionsEnum.isGreater.i18nLabelKey,
                key: optionsEnum.isGreater.key,
                getDisplayValue: valueObj => `${valueObj.value} ${valueObj.unit.label}`,
                filterFn: (entity, values, field) => {
                    if (isOmittedUnitType(entity, field, values?.unit?.id)) {
                        return false;
                    }
                    return (
                        !isNaN(values.value) &&
                        entity[field] &&
                        parseFloat(entity[field]) > parseFloat(values.value) * values.unit.multiplier
                    );
                },
            },
            isLess: {
                i18nLabel: optionsEnum.isLess.i18nLabelKey,
                key: optionsEnum.isLess.key,
                getDisplayValue: valueObj => `${valueObj.value} ${valueObj.unit.label}`,
                filterFn: (entity, values, field) => {
                    if (isOmittedUnitType(entity, field, values?.unit?.id)) {
                        return false;
                    }
                    return (
                        !isNaN(values.value) &&
                        entity[field] &&
                        parseFloat(entity[field]) < parseFloat(values.value) * values.unit.multiplier
                    );
                },
            },
        },
    },
    TEXT_LIMITED_OPTIONS: {
        id: 5,
        key: 'TEXT_LIMITED_OPTIONS',
        options: {
            is: {
                i18nLabel: optionsEnum.is.i18nLabelKey,
                key: optionsEnum.is.key,
                filterFn: (entity, values, field) => entity[field] && entity[field] === values.value,
            },
            is_not: {
                i18nLabel: optionsEnum.isNot.i18nLabelKey,
                key: optionsEnum.isNot.key,
                filterFn: (entity, values, field) => entity[field] && entity[field] !== values.value,
            },
        },
    },
    DATETIME: {
        id: 6,
        key: 'DATETIME',
        options: {
            is_before: {
                i18nLabel: optionsEnum.isBefore.i18nLabelKey,
                key: optionsEnum.isBefore.key,
                getDisplayValue: values => `${localeLibrary.getFormattedDateAndTime(values.value)}`,
                filterFn: (entity, values, field) =>
                    entity[field] &&
                    (moment(entity[field]).isBefore(values.value, 'minute') ||
                        moment(
                            entity[field],
                            `${localeLibrary.getDateFormat()} ${localeLibrary.getTimeFormat()}`,
                        ).isBefore(values.value, 'minute')),
            },
            is_after: {
                i18nLabel: optionsEnum.isAfter.i18nLabelKey,
                key: optionsEnum.isAfter.key,
                getDisplayValue: values => `${localeLibrary.getFormattedDateAndTime(values.value)}`,
                filterFn: (entity, values, field) =>
                    entity[field] &&
                    (moment(entity[field]).isAfter(values.value, 'minute') ||
                        moment(
                            entity[field],
                            `${localeLibrary.getDateFormat()} ${localeLibrary.getTimeFormat()}`,
                        ).isAfter(values.value, 'minute')),
            },
            is_between: {
                i18nLabel: optionsEnum.isBetween.i18nLabelKey,
                key: optionsEnum.isBetween.key,
                isRange: true,
                getDisplayValue: values => `${localeLibrary.getFormattedDateAndTime(values.value)}
                    ${i18n.t('generic.and')} ${localeLibrary.getFormattedDateAndTime(values.endValue)}`,
                filterFn: (entity, values, field) =>
                    entity[field] &&
                    (moment(entity[field]).isBetween(values.value, values.endValue, 'minute') ||
                        moment(
                            entity[field],
                            `${localeLibrary.getDateFormat()} ${localeLibrary.getTimeFormat()}`,
                        ).isBetween(values.value, values.endValue, 'minute')),
            },
        },
    },
    TEXT_LIMITED_OPTIONS_CONTAINS: {
        id: 7,
        key: 'TEXT_LIMITED_OPTIONS_CONTAINS',
        options: {
            contains: {
                i18nLabel: optionsEnum.contains.i18nLabelKey,
                key: optionsEnum.contains.key,
                filterFn: (entity, values, field) => {
                    if (values.value === i18n.t('productCatalog.offers.noCategories') && entity[field]?.length === 0) {
                        return entity[field].length === 0;
                    }
                    return entity[field]?.find((x: any) => x === values.value);
                },
            },
            does_not_contain: {
                i18nLabel: optionsEnum.doesNotContain.i18nLabelKey,
                key: optionsEnum.doesNotContain.key,
                filterFn: (entity, values, field) => {
                    if (values.value === i18n.t('productCatalog.offers.noCategories') && entity[field]?.length === 0) {
                        return entity[field].length !== 0;
                    }
                    return !entity[field]?.find((x: any) => x === values.value);
                },
            },
        },
    },
};

function getFilteredEntities(entities: Entity[], filter: Filter) {
    const columnType = Object.values(tableColumnType).find(type => type.key === filter.column.filterType.key);
    if (!columnType) {
        throw new Error(`Invalid column type: ${filter.column.filterType.key}`);
    }

    const { options } = columnType;
    const { filterFn } = options[filter.condition.key];

    return entities.filter((entity: Entity) => filterFn(entity, filter.values, filter.column.field));
}

export function filterEntities(entities: Entity[], filters: Filter[]) {
    let listOfEntities = entities;

    filters.forEach(filter => {
        listOfEntities = getFilteredEntities(listOfEntities, filter);
    });
    return listOfEntities;
}

// Extend logic for other uses of 'dataAndMonetary' unit type
function isOmittedUnitType(entity: Entity, field: string, unitId: string) {
    return (
        field === 'price' &&
        entity.hasOwnProperty('currencyType') &&
        ((entity.currencyType === currencyTypes.DATA && unitId === 'currency') ||
            (entity.currencyType === currencyTypes.MONETARY && unitId !== 'currency'))
    );
}

export default tableColumnType;
