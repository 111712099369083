























import Vue from 'vue';

import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import BucketTypeCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/BucketTypeCondition';
import { MAP_BUCKET_TYPE_TO_OPTION_VALUES } from '@/__new__/services/dno/charging/common/bucketTypeConditionHelper';

export default Vue.extend({
    name: 'BucketType',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            bucketTypeOptions: MAP_BUCKET_TYPE_TO_OPTION_VALUES,
            bucketTypeData: new BucketTypeCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.bucketTypeData = new BucketTypeCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput(): void {
            this.bucketTypeData.validate();
            this.$emit('input', this.bucketTypeData);
        },
    },
});
