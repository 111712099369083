<template>
    <div class="page-wrap">
        <SinkConfigEditor
            v-if="shouldShowContent"
            :entityType="ENTITY_TYPES.ORD_CONFIG"
            :uiSettings="ORDConfigsSettings"
            :createFunction="upsertReportConfig"
            :updateFunction="upsertReportConfig"
            :saveDraftFunction="upsertReportConfig"
            :typeCasters="typeCasters"
            :dashboardPageName="RouteNames.ORD_CONFIG_DASHBOARD"
            :triggerDefinitions="triggerDefinitions"
            :events="events"
            :formatters="getSinkFormatters"
            :editableConfig="editableConfig"
        />
    </div>
</template>

<script>
import { upsertReportConfig } from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'ORDConfigsEditor',
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            upsertReportConfig,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            ORDConfigsSettings: Getters.GET_ORD_CONFIGS_SETTINGS,
            allORDConfigs: Getters.GET_ORD_CONFIGS,
            getConfigById: Getters.GET_ORD_CONFIG_BY_ID,
            getSinkFormatters: Getters.GET_SINK_FORMATTERS,
            typeCasters: Getters.GET_SINK_TYPE_CASTERS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        shouldShowContent() {
            if (this.$route.params.id) {
                return !!this.editableConfig && this.events.length;
            }
            return !isEmpty(this.ORDConfigsSettings) && this.triggerDefinitions && this.events;
        },
        editableConfig() {
            return this.getConfigById(this.$route.params.id);
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this[Actions.LOAD_ORD_CONFIGS_SETTINGS](),
                    this.fetchTriggers(),
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchSegments(),
                    this.fetchSinkTypeCasters(),
                ];

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                if (this.$route.params.id && this.allORDConfigs.length === 0) {
                    await this[Actions.LOAD_ORD_CONFIGS]();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.failedToLoadReportsConfig'),
                        type: ALERT_TYPES.alert,
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_ORD_CONFIGS_SETTINGS, Actions.LOAD_ORD_CONFIGS]),
    },
};
</script>
