



























































































































































































































































import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import ChargingActions from '@/__new__/features/charging/ChargingActions.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// HTTP
import { getWalletCounterEntityDraft } from '@/__new__/services/dno/charging/http/walletCounters';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Validations
import { validationMixin } from 'vuelidate';
import { required, minValue, between, requiredIf } from 'vuelidate/lib/validators';

// helpers
import { languageMap } from '@/common/locale/language';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    getMultiLangFieldValueByLocale,
    getProperlyFormattedMultilangFieldValue,
    EDITOR_MODE,
} from '@/common/entities/entityHelper';
import {
    ACTION_TYPES_OPTIONS,
    actionTypesOptionsMapper,
    triggerOptions,
    TRIGGER_TYPES,
    ActionTypesOptionsObject,
    TriggerOptionsObject,
} from '@/__new__/services/dno/charging/common/chargingActionsHelper';
import cloneDeep from 'lodash/cloneDeep';
import {
    WalletCounter,
    WalletCounterAction,
    ChargingEntityVersion,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { WalletCounterDraft } from '@/__new__/services/dno/charging/models/WalletCounter';
import { LABEL_COLOR } from '@/common/labelsHelper';
import Button from '@/common/button/Button';
import { STATUS_CODES } from '@/common/commonHelper';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    getLowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default Vue.extend({
    name: 'WalletCountersEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppButton,
        AppMultiselectV3,
        ChargingActions,
        AppLabel,
        EditorButtons,
        AppIcon,
        ChargingTagLabel,
        EntityStatusIndicator,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            PLURALIZATION,
            name: {} as string | Record<string, string>,
            description: {} as string | Record<string, string>,
            remark: '' as string,
            approveOnCreate: false as boolean,
            usageCounter: null as any,
            limit: null as boolean | null,
            isWalletLimit: false as boolean,
            disabled: false as boolean,
            data: {} as WalletCounter,
            entityType: ENTITY_TYPES.WALLET_COUNTERS as string,
            actionsModel: [] as WalletCounterAction[],
            selectedCSType: {} as number,
            version: null as number | null,
            selectedLanguage: '' as string,

            entityId: null as string | null,
            readonly: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            isOnlyDraft: false as boolean,
            updateTime: null as number | null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            LABEL_COLOR,
            EDITOR_MODE,
            STATUS_CODES,
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as string | null,
            state: EntityStateMapping.UNAPPROVED,
            lowerEntitiesVersions: null,
            CHARGING_ENTITY_TYPES,
            getLowerEntitiesVersions,
            RouteNames,
        };
    },
    validations: {
        name: {
            en: {
                required,
            },
        },
        limit: {
            required,
            minValue: minValue(1),
        },
        usageCounter: {
            id: {
                required,
            },
        },
        actionsModel: {
            $each: {
                actionTrigger: {
                    type: {
                        required,
                    },
                    usageCounter: {
                        required,
                    },
                    notificationPercentage: {
                        required,
                        between: between(1, 100),
                    },
                },
                actionFlag: {
                    type: {
                        required,
                    },
                    name: {
                        // eslint-disable-next-line func-names, prefer-arrow-callback
                        required: requiredIf(function (obj) {
                            return obj?.type?.id === ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG;
                        }),
                    },
                },
            },
        },
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_APPROVED_USAGE_COUNTERS,
            Getters.GET_USAGE_COUNTER_BY_ID,
            Getters.GET_WALLET_COUNTER_BY_ID,
            Getters.GET_WALLET_COUNTERS,
            Getters.GET_WALLET_COUNTER_BY_VERSION,
        ]),
        isNewElement(): boolean {
            return !this.entityId || this.$route.params.clone;
        },
        usageCountersOptions(): any[] {
            return this[Getters.GET_APPROVED_USAGE_COUNTERS].map(({ id, name }) => ({ id, name }));
        },
        walletCountersData(): WalletCounter {
            return this[Getters.GET_WALLET_COUNTER_BY_ID](this.entityId);
        },
        walletCounters(): WalletCounter[] {
            return this[Getters.GET_WALLET_COUNTERS] || [];
        },
        walletCounterByVersion(): WalletCounter | null {
            return this[Getters.GET_WALLET_COUNTER_BY_VERSION];
        },
        alreadyUsedName(): boolean {
            if (this.isEditingEntityExisting) {
                if (this.name.en !== this.walletCountersData?.name) {
                    return Object.values(this.walletCounters).some((uc: any) => uc.name === this.name.en);
                }
                return false;
            }
            return Object.values(this.walletCounters).some((uc: any) => uc.name === this.name.en);
        },
        isEditingEntityExisting(): boolean {
            return Boolean(this.entityId && !this.$route.params.clone);
        },
        mapSelectedUsageCounter(): any[] {
            return this.usageCounter
                ? [
                      {
                          label: this.usageCounter.name,
                          ...this.usageCounter,
                      },
                  ]
                : [];
        },
        chargingActionsOptions(): ActionTypesOptionsObject[] {
            return [
                actionTypesOptionsMapper[ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG],
                actionTypesOptionsMapper[ACTION_TYPES_OPTIONS.THRESHOLD_CROSSED_NOTIFICATION],
                actionTypesOptionsMapper[ACTION_TYPES_OPTIONS.LIMIT_REACHED_NOTIFICATION],
            ];
        },
        chargingActionsTriggersOptions(): TriggerOptionsObject[] {
            return [triggerOptions[TRIGGER_TYPES.THRESHOLD_PERCENTAGE]];
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('charging.walletCounters.name');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.walletCounters.editWc');
            }
            return this.$i18n.t('charging.walletCounters.addnewWc');
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone, chargingVersion } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                if (chargingVersion) {
                    this.currentVersion = chargingVersion;
                }

                const promises = [this[Actions.REQUEST_WALLET_COUNTERS](), this[Actions.REQUEST_USAGE_COUNTERS]()];

                if (!clone) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (this.entityId) {
                    this.initData(false, !this.currentVersion);
                    if (this.readonly) {
                        this.loadVersionHistory(CHARGING_ENTITY_TYPES.WALLET_COUNTER, this.entityId, chargingVersion);
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_WALLET_COUNTERS,
            Actions.REQUEST_WALLET_COUNTER_BY_VERSION,
            Actions.REQUEST_USAGE_COUNTERS,
        ]),
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            if (this.entityId) {
                const wc = this.walletCountersData;
                let wcData: any;

                if (initVersion) {
                    this.currentVersion = wc?.chargingVersion || null;
                }

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new WalletCounterDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = wc.version;
                    wcData = draftData;
                    this.isOnlyDraft = wc?.state === STATUS_CODES.NA;

                    if (wc?.update_time <= wcData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityId, this.currentVersion);
                    wcData = cloneDeep(this.walletCounterByVersion);
                } else {
                    wcData = cloneDeep(wc);
                }

                const {
                    name,
                    description,
                    remark,
                    disabled,
                    isWalletLimit,
                    limit,
                    usageCounterId,
                    version,
                    update_time: updateTime,
                    updatePortalId,
                    state,
                    lowerEntitiesVersions,
                } = wcData;

                const properlyFormattedName = getProperlyFormattedMultilangFieldValue(name);
                if (this.$route.params.clone) {
                    Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                        properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                    });
                }

                this.name = properlyFormattedName;
                this.description = getProperlyFormattedMultilangFieldValue(description);
                this.remark = remark;
                this.disabled = disabled;
                this.isWalletLimit = isWalletLimit;
                this.limit = limit;
                this.version = version;
                this.updateTime = updateTime;
                this.state = state;
                this.lowerEntitiesVersions = lowerEntitiesVersions;
                const selectedUsageCounter = this[Getters.GET_USAGE_COUNTER_BY_ID](usageCounterId);
                if (selectedUsageCounter) {
                    this.usageCounter = {
                        id: selectedUsageCounter.id,
                        name: selectedUsageCounter.name,
                    };
                }
                this.loadActionsData(wcData);
                this.getUpdateUserName(updatePortalId);
            }
        },
        loadActionsData(wcData: WalletCounter): void {
            const actions = cloneDeep(wcData.actions) || [];

            this.actionsModel = actions.map((action: WalletCounterAction) => {
                const uc = this[Getters.GET_USAGE_COUNTER_BY_ID](action.actionTrigger.ucId) ?? {};
                action.actionTrigger.usageCounter = {
                    ...uc,
                    label: getMultiLangFieldValueByLocale(uc.name),
                };

                action.actionTrigger.type = triggerOptions[action.actionTrigger.triggerType];

                return action;
            });
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.walletCounters.nameOfWcMustBeUnique'),
                });
                return false;
            }

            return true;
        },
        parseActionsData() {
            return this.actionsModel.map(action => {
                const { type: actionTriggerType, usageCounter, notificationPercentage } = action.actionTrigger;

                const { type, name, flagValue } = action.actionFlag;

                const actionData = {
                    action_trigger: {
                        trigger_type: actionTriggerType?.id,
                        usage_counter_id: usageCounter?.id,
                        usage_counter_threshold_percentage: notificationPercentage,
                    },
                    action: {
                        action_type: type?.id,
                        flag_value: undefined,
                        flag_name: undefined,
                    },
                };

                if (type?.id === ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG) {
                    actionData.action.flag_value = flagValue;
                    actionData.action.flag_name = name;
                }

                return actionData;
            });
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.data = {
                name: this.name[this.selectedLanguage],
                description: this.description,
                remark: this.remark,
                limit: this.limit,
                is_wallet_limit: this.isWalletLimit,
                disabled: this.disabled,
                applicable_wallet_types: ['primary'],
                usage_counter_id: this.usageCounter?.id || '',
                deactivated: false,
                actions: this.parseActionsData(),
            };

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getWalletCounterEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_WALLET_COUNTERS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_WALLET_COUNTER_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        async loadVersionHistory(
            entityType: CHARGING_ENTITY_TYPES,
            id: string,
            initChargingVersion?: string,
        ): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_WALLET_COUNTER_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        openLowerEntitiData(entry: any, routeName: string): void {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
    },
});
