<template>
    <div
        data-test-id="account-order-details"
        :class="{ 'shrink-width': isSidebarOpen }"
    >
        <TableFiltersRenderless :entities="ordersList">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div
                            v-show="title"
                            class="d-flex component-title-um"
                        >
                            {{ title }}
                        </div>
                        <div class="d-flex">
                            <SearchBox
                                v-model="searchQuery"
                                class="table-search-box"
                                :smallSearchIcon="true"
                            />
                            <ResponseModalButton
                                :response="apiResponse"
                                :title="title"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="ordersColumnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>

                            <div class="d-flex align-items-center">
                                <div class="section-toggle-label mr-3">
                                    {{ $i18n.t('customerCare.userInformation.activeRecords') }}
                                </div>
                                <AppToggle
                                    v-model="showOnlyActiveOrders"
                                    :small="true"
                                    customId="order-active-toggle"
                                />
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :selectedEntityId="selectedEntityId"
                        :innerSearchQuery="searchQuery"
                        :isSearchEnabled="true"
                        :isDefaultHoverEnabled="isEditAllowed"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :defaultSort="defaultSort"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :enableRowStateControls="hasEnabledRowControls"
                        :columnsData="ordersColumnsData"
                        :entityType="ENTITY_TYPES.ORDER"
                        tableKey="user/account-order"
                        keyName="orderId"
                        @editOrder="editOrder"
                        @cancelEditOrder="cancelEditOrder"
                        @selectedEntityModel="
                            o => [selectOrder(o), toggleModal(isEditAllowed, sectionKeys.ORDER_DETAILS)]
                        "
                    >
                        <template #status="{ entity }">
                            <EntityStatusIndicator
                                v-if="!isUndefined(entity.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="getOrderStatus(entity)"
                                :isInTable="true"
                            />

                            <i v-else>{{ $i18n.t('generic.empty') }}</i>
                        </template>
                        <template #buyerAccountId="{ entity }">
                            <router-link
                                v-if="entity.buyerAccountId"
                                :to="{
                                    name: RouteNames.USER_MANAGEMENT_ACCOUNT_V2,
                                    params: {
                                        id: entity.buyerAccountId,
                                    },
                                }"
                            >
                                {{ entity.buyerAccountId }}
                            </router-link>
                            <span
                                v-else
                                :title="$i18n.t('generic.empty')"
                            >
                                <i>{{ $i18n.t('generic.empty') }}</i>
                            </span>
                        </template>
                        <template #createTimestamp="{ entity }">
                            {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.createTimestamp) }}
                        </template>

                        <template #customRowButtons="{ entity }">
                            <IconButton
                                v-if="isEditOrderStateAllowed"
                                :label="$i18n.t('customerCare.orderHistory.editStatus')"
                                :icon="ICON_TYPES.DETAILS"
                                class="ml-n2 mr-2"
                                @iconClick="openEditOrderStateModal(entity)"
                            />
                        </template>
                    </AppTable>

                    <AppDialog
                        :visible="isDialogOpen"
                        maxWidth="760px"
                    >
                        <div
                            v-if="showDialog"
                            class="plan-change"
                        >
                            <h2 class="lf-title plan-change-title">
                                {{ $i18n.t('planChange.planChange') }}
                            </h2>
                            <AppMultiselectV3
                                v-model="selectedPlan"
                                :additionalLabel="$i18n.t('planChange.chooseNewPlan')"
                                :options="isViasatDemo ? viasatOffersOptions : offersOptions"
                                :showLabels="false"
                                :isSmall="true"
                                :error="planChangeError"
                                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                                :allowEmpty="false"
                                trackBy="id"
                                label="label"
                                class="plan-change-multiselect"
                                @select="checkPromoForOffer"
                            />
                            <AppMultiselectV3
                                v-if="isViasatDemo"
                                v-model="selectedAddOns"
                                :additionalLabel="$i18n.t('planChange.chooseNewAddOn')"
                                :options="viasatAddOnOptions"
                                :multiple="true"
                                :showLabels="false"
                                :isSmall="true"
                                :error="addOnChangeError"
                                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                                :allowEmpty="false"
                                trackBy="id"
                                label="label"
                                class="plan-change-multiselect"
                            />
                            <div class="plan-change-buttons">
                                <AppButton
                                    :label="$t('generic.cancel')"
                                    class="mr-5"
                                    @click="onCancelPlanChange"
                                />
                                <AppButton
                                    :label="$t('generic.confirm')"
                                    :buttonType="BUTTON_TYPES.PRIMARY"
                                    @click="onSavePlanChange"
                                />
                            </div>
                        </div>

                        <div
                            v-if="showCancelDialog"
                            class="plan-change-cancel"
                        >
                            <h2 class="lf-title plan-change-title">
                                {{ $i18n.t('planChange.cancelPlanChange') }}
                            </h2>
                            <div class="plan-change-text">
                                {{ $i18n.t('planChange.cancelPlanChangeText') }}
                            </div>
                            <div class="plan-change-buttons">
                                <AppButton
                                    :label="$t('generic.cancel')"
                                    class="mr-5"
                                    @click="onCancelCancelPlanChange"
                                />
                                <AppButton
                                    :label="$t('generic.confirm')"
                                    :buttonType="BUTTON_TYPES.PRIMARY"
                                    @click="onSaveCancelPlanChange"
                                />
                            </div>
                        </div>

                        <ChangeOrderStatusForm
                            v-if="showEditStatusDialog"
                            :targetType="targetType"
                            :targetTypeId="targetTypeId"
                            :order="selectedParentOrder"
                            @close="showEditStatusDialog = false"
                            @refreshData="$emit('refreshData')"
                        />
                    </AppDialog>
                </div>
            </template>
        </TableFiltersRenderless>

        <AdditionalSidebarForOrders
            v-if="isEditAllowed && !isSuborderDisabled"
            :selectedParentOrder="selectedParentOrder"
            :showAdditionalSidebar="showAdditionalSidebar"
            @closeSidebar="toggleModal(false, sectionKeys.ORDER_DETAILS)"
        />

        <AdditionalSidebarOrdersEntities
            v-if="isEditAllowed && isSuborderDisabled"
            :selectedParentOrder="selectedParentOrder"
            :showAdditionalSidebar="showAdditionalSidebar"
            @closeSidebar="toggleModal(false, sectionKeys.ORDER_DETAILS)"
        />
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AdditionalSidebarForOrders from '@/__new__/features/customerCare/AdditionalSidebarForOrders.vue';
import AdditionalSidebarOrdersEntities from '@/__new__/features/customerCare/AdditionalSidebarOrdersEntities.vue';
import ChangeOrderStatusForm from '@/__new__/features/customerCare/ChangeOrderStatusForm.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import RouteNames from '@/router/routeNames';

// HELPERS
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
    STATUS_CODES,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { isUndefined } from 'lodash';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import tableColumnType from '@/common/filterTable';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import permissionsService, {
    getOperatorConfigValue,
    isUserAllowed,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import AccountSidebarMixin, { sectionKeys } from '@/__new__/features/customerCare/mixins/AccountSidebarMixin.vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export const CHANGE_TYPE = {
    ACTIVE: 1,
    PENDING: 2,
};

const MAX_DATE = new Date(8640000000000000);

/**
 * Function to set offer Status based on time and isDeleted param. This function
 * expects milliseconds as JS works with them and not seconds.
 * @param startTime Unix timestamp in milliseconds
 * @param endTime Unix timestamp in milliseconds
 * @param isDeleted Boolean flag
 * @returns {string}
 */
export function getOfferStatusCode(state, startTime, endTime) {
    const now = Date.now();
    // multiplying by 1000 since Date.now return epoch in milliseconds
    const start = startTime ? startTime * 1000 : 0;
    const end = endTime ? endTime * 1000 : MAX_DATE;

    // special handling for the APPROVED Offer state (clarifying it for users)
    if (state === STATUS_CODES.APPROVED) {
        if (now > start && now < end) {
            return STATUS_CODES.RUNNING;
        }
        if (now < start) {
            return STATUS_CODES.UPCOMING;
        }
        if (now > end) {
            return STATUS_CODES.FINISHED;
        }
    }

    // other statuses are just retunrned since they're handled properly with EntityStatusIndicator
    return state;
}

export default {
    name: 'OrderDetails',
    components: {
        AppButton,
        AppTable,
        EntityStatusIndicator,
        AppToggle,
        SearchBox,
        IconButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        AppMultiselectV3,
        AppDialog,
        AdditionalSidebarForOrders,
        AdditionalSidebarOrdersEntities,
        ResponseModalButton,
        ChangeOrderStatusForm,
    },
    mixins: [supportButtonMixin, AccountSidebarMixin],
    props: {
        targetTypeId: {
            type: String,
            default: '',
        },
        targetType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        orders: {
            type: Object,
            required: true,
        },
        apiResponse: {
            type: Object,
            default: null,
        },
        offers: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        allowGetAppointments: {
            type: Boolean,
            default: false,
        },
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            ORDER_ENTITY_STATES,
            showOnlyActiveOrders: false,
            selectedParentOrder: null,
            searchQuery: '',
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            showDialog: false,
            showCancelDialog: false,
            showEditStatusDialog: false,
            selectedPlan: null,
            selectedAddOns: null,
            planChangeError: false,
            addOnChangeError: false,
            isFriendlyOrderIdEnabled:
                permissionsService.accountOrdersFriendlyOrderIdEnabled() &&
                isViewEnabled('UMAccountOrderDetailsFriendlyOrderId'),
            isOrderTypeEnabled:
                permissionsService.accountOrdersOrderTypeEnabled() && isViewEnabled('UMAccountOrderDetailsOrderType'),
            isSuborderDisabled:
                permissionsService.accountOrdersSubordersDisabled() &&
                isViewEnabled('UMAccountOrderDetailsSubordersDisabled'),
            isSubscriberIdEnabled: permissionsService.subscriberLevelEnabled() && isViewEnabled('UMSubscriber'),
            // do not enable for non LFAdmins without change of logic(atm all states are allowed)
            isEditOrderStateAllowed:
                permissionsService.isUserLotusFlareAdmin() && isUserAllowed('UMAccountOrderDetailsChangeOrderStatus'),
            defaultSort: {
                key: 'createTimestamp',
                sortBy: entity => entity.createTimestamp,
                type: 'desc',
            },
            RouteNames,
        };
    },
    computed: {
        showAdditionalSidebar() {
            return this.getAdditionalSidebarStateBySectionKey(sectionKeys.ORDER_DETAILS);
        },
        offersOptions() {
            return this.offers
                .map(offer => ({
                    ...offer,
                    label: offer.name,
                    status: getOfferStatusCode(offer.state, offer.startTime, offer.endTime),
                }))
                .filter(offer => offer.status === STATUS_CODES.RUNNING && offer.id !== this.currentPlan);
        },
        viasatOffersOptions() {
            return this.offersOptions.filter(
                offer =>
                    offer?.categories.includes('8a685759369945c381e4dac197c04d1a') ||
                    offer?.categories.includes('5b208b8baf3e4df48dbc270bfca55410'),
            );
        },
        viasatAddOnOptions() {
            return this.offersOptions.filter(
                offer =>
                    offer?.categories.includes('f8cc359b8e28432d8dd1e224fc594703') ||
                    offer?.categories.includes('ed96795a761844fda47a1a762916c2e5') ||
                    offer?.categories.includes('133bb3ffc42b4c9796f3a9c69123b9e3'),
            );
        },
        ordersColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.chargedAmount'),
                    key: 'amountFormatted',
                    field: 'amountFormatted',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.currency'),
                    key: 'currency',
                    field: 'currency',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.totalAmount'),
                    key: 'totalAmountFormatted',
                    field: 'totalAmountFormatted',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    classes: ['overflow-visible-all'],
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.ordersList.map(entity => entity.status))],
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'buyerFirstName',
                    field: 'buyerFirstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'buyerLastName',
                    field: 'buyerLastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.accountId'),
                    key: 'buyerAccountId',
                    field: 'buyerAccountId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'buyerMSISDN',
                    field: 'buyerMSISDN',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.isFriendlyOrderIdEnabled) {
                // Must be placed as 1st column if enabled.
                columns.unshift({
                    name: this.$i18n.t('customerCare.orderHistory.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if (this.isOrderTypeEnabled) {
                // Placed after 'orderId'
                columns.splice(columns.findIndex(c => c.key === 'orderId') + 1, 0, {
                    name: this.$i18n.t('customerCare.orderHistory.orderType'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.ordersList.map(entity => entity.type))],
                });
            }

            if (this.isSubscriberIdEnabled) {
                columns.push({
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return columns;
        },
        ordersList() {
            if (Object.values(this.orders).length) {
                return Object.values(this.orders).filter(({ state }) =>
                    this.showOnlyActiveOrders ? state === ORDER_ENTITY_STATES.ACTIVE : true,
                );
            }
            return [];
        },
        orderDetails() {
            if (Object.values(this.orders).length) {
                const orderId = Object.keys(this.orders).find(
                    key =>
                        this.orders[key].state === ORDER_ENTITY_STATES.PENDING ||
                        this.orders[key].state === ORDER_ENTITY_STATES.PENDING_ACTIVATION ||
                        this.orders[key].state === ORDER_ENTITY_STATES.ACTIVE,
                );
                return this.orders[orderId];
            }
            return null;
        },
        selectedEntityId() {
            return this.isEditAllowed && this.selectedParentOrder ? this.selectedParentOrder.orderId : '';
        },
        currentPlan() {
            const order = this.orders[this.selectedEntityId] || this.orderDetails;
            if (order) {
                const changePending = !!(order.replaceInfo && order.replaceInfo.entity_id);

                if (this.isSuborderDisabled) {
                    return Object.keys(order.entities.offer).find(Boolean);
                }

                for (const [, subOrder] of Object.entries(order.subOrders)) {
                    return changePending ? order.replaceInfo.entity_id : subOrder.entity_id;
                }
            }
            return '';
        },
        allRecurrences() {
            return this.ordersList.reduce((acc, order) => acc.concat(Object.values(order.subOrders)), []);
        },
        hasEnabledRowControls() {
            return this.isEditAllowed && (this.allowGetAppointments || this.isEditOrderStateAllowed);
        },
        isDialogOpen() {
            return this.showDialog || this.showCancelDialog || this.showEditStatusDialog;
        },
        isViasatDemo() {
            return getOperatorConfigValue('isViasatDemo');
        },
    },
    methods: {
        getMultiLangFieldValueByLocale,
        isUndefined,
        editOrder(order) {
            this.selectedPlan = null;
            this.showDialog = true;
            this.selectedParentOrder = order;
        },
        cancelEditOrder() {
            this.showCancelDialog = true;
        },
        onCancelPlanChange() {
            this.showDialog = false;
        },
        onCancelCancelPlanChange() {
            this.showCancelDialog = false;
        },
        checkPromoForOffer() {
            this.planChangeError = false;
        },
        onSaveCancelPlanChange() {
            this.$emit('cancelPlanChange', this.targetTypeId);
            this.showCancelDialog = false;
        },
        onSavePlanChange() {
            if (!this.selectedPlan) {
                this.planChangeError = true;
                return;
            }

            if (this.isViasatDemo) {
                const newAddOnIds = this.selectedAddOns?.map(item => item.id) ?? [];
                this.$emit('viasatPlanChange', this.targetTypeId, this.selectedPlan.id, newAddOnIds);
                this.showDialog = false;
                return;
            }

            let changeType = 0;
            const { state } = this.orderDetails;
            if (state === ORDER_ENTITY_STATES.PENDING || state === ORDER_ENTITY_STATES.PENDING_ACTIVATION) {
                changeType = CHANGE_TYPE.PENDING;
            } else if (state === ORDER_ENTITY_STATES.ACTIVE) {
                changeType = CHANGE_TYPE.ACTIVE;
            }

            this.$emit('planChange', this.targetTypeId, this.selectedPlan.id, changeType);
            this.showDialog = false;
        },
        selectOrder(order) {
            this.selectedParentOrder = this.ordersList.find(entity => entity.orderId === order.orderId);
        },
        getOrderStatus(order) {
            return order.replaceInfo && order.state === ORDER_ENTITY_STATES.ACTIVE
                ? ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE
                : order.state;
        },
        openEditOrderStateModal(order) {
            this.showEditStatusDialog = true;
            this.selectOrder(order);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/palette';

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.auto-height {
    height: auto !important;
}

.table-search-box {
    width: 15rem;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.plan-change {
    height: 22.25rem;
}

.plan-change-cancel {
    height: 13rem;
}

.plan-change-blackout {
    height: 12.813rem;
}
.plan-change-title {
    margin-top: 1.813rem;
    margin-left: 2.5rem;
}

.plan-change-multiselect {
    margin-top: 1.75rem;
    margin-left: 2.5rem;
    margin-right: 9rem;
}

.plan-change-buttons {
    margin-top: 2.813rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
}

.plan-change-text {
    font-size: 0.875rem;
    margin-top: 1.688rem;
    margin-left: 2.5rem;
    margin-right: 2.375rem;
}
</style>
