import moment from 'moment';
import { flatMap } from 'lodash';
import MessageNames, { MessageCodes, Messages, messageByName } from '@/__new__/features/campaigns/common/Messages';
import Campaign, {
    CampaignType,
    CampaignTargetType,
    DeliveryTypeNames,
    campaignCreationStepNameToNumbersMap,
    CohortType,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import { RepeatTypes } from '@/__new__/services/dno/campaigns/models/Repeat';
import Message, {
    MessageTypesWithRequiredTitle,
    MessageTypesWithRequiredText,
} from '@/__new__/services/dno/campaigns/models/Message';
import { MessageTypeLabels } from '@/common/CampaignMessage';
import i18n from '@/i18n';
import { MessageTypes } from '@/common/CampaignMessage';
import { TranslateResult } from 'vue-i18n';

export const messagePropertyTypes = {
    text: 'text',
    title: 'title',
    unregisteredEmail: 'Unregistered email',
    subscriber: 'Subscriber',
};

const ALLOW_UNREGISTERED = 'true';
const ALLOW_ONLY_REGISTERED = 'false';

export const CAMPAIGN_COHORT_EXPRESSION_COMBINATIONS = {
    [ALLOW_UNREGISTERED]: {
        [MessageTypes.EMAIL]: [[CohortType.EMAIL]],
        [MessageTypes.SMS]: [[CohortType.MSISDN]],
    },
    [ALLOW_ONLY_REGISTERED]: {
        [MessageTypes.PUSH]: [
            [CohortType.SUBSCRIBER, CohortType.SUBSCRIBER_ID, CohortType.USER, CohortType.MSISDN],
            [CohortType.ACCOUNT, CohortType.ACCOUNT_ID, CohortType.USER, CohortType.MSISDN],
            [CohortType.USER, CohortType.MSISDN],
            [CohortType.EXTERNAL_IDP_ID, CohortType.MSISDN],
        ],
        [MessageTypes.OTT]: [
            [CohortType.SUBSCRIBER, CohortType.SUBSCRIBER_ID, CohortType.USER],
            [CohortType.ACCOUNT, CohortType.ACCOUNT_ID, CohortType.USER],
            [CohortType.USER],
            [CohortType.EXTERNAL_IDP_ID],
        ],
        [MessageTypes.SMS]: [
            [CohortType.SUBSCRIBER, CohortType.SUBSCRIBER_ID, CohortType.USER, CohortType.MSISDN],
            [CohortType.ACCOUNT, CohortType.ACCOUNT_ID, CohortType.USER, CohortType.MSISDN],
            [CohortType.USER, CohortType.MSISDN],
            [CohortType.EXTERNAL_IDP_ID, CohortType.MSISDN],
        ],
        [MessageTypes.EMAIL]: [
            [CohortType.SUBSCRIBER, CohortType.SUBSCRIBER_ID, CohortType.USER, CohortType.EMAIL],
            [CohortType.ACCOUNT, CohortType.ACCOUNT_ID, CohortType.USER, CohortType.EMAIL],
            [CohortType.USER, CohortType.EMAIL],
            [CohortType.EXTERNAL_IDP_ID, CohortType.EMAIL],
        ],
    },
};

type ValidationResult = {
    alertMessage: string | TranslateResult;
    messageWithErrorId: string;
    propertyType?: string;
};

/**
 * @param campaignMessages [[msg1, msg2]]
 * @param campaignType type of campaign this message belongs to
 * @returns {?Object} null is returned if validation passed successfully
 */
export function validateCampaignMessages(campaignMessages: Message[][], campaignType: number): ValidationResult | null {
    const validationResult: ValidationResult = {
        // message that will be displayed in the alert
        alertMessage: '',
        // Id of the message that contains error - necessary to highlight it
        messageWithErrorId: '',
        // text or title which is required based on the message type
        propertyType: undefined,
    };
    // check messages titles
    let msgWithoutTitle: Message | undefined;
    campaignMessages.forEach(messagesArray => {
        if (!msgWithoutTitle) {
            msgWithoutTitle = messagesArray
                .filter(msg => MessageTypesWithRequiredTitle.includes(msg.type))
                .find(msg => !msg.title);
        }
    });
    if (msgWithoutTitle && msgWithoutTitle.uniqueId) {
        const isEmailType = msgWithoutTitle.type === MessageTypes.EMAIL;
        validationResult.messageWithErrorId = msgWithoutTitle.uniqueId;
        const messageTypeName = MessageTypeLabels[msgWithoutTitle.type];
        validationResult.alertMessage = i18n.t('campaigns.alerts.messageTitleIsMissing', {
            msgType: messageTypeName,
            titleType: isEmailType ? i18n.t('campaigns.subject') : i18n.t('generic.title'),
        });
        validationResult.propertyType = messagePropertyTypes.title;
        return validationResult;
    }

    // check text fields
    let msgWithoutText: Message | undefined;
    campaignMessages.forEach(messagesArray => {
        if (!msgWithoutText) {
            msgWithoutText = messagesArray
                .filter(msg => MessageTypesWithRequiredText.includes(msg.type))
                .find(msg => !msg.text);
        }
    });

    if (msgWithoutText && msgWithoutText.uniqueId) {
        validationResult.messageWithErrorId = msgWithoutText.uniqueId;
        validationResult.alertMessage = messageByName(MessageNames.MESSAGE_TEXT_IS_MISSING)('');
        validationResult.propertyType = messagePropertyTypes.text;
        return validationResult;
    }

    // calling new style of validation, does not include validations above yet not to duplicate
    const invalidMessage = flatMap(campaignMessages, messagesArray =>
        messagesArray.map(message => ({
            uniqueId: message.uniqueId,
            ...Message.validateMessage(message, campaignType),
        })),
    ).find(messageValidation => Object.entries(messageValidation).length > 1); // 1 is for uniqueId

    if (invalidMessage) {
        return {
            messageWithErrorId: invalidMessage.uniqueId,
            alertMessage: Object.values(invalidMessage)[1], // show first message, 0 is uniqueId
            propertyType: undefined, // explicitly showing that it does not matter here
        };
    }

    return null;
}

/**
 * @param campaign {Campaign}
 * @param campaignCreationStepName {Number} based on this function decides which fields should be validated
 * @param isSaving {Boolean} true if saving of the campaign is going to be executed after this validation
 * @returns {?String} null is returned if validation passed successfully otherwise error message returned
 */
export function validateCampaign(campaign: Campaign, campaignCreationStepName: string, isSaving = false) {
    // converting campaignCreationStepName into a number for easier treating
    let campaignCreationStepNumber;
    if (campaignCreationStepName) {
        campaignCreationStepNumber = campaignCreationStepNameToNumbersMap.get(campaignCreationStepName);
    }

    const shouldValidateAllData = isSaving && campaignCreationStepNumber === 5;
    if (isSaving) {
        if (!campaign.name || (typeof campaign.name === 'string' && !campaign.name.trim())) {
            const msg = Messages.get(MessageCodes[MessageNames.CAMPAIGN_NAME_EMPTY]);
            if (!msg) {
                throw new Error('Could not find message for CAMPAIGN_NAME_EMPTY.');
            }

            return msg('');
        }
    }

    // validations for each step of campaign
    if (shouldValidateAllData || (!isSaving && campaignCreationStepNumber && campaignCreationStepNumber >= 1)) {
        // from validate method
        if (campaign.endTime && campaign.endTime <= moment().unix()) {
            const msg = Messages.get(MessageCodes[MessageNames.END_DATE_IN_PAST]);
            if (!msg) {
                throw new Error('Could not find message for END_DATE_IN_PAST.');
            }

            return msg('');
        }

        if (campaign.startTime && campaign.endTime && campaign.endTime <= campaign.startTime) {
            const msg = Messages.get(MessageCodes[MessageNames.END_DATE_BEFORE_START]);
            if (!msg) {
                throw new Error('Could not find message for END_DATE_BEFORE_START.');
            }

            return msg('');
        }

        if (
            campaign.deliveryType?.type === DeliveryTypeNames.ActionBased &&
            (!campaign.triggerCondition ||
                !campaign.triggerCondition.filters ||
                !campaign.triggerCondition.filters.length)
        ) {
            return i18n.t('campaigns.noTriggerSelected');
        }

        if (
            campaign.deliveryType?.type === DeliveryTypeNames.Scheduled &&
            campaign.deliveryType?.recurring &&
            campaign.deliveryType.repeat &&
            campaign.deliveryType.repeat.type === RepeatTypes.monthly &&
            !campaign.deliveryType.repeat.lastDayOfMonth &&
            campaign.deliveryType.repeat.dayOfMonth !== null &&
            (campaign.deliveryType.repeat.dayOfMonth < 1 || campaign.deliveryType.repeat.dayOfMonth > 31)
        ) {
            return i18n.t('campaigns.dayOfMonthShouldBe');
        }
    }

    if (shouldValidateAllData || (!isSaving && campaignCreationStepNumber && campaignCreationStepNumber >= 2)) {
        const messagesValidationResult = validateCampaignMessages(campaign.messages, campaign.campaignType);

        if (messagesValidationResult) {
            return messagesValidationResult.alertMessage;
        }

        const containDynamicParams = campaign.messages[0].some(
            ({ text }) => text.includes('{{') || text.includes('}}'),
        );

        if (campaign.deliveryType?.type === DeliveryTypeNames.Scheduled && containDynamicParams) {
            return i18n.t('campaigns.notSupportedScheduledMessageParams');
        }
    }

    if (shouldValidateAllData || (!isSaving && campaignCreationStepNumber && campaignCreationStepNumber >= 3)) {
        const isPushMsgSelected = campaign.messages[0].some(msg => msg.type === MessageTypes.PUSH);

        if (campaign.campaignType === CampaignType.MarketingCampaign && isPushMsgSelected) {
            // push msgs can not exist without carr apps
            if (!campaign.deliveryType?.carrierAppIds.length) {
                return i18n.t('campaigns.carrierAppsValidationError');
            }
        }
        if (
            campaign.campaignType === CampaignType.MarketingCampaign &&
            campaign.targetType === CampaignTargetType.CohortTarget
        ) {
            const cohorts = campaign.target[campaign.targetType];
            const isWhitelistCohortSelected = Object.keys(cohorts.whitelist).length > 1;
            if (!isWhitelistCohortSelected) {
                return i18n.t('generic.cohorts.applicableFieldCouldNotBeEmpty');
            }
            const allSelectedCohorts = Object.values(cohorts.whitelist)
                .concat(Object.values(cohorts.blacklist))
                .filter(val => Array.isArray(val))
                .flat();
            const cohortsAreDuplicated = new Set(allSelectedCohorts).size !== allSelectedCohorts.length;
            if (cohortsAreDuplicated) {
                return i18n.t('generic.cohorts.cohortsDuplicationError');
            }
        }
    }

    return null;
}
