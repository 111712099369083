





















import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import type { HierarchyItem } from '@/__new__/features/customerCareSuite/common/umHierarhyMapperHelper';

// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

export type ASSOCIATED_ENTITY_DROPDOWN_ITEM = {
    id: string;
    label: string;
    type: USER_MANAGER_HIERARCHY;
};

export default Vue.extend({
    name: 'AssociatedUMEntityPicker',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        requesterTargetType: {
            type: Number,
            required: true,
            default: USER_MANAGER_HIERARCHY.USER,
        },
        requesterTargetId: {
            type: String,
            required: true,
        },
        includeGrandChildren: {
            type: Boolean,
            default: false,
        },
        includeTarget: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [
            Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID,
            Getters.GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID,
        ]),
        assosicatedUMEntities(): Array<ASSOCIATED_ENTITY_DROPDOWN_ITEM> {
            let result: Array<HierarchyItem> = [];

            if (this.includeTarget) {
                const target = this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                    targetId: this.requesterTargetId,
                    targetType: this.requesterTargetType,
                });
                // handling case when target data is not yet loaded
                if (target) {
                    result.push(target);
                }
            }

            // getting direct requester children
            const directChildren = this[Getters.GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID]({
                targetType: this.requesterTargetType,
                targetId: this.requesterTargetId,
            });
            // direct children included by default
            result = [...result, ...directChildren];

            // conditionally getting grandchildren (aka Subs for a User belonged Accs)
            if (this.includeGrandChildren) {
                let grandChildren: Array<HierarchyItem> = [];
                directChildren.forEach((child: HierarchyItem) => {
                    const grandchildrenBatch = this[Getters.GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID]({
                        targetType: child.type,
                        targetId: child.id,
                    });
                    if (grandchildrenBatch.length) {
                        grandChildren = [...grandChildren, ...grandchildrenBatch];
                    }
                });
                // adding grand children to the list
                if (grandChildren.length) {
                    grandChildren.forEach(gc => result.push(gc));
                }
            }

            return result.map(anyChild => this.mapUMEntityForDropdown(anyChild));
        },
    },
    watch: {
        assosicatedUMEntities: {
            handler(newVal) {
                if (newVal.length) {
                    // eslint-disable-next-line prefer-destructuring
                    this.selectedAssociatedUMEntity = newVal[0];
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        mapUMEntityForDropdown(entity: HierarchyItem): ASSOCIATED_ENTITY_DROPDOWN_ITEM {
            return {
                id: entity.id,
                label: entity.id,
                type: entity.type,
            };
        },
        onMultiselectInput(val: ASSOCIATED_ENTITY_DROPDOWN_ITEM) {
            this.$emit('input', val);
        },
    },
});
