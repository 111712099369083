








































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Vue components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';
import ScheduleEntryActionModal from '@/__new__/features/billing/dunningProfiles/ScheduleEntryActionModal.vue';

// Mixins
import { validationMixin } from 'vuelidate';

// Misc
import RouteNames from '@/router/routeNames';
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Permissions
import * as permissionsService from '@/services/permissions/permissions.service';
import { DunningProfileEntity } from '@/__new__/services/dno/billing/models/dunningProfilesPortal';
import {
    createDunningProfile,
    getDunningProfileById,
    updateDunningProfile,
} from '@/__new__/services/dno/billing/dunningProfilesService';
import { ACTION_TYPE, TIME_REFERENCE, TIME_UNIT } from '@/__new__/services/dno/billing/models/dunningProfilesDno';

export default Vue.extend({
    name: 'DunningProfilesEditor',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppTextareaV3,
        EditorButtons,
        IconButton,
        ScheduleEntryActionModal,
    },
    mixins: [validationMixin],
    data() {
        return {
            ICON_TYPES,
            isExistingEntity: false,
            editorMode: EDITOR_MODE.VIEW,
            ALERT_TYPES,
            entity: {
                schedule: [],
            } as DunningProfileEntity,
            actionModal: {
                visible: false,
                title: '',
                data: {},
                scheduleIndex: -1,
                actionIndex: undefined as undefined | number,
            },
            showValidation: false,
            readonly: false,
            timeReferenceOptions: [
                {
                    id: TIME_REFERENCE.BILL_AVAIABLE_IN,
                    name: this.$i18n.t('billing.availableIn'),
                },
                {
                    id: TIME_REFERENCE.BILL_DUE_IN,
                    name: this.$i18n.t('billing.dueIn'),
                },
                {
                    id: TIME_REFERENCE.BILL_PAST_DUE,
                    name: this.$i18n.t('billing.pastDue'),
                },
            ],
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('billing.viewDunningProfile');
            }
            return this.isExistingEntity
                ? this.$i18n.t('billing.editDunningProfile')
                : this.$i18n.t('billing.newDunningProfile');
        },
    },
    async mounted() {
        // Route params:
        const { id, clone, mode } = this.$route.params;

        // Determine if we're looking at an existing pricing rule
        this.isExistingEntity = Boolean(id) && !clone;

        // Set editor mode
        this.editorMode = mode;

        // Determine if page is readonly
        this.readonly = this.editorMode === EDITOR_MODE.VIEW || !this.userHasWriteAccess();

        // Initialize form data
        if (id) {
            await this.loadEditor(id, clone);
        }
    },
    methods: {
        /**
         * Loads up the editors fields.
         * Contents determined by entity of given `id`.
         * Append ('cloned') to name if we're cloning from given `id`.
         */
        async loadEditor(id: string, clone: boolean) {
            await this.$withLoadingSpinner(
                async () => {
                    this.entity = await getDunningProfileById(id, this.selectedLanguage);
                    if (clone) {
                        this.entity.name += ' (cloned)';
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedToLoadDunningProfile'));
                        this.routeToListPage();
                    },
                },
            );
        },
        /**
         * Creates an alert which confirms entity save
         */
        confirmSave() {
            if (!this.isExistingEntity) {
                this.onSave();
            } else {
                // Create delete button
                const button = new Button({
                    label: this.$i18n.t('generic.save'),
                    alertType: ALERT_TYPES.warning,
                    handler: () => {
                        this.onSave();
                    },
                });

                // Deletion Confirmation Alert
                this.$alert(
                    this.$i18n.t('billingAccount.dunningProfileEditWarning', {
                        entityName: this.entity.name,
                    }),
                    {
                        type: ALERT_TYPES.warning,
                        buttons: [button],
                    },
                );
            }
        },
        async onSave() {
            if (this.isExistingEntity) {
                await this.saveExistingEntity();
            } else {
                await this.saveNewEntity();
            }
        },
        async saveNewEntity() {
            await this.$withLoadingSpinner(
                async () => {
                    await createDunningProfile(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('billing.successfullyCreatedDunningProfile'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedToCreateDunningProfile'));
                    },
                },
            );
        },
        async saveExistingEntity() {
            await this.$withLoadingSpinner(
                async () => {
                    await updateDunningProfile(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('billing.successfullyUpdatedDunningProfile'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedToUpdateDunningProfile'));
                    },
                },
            );
        },
        routeToListPage() {
            this.$router.push({
                name: RouteNames.DUNNING_PROFILES,
                params: { companyId: this.$route.params.companyId },
            });
        },
        userHasWriteAccess(): boolean {
            return permissionsService.isFeatureFlagEnabled('postpaid-demo');
        },
        addEntry() {
            const index = this.entity.schedule.length;
            const entry = {
                timeReference: null,
                timeAmount: '',
                timeUnit: TIME_UNIT.DAY,
                actions: [],
            };
            this.$set(this.entity.schedule, index, entry);
        },
        deleteEntry(index: number) {
            this.entity.schedule.splice(index, 1);
        },
        addAction(scheduleIndex: number) {
            this.actionModal = {
                visible: true,
                title: this.$i18n.t('billing.addScheduleAction'),
                data: {},
                scheduleIndex,
                actionIndex: undefined,
            };
        },
        editAction(scheduleIndex: number, actionIndex: number) {
            this.actionModal = {
                visible: true,
                title: this.$i18n.t('billing.editScheduleAction'),
                data: this.entity.schedule[scheduleIndex].actions[actionIndex],
                scheduleIndex,
                actionIndex,
            };
        },
        deleteAction(scheduleEntryIndex: number, actionIndex: number) {
            this.entity.schedule[scheduleEntryIndex].actions.splice(actionIndex, 1);
        },
        onActionSave() {
            this.actionModal.visible = false;
            const { scheduleIndex } = this.actionModal;
            const { actionIndex } = this.actionModal;

            if (actionIndex === undefined) {
                // Add action
                this.entity.schedule[scheduleIndex].actions.push({
                    ...this.actionModal.data,
                });
            } else {
                // Edit action
                this.entity.schedule[scheduleIndex].actions[actionIndex] = {
                    ...this.actionModal.data,
                };
            }
        },
        actionDataToSummary(data: any): string {
            const { actionType } = data;
            switch (actionType) {
                case ACTION_TYPE.SEND_EMAIL:
                    return this.$i18n.t('billing.sendEmail');
                case ACTION_TYPE.RETRY_PAYMENT:
                    return this.$i18n.t('billing.retryPayment');
                case ACTION_TYPE.SOFT_SUSPENSION:
                    return this.$i18n.t('billing.softSuspension');
                case ACTION_TYPE.HARD_SUSPENSION:
                    return this.$i18n.t('billing.hardSuspension');
                default:
                    return String(actionType);
            }
        },
    },
});
