








































































































import Vue, { type PropType } from 'vue';
// vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Tag from '@/components/partials/inputs/Tag.vue';
// helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import {
    groupEventPropertiesByType,
    type SinkConfigJsonPath,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import EventProp, {
    CDP_PROPERTY_COMPLEX_TYPES,
    CDP_PROPERTY_JSON_PATH_TYPES,
    CDP_PROPERTY_TYPE,
    eventPropTypeIsMultiple,
} from '@/__new__/services/dno/events/models/EventProp';
import {
    createJsonPathOptions,
    type SinkConfigRowValue,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
// model
import { Event } from '@/__new__/services/dno/events/models/Event';

export default Vue.extend({
    name: 'SinkConfigColumnRow',
    components: {
        AppMultiselectV3,
        IconButton,
        Tag,
    },
    model: {
        prop: 'rowData',
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        rowData: {
            type: Object as PropType<SinkConfigRowValue>,
            default: () => ({} as Record<string, never>),
        },
        eventDropdownOptions: {
            type: Array,
            required: true,
        },
        propertyDropdownOptions: {
            type: Array as PropType<Event['properties'] | never[]>,
            required: true,
        },
        shouldAddLabels: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            CDP_PROPERTY_JSON_PATH_TYPES,
            CDP_PROPERTY_COMPLEX_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            allJsonPathOptions: Getters.GET_JSON_PATH_OPTIONS,
        }),
        shouldShowJsonPathBtn() {
            return (
                CDP_PROPERTY_COMPLEX_TYPES.includes(this.rowData.property.sourceType) ||
                eventPropTypeIsMultiple(this.rowData.property.sourceType)
            );
        },
        sourceType(): CDP_PROPERTY_TYPE {
            if (this.isFormattingApplied) {
                return this.propertySourceData?.type;
            }

            return this.rowData.isJsonPathEnabled ? this.rowData.jsonPathType : this.rowData?.property?.sourceType;
        },
        jsonPathOptions(): ReturnType<typeof createJsonPathOptions> {
            return createJsonPathOptions(
                this.allJsonPathOptions[this.rowData?.eventType]?.[this.rowData?.property?.name],
            );
        },
        groupedEventPropertiesByType(): ReturnType<typeof groupEventPropertiesByType> {
            return groupEventPropertiesByType(this.propertyDropdownOptions);
        },
        propertySourceData(): EventProp {
            return this.propertyDropdownOptions.find(p => p.name === this.rowData?.property?.name);
        },
        relatedJsonPathType(): CDP_PROPERTY_TYPE | undefined {
            return this.allJsonPathOptions[this.rowData?.eventType]?.[this.rowData?.property.name].find(
                v => v.jsonPath === this.rowData?.jsonPath?.jsonPath,
            )?.type;
        },
        shouldDisableJsonPathTypeInput(): boolean {
            return this.disabled || this.relatedJsonPathType !== undefined;
        },
        isFormattingApplied(): boolean {
            return this.propertySourceData?.type !== this.rowData?.property.type;
        },
    },
    methods: {
        jsonPathToggle() {
            this.$emit('jsonPathToggle');
            if (this.rowData?.isJsonPathEnabled) {
                this.jsonPathSelect(this.jsonPathOptions[0].groupValues[0]);
            }
        },
        jsonPathTypeSelect(type: CDP_PROPERTY_TYPE) {
            this.$emit('jsonPathTypeSelect', type);
        },
        jsonPathSelect(val: SinkConfigJsonPath) {
            this.$emit('jsonPathSelect', val);
            if (this.relatedJsonPathType) {
                this.jsonPathTypeSelect(this.relatedJsonPathType);
            }
        },
        customJsonPathInput(val: string) {
            this.$emit('jsonPathSelect', {
                type: this.rowData?.jsonPathType,
                jsonPath: val,
            });
        },
        emitDelete() {
            this.$emit('delete');
        },
        generateStringBasedOnLabelPresence(truthyVal: string, falsyVal = '') {
            return this.shouldAddLabels ? truthyVal : falsyVal;
        },
    },
});
