







// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// helpers
import moment from 'moment';
import * as Sentry from '@sentry/vue';
import { mapMutations } from 'vuex';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { CHALLENGE_TYPES, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

const IDENTIFIER_TYPE_TO_TEXT = {
    3: 'USER ID',
};

const SERVICE_TYPE_TO_TEXT = {
    0: 'Sign in',
    2: 'Verify email',
    3: 'Challenge',
    4: 'Reset secret',
    5: 'Update email confirm ownership',
    7: 'Generic',
    9: 'Update email',
    13: 'MFA login',
    16: 'Email_otp_login',
    17: 'Msisdn_otp_login',
    18: 'Verify_external_identifier',
};

class AuthenticationHistoryRecord {
    dateAndTime: string;

    channel: string;

    status: string;

    constructor(dateAndTime: string, channel: string, status: string) {
        this.dateAndTime = dateAndTime;
        this.channel = channel;
        this.status = status;
    }
}

export default {
    name: 'AuthenticationHistoryTile',
    components: {
        AbstractTableTile,
    },
    data() {
        return {
            isGenerateModalVisible: false,
            isVerifyModalVisible: false,
            entities: [],
            isLoading: false,
            ALERT_TYPES,
            CHALLENGE_TYPES,
            codeGenerated: false,
            challengeId: '',
            otp: '',
            challengeType: null,
            numberOfDigits: getOperatorConfigValue('service_config.lf-user.otp.otp_digits', 4),
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userAuthentication.dateAndTime'),
                    field: 'dateAndTime',
                    key: 'dateAndTime',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.channel'),
                    key: 'channel',
                    field: 'channel',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.status'),
                    key: 'status',
                    field: 'status',
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        ...mapMutations('userManagementUser', ['setUserVerificationStatus']),
        async fetchTileData() {
            try {
                this.$Progress.start();
                const { data } = await HTTPCustomerCare.fetchServiceBlockerHistory(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                );
                if (data?.otp_attempts) {
                    for (const elem of data.otp_attempts) {
                        const entity = new AuthenticationHistoryRecord(
                            moment(elem.event_time).format('YYYY-MM-DD HH:mm'),
                            IDENTIFIER_TYPE_TO_TEXT?.[elem.identifier_type] || '',
                            SERVICE_TYPE_TO_TEXT?.[elem.service_type] || '',
                        );
                        this.entities.push(entity);
                    }
                }
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
    },
};
