<template>
    <div>
        <div class="filters-box">
            <div
                v-for="(uiComponentsGroup, groupIndex) in uiComponentsGroups"
                :key="groupIndex"
                class="d-flex flex-column filter-section"
            >
                <div class="filter-name-row">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div class="d-flex align-items-center">
                            <span class="ml-3 mr-2">{{ conditionName }}</span>
                            <AppTooltip
                                v-if="shouldShowDescriptionTooltip"
                                :offset="10"
                                :tooltipPosition="TOOLTIP_POSITION.top"
                            >
                                <template slot="label">
                                    <AppIcon
                                        class="mx-2"
                                        :color="ICON_COLORS.GRAY"
                                        :type="ICON_TYPES.INFO"
                                    />
                                </template>

                                <template slot="content">
                                    <div class="tooltip-info body-xs px-3 py-2">
                                        {{ conditionDefinition.description }}
                                    </div>
                                </template>
                            </AppTooltip>
                            <IconButton
                                :label="$t('generic.copyId')"
                                :icon="ICON_TYPES.CLONE"
                                @iconClick="copyToClipboard(conditionDefinition.id)"
                            />
                            <IconButton
                                v-if="shouldShowNavigateBtn"
                                :label="$t('generic.navigateToEntity')"
                                :icon="ICON_TYPES.LOGOUT"
                                @iconClick="navigateToEntity(conditionDefinition.id)"
                            />
                        </div>
                        <div class="d-flex align-items-center">
                            <IconButton
                                v-if="groupIndex === uiComponentsGroups.length - 1"
                                :buttonType="BUTTON_TYPES.TERTIARY"
                                :icon="ICON_TYPES.PLUS"
                                class="mr-2"
                                :label="$t('conditionsTree.operation.or')"
                                @iconClick="addPropertyGroup(groupIndex + 1)"
                            />

                            <IconButton
                                :buttonType="BUTTON_TYPES.SECONDARY"
                                :icon="ICON_TYPES.CLOSE"
                                :label="$t('productCatalog.templates.deleteGroup')"
                                class="mr-2"
                                @iconClick="deleteGroup(groupIndex)"
                            />
                        </div>
                    </div>
                </div>

                <div
                    v-for="(property, propertyIndex) in uiComponentsGroup"
                    :key="propertyIndex"
                    class="tree-node"
                    :style="computedStyles(0)"
                >
                    <div class="filter-row d-flex align-items-center justify-content-between ml-3 py-1">
                        <div class="d-flex align-items-center flex-grow-1">
                            <div class="d-flex justify-content-end">
                                <span
                                    v-if="property.parentPropertyDefinition.type"
                                    class="blue-caps-label"
                                    :class="{ 'shifted-up': false }"
                                >
                                    {{ property.parentPropertyDefinition.type }}
                                </span>
                            </div>
                            <Dropdown
                                :key="groupIndex + '' + propertyIndex"
                                :selected="[property.parentPropertyDefinition]"
                                :items="propertyDefinitions"
                                :searchable="true"
                                :disabled="property.rowData.isJsonPathEnabled"
                                displayProperty="label"
                                class="mr-3"
                                @selected="propertyDef => addProperty(groupIndex, propertyIndex, propertyDef)"
                            />
                            <AppTooltip
                                v-if="shouldShowPropertyDescriptionTooltip(property)"
                                :offset="10"
                                :tooltipPosition="TOOLTIP_POSITION.top"
                            >
                                <template slot="label">
                                    <AppIcon
                                        :color="ICON_COLORS.GRAY"
                                        :type="ICON_TYPES.INFO"
                                    />
                                </template>

                                <template slot="content">
                                    <div class="tooltip-info body-xs px-3 py-2">
                                        {{ property.parentPropertyDefinition.description }}
                                    </div>
                                </template>
                            </AppTooltip>
                            <component
                                :is="property.parentUIComponentClassInstance.uiComponentInstance"
                                v-if="!property.rowData.isJsonPathEnabled"
                                :key="propertyIndex"
                                :disabled="false"
                                :changeCallback="changeCallback"
                                :shouldAddLabels="propertyIndex === 0"
                            />
                        </div>

                        <IconButton
                            :icon="ICON_TYPES.CLOSE"
                            class="mr-2"
                            :label="$t('productCatalog.templates.deleteProperty')"
                            @iconClick="deleteProperty(groupIndex, propertyIndex)"
                        />
                    </div>
                    <div
                        v-if="CDP_PROPERTY_COMPLEX_TYPES.includes(property.rowData.property.type)"
                        class="mb-1 ml-3 py-1"
                    >
                        <AppToggle
                            data-test-id="select-jsonpath-toggler"
                            :value="property.rowData.isJsonPathEnabled"
                            :label="$i18n.t('segments.filters.enableNestedLvlCondition')"
                            :explanationText="$i18n.t('segments.filters.nestedConditionsExplanationText')"
                            :small="true"
                            :disabled="false"
                            class="mt-4"
                            @input="jsonPathToggleOnFilters(groupIndex, propertyIndex)"
                        />
                    </div>
                    <div
                        v-if="property.rowData.isJsonPathEnabled"
                        class="d-flex align-items-center tree-node"
                        :style="computedStyles(1)"
                    >
                        <div class="filter-row d-flex align-items-center justify-content-between ml-3 py-1">
                            <AppMultiselectV3
                                :value="property.rowData.jsonPathType"
                                small
                                :disabled="isJsonPathTypeDisabled(property.rowData)"
                                :additionalLabel="''"
                                class="col-md-4"
                                :allowEmpty="false"
                                :options="CDP_PROPERTY_JSON_PATH_TYPES"
                                @input="type => selectJsonPathType(groupIndex, propertyIndex, type)"
                            />

                            <AppMultiselectV3
                                data-test-id="jsonPath-multiselect"
                                :value="property.rowData.jsonPath"
                                small
                                :disabled="false"
                                :additionalLabel="''"
                                class="col-md-6"
                                :options="jsonPathOptions(groupIndex, propertyIndex)"
                                :allowEmpty="false"
                                groupLabel="groupName"
                                label="jsonPath"
                                groupValues="groupValues"
                                :taggable="true"
                                :multiple="false"
                                @tag="val => inputCustomJsonPath(groupIndex, propertyIndex, val)"
                                @input="val => selectJsonPathFromDropdown(groupIndex, propertyIndex, val)"
                            >
                                <template #option="props">
                                    <div class="json-path-dropdown">
                                        <span>{{ props.option.jsonPath || props.option.$groupLabel }}</span>
                                        <span
                                            v-if="props.option.type"
                                            class="json-path-type ml-3"
                                            >{{ props.option.type }}</span
                                        >
                                    </div>
                                </template>
                            </AppMultiselectV3>
                            <component
                                :is="property.uiComponentClassInstance.uiComponentInstance"
                                :key="propertyIndex"
                                :disabled="false"
                                :changeCallback="changeCallback"
                                :shouldAddLabels="propertyIndex === 0"
                            />
                        </div>
                    </div>
                </div>
                <AppButton
                    class="mb-1 ml-3 add-button align-self-baseline"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('events.addProperty')"
                    @click="addProperty(groupIndex, uiComponentsGroup.length, propertyDefinitions[0])"
                />

                <span
                    v-show="groupIndex < uiComponentsGroups.length - 1"
                    class="or-label my-3 ml-3"
                >
                    or
                </span>
            </div>
            <div
                v-if="showStats"
                class="ml-1 pb-2 d-flex align-items-center"
            >
                <AppIcon
                    :color="ICON_COLORS.BLUE"
                    :type="filtersIcon"
                    :size="'20px'"
                    class="mx-2"
                />
                <strong>{{ statisticsComponents }}</strong>
            </div>
        </div>

        <div class="and-label my-3">
            {{ topLevelCombinator }}
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import Dropdown from '@/components/partials/Dropdown.vue';
import SegmentStatistics from '@/__new__/services/dno/segments/models/SegmentStatistics';
import IconButton from '@/components/partials/IconButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { copyToClipboard } from '@/common/utils';
import { EntityType } from '@/components/partials/filters/Filters.vue';
import RouteNames from '@/router/routeNames';
import SinkConfigColumnRow from '@/__new__/features/sinkConfigs/SinkConfigColumnRow.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import {
    CDP_PROPERTY_COMPLEX_TYPES,
    CDP_PROPERTY_JSON_PATH_TYPES,
    CDP_PROPERTY_TYPE,
} from '@/__new__/services/dno/events/models/EventProp';
import { createJsonPathOptions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { COLOR_SCHEME } from '@/common/conditions-tree';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { JsonPathTypeToPropertyValue } from '@/models/filters/uiComponents/UIComponentInstance';
import { isUserAllowed } from '@/services/permissions/permissions.service';

/**
 * Represents a single filter as Vue component.
 * Displays all rows.
 */
export default {
    name: 'FilterComponentV2',
    components: {
        AppToggle,
        AppMultiselectV3,
        SinkConfigColumnRow,
        AppButton,
        AppIcon,
        Dropdown,
        IconButton,
        AppTooltip,
    },
    props: {
        filtersIcon: { type: String, default: ICON_TYPES.USER },
        entityType: { type: String, default: '' },
        stats: { type: Object, default: () => SegmentStatistics.empty() },
        changeCallback: { type: Function, default: null },
        showStats: { type: Boolean, default: true },
        topLevelCombinator: { type: String, default: 'and' }, // 'or' is possible, this is just a label
        conditionInstanceTopLevelById: { type: Object, default: () => ({}) },
        conditionDefinition: { type: Object, default: () => ({}) },
        propertyDefinitions: { type: Array, default: () => [] },
        allJsonPathOptions: { type: Object, default: () => ({}) },
        conditionName: { type: String, default: '' },
        depth: { type: Number, default: 3 },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            BUTTON_TYPES,
            TOOLTIP_POSITION,
            CDP_PROPERTY_COMPLEX_TYPES,
            CDP_PROPERTY_JSON_PATH_TYPES,
        };
    },
    computed: {
        shouldShowNavigateBtn() {
            return this.entityType === EntityType.Filter
                ? isUserAllowed('TableConfigurationsRead')
                : isUserAllowed('EventsRead');
        },
        shouldShowDescriptionTooltip() {
            return !!this.conditionDefinition?.description;
        },
        conditionDefinitionId() {
            return Object.keys(this.conditionInstanceTopLevelById)[0];
        },
        uiComponentsGroups() {
            return this.conditionInstanceTopLevelById[this.conditionDefinitionId];
        },
        statisticsComponents() {
            const allStats = this.stats.buildStaticsComponents();
            const filtered = 3;
            return allStats[filtered].value;
        },
    },
    methods: {
        jsonPathOptions(groupIndex, propertyIndex) {
            const maybeRowData = this.uiComponentsGroups[groupIndex][propertyIndex]?.rowData;
            const eventType = maybeRowData?.eventType;
            const propertyName = maybeRowData?.property.name;

            const jsonPathOptions = this.allJsonPathOptions[eventType]?.[propertyName] || [maybeRowData.jsonPath];

            return createJsonPathOptions(jsonPathOptions);
        },
        propertyDefinitionFromJsonPath(jsonPath) {
            return {
                name: jsonPath.jsonPath,
                type: jsonPath.type,
                description: '',
                value: JsonPathTypeToPropertyValue[jsonPath.type],
                label: jsonPath.jsonPath,
            };
        },
        inputCustomJsonPath(groupIndex, propertyIndex, jsonPathValue) {
            const uiComponentsGroupElement = this.uiComponentsGroups[groupIndex][propertyIndex];
            const rowData = uiComponentsGroupElement?.rowData;
            const jsonPath = {
                type: uiComponentsGroupElement?.rowData.jsonPathType,
                jsonPath: jsonPathValue,
            };
            this.$set(rowData, 'jsonPath', jsonPath);

            const propertyDefinition = this.propertyDefinitionFromJsonPath(jsonPath);
            this.updateProperty(groupIndex, propertyIndex, propertyDefinition, rowData);
        },
        selectJsonPathFromDropdown(groupIndex, propertyIndex, jsonPath) {
            const uiComponentsGroupElement = this.uiComponentsGroups[groupIndex][propertyIndex];
            const newRowData = uiComponentsGroupElement.rowData;
            this.$set(newRowData, 'jsonPath', jsonPath);

            const propertyDefinition = this.propertyDefinitionFromJsonPath(jsonPath);
            this.updateProperty(groupIndex, propertyIndex, propertyDefinition, newRowData);

            const relatedJsonPathType = this.getRelatedJsonPathType(newRowData);
            if (relatedJsonPathType) {
                this.selectJsonPathType(groupIndex, propertyIndex, relatedJsonPathType);
            }
        },
        selectJsonPathType(groupIndex, propertyIndex, type) {
            const uiComponentsGroupElement = this.uiComponentsGroups[groupIndex][propertyIndex];

            const newRowData = uiComponentsGroupElement.rowData;
            const newJsonPath = uiComponentsGroupElement.rowData.jsonPath;
            this.$set(newJsonPath, 'type', type);
            this.$set(newRowData, 'jsonPathType', type);
            this.$set(newRowData, 'jsonPath', newJsonPath);

            const propertyDefinition = this.propertyDefinitionFromJsonPath(newJsonPath);
            this.updateProperty(groupIndex, propertyIndex, propertyDefinition, newRowData);
        },
        jsonPathToggleOnFilters(groupIndex, propertyIndex) {
            const uiComponentsGroupElement = this.uiComponentsGroups[groupIndex][propertyIndex];
            const newRowData = uiComponentsGroupElement.rowData;
            this.$set(newRowData, 'isJsonPathEnabled', !newRowData.isJsonPathEnabled);

            const propertyDefinition = newRowData.isJsonPathEnabled
                ? uiComponentsGroupElement?.propertyDefinition
                : uiComponentsGroupElement?.parentPropertyDefinition;
            this.updateProperty(groupIndex, propertyIndex, propertyDefinition, newRowData);

            if (newRowData.isJsonPathEnabled) {
                const jsonPath = this.jsonPathOptions(groupIndex, propertyIndex)[0].groupValues[0];
                this.selectJsonPathFromDropdown(groupIndex, propertyIndex, jsonPath);
            }
        },
        getRelatedJsonPathType(rowData) {
            return this.allJsonPathOptions[rowData.eventType]?.[rowData.property.name].find(
                v => v.jsonPath === rowData.jsonPath?.jsonPath,
            )?.type;
        },
        computedStyles(depth) {
            return {
                backgroundColor: COLOR_SCHEME[depth] || COLOR_SCHEME[COLOR_SCHEME.length - 1],
                padding: '1.25rem',
            };
        },
        copyToClipboard,
        navigateToEntity(id) {
            this.$router.push({
                name:
                    this.entityType === EntityType.Filter
                        ? RouteNames.REDSHIFT_CONFIG_DASHBOARD
                        : RouteNames.EVENTS_DASHBOARD,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        shouldShowPropertyDescriptionTooltip(property) {
            return !!property.parentPropertyDefinition?.description;
        },
        deleteGroup(groupIndex) {
            this.$emit('deleteGroup', groupIndex);
        },
        deleteProperty(groupIndex, propertyIndex) {
            this.$emit('deleteProperty', groupIndex, propertyIndex);
        },
        addPropertyGroup(groupIndex) {
            this.$emit('addPropertyGroup', groupIndex);
        },
        addProperty(groupIndex, propertyIndex, propertyDefinition) {
            this.$emit('addProperty', groupIndex, propertyIndex, propertyDefinition);
        },
        updateProperty(groupIndex, propertyIndex, propertyDefinition, rowData) {
            this.$emit('updateProperty', groupIndex, propertyIndex, propertyDefinition, rowData);
        },
        isJsonPathTypeDisabled(rowData) {
            return (
                !!this.getRelatedJsonPathType(rowData) &&
                this.getRelatedJsonPathType(rowData) !== CDP_PROPERTY_TYPE.MIXED
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_icons.scss';
@import '~@/assets/scss/_mixins.scss';
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/palette';

.tree-node {
    margin: 0.625rem 0 0 0;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;
}

$icon-path: '~@/assets/icons/';

.statistics {
    display: flex;
    margin-top: 1rem;
    padding-left: 6.25rem;

    @include statsIcons($icon-path);

    .stats {
        flex-grow: 1;
    }
}

.and-label,
.or-label {
    display: inline-block;
    text-transform: uppercase;
    color: $gray-blue;
    font-weight: bold;
    font-size: 0.75rem;
}
.filters-box {
    background: $dirty-white;
    border-radius: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.filter-section .or-btn {
    width: 4rem;
    height: 2rem;
    min-height: 2rem;
    line-height: 2rem;
    border-radius: 1.25rem;
    box-shadow: 0 0.125rem 0.375rem 0 $blue30;
}

.filter-name-row {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    min-height: 2.5rem;

    .add-or {
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    .component {
        padding-bottom: 4px;
    }
}

.add-button::v-deep {
    &.with-icon-padding {
        padding-left: 0.875rem;
    }
}
.tooltip {
    &-info {
        background: white;
        border-radius: 2rem;
    }
    &-icon {
        top: 3px;
    }
}
</style>
