import { LABEL_COLOR } from '@/common/labelsHelper';

// ORD
export enum ORD_CONFIG_STATUS {
    DELETED = 'deleted',
    DISABLED = 'disabled',
    DRAFT = 'draft',
    ENABLED = 'enabled',
}

export const ORD_JOB_CONFIG_STATUS_TO_I18N_MAP = new Map([
    [ORD_CONFIG_STATUS.DELETED, 'generic.stateMap.deleted'],
    [ORD_CONFIG_STATUS.DISABLED, 'generic.stateMap.disabled'],
    [ORD_CONFIG_STATUS.DRAFT, 'generic.stateMap.draft'],
    [ORD_CONFIG_STATUS.ENABLED, 'generic.enabled'],
]);

export const ORD_JOB_CONFIG_STATUS_TO_COLOR_MAP = new Map([
    [ORD_CONFIG_STATUS.DELETED, LABEL_COLOR.red],
    [ORD_CONFIG_STATUS.DISABLED, LABEL_COLOR.gray],
    [ORD_CONFIG_STATUS.DRAFT, LABEL_COLOR.gray],
    [ORD_CONFIG_STATUS.ENABLED, LABEL_COLOR.green],
]);
