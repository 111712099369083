






























import Vue from 'vue';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import LabelWithVerificationStatus from '@/__new__/features/customerCare/user/LabelWithVerificationStatus.vue';

// Helpers
import User, { USER_INFO_CRED_TYPES, IS_OTP_VALIDATED, IS_PIN_SETUP } from '@/__new__/services/dno/user/models/User';
import permissionsService, {
    getUserConsentsDefault,
    getUserDetailFields,
} from '@/services/permissions/permissions.service';
import Flag, { FLAG_TYPE, FLAG_TC_GLOBAL_ACCEPTED_TEXT } from '@/__new__/services/dno/user/models/Flag';
import { getConsentsContextTypeI18nKey } from '@/__new__/features/customerCare/common/consentsHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { USER_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import {
    EKYC_STATUS_TO_I18N_LABEL,
    USER_VERIFICATION_ID_TYPES,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

// HTTP
import consentHTTP from '@/__new__/services/dno/consent/http/consent';

export default Vue.extend({
    name: 'UserPageAnchor',

    components: {
        AppCustomerHeader,
        LabelWithVerificationStatus,
    },
    data() {
        return {
            user: {} as User,
            userDetailFields: getUserDetailFields(),
            consentsDefault: getUserConsentsDefault(),
            consents: [] as Array<any>,
            // permissions related stuff
            isEkycEnabled: permissionsService.userManagementEKYCEnabled(),
            consentsEnabled: permissionsService.userManagementConsentsEnabled(),
            USER_VERIFICATION_ID_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        viewedEntityId() {
            return this.$route.params.id || '';
        },
        userInfo() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.viewedEntityId,
            });
        },
        basicInfo() {
            const data = [];

            if (this.userInfo) {
                data.push({
                    name: this.$i18n.t('account.fullName'),
                    value: this.userInfo.fullName,
                });

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.USER_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.userId'),
                        value: this.userInfo.id,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FIRST_NAME)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.firstName'),
                        value: this.userInfo.name,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.LAST_NAME)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.lastName'),
                        value: this.userInfo.surname,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.MIDDLE_NAME)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.middleName'),
                        value: this.userInfo.middleName,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.MOTHERS_MAIDEN_NAME)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.mothersMaidenName'),
                        value: this.userInfo.mothersMaidenName,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.VERIFICATION_STATUS)) {
                    const verificationStatus = this.user?.notificationIdentifiers?.find(item => item.isDefault);

                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.verificationStatus'),
                        value: verificationStatus?.verified
                            ? this.$i18n.t('generic.verified')
                            : this.$i18n.t('generic.notVerified'),
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CREDENTIALS)) {
                    this.userInfo.creds?.forEach(({ id, id_type: idType }) => {
                        if (!['msisdn', 'facebook'].includes(idType.toLowerCase())) {
                            data.push({
                                name: onlyFirstLetterUppercase(idType.replace(/_/g, ' ')),
                                value: id,
                            });
                        }
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FACEBOOK)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.facebook'),
                        value: this.userInfo.facebook,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CREATION_DATE)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.creationDate'),
                        value: this.userInfo.creationDate,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.LAST_ACTIVE)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.lastActive'),
                        value: this.userInfo.lastActiveDate,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CARDINALITY)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.cardinality'),
                        value: this.userInfo.cardinality,
                    });
                }
            }

            return data;
        },
        tmoIdSection() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.tmoId'),
                    value: this.userInfo?.creds?.find(el => el.id_type === USER_INFO_CRED_TYPES.SECURE_ID_INTERNAL)
                        ? this.$i18n.t('generic.yes')
                        : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.userName'),
                    value: this.userInfo?.externalEmail || '',
                },
                {
                    name: this.$i18n.t('generic.email'),
                    value: this.userInfo?.externalEmail || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumber'),
                    value: this.userInfo?.externalMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.tmoIdLinked'),
                    value: this.userInfo?.creds?.find(el => el.id_type === USER_INFO_CRED_TYPES.SECURE_ID_INTERNAL)
                        ? this.$i18n.t('generic.yes')
                        : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.validationMethod'),
                    value:
                        this.userInfo?.flags?.is_otp_validated?.originalValue === IS_OTP_VALIDATED.OTP
                            ? this.$i18n.t('customerCareSuite.otp')
                            : this.$i18n.t('customerCareSuite.pin'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.isPinEnabled'),
                    value:
                        this.userInfo?.flags?.is_pin_setup?.originalValue === IS_PIN_SETUP.PIN
                            ? this.$i18n.t('generic.yes')
                            : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumberForOtp'),
                    value: this.userInfo?.notificationMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.isTmoUser'),
                    value: this.userInfo?.isTmoUser ? this.$i18n.t('generic.yes') : this.$i18n.t('generic.no'),
                },
            ];
        },
        contactInfo() {
            const data = [];

            if (this.userInfo) {
                if (
                    this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN) ||
                    this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN_WITH_VERIFY_STATUS)
                ) {
                    data.push({
                        name: this.$i18n.t('customerCare.msisdn'),
                        value: this.userInfo.msisdn?.replace(/,/g, ', '),
                        ...(this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN_WITH_VERIFY_STATUS) && {
                            key: USER_VERIFICATION_ID_TYPES.MSISDN,
                        }),
                    });
                }

                if (
                    this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL) ||
                    this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL_WITH_VERIFY_STATUS)
                ) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.email'),
                        value: this.userInfo.notificationEmail,
                        ...(this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL_WITH_VERIFY_STATUS) && {
                            key: USER_VERIFICATION_ID_TYPES.EMAIL,
                        }),
                    });
                }

                if (!this.isEkycEnabled && this.isDetailFieldEnabled(USER_DETAIL_FIELD.BIRTHDAY)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.birthday'),
                        value: this.userInfo.birthday || '',
                    });
                }
            }

            return data;
        },
        kycInfo() {
            const data = [];

            if (this.userInfo) {
                if (!this.isEkycEnabled) {
                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.document'),
                            value: [
                                {
                                    text: this.userInfo.documentType,
                                    link: this.userInfo.documentSrc,
                                },
                            ],
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_NUMBER)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.documentNumber'),
                            value: this.userInfo.documentNumber,
                            onClick:
                                !!this.accountDocImageUrl &&
                                (() => {
                                    this.accountDocModalVisible = true;
                                }),
                        });
                    }
                } else {
                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.EKYC_STATUS)) {
                        data.push({
                            name: this.$i18n.t('generic.status'),
                            value: EKYC_STATUS_TO_I18N_LABEL.get(this.ekycStatus) || '',
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.IS_LOCALLY_TIED)) {
                        data.push({
                            name: this.userInfo.flags?.[FLAG_TYPE.IS_LOCALLY_TIED]?.name,
                            value: this.userInfo.flags?.[FLAG_TYPE.IS_LOCALLY_TIED]?.detailedValue,
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.BIRTHDAY)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.birthday'),
                            value: this.userInfo.birthday,
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.NATIONALITY)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.nationality'),
                            value: this.userInfo.nationality,
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_TYPE)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.documentType'),
                            value: [
                                {
                                    text: this.userInfo.documentType,
                                    link: this.userInfo.documentSrc,
                                },
                            ],
                        });
                    }

                    if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_SUBTYPE)) {
                        data.push({
                            name: this.$i18n.t('customerCare.userInformation.documentSubtype'),
                            value: this.userInfo.documentSubtype,
                        });
                    }
                }
            }

            return data;
        },
        consentInfo() {
            const data = [];

            /**
             * @param {number} consentType
             * @param {number} epoch
             * @param {string} contextId
             * @param {boolean} isSet
             */
            const mapConsentItem = (consentType, epoch, contextId, isSet) => {
                let label = isSet
                    ? this.$i18n.t('customerCare.messageStates.acceptedOn')
                    : this.$i18n.t('customerCare.messageStates.notAccepted');

                if (isSet && epoch) {
                    label += ` ${this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(epoch)}`;
                }

                const item = {
                    name: this.$i18n.t(getConsentsContextTypeI18nKey(consentType)),
                    value: [label],
                };

                if (contextId) {
                    item.value.push(`${this.$i18n.t('customerCare.context')} ${contextId}`);
                }

                return item;
            };

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_TERMS_AND_CONDITIONS)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.tcAcceptance'),
                    // Checking if the DevEdge T&C is accepted so in relation to that T&C timestamp is displayed
                    value:
                        this.userInfo?.tcGlobalAccepted === FLAG_TC_GLOBAL_ACCEPTED_TEXT.ACCEPTED
                            ? `${this.$i18n.t('customerCare.messageStates.acceptedOn')} ${this.$localeLibrary
                                  .getFormattedDateAndTimeWithSecondsPrecision(this.userInfo?.tcAcceptanceTimestamp)
                                  .toString()}`
                            : this.userInfo?.tcGlobalAccepted,
                });
            }
            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ACCEPTED)) {
                // Map accepted consents only.
                this.consents.forEach(consent => {
                    data.push(mapConsentItem(consent.context_type, consent.epoch, consent.context_id, consent.is_set));
                });
            } else if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ALL)) {
                // Map all consents(accepted/not-accepted).
                this.consentsDefault.forEach(consentType => {
                    // Get the latest consent of a $consentType(multiple entries).
                    const consent = this.consents.reduce((latest, current) => {
                        if (current.context_type === consentType) {
                            return latest.epoch > current.epoch ? latest : current;
                        }
                        return latest;
                    }, {});

                    data.push(mapConsentItem(consentType, consent?.epoch, consent?.context_id, consent?.is_set));
                });
            }

            return data;
        },
        flagInfo() {
            const data = [];
            const isProfileDeletedFlag = this.userInfo?.flags?.[FLAG_TYPE.IS_PROFILE_DELETED];

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FLAG_PROFILE_DELETED) && isProfileDeletedFlag) {
                data.push({
                    name: isProfileDeletedFlag.name,
                    value: isProfileDeletedFlag.detailedValue,
                });
            }

            return data;
        },
        flagStatusIndicators() {
            const additionalLabels = [];
            if (this.userInfo) {
                const hasSuspendedServices = Object.values(this.userInfo.flags).some(Flag.isFlagSuspendingSomeServices);

                if (hasSuspendedServices) {
                    additionalLabels.push({
                        title: this.$i18n.t('customerCare.suspendServices.someServicesSuspended'),
                        color: LABEL_COLOR.yellow,
                    });
                }
            }

            return additionalLabels;
        },
        infoData() {
            const result = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.basicInfo,
                    initiallyExpanded: true,
                    additionalLabels: [],
                },
            ];

            if (this.contactInfo.length) {
                result.push({
                    title: this.isDetailFieldEnabled(USER_DETAIL_FIELD.FIBER_ACCOUNT_CONTACT_INFO)
                        ? this.$i18n.t('customerCareSuite.fiberAccountContactInfo')
                        : this.$i18n.t('customerCare.userInformation.contactInfo'),
                    value: this.contactInfo,
                    initiallyExpanded: true,
                    additionalLabels: [],
                });
            }

            if (this.kycInfo.length) {
                result.push({
                    title: this.$i18n.t('customerCare.userInformation.kyc'),
                    value: this.kycInfo,
                    initiallyExpanded: true,
                    additionalLabels: [],
                });
            }

            if (this.consentInfo.length) {
                result.push({
                    title: this.$i18n.t('customerCare.userInformation.consents'),
                    value: this.consentInfo,
                    initiallyExpanded: true,
                    additionalLabels: [],
                });
            }

            if (this.flagInfo.length) {
                result.push({
                    title: this.$i18n.t('customerCare.flags'),
                    value: this.flagInfo,
                    initiallyExpanded: true,
                    additionalLabels: this.flagStatusIndicators,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.TMO_ID_SECTION)) {
                result.push({
                    title: this.$i18n.t('customerCareSuite.tmoIdSection'),
                    value: this.tmoIdSection,
                    initiallyExpanded: true,
                    additionalLabels: [],
                });
            }

            return result;
        },
        hasNotificationIdentifiers() {
            return (
                this.userInfo &&
                this.userInfo.notificationIdentifiers &&
                this.userInfo.notificationIdentifiers.length > 0
            );
        },
        msidsnNotifiers() {
            let result = [];
            if (this.userInfo?.notificationIdentifiers?.length) {
                result = this.userInfo.notificationIdentifiers?.filter(
                    i => i.identifier_type === USER_VERIFICATION_ID_TYPES.MSISDN,
                );
            }
            return result;
        },
        emailNotifiers() {
            return this.user?.notificationIdentifiers?.filter(
                i => i.identifier_type === USER_VERIFICATION_ID_TYPES.EMAIL,
            );
        },
    },
    watch: {
        viewedEntityId: {
            handler(newVal) {
                this.requestConsents(newVal);
            },
            immediate: true,
        },
    },
    methods: {
        isDetailFieldEnabled(fieldName: string) {
            return this.userDetailFields.includes(fieldName);
        },
        async requestConsents(targetId: string) {
            await this.$withProgressBar(
                async () => {
                    const consentResponse = await consentHTTP.getConsents(targetId, TARGET_TYPE.USER);
                    this.consents = consentResponse?.data?.consents || [];
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
    },
});
