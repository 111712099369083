import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

// HTTP
import {
    getPlans,
    getOETemplates,
    getMappedExecutions,
    getExecution,
    getPausedExecutions,
    getExecutionsEs,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Models
import { MappedExecutions } from '@/__new__/services/dno/orchestrationengine/models/MappedExecutions';
import Execution from '@/__new__/services/dno/orchestrationengine/models/Execution';

export default {
    namespaced: true,
    state: {
        // map structure => plan_id: { plan data from API }
        [State.OE_PLANS]: {},
        [State.OE_PLANS_API_RESPONSE]: {},
        // map structure => plan_id: [list of executions]
        [State.OE_EXECUTIONS]: {},
        [State.OE_EXECUTIONS_API_RESPONSE]: {},
        [State.OE_EXECUTION]: {},
        [State.OE_EXECUTION_API_RESPONSE]: {},
        [State.OE_EXECUTION_OBJECT]: {},
        [State.OE_TEMPLATES]: {},
        [State.OE_TEMPLATES_API_RESPONSE]: {},
        [State.OE_MAPPED_EXECUTIONS]: {},
        [State.OE_MAPPED_EXECUTIONS_API_RESPONSE]: {},
        [State.OE_PAUSED_EXECUTIONS]: {},
        [State.OE_PAUSED_EXECUTIONS_API_RESPONSE]: {},
    },
    mutations: {
        [Mutations.SET_OE_PLANS]: (state, plans) => {
            plans.forEach(plan => {
                Vue.set(state[State.OE_PLANS], plan.plan_id, plan);
            });
        },
        [Mutations.SET_OE_PLANS_API_RESPONSE]: (state, response) => {
            Vue.set(state, State.OE_PLANS_API_RESPONSE, response);
        },
        [Mutations.SET_OE_EXECUTIONS]: (state, { planId, executionsObject, executionsFormatted }) => {
            Vue.set(state[State.OE_EXECUTIONS], planId, executionsFormatted);
            Vue.set(state, State.OE_EXECUTION_OBJECT, executionsObject);
        },
        [Mutations.SET_OE_EXECUTIONS_API_RESPONSE]: (state, response) => {
            Vue.set(state, State.OE_EXECUTIONS_API_RESPONSE, response);
        },
        [Mutations.ADD_OE_EXECUTIONS]: (state, { planId, executionsObject, executionsFormatted }) => {
            state[State.OE_EXECUTIONS][planId].push(...executionsFormatted);
            Vue.set(state, State.OE_EXECUTION_OBJECT, executionsObject);
        },
        [Mutations.SET_OE_TEMPLATES]: (state, templates) => {
            templates.forEach(template => {
                Vue.set(state[State.OE_TEMPLATES], template?.data?.name, template);
            });
        },
        [Mutations.SET_OE_TEMPLATES_API_RESPONSE]: (state, response) => {
            Vue.set(state, State.OE_TEMPLATES_API_RESPONSE, response);
        },
        [Mutations.SET_OE_MAPPED_EXECUTIONS]: (state, mappedExecutions) => {
            // reseting state because user can go back to search page and enter different params
            state[State.OE_MAPPED_EXECUTIONS] = {};
            mappedExecutions.forEach(execution => {
                Vue.set(state[State.OE_MAPPED_EXECUTIONS], execution?.executionId, execution);
            });
        },
        [Mutations.SET_OE_MAPPED_EXECUTIONS_API_RESPONSE]: (state, apiResponse) => {
            Vue.set(state, State.OE_MAPPED_EXECUTIONS_API_RESPONSE, apiResponse);
        },
        [Mutations.SET_OE_EXECUTION]: (state, execution) => {
            Vue.set(state, State.OE_EXECUTION, execution);
        },
        [Mutations.SET_OE_EXECUTION_API_RESPONSE]: (state, response) => {
            Vue.set(state, State.OE_EXECUTION_API_RESPONSE, response);
        },
        [Mutations.SET_OE_PAUSED_EXECUTIONS]: (state, executions) => {
            Vue.set(state, State.OE_PAUSED_EXECUTIONS, executions);
        },
        [Mutations.SET_OE_PAUSED_EXECUTIONS_API_RESPONSE]: (state, response) => {
            Vue.set(state, State.OE_PAUSED_EXECUTIONS_API_RESPONSE, response);
        },
    },
    actions: {
        async [Actions.REQUEST_OE_PLANS]({ commit }) {
            try {
                const response = await getPlans();
                const { plans } = response.data;
                commit(Mutations.SET_OE_PLANS, plans);
                commit(Mutations.SET_OE_PLANS_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_OE_PLANS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_OE_EXECUTIONS]({ commit }, { planId, pageSize, filters, fromPage, sortBy }) {
            try {
                const response = await getExecutionsEs({ pageSize, filters, fromPage, sortBy });

                const executionsObject = response.data;
                const executionsFormatted = executionsObject.executions.map(
                    executionJSON => new Execution(executionJSON),
                );

                if (!fromPage) {
                    commit(Mutations.SET_OE_EXECUTIONS, { planId, executionsObject, executionsFormatted });
                } else {
                    commit(Mutations.ADD_OE_EXECUTIONS, { planId, executionsObject, executionsFormatted });
                }

                commit(Mutations.SET_OE_EXECUTIONS_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_OE_EXECUTIONS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_OE_TEMPLATES]({ commit }) {
            try {
                const response = await getOETemplates();
                const { orchestration_template_by_id: orchestrationTemplateById } = response.data;
                const templatesById = Object.values(orchestrationTemplateById);
                commit(Mutations.SET_OE_TEMPLATES, templatesById);
                commit(Mutations.SET_OE_TEMPLATES_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_OE_PLANS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_OE_MAPPED_EXECUTIONS]({ commit }, data) {
            const response = await getMappedExecutions(data);
            commit(Mutations.SET_OE_MAPPED_EXECUTIONS_API_RESPONSE, response);
            const mappedExecutions = Object.values(response?.data?.mappings?.[0] || {}).map(
                transactionJSON => new MappedExecutions(transactionJSON),
            );
            commit(Mutations.SET_OE_MAPPED_EXECUTIONS, mappedExecutions);
        },
        async [Actions.REQUEST_OE_EXECUTION]({ commit }, executionId) {
            try {
                const execution = await getExecution(executionId);
                commit(Mutations.SET_OE_EXECUTION, execution);
                commit(Mutations.SET_OE_EXECUTION_API_RESPONSE, execution.data);
            } catch (e) {
                commit(Mutations.SET_OE_PLANS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_OE_PAUSED_EXECUTIONS]({ commit }, data) {
            try {
                const pausedExecutions = await getPausedExecutions(data);
                commit(Mutations.SET_OE_PAUSED_EXECUTIONS, pausedExecutions.data);
                commit(Mutations.SET_OE_PAUSED_EXECUTIONS_API_RESPONSE, pausedExecutions);
            } catch (e) {
                commit(Mutations.SET_OE_PAUSED_EXECUTIONS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
    },
    getters: {
        [Getters.GET_OE_PLANS]: state => Object.values(state[State.OE_PLANS]),
        [Getters.GET_OE_PLANS_API_RESPONSE]: state => state[State.OE_PLANS_API_RESPONSE],
        [Getters.GET_OE_PLAN_BY_ID]: state => planId => state[State.OE_PLANS][planId],
        [Getters.GET_OE_EXECUTIONS_BY_PLAN_ID]: state => planId => state[State.OE_EXECUTIONS][planId] || [],
        [Getters.GET_OE_EXECUTIONS_API_RESPONSE]: state => state[State.OE_EXECUTIONS_API_RESPONSE],
        [Getters.GET_OE_EXECUTION]: state => state[State.OE_EXECUTION],
        [Getters.GET_OE_EXECUTION_API_RESPONSE]: state => state[State.OE_EXECUTION_API_RESPONSE],
        [Getters.GET_OE_EXECUTION_OBJECT]: state => state[State.OE_EXECUTION_OBJECT],
        [Getters.GET_OE_TEMPLATES]: state => Object.values(state[State.OE_TEMPLATES]),
        [Getters.GET_OE_TEMPLATES_API_RESPONSE]: state => state[State.OE_TEMPLATES_API_RESPONSE],
        [Getters.GET_OE_MAPPED_EXECUTIONS]: state => Object.values(state[State.OE_MAPPED_EXECUTIONS]),
        [Getters.GET_OE_MAPPED_EXECUTIONS_API_RESPONSE]: state => state[State.OE_MAPPED_EXECUTIONS_API_RESPONSE],
        [Getters.GET_OE_PAUSED_EXECUTIONS]: state => state[State.OE_PAUSED_EXECUTIONS],
        [Getters.GET_OE_PAUSED_EXECUTIONS_API_RESPONSE]: state => state[State.OE_PAUSED_EXECUTIONS_API_RESPONSE],
    },
};
