import { LABEL_COLOR } from '@/common/labelsHelper';

export enum ONT_STATUS {
    UNKNOWN,
    ACTIVE,
    DISCONNECTED,
    DEGRADED,
}

export const ontStatusColorMap = new Map([
    [ONT_STATUS.UNKNOWN, LABEL_COLOR.gray],
    [ONT_STATUS.ACTIVE, LABEL_COLOR.green],
    [ONT_STATUS.DISCONNECTED, LABEL_COLOR.red],
    [ONT_STATUS.DEGRADED, LABEL_COLOR.yellow],
]);

export const ontStatusLabelMap = new Map([
    [ONT_STATUS.UNKNOWN, 'generic.stateMap.unknown'],
    [ONT_STATUS.ACTIVE, 'generic.stateMap.active'],
    [ONT_STATUS.DISCONNECTED, 'generic.stateMap.disconnectedOff'],
    [ONT_STATUS.DEGRADED, 'generic.stateMap.degraded'],
]);
