<template>
    <div>
        <div
            v-if="label"
            class="lf-subtitle heading"
        >
            {{ label }}
        </div>
        <div
            v-if="description"
            class="lf-secondary-text sub-heading"
        >
            {{ description }}
        </div>
        <div
            :class="{ 'drag-on': isDragOn }"
            class="upload-container mb-3"
            @drag="preventAndStop"
            @dragover="preventAndStop"
            @dragstart="preventAndStop"
            @dragend="preventAndStop"
            @dragenter="[addDragOnClass(), e => preventAndStop(e)]"
            @dragleave="[deleteDragOnClass(), e => preventAndStop(e)]"
            @drop="onDrop"
        >
            <input
                id="file-dragdrop"
                ref="nativeFileInput"
                :multiple="true"
                :accept="acceptType"
                type="file"
                name="file"
                class="input-file-dragdrop"
                @change="onSelect"
            />
            <div class="w-100 d-flex flex-row align-items-center">
                <img
                    class="upload-img"
                    src="@/assets/icons/file-upload.svg"
                />
                <div class="upload-text-wrapper">
                    <div
                        class="upload-text d-flex justify-content-center"
                        :class="{ 'drag-on': isDragOn }"
                    >
                        {{ $i18n.t('partials.fileUploader.dragAndDropText') }}
                        <label
                            for="file-dragdrop"
                            class="upload-link ml-1"
                            :class="{ 'drag-on': isDragOn }"
                        >
                            {{ $i18n.t('partials.fileUploader.browseText') }}
                        </label>
                    </div>
                    <div
                        class="text-center upload-subtext"
                        :class="{ 'drag-on': isDragOn }"
                    >
                        {{ $i18n.t('partials.fileUploader.youCanUpload') + acceptType }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="quickFiltersState && tableColumnsData"
            class="filter-wrapper d-flex justify-content-between w-100 mt-4 mb-4"
        >
            <div class="quick-filters-wrapper d-flex">
                <div
                    v-for="(quick, index) in quickFiltersState"
                    :key="index"
                >
                    <QuickFilterTooltip :text="quick.filterValue.values.value">
                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :iconType="quick.iconType"
                            :iconColor="quick.iconColor"
                            :label="quick.label"
                            class="mr-2"
                            :clicked="quick.clicked"
                            :data-test-id="`quick-filter-button-${quick.id}`"
                            @click="quick.onClick"
                        />
                    </QuickFilterTooltip>
                </div>
            </div>
            <FilterTable
                :columns="tableColumnsData"
                class="position-relative"
                data-test-id="filter-table"
                @filterAdded="addFilter"
            />
        </div>
        <div
            v-if="filterTableMixin && filterTableMixin.allFilters.length > 0"
            class="d-flex flex-wrap"
        >
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                data-test-id="filter-table-tags"
                @removeFilter="removeFilterAndCheckQuick"
                @removeAllFilters="removeFilters"
            />
        </div>
        <div
            v-if="hasSelectedFiles"
            class="files-wrap"
        >
            <div
                v-for="(file, index) in formattedFilteredFiles"
                :key="index"
                class="file-wrapper"
            >
                <FileUploaderSingleFile
                    :file="file"
                    :data-test-id="`single-file-${index}`"
                    @removeFile="removeFile(index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
// COMPONENTS
import FileUploaderSingleFile from '@/components/partials/fileUploader/FileUploaderSingleFile.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import QuickFilterTooltip from '@/components/partials/fileUploader/QuickFilterTooltip.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// HELPERS
import tableColumnType from '@/common/filterTable';
import { FILE_UPLOAD_STATES } from '@/common/commonHelper';
import {
    getUploadingPercentage,
    removeUploadingPercentage,
    setUploadingPercentage,
    uploadToSignedURL,
} from '@/http/fileUploader';
import { uuidV4 } from '@/common/utils';
import * as Sentry from '@sentry/vue';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { EntityUploadStatus } from '@/__new__/services/dno/progressTracker/models/entity';

export default {
    name: 'DragDropFileUploader',
    components: {
        FileUploaderSingleFile,
        AppButton,
        FilterTable,
        QuickFilterTooltip,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin],
    props: {
        label: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        acceptType: {
            type: String,
            default: '',
        },
        /**
         * @configFileUploader - config object:
         * {
         *     getSignedUrl: function API request
         *     getEntityDetailsByCategory: function API request
         *     getEntityDetailsByName: function API request
         *     getRegisteredEntities: function API request
         *     getSignedURLParams: function returning parameters for getSignedUrl request
         *     customData: {} : object with custom data
         *     filesUpdateNotify: function to notify parent of file list size
         * }
         */
        configFileUploader: {
            type: Object,
            required: true,
        },
        triggerUploadFiles: {
            type: Boolean,
            default: false,
        },
        triggerClearPollers: {
            type: Boolean,
            default: false,
        },
        triggerUploadWhenFilesAreAdded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedFiles: [],
            isDragOn: false,
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
            FILE_UPLOAD_STATES,
            formattedFiles: [],
            metadataPollers: {},
            entityIDs: [],
            uploadInProgress: false,
            size: 500,
            pages: [1],
        };
    },
    computed: {
        hasSelectedFiles() {
            return this.selectedFiles.length !== 0;
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(Object.keys(FILE_UPLOAD_STATES).map(key => FILE_UPLOAD_STATES[key])),
                    ),
                },
            ];
        },
        quickFiltersState() {
            return [
                {
                    id: 0,
                    iconType: ICON_TYPES.PENDING,
                    iconColor: ICON_COLORS.GRAY,
                    label: this.countFilesByStatus(FILE_UPLOAD_STATES.PENDING),
                    clicked: false,
                    filterValue: {
                        column: this.tableColumnsData[1],
                        condition: {
                            i18nLabel: 'tableFilter.is',
                            key: 'is',
                            label: 'is',
                        },
                        values: { value: FILE_UPLOAD_STATES.PENDING },
                    },
                    onClick: () => this.toggleQuickFilter(0),
                },
                {
                    id: 1,
                    iconType: ICON_TYPES.CHECK,
                    iconColor: ICON_COLORS.GREEN,
                    label: this.countFilesByStatus(FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED),
                    clicked: false,
                    filterValue: {
                        column: this.tableColumnsData[1],
                        condition: {
                            i18nLabel: 'tableFilter.is',
                            key: 'is',
                            label: 'is',
                        },
                        values: { value: FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED },
                    },
                    onClick: () => this.toggleQuickFilter(1),
                },
                {
                    id: 2,
                    iconType: ICON_TYPES.WARNING,
                    iconColor: ICON_COLORS.YELLOW,
                    label: this.countFilesByStatus(FILE_UPLOAD_STATES.PARTIAL_COMPLETED),
                    clicked: false,
                    filterValue: {
                        column: this.tableColumnsData[1],
                        condition: {
                            i18nLabel: 'tableFilter.is',
                            key: 'is',
                            label: 'is',
                        },
                        values: { value: FILE_UPLOAD_STATES.PARTIAL_COMPLETED },
                    },
                    onClick: () => this.toggleQuickFilter(2),
                },
                {
                    id: 3,
                    iconType: ICON_TYPES.WARNING,
                    iconColor: ICON_COLORS.RED,
                    label: this.countFilesByStatus(FILE_UPLOAD_STATES.FAILED_COMPLETED),
                    clicked: false,
                    filterValue: {
                        column: this.tableColumnsData[1],
                        condition: {
                            i18nLabel: 'tableFilter.is',
                            key: 'is',
                            label: 'is',
                        },
                        values: { value: FILE_UPLOAD_STATES.FAILED_COMPLETED },
                    },
                    onClick: () => this.toggleQuickFilter(3),
                },
                {
                    id: 4,
                    iconType: ICON_TYPES.PENDING,
                    iconColor: ICON_COLORS.BLUE,
                    label: this.countFilesByStatus(FILE_UPLOAD_STATES.PROCESSING),
                    clicked: false,
                    filterValue: {
                        column: this.tableColumnsData[1],
                        condition: {
                            i18nLabel: 'tableFilter.is',
                            key: 'is',
                            label: 'is',
                        },
                        values: { value: FILE_UPLOAD_STATES.PROCESSING },
                    },
                    onClick: () => this.toggleQuickFilter(4),
                },
            ];
        },
        formattedFilteredFiles() {
            return this.filteredEntitiesMixin(this.formattedFiles);
        },
    },
    watch: {
        triggerUploadFiles: {
            handler() {
                this.uploadFiles();
            },
        },
        triggerClearPollers: {
            handler() {
                this.clearPollers();
            },
        },
        formattedFiles: {
            handler() {
                this.$emit('updateAmountFilesUpload', this.formattedFiles.length);
            },
            deep: true,
        },
        uploadInProgress: {
            handler() {
                this.$emit('updateUploadStatus', this.uploadInProgress);
            },
        },
    },
    beforeDestroy() {
        this.clearPollers();
    },
    methods: {
        onSelect(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.addFilesToList(files);
        },
        addFilesToList(files) {
            if (files.length) {
                this.selectedFiles =
                    this.selectedFiles.length > 0 ? this.arrayUnique(Array.from(files)) : Array.from(files);

                this.updateFormattedFiles();
                if (this.configFileUploader.filesUpdateNotify) {
                    this.configFileUploader.filesUpdateNotify(this.selectedFiles.length);
                }

                if (this.triggerUploadWhenFilesAreAdded) {
                    this.uploadFiles();
                }
            }
        },
        updateFormattedFiles() {
            // Leave out files that exist and format files for uploading
            const fileNames = new Set(this.formattedFiles.map(d => d.name));
            const newFiles = this.selectedFiles
                .filter(d => !fileNames.has(d.name))
                .map(file => {
                    setUploadingPercentage(0);
                    return {
                        file,
                        blob: null,
                        blobName: '',
                        id: '',
                        url: '',
                        name: file.name,
                        size: file.size,
                        total: 0,
                        success: 0,
                        fail: 0,
                        status: FILE_UPLOAD_STATES.PENDING,
                        loadingPercentage: 0,
                        failedLines: [],
                        failedLinesString: '',
                        failureEventToLineNumbers: {},
                    };
                });

            this.formattedFiles.push(...newFiles);
        },
        toggleQuickFilter(index) {
            if (this.quickFiltersState[index].clicked === false) {
                this.quickFiltersState[index].clicked = true;
                this.onFilterAdded(this.quickFiltersState[index].filterValue);
            }
        },
        countFilesByStatus(status) {
            return this.formattedFiles
                .reduce((count, file) => (file.status === status ? count + 1 : count), 0)
                .toString();
        },
        removeFile(index) {
            this.selectedFiles.splice(index, 1);

            if (this.formattedFiles[index].id in this.metadataPollers) {
                this.clearPollers(this.formattedFiles[index].id);
            }
            // Cover the case when there are no files left on the list
            if (Object.values(this.metadataPollers).length === 1 && 'registeredEntities' in this.metadataPollers) {
                this.clearPollers();
            }
            this.formattedFiles.splice(index, 1);
            if (!this.selectedFiles.length) {
                // clear native html element
                this.$refs.nativeFileInput.value = '';
            }
            removeUploadingPercentage(index, 1);
            if (this.configFileUploader.filesUpdateNotify) {
                this.configFileUploader.filesUpdateNotify(this.selectedFiles.length);
            }
        },
        addDragOnClass() {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass() {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        preventAndStop(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        arrayUnique(newFiles) {
            const fileNames = new Set(this.selectedFiles.map(({ name }) => name));
            return [...this.selectedFiles, ...newFiles.filter(({ name }) => !fileNames.has(name))];
        },
        onDrop(e) {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            /**
             * Determine which files are valid (by extension and filename)
             */
            const { files } = e.dataTransfer;
            const validFiles = [];
            for (const file of files) {
                if (!this.isAppropriateFileType(file.name)) {
                    this.$alert(
                        `${this.$t('partials.fileUploader.invalidExtension')}: "${file.name}". ${this.$t(
                            'partials.fileUploader.acceptedExtensions',
                        )}: ${this.acceptType}`,
                    );
                } else if (!this.isAppropriateFileName(file.name)) {
                    this.$alert(
                        `${this.$t('partials.fileUploader.invalidFilename')}: "${file.name}". ${this.$t(
                            'partials.fileUploader.filenamesCanOnlyContain',
                        )}`,
                    );
                } else {
                    validFiles.push(file);
                }
            }

            this.addFilesToList(validFiles);
        },
        isAppropriateFileName(name) {
            return /^[a-zA-Z0-9_]+\.[a-zA-Z]+$/.test(name);
        },
        isAppropriateFileType(name) {
            // The following expression returns a list of all accepted file extensions because the
            // input type file element accepts a string of comma separated extension ( ".txt, .csv")
            const types = this.acceptType
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
        removeFilters() {
            this.removeAllFilters();
            this.quickFiltersState.forEach(filter => {
                filter.clicked = false;
            });
        },
        removeFilterAndCheckQuick(index) {
            this.quickFiltersState.forEach(filter => {
                if (filter.filterValue.values.value === this.filterTableMixin.allFilters[index].values.value) {
                    filter.clicked = false;
                }
            });
            this.removeFilter(index);
        },
        addFilter(filter) {
            this.onFilterAdded(filter);
            this.quickFiltersState.forEach(f => {
                if (f.filterValue.values.value === filter.values.value) {
                    f.clicked = true;
                }
            });
        },

        uploadFiles() {
            if (
                !this.uploadInProgress &&
                this.formattedFiles.some(({ status }) => status === FILE_UPLOAD_STATES.PENDING)
            ) {
                this.uploadInProgress = true;
                this.addValuesFromFiles();
            }
        },

        addValuesFromFiles() {
            this.formattedFiles
                .filter(f => f.status === FILE_UPLOAD_STATES.PENDING)
                .forEach(async (file, index) => {
                    // Upload file
                    const formattedFile = this.formattedFiles.find(f => file.name === f.name);
                    try {
                        formattedFile.status = FILE_UPLOAD_STATES.UPLOADING;
                        // Update loading percentage while uploading
                        const uploadPoller = setInterval(() => {
                            formattedFile.loadingPercentage = getUploadingPercentage(index);
                            if (formattedFile.loadingPercentage === 100 && file.name in this.metadataPollers) {
                                this.clearPollers(file.name);
                            }
                        }, 1000);
                        this.$set(this.metadataPollers, file.name, uploadPoller);

                        const fileUUID = uuidV4();
                        formattedFile.blobName = formattedFile.name.replace(' ', '_').concat('-', fileUUID);

                        const newFile = new File([formattedFile.file], formattedFile.blobName);
                        formattedFile.blob = new Blob([newFile], { type: 'binary' });

                        const fileParams = {
                            uuid: fileUUID,
                            staticFilterId: this.configFileUploader.customData?.staticFilterId || undefined,
                            voucherSetId: this.configFileUploader.customData?.voucherSetId || undefined,
                            fileName: formattedFile.name,
                            blobName: formattedFile.blobName,
                        };
                        const signedURLParams = this.configFileUploader.getSignedURLParams(fileParams);
                        const urlRes = await this.configFileUploader.getSignedURL(signedURLParams);

                        formattedFile.id = urlRes.data.data.entity_id;
                        formattedFile.url = urlRes.data.data.upload_url;
                        const requestHeaders = urlRes.data.data.required_request_headers;

                        this.entityIDs.push(formattedFile.id);

                        await uploadToSignedURL(formattedFile.blob, formattedFile.url, index, requestHeaders);
                        await this.findFileAndSetStatus(formattedFile.id, FILE_UPLOAD_STATES.PROCESSING);
                        await this.setRegisteredEntitiesPoller();
                    } catch (error) {
                        this.uploadInProgress = false;
                        Sentry.captureException(error);

                        formattedFile.status = FILE_UPLOAD_STATES.UPLOADING_FAILED;
                        formattedFile.loadingPercentage = 100;
                        this.clearPollers();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$i18n.t('segments.filters.error.alertAction'),
                                entityName: this.$i18n.t('segments.filters.error.alertEntityName'),
                            }),
                        });
                    }
                });
        },
        findFileAndSetStatus(id, status) {
            const file = this.formattedFiles.find(f => f.id === id);
            file.status = status;
            file.loadingPercentage = 0;
        },
        setRegisteredEntitiesPoller() {
            if (!this.metadataPollers.registeredEntities) {
                const poller = setInterval(() => this.updateMetadata(), 5000);
                this.$set(this.metadataPollers, 'registeredEntities', poller);
            }
        },
        clearPollers(t = null) {
            if (t) {
                clearInterval(this.metadataPollers[t]);
                delete this.metadataPollers[t];
            } else {
                Object.values(this.metadataPollers).forEach(timer => clearInterval(timer));
                this.metadataPollers = {};
            }
        },
        async updateMetadata() {
            try {
                // Fetch all records of registered entities that are tracked by Data Pipeline Metadata API.
                const registeredEntities = [];
                const promises = this.pages.map(page => this.configFileUploader.getRegisteredEntities(page, this.size));
                const responses = await Promise.all(promises);
                responses.forEach(({ data }) => {
                    if (data.data.total_pages !== this.pages.length) {
                        this.pages = Array.from({ length: data.data.total_pages }, (_, i) => i + 1);
                    }
                    registeredEntities.push(...data.data.items);
                });

                // Get detailed information of monitored entity (file)  by its name.
                const registeredEntityIds = registeredEntities.map(item => item.entity_name);
                this.entityIDs.forEach(id => {
                    if (registeredEntityIds.includes(id) && !this.metadataPollers[id]) {
                        const poller = setInterval(() => this.updateSingleFileMetadata(id), 5000);
                        this.$set(this.metadataPollers, id, poller);
                    }
                });

                // Clear registeredEntities poller if the conditions are met
                const noPendingLeft = this.formattedFiles.every(({ status }) => status !== FILE_UPLOAD_STATES.PENDING);
                const allEventsRegistered = this.entityIDs.every(id => registeredEntityIds.includes(id));
                if (allEventsRegistered && noPendingLeft) {
                    this.clearPollers('registeredEntities');
                    removeUploadingPercentage();
                }
            } catch (error) {
                Sentry.captureException(error);
                this.uploadInProgress = false;
                this.clearPollers();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('segments.filters.error.alertAction'),
                        entityName: this.$i18n.t('segments.filters.error.alertEntityName'),
                    }),
                });
            }
        },
        async updateSingleFileMetadata(id) {
            try {
                // Get metadata
                const res = await this.configFileUploader.getEntityDetailsByName(id);

                // Update metadata params
                const file = this.formattedFiles.find(f => f.id === id);
                file.total = res.data.data.expected_count;
                const categories = Object.keys(res.data.data.event_category_counters);
                const successfulEvents = categories.find(c => c.includes('successful')) || '';
                const failedEvents = categories.filter(c => !c.includes('successful'));
                file.success = successfulEvents ? res.data.data.event_category_counters[successfulEvents] : 0;
                file.fail = failedEvents.reduce(
                    (sum, failedEventCategory) => sum + res.data.data.event_category_counters[failedEventCategory],
                    0,
                );
                file.loadingPercentage = Math.min(((file.fail + file.success) / file.total) * 100, 100);

                // Update status and stop fetching metadata when the processing is finished
                const uploadStatus = res.data.data.status;
                if (uploadStatus === EntityUploadStatus.COMPLETED || uploadStatus === EntityUploadStatus.DISCARDED) {
                    // If some of the lines failed, fetch detailed info
                    if (file.fail) {
                        // Hardcoding page to 1 since the platform team doesn't support pagination
                        // In other words even if there are more than 500 results, requesting a different page will
                        // simply return the first page. This is a limitation on the `progress-tracker`.
                        // Source: https://lotusflare.slack.com/archives/CP27PMY3T/p1725471758905259
                        const page = 1;
                        const promises = failedEvents.map(event =>
                            this.configFileUploader.getEntityDetailsByCategory(id, event, page, this.size),
                        );
                        const responses = await Promise.all(promises);
                        file.failedLines = responses
                            .map(response => response.data.data.items)
                            .flat()
                            .map(item => item.id)
                            .sort((a, b) => a - b);
                        file.failedLinesString = file.failedLines.join(', ');
                        file.failureEventToLineNumbers = responses.reduce((map, response, index) => {
                            const event = failedEvents[index];
                            map[event] = response.data.data.items.map(item => item.id);
                            return map;
                        }, {});
                    }
                    file.loadingPercentage = 100;
                    this.entityIDs.splice(this.entityIDs.indexOf(id), 1);
                    if (file.fail === 0) {
                        file.status = FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED;
                        if (this.configFileUploader.successfulUploadClb) {
                            this.configFileUploader.successfulUploadClb();
                        }
                    } else if (file.success === 0) {
                        file.status = FILE_UPLOAD_STATES.FAILED_COMPLETED;
                        if (this.configFileUploader.failedUploadClb) {
                            this.configFileUploader.failedUploadClb();
                        }
                    } else {
                        file.status = FILE_UPLOAD_STATES.PARTIAL_COMPLETED;
                        if (this.configFileUploader.partiallySuccessfulUploadClb) {
                            this.configFileUploader.partiallySuccessfulUploadClb();
                        }
                    }
                    this.clearPollers(id);
                    if (Object.values(this.metadataPollers).length === 0) {
                        this.uploadInProgress = false;
                    }
                }
            } catch (error) {
                Sentry.captureException(error);
                this.uploadInProgress = false;
                this.clearPollers();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('segments.filters.error.alertAction'),
                        entityName: this.$i18n.t('segments.filters.error.alertEntityName'),
                    }),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';

.input-file-dragdrop {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem rgba($gray5, 0.5);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    &.drag-on {
        background: $blue5;
        opacity: unset;
        border-color: $blue;
    }
}

.upload-img {
    align-self: center;
    width: 3.5rem;
    margin-left: 2rem;
}

.upload-text-wrapper {
    width: calc(100% - 9.5rem);
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba($gray90, 0.5);
    margin-bottom: 0.25rem;

    &.drag-on {
        color: $gray90;
    }
}

.upload-link {
    color: rgba($blue, 0.5);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.drag-on {
        color: $blue;
    }
}

.upload-subtext {
    color: rgba($gray60, 0.5);
    font-size: 0.75rem;

    &.drag-on {
        color: $gray60;
    }
}

.files-wrap {
    display: flex;
    flex-wrap: wrap;
}

.file-wrapper {
    width: 100%;
}

.file-wrapper:last-child {
    margin-bottom: 0;
}

.heading {
    font-size: 0.75rem;
}

.heading,
.sub-heading {
    margin: 0;
    margin-bottom: $spacing-xs;
}
</style>
