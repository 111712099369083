// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// Tiles
import MessageHistoryTile from '@/__new__/features/customerCareSuite/components/MessageHistoryTile.vue';
import LoggedInDevicesTile from '@/__new__/features/customerCareSuite/components/LoggedInDevicesTile.vue';
import AppointmentsTile from '@/__new__/features/customerCareSuite/components/AppointmentsTile.vue';
import WalletTile from '@/__new__/features/customerCareSuite/components/WalletTile.vue';
import BillingTile from '@/__new__/features/customerCareSuite/components/BillingTile.vue';
import BillingTileNextGen from '@/__new__/features/customerCareSuite/components/BillingTileNextGen.vue';
import CurrentBenefitsTile from '@/__new__/features/customerCareSuite/components/CurrentBenefitsTile.vue';
import CredentialChangeHistoryTile from '@/__new__/features/customerCareSuite/components/CredentialChangeHistoryTile.vue';
import AccessListTile from '@/__new__/features/customerCareSuite/components/AccessListTile.vue';
import AgentNotesTile from '@/__new__/features/customerCareSuite/components/AgentNotesTile.vue';
import ServiceStatusTile from '@/__new__/features/customerCareSuite/components/ServiceStatusTile.vue';
import ServiceUsageTile from '@/__new__/features/customerCareSuite/components/ServiceUsageTile.vue';
import AccountPromotionsTile from '@/__new__/features/customerCareSuite/components/AccountPromotionsTile.vue';
import BillingTransactionTile from '@/__new__/features/customerCareSuite/components/BillingTransactionTile.vue';
import BillingTransactionTileNextGen from '@/__new__/features/customerCareSuite/components/BillingTransactionTileNextGen.vue';
import AppointmentsNotesTile from '@/__new__/features/customerCareSuite/components/AppointmentsNotesTile.vue';
import PaymentTransactionTile from '@/__new__/features/customerCareSuite/components/PaymentTransactionTile.vue';
import LatestTransactionTile from '@/__new__/features/customerCareSuite/components/LatestTransactionTile.vue';
import LatestOrderDetailsTile from '@/__new__/features/customerCareSuite/components/LatestOrderDetailsTile.vue';
import LatestOrderDetailsTileNextGen from '@/__new__/features/customerCareSuite/components/LatestOrderDetailsTileNextGen.vue';
import AssociatedEntitiesTile from '@/__new__/features/customerCareSuite/components/AssociatedEntitiesTile.vue';
import DeliveriesTile from '@/__new__/features/customerCareSuite/components/DeliveriesTile.vue';
import AuthenticationHistoryTile from '@/__new__/features/customerCareSuite/components/AuthenticationHistoryTile.vue';
import OrderDetailsTile from '@/__new__/features/customerCareSuite/components/OrderDetailsTile.vue';
import OrderDetailsTileNextGen from '@/__new__/features/customerCareSuite/components/OrderDetailsTileNextGen.vue';
import ServiceBalanceTile from '@/__new__/features/customerCareSuite/components/ServiceBalanceTile.vue';
import BillingStatementsTile from '@/__new__/features/customerCareSuite/components/BillingStatementsTile.vue';
import RewardHistoryTile from '@/__new__/features/customerCareSuite/components/RewardHistoryTile.vue';
import VoucherHistoryTile from '@/__new__/features/customerCareSuite/components/VoucherHistoryTile.vue';
import OEWorkflowTile from '@/__new__/features/customerCareSuite/components/OEWorkflowTile.vue';
import AppointmentHistoryTile from '@/__new__/features/customerCareSuite/components/AppointmentHistoryTile.vue';
import OutageHistoryTile from '@/__new__/features/customerCareSuite/components/OutageHistoryTile.vue';
import WholesalePricingSchemaTile from '@/__new__/features/customerCareSuite/components/WholesalePricingSchemaTile.vue';
import BroadbandNutritionLabelTile from '@/__new__/features/customerCareSuite/components/BroadbandNutritionLabelTile.vue';
import DataTransferTile from '@/__new__/features/customerCareSuite/components/DataTransferTile.vue';
import ApplicationTile from '@/__new__/features/customerCareSuite/components/ApplicationTile.vue';
import GroupTile from '@/__new__/features/customerCareSuite/components/GroupTile.vue';
import BillingAccountInvoicesTile from '@/__new__/features/customerCareSuite/components/BillingAccountInvoicesTile.vue';
import BillingAccountChargesTile from '@/__new__/features/customerCareSuite/components/BillingAccountChargesTile.vue';
import BillingAccountDiscountsTile from '@/__new__/features/customerCareSuite/components/BillingAccountDiscountsTile.vue';
import BillingAccountPaymentsTile from '@/__new__/features/customerCareSuite/components/BillingAccountPaymentsTile.vue';
import SubscriptionsTile from '@/__new__/features/customerCareSuite/components/SubscriptionsTile.vue';
import DeviceInfoTile from '@/__new__/features/customerCareSuite/components/DeviceInfoTile.vue';
import PortingTableTile from '@/__new__/features/customerCareSuite/components/PortingTableTile.vue';
import MsisdnTransactionHistoryTile from '@/__new__/features/customerCareSuite/components/MsisdnTransactionHistoryTile.vue';
import ServiceTransactionsTile from '@/__new__/features/customerCareSuite/components/ServiceTransactionsTile.vue';
import SimSwapTransactionHistoryTile from '@/__new__/features/customerCareSuite/components/SimSwapTransactionHistoryTile.vue';
import FlagChangesHistoryTile from '@/__new__/features/customerCareSuite/components/FlagChangesHistoryTile.vue';
import AuthenticationTransactionHistoryTile from '@/__new__/features/customerCareSuite/components/AuthenticationTransactionHistoryTile.vue';
import PricingRulesDiscountsTile from '@/__new__/features/customerCareSuite/components/PricingRulesDiscountsTile.vue';

export enum LAYOUT_PAGE_KEYS {
    // implemented
    USER = 'User',
    ACCOUNT = 'Account',
    ORGANIZATION = 'organization',
    SUBSCRIBER = 'subscriber',
    BILLING_ACCOUNT = 'billing_account',
    // not implemented
    MESSAGE_HISTORY = 'messageHistory',
    HIERARCHY = 'hierarchy',
    SEARCH_PAGE = 'searchPage',
}

export const LAYOUT_PAGE_KEYS_TO_UM_HIERARCHY_MAP = new Map([
    [LAYOUT_PAGE_KEYS.ORGANIZATION, USER_MANAGER_HIERARCHY.ORGANIZATION],
    [LAYOUT_PAGE_KEYS.USER, USER_MANAGER_HIERARCHY.USER],
    [LAYOUT_PAGE_KEYS.ACCOUNT, USER_MANAGER_HIERARCHY.ACCOUNT],
    [LAYOUT_PAGE_KEYS.SUBSCRIBER, USER_MANAGER_HIERARCHY.SUBSCRIBER],
]);

export enum LAYOUT_TILE_SIZES {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    THIRD = 'third',
    VERTICAL = 'vertical',
}

export const LAYOUT_TILE_CONFIGS = {
    [LAYOUT_TILE_SIZES.LARGE]: {
        w: 12,
        h: 4,
    },
    [LAYOUT_TILE_SIZES.MEDIUM]: {
        w: 6,
        h: 4,
    },
    [LAYOUT_TILE_SIZES.SMALL]: {
        w: 3,
        h: 4,
    },
    [LAYOUT_TILE_SIZES.THIRD]: {
        w: 4,
        h: 2,
    },
    [LAYOUT_TILE_SIZES.VERTICAL]: {
        w: 4,
        h: 6,
    },
};

export enum COMPONENT_KEYS {
    MESSAGE_HISTORY_TILE = 'MessageHistoryTile',
    LOGGED_IN_DEVICES_TILE = 'LoggedInDevicesTile',
    APPOINTMENTS_TILE = 'AppointmentsTile',
    WALLET_TILE = 'WalletTile',
    CURRENT_BENEFITS_TILE = 'CurrentBenefitsTile',
    ACCESS_LIST_TILE = 'AccessListTile',
    AGENT_NOTES = 'AgentNotesTile',
    CREDENTIAL_CHANGE_HISTORY_TILE = 'CredentialChangeHistoryTile',
    BILLING_TILE = 'BillingTile',
    BILLING_TILE_NEXT_GEN = 'BillingTileNextGen',
    SERVICE_STATUS_TILE = 'ServiceStatusTile',
    SERVICE_USAGE_TILE = 'ServiceUsageTile',
    ACCOUNT_PROMOTIONS_TILE = 'AccountPromotionsTile',
    BILLING_TRANSACTION_TILE = 'BillingTransactionTile',
    BILLING_TRANSACTION_TILE_NEXT_GEN = 'BillingTransactionTileNextGen',
    APPOINTMENTS_NOTES_TILE = 'AppointmentsNotesTile',
    PAYMENT_TRANSACTION_TILE = 'PaymentTransactionTile',
    LATEST_TRANSACTION_TILE = 'LatestTransactionTile',
    LATEST_ORDER_DETAILS_TILE = 'LatestOrderDetailsTile',
    LATEST_ORDER_DETAILS_TILE_NEXT_GEN = 'LatestOrderDetailsTileNextGen',
    ASSOCIATED_ENTITIES_TILE = 'AssociatedEntitiesTile',
    DELIVERIES_TILE = 'DeliveriesTile',
    AUTHENTICATION_HISTORY_TILE = 'AuthenticationHistoryTile',
    ORDER_DETAILS_TILE = 'OrderDetailsTile',
    ORDER_DETAILS_TILE_NEXT_GEN = 'OrderDetailsTileNextGen',
    SERVICE_BALANCE_TILE = 'ServiceBalanceTile',
    BILLING_STATEMENTS_TILE = 'BillingStatementsTile',
    REWARD_HISTORY_TILE = 'RewardHistoryTile',
    VOUCHER_HISTORY_TILE = 'VoucherHistoryTile',
    OE_WORKFLOW_TILE = 'OEWorkflowTile',
    APPOINTMENT_HISTORY_TILE = 'AppointmentHistoryTile',
    OUTAGE_HISTORY_TILE = 'OutageHistoryTile',
    WHOLESALE_PRICING_SCHEMA_TILE = 'WholesalePricingSchemaTile',
    BROADBAND_NUTRITION_LABEL_TILE = 'BroadbandNutritionLabelTile',
    DATA_TRANSFER_TILE = 'DataTransferTile',
    APPLICATION_TILE = 'ApplicationTile',
    GROUP_TILE = 'GroupTile',
    BILLING_ACCOUNT_INVOICES_TILE = 'BillingAccountInvoicesTile',
    BILLING_ACCOUNT_CHARGES_TILE = 'BillingAccountChargesTile',
    BILLING_ACCOUNT_DISCOUNTS_TILE = 'BillingAccountDiscountsTile',
    BILLING_ACCOUNT_PAYMENTS_TILE = 'BillingAccountPaymentsTile',
    SUBSCRIPTIONS_TILE = 'SubscriptionsTile',
    DEVICE_INFO_TILE = 'DeviceInfoTile',
    PORTING_TABLE_TILE = 'PortingTableTile',
    MSISDN_TRANSACTION_HISTORY_TILE = 'MsisdnTransactionHistoryTile',
    SERVICE_TRANSACTIONS_TILE = 'ServiceTransactionsTile',
    SIM_SWAP_TRANSACTION_HISTORY_TILE = 'SimSwapTransactionHistoryTile',
    FLAG_CHANGES_HISTORY_TILE = 'FlagChangesHistoryTile',
    AUTHENTICATION_TRANSACTION_HISTORY_TILE = 'AuthenticationTransactionHistoryTile',
    PRICING_RULES_DISCOUNTS_TILE = 'PricingRulesDiscountsTile',
}

export interface LAYOUT_TILE {
    tileKey: COMPONENT_KEYS;
    tileSize: LAYOUT_TILE_SIZES;
    tileDisplayName: string;
    isTileAvailable: boolean;
    props: unknown;
}

export const COMPONENTS = {
    [COMPONENT_KEYS.MESSAGE_HISTORY_TILE]: MessageHistoryTile,
    [COMPONENT_KEYS.LOGGED_IN_DEVICES_TILE]: LoggedInDevicesTile,
    [COMPONENT_KEYS.APPOINTMENTS_TILE]: AppointmentsTile,
    [COMPONENT_KEYS.WALLET_TILE]: WalletTile,
    [COMPONENT_KEYS.CURRENT_BENEFITS_TILE]: CurrentBenefitsTile,
    [COMPONENT_KEYS.ACCESS_LIST_TILE]: AccessListTile,
    [COMPONENT_KEYS.AGENT_NOTES]: AgentNotesTile,
    [COMPONENT_KEYS.CREDENTIAL_CHANGE_HISTORY_TILE]: CredentialChangeHistoryTile,
    [COMPONENT_KEYS.BILLING_TILE]: BillingTile,
    [COMPONENT_KEYS.BILLING_TILE_NEXT_GEN]: BillingTileNextGen,
    [COMPONENT_KEYS.SERVICE_STATUS_TILE]: ServiceStatusTile,
    [COMPONENT_KEYS.SERVICE_USAGE_TILE]: ServiceUsageTile,
    [COMPONENT_KEYS.ACCOUNT_PROMOTIONS_TILE]: AccountPromotionsTile,
    [COMPONENT_KEYS.BILLING_TRANSACTION_TILE]: BillingTransactionTile,
    [COMPONENT_KEYS.BILLING_TRANSACTION_TILE_NEXT_GEN]: BillingTransactionTileNextGen,
    [COMPONENT_KEYS.APPOINTMENTS_NOTES_TILE]: AppointmentsNotesTile,
    [COMPONENT_KEYS.PAYMENT_TRANSACTION_TILE]: PaymentTransactionTile,
    [COMPONENT_KEYS.LATEST_TRANSACTION_TILE]: LatestTransactionTile,
    [COMPONENT_KEYS.LATEST_ORDER_DETAILS_TILE]: LatestOrderDetailsTile,
    [COMPONENT_KEYS.LATEST_ORDER_DETAILS_TILE_NEXT_GEN]: LatestOrderDetailsTileNextGen,
    [COMPONENT_KEYS.ASSOCIATED_ENTITIES_TILE]: AssociatedEntitiesTile,
    [COMPONENT_KEYS.DELIVERIES_TILE]: DeliveriesTile,
    [COMPONENT_KEYS.AUTHENTICATION_HISTORY_TILE]: AuthenticationHistoryTile,
    [COMPONENT_KEYS.ORDER_DETAILS_TILE]: OrderDetailsTile,
    [COMPONENT_KEYS.ORDER_DETAILS_TILE_NEXT_GEN]: OrderDetailsTileNextGen,
    [COMPONENT_KEYS.SERVICE_BALANCE_TILE]: ServiceBalanceTile,
    [COMPONENT_KEYS.BILLING_STATEMENTS_TILE]: BillingStatementsTile,
    [COMPONENT_KEYS.REWARD_HISTORY_TILE]: RewardHistoryTile,
    [COMPONENT_KEYS.VOUCHER_HISTORY_TILE]: VoucherHistoryTile,
    [COMPONENT_KEYS.OE_WORKFLOW_TILE]: OEWorkflowTile,
    [COMPONENT_KEYS.APPOINTMENT_HISTORY_TILE]: AppointmentHistoryTile,
    [COMPONENT_KEYS.OUTAGE_HISTORY_TILE]: OutageHistoryTile,
    [COMPONENT_KEYS.WHOLESALE_PRICING_SCHEMA_TILE]: WholesalePricingSchemaTile,
    [COMPONENT_KEYS.BROADBAND_NUTRITION_LABEL_TILE]: BroadbandNutritionLabelTile,
    [COMPONENT_KEYS.DATA_TRANSFER_TILE]: DataTransferTile,
    [COMPONENT_KEYS.APPLICATION_TILE]: ApplicationTile,
    [COMPONENT_KEYS.GROUP_TILE]: GroupTile,
    [COMPONENT_KEYS.BILLING_ACCOUNT_INVOICES_TILE]: BillingAccountInvoicesTile,
    [COMPONENT_KEYS.BILLING_ACCOUNT_CHARGES_TILE]: BillingAccountChargesTile,
    [COMPONENT_KEYS.BILLING_ACCOUNT_DISCOUNTS_TILE]: BillingAccountDiscountsTile,
    [COMPONENT_KEYS.BILLING_ACCOUNT_PAYMENTS_TILE]: BillingAccountPaymentsTile,
    [COMPONENT_KEYS.SUBSCRIPTIONS_TILE]: SubscriptionsTile,
    [COMPONENT_KEYS.DEVICE_INFO_TILE]: DeviceInfoTile,
    [COMPONENT_KEYS.PORTING_TABLE_TILE]: PortingTableTile,
    [COMPONENT_KEYS.MSISDN_TRANSACTION_HISTORY_TILE]: MsisdnTransactionHistoryTile,
    [COMPONENT_KEYS.SERVICE_TRANSACTIONS_TILE]: ServiceTransactionsTile,
    [COMPONENT_KEYS.SIM_SWAP_TRANSACTION_HISTORY_TILE]: SimSwapTransactionHistoryTile,
    [COMPONENT_KEYS.FLAG_CHANGES_HISTORY_TILE]: FlagChangesHistoryTile,
    [COMPONENT_KEYS.AUTHENTICATION_TRANSACTION_HISTORY_TILE]: AuthenticationTransactionHistoryTile,
    [COMPONENT_KEYS.PRICING_RULES_DISCOUNTS_TILE]: PricingRulesDiscountsTile,
};

export const LAYOUT_TILES = [
    {
        tileKey: COMPONENT_KEYS.MESSAGE_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Message History',
        //ToDo: Replace with actual list of permissions. Test mock to check tile availability
        isTileAvailable: ['Login'],
        viewKey: 'MessageHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.APPOINTMENTS_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Appointments',
        viewKey: 'AppointmentsTile',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER, LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.LOGGED_IN_DEVICES_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Logged In Devices',
        viewKey: 'LoggedInDevicesTile',
        // TODO: permissions when we have tile to permission mapping
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.WALLET_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Wallet',
        props: {
            enableOverflow: true,
        },
        viewKey: 'WalletTile',
        // TODO: permissions when we have tile to permission mapping
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER, LAYOUT_PAGE_KEYS.ACCOUNT, LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.CURRENT_BENEFITS_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Current Benefits',
        viewKey: 'CurrentBenefitsTile',
        // TODO: permissions when we have tile to permission mapping
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.ACCESS_LIST_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Access List',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AccessListTile',
        pages: [LAYOUT_PAGE_KEYS.ORGANIZATION, LAYOUT_PAGE_KEYS.ACCOUNT, LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.AGENT_NOTES,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Agent Notes',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AgentNotesTile',
        props: {
            userManagerHierarchy: USER_MANAGER_HIERARCHY.USER,
        },
        pages: [
            LAYOUT_PAGE_KEYS.ORGANIZATION,
            LAYOUT_PAGE_KEYS.USER,
            LAYOUT_PAGE_KEYS.ACCOUNT,
            LAYOUT_PAGE_KEYS.SUBSCRIBER,
        ],
    },
    {
        tileKey: COMPONENT_KEYS.CREDENTIAL_CHANGE_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Credential Change History',
        viewKey: 'CredentialChangeHistoryTile',
        // TODO: permissions when we have tile to permission mapping
        isTileAvailable: true,
        // permissionsService.userManagementCredsHistoryEnabled() && isViewEnabled('UMUserCredentialChangeHistory'),
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Billing',
        viewKey: 'BillingTile',
        props: {
            enableOverflow: true,
            userManagerHierarchy: USER_MANAGER_HIERARCHY.USER,
        },
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_TILE_NEXT_GEN,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Billing Next Gen',
        viewKey: 'BillingTileNextGen',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER, LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.LATEST_TRANSACTION_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Payment History',
        viewKey: 'LatestTransactionTile',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.LATEST_ORDER_DETAILS_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Latest Order Details',
        viewKey: 'LatestOrderDetailsTile',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.LATEST_ORDER_DETAILS_TILE_NEXT_GEN,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Latest Order Details Next Gen',
        viewKey: 'LatestOrderDetailsTileNextGen',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    // Tiles for demo purposes

    // {
    //     tileKey: COMPONENT_KEYS.SERVICE_STATUS_TILE,
    //     tileSize: LAYOUT_TILE_SIZES.SMALL,
    //     tileDisplayName: 'Network Experience',
    //     viewKey: 'ServiceStatusTile',
    //     props: {
    //         userManagerHierarchy: USER_MANAGER_HIERARCHY.ACCOUNT,
    //     },
    //     isTileAvailable: true,
    //     pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    // },
    // {
    //     tileKey: COMPONENT_KEYS.SERVICE_USAGE_TILE,
    //     tileSize: LAYOUT_TILE_SIZES.THIRD,
    //     tileDisplayName: 'Service Usage',
    //     viewKey: 'ServiceUsageTile',
    //     props: {
    //         serviceNumber: 1,
    //     },
    //     isTileAvailable: true,
    //     pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    // },
    {
        tileKey: COMPONENT_KEYS.BILLING_TRANSACTION_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Transaction',
        isTileAvailable: true,
        viewKey: 'BillingTransactionTile',
        // TODO: permissions when we have tile to permission mapping
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_TRANSACTION_TILE_NEXT_GEN,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Transaction Next Gen',
        isTileAvailable: true,
        viewKey: 'BillingTransactionTileNextGen',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.ACCOUNT_PROMOTIONS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Promotions & Retention',
        props: {
            enableOverflow: true,
            userManagerHierarchy: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        viewKey: 'AccountPromotionsTile',
        // TODO: permissions when we have tile to permission mapping
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.APPOINTMENTS_NOTES_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Appointments Notes',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AppointmentsNotesTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.PAYMENT_TRANSACTION_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Payment Transaction',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'PaymentTransactionTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.GROUP_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Group',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'GroupTile',
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.ASSOCIATED_ENTITIES_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Associated Entities',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AssociatedEntitiesTile',
        pages: [
            LAYOUT_PAGE_KEYS.ORGANIZATION,
            LAYOUT_PAGE_KEYS.USER,
            LAYOUT_PAGE_KEYS.ACCOUNT,
            LAYOUT_PAGE_KEYS.SUBSCRIBER,
        ],
    },
    {
        tileKey: COMPONENT_KEYS.DELIVERIES_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Deliveries',
        viewKey: 'DeliveriesTile',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        pages: [LAYOUT_PAGE_KEYS.USER, LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.AUTHENTICATION_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Authentication History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AuthenticationHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.USER],
    },
    {
        tileKey: COMPONENT_KEYS.ORDER_DETAILS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Order Details',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'OrderDetailsTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.ORDER_DETAILS_TILE_NEXT_GEN,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Order Details Next Gen',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'OrderDetailsTileNextGen',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.BROADBAND_NUTRITION_LABEL_TILE,
        tileSize: LAYOUT_TILE_SIZES.VERTICAL,
        tileDisplayName: 'Broadband facts',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'BroadbandNutritionLabelTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.SERVICE_BALANCE_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Service Balance',
        viewKey: 'ServiceBalanceTile',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        pages: [LAYOUT_PAGE_KEYS.USER],
        props: {
            enableOverflow: true,
        },
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_STATEMENTS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Statements',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'BillingStatementsTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.REWARD_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Reward History',
        viewKey: 'RewardHistoryTile',
        props: {
            userManagerHierarchy: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.VOUCHER_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Voucher History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'VoucherHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.OE_WORKFLOW_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Orchestration Engine Workflow',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
        viewKey: 'OEWorkflowTile',
    },
    {
        tileKey: COMPONENT_KEYS.OUTAGE_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Outage History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'OutageHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.APPOINTMENT_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.MEDIUM,
        tileDisplayName: 'Appointments History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AppointmentHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.DATA_TRANSFER_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Data Transfer',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'DataTransferTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.APPLICATION_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Applications',
        viewKey: 'ApplicationTile',
        isTileAvailable: true,
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.WHOLESALE_PRICING_SCHEMA_TILE,
        tileSize: LAYOUT_TILE_SIZES.SMALL,
        tileDisplayName: 'Wholesale Pricing Schema',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'WholesalePricingSchemaTile',
        pages: [LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_ACCOUNT_INVOICES_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Account Invoices',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        //viewKey: 'WholesalePricingSchemaTile',
        pages: [LAYOUT_PAGE_KEYS.BILLING_ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_ACCOUNT_CHARGES_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Account Charges',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        //viewKey: 'WholesalePricingSchemaTile',
        pages: [LAYOUT_PAGE_KEYS.BILLING_ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_ACCOUNT_DISCOUNTS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Account Discounts',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        //viewKey: 'WholesalePricingSchemaTile',
        pages: [LAYOUT_PAGE_KEYS.BILLING_ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.BILLING_ACCOUNT_PAYMENTS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Billing Account Payments',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        //viewKey: 'WholesalePricingSchemaTile',
        pages: [LAYOUT_PAGE_KEYS.BILLING_ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.SUBSCRIPTIONS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Subscriptions',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'SubscriptionsTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT, LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.DEVICE_INFO_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Device Info',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'DeviceInfoTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
    {
        tileKey: COMPONENT_KEYS.PORTING_TABLE_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Porting Status',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'PortingTableTile',
        pages: [LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.MSISDN_TRANSACTION_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Change MSISDN transaction history',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'MsisdnTransactionHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.SERVICE_TRANSACTIONS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Service Transactions',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'ServiceTransactionsTile',
        pages: [LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.SIM_SWAP_TRANSACTION_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Sim swap transaction history',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'SimSwapTransactionHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.FLAG_CHANGES_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Flag Changes History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'FlagChangesHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT, LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.AUTHENTICATION_TRANSACTION_HISTORY_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Authentication Transaction History',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'AuthenticationTransactionHistoryTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT, LAYOUT_PAGE_KEYS.SUBSCRIBER],
    },
    {
        tileKey: COMPONENT_KEYS.PRICING_RULES_DISCOUNTS_TILE,
        tileSize: LAYOUT_TILE_SIZES.LARGE,
        tileDisplayName: 'Pricing Rules Discounts',
        isTileAvailable: true,
        // TODO: permissions when we have tile to permission mapping
        viewKey: 'PricingRulesDiscountsTile',
        pages: [LAYOUT_PAGE_KEYS.ACCOUNT],
    },
];

export enum LAYOUT_FEATURES {
    CONFIGURE_BUTTONS = 'CONFIGURE_BUTTONS',
}

export enum LAYOUT_BUTTONS {
    // customer info
    USER_GENERATE_CODE = 'USER_GENERATE_CODE',
    USER_VERIFY_USER = 'USER_VERIFY_USER',
    USER_REVERT_LEGACY = 'USER_REVERT_LEGACY',
    // account
    ACCOUNT_REVERT_LEGACY = 'ACCOUNT_REVERT_LEGACY',
    // subscriber
    SUBSCRIBER_REVERT_LEGACY = 'SUBSCRIBER_REVERT_LEGACY',
    // organization
    ORGANIZATION_REVERT_LEGACY = 'ORGANIZATION_REVERT_LEGACY',
}
