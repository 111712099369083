

































import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import TransactionHistoryStatusIndicator from '@/__new__/features/customerCare/account/TransactionHistoryStatusIndicator.vue';

// Helpers
import get from 'lodash/get';
import tableColumnType from '@/common/filterTable';
import { getScaledAmount, DATA_DEFINITIONS } from '@/common/formatting';
import RouteNames from '@/router/routeNames';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TranslateResult } from 'vue-i18n';
import { PAYMENT_TRANSACTION_TYPE_CODES } from '@/__new__/features/customerCareSuite/common/transactionHelper';

// HTTP
import paymentHTTP from '@/http/payment';

interface ColumnData {
    name: TranslateResult;
    key: string;
    field: string;
    filterType: any;
    mapper?: any;
    sortBy?: any;
}

interface TransactionHistory {
    amount: number;
    amountFormatted: string;
    connectedAcount: string;
    connectedMSISDN: string;
    transactionTimestamp: string;
    transactionId: string;
}

export default Vue.extend({
    name: 'DataTransferTile',
    components: {
        AbstractTableTile,
        TransactionHistoryStatusIndicator,
    },
    props: {
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            transactionHistoryApiResponse: {},

            // proxy
            RouteNames,
        };
    },
    computed: {
        columnsData(): ColumnData[] {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'transactionId',
                    field: 'transactionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'transactionTimestamp',
                    mapper: (entity: any) => {
                        if (entity) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                entity.transactionTimestamp,
                            );
                        }

                        return '';
                    },
                    sortBy: (entity: any) => entity.transaction_timestamp,
                    field: 'transactionTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormated',
                    sortBy: (entity: any) => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.receivedFromSentTo'),
                    key: 'connectedAccount',
                    mapper: (entity: any) => get(entity, 'connectedAccount', this.$i18n.t('generic.N/A')),
                    field: 'connectedAccount',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'connectedMSISDN',
                    mapper: (entity: any) => get(entity, 'connectedMSISDN', this.$i18n.t('generic.N/A')),
                    field: 'connectedMSISDN',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
            ];
        },
        transactionHistoriesFormatted(): TransactionHistory[] {
            if (this.transactionHistoryApiResponse?.data?.transactions?.length) {
                return this.transactionHistoryApiResponse.data.transactions.map((transaction: any) => {
                    const amount = getScaledAmount(DATA_DEFINITIONS[0], transaction.amount);
                    const transactionType = transaction?.transaction_type || null;
                    let connectedAccount = '';
                    let connectedMSISDN = '';

                    if (transactionType) {
                        connectedAccount =
                            transactionType === PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE
                                ? transaction?.transfer_info?.sender_info?.target_id
                                : transaction?.transfer_info?.receiver_info?.target_id;
                        connectedMSISDN = PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE
                            ? transaction?.transfer_info?.sender_info?.msisdn
                            : transaction?.transfer_info?.receiver_info?.msisdn;
                    }

                    return {
                        amount,
                        amountFormated: `${
                            transactionType === PAYMENT_TRANSACTION_TYPE_CODES.TRANSFER_DATA_RECEIVE ? '+' : '-'
                        } ${amount}${DATA_DEFINITIONS[0].label}`,
                        connectedAccount,
                        connectedMSISDN,
                        transactionTimestamp: transaction?.transaction_timestamp || '',
                        transactionId: transaction?.transaction_id || '',
                        state: transaction?.state || '',
                    };
                });
            }

            return [];
        },
    },
    watch: {
        benefitsType() {
            this.fetchTableData();
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    this.transactionHistoryApiResponse = await paymentHTTP.getTransactionHistory({
                        target_id: this.$route.params.id,
                        target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                        filters: [
                            {
                                field_name: 'transaction_type',
                                operator: 'IS_GREATER_THAN',
                                value: 5,
                            },
                            {
                                field_name: 'transaction_type',
                                operator: 'IS_LESS_THAN',
                                value: 8,
                            },
                        ],
                        include_payment_items: false,
                    });

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.transactionHistoryApiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.dataTransferFetchError'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
