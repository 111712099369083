


















































































































































import Vue from 'vue';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// Helpers
import { TranslateResult } from 'vue-i18n';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    DailyUsage,
    DailyUsageConstructorArgs,
    TABLES_TABS_IDS,
    MAP_TABLES_TABS_IDS_TO_RECORD_CATEGORY,
} from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// HTTP
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';

export default Vue.extend({
    name: 'ServiceTransactionsTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
    },
    data() {
        return {
            TABLES_TABS_IDS,
            activeTableId: TABLES_TABS_IDS.VOICE_SMS_USAGE,
            entities: [] as DailyUsage[],
            apiResponse: {},
            nextPageToken: '',
            displayTable: true,
            selectedEntity: null as DailyUsage | null,
            isSidebarVisible: false,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData() {
            return (
                this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    targetId: this.$route.params.id,
                }) || {}
            );
        },
        tablesTabs(): Array<{ id: TABLES_TABS_IDS; title: TranslateResult }> {
            return [
                {
                    id: TABLES_TABS_IDS.VOICE_SMS_USAGE,
                    title: this.$i18n.t('customerCare.serviceTransactionsTile.voiceSmsUsage'),
                },
                {
                    id: TABLES_TABS_IDS.DATA_USAGE,
                    title: this.$i18n.t('customerCare.serviceTransactionsTile.dataUsage'),
                },
            ];
        },
        columnsData(): TableColumn[] {
            return this.activeTableId === TABLES_TABS_IDS.VOICE_SMS_USAGE
                ? this.voiceSmsColumnsData
                : this.dataColumnsData;
        },
        voiceSmsColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageType'),
                    key: 'usageType',
                    field: 'usageType',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.subscriberMobileNumber'),
                    key: 'msisdn',
                    field: 'msisdn',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.startTimeOfTheUsageType'),
                    key: 'startTimeOfUsage',
                    field: 'startTimeOfUsage',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.terminatingNumberCalled'),
                    key: 'callTerminatingNumber',
                    field: 'callTerminatingNumber',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.voiceDurationRoundedMin'),
                    key: 'durationRoundedMin',
                    field: 'durationRoundedMin',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageCallDirection'),
                    key: 'callDirection',
                    field: 'callDirection',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.roamedCountry'),
                    key: 'callTerminatingLocation',
                    field: 'callTerminatingLocation',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        dataColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageType'),
                    key: 'usageType',
                    field: 'usageType',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.subscriberMobileNumber'),
                    key: 'msisdn',
                    field: 'msisdn',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.startTimeOfTheUsageType'),
                    key: 'startTimeOfUsage',
                    field: 'startTimeOfUsage',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.totalVolumeMb'),
                    key: 'totalVolume',
                    field: 'totalVolume',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.onNetUsage'),
                    key: 'onNetUsages',
                    field: 'onNetUsages',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.mmsIndicator'),
                    key: 'mmsIndicator',
                    field: 'mmsIndicator',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.roamedCountry'),
                    key: 'countryName',
                    field: 'countryName',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        showSidebar(): boolean {
            return this.isSidebarVisible && Boolean(this.selectedEntity);
        },
    },
    watch: {
        subscriberData() {
            this.fetchTileData();
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const { msisdn } = this.subscriberData;

                    if (!msisdn) {
                        this.$emit('isDataLoadingUpd', false);
                        return;
                    }

                    this.apiResponse = await ossmvneHTTP.getDailyUsage(
                        msisdn,
                        MAP_TABLES_TABS_IDS_TO_RECORD_CATEGORY[this.activeTableId],
                        this.nextPageToken || undefined,
                    );
                    this.nextPageToken = this.apiResponse?.data?.page_token || '';
                    this.entities = Array.isArray(this.apiResponse?.data?.records)
                        ? this.apiResponse.data.records.map((el: DailyUsageConstructorArgs) => new DailyUsage(el))
                        : [];
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('isDataLoadingUpd', false);
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        onTabSelected(tabId: TABLES_TABS_IDS): void {
            this.entities = [];
            this.selectedEntity = null;
            this.isSidebarVisible = false;
            this.activeTableId = tabId;
            this.forceRerender();
            this.fetchTileData();
        },
        async forceRerender() {
            // force table columns to reset when tab changes
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
        rowSelected(rowid: string): void {
            this.selectedEntity = this.entities.find(el => el.id === rowid) || null;
            this.isSidebarVisible = true;
        },
        onSidebarClose(): void {
            this.selectedEntity = null;
            this.isSidebarVisible = false;
        },
        isCurrentTableActive(tabId: TABLES_TABS_IDS): boolean {
            return this.activeTableId === tabId;
        },
    },
});
