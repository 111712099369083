var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayTable)?_c('AbstractTableTile',{attrs:{"entities":_vm.formattedData,"columnsData":_vm.columnsData,"apiResponse":_vm.apiResponse,"entityKey":'index',"tabs":_vm.tabs,"activeTabId":_vm.activeTab,"tableKey":_vm.tableKey},on:{"tabSelected":function (id) { return _vm.onTabSelected(id); }},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.status,"stateMap":_vm.stateMam,"stateMapColor":_vm.stateMapColor}})]}},{key:"afterButtons",fn:function(){return [(_vm.isUserAllowed('UMSubscriberEditSubscriberChangeMSISDN'))?_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.isFraud
                        ? _vm.$i18n.t('customerCareSuite.msisdnTransactionHistoryTile.msisdnChangeDisabled')
                        : '',
                    classes: ['dark'],
                    placement: 'bottom',
                    trigger: 'hover click',
                    autoHide: false,
                }),expression:"{\n                    content: isFraud\n                        ? $i18n.t('customerCareSuite.msisdnTransactionHistoryTile.msisdnChangeDisabled')\n                        : '',\n                    classes: ['dark'],\n                    placement: 'bottom',\n                    trigger: 'hover click',\n                    autoHide: false,\n                }"}],class:['ml-3', { 'allow-pointer-events': _vm.isFraud }],attrs:{"label":_vm.$i18n.t('authorizationPage.sensitiveOperation.changeMsisdn'),"disabled":_vm.isFraud,"data-test-id":"change-msisdn-btn"},on:{"click":function($event){return _vm.changeMsisdn()}}}):_vm._e()]},proxy:true}],null,false,3741879398)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }