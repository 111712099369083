





























































































































































































import Vue from 'vue';

// Vuex
import { Modules } from '@/store/store';
import { mapGetters } from 'vuex';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// mixins
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';

// HTTP
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    getSignedURLForDownload,
} from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import {
    getUploadHistory,
    getAllFileUploadStatusStrings,
    FILE_UPLOAD_STATUS_TO_COLOR_MAP,
} from '@/__new__/services/dno/developerLineAuthorization/developerLineAuthorizationService';

// Helppers
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import { GetSignedUrlParams } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { UploadedFileDetails } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { capitalize } from 'lodash';
import { TranslateResult } from 'vue-i18n';
import download from 'downloadjs';

type SideBarRow = {
    name: string;
    value: string | string | number;
};

type SideBarItem = {
    name: TranslateResult;
    rows: SideBarRow[];
};

export default Vue.extend({
    name: 'UploadWps',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        DragDropFileUploader,
        FileUploaderModal,
        TableFiltersRenderless,
        AppTable,
        TableFiltersTags,
        SearchBox,
        FilterTable,
        IconButton,
        EntityOverview,
        OverviewHeaderV2,
        AppOverviewBlock,
        CollapsibleItem,
        EntityStatusIndicator,
    },
    mixins: [DownloadDetailsMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            FILE_UPLOAD_STATUS_TO_COLOR_MAP,
            ENTITY_TYPES,

            apiType: DEVICE_LINE_AUTH_API_TYPE.WPS_BULK as DEVICE_LINE_AUTH_API_TYPE,
            showErrorModal: false,
            failedLinesErrorMessage: [],
            triggerUploadFiles: false,
            triggerClearPollers: false,
            numberOfFilesToUpload: 0,
            showUploadHistoryResults: false,
            uploadHistoryDataLoading: false,
            uploadHistory: [] as UploadedFileDetails[],
            uploadHistorySearchQuery: '',
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            selectedUploadHistory: undefined as UploadedFileDetails | undefined,
            showUploadHistoryOverview: false,
        };
    },
    computed: {
        configFileUploader() {
            return {
                getSignedURL: (params: GetSignedUrlParams) => getSignedURL(this.apiType, params),
                getEntityDetailsByCategory: (name: string, category: string, page: number, size: number) =>
                    getEntityDetailsByCategory(this.apiType, name, category, page, size),
                getEntityDetailsByName: (name: string) => getEntityDetailsByName(this.apiType, name),
                getRegisteredEntities: (page: number, size: number) => getRegisteredEntities(this.apiType, page, size),
                getSignedURLParams: (params: { fileName: string }) => ({
                    name: `${this.accountNameFormatted}-${this.accountData.id}-${params.fileName}`,
                }),
            };
        },
        canEndUserUploadFiles(): boolean {
            return !isViewEnabled('UploadWps') || !isUserAllowed('UploadWpsWrite');
        },
        enableUploadBtn(): boolean {
            return !this.numberOfFilesToUpload;
        },
        accountNameFormatted(): string {
            const accountName = this.accountData.name ?? '';
            return accountName.replace(/[^A-Za-z ]/g, '');
        },
        uploadHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.uploadedBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ];
        },
        selectedUploadHistoryGeneralData(): SideBarItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    rows: this.selectedUploadHistory
                        ? [
                              {
                                  name: this.$t('generic.filename'),
                                  value: this.selectedUploadHistory.fileName ?? '',
                              },
                              {
                                  name: this.$t('qodNumberManagement.bulkUploadId'),
                                  value: this.selectedUploadHistory.bulkUploadId ?? this.$t('generic.unknown'),
                              },
                              {
                                  name: this.$t('qodNumberManagement.recordCount'),
                                  value: this.selectedUploadHistory.recordCount ?? this.$t('generic.unknown'),
                              },
                              {
                                  name: this.$t('generic.uploadedBy'),
                                  value: this.selectedUploadHistory.createdBy ?? this.$t('generic.unknown'),
                              },
                              {
                                  name: this.$t('generic.uploadTime'),
                                  value: this.$localeLibrary.getFormattedDateAndTime(
                                      this.selectedUploadHistory.createdAt,
                                  ),
                              },
                          ]
                        : [],
                },
            ];
        },
        selectedUploadHistoryProvisionStatusData(): SideBarItem[] {
            return [
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    rows: this.selectedUploadHistory
                        ? Object.entries(this.selectedUploadHistory.eventCategoryCounters || {}).map(
                              ([status, count]) => ({
                                  name: capitalize(String(status).replace(/_/g, ' ')),
                                  value: `${count}/${this.selectedUploadHistory?.recordCount}`,
                              }),
                          )
                        : [],
                },
            ];
        },
    },
    methods: {
        ...mapGetters(Modules.config, {
            accountData: 'getAccountData',
        }),
        updateAmountFilesUpload(numberOfFilesToUpload: number): void {
            this.numberOfFilesToUpload = numberOfFilesToUpload;
        },
        displayFailedLines(lines: any): void {
            this.failedLinesErrorMessage = [
                this.$i18n.tc(
                    'operator.numberManagement.error.failedLinesMessage',
                    lines.length > 1 ? PLURALIZATION.PLURAL : PLURALIZATION.SINGULAR,
                    {
                        n: lines,
                    },
                ),
            ];
            this.showErrorModal = true;
        },
        onBack(): void {
            this.triggerClearPollers = !this.triggerClearPollers;
            this.$router.go(-1);
        },
        onUpload(): void {
            this.triggerUploadFiles = !this.triggerUploadFiles;
        },
        async onLoadHistory() {
            this.showUploadHistoryResults = true;
            await this.$withProgressBar(
                async () => {
                    this.uploadHistoryDataLoading = true;
                    this.uploadHistory = await getUploadHistory(this.apiType);
                    this.uploadHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.uploadHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        onUploadHistorySelected(entity: UploadedFileDetails): void {
            this.selectedUploadHistory = entity;
            this.showUploadHistoryOverview = true;
        },
        async onDownloadFile() {
            if (!this.selectedUploadHistory) {
                return;
            }

            await this.$withLoadingSpinner(
                async () => {
                    const uploadedFilename = this.selectedUploadHistory?.entityName || '';
                    const signedUrlRes = await getSignedURLForDownload(this.apiType, uploadedFilename);
                    const downloadUrl = signedUrlRes.data?.signed_entity?.url;

                    const fileRes = await fetch(downloadUrl);
                    if (!fileRes.ok) {
                        throw new Error(`Fetch to endpoint ${downloadUrl} to download ${uploadedFilename} failed.`);
                    }

                    const file = await fileRes.text();
                    const filename = this.selectedUploadHistory?.fileName;

                    download(file, filename, 'text/plain');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('qodNumberManagement.failedToDownloadFile'));
                    },
                },
            );
        },
        onDownloadTemplate(): void {
            this.downloadOffersCSV(
                {
                    fields: ['MSISDN', 'newWPS'],
                    data: [['7064097571', '50001000']],
                },
                'BulkUpdateWPS',
            );
        },
    },
});
