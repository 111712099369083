





































































import Vue from 'vue';

// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppTable from '@/components/partials/AppTable.vue';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import {
    EXECUTION_STATES,
    EXECUTION_TO_LABEL,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import { TranslateResult } from 'vue-i18n';

// Mixins
import OESkipStatesMixin from '@/__new__/features/orchestrationengine/mixins/OESkipStatesMixin.vue';

// HTTP
import { runPlan } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default Vue.extend({
    name: 'RunExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
        AppTable,
        IconButton,
        ExecutionStatusIndicator,
    },

    mixins: [OESkipStatesMixin],

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        planId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,

            runExecutionResult: [] as { id: any; startDateFormatted: any; endDateFormatted: any; status: string }[],
            isRunExecutionButtonClicked: false as boolean,
            runExecutionFinished: false as boolean,
        };
    },

    computed: {
        tableColumnsData(): { name: TranslateResult; key: string; field: string; mapper?: any }[] {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startDateFormatted',
                    field: 'startDateMoment',
                    mapper: (entity: { startDateFormatted: any }) =>
                        this.$localeLibrary.getFormattedDateAndTime(entity.startDateFormatted),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'endDateFormatted',
                    field: 'endDateMoment',
                    mapper: (entity: { endDateFormatted: any }) =>
                        this.$localeLibrary.getFormattedDateAndTime(entity.endDateFormatted),
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'statusFilterFormatted',
                    mapper: (entity: { status: any }) => EXECUTION_TO_LABEL(entity.status),
                },
            ];
        },
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    validations: {
        inputJsonString: {
            validation: function validation(value) {
                if (value) {
                    try {
                        JSON.parse(value);

                        return true;
                    } catch (e) {
                        return false;
                    }
                }

                return false;
            },
        },
    },

    methods: {
        onCloseModal(): void {
            this.$emit('close');

            // Reset validation
            this.$v.$reset();

            // Reset modal data
            this.isRunExecutionButtonClicked = false;
            this.inputJsonString = '';
            this.runExecutionFinished = false;
            this.runExecutionResult = [];
            this.skipStates = [];
        },
        async onRunExecution(): Promise<any> {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    this.isRunExecutionButtonClicked = true;
                    this.$Progress.start();

                    const jsonAsObject = JSON.parse(this.inputJsonString);

                    const response = await runPlan(this.planId, jsonAsObject);

                    this.runExecutionFinished = true;
                    this.setRunExecutionResultData(response);

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.scheduleSuccess'),
                        type: ALERT_TYPES.success,
                    });
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.somethingWentWrongSchedule'),
                    });
                } finally {
                    this.$Progress.finish();
                    this.isRunExecutionButtonClicked = false;
                }
            }
        },
        executionDetailsAction(entity: { id: any; execId: any; companyId: any }): void {
            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                params: {
                    id: this.planId,
                    execId: entity.id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        setRunExecutionResultData(response: { data: any }): void {
            if (response?.data) {
                let status = '';
                const { ended_in_success: endedInSuccess = false, ended_in_failure: endedInFailure = false } =
                    response.data;

                if (!endedInSuccess && !endedInFailure) {
                    status = EXECUTION_STATES.MOVED_TO_ASYNCHRONOUS;
                } else if (endedInSuccess) {
                    status = EXECUTION_STATES.SUCCESS;
                } else if (endedInFailure) {
                    status = EXECUTION_STATES.FAILED;
                }

                this.runExecutionResult = [
                    {
                        id: response.data.execution_data.execution_id || '',
                        startDateFormatted: response.data.execution_data.started_at || '',
                        endDateFormatted: response.data.execution_data.finished_at || '',
                        status,
                    },
                ];
            }
        },
    },
});
