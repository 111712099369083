















































































































// Vue
import Vue, { PropType } from 'vue';

// Components
import AbstractTileWrapper from '@/__new__/features/customerCareSuite/common/AbstractTileWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppDropdown from '@/components/partials/AppDropdown.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import VueGridLayout from 'vue-grid-layout';
import { debounce } from 'lodash';
import { isViewEnabled } from '@/services/permissions/permissions.service';
import {
    LAYOUT_TILE_CONFIGS,
    COMPONENTS,
    LAYOUT_TILES,
    LAYOUT_TILE,
    LAYOUT_PAGE_KEYS_TO_UM_HIERARCHY_MAP,
    LAYOUT_PAGE_KEYS,
} from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { LAYOUT_POSITION_DIRECTION } from '@/__new__/features/customerCareSuite/common/ccsLayoutHelper';
import { LayoutData } from '@/__new__/services/portal/layoutPage/http/layoutPage';
import { ICON_TYPES } from '@/common/iconHelper';
import Account from '@/models/Account';

export default Vue.extend({
    name: 'LayoutSection',

    components: {
        AbstractTileWrapper,
        AppInputV3,
        AppButton,
        AppMultiselectV3,
        AppDropdown,
        AppCheckbox,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
    },

    props: {
        sectionTitle: {
            type: String,
            default: '',
        },
        sectionKey: {
            type: String,
            default: '',
        },
        columnNumber: {
            type: Number,
            default: 12,
        },
        rowHeight: {
            type: Number,
            default: 100,
        },
        margin: {
            type: Array,
            default: () => [20, 20],
        },
        sectionLayout: {
            type: Object as PropType<LayoutData>,
            default: null,
        },
        layoutIndex: {
            type: Number,
            default: null,
        },
        isEditModeEnabled: {
            type: Boolean,
            default: false,
        },
        isResizable: {
            type: Boolean,
            default: false,
        },
        verticalCompact: {
            type: Boolean,
            default: false,
        },
        useCssTransforms: {
            type: Boolean,
            default: false,
        },
        readOnlyMode: {
            type: Boolean,
            default: true,
        },
        moveUp: {
            type: Boolean,
            default: false,
        },
        moveDown: {
            type: Boolean,
            default: false,
        },
        page: {
            type: String as PropType<LAYOUT_PAGE_KEYS>,
            default: LAYOUT_PAGE_KEYS.USER,
        },
    },
    data() {
        return {
            layout: [],
            debounceEmit: null,
            BUTTON_TYPES,
            ICON_TYPES,
            LAYOUT_TILES,
            LAYOUT_POSITION_DIRECTION,
            selectedTileType: {},
        };
    },
    computed: {
        availableTiles() {
            return LAYOUT_TILES.filter(
                item =>
                    item.pages?.includes(this.page) && (!item.viewKey || (item.viewKey && isViewEnabled(item.viewKey))),
            );
        },
        currentUserManagerHierarchy() {
            return LAYOUT_PAGE_KEYS_TO_UM_HIERARCHY_MAP.get(this.page);
        },
    },
    watch: {
        layout: {
            handler(val) {
                if (this.isEditModeEnabled && typeof this.debounceEmit === 'function') {
                    this.debounceEmit(val);
                }
            },
            deep: true,
        },
        sectionLayout: {
            handler() {
                this.mapLayout();
            },
            deep: true,
        },
        availableTiles: {
            handler(newVal) {
                if (newVal.length) {
                    this.mapLayout();
                }
            },
            immediate: true,
        },
    },
    created() {
        this.mapLayout();
        this.debounceEmit = debounce(this.emitLayout, 300);
    },
    methods: {
        mapLayout() {
            this.layout = [];
            for (const el of this.sectionLayout.layout) {
                if (
                    this.availableTiles.find(item => {
                        return item.tileKey.toString() === el.componentKey.toString();
                    })
                ) {
                    const elementCorrespondingTile = LAYOUT_TILES.find(
                        (tile: LAYOUT_TILE) => tile.tileKey === el.componentKey,
                    );
                    this.layout.push({
                        i: el.i,
                        x: el.x,
                        y: el.y,
                        size: el.size,
                        w: LAYOUT_TILE_CONFIGS[el.size].w,
                        h: LAYOUT_TILE_CONFIGS[el.size].h,
                        tileTitle: el.tileTitle,
                        componentKey: el.componentKey,
                        component: COMPONENTS[el.componentKey],
                        props: el.props,
                        isTileAvailable: this.arePermissionsExist(elementCorrespondingTile.isTileAvailable),
                    });
                }
            }
        },
        emitLayout(layout) {
            const clearLayout = layout.map(el => ({
                i: el.i,
                x: el.x,
                y: el.y,
                size: el.size,
                tileTitle: el.tileTitle,
                componentKey: el.componentKey,
                props: el.props,
            }));
            this.$emit('emitLayout', {
                layoutIndex: this.layoutIndex,
                sectionName: this.sectionLayout.sectionName,
                isCollapsed: this.sectionLayout.isCollapsed,
                layout: clearLayout,
            });
        },
        emitRemoveSection() {
            this.$emit('removeSection', {
                layoutIndex: this.layoutIndex,
            });
        },
        emitMoveSection(direction) {
            this.$emit('moveSection', {
                layoutIndex: this.layoutIndex,
                direction,
            });
        },
        addTile() {
            // Calculate current max row and index for addition

            const maxRow = Math.max(...this.layout.map((item: any) => item.y), 0);

            const maxIndex = Math.max(...this.layout.map((item: any) => item.i), 0);

            const addedTile = LAYOUT_TILES.find((item: any) => item.tileKey === this.selectedTileType.tileKey);

            // Add a new item. It must have a unique key!
            this.layout.push({
                i: maxIndex + 1,
                x: 0,
                y: maxRow + 1, // puts it at the bottom
                size: addedTile.tileSize,
                w: LAYOUT_TILE_CONFIGS[addedTile.tileSize].w,
                h: LAYOUT_TILE_CONFIGS[addedTile.tileSize].h,
                tileTitle: addedTile.tileDisplayName,
                componentKey: addedTile.tileKey,
                component: COMPONENTS[addedTile.tileKey],
                props: addedTile.props,
                isTileAvailable: addedTile.isTileAvailable,
            });

            this.emitLayout(this.layout);
        },
        removeTile(event) {
            const arrayIndex = this.layout.map(item => item.i).indexOf(event.index);
            this.layout.splice(arrayIndex, 1);

            this.emitLayout(this.layout);
        },
        toggleCollapsed(event) {
            this.sectionLayout.isCollapsed = event;
        },
        arePermissionsExist(permissionsList) {
            // For compatibility purposes
            if (typeof permissionsList === 'boolean') {
                return permissionsList;
            }
            // Check if Account have all the needed permissions to display this tile
            return permissionsList.every(v => Account.storedInstance.permissions.includes(v));
        },
    },
});
