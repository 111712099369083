import HTTP from '@/http';

const templateHelpersRoute = '/ceapi/cep-site-backend/template_helpers';

export function getTemplateHelpers() {
    return HTTP.get(templateHelpersRoute);
}

export default {
    getTemplateHelpers,
};
