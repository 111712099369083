

































































































































































































































// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import SimSwapModal from '@/__new__/features/customerCare/SimSwapModal.vue';
import SuspendServices from '@/__new__/features/customerCareSuite/components/SuspendServices.vue';
import CancelRequest from '@/__new__/features/customerCareSuite/components/CancelRequest.vue';

// Vuex
import Actions from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// http
import userManagementAJAX from '@/__new__/services/dno/user/http/user-management';
import luaErrorCodes from '@/common/luaErrors';
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';

// helper
import {
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_STATE,
    SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
    SUBSCRIBER_CANCEL_REQUEST_ACTIONS,
    CANCEL_TERMINATE_ACTIONS,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import Flag, { FLAG_MAP, FLAG_TYPE, FLAG_VALUE_LABEL_MAP } from '@/__new__/services/dno/user/models/Flag';
import { isEmpty, pick } from 'lodash';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import permissionsService, {
    getOperatorConfigValue,
    isUserAllowed,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';

// sentry
import * as Sentry from '@sentry/vue';

export default {
    name: 'SubscriberDetailsEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppButton,
        AppDialog,
        AppInfoBlock,
        AppToggle,
        SimSwapModal,
        SuspendServices,
        CancelRequest,
    },
    mixins: [supportButtonMixin, entityEditorMixin],
    data() {
        return {
            permissionsService,
            flagsForUpdate: {},
            propertiesForUpdate: {},
            isCancelSubscriptionEnabled:
                permissionsService.userManagementCancelSubscriptionEnabled() &&
                isUserAllowed('UMSubscriberEditSubscriberCancelUndoCancel'),
            isTerminationEnabled:
                permissionsService.userManagementTerminationEnabled() &&
                isUserAllowed('UMSubscriberEditSubscriberTerminateUndoTermination'),
            ICON_TYPES,
            BUTTON_TYPES,
            value: true,
            subscriber: new Subscriber({}),
            entityType: ENTITY_TYPES.USER_MANAGEMENT_SUBSCRIBER,
            simSwapDialogVisible: false,
            isSuspendServicesModalVisible: false,
            suspensionModalAction: '',
            isCancelRequestModalVisible: false,
            cancelRequestModalAction: '',
            iccid: '',
            SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
            SUBSCRIBER_CANCEL_REQUEST_ACTIONS,
            isViewEnabled,
        };
    },
    computed: {
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById']),
        hasFlags() {
            return !isEmpty(this.subscriber.flags);
        },
        isMVNESimSwap() {
            return getOperatorConfigValue('isMVNESimSwap');
        },
        isMVNESuspend() {
            return getOperatorConfigValue('isMVNESuspend');
        },
        isUserAllowedToSimSwap() {
            return this.isMVNESimSwap ? false : isUserAllowed('UMSubscriberEditSubscriberSimSwap');
        },
        enabledCancelTerminateSubscription() {
            if (this.isCancelSubscriptionEnabled || this.isTerminationEnabled) {
                return (
                    this.subscriber.state === SUBSCRIBER_STATE.ACTIVE ||
                    this.subscriber.state === SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION ||
                    this.subscriber.state === SUBSCRIBER_STATE.PORTING_OUT
                );
            }
            return false;
        },
        subscriberInfo() {
            return this.$route.params.id ? this.getSubscriberInfoById(this.$route.params.id) : {};
        },
        isSubscriberSuspended() {
            return this.subscriber?.flags?.[FLAG_TYPE.IS_FRAUD]?.value;
        },
        isSubscriberCanceled() {
            return this.subscriber?.flags?.[FLAG_TYPE.CANCEL_REQUESTED]?.value;
        },
        flagsInfoData() {
            const infoData = [];
            const subscriberFlagsConfig = getOperatorConfigValue('service_config.lf-user.flags.subscriber_flags', []);
            const isFilteringPossible =
                Array.isArray(subscriberFlagsConfig) && !getOperatorConfigValue('isFlagFilteringDisabled');

            const subscriberFlags = isFilteringPossible
                ? pick(this.subscriber?.flags, subscriberFlagsConfig)
                : this.subscriber?.flags;

            if (!isEmpty(subscriberFlags)) {
                const flags = {
                    value: [],
                };

                flags.value = Flag.mapFlagStatus(subscriberFlags);

                if (flags.value.length) {
                    infoData.push(flags);
                }
            }

            return infoData;
        },
        isFraudFlag() {
            return (
                this.subscriber?.flags?.[FLAG_TYPE.IS_FRAUD] || {
                    detailedValue: FLAG_VALUE_LABEL_MAP.get(FLAG_MAP.FALSE),
                    value: false,
                    field: FLAG_TYPE.IS_FRAUD,
                }
            );
        },
        isSubscriberSuspendServiceInProgress() {
            return (
                this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_LOST, FLAG_MAP.TRUE_PENDING) ||
                this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_STOLEN, FLAG_MAP.TRUE_PENDING)
            );
        },
        isSubscriberRestoreServiceInProgress() {
            return (
                this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_LOST, FLAG_MAP.FALSE_PENDING) ||
                this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_STOLEN, FLAG_MAP.FALSE_PENDING)
            );
        },
        isSuspendButtonEnabled() {
            return (
                this.isUserAllowed('UMSubscriberEditSubscriberSuspendResume') &&
                this.isMVNESuspend &&
                (((this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_LOST, FLAG_MAP.FALSE) ||
                    this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_LOST, undefined)) &&
                    (this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_STOLEN, FLAG_MAP.FALSE) ||
                        this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_STOLEN, undefined))) ||
                    this.isSubscriberSuspendServiceInProgress ||
                    this.isSubscriberRestoreServiceInProgress)
            );
        },
        isRestoreButtonEnabled() {
            return (
                this.isUserAllowed('UMSubscriberEditSubscriberSuspendResume') &&
                this.isMVNESuspend &&
                (this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_LOST, FLAG_MAP.TRUE) ||
                    this.checkFlagValue(FLAG_TYPE.SUSPEND_LINE_STOLEN, FLAG_MAP.TRUE))
            );
        },
        isSubscriberCancelRequest() {
            return this.checkFlagValue(FLAG_TYPE.CANCEL_REQUESTED, FLAG_MAP.TRUE);
        },
        isCancelRequestButtonEnabled() {
            return (
                this.isUserAllowed('UMSubscriberEditSubscriberCancelUndoCancelRequest') &&
                !this.isSubscriberCancelRequest
            );
        },
        isUndoCancelRequestButtonEnabled() {
            return (
                this.isUserAllowed('UMSubscriberEditSubscriberCancelUndoCancelRequest') &&
                this.isSubscriberCancelRequest
            );
        },
        suspendServicesLabel() {
            if (this.isSubscriberSuspendServiceInProgress) {
                return this.$i18n.t('customerCare.subscriberTab.suspendInProgress');
            }
            if (this.isSubscriberRestoreServiceInProgress) {
                return this.$i18n.t('customerCare.subscriberTab.resumeInProgress');
            }
            return this.$i18n.t('customerCare.subscriberTab.suspendServices');
        },
    },
    async created() {
        const { id } = this.$route.params;

        await Promise.all([this.getSubscriberInfo(id), this.fetchAndSetICCID()]);

        this.setSubscriber();
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo', 'setSuspendedService']),
        ...mapActions('customerCare', [Actions.SET_CURRENT_SUBSCRIBER_ID]),
        isUserAllowed,
        setSubscriber() {
            this.subscriber = new Subscriber(Subscriber.remapUserFromBe(this.subscriberInfo));
            this[Actions.SET_CURRENT_SUBSCRIBER_ID](this.subscriber.id);
        },
        async onSave() {
            try {
                this.$Progress.start();
                const promises = [];

                if (!isEmpty(this.flagsForUpdate)) {
                    promises.push(
                        userManagementAJAX.updateFlags(
                            this.subscriber.id,
                            USER_MANAGER_HIERARCHY.SUBSCRIBER,
                            this.flagsForUpdate,
                        ),
                    );
                }

                if (!isEmpty(this.propertiesForUpdate)) {
                    promises.push(
                        userManagementAJAX.updateInfo(
                            this.subscriber.id,
                            USER_MANAGER_HIERARCHY.SUBSCRIBER,
                            this.propertiesForUpdate,
                        ),
                    );
                }

                await Promise.all(promises);
                this.$Progress.finish();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successActionMessage', {
                        entityName: this.$i18n.t('generic.subscriber'),
                        action: 'updated',
                    }),
                    type: ALERT_TYPES.success,
                });

                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000);
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            } finally {
                this.$emit('updatedData');
            }
        },
        onCancel() {
            this.$router.go(-1);
        },
        updateProperty(field, value) {
            this.propertiesForUpdate[field] = value;
        },
        updateFlag(field, value) {
            this.flagsForUpdate[field] = value;
        },
        isFlagPending(value) {
            return value === FLAG_MAP.FALSE_PENDING || value === FLAG_MAP.TRUE_PENDING;
        },
        isFlagEditDisabled(flag) {
            return this.isFlagPending(flag.originalValue) || !isUserAllowed('UMSubscriberEditSubscriberToggleFlag');
        },
        getCancelTerminateAction() {
            return this.isCancelSubscriptionEnabled
                ? CANCEL_TERMINATE_ACTIONS.CANCEL
                : CANCEL_TERMINATE_ACTIONS.TERMINATE;
        },
        cancelTerminateSubscriptionAction() {
            this.setSuspendedService({
                subscriberId: this.$route.params.id,
                suspendedService: {
                    type: MODAL_CONTENT.CANCEL_TERMINATE,
                    action: this.getCancelTerminateAction(),
                },
            });
            this.$router.go(-1);
        },
        undoCancelSubscriptionAction() {
            this.setSuspendedService({
                subscriberId: this.$route.params.id,
                suspendedService: {
                    type: MODAL_CONTENT.CANCEL_TERMINATE,
                    action: CANCEL_TERMINATE_ACTIONS.CANCEL_UNDO,
                },
            });
            this.$router.go(-1);
        },
        handleServicesAction(action) {
            this.setSuspendedService({
                subscriberId: this.$route.params.id,
                suspendedService: {
                    type: MODAL_CONTENT.SUSPEND,
                    action,
                },
            });
            this.$router.go(-1);
        },
        getAppropriateAlertMessage(errorMess) {
            if (errorMess.module === 'SIM') {
                switch (errorMess.code) {
                    case luaErrorCodes.IMSI_NOT_FOUND.code:
                        return this.$i18n.t('customerCare.userInformation.imsiCannotBeFoundMessage');
                    default:
                        return this.$i18n.t('customerCare.userInformation.unsuccessfulImsiDisassociationMessage');
                }
            }
            return this.$i18n.t('customerCare.userInformation.unsuccessfulImsiDisassociationMessage');
        },
        async fetchAndSetICCID() {
            try {
                this.$Progress.start();
                const {
                    data: { profile_data: profileData },
                } = await HTTPCustomerCare.getProfileInfo({
                    targetId: this.$route.params.id,
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                });
                this.iccid = profileData?.iccid;
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(e);
            }
        },
        onSimSwapClick() {
            this.simSwapDialogVisible = true;
        },
        onSimSwapModalClose() {
            this.simSwapDialogVisible = false;
            this.fetchAndSetICCID();
        },
        handleServicesSuspension(action) {
            if (action === SUBSCRIBER_SUSPEND_SERVICES_ACTIONS.BAR) {
                this.$alert(this.$i18n.t('customerCare.suspendServices.alerts.servicesSuspendedForSubscriber'), {
                    type: ALERT_TYPES.warning,
                });
            }
            this.isSuspendServicesModalVisible = true;
            this.suspensionModalAction = action;
        },
        handleCancelRequest(action) {
            this.isCancelRequestModalVisible = true;
            this.cancelRequestModalAction = action;
        },
        closeModal(key) {
            this[key] = false;
        },
        checkFlagValue(flag, value) {
            return this.subscriber?.flags?.[flag]?.originalValue === value;
        },
    },
};
