<template>
    <div>
        <div
            class="cards-container"
            :class="{ 'flex-wrap': columnView }"
        >
            <div
                v-for="cardItem in cardsValues"
                :key="cardItem.id"
                :class="[
                    'card-content pointer-none',
                    {
                        selected: isSelected(cardItem),
                        disabled,
                        small,
                        'error-border': requiredError,
                        'flex-shrink-grouped-cards': cardItem.child,
                    },
                ]"
                @click="selectCard(cardItem)"
            >
                <div
                    v-if="cardItem.title"
                    :class="[
                        {
                            header: cardItem.header,
                        },
                    ]"
                >
                    {{ cardItem.title }}
                </div>
                <div
                    v-if="cardItem.child"
                    class="d-flex pointer-all"
                >
                    <div
                        v-for="child in cardItem.child"
                        :key="child.id"
                        :class="[
                            'group-cards',
                            {
                                small,
                                selected: selectedChildIndex === child.id,
                                disabled,
                            },
                        ]"
                        @click="selectChild(child, cardItem.id)"
                    >
                        <div class="truncate-text">
                            <div>{{ child.text }}</div>
                            <slot :name="`groupCardBody${child.id}`" />
                        </div>
                    </div>
                </div>
                <div class="py-2 px-2 pointer-all">
                    <div class="truncate-text">
                        {{ cardItem.text }}
                    </div>
                    <slot :name="`cardBody${cardItem.id}`" />
                </div>
            </div>
        </div>
        <div
            v-if="requiredError"
            class="lf-error-message"
        >
            {{ $i18n.t('generic.validations.fieldIsRequired') }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardListWrapper',
    props: {
        // Card values should be an array of object where required field is id
        // Other content could be passed as a prop or through slots
        cardsValues: {
            type: Array,
            default: () => [],
        },
        columnView: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        // Property which if set to true disables input on all cards
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedCards: {
            type: Array,
            default: () => [],
        },
        // should be true for checkbox
        // and false for radiobutton
        multiselect: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        requiredError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedCardndex: null,
            selectedChildIndex: null,
        };
    },
    watch: {
        value: {
            handler() {
                this.selectCardOninit();
            },
            immediate: true,
        },
    },
    methods: {
        isSelected(card) {
            if (this.multiselect) {
                return this.selectedCards.includes(card);
            }
            return this.selectedCardndex === card.id;
        },
        selectCard(card) {
            if (this.disabled) {
                return;
            }
            if (Object.prototype.hasOwnProperty.call(card, 'child')) {
                this.selectedCardndex = null;
                if (card.child) {
                    const childToEmit = card.child.find(child => child.id === this.selectedChildIndex);
                    this.$emit('input', childToEmit);
                }
            } else {
                this.selectedCardndex = card.id;
                this.selectedChildIndex = null;
                this.$emit('input', card);
            }
        },
        selectChild(child) {
            if (this.disabled) {
                return;
            }
            this.selectedChildIndex = child.id;
        },
        selectCardOninit() {
            if (this.value) {
                this.cardsValues.forEach(card => {
                    if (card.id === this.value.id) {
                        this.selectedCardndex = this.value.id;
                    } else if (Object.prototype.hasOwnProperty.call(card, 'child')) {
                        card.child.forEach(child => {
                            if (child.id === this.value.id) {
                                this.selectedChildIndex = this.value.id;
                            }
                        });
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/_icons.scss';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2.scss';

$icons-path: '~@/assets/icons/';

.pointer-none {
    pointer-events: none;
}

.pointer-all {
    pointer-events: all;
}

.cards-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    .card-content {
        position: relative;
        max-height: 146px;
        flex: 1 1 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background-color: $white;
        text-align: center;
        color: $blue;
        // in order to use rgba color on the border
        background-clip: padding-box;
        border: solid 1px $blue15;
        border-radius: 8px;
        margin-right: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        .icon-card {
            background: $blue15;
            height: 40px;
            width: 40px;
            margin-bottom: 12px;
            border-radius: 50%;
        }

        .header {
            padding: 2px;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $steel;
            background-color: $blue15;
        }

        &.disabled {
            cursor: not-allowed;
            color: $blue;

            // CREATING OPACITY WITH AFTER BLOCK FOR DISABLE INHERITANCE OPACITY
            // FOR TOOLTIP CONTENT IN CARD BLOCK
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: $white;
                opacity: 0.5;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &.flex-shrink-grouped-cards {
            flex: 1 0 50%;
        }

        &.selected {
            background-color: $blue15;

            .header {
                background-color: transparent;
            }

            .icon-card {
                background: $blue;
                fill: $white;
                border-radius: 50%;

                g {
                    fill: $blue;

                    #icon {
                        fill: $white;
                    }

                    #icon-stroke {
                        stroke: $white;
                    }
                }
            }
        }

        &.small {
            max-height: 80px;
            min-height: 60px;
        }

        &.error-border {
            border-color: $red;
        }

        .group-cards {
            min-height: 126px;
            max-height: 146px;
            flex: 2;
            overflow: hidden;
            overflow-wrap: break-word;
            text-align: center;
            color: $blue;
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            &.selected {
                background-color: $blue15;
            }

            &.small {
                max-height: 80px;
                min-height: 60px;
            }

            &:last-child {
                border-left: solid 1px $blue15;
            }

            &.disabled {
                cursor: not-allowed;
                color: $blue;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $white;
                    opacity: 0.5;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// Adding negative margin to compensate for bottom margin from CardComponent
.lf-error-message {
    margin-top: -4px;
}
</style>
