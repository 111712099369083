import { TranslateResult } from 'vue-i18n';
import { DnoResponseBase, Entity } from '@/http';

export interface DocumentTemplate {
    id: string;
    state?: number;
    version?: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
    templateDefinition: string;
}

export interface Document {
    id: string;
    state: number;
    version: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
    payload: object;
    fileName: string;
}

export interface DocumentAsset {
    id: string;
    state?: number;
    version?: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
}

export interface DocumentAssetsResponse<T> extends DnoResponseBase {
    data: {
        assets: Record<string, Entity<T>>;
    };
}

export interface DocumentAssetResponse extends DnoResponseBase {
    data: {
        asset_id?: string;
        signed_url: string;
    };
}

export enum DOCUMENT_GENERATE_TYPES {
    ACCEPTED = 'Accepted',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
}

export type Extension = 'html' | 'json' | 'pdf';

export type DocumentTemplatesUrlData = {
    url: string;
    signed_url: string;
};

export interface DocumentTemplatesResponse extends DnoResponseBase {
    data: {
        status: DOCUMENT_GENERATE_TYPES;
        document_id: string;
        operator_name: string;
        url_data?: DocumentTemplatesUrlData;
    };
}

export type DocumentTemplatesPayload = {
    payload: any;
    fileName: string;
    templateDefinition: string;
};

export interface AddTemplatePayload {
    templateId: string;
    templateDefinition: string;
}

export interface DocumentTemplatesApiResponse<T> extends DnoResponseBase {
    data: {
        template_ids: Record<string, Entity<T>>;
    };
}
