var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.transactionHistoriesFormatted},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var allFilters = ref.allFilters;
var filteredEntities = ref.filteredEntities;
var onFilterAdded = ref.onFilterAdded;
var removeFilter = ref.removeFilter;
var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.account.dataTransfersTable'))+" ")]),_c('div',{staticClass:"d-flex"},[_c('ResponseModalButton',{attrs:{"response":_vm.transactionHistoryApiResponse,"title":_vm.$i18n.t('customerCare.account.dataTransfersTable')}}),_c('div',{staticClass:"position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.columnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":true,"isCustomButtonPassed":true,"isDataLoading":_vm.isDataLoading,"disableBoxShadow":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.columnsData,"tableKey":"account/data-transfers"},scopedSlots:_vm._u([{key:"connectedAccount",fn:function(ref){
var entity = ref.entity;
return [(entity.connectedAccount)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.USER_MANAGEMENT_ACCOUNT_V2,
                                params: {
                                    id: entity.connectedAccount,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.connectedAccount)+" ")])],1):_vm._e()]}},{key:"state",fn:function(ref){
                            var entity = ref.entity;
return [_c('TransactionHistoryStatusIndicator',{attrs:{"status":entity.state}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }