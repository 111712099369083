














































import Vue, { PropType } from 'vue';

// Vuex
import { Getters } from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import OrdersEndTime from '@/__new__/features/charging/bucketWizard/OrdersEndTime.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import Button from '@/common/button/Button';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// Helpers
import { updateOrderEndTime, chargingBucketWizardCreateOrder } from '@/__new__/services/dno/charging/http/bucketWizard';
import { Order } from '@/__new__/services/dno/charging/common/bucketWizardHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import moment from 'moment';

type ChargingSpecificationGroupOption = {
    id: string;
    name: string;
};

export default Vue.extend({
    name: 'OrdersData',
    components: {
        AppCustomerHeader,
        OrdersEndTime,
        AppButton,
        AppMultiselectV3,
        DateTimePicker,
    },
    props: {
        value: {
            type: Object as PropType<Record<string, Order>>,
            required: true,
        },
        targetId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            alertButtons: {
                updateOrderEndTime: new Button({
                    label: this.$i18n.t('generic.update'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,
            newOrderCsg: null as ChargingSpecificationGroupOption | null,
            newOrderEndTime: null as Date | null,
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]),
        infoData() {
            return Object.keys(this.value).map((key: string, index: number) => {
                const order: Order = this.value[key];
                return {
                    title: order.offer_name,
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('customerCare.transactionHistory.offerId'),
                            value: key,
                        },
                        {
                            name: this.$i18n.t('generic.startTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(order.start_time),
                        },
                        {
                            name: this.$i18n.t('generic.endTime'),
                            key: `endTime${index}`,
                        },
                    ],
                };
            });
        },
        getChargingSpecificationGroups(): ChargingSpecificationGroupOption[] {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUPS].map((csg: any) => ({
                id: csg.id,
                name: csg.data.name.en,
            }));
        },
        disableDateStart() {
            return (date: Date) => {
                const restrictions = {
                    from: moment().toDate(),
                };

                return date <= restrictions.from;
            };
        },
        disableNewTestChargingOrderBtn() {
            return !this.newOrderEndTime || !this.newOrderCsg?.id;
        },
    },
    methods: {
        updateOrderEndTime(orderKey: string, { end_time: endTime }: Order): void {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.updateOrderEndTime'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.updateOrderEndTime],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.updateOrderEndTime.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            await updateOrderEndTime(
                                {
                                    [orderKey]: {
                                        end_time: endTime,
                                    },
                                },
                                this.targetId,
                                this.targetType,
                            );
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('charging.bucketWizard.updateOrderEndTimeError'),
                                });
                            },
                        },
                    );
                }
            });
        },
        newTestChargingOrder(): void {
            if (!this.newOrderCsg?.id) {
                return;
            }

            this.$withLoadingSpinner(
                async () => {
                    const endTimeUnix = moment(this.newOrderEndTime).unix();
                    await chargingBucketWizardCreateOrder(
                        this.newOrderCsg.id,
                        endTimeUnix,
                        this.targetId,
                        this.targetType,
                    );
                    this.$emit('newOrder');
                    this.newOrderCsg = null;
                    this.newOrderEndTime = null;
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.bucketWizard.createNewOrderError'),
                        });
                    },
                },
            );
        },
    },
});
