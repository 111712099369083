




// Store
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Actions, { Mutations, Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

import RouteWrapper from '@/components/layout/RouteWrapper.vue';

export default {
    name: 'CustomerCareSuite',
    components: {
        RouteWrapper,
    },
    beforeRouteLeave(to, from, next) {
        if (to.path !== from.path) {
            this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
            });
            this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]('');
        }
        next();
    },
    computed: {
        ...mapGetters(Modules.endUserAuthorization, [Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]),
    },
    created() {
        this[Actions.SET_AUTH_2FA_SUCCESSFUL_STATE]();
    },
    methods: {
        ...mapActions(Modules.endUserAuthorization, [Actions.SET_AUTH_2FA_SUCCESSFUL_STATE]),
        ...mapMutations(Modules.endUserAuthorization, [
            Mutations.SET_AUTH_TOKEN_1FA,
            Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS,
            Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING,
        ]),
    },
};
