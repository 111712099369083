


































import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AdditionalSidebarDeliveries from '@/__new__/features/customerCare/account/AdditionalSidebarDeliveries.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { remapDeliveriesFromBe } from '@/__new__/features/customerCare/common/deliveriesHelper';
import { isUndefined } from 'lodash';
import {
    DELIVERY_STATES_TO_STATUS_NAME_MAP,
    DELIVERY_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/account/common/deliveryStateHelper';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default Vue.extend({
    name: 'DeliveriesTile',
    components: {
        AssociatedUMEntityPicker,
        AbstractTableTile,
        AdditionalSidebarDeliveries,
        EntityStatusIndicator,
    },
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number as USER_MANAGER_HIERARCHY,
        },
    },
    data() {
        return {
            selectedAssociatedAccount: null,

            deliveries: {},
            apiResponse: {},

            showAdditionalSidebar: false,
            selectedDelivery: {},

            // proxy
            DELIVERY_STATES_TO_STATUS_NAME_MAP,
            DELIVERY_STATUS_INDICATOR_MAP,
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        isTileForUserHierarchy() {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER;
        },
        isTileForAccountHierarchy() {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT;
        },
        deliveriesFormatted() {
            return Object.values(this.deliveries);
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.deliveries.deliveryId'),
                    key: 'deliveryId',
                    forbidHideColumn: true,
                    field: 'deliveryId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.deliveries.addressId'),
                    key: 'addressId',
                    field: 'addressId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.deliveries.trackingId'),
                    key: 'trackingId',
                    field: 'trackingId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.deliveriesFormatted.map(entity => entity.state))),
                },
            ];
        },
    },
    watch: {
        userManagerHierarchy: {
            handler(newVal) {
                if (newVal === USER_MANAGER_HIERARCHY.ACCOUNT) {
                    this.selectedAssociatedAccount = {
                        id: this.$route.params.id,
                    };
                }
            },
            immediate: true,
        },
        selectedAssociatedAccount: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    const response = await ordersHTTP.getDeliveryDetails({
                        targetId: this.selectedAssociatedAccount.id,
                        targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    });
                    this.deliveries = remapDeliveriesFromBe(response.data?.data);
                    for (const property in this.deliveries) {
                        if (this.deliveries[property]) {
                            this.deliveries[property].state = this.deliveries[property].status;
                        }
                    }
                    this.apiResponse = response;
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        toggleModal(val) {
            this.showAdditionalSidebar = val;
        },
        selectDelivery(deliveryId) {
            this.selectedDelivery = this.deliveriesFormatted.find(entity => entity.deliveryId === deliveryId);
        },
        isUndefined,
    },
});
