var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-background"},[_c('AppHeader',{attrs:{"pageTitle":_vm.pageTitle}}),_c('Breadcrumbs',{staticClass:"ml-4",attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('div',{staticClass:"section-layout"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.esimOrders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filteredEntities = ref.filteredEntities;
return [_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center position-relative"},[_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.guestOrder.orderDetails'))+" ")])]),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"newDesign":true,"columnsData":_vm.tableOrderColumnsData,"isSearchEnabled":true,"isDataLoading":_vm.isDataLoading,"innerSearchQuery":_vm.searchQueryHasAccessTable,"enableRowStateControls":_vm.enableRowStateControls,"data-test-id":"resend-qr-code-table"},scopedSlots:_vm._u([{key:"total_amount",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" ₱"+_vm._s(entity.total_amount)+" ")]}},{key:"order_creation_time",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision( entity.order_creation_time ))+" ")]}},{key:"customRowButtons",fn:function(ref){
var entity = ref.entity;
return [_c('div',{staticClass:"customer-operate-wrapper"},[_c('IconButton',{attrs:{"icon":_vm.ICON_TYPES.SEND,"label":_vm.$i18n.t('customerCare.guestOrder.resendCode')},on:{"iconClick":function($event){return _vm.displayDialog(entity)}}})],1)]}}],null,true)})],1)]}}])})],1),(_vm.showDialog)?[_c('AppDialogV2',{attrs:{"visible":_vm.showDialog,"title":_vm.$i18n.t('customerCare.guestOrder.resendCode'),"overflowAuto":false,"maxWidth":"47.5rem","contentCustomClass":"overflow-hidden"},on:{"close":_vm.onCancelButtonClicked,"submit":_vm.onContinueButtonClicked}},[_c('div',{},[_c('div',{},[_c('p',[_vm._v(_vm._s(_vm.$i18n.t('customerCare.guestOrder.resendConfirm'))+" \""+_vm._s(_vm.selectedOrder.msisdn)+"\"?")])])])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }