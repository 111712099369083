var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.rolesData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var allFilters = ref.allFilters;
        var filteredEntities = ref.filteredEntities;
        var onFilterAdded = ref.onFilterAdded;
        var removeFilter = ref.removeFilter;
        var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center position-relative"},[_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.userInformation.associatedRoles'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.apiResponse,"title":_vm.$i18n.t('customerCare.userInformation.associatedRoles')}}),_c('FilterTable',{attrs:{"columns":_vm.columnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.columnsData,"isSearchEnabled":true,"innerSearchQuery":_vm.searchQuery,"tableKey":"customer-care-new/associated-roles"},scopedSlots:_vm._u([{key:"targetId",fn:function(ref){
        var entity = ref.entity;
return [(entity.targetId)?_c('div',{staticClass:"link",on:{"click":function($event){_vm.goToRoute(_vm.getBreadcrumbPath(entity.targetTypeId, entity.targetId))}}},[_vm._v(" "+_vm._s(entity.targetId)+" ")]):_c('i',[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }