var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cards-container",class:{ 'flex-wrap': _vm.columnView }},_vm._l((_vm.cardsValues),function(cardItem){return _c('div',{key:cardItem.id,class:[
                'card-content pointer-none',
                {
                    selected: _vm.isSelected(cardItem),
                    disabled: _vm.disabled,
                    small: _vm.small,
                    'error-border': _vm.requiredError,
                    'flex-shrink-grouped-cards': cardItem.child,
                } ],on:{"click":function($event){return _vm.selectCard(cardItem)}}},[(cardItem.title)?_c('div',{class:[
                    {
                        header: cardItem.header,
                    } ]},[_vm._v(" "+_vm._s(cardItem.title)+" ")]):_vm._e(),(cardItem.child)?_c('div',{staticClass:"d-flex pointer-all"},_vm._l((cardItem.child),function(child){return _c('div',{key:child.id,class:[
                        'group-cards',
                        {
                            small: _vm.small,
                            selected: _vm.selectedChildIndex === child.id,
                            disabled: _vm.disabled,
                        } ],on:{"click":function($event){return _vm.selectChild(child, cardItem.id)}}},[_c('div',{staticClass:"truncate-text"},[_c('div',[_vm._v(_vm._s(child.text))]),_vm._t(("groupCardBody" + (child.id)))],2)])}),0):_vm._e(),_c('div',{staticClass:"py-2 px-2 pointer-all"},[_c('div',{staticClass:"truncate-text"},[_vm._v(" "+_vm._s(cardItem.text)+" ")]),_vm._t(("cardBody" + (cardItem.id)))],2)])}),0),(_vm.requiredError)?_c('div',{staticClass:"lf-error-message"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.validations.fieldIsRequired'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }