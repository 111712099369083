








































































































// generic
import Vue from 'vue';
import RouteNames from '@/router/routeNames';
import { Modules } from '@/store/store';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { formatDuration, formatDataAmount, unitTypes } from '@/common/formatting';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { entityStateReverseMap, StateActionsMapping } from '@/common/entityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { languageMap } from '@/common/locale/language';
import {
    getMultiLangFieldValueByLocale,
    getEntityConsumers,
    formatMutationDialogEntities,
} from '@/common/entities/entityHelper';
import { upperFirst } from 'lodash';
import { RatingGroup, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import { deleteRatingGroupDraft, updateRatingGroupState } from '@/__new__/services/dno/charging/http/ratingGroups';

export default Vue.extend({
    name: 'RatingGroups',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        MutationDialog,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ENTITY_TYPES,
            ICON_TYPES,
            BUTTON_TYPES,
            entityType: ENTITY_TYPES.RATING_GROUP as string,
            editorRoute: RouteNames.CHARGING_RATING_GROUP_EDITOR as string,
            selectedEntity: null as RatingGroup | null,
            searchQueryForTable: '' as string,
            selectedLanguage: '' as string,
            isDataLoading: false as boolean,
            isOverviewEnabled: false as boolean,
            isMutationModalVisible: false as boolean,
            mutationDialogDataType: '' as string,
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_NOT_DELETED_RATING_GROUPS,
            Getters.GET_RATING_GROUPS_API_RESPONSE,
            Getters.GET_RATING_GROUP_BY_ID,
        ]),
        isEditEnabled(): boolean {
            return permissionsService.chargingRatingGroupsEnabled() && isUserAllowed('RatingGroupsReadWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_RATING_GROUPS_API_RESPONSE];
        },
        getRatingGroupsApiPayload(): RatingGroup[] {
            if (!this[Getters.GET_NOT_DELETED_RATING_GROUPS]) return [];
            return this[Getters.GET_NOT_DELETED_RATING_GROUPS];
        },
        ratingGroups(): RatingGroup[] {
            return this.getRatingGroupsApiPayload.map((rg: RatingGroup) => ({
                ...rg,
                status: this.entityState(rg.state),
            }));
        },
        sortedFilteredRatingGroups(): RatingGroup[] {
            return this.filteredEntitiesMixin(this.ratingGroups);
        },
        selectedEntityId(): string | null {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedEntity.name,
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity.number,
                            label: this.$i18n.t('generic.number'),
                        },
                        {
                            value: this.selectedEntity.priority,
                            label: this.$i18n.t('generic.priority'),
                        },
                        {
                            value: formatDataAmount(this.selectedEntity.quotaAmount),
                            label: this.$i18n.t('generic.quota'),
                        },
                        {
                            value: this.selectedEntity.thresholdPercentage,
                            label: this.$i18n.t('charging.policyCounters.thresholdPercentage'),
                        },
                        {
                            value: formatDuration(this.selectedEntity.validityTime),
                            label: this.$i18n.t('productCatalog.ratingGroups.validityTime'),
                        },
                        {
                            value: formatDuration(this.selectedEntity.holdingTime),
                            label: this.$i18n.t('productCatalog.ratingGroups.holdingTime'),
                        },
                        {
                            value: this.selectedEntity.enableDynamicValidityTime,
                            label: this.$i18n.t('charging.ratingGroups.enableDynamicValidityTime'),
                        },
                    ],
                },
                this.ratingGroupInUseByUpperLevelEntities,
            ];
        },
        ratingGroupInUseByUpperLevelEntities(): DetailsSections | object {
            const { [ENTITY_TYPES.CONDITION_PARAMETERS]: inUseByConditionParameters = [] } = this.upperEntities();

            if (!inUseByConditionParameters.length) {
                return {};
            }

            const data: DetailsSections = {
                name: `${upperFirst(this.$i18n.t('productCatalog.entities.plural.ratingGroups'))} ${this.$i18n.t(
                    'generic.usedBy',
                )}`,
                properties: [],
            };

            if (inUseByConditionParameters.length) {
                data.properties.push({
                    label: `${inUseByConditionParameters.length} ${this.$i18n.t(
                        'charging.entities.plural.conditionParameters',
                    )}`,
                    value: inUseByConditionParameters.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.CONDITION_PARAMETERS,
                });
            }

            return data;
        },
        allowedActions(): number[] {
            return StateActionsMapping[this.selectedEntity.state];
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    mapper: (entity: RatingGroup) => getMultiLangFieldValueByLocale(entity.name),
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.number'),
                    key: 'number',
                    field: 'number',
                    mapper: (entity: RatingGroup) => entity.number,
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('productCatalog.ratingGroups.quotaAmount'),
                    key: 'amount',
                    field: 'quotaAmount',
                    type: 'data',
                    mapper: (entity: RatingGroup) => (entity.quotaAmount ? formatDataAmount(entity.quotaAmount) : ''),
                    filterType: tableColumnType.NUMBER_UNITS,
                    unitType: unitTypes.DATA,
                },
                {
                    name: this.$i18n.t('productCatalog.offers.table.updatedTime'),
                    key: 'updateTime',
                    field: 'update_time',
                    sortBy: (entity: RatingGroup) => entity.updateTime,
                    mapper: (entity: RatingGroup) =>
                        entity?.isDraft ? this.$localeLibrary.getFormattedDate(entity.update_time) : entity.updateTime,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredRatingGroups.map((e: RatingGroup) => e.entityStatusCodeLabel)),
                    ],
                },
            ];
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_RATING_GROUPS]),
        ...mapActions(Modules.charging, [Actions.REQUEST_CONDITION_PARAMETERS]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    await this.fetchEntitiesList();

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        onSelectEntity(ratingGroupId: string): void {
            this.selectedEntity =
                this.sortedFilteredRatingGroups.find((rg: RatingGroup) => rg.id === ratingGroupId) || null;
            this.isOverviewEnabled = true;
        },
        create(): void {
            this.$router.push({
                name: RouteNames.CHARGING_RATING_GROUP_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s: number): string {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        async fetchEntitiesList() {
            try {
                await Promise.all([
                    this[Actions.REQUEST_RATING_GROUPS](ENTITY_TYPES.RATING_GROUP),
                    this[Actions.REQUEST_CONDITION_PARAMETERS](),
                ]);
            } catch (error: any) {
                this.$eventBus.$emit('showAlert', {
                    message: error.message,
                });
            } finally {
                this.isDataLoading = false;
            }
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        showMutationDialog(type: string): void {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value: string): void {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getAffectedEntities(mutationDialogDataType: string): any {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        upperEntities(entityNumber: number = this.selectedEntity?.number): any {
            return getEntityConsumers(entityNumber, this.entityType);
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version, number } = this[Getters.GET_RATING_GROUP_BY_ID](entityId);
            const { [ENTITY_TYPES.CONDITION_PARAMETERS]: inUseByConditionParameters = [] } = this.upperEntities(number);

            this.showDeleteAlert(entityId, inUseByConditionParameters.length, state, version, [
                updateRatingGroupState,
                deleteRatingGroupDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
