






























import Vue, { PropType } from 'vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';

export default Vue.extend({
    name: 'AccessListTile',
    components: {
        AbstractTableTile,
    },
    props: {
        /**
         * Indicates the type of entity represented by `this.$route.params.id`
         */
        userManagerHierarchy: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            columnsData: [
                {
                    name: this.$i18n.t('customerCare.userId'),
                    key: 'targetId',
                    field: 'targetId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'roleName',
                    field: 'roleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ] as TableColumn[],
            entities: [] as AccessRole[],
            apiResponse: {} as object,
        };
    },
    computed: {
        id() {
            return this.$route.params.id || '';
        },
    },
    watch: {
        userManagerHierarchy: {
            immediate: true,
            async handler() {
                await this.fetchTileData();
            },
        },
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.apiResponse = await userManagementHTTP.getAccessList(this.id, this.userManagerHierarchy);
                    this.entities =
                        this.apiResponse.data.access_list?.map(role => AccessRole.remapFromAccessList(role)) || [];
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.apiResponse = error;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        goToUserPage(id: string) {
            this.$router.push({
                name: RouteNames.CCS_USER_PAGE,
                params: {
                    id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
    },
});
