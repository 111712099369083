<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="campaign-overview">
        <!-- Delivery section -->
        <div class="section pt-4 mt-1 ml-0">
            <div class="section-header d-flex justify-content-between">
                <span>Delivery</span>
                <span
                    v-if="editableSteps"
                    class="img"
                    @click="$emit('goToStep', 0)"
                />
            </div>

            <div class="gray-header">Delivery Method:</div>
            <div class="mt-2 mb-0 font-weight-bold text">
                {{ shortDeliveryMessage }}
            </div>

            <hr class="small-margin" />

            <div class="mt-2 text">
                <div
                    v-show="triggerEvents.length"
                    class="property-row"
                >
                    <FiltersRenderer
                        :popupTitle="campaign.name"
                        :filters="triggerEvents"
                        :conditionDefinitionsById="conditionDefinitionsById"
                        :entityType="EntityType.Trigger"
                        topLevelCombinator="or"
                        :title="$t('generic.triggers')"
                    />
                </div>

                <div class="property-row">
                    <div class="property-name">Launch:</div>
                    <div class="property-values">
                        <div v-html="launchText" />
                    </div>
                </div>

                <div class="property-row">
                    <div class="property-name">Duration:</div>
                    <div class="property-values">
                        <div v-html="campaignDurationText" />
                        <div
                            v-if="isActionBasedDelivery && campaign.delivery.userReEligibleDays"
                            v-html="userReEligibleText"
                        />
                    </div>
                </div>
                <div class="property-row">
                    <div class="property-name">{{ $t('campaigns.priority.campaignPriority') }}:</div>
                    <div class="property-values">
                        <div>{{ campaign.selectedPriority }}</div>
                    </div>
                </div>

                <div class="property-row">
                    <div class="property-name">Settings:</div>
                    <div class="property-values">
                        <div v-if="campaign.delivery.ignoreGlobalMsgLimit">
                            {{ $i18n.t('campaigns.ignoreGlobalMsgLimit') }}
                        </div>

                        <div v-if="campaign.delivery.dryRun">
                            {{ $i18n.t('campaigns.dryRun') }}
                        </div>

                        <div v-if="campaign.delivery.respectQuietHoursRules">
                            {{ $i18n.t('campaigns.respectQuietHoursRules') }}
                        </div>
                        <div v-if="campaign.delivery.sendAfterQuietHoursEnd">
                            {{ $i18n.t('campaigns.sendAfterQuietHoursEnd') }}
                        </div>
                    </div>
                </div>
            </div>

            <hr class="fat-separation-line" />
        </div>

        <!-- Compose summary section -->
        <div class="section mt-0">
            <div class="section-header pb-3 d-flex justify-content-between">
                <span>Compose summary</span>
                <span
                    v-if="editableSteps"
                    class="img"
                    @click="$emit('goToStep', 1)"
                />
            </div>

            <div class="message-summary gray-header mt-0 d-flex justify-content-between">
                <div>MESSAGE NAME:</div>
                <div class="d-flex">
                    <div class="pr-3">CHANNEL:</div>
                    <div class="channels d-flex selected">
                        <span :class="['channel', 'icon', message.type]" />
                    </div>
                </div>
            </div>
            <div class="mt-2 mb-0 font-weight-bold text">
                {{ message.name }}
            </div>

            <hr class="small-margin" />

            <div class="mt-2 text">
                <template v-if="message.type === MessageTypes.PUSH">
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.pushTitle') }}:</div>
                        <div class="property-values">
                            {{ message.title }}
                        </div>
                    </div>
                </template>

                <div v-show="message.type === MessageTypes.EMAIL">
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.subject') }}:</div>
                        <div class="property-values">
                            {{ message.title }}
                        </div>
                    </div>
                    <div
                        v-if="campaignFeatures.backofficeTargetsEnabled"
                        class="property-row"
                    >
                        <div class="property-name text-capitalize">{{ $i18n.t('generic.to') }}:</div>
                        <div class="property-values">
                            {{ backofficeTargetsString }}
                        </div>
                    </div>
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.sender') }}:</div>
                        <div class="property-values">
                            {{ message.sender }}
                        </div>
                    </div>
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.BCC') }}:</div>
                        <div class="property-values">
                            {{ bccEmailString }}
                        </div>
                    </div>
                </div>

                <div v-show="message.type === MessageTypes.OTT">
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('generic.title') }}:</div>
                        <div class="property-values">
                            {{ message.title }}
                        </div>
                    </div>
                </div>

                <div v-show="message.type === MessageTypes.SMS">
                    <div
                        v-if="campaignFeatures.backofficeTargetsEnabled"
                        class="property-row"
                    >
                        <div class="property-name text-capitalize">{{ $i18n.t('generic.to') }}:</div>
                        <div class="property-values">
                            {{ backofficeTargetsString }}
                        </div>
                    </div>
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.smsShowSenderAs') }}:</div>
                        <div class="property-values">
                            {{ message.smsSenderText }}
                        </div>
                    </div>
                </div>

                <div class="property-row align-items-center">
                    <div class="property-name">{{ $i18n.t('campaigns.message') }}:</div>
                    <iframe
                        class="message-iframe"
                        :srcdoc="message.text"
                        frameBorder="0"
                        @load="fixIframeHeight"
                    />
                </div>

                <template v-if="message.type === MessageTypes.PUSH">
                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.pushOpenAction') }}:</div>
                        <div class="property-values">
                            <div>
                                {{ NotificationButtonActionsToUI[message.openAction] }}
                            </div>
                            <div>
                                <a
                                    :href="message.deepLink"
                                    target="_blank"
                                    class="link"
                                >
                                    {{ message.deepLink }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <template v-if="isInboxViewChecked">
                        <div class="property-row">
                            <div class="property-name">
                                {{ $i18n.t('campaigns.inboxTitle') }}
                            </div>
                            <div class="property-values">
                                {{ message.enhancedViewTitle }}
                            </div>
                        </div>

                        <div class="property-row">
                            <div class="property-name">{{ $i18n.t('campaigns.inboxMessage') }}:</div>
                            <div
                                class="property-values"
                                v-html="message.enhancedViewMessage"
                            />
                        </div>
                        <div class="property-row">
                            <div class="property-name">{{ $i18n.t('campaigns.inboxShortMessage') }}:</div>
                            <div class="property-values">
                                {{ message.enhancedViewMessagePushSummary }}
                            </div>
                        </div>
                    </template>

                    <div class="property-row">
                        <div class="property-name">{{ $i18n.t('campaigns.carrierAppsTitle') }}:</div>
                        <div class="property-values">
                            <div
                                v-for="app in selectedCarrierApps"
                                :key="app"
                            >
                                {{ app }}
                            </div>
                        </div>
                    </div>
                </template>

                <div v-show="message.type === MessageTypes.OTT">
                    <div v-if="isInboxViewCheckedOtt">
                        <div class="property-row">
                            <div class="property-name">{{ $i18n.t('campaigns.inboxMessage') }}:</div>
                            <div
                                class="property-values"
                                v-html="message.enhancedViewMessageOtt"
                            />
                        </div>
                        <div class="property-row">
                            <div class="property-name">{{ $i18n.t('campaigns.inboxMessageExpiry') }}:</div>
                            <div class="property-values">
                                {{ messageExpiryText }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Target Users section -->
        <div
            v-if="isTargetStepEnabled"
            class="section mb-5 mt-0 ml-0"
        >
            <div
                v-if="campaign.delivery.sendToUnregisteredUsers"
                class="mt-2 property-row"
            >
                <strong>{{ $i18n.t('campaigns.allowSendingUnregistered') }}</strong>
            </div>

            <CohortExpressionOverview
                :title="$t('campaigns.targetAudience')"
                :popupTitle="campaign.name"
                :titleButtonLabel="$t('generic.seeDetails')"
                :groups="cohortExpressionOverviewGroups"
                class="mt-4"
            >
                <template #underTitle>
                    <div
                        v-if="campaign.delivery.advancedTargeting"
                        class="d-flex justify-content-between align-items-center mb-3 mt-1"
                    >
                        <span class="heading-xs">{{ $t('campaigns.targetingStrategy') }}</span>
                        <span class="body-xs">{{ campaign.delivery.advancedTargeting }}</span>
                    </div>
                </template>
            </CohortExpressionOverview>
        </div>
    </div>
</template>

<script>
// components
import FiltersRenderer, { EntityType } from '@/components/partials/filters/FiltersRenderer.vue';
import CohortExpressionOverview from '@/components/partials/CohortExpressionOverview.vue';
// helpers
import { get, omit } from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters, State } from '@/store/mutation-types';
import filters from '@/common/filters';
import {
    ExpiryLabel,
    NotificationButtonActions,
    NotificationButtonActionsToUI,
} from '@/__new__/services/dno/campaigns/models/Message';
import { DaysOfWeekNames } from '@/__new__/features/campaigns/common/DaysOfWeek';
import {
    CampaignTargetType,
    CohortList,
    DeliveryTypeNames,
    Steps,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import { RepeatTypes } from '@/__new__/services/dno/campaigns/models/Repeat';
import Delay, { ChronoUnit } from '@/__new__/services/dno/campaigns/models/Delay';
import localeLibrary from '@/common/locale/localeLibrary';
import { MessageTypes } from '@/common/CampaignMessage';
import { constructTimeString } from '@/common/formatting';
import { LOGICAL_OPERATORS } from '@/common/segments';

export default {
    name: 'CampaignSummary',
    filters,
    components: {
        FiltersRenderer,
        CohortExpressionOverview,
    },
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        triggerEvents: {
            type: Array,
            default: () => [],
        },
        editableSteps: {
            type: Boolean,
            default: false,
        },
        campaignFeatures: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            CohortList,
            EntityType,
            MessageTypes,
            NotificationButtonActionsToUI,
            NotificationButtonActions,
        };
    },
    computed: {
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
            segmentsById: Getters.CACHED_SEGMENTS_BY_ID,
        }),
        cohortExpressionOverviewGroups() {
            return [this.cohortListSegments(CohortList.WHITELIST), this.cohortListSegments(CohortList.BLACKLIST)].map(
                (group, index) => {
                    const allUsersGroup = this.isAllUsersTarget && index === 0;
                    let subtitle = '';
                    let rowsCombinator = LOGICAL_OPERATORS.or;

                    if (
                        (index === 0 && this.isUserInEveryCohort(CohortList.WHITELIST)) ||
                        (index === 1 && this.isUserInEveryCohort(CohortList.BLACKLIST))
                    ) {
                        subtitle = this.$t('editors.identifiersInEverySegment');
                        rowsCombinator = LOGICAL_OPERATORS.and;
                    }
                    if (allUsersGroup) {
                        subtitle = this.$t('campaigns.applicableto.AllRegistered.label');
                    }
                    return {
                        title: this.$t(index === 0 ? 'editors.applicableTo' : 'editors.notApplicableTo'),
                        subtitle,
                        rowsCombinator,
                        rows: allUsersGroup
                            ? []
                            : group.map(cohortId => ({
                                  name: this.segmentsById[cohortId]?.name,
                                  id: cohortId,
                              })),
                    };
                },
            );
        },
        segmentNameById() {
            return id => get(this.segmentsById, [id, 'name'], `${id} ${this.$t('generic.deleted')}`);
        },
        isTargetStepEnabled() {
            return this.campaignFeatures.steps.enabled.includes(Steps.targetUsers);
        },
        targetType() {
            return Object.keys(this.campaign.target)[0];
        },
        message() {
            return Object.values(this.campaign.messages[0])[0];
        },
        conditionDefinitionsById() {
            return this.$store.state[Modules.triggers][State.TRIGGER_DEFINITIONS_BY_ID];
        },
        isInboxViewChecked() {
            return !!(this.message.enhancedViewTitle || this.message.enhancedViewMessage);
        },
        isInboxViewCheckedOtt() {
            return !!this.message.enhancedViewMessageOtt;
        },
        bccEmailString() {
            return (this.message.bccEmails || []).join(', ');
        },
        backofficeTargetsString() {
            return (this.message.backOfficeTargets || []).join(', ');
        },
        selectedCarrierApps() {
            const apps = this.$store.state[Modules.campaigns][State.CAMPAIGN_CARRIER_APPS];
            if (this.campaign.delivery.carrierAppIds.length && apps.length) {
                return this.campaign.delivery.carrierAppIds.map(id => {
                    const carrAppsObj = apps.find(app => app.id === id);
                    return `${carrAppsObj.label} (${carrAppsObj.platform.label})`;
                });
            }
            return [];
        },
        isScheduledDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.Scheduled;
        },
        isActionBasedDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.ActionBased;
        },
        isApiTriggeredDelivery() {
            return this.campaign.delivery.type === DeliveryTypeNames.ApiTriggered;
        },
        isLaunchedImmediately() {
            return this.campaign.delivery.immediate;
        },
        cohortList() {
            return list => get(this.campaign.target, [this.targetType, list], {});
        },
        cohortListSegments() {
            return list => Object.values(omit(this.cohortList(list), 'logical_operator')).flat();
        },
        isUserInEveryCohort() {
            return list => get(this.cohortList(list), 'logical_operator', '') === LOGICAL_OPERATORS.and;
        },
        isAllUsersTarget() {
            return (
                this.targetType === null ||
                this.targetType === CampaignTargetType.AllRegistered ||
                (this.targetType === CampaignTargetType.CohortTarget &&
                    this.cohortListSegments(CohortList.WHITELIST).length < 1)
            );
        },
        campaignDurationText() {
            let startString = '';
            let endString = '';
            const startDate =
                this.campaign.startDate && moment(this.campaign.startDate).format(localeLibrary.getDateFormat());
            startString = `Start time ${startDate.bold()} at ${this.campaign.startTimeString.bold()}`;
            if (this.campaign.hasEndTime) {
                const endDate = moment(this.campaign.endDate).format(localeLibrary.getDateFormat());
                endString = `, end time ${endDate.bold()} at ${this.campaign.endTimeString.bold()}`;
            }
            return startString + endString;
        },
        shortDeliveryMessage() {
            switch (this.campaign.delivery.type) {
                case DeliveryTypeNames.Scheduled:
                    return 'Scheduled Delivery';
                case DeliveryTypeNames.ActionBased:
                    return 'Action-Based Delivery';
                case DeliveryTypeNames.ApiTriggered:
                    return 'Api Triggered Delivery';
                default:
                    return '';
            }
        },
        launchText() {
            if (this.isActionBasedDelivery) {
                if (this.isLaunchedImmediately || this.campaign.delivery.delay.amount === 0) {
                    return this.$i18n.t('campaigns.actionBasedMessageSentImmediately');
                }
                return [
                    'The first message will be sent',
                    new Delay(this.campaign.delivery.delay.amount, this.campaign.delivery.delay.unit).toString().bold(),
                    'after user produces the trigger',
                ].join(' ');
            }
            if (!this.isLaunchedImmediately) {
                return `Recurring ${this.repeatPeriodText} ${
                    this.campaign.delivery.useLocalTimezone ? 'in users’ local time zones' : ''
                }`;
            }

            return [
                `At exact time on ${moment(this.campaign.startDate).format(localeLibrary.getDateFormat()).bold()}`,
                `at ${this.campaign.startTimeString.bold()}`,
                this.campaign.delivery.useLocalTimezone ? 'in users’ local time zones' : '',
            ].join(' ');
        },
        repeatPeriodText() {
            const { repeat } = this.campaign.delivery;
            let text = repeat.type.toLowerCase().bold();

            if (repeat.type === RepeatTypes.monthly) {
                if (repeat.lastDayOfMonth) {
                    const lastDayOfMonth = 'last day of the month';
                    text += ` on ${lastDayOfMonth.bold()}`;
                } else {
                    text += ` on ${filters.ordinal(repeat.dayOfMonth).bold()}`;
                }
            }

            if (repeat.type === RepeatTypes.weekly) {
                text += ` on ${DaysOfWeekNames[repeat.dayOfWeek].bold()}`;
            }

            if (repeat.every !== null && repeat.type !== RepeatTypes.monthly) {
                text += [
                    ' every ',
                    repeat.type === RepeatTypes.daily ? new Delay(repeat.every, ChronoUnit.day).toString().bold() : '',
                    repeat.type === RepeatTypes.weekly ? new Delay(repeat.every, 'weeks').toString().bold() : '',
                ].join('');
            }

            return `${text} at ${constructTimeString(repeat.hours, repeat.minutes).bold()}`;
        },
        messageExpiryText() {
            return this.message.enhancedOttExpiryTimeDays === null
                ? ExpiryLabel.NoExpiry
                : `${this.message.enhancedOttExpiryTimeDays} ${this.$i18n.t('generic.days')}`;
        },
        userReEligibleText() {
            const { userReEligibleInDays } = this.campaign.delivery;
            return [
                'User will be re-eligible for this campaign again in',
                userReEligibleInDays.toString().bold(),
                this.$options.filters.plural('day', userReEligibleInDays).bold(),
            ].join(' ');
        },
    },
    created() {
        if (Object.values(this.conditionDefinitionsById).length < 1) {
            this.$store.dispatch(`${Modules.triggers}/${Actions.LOAD_TRIGGER_DEFINITIONS}`);
        }
        if (this.segments.length < 1) {
            this.$store.dispatch(`${Modules.segments}/${Actions.FETCH_SEGMENTS}`);
        }
    },
    methods: {
        fixIframeHeight(e) {
            const iframe = e.target;
            iframe.contentWindow.document.body.style.margin = 0;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_icons.scss';
@import '~@/assets/scss/_colors.scss';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_mixins.scss';

$icon-path: '~@/assets/icons/';
.message-iframe {
    min-height: 3rem;
}
hr {
    border-top: 1px solid $dirty-white;

    &.fat-separation-line {
        border-top-width: 10px;
    }
}

.campaign-overview {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 14px;
    color: $gray60;

    .message-summary {
        line-height: 40px;

        .channels {
            .channel {
                width: 40px;
                height: 40px;
                border: 1px solid $dirty-white;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @include messageIcons($icon-path, 24px);
        }
    }

    .section {
        width: 100%;
        max-width: 52rem;

        .section-header {
            display: flex;
            align-items: center;

            padding-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
            color: $navy;

            span.img {
                width: 22px;
                height: 22px;
                margin-right: 10px;

                &:before {
                    content: url($icon-path + $icon-edit);
                    opacity: 0.8;
                }

                &:hover:before {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }

    .text {
        line-height: 26px;
    }

    .gray-header {
        margin-top: 4px;

        font-size: 13px;
        color: $gray30;
        font-weight: bold;
        text-transform: uppercase;
    }

    .property-row {
        display: flex;
        align-items: baseline;
        margin-top: 4px;

        .property-name {
            color: $gray90;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.2;
            min-width: 7rem;
            max-width: 7rem;
        }
    }
}
</style>
