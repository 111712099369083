import { map, groupBy, mapValues, pick, sortBy, reduce } from 'lodash';
import { LOGICAL_OPERATORS } from '@/common/segments';
import { SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';
import i18n from '@/i18n';

export const cohortExpressionsToJson = cohortsObj => {
    const applicable = cohortsObj.applicableCohorts;
    const notApplicable = cohortsObj.notApplicableCohorts;
    const everyApplicable = cohortsObj.everyApplicableCohort;
    const everyNotApplicable = cohortsObj.everyNotApplicableCohort;
    const applicableGroups = mapValues(groupBy(applicable, 'groupType'), group => group.map(item => item.id));
    const notApplicableGroups = mapValues(groupBy(notApplicable, 'groupType'), group => group.map(item => item.id));
    return {
        whitelist: {
            logical_operator: everyApplicable ? LOGICAL_OPERATORS.and : LOGICAL_OPERATORS.or,
            ...applicableGroups,
        },
        blacklist: {
            logical_operator: everyNotApplicable ? LOGICAL_OPERATORS.and : LOGICAL_OPERATORS.or,
            ...notApplicableGroups,
        },
    };
};

export const simpleCohortExpressionFromJson = cohortExpression => {
    const result = {};
    const arrayCohortsAllType = Object.values(SEGMENT_ID_TYPES_REVERSE);

    // initing as empty array for fallback
    const applicableCohorts = [];
    const notApplicableCohorts = [];

    // picking applicable entities from the API response
    const applicableEntries = Object.entries(pick(cohortExpression.whitelist, arrayCohortsAllType));

    // parsing and putting them into flag that is used in app
    applicableEntries.forEach(([groupType, ids]) => {
        ids.forEach(id => {
            applicableCohorts.push({
                groupType,
                id,
            });
        });
    });

    // picking not applicable entities from the API response
    const notApplicableEntries = Object.entries(pick(cohortExpression.blacklist, arrayCohortsAllType));

    // parsing and putting them into flag that is used in app
    notApplicableEntries.forEach(([groupType, ids]) => {
        ids.forEach(id => {
            notApplicableCohorts.push({
                groupType,
                id,
            });
        });
    });

    result.applicableCohorts = applicableCohorts;

    result.notApplicableCohorts = notApplicableCohorts;

    /* eslint-disable camelcase */
    result.everyApplicableCohort = cohortExpression?.whitelist?.logical_operator === LOGICAL_OPERATORS.and || false;

    result.everyNotApplicableCohort = cohortExpression?.blacklist?.logical_operator === LOGICAL_OPERATORS.and || false;

    return result;
};

const mapCohortsForDropdown = (cohortSimplified, arrayCohortsAll) =>
    arrayCohortsAll.find(cohort => cohort.id === cohortSimplified.id) || {
        id: cohortSimplified.id,
        name: `${cohortSimplified.id} ${i18n.t('generic.deleted')}`,
        groupType: cohortSimplified.groupType,
    };

export const mapCohortExpressionsFromJson = (cohortsObj, arrayCohortsAll) => {
    cohortsObj.applicableCohorts = cohortsObj.applicableCohorts.map(cohortSimplified =>
        mapCohortsForDropdown(cohortSimplified, arrayCohortsAll),
    );
    cohortsObj.notApplicableCohorts = cohortsObj.notApplicableCohorts.map(cohortSimplified =>
        mapCohortsForDropdown(cohortSimplified, arrayCohortsAll),
    );
};

export const cohortExpressionsFromJson = (cohortExpression, arrayCohortsAll) => {
    const cohortsObj = simpleCohortExpressionFromJson(cohortExpression);
    mapCohortExpressionsFromJson(cohortsObj, arrayCohortsAll);
    return cohortsObj;
};

function createEmptyCohortExpressionObject() {
    return {
        applicableCohorts: [],
        notApplicableCohorts: [],
        everyApplicableCohort: false,
        everyNotApplicableCohort: false,
    };
}

export function createEmptyCohortExpressionForSink() {
    return {
        cohortsObj: createEmptyCohortExpressionObject(),
        entryType: {
            id: '',
            label: '',
        },
        identifierField: 'id_value',
    };
}

export function createEmptyCohortExpressionsForSink(eventIds) {
    return reduce(
        eventIds,
        (acc, id) => {
            acc[id] = createEmptyCohortExpressionForSink();
            return acc;
        },
        {},
    );
}

export const groupSegments = segments => {
    const groups = groupBy(segments, segment => segment.segmentIdType);
    return map(groups, (array, segmentIdType) => ({
        groupName: `${SEGMENT_ID_TYPES_REVERSE[segmentIdType]} Segments`,
        groupValues: sortBy(
            array.map(segment => ({ ...segment, groupType: SEGMENT_ID_TYPES_REVERSE[segmentIdType] })),
            item => item.name,
        ),
    }));
};

export const isNonEmptyCohortExpression = cohortsObj =>
    cohortsObj &&
    ((cohortsObj.applicableCohorts && cohortsObj.applicableCohorts.length > 0) ||
        (cohortsObj.notApplicableCohorts && cohortsObj.notApplicableCohorts.length > 0));
