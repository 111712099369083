import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import parseBearer from '@/__new__/services/dno/charging/common/pcConditionsParser/bearerParser';
import apnParser from '@/__new__/services/dno/charging/common/pcConditionsParser/apnParser';
import specialNumberParser from '@/__new__/services/dno/charging/common/pcConditionsParser/specialNumberParser';
import trafficTypeParser from '@/__new__/services/dno/charging/common/pcConditionsParser/trafficTypeParser';
import ratingGroupParser from '@/__new__/services/dno/charging/common/pcConditionsParser/ratingGroupParser';
import zoneParser from '@/__new__/services/dno/charging/common/pcConditionsParser/zoneParser';
import scheduleParser from '@/__new__/services/dno/charging/common/pcConditionsParser/scheduleParser';
import timeInCallParser from '@/__new__/services/dno/charging/common/pcConditionsParser/timeInCallParser';
import networkSliceIdentifierParser from '@/__new__/services/dno/charging/common/pcConditionsParser/networkSliceIdentifierParser';
import serviceTypeParser from '@/__new__/services/dno/charging/common/pcConditionsParser/serviceTypeParser';
import otherPartyRNParser from '@/__new__/services/dno/charging/common/pcConditionsParser/otherPartyRNParser';
import flagParser from '@/__new__/services/dno/charging/common/pcConditionsParser/flagParser';
import otherPartyNumberLengthParser from '@/__new__/services/dno/charging/common/pcConditionsParser/otherPartyNumberLengthParser';
import subscriberLocationParser from '@/__new__/services/dno/charging/common/pcConditionsParser/subscriberLocationParser';
import otherPartyNumberParser from '@/__new__/services/dno/charging/common/pcConditionsParser/otherPartyNumberParser';
import vlrIdParser from '@/__new__/services/dno/charging/common/pcConditionsParser/vlrIdParser';
import serviceIdParser from '@/__new__/services/dno/charging/common/pcConditionsParser/serviceIdParser';
import bucketTypeParser from '@/__new__/services/dno/charging/common/pcConditionsParser/bucketTypeParser';
import dataBalanceParser from '@/__new__/services/dno/charging/common/pcConditionsParser/dataBalanceParser';

const CONDITION_TYPE_TO_PARSER_MAP = {
    [PC_CONDITION_TYPES.BEARER]: parseBearer,
    [PC_CONDITION_TYPES.ZONE]: zoneParser,
    [PC_CONDITION_TYPES.SPECIAL_NUMBER]: specialNumberParser,
    [PC_CONDITION_TYPES.SCHEDULE]: scheduleParser,
    [PC_CONDITION_TYPES.APN]: apnParser,
    [PC_CONDITION_TYPES.RATING_GROUP]: ratingGroupParser,
    [PC_CONDITION_TYPES.TRAFFIC_TYPE]: trafficTypeParser,
    [PC_CONDITION_TYPES.TIME_IN_CALL]: timeInCallParser,
    [PC_CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER]: networkSliceIdentifierParser,
    [PC_CONDITION_TYPES.SERVICE_TYPE]: serviceTypeParser,
    [PC_CONDITION_TYPES.OTHER_PARTY_RN]: otherPartyRNParser,
    [PC_CONDITION_TYPES.SUBSCRIBER_FLAG]: flagParser,
    [PC_CONDITION_TYPES.BUCKET_FLAG]: flagParser,
    [PC_CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH]: otherPartyNumberLengthParser,
    [PC_CONDITION_TYPES.SUBSCRIBER_LOCATION]: subscriberLocationParser,
    [PC_CONDITION_TYPES.OTHER_PARTY_NUMBER]: otherPartyNumberParser,
    [PC_CONDITION_TYPES.VLR_ID]: vlrIdParser,
    [PC_CONDITION_TYPES.SERVICE_ID]: serviceIdParser,
    [PC_CONDITION_TYPES.BUCKET_TYPE]: bucketTypeParser,
    [PC_CONDITION_TYPES.DATA_BALANCE]: dataBalanceParser,
};

export default conditionsParameters => {
    const conditionsList = Object.keys(conditionsParameters).map(id => {
        const value = conditionsParameters[id];
        const { type } = value;
        const parser = CONDITION_TYPE_TO_PARSER_MAP[type];
        return {
            ...parser(id, value),
            dataEmpty: false,
            dataValid: true,
        };
    });
    return conditionsList;
};
