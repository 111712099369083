































































import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import Tags from '@/components/partials/inputs/Tags.vue';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import {
    VlrIdConditionValues,
    MAP_VLR_ID_TYPE_TO_OPTION_VALUES,
} from '@/__new__/services/dno/charging/common/vlrIdConditionHelper';

export default Vue.extend({
    name: 'VlrIdConditionRow',
    components: {
        AppMultiselectV3,
        AppButton,
        AppInputV3,
        Tags,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        vlrIdOptions: {
            type: Array,
            default: () => Object.values(MAP_VLR_ID_TYPE_TO_OPTION_VALUES),
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            TOOLTIP_POSITION,
            isCollapsed: true,
            rowData: {} as VlrIdConditionValues,
            tempKey: null as number | null,
            tempValue: null as number | null,
        };
    },
    computed: {
        rowHeaderText(): string {
            const { vlrIdType, values } = this.rowData;
            return `${vlrIdType?.label}: ${values.length ? values.join(', ') : ''}`;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.rowData = this.value;
                this.ifEmptyShowRow();
            },
        },
    },
    methods: {
        emitData(): void {
            this.$emit('input', this.rowData);
        },
        updateValues(): void {
            if (!this.rowData.values.includes(this.tempValue)) {
                this.rowData.values.push(this.tempValue);
            }
            this.tempValue = null;
            this.emitData();
        },
        ifEmptyShowRow(): void {
            if (!this.rowData.values.length) {
                this.isCollapsed = false;
            }
        },
    },
});
