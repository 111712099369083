import i18n from '@/i18n';
import { invert, uniqBy, compact, isEmpty } from 'lodash';
import { EntityActions } from '@/common/commonEntityStateMapper';
import Validator from 'z-schema';
import { stringToSnakeCase } from '@/common/formatting';
import { SinkConfigCol, SinkConfigRowValue } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { TranslateResult } from 'vue-i18n';
import { Event } from '@/__new__/services/dno/events/models/Event';
import { SinkRequiredFields } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import EventProp from '@/__new__/services/dno/events/models/EventProp';
import { SinkTypeCasterOption } from '@/__new__/services/dno/sinkConfigs/models/SinkTypeCasters';

// types
export type StepperItem = {
    title: TranslateResult;
    isCompleted: boolean;
};

export const validateName = (str: string): boolean => {
    const regexp = new RegExp('^([a-z][a-z0-9]*)(_[a-z0-9]+)*$');
    return regexp.test(str);
};

export const validateEventName = (str: string): boolean => {
    const regexp = new RegExp('^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$');
    return regexp.test(str);
};

export const validatePropertyName = validateEventName;

export const validateEventType = validateName;

export const nameErrorMsg = (nameIsValid: boolean, nameIsNotEmpty: boolean): string | TranslateResult => {
    let errorMsg = '' as TranslateResult;
    if (!nameIsValid) {
        errorMsg = i18n.t('events.alerts.failedNameValidation');
    }
    if (!nameIsNotEmpty) {
        errorMsg = i18n.t('generic.validations.fieldIsRequired');
    }
    return errorMsg;
};

export const sinkFormattingFunctions = {
    stringToSnakeCase,
};

export const eventStatuses = {
    PUBLISHED: i18n.t('events.statuses.published'),
    DRAFT: i18n.t('events.statuses.draft'),
    UNPUBLISHED: i18n.t('events.statuses.unpublished'),
};

export const EVENT_SOURCE_NAME_VALUES = {
    EXTERNAL: 'external',
    INTERNAL: 'internal',
};

export const CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS = {
    EXTERNAL: 0,
    INTERNAL: 1,
};

export const CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_VALUES = invert(CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS);

export const CDP_SUPPORTED_API_CONTROL_AUTH_TYPES = {
    SERVICE: 'service',
    CLIENT: 'client',
    SERVER: 'server',
    UNREGISTERED: 'unregistered',
};

export const eventActions = [EntityActions.SEND_EVENT, EntityActions.DETAILS, EntityActions.EDIT, EntityActions.DELETE];

export const getAPIControlItemsList = (
    internalAPIVal: boolean,
    clientAPIVal: boolean,
    serverAPIVal: boolean,
    unauthenticatedAPIVal: boolean,
) => {
    return [
        {
            label: i18n.t('events.eventApiControlsList.internalApi'),
            description: i18n.t('events.eventApiControlsList.internalApiDescription'),
            value: internalAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.clientApi'),
            description: i18n.t('events.eventApiControlsList.clientApiDescription'),
            value: clientAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.serverToServerApi'),
            description: i18n.t('events.eventApiControlsList.serverToServerApiDescription'),
            value: serverAPIVal,
        },
        {
            label: i18n.t('events.eventApiControlsList.unauthenticatedApi'),
            description: i18n.t('events.eventApiControlsList.unauthenticatedApiDescription'),
            value: unauthenticatedAPIVal,
        },
    ];
};

export const eventRequiredFieldsFromJson = (requiredFields: SinkRequiredFields[], events: Event[]): SinkConfigCol[] =>
    requiredFields.map(field => {
        let relatedFormatter = null;
        if (field.formatting_settings) {
            relatedFormatter = {
                name: field.formatting_settings.name,
                options: field.formatting_settings.params,
                value: field.formatting_settings.params[0],
                conversionType: field.formatting_settings.final_field_type,
                columnType: field.formatting_settings.final_field_type,
            };
        }

        return new SinkConfigCol({
            name: field.sink_event_field_name,
            // doc is the same for all required fields, thats' why we can take first event [0]
            doc: events[0].properties.find(prop => prop.name === field.sink_event_field_name)?.doc,
            defaultValueType: field.sink_event_field_type,
            forbidFieldRemoval: true,
            rowValues: uniqBy(events, event => event.eventType).map(
                event =>
                    new SinkConfigRowValue({
                        eventName: event.name.toString(),
                        eventType: event.eventType,
                        property: new EventProp({
                            name: field.custom_event_field_name,
                            mandatory: true,
                            type: field.custom_event_field_type,
                            sourceType: field.custom_event_field_type,
                        }),
                    }),
            ),
            formatter: relatedFormatter,
            typeCaster: SinkTypeCasterOption.fromJson(field.casting_settings),
            isRequiredField: true,
        });
    });
// is used for table filters on events dashboard page
export function getAllDataFromEventField(events: Event[], fieldName: keyof Event) {
    return Array.from(
        new Set(
            compact(
                events
                    .map(e => e[fieldName])
                    .flat()
                    .filter(e => !isEmpty(e)),
            ),
        ),
    );
}

const LFJsonSchema = {
    description: 'Event json schema format',
    type: 'object',
    required: ['title', 'humanReadableName', 'description', 'properties', 'type', 'required'],
    properties: {
        type: {
            enum: ['object'],
        },
        description: {
            type: 'string',
        },
        title: {
            type: 'string',
            minLength: 3,
            pattern: '^[a-z][a-z0-9]*(_[a-z0-9]+)*$',
        },
        humanReadableName: {
            type: 'string',
            minLength: 1,
            pattern: '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        },
        required: {
            type: 'array',
            items: {
                type: 'string',
            },
        },
        properties: {
            type: 'object',
            required: ['event_type', 'id_type', 'id_value', 'epoch_millis'],
            properties: {
                event_type: {
                    type: 'object',
                    properties: {
                        type: {
                            type: 'string',
                        },
                        description: {
                            enum: ['Unique name used by the platform to identify event type'],
                        },
                    },
                },
                id_type: {
                    type: 'object',
                    properties: {
                        type: {
                            enum: ['integer'],
                        },
                        description: {
                            enum: [
                                'The type of the unique identifier (for the list of supported id types refer to product documentation)',
                            ],
                        },
                    },
                },
                id_value: {
                    type: 'object',
                    properties: {
                        type: {
                            type: 'string',
                        },
                        description: {
                            enum: ['The unique identifier used to provide the value for the id_type group member'],
                        },
                    },
                },
                epoch_millis: {
                    type: 'object',
                    properties: {
                        type: {
                            enum: ['integer'],
                        },
                        description: {
                            enum: ['The timestamp when the event was produced, in epoch milliseconds format'],
                        },
                    },
                },
            },
        },
    },
};

export function isJsonSchemaValid(schemaInstance: any) {
    const validator = new Validator({
        breakOnFirstError: true,
    });
    const isSchemaValid = validator.validate(schemaInstance, LFJsonSchema);

    return isSchemaValid ? '' : `${validator.getLastErrors()[0].message}, path: ${validator.getLastErrors()[0].path}`;
}
export default {
    validateName,
};
