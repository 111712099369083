




















// Components
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Misc
import Vue, { PropType } from 'vue';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';

export default Vue.extend({
    name: 'CustomOfferOption',

    components: {
        EntityStatusIndicator,
    },

    props: {
        option: {
            type: Object as PropType<ProductCatalogOfferModel>,
            required: true,
        },
    },
});
