


































































import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import BenefitEditor from '@/__new__/features/customerCare/account/BenefitEditor.vue';
import BenefitTableSidebar from '@/__new__/features/customerCare/account/BenefitTableSidebar.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import Button from '@/common/button/Button';
import { isEmpty } from 'lodash';
import {
    PROMOTION_ENUM_TO_LABEL_MAP,
    GRANT_TYPE_ENUM_TO_OBJECT,
    amountByTypeFormatting,
} from '@/modules/promotions/common/promotionsHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// models
import Benefit from '@/models/Benefit';
import Promotion from '@/models/Promotion';

// http
import { BENEFITS_TYPES, getBenefits, deleteBenefit } from '@/http/benefits';

export default Vue.extend({
    name: 'AccountPromotionsTile',
    components: {
        AbstractTableTile,
        SubscriberStatusIndicator,
        IconButton,
        AppButton,
        BenefitEditor,
        BenefitTableSidebar,
    },
    props: {
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            benefitsType: BENEFITS_TYPES.AVAILABLE,
            benefitsApiResponse: null,
            benefitsTabs: [
                {
                    id: BENEFITS_TYPES.AVAILABLE,
                    title: this.$i18n.t('customerCare.promotions.availableTabLabel'),
                },
                {
                    id: BENEFITS_TYPES.HISTORICAL,
                    title: this.$i18n.t('customerCare.promotions.historicalTabLabel'),
                },
            ],
            isBenefitSidebarVisible: false,
            benefits: [] as Array<Benefit>,
            subscribers: [],

            // modal related
            isBenefitEditorVisible: false,
            benefitEntity: {},

            // permissions
            isSubscriberEnabled: permissionsService.subscriberLevelEnabled() && isViewEnabled('UMSubscriber'),

            // proxy
            BENEFITS_TYPES,
            ICON_TYPES,
            BUTTON_TYPES,
            Button,
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        ...mapGetters('userManagementAccount', ['getTransactionsHistoriesById']),
        ...mapGetters(Modules.promotions, [Getters.PROMOTION_BY_ID]),
        accountId() {
            return this.$route.params.id;
        },
        promotionsTabs() {
            return [
                {
                    id: BENEFITS_TYPES.AVAILABLE,
                    title: this.$i18n.t('customerCare.promotions.availableTabLabel'),
                },
                {
                    id: BENEFITS_TYPES.HISTORICAL,
                    title: this.$i18n.t('customerCare.promotions.historicalTabLabel'),
                },
            ];
        },
        transactionsData() {
            return this.accountId ? this.getTransactionsHistoriesById(this.accountId) : [];
        },
        isAvailableBenefits() {
            return this.benefitsType === BENEFITS_TYPES.AVAILABLE;
        },
        columnsData() {
            const cols = [
                {
                    name: this.$i18n.t('customerCare.promotions.billingTransactionId'),
                    key: this.isAvailableBenefits ? 'baseOrderId' : 'transactionId',
                    width: 24,
                },
                {
                    name: this.$i18n.t('benefits.creationDate'),
                    key: 'creationDateLabel',
                    sortBy: (el: Benefit) => el.creationDate,
                },
                {
                    name: this.$i18n.t('benefits.grantedType'),
                    key: 'grantedTypeLabel',
                },
                {
                    name: this.$i18n.t('promotions.promotionType'),
                    key: 'promotionType',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amountLabel',
                    sortBy: (el: Benefit) => el.amount,
                },
                {
                    name: this.$i18n.t('benefits.remainingUses'),
                    key: 'remainingUses',
                },
                {
                    name: this.$i18n.t('benefits.override'),
                    key: 'override',
                },
            ];

            if (this.isSubscriberEnabled) {
                cols.push(
                    {
                        name: this.$i18n.t('customerCare.subscriberId'),
                        key: 'subscriberId',
                    },
                    {
                        name: this.$i18n.t('customerCare.promotions.subscriberStatus'),
                        key: 'subscriberStatus',
                    },
                    {
                        name: this.$i18n.t('customerCare.msisdn'),
                        key: 'msisdn',
                    },
                );
            }

            return cols;
        },
    },
    watch: {
        benefitsType() {
            this.fetchTableData();
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.productcatalog, [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions(Modules.promotions, [Actions.REQUEST_PROMOTIONS]),
        ...mapActions('userManagementAccount', ['getTransactionHistoryTableData']),

        // proxy
        isUserAllowed,

        // actual components methods
        async fetchTableData() {
            await this.fetchBenefitsData();
            if (this.isSubscriberEnabled) {
                await this.getSubscribersData();
            }
        },
        fetchBenefitsData() {
            this.$withProgressBar(
                async () => {
                    const result = await getBenefits(this.benefitsType, this.accountId, USER_MANAGER_HIERARCHY.ACCOUNT);
                    this.benefitsApiResponse = result;

                    // eslint-disable-next-line camelcase
                    const rawBenefits = result?.data?.benefits_by_id;

                    if (rawBenefits) {
                        this.benefits = this.mapBenefitsForDisplay(rawBenefits);
                    }
                },
                {
                    errorHandler: error => {
                        const alertMessage = `${this.$i18n.t('alertMessage.customerCare.promotions.fetchFailure')} (${
                            this.benefitsType
                        })`;
                        this.$showErrorAlert({ message: alertMessage });
                        this.benefitsApiResponse = error.response;
                    },
                },
            );
        },
        mapBenefitsForDisplay(rawBenefits) {
            return Object.values(rawBenefits).reduce((map, el) => {
                const mappedBenefit = Benefit.remapBenefitFromBe(el);

                map.push({
                    ...mappedBenefit,
                    creationDateLabel: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                        mappedBenefit.creationDate,
                    ),
                    grantedTypeLabel: GRANT_TYPE_ENUM_TO_OBJECT[mappedBenefit.grantType]?.label,
                    promotionType: PROMOTION_ENUM_TO_LABEL_MAP[mappedBenefit.promotionTypeId],
                    amountLabel: amountByTypeFormatting(mappedBenefit.amount, mappedBenefit.promotionTypeId),
                    remainingUses: this.remainingUsesMapper(
                        mappedBenefit.recurrenceLimit,
                        mappedBenefit.recurrenceCounter,
                    ),
                    override: this.checkOverride(mappedBenefit),
                    ...(this.isSubscriberEnabled && {
                        subscriberId: this.getSubscriberId(mappedBenefit),
                        subscriberStatus: '',
                        msisdn: '',
                    }),
                });
                return map;
            }, []);
        },
        getSubscriberId(benefit: Benefit) {
            const transactionEntity = this.transactionsData.find(({ id }) =>
                [benefit.baseOrderId, benefit.transactionId].includes(id),
            );
            return transactionEntity?.chargesData?.[0].subscriberId;
        },
        async getSubscribersData() {
            const subscriberIds = new Set(this.benefits.map(b => this.getSubscriberId(b)));
            const promises = [...subscriberIds].map(subId => this.getSubscriberData(subId));

            await Promise.all(promises);
            this.mapSubscribersData();
        },
        getSubscriberData(subscriberId: string) {
            this.$withProgressBar(
                async () => {
                    if (!subscriberId) {
                        return;
                    }
                    const subscriber = this.getSubscriberInfoById(subscriberId);
                    if (!isEmpty(subscriber)) {
                        this.subscribers.push(subscriber);
                    } else {
                        await this.getSubscriberInfo(subscriberId);
                        this.getSubscriberData(subscriberId);
                    }
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingSubscriberData',
                            ),
                        });
                    },
                },
            );
        },
        mapSubscribersData() {
            this.benefits.forEach(benefit => {
                const subscriberId = this.getSubscriberId(benefit);
                const { msisdn, state } = {
                    ...this.subscribers.find(({ subscriber_id: id }) => id === subscriberId),
                };
                benefit.subscriberId = subscriberId;
                benefit.msisdn = msisdn;
                benefit.subscriberStatus = state;
            });
        },

        onTabSelected(tabId: string) {
            this.benefitsType = tabId;
        },

        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const promises = [
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({
                            entityType: ENTITY_TYPES.PRODUCT,
                        }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({
                            entityType: ENTITY_TYPES.OFFER,
                        }),
                        this[Actions.REQUEST_PROMOTIONS](),
                    ];
                    if (this.isSubscriberEnabled && !this.transactionsData.length) {
                        promises.push(
                            this.getTransactionHistoryTableData({
                                accountId: this.accountId,
                                sortType: 'DESC',
                            }),
                        );
                    }

                    await Promise.all(promises);

                    // data for table (needs to be fetched last)
                    await this.fetchTableData();

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        selectBenefit(benefitId: string) {
            this.benefitEntity = this.benefits.find((benefit: Benefit) => benefit.benefitId === benefitId);
            this.isBenefitSidebarVisible = true;
        },
        checkOverride(benefit: Benefit) {
            try {
                if (benefit.promotionId) {
                    const promoRuleUnmapped = this[Getters.PROMOTION_BY_ID](benefit.promotionId);
                    const promoRule = Promotion.remapPromotionFromBe(promoRuleUnmapped);

                    if (
                        promoRuleUnmapped &&
                        promoRule &&
                        !(
                            benefit.name === promoRule.name &&
                            benefit.description === promoRule.description &&
                            benefit.promoShortDescription === promoRule.promoShortDescription &&
                            benefit.benefitShortDescription === promoRule.benefitShortDescription &&
                            benefit.termsAndConditions === promoRule.termsAndConditions &&
                            benefit.amount === promoRule.amount &&
                            benefit.recurrenceLimit === promoRule.recurrenceLimit
                        )
                    ) {
                        return this.$i18n.t('generic.yes');
                    }
                }
                return this.$i18n.t('generic.no');
            } catch (e) {
                return this.$i18n.t('generic.no');
            }
        },
        remainingUsesMapper(recurrenceLimit: number, recurrenceCounter: number) {
            if (!recurrenceLimit) {
                return this.$i18n.t('generic.unlimitedCapital');
            }

            return recurrenceLimit - (recurrenceCounter || 0);
        },
        deleteAction(entity) {
            const deleteButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('benefits.alerts.areYouSureBenefitAction', {
                    action: this.$i18n.t('generic.delete').toLowerCase(),
                }),
                type: ALERT_TYPES.warning,
                buttons: [deleteButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                await this.$withProgressBar(
                    async () => {
                        if (buttonId === deleteButton.id) {
                            await deleteBenefit(this.accountId, USER_MANAGER_HIERARCHY.ACCOUNT, entity.benefitId);
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('benefits.alerts.benefitSuccessfulAction', {
                                    action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                                }),
                                type: ALERT_TYPES.success,
                            });
                            await this.fetchTableData();
                        }
                    },
                    {
                        errorHandler: () => {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            });
                        },
                    },
                );
            });
        },
        createAction() {
            this.isBenefitEditorVisible = true;
        },
        editAction(entity: Benefit) {
            this.benefitEntity = entity;
            this.isBenefitEditorVisible = true;
        },
        clearBenefitEntity(val: Benefit | null) {
            if (!val) {
                this.benefitEntity = {};
                this.fetchTableData();
            }
        },
    },
});
