



















































import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// HTTP
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppOtpCodeInput from '@/components/partials/inputs/AppOtpCodeInput.vue';

// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import {
    CHALLENGE_CONTEXT_TYPE,
    CHALLENGE_TYPES,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Modules } from '@/store/store';

export default Vue.extend({
    name: 'AuthenticationDialogs',

    components: {
        AppButton,
        AppDialogV2,
        AppMultiselectV3,
        AppOtpCodeInput,
    },
    props: {
        isGenerateModalVisible: {
            type: Boolean,
            default: false,
        },
        isVerifyModalVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            otp: '',
            challengeType: null,
            numberOfDigits: getOperatorConfigValue('service_config.lf-user.otp.otp_digits', 4),
            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_USER_AUTHENTICATION_STATE]),
        generateModalTitle() {
            return this.$i18n.t('customerCare.userAuthentication.modal.generateTitle', { num: this.numberOfDigits });
        },
        verifyModalTitle() {
            return this.$i18n.t('customerCare.userAuthentication.modal.enterDigits', { num: this.numberOfDigits });
        },
        challengeTypeOptions() {
            return Object.keys(CHALLENGE_TYPES).filter(challenge => Number.isNaN(Number(challenge)));
        },
    },
    methods: {
        ...mapMutations('userManagementUser', ['setUserVerificationStatus']),
        ...mapActions(Modules.customerCareSuite, [Actions.GENERATE_CODE, Actions.VERIFY_CODE]),
        triggerGenerateCode() {
            this.$withProgressBar(
                async () => {
                    const payload = {
                        id: this.$route.params.id,
                        userType: USER_MANAGER_HIERARCHY.USER,
                        contextType: CHALLENGE_CONTEXT_TYPE.GENERIC,
                        challengeType: this.challengeType,
                    };
                    await this[Actions.GENERATE_CODE](payload);
                    this.onCloseGenerateModal();
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        triggerVerifyCode() {
            this.$withProgressBar(
                async () => {
                    const payload = {
                        challengeId: this.$route.params.id,
                        otp: this.otp,
                        contextType: CHALLENGE_CONTEXT_TYPE.GENERIC,
                        challengeType: this.challengeType,
                    };
                    await this[Actions.VERIFY_CODE](payload);
                    this.setUserVerificationStatus({
                        userId: this.$route.params.id,
                        status: true,
                    });
                    this.onCloseVerifyModal();
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        onCloseGenerateModal() {
            this.$emit('closeGenerateModal');
        },
        onCloseVerifyModal() {
            this.$emit('closeVerifyModal');
        },
    },
});
