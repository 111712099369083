export enum USER_DETAIL_FIELD {
    // basic info
    USER_ID = 'USER_ID',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    MIDDLE_NAME = 'MIDDLE_NAME',
    MOTHERS_MAIDEN_NAME = 'MOTHERS_MAIDEN_NAME',
    VERIFICATION_STATUS = 'VERIFICATION_STATUS',
    CREDENTIALS = 'CREDENTIALS',
    FACEBOOK = 'FACEBOOK',
    CREATION_DATE = 'CREATION_DATE',
    LAST_ACTIVE = 'LAST_ACTIVE',
    CARDINALITY = 'CARDINALITY',
    // contact
    MSISDN = 'MSISDN',
    MSISDN_WITH_VERIFY_STATUS = 'MSISDN_WITH_VERIFY_STATUS',
    EMAIL = 'EMAIL',
    EMAIL_WITH_VERIFY_STATUS = 'EMAIL_WITH_VERIFY_STATUS',
    BIRTHDAY = 'BIRTHDAY',
    // kyc
    DOCUMENT = 'DOCUMENT',
    DOCUMENT_NUMBER = 'DOCUMENT_NUMBER',
    EKYC_STATUS = 'EKYC_STATUS',
    IS_LOCALLY_TIED = 'IS_LOCALLY_TIED',
    NATIONALITY = 'NATIONALITY',
    DOCUMENT_TYPE = 'DOCUMENT_TYPE',
    DOCUMENT_SUBTYPE = 'DOCUMENT_SUBTYPE',
    // consents
    CONSENTS_TERMS_AND_CONDITIONS = 'CONSENTS_TERMS_AND_CONDITIONS',
    CONSENTS_ACCEPTED = 'CONSENTS_ACCEPTED',
    CONSENTS_ALL = 'CONSENTS_ALL',
    // flags
    FLAG_PROFILE_DELETED = 'FLAG_PROFILE_DELETED',
    // TMO ID section
    TMO_ID_SECTION = 'TMO_ID_SECTION',
    FIBER_ACCOUNT_CONTACT_INFO = 'FIBER_ACCOUNT_CONTACT_INFO',
}

export enum ACCOUNT_DETAIL_FIELD {
    // basic info
    ACCOUNT_ID = 'ACCOUNT_ID',
    ACCOUNT_NAME = 'ACCOUNT_NAME',
    BILLING_DAY = 'BILLING_DAY',
    LAST_4_DIGITS = 'LAST_4_DIGITS',
    EXPIRY_TIME = 'EXPIRY_TIME',
    REFERRAL_CODE = 'REFERRAL_CODE',
    CREATION_DATE = 'CREATION_DATE',
    CARDINALITY = 'CARDINALITY',
    EXPORT_CUSTOMER_INFO = 'EXPORT_CUSTOMER_INFO',
    AUTOPAY = 'AUTOPAY',
    // address
    ADDRESS = 'ADDRESS',
    BUSSINES_ADDRESS_TYPE = 'BUSSINES_ADDRESS_TYPE',
    TAX_AREA_ID = 'TAX_AREA_ID',
    // network
    DOCUMENT = 'DOCUMENT',
    DOCUMENT_NUMBER = 'DOCUMENT_NUMBER',
    SERVICE_ID = 'SERVICE_ID',
    EXTERNAL_SERVICE_ID = 'EXTERNAL_SERVICE_ID',
    PROVIDER_PRODUCT = 'PROVIDER_PRODUCT',
    ROUTER_IDS = 'ROUTER_IDS',
    PROVIDER = 'PROVIDER',
    ONT_ID = 'ONT_ID',
    ONT_STATUS = 'ONT_STATUS',
    ORDER_ID = 'ORDER_ID',
    ORDER_ACTION_ID = 'ORDER_ACTION_ID',
    RESERVATION_ID = 'RESERVATION_ID',
    RESERVED_DP_NAP_ID = 'RESERVED_DP_NAP_ID',
    CVLAN = 'CVLAN',
    SVLAN = 'SVLAN',
    MAC_ADDRESS = 'MAC_ADDRESS',
    DEVICE_TYPE = 'DEVICE_TYPE',
    DEVICE_MODEL = 'DEVICE_MODEL',
    // terms and conditions
    AUTOPAY_TC = 'AUTOPAY_TC',
    AUTOPAY_TC_TIMESTAMP = 'AUTOPAY_TC_TIMESTAMP',
    TC_ACCEPTANCE_TIME = 'TC_ACCEPTANCE_TIME',
    // flags
    FLAGS = 'FLAGS',
    FLAG_TAX_EXEMPTION = 'FLAG_TAX_EXEMPTION',
    // outage
    OUTAGE_INFO = 'OUTAGE_INFO',
    // TMO ID section
    TMO_ID_SECTION = 'TMO_ID_SECTION',
    // device section
    DEVICE_SECTION = 'DEVICE_SECTION',
}

export enum SUBSCRIBER_DETAIL_FIELD {
    // basic info
    SUBSCRIBER_ID = 'SUBSCRIBER_ID',
    ACTIVATION_DATE = 'ACTIVATION_DATE',
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
    REFERRAL_CODE = 'REFERRAL_CODE',
    CREATION_DATE = 'CREATION_DATE',
    CARDINALITY = 'CARDINALITY',
    // sim and number
    MSISDN = 'MSISDN',
    IMSI = 'IMSI',
    PUK_ICCID = 'PUK_ICCID',
    PUK = 'TAX_AREA_ID',
    // flags
    FLAGS = 'FLAGS',
}
