































import Vue, { PropType } from 'vue';

// Components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';

// Helpers
import { UsageCounterConstructorArgs } from '@/__new__/services/dno/charging/models/UsageCounter';
import { parseExpressionsFromBe, parseExpressionToTree } from '@/__new__/services/dno/charging/common/expression';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import moment from 'moment';

/* eslint-disable camelcase */
type BucketUsageCountersData = UsageCounterConstructorArgs & {
    amount_used: number;
    expiration_timestamp: number;
    name: string;
};

export default Vue.extend({
    name: 'BucketUsageCountersData',
    components: {
        ConditionsExpressionEditor,
        AppButton,
        AppCustomerHeader,
    },
    props: {
        value: {
            type: Object as PropType<BucketUsageCountersData>,
            required: true,
        },
        ucId: {
            type: String,
            required: true,
        },
        conditionsList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            collapseSection: true,
        };
    },
    computed: {
        expression: {
            get() {
                return this.value?.condition_expr ? parseExpressionsFromBe(this.value?.condition_expr) : [];
            },
            set(value) {
                this.value.condition_expr = parseExpressionToTree(value);
            },
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.ucId,
                        },
                        {
                            name: this.$i18n.t('charging.usageCounters.resetPeriod'),
                            value: this.value.reset_period,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.amountUsed'),
                            value: this.value.amount_used,
                        },
                        {
                            name: this.$i18n.t('generic.expiration'),
                            value: this.value?.expiration_timestamp
                                ? moment(this.value.expiration_timestamp * 1000).format('DD-MM-YYYY')
                                : '',
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        toggleCollapseSection(): void {
            this.collapseSection = !this.collapseSection;
        },
    },
});
