










































































// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';

// Helpers & Misc
import {
    DURATION_OPTIONS,
    DurationOption,
} from '@/__new__/features/orchestrationengine/common/orchestrationPausedExecutionsHelper';
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import { updatePauseDuration } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'ExpandExecutionPauseSidebar',
    components: {
        AppAditionalSidebar,
        AppButton,
        AppMultiselectV3,
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
        },
        execution: {
            required: true,
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            // Proxy imports
            BUTTON_TYPES: BUTTON_TYPES as any,
            DURATION_OPTIONS: DURATION_OPTIONS as DurationOption[],
            TOOLTIP_POSITION: typeof TOOLTIP_POSITION,
            RouteNames: RouteNames as any,

            expandExecutionButtonClicked: false as boolean,
            selectedDuration: null as null | string,
            isSidebarVisible: false as boolean,
        };
    },
    computed: {
        visibleSidebar(): boolean {
            return this.isSidebarVisible;
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('PausedExecutionsWrite') &&
                permissionsService.orchestrationEnginePausedExecutionsEnabled()
            );
        },
    },
    watch: {
        isVisible: {
            handler() {
                this.isSidebarVisible = this.isVisible;
            },
        },
        isSidebarVisible: {
            handler() {
                this.$emit('showSidebarChange', this.isSidebarVisible);
            },
        },
    },
    created() {
        this.selectedDuration = DURATION_OPTIONS[0].id;
    },
    methods: {
        onCloseSidebar(): void {
            this.isSidebarVisible = false;
        },
        async onExpand(): Promise<any> {
            try {
                this.expandExecutionButtonClicked = true;

                this.$Progress.start();
                await updatePauseDuration(this.execution.executionId, this.selectedDuration);
                this.$Progress.finish();

                this.onCloseSidebar();
                this.$emit('pauseExpanded');
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.somethingWentWrong'),
                });
            } finally {
                this.expandExecutionButtonClicked = false;
            }
        },
    },
};
