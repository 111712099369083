import { DATA_DEFINITIONS, getBestUnit, getScaledAmount, UnitDefinition } from '@/common/formatting';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import Order from '@/__new__/services/dno/orders/models/Order';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

// get_orders
export enum WALLET_TYPES {
    PRIMARY = 'primary',
    SPINS = 'spins',
    COINS = 'coins',
}

export const WALLET_TYPES_PLACEHOLDER: Map<WALLET_TYPES, TranslateResult> = new Map([
    [WALLET_TYPES.PRIMARY, '$'],
    [WALLET_TYPES.SPINS, i18n.t('productCatalog.products.spins')],
    [WALLET_TYPES.COINS, i18n.t('productCatalog.offers.editor.rewardPoints')],
]);

export enum CHARGED_TO {
    WALLET = 'wallet',
    BILL = 'bill',
    EXTERNAL = 'external',
}

export enum REWARD_WALLET_TYPE {
    SPINS = 'Spins wallet',
    COINS = 'Coins wallet',
}

export enum PURCHASED_ENTITY_TYPE {
    PRODUCT = 'product',
    OFFER = 'offer',
}

export type OrderId = string;

type SubOrderId = string;

type OfferId = string;

type ProductId = string;

type OrderCharge = {
    id: string;
    aggregation_id: string;
    cron_expression: string;
};

type OrderDelayedCharge = {
    id: string;
    aggregation_id: string;
    cron_expression: string;
    data: {
        grandfathered: boolean;
        misc: {
            entity_type: PURCHASED_ENTITY_TYPE.OFFER;
            offer_name: string;
            offer_fiber_plan_included: string;
            tax: {
                tax_inclusive: boolean;
                is_business: boolean;
                calculation_type: number;
                component_tax_id: string;
                tax_address_type: number;
            }[];
            offer_description: string;
            order_id: OrderId;
            entity_id: OfferId;
            offer_fiber_plan_name: string;
            product_type: string;
            charge_immediately: boolean;
        };
        recurrence_data: {
            billing_recurrence_limit: number;
        };
        amount: string;
        subscription_model: 'postpaid' | 'prepaid';
        added_at: number;
    };
};

interface PurchasedEntity {
    state: ORDER_ENTITY_STATES;
    charges?: {
        immediate_charges?: OrderCharge[];
        delayed_charges?: OrderDelayedCharge[];
    };
    contains_base_plan?: boolean;
    entity_type: PURCHASED_ENTITY_TYPE;
    entity_name: string;
    entity_id?: OrderId | OfferId;
    quantity?: number;
    expiry_time?: number;
    activation_time?: number;
    activation_timestamp: number;
    is_recurring: boolean;
    target_type?: TARGET_TYPE;
    target_id: string;
    order_id_on_cm: OrderId;
    external_provisioning_service: string;
    external_id: string;
    charge_id: string;
}

interface Offer extends PurchasedEntity {
    entity_id: OfferId;
    entity_type: PURCHASED_ENTITY_TYPE.OFFER;
}

interface Product extends PurchasedEntity {
    entity_id: ProductId;
    entity_type: PURCHASED_ENTITY_TYPE.PRODUCT;
    parent_entity_id: OfferId;
}

export interface OrderBE {
    target_id: string;
    target_type: number;
    order_id: OrderId;
    create_timestamp: number;
    charged_amount: number;
    charged_to: CHARGED_TO;
    state: ORDER_ENTITY_STATES;
    activation_timestamp: number;
    wallet_type?: WALLET_TYPES;
    order_data?: {
        charged_amount?: number;
        charged_to?: string;
        currency?: string;
        delayed_charges?: OrderDelayedCharge[];
        entity_name?: string;
        expiry_time?: number;
        friendly_order_id: string;
        is_preorder?: boolean;
        buyer_info?: {
            first_name?: string;
            last_name?: string;
            middle_name?: string;
            nick_name?: string;
            msisdn?: string;
            account_id?: string;
        };
        is_trial?: boolean;
        iccid?: string;
        imei?: string;
        order_creation_time?: number;
        order_items?: {
            [key: string]: {
                price: string;
                currency: string;
            };
        };
        subscriber_id: string;
        total_amount: string;
        transaction_id?: string;
    };
    order_state_history?: {
        id: string;
        state: ORDER_ENTITY_STATES;
        operator_name: string;
        id_type: string;
        update_time_ms: number;
    }[];
    entities: {
        [PURCHASED_ENTITY_TYPE.OFFER]?: {
            [key: OfferId]: Offer;
        };
        [PURCHASED_ENTITY_TYPE.PRODUCT]?: {
            [key: ProductId]: Product;
        };
    };
    replace_info?: {
        entity_name: string;
        entity_id: SubOrderId;
        entity_type: PURCHASED_ENTITY_TYPE;
        transaction_id: string;
    };
    sub_orders: {
        [key: SubOrderId]: {
            activation_timestamp: number;
            sub_order_id: SubOrderId;
            state: ORDER_ENTITY_STATES;
            entity_id: string;
            entity_type: string;
            entity_name: string;
            contains_base_plan: boolean;
            external_provisioning_service: string;
            is_recurring: boolean;
        };
    };
}

/**
 * Function receives two data amounts in bytes and returns unitDefinition that fits both of them
 * @param firstDataAmount
 * @param secondDataAmount
 * @returns {*}
 */
export function getFittingDataDefinition(firstDataAmount: number, secondDataAmount: number): UnitDefinition {
    const firstAmountBestUnit = getBestUnit(DATA_DEFINITIONS, firstDataAmount);
    const secondAmountBestUnit = getBestUnit(DATA_DEFINITIONS, secondDataAmount);
    if (firstAmountBestUnit && secondAmountBestUnit) {
        return firstAmountBestUnit.multiplier < secondAmountBestUnit.multiplier
            ? firstAmountBestUnit
            : secondAmountBestUnit;
    }
    return DATA_DEFINITIONS[0];
}

/**
 * Function receives two data amounts in bytes and return them scaled with label of their unit
 * @param firstDataAmount
 * @param secondDataAmount
 * @returns {array} - [scaledFirstAmount, scaledSecondAmount, fittingDefinitionLabel]
 */
export function getScaledAmountsAndFittingUnit(firstDataAmount: number, secondDataAmount: number): (string | number)[] {
    const resultArray = [];
    const fittingDefinition = getFittingDataDefinition(firstDataAmount, secondDataAmount);
    if (fittingDefinition) {
        resultArray.push(getScaledAmount(fittingDefinition, firstDataAmount));
        resultArray.push(getScaledAmount(fittingDefinition, secondDataAmount));
        resultArray.push(fittingDefinition.label);
    }
    return resultArray;
}

export function remapOrdersFromBe(orders: Record<OrderId, Partial<OrderBE>>): Record<OrderId, Order> {
    if (!orders) {
        return {};
    }
    return Object.assign(
        {},
        ...Object.keys(orders).map(orderId => ({
            [orderId]: new Order(Order.remapOrderFromBe(orders[orderId])),
        })),
    );
}
