

























import Vue, { PropType } from 'vue';

// Components
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import DataflowElement from '@/__new__/features/dataflows/DataflowElement.vue';

// Helpers
import { DataflowSection, SECTION_TYPES, SectionElement } from '@/__new__/features/dataflows/common/DataflowInterfaces';

export default Vue.extend({
    name: 'DataflowModelSection',
    components: {
        CollapsibleItem,
        DataflowElement,
    },
    props: {
        value: {
            type: Object as PropType<DataflowSection>,
            required: true,
        },
    },
    computed: {
        sectionHasError(): boolean {
            return this.value.elements.some(el => el.error);
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                if (this.value.collapsed) {
                    this.value.error = false;
                } else {
                    this.value.error = this.sectionHasError;
                }
                this.$emit('input', this.value);
            },
        },
    },
    methods: {
        isElementSubSection(data: SectionElement): boolean {
            return data.type === SECTION_TYPES.SECTION;
        },
    },
});
