<template>
    <AbstractEditPageWrapper :shouldDisplaySidebar="true">
        <template #header>
            <AppHeader :pageTitle="categoryPageTitle" />
        </template>

        <template #content>
            <!-- General section -->
            <div class="editor-section">
                <div class="d-flex align-items-center mb-3">
                    <div class="lf-subtitle mb-0">
                        {{ $i18n.t('generic.general') }}
                        <p
                            v-show="isEditing"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                        </p>
                        <p
                            v-show="isEditing"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(data.updateTime) }}
                        </p>
                        <p
                            v-show="isEditing"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                        </p>
                    </div>
                    <!-- Bar containing labels and buttons -->
                    <div class="labels-and-buttons-bar flex-grow-1 d-flex align-items-center justify-content-end">
                        <EntityStatusIndicator
                            v-if="state && state !== STATUS_CODES"
                            :status="state"
                            class="state-label"
                        >
                        </EntityStatusIndicator>
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="state-label"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="state-label"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="state-label"
                        />
                        <AppButton
                            v-if="!isEditing"
                            data-test-id="advanced-btn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.advanced')"
                            @click="openAdvanced"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            @click="reloadEditor(EDITOR_MODE.EDIT)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            @click="reloadEditor(EDITOR_MODE.VIEW)"
                        />
                        <AppButton
                            v-if="isRevertAvailable"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('productCatalog.editors.revertToPublished')"
                            @click="resetToPublished"
                        />
                    </div>
                </div>
                <AppDialogV2
                    :visible="advanced"
                    :title="$i18n.t('generic.advanced')"
                    :disableDefaultSaveBtn="true"
                    @close="closeAdvanced()"
                >
                    <AppInputV3
                        v-if="!isEditing"
                        id="template-name-uuid"
                        v-model="uuid"
                        :invalid="!validateUUID()"
                        :placeholder="$i18n.t('generic.uuid')"
                        :label="$i18n.t('generic.uuid')"
                        :explanationText="$i18n.t('generic.uuidtooltip')"
                        :tooltipOffset="0"
                        class="editor-input-largest pb-3"
                        data-test-id="uuid-input"
                    />
                    <template #modalFooter>
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$i18n.t('generic.apply')"
                            :disabled="!validateUUID()"
                            @click="applyAdvanced()"
                        />
                    </template>
                </AppDialogV2>

                <p
                    v-show="uuid"
                    class="lf-primary-text pb-2"
                >
                    {{ $i18n.t('generic.id') }}: {{ uuid }}
                </p>

                <AppInputV3
                    v-model="data.name[selectedLanguage]"
                    :placeholder="$i18n.t('productCatalog.categories.addCategoryName')"
                    :label="$i18n.t('generic.name')"
                    :disabled="readonly"
                    :invalid="requiredErrors.showNameFieldRequiredError"
                    class="col-xl-7 col-lg-7 col-md-7 col-12 section-content"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    data-test-id="name-input"
                />

                <AppTextareaV3
                    id="category-description"
                    v-model="data.description[selectedLanguage]"
                    :placeholder="$i18n.t('productCatalog.categories.descriptionCategory')"
                    class="col-xl-7 col-lg-7 col-md-7 col-12 pt-3 pb-2 section-content"
                    :label="$i18n.t('generic.description')"
                    :disabled="readonly"
                    data-test-id="description-input"
                />
                <div
                    v-if="!isEditing"
                    class="d-flex"
                >
                    <div class="section-content mb-0">
                        {{ $i18n.t('rewards.editor.general.approveOnCreation') }}
                    </div>
                    <AppToggle
                        v-model="approveOnCreate"
                        :small="true"
                        :disabled="readonly"
                        data-test-id="approve-toggle"
                    />
                </div>
            </div>
            <!-- Category Parameters section -->
            <div class="editor-section">
                <div
                    v-t="'productCatalog.categories.categoryParameters'"
                    class="editor-section-heading"
                />
                <AppMultiselectV3
                    v-model="data.parent"
                    :multiple="false"
                    :options="parentsList"
                    :optional="true"
                    :showLabels="false"
                    :placeholder="$i18n.t('productCatalog.categories.parentCategoryPlaceholder')"
                    :additionalLabel="$i18n.t('productCatalog.categories.parentCategory')"
                    :small="true"
                    :disabled="readonly"
                    label="name"
                    class="editor-input-large mb-3"
                    data-test-id="parent-categories"
                    trackBy="id"
                    optionId="id"
                />
                <div class="mb-3">
                    <AppInputV3
                        v-model.number="data.sort_priority"
                        :invalid="$v.data.sort_priority.$error"
                        :label="$i18n.t('productCatalog.categories.sortPriority')"
                        :min="0"
                        :step="1"
                        type="number"
                        class="editor-input-large"
                        :optional="true"
                        :disabled="readonly"
                        data-test-id="priority-input"
                    />
                </div>
                <div>
                    <AppToggle
                        ref="uniqueToggle"
                        v-model="data.unique"
                        :disabled="!canToggleUniqueFlag || readonly"
                        :label="$i18n.t('productCatalog.categories.isUnique')"
                        :small="true"
                        :explanationText="$i18n.t('productCatalog.categories.isUniqueExplanation')"
                        :tooltipOffset="-200"
                    />
                    <div
                        v-if="canToggleUniqueFlag"
                        class="warning"
                    >
                        {{ $t('productCatalog.categories.isUniqueNewNote') }}
                    </div>
                    <div
                        v-else
                        class="warning"
                    >
                        {{
                            $tc('productCatalog.categories.isUniqueDisabledReason', referencingOffers.length, {
                                count: referencingOffers.length,
                            })
                        }}
                    </div>
                </div>
            </div>
            <!-- Miscellaneous parameter section -->
            <div class="editor-section">
                <div
                    v-t="'generic.miscParams'"
                    class="editor-section-heading pb-3"
                />
                <div>
                    <KeyValueInputs
                        ref="miscParams"
                        v-model="data.miscParameters"
                        :addButtonLabel="$i18n.t('generic.addMiscParams')"
                        :firstInputPlaceholder="$i18n.t('generic.key')"
                        :secondInputPlaceholder="$i18n.t('generic.value')"
                        :initInputValues="initMiscParameters"
                        data-test-id="misc-input"
                        :setWidth="true"
                        :disabled="readonly"
                    />
                </div>
            </div>
        </template>

        <template
            v-if="readonly"
            #sideBar
        >
            <div class="lf-subtitle m-3">
                {{ $i18n.t('productCatalog.editors.versionHistory') }}
            </div>
            <div
                v-for="entry in versionsHistory"
                :key="entry.version"
            >
                <div
                    class="lf-primary-text version-item"
                    :class="{ active: entry.version === currentVersion.version }"
                    @click="selectVersion(entry)"
                >
                    <AppIcon
                        v-if="entry.version === currentVersion.version"
                        type="check-new"
                        size="1rem"
                        class="icon mr-2"
                    />
                    <span>{{ entry.label }}</span>
                </div>
            </div>
        </template>

        <!-- Buttons section -->
        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import KeyValueInputs from '@/components/partials/inputs/KeyValueInputs.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import { validationMixin } from 'vuelidate';

// helpers
import { languageMap } from '@/common/locale/language';
import * as Sentry from '@sentry/vue';
import { cloneDeep, isEmpty } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { loadVersionHistory, EDITOR_MODE } from '@/common/entities/entityHelper';
import { getObjectFromArray, getArrayFromObject } from '@/common/formatting';
import { required } from 'vuelidate/lib/validators';
import Actions, { Getters } from '@/store/mutation-types';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { isAxiosError } from 'axios';
import luaErrors, { modules } from '@/common/luaErrors';
import { STATUS_CODES } from '@/common/commonHelper';

// API
import { addCategory, updateCategory } from '@/__new__/services/dno/pc/http/categories';
import { isValidUuid } from '@/common/uuidHelper';
import { setEntityDraft, getEntityDraft } from '@/__new__/services/dno/pc/entities';
import ProductCatalogCategoryModel from '@/__new__/services/dno/pc/models/ProductCatalogCategoryModel';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import Button from '@/common/button/Button';
import { getHistoryVersion } from '@/__new__/services/dno/pc/http/catalog';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/features/pc/common/entitiesTypesToModel';

const { mapGetters, mapActions } = createNamespacedHelpers('productcatalog');

export default {
    name: 'CategoryEditor',
    components: {
        AppTextareaV3,
        AppHeader,
        AppInputV3,
        AppButton,
        AppMultiselectV3,
        KeyValueInputs,
        AbstractEditPageWrapper,
        AppToggle,
        AppDialogV2,
        EditorButtons,
        AppLabel,
        AppIcon,
        EntityStatusIndicator,
    },
    mixins: [validationMixin, entityEditorMixin],
    data() {
        return {
            initUnique: false,
            state: EntityStateMapping.UNAPPROVED,
            data: {
                name: {},
                description: {},
                parent: null,
                sort_priority: 1,
                unique: false,
                miscParameters: null,
            },
            entityDraft: undefined,
            isOnlyDraft: false,
            updateName: this.$i18n.t('generic.N/A'),
            approveOnCreate: false,
            version: null,
            initMiscParameters: [],
            maxNestingDepth: 4,
            unapprovedError: this.$i18n.t('alertMessage.unapprovedEntity'),
            parentApproved: true,
            entityType: ENTITY_TYPES.CATEGORY,
            approveOnCreationText: this.$i18n.t('productCatalog.offers.editor.approveOnCreationText'),
            isSaveButtonClicked: false,
            requiredErrors: {
                showNameFieldRequiredError: false,
            },
            selectedLanguage: languageMap.en.key,
            advanced: false,
            uuid: '',
            readonly: false,
            isUnpublished: false,
            historyEntity: undefined,
            currentVersion: undefined,
            versionsHistory: [],
            isOutdatedDraft: false,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.setData(true),
            }),
            resetDraftButton: new Button({
                label: this.$i18n.t('productCatalog.editors.reset'),
                alertType: ALERT_TYPES.warning,
                handler: this.resetDraft,
            }),
            restartDraftEditButton: new Button({
                label: this.$i18n.t('generic.refreshPage'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.$router.go(0),
            }),
            ICON_TYPES,
            EDITOR_MODE,
            BUTTON_TYPES,
            LABEL_COLOR,
            STATUS_CODES,
        };
    },
    validations: {
        data: {
            name: {
                required,
            },
            sort_priority: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters([Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED, Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]),
        categoryId() {
            return this.$route.params.id;
        },
        canToggleUniqueFlag() {
            return !this.referencingOffers.length || this.initUnique;
        },
        categories() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.CATEGORY);
        },
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        referencingOffers() {
            return this.offers.filter(o => o.categories?.find(c => c === this.categoryId));
        },
        parentsList() {
            return this.categories.filter(c => c.id !== this.categoryId && c.state === EntityStateMapping.APPROVED);
        },
        categoryPageTitle() {
            if (this.readonly) {
                return `${this.capitilizedEntityType}`;
            }
            if (this.isEditing) {
                return `${this.$i18n.t('generic.edit')} ${this.capitilizedEntityType} ${this.$i18n.t(
                    'generic.stateMap.draft',
                )}`;
            }
            return `${this.$i18n.t('generic.addNew')} ${this.capitilizedEntityType}`;
        },
        allowViewPublishedBtn() {
            return this.$route.params.id && !this.readonly && !this.isOnlyDraft;
        },
        allowEditDraftBtn() {
            return this.$route.params.id && this.readonly && !this.isOnlyDraft;
        },
        isDraft() {
            return this.$route.params.id && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.$route.params.id && this.readonly;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
    },

    mounted() {
        this.$withLoadingSpinner(async () => {
            if (this.$route.params.id && this.$route.params.mode) {
                this.readonly = this.$route.params.mode === EDITOR_MODE.VIEW;
            }

            await this.fetchData();

            await this.setData();

            this.addWatcher('$data');
        });
    },

    methods: {
        ...mapActions([Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        async fetchData() {
            try {
                this.$Progress.start();
                const loadPromises = [
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY }),
                ];
                if (!this.$route.params.clone) {
                    loadPromises.push(this.loadEntityDraft());
                }
                await Promise.all(loadPromises);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: 'categories',
                    }),
                });
            }
        },
        selectVersion(entry) {
            this.currentVersion = entry;
            this.setData();
        },
        async loadEntityDraft() {
            try {
                const { id } = this.$route.params;
                // Cannot load draft if we don't have an id
                if (!id) {
                    return;
                }

                // Read-only mode does not see draft data
                if (this.readonly) {
                    return;
                }

                // Load draft data
                const result = await getEntityDraft(this.entityType, id);

                // If the data contents of the draft is empty then we consider it null
                // Short circuit so we don't consider this a valid draft
                const isNullDraft = isEmpty(result?.data?.data?.[id]?.data);
                if (isNullDraft) {
                    return;
                }

                // Construct entityDraft data
                this.entityDraft = new ProductCatalogCategoryModel({
                    ...result.data.data[id],
                });

                // Set unpublished flag since we have a draft (that's not null)
                this.isUnpublished = true;
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadDraftData'), { type: ALERT_TYPES.error });
                Sentry.captureException(error);
            }
        },
        async loadVersionHistory(entityType, id) {
            this.versionsHistory = await loadVersionHistory(entityType, id);
            [this.currentVersion] = this.versionsHistory;
        },
        async loadHistoryEntity(entityType, id) {
            if (id && this.currentVersion.version) {
                await this.$withLoadingSpinner(
                    async () => {
                        const result = await getHistoryVersion(id, entityType, this.currentVersion.version);
                        if (result?.data?.specific_entity_version && this.readonly) {
                            const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
                            this.historyEntity = new EntityTypeModel(result.data.specific_entity_version);
                        }
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        async onSave(isPublish = true, stayInEditor = false) {
            // prevent sending requests until the first one is resolved
            if (this.isSaveButtonClicked) {
                return;
            }

            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.isSaveButtonClicked = true;
            this.$v.$touch();

            if (this.$v.$invalid || !this.validateUUID()) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                this.isSaveButtonClicked = false;
                this.addAlertWatcher('$data');
            } else {
                try {
                    if (this.getNestingDepth() > this.maxNestingDepth) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorExceededMaxDepth'),
                        });
                        return;
                    }

                    this.$Progress.start();
                    if (this.data?.miscParameters?.data?.length) {
                        this.data.misc_params = getObjectFromArray(this.data.miscParameters.data);
                    }

                    this.$delete(this.data, 'miscParameters');

                    // Send changes to DNO
                    const isEditingEntity = this.$route.params.id && !this.$route.params.clone && !this.isOnlyDraft;
                    if (isPublish) {
                        if (isEditingEntity) {
                            await updateCategory(this.$route.params.id, this.version, this.data);
                        } else {
                            await addCategory({
                                approve_on_create: this.approveOnCreate,
                                data: this.data,
                                ...(this.$route.params.id &&
                                    !this.$route.params.clone && { entity_id: this.$route.params.id }),
                                ...(this.uuid && { entity_id: this.uuid }),
                            });
                        }
                    } else {
                        await this.saveEntityDraft();
                    }

                    // Success alert
                    this.$eventBus.$emit('showAlert', {
                        message: this.successSaveMessage,
                        type: ALERT_TYPES.success,
                    });

                    this.$Progress.finish();
                    this.entityEditorMixin.successfullySaved = true;
                    if (stayInEditor) {
                        this.reloadEditor(EDITOR_MODE.EDIT);
                    } else {
                        setTimeout(
                            () =>
                                this.$router.push({
                                    name: RouteNames.CATEGORY_LIST,
                                    params: { companyId: this.$route.params.companyId },
                                }),
                            1000,
                        );
                    }
                } catch (e) {
                    if (
                        isAxiosError(e) &&
                        e.response?.data &&
                        e.response.data.module === modules.CATALOG &&
                        e.response.data.code === luaErrors.CATALOG.WRONG_VERSION.code
                    ) {
                        this.$alert(
                            this.$i18n.t('alertMessage.pleaseRestartDraftEdit', {
                                entityName: this.$i18n.t('productCatalog.entities.category').toLowerCase(),
                            }),
                            {
                                buttons: [this.restartDraftEditButton],
                            },
                        );
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: 'saving',
                                entityName: this.$i18n.t('productCatalog.entities.category').toLowerCase(),
                            }),
                        });
                    }
                    this.$Progress.fail();
                    this.isSaveButtonClicked = false;
                    Sentry.captureException(e);
                }
            }
        },
        saveEntityDraft() {
            return setEntityDraft(
                {
                    ...(!this.isEditing && this.uuid && { id: this.uuid }),
                    ...(this.isEditing && { id: this.$route.params.id }),
                    data: this.data,
                },
                this.entityType,
            );
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async resetDraft() {
            await this.setData(true);
            this.onSave(false, true);
        },
        async setData(forceInitPublished = false) {
            if (this.$route.params.id) {
                if (this.readonly) {
                    this.loadVersionHistory(this.entityType, this.$route.params.id);
                }
                let currentlyEditedCategory;
                if (this.entityDraft && !forceInitPublished) {
                    currentlyEditedCategory = this.entityDraft;
                    currentlyEditedCategory.version = this[Getters.PC_GET_ENTITY_BY_TYPE_AND_ID](
                        ENTITY_TYPES.CATEGORY,
                        this.$route.params.id,
                    ).version;
                    const publishedEntity = this[Getters.PC_GET_ENTITY_BY_TYPE_AND_ID](
                        ENTITY_TYPES.CATEGORY,
                        this.$route.params.id,
                    );
                    this.isOnlyDraft = publishedEntity?.state === 24;
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityType, this.$route.params.id);
                    currentlyEditedCategory = cloneDeep(this.historyEntity);
                } else {
                    currentlyEditedCategory = this[Getters.PC_GET_ENTITY_BY_TYPE_AND_ID](
                        ENTITY_TYPES.CATEGORY,
                        this.$route.params.id,
                    );
                }
                this.getUpdateUserName(currentlyEditedCategory.updateUser);

                this.initUnique = currentlyEditedCategory.unique;
                this.state = currentlyEditedCategory.state;
                this.data = {
                    name: { [this.selectedLanguage]: currentlyEditedCategory.name },
                    description: { [this.selectedLanguage]: currentlyEditedCategory.description },
                    sort_priority: currentlyEditedCategory.sortPriority,
                    unique: currentlyEditedCategory.unique,
                    parent: null,
                    updateTime: currentlyEditedCategory.updateTime,
                };

                if (currentlyEditedCategory.miscParams) {
                    this.initMiscParameters = getArrayFromObject(currentlyEditedCategory.miscParams);
                }

                if (currentlyEditedCategory.parent) {
                    this.data.parent = currentlyEditedCategory.parent;
                }

                this.version = currentlyEditedCategory.version;
            }

            if (forceInitPublished && !this.isOutdatedDraft) {
                this.$alert(this.$i18n.t('productCatalog.editors.revertSuccess'), { type: ALERT_TYPES.success });
            }

            if (this.$route.params.clone) {
                this.data.name[this.selectedLanguage] += ' (cloned)';
            }
        },
        getNestingDepth() {
            let layerAmount = 0;
            let parentId = this.data.parent;

            while (parentId) {
                const filterParent = this.findParent(parentId);

                parentId = filterParent.parent;
                layerAmount += 1;
            }

            return layerAmount;
        },
        findParent(parentId) {
            return this.parentsList.find(parent => parent.id === parentId);
        },
        hideUnapprovedError() {
            this.parentApproved = true;
        },
        checkDataBeforeSave() {
            if (!this.data.name[this.selectedLanguage]) {
                this.requiredErrors.showNameFieldRequiredError = true;
                return false;
            }
            return true;
        },
        validateUUID() {
            if (this.uuid) {
                return isValidUuid(this.uuid);
            }
            return true;
        },
        openAdvanced() {
            this.advanced = true;
        },
        closeAdvanced() {
            this.uuid = '';
            this.advanced = false;
        },
        applyAdvanced() {
            this.advanced = false;
        },
        reloadEditor(mode) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({ name: RouteNames.CATEGORY_LIST, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CATEGORY_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), { type: ALERT_TYPES.error });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.gray-bar {
    background-color: $white;
    font-weight: bold;
    margin-bottom: 18px;

    .select {
        font-weight: initial;
    }
}

.product-type-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    text-align: right;
    color: $gray60;
}

.key-value-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    text-align: right;
    color: $gray60;
    align-self: end;
}

.buttons-row {
    margin: 8px;
}

.form-content {
    min-height: calc(100vh - 130px);
}

.label-danger {
    margin-left: 19px;
}
.labels-and-buttons-bar > * {
    margin-left: 1rem;
}
.state-label {
    height: 1.5rem;
}

.warning {
    color: $red;
}

.section-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 2.125rem 0 1.25rem;
    color: $gray90;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue5;
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}
</style>
