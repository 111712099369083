// axios initialization done here and its instance exporting

import Axios, { AxiosError, AxiosResponse } from 'axios';

import eventBus from '@/eventBus';

import VueCookie from 'vue-cookie';

import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrors from '@/common/luaErrors';
import { template, templateSettings } from 'lodash';

export type DnoResponseBase = {
    module: string;
    code: number;
    server_time: number;
    request_id: string;
};

export type Entity<T> = {
    id: string;
    state: EntityState;
    type: string;
    update_time: number;
    version: number;
    data: T;
};

export enum EntityState {
    UNAPPROVED = 1,
    APPROVED = 2,
    PAUSED = 3,
    DELETED = 4,
    PENDING = 5,
    REJECTED = 6,
    EXPIRED = 7,
}

export enum PromiseAllSettledStatus {
    REJECTED = 'rejected',
    FULFILLRF = 'fulfilled',
}

export type PromiseAllSettled<T, E = any> = {
    status: PromiseAllSettledStatus;
    reason?: AxiosResponse<E>;
    value?: T;
};

export type LocalizedString = Record<string, string>;

const axiosConfig = {
    baseURL: process.env.VUE_APP_HTTP_CLIENT_BASE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': process.env.VUE_APP_HTTP_CLIENT_ACCESS_CONTROL_ALLOW_ORIGIN,
    },
    transitional: {
        // throw ETIMEDOUT error instead of generic ECONNABORTED on request timeouts
        clarifyTimeoutError: true,
    },
};

const axios = Axios.create(axiosConfig);

if (process.env.NODE_ENV === 'test') {
    axios.interceptors.request.use(function (config) {
        const msg = `ERROR: Detected an HTTP request being made from within a unit test: ${config.url}`;
        console.trace(msg);
        throw msg;
    });
}

// Set the "x-lf-company-id" header by pulling the company from the URL.
//
// Historically, portal APIs used to pull the company from cookies. However,
// cookies are a non-ideal method for communicating the company (in particular,
// it prevents having different tabs on different companies for the same portal
// instance).
//
// With the change to include company IDs in URLs, we can now do away with
// a cookie-based approach, but we now need to take the hackish approach of
// pulling the company ID from the URL (in lieu of changing all usages of
// Axios).
//
axios.interceptors.request.use(config => {
    const companyId = window.location.hash.match('^#/(?<companyId>[0-9]+)/.*$')?.groups?.companyId;
    // dumb way to check if this is external request. For internal, we use relative path without http. And 'Host' header is not set yet
    if (companyId && !config.url?.startsWith('http')) {
        config.headers['x-lf-company-id'] = companyId;
    }

    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.code === AxiosError.ECONNABORTED) {
            // Return whole response object instead of Promise.reject()
            // in case of aborted API call(e.g. reload)
            return error;
        }

        if (error.response) {
            const { status, data } = error.response;

            // Handling in case of session expiring
            if (status === 401) {
                VueCookie.delete('lf-portal-session');
                window.location.reload();
            }

            if (status >= 400 && status < 600) {
                // We know HTTP status code is within range, but not all APIs actually use this format.
                // This format is primerally for Lua powered APIs

                // Checking response format
                const isDnoErrorResponse =
                    Object.prototype.hasOwnProperty.call(data, 'module') &&
                    Object.prototype.hasOwnProperty.call(data, 'code');
                if (isDnoErrorResponse) {
                    // If this if has passed we can safely read the fields
                    const responseModuleErrors = luaErrors[data.module] || {};

                    // Sadly our structure does not yield direct access to the info we get back from the BE so we need
                    // to run a search. Remapping and keeping a map of code values === to the error names might make it
                    // direct access but might require another little JS script to output that array so we can keep it
                    // + which makes it a bit more annoying to maintain. So might as well search.

                    const responseError = Object.values(responseModuleErrors).find(e => e.code === data.code);

                    // Check if the mapped error exists and if it has the message provided in it.
                    if (responseError?.errorMessage) {
                        // custom lodash template delimiter {}
                        templateSettings.interpolate = /{([\s\S]+?)}/g;
                        const errorTemplate = template(responseError.errorMessage as string);
                        eventBus.$emit('showAlert', {
                            message: !!data.details ? errorTemplate(data.details) : responseError.errorMessage,
                            type: ALERT_TYPES.error,
                        });
                        error.isInterceptorAlertShown = true;
                    }
                }
            }
        }

        return Promise.reject(error);
    },
);

export default axios;
