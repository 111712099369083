




































































import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import DataflowModelSection from '@/__new__/features/dataflows/DataflowModelSection.vue';

// Http
import { dataflowValidation, createDataflow } from '@/__new__/services/dno/dataflows/http/dataflows';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Helpers
import {
    DataflowTemplate,
    DataflowSection,
    SectionElement,
    SECTION_TYPES,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import {
    createDataflowApiPayloadData,
    convertDataflowNameOfId,
} from '@/__new__/features/dataflows/common/dataflowHelper';
import DataflowSectionModel from '@/__new__/services/dno/dataflows/models/DataflowSectionModel';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { cloneDeep } from 'lodash';

export default Vue.extend({
    name: 'DataflowsEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppButton,
        DataflowModelSection,
    },
    mixins: [validationMixin],
    data() {
        return {
            BUTTON_TYPES,
            dataflowName: '' as string,
            dataflowId: '' as string,
            selectedTemplate: null as DataflowTemplate | null,
            dataflowTree: {},
            data: {} as Record<string, string>,
            dataflowActions: [] as string[],
            disableCreateDataflowBtn: true as boolean,
        };
    },
    validations: {
        dataflowName: {
            required,
        },
        selectedTemplate: {
            id: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters(Modules.dataflows, [Getters.GET_DATAFLOW_TEMPLATES, Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL]),
        getAllTemplates(): DataflowTemplate[] {
            return this[Getters.GET_DATAFLOW_TEMPLATES] || [];
        },
        getDataflowUiModel(): DataflowSection[] {
            return this[Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL] || [];
        },
        getSelectedTemplateDesc(): string {
            return this.selectedTemplate?.description || '';
        },
        getSelectedTemplateId(): string | null {
            return this.selectedTemplate?.id || null;
        },
        getSelectedDataflowTreeValues(): DataflowSection[] {
            return this.dataflowTree?.[this.getSelectedTemplateId] || [];
        },
    },
    watch: {
        getSelectedDataflowTreeValues() {
            this.disableCreateDataflowBtn = true;
            this.dataflowActions = [];
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                await this[Actions.REQUEST_DATAFLOW_TEMPLATES]();
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.dataflows, [
            Actions.REQUEST_DATAFLOW_TEMPLATES,
            Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL,
        ]),
        createDataflowId(): void {
            this.dataflowId = convertDataflowNameOfId(cloneDeep(this.dataflowName));
        },
        templateSelected(template: DataflowTemplate): void {
            this.$withLoadingSpinner(
                async () => {
                    if (!this.dataflowTree?.[template.id]) {
                        await this[Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL](template.id);
                        const data = this.getDataflowUiModel?.map(e => new DataflowSectionModel(e)) || [];
                        Vue.set(this.dataflowTree, template.id, data);
                    }
                },
                {
                    errorHandler: (e: any) => {
                        this.$eventBus.$emit('showAlert', {
                            message:
                                e?.response?.data?.message || this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        validateDataflow(): void {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$withLoadingSpinner(
                async () => {
                    this.data = createDataflowApiPayloadData(this.getSelectedDataflowTreeValues);
                    const res = await dataflowValidation(this.getSelectedTemplateId, this.dataflowId, {
                        name: this.dataflowName,
                        ui_input: this.data,
                    });

                    this.dataflowActions = res?.data?.data.actions;
                    this.disableCreateDataflowBtn = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.disableCreateDataflowBtn = true;
                        this.$eventBus.$emit('showAlert', {
                            message:
                                e?.response?.data?.message || this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid || this.validateSelectedDataflowTree(this.getSelectedDataflowTreeValues)) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.helpers.invalidStringPassed'),
                });
                return false;
            }

            return true;
        },
        validateSelectedDataflowTree(data: any[]): boolean {
            let error = false;
            data?.forEach((section: DataflowSection) => {
                if (section.collapsed === false) {
                    section.elements.forEach((element: SectionElement) => {
                        if (
                            element.type === SECTION_TYPES.SECTION &&
                            this.validateSelectedDataflowTree(section.elements)
                        ) {
                            error = true;
                        } else if (
                            element.type !== SECTION_TYPES.SECTION &&
                            element.required &&
                            element.value === null
                        ) {
                            element.error = true;
                            error = true;
                        }
                    });
                }
            });

            return error;
        },
        createDataflow(): void {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.data = createDataflowApiPayloadData(this.getSelectedDataflowTreeValues);
                    await createDataflow(this.getSelectedTemplateId, this.dataflowId, {
                        name: this.dataflowName,
                        ui_input: this.data,
                    });

                    this.$Progress.finish();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('dataflows.dataflowCreated'),
                        type: ALERT_TYPES.success,
                    });

                    setTimeout(
                        () =>
                            this.$router
                                .push({
                                    name: RouteNames.HOME_VIEW,
                                    params: { companyId: this.$route.params.companyId },
                                })
                                .then(() => {
                                    this.$router.push({
                                        name: RouteNames.DATAFLOWS_EDITOR,
                                        params: { companyId: this.$route.params.companyId },
                                    });
                                }),
                        1001,
                    );
                },
                {
                    errorHandler: (e: any) => {
                        this.$Progress.fail();
                        this.disableCreateDataflowBtn = true;
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message:
                                e?.response?.data?.message || this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
    },
});
