import HTTP from '@/http';
import Campaign, { BackendCampaign } from '@/__new__/services/dno/campaigns/models/Campaign';
import { AxiosPromise } from 'axios';
import { uuidV4 } from '@/common/utils';

type AxiosEmptyResponse = AxiosPromise<Record<string, never>>;

const campaignsRoutePrefix = '/v3cepcampaigns/cep-site-backend';

export function getCampaignCarrierApps() {
    return HTTP.get(`${campaignsRoutePrefix}/carrierapps`);
}

export function getAllCampaigns(include_deleted = false): AxiosPromise<BackendCampaign[]> {
    return HTTP.post(`/campaign/get_all`, {
        option: {
            include_deleted,
        },
    });
}
export function getCampaignPriorities(): AxiosPromise<any> {
    return HTTP.post(`/campaign/available_priorities`);
}

export function getCampaign(id: string): AxiosPromise<BackendCampaign> {
    return HTTP.post('/campaign/get', {
        id,
    });
}

export function startCampaign(id: string): AxiosEmptyResponse {
    return HTTP.post('/campaignwrite/start', { id });
}

export function stopCampaign(id: string): AxiosEmptyResponse {
    return HTTP.post('/campaignwrite/stop', { id });
}

export function removeCampaign(id: string, version: number): AxiosEmptyResponse {
    return HTTP.post('/campaignwrite/delete', {
        id,
        version,
    });
}

export function storeCampaign(campaign: Campaign): AxiosEmptyResponse {
    return HTTP.post('/campaignwrite/add', {
        data: {
            ...campaign.buildJson(),
            id: uuidV4(),
        },
    });
}

export function updateCampaign(campaign: Campaign): AxiosEmptyResponse {
    return HTTP.post('/campaignwrite/update', {
        id: campaign.id,
        version: campaign.version,
        data: campaign.buildJson(),
    });
}

// TODO: move to common 'types' folder when it's created, because it's being used in files outside of CEP
export interface InboxHistoryMessage {
    insert_timestamp: number;
    caller_message_id: string;
    misc: Record<string, any>;
    operator_name: string;
    message: string;
    target_id: string;
    channel_type: number;
    target_type: number;
    message_id: string;
}

/**
 * Get notes
 * https://github.com/lotusflare/lua/blob/master/services_root/services/inbox/resources/swagger/Inbox.yaml
 * @param {string} target_id - Account/User/Entity ID
 * @param {number} channel_type - Channel type ( 0-email/1-sms/3-push )
 * @param {number} target_type - Entity type (2-account/3-user/etc...)
 * @param {number} page_size - Page size default 50.
 * @param {number} max_results - Max result default 200.
 * @returns {AxiosPromise}
 */
export function getInboxHistory(
    target_id: string,
    channel_type: number,
    target_type: number,
    page_size = 50,
    max_results = 200,
): AxiosPromise<InboxHistoryMessage[][]> {
    return HTTP({
        url: '/cepdew/inbox/api/v3/inbox/get',
        method: 'POST',
        data: {
            target_id,
            channel_type,
            target_type,
            page_size,
            max_results,
            fetch_for_message_history: true,
        },
    });
}

export default {
    getAllCampaigns,
    getCampaign,
    startCampaign,
    stopCampaign,
    removeCampaign,
    storeCampaign,
    updateCampaign,
    getCampaignCarrierApps,
    getInboxHistory,
};
