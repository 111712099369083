// Triggers file download from a link
// Has beetter performance than 'downloadjs' for
// larger files that are not streamed or blobs(e.g. zips)
export function downloadFile(url: string) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', '');
    a.setAttribute('hidden', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
