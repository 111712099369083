export const QuietHoursTypes = {
    daily: {
        field: 'DayRule',
        id: 'daily',
        i18nLabel: 'quietHours.editor.day',
        title: 'quietHours.editor.dailyQuietHoursTitle',
    },
    holiday: {
        field: 'HolidayRule',
        id: 'holiday',
        i18nLabel: 'quietHours.editor.holiday',
        title: 'quietHours.editor.holidayQuietHoursTitle',
    },
};

export const dayOfWeek = {
    1: 'generic.weekDays.monday',
    2: 'generic.weekDays.tuesday',
    3: 'generic.weekDays.wednesday',
    4: 'generic.weekDays.thursday',
    5: 'generic.weekDays.friday',
    6: 'generic.weekDays.saturday',
    7: 'generic.weekDays.sunday',
};

export const i18nShortDayOfWeek = [
    'generic.weekDays.short.monday',
    'generic.weekDays.short.tuesday',
    'generic.weekDays.short.wednesday',
    'generic.weekDays.short.thursday',
    'generic.weekDays.short.friday',
    'generic.weekDays.short.saturday',
    'generic.weekDays.short.sunday',
];

export const dayRangeTypes = {
    timeRange: {
        field: 'TimeRange',
    },
    allDay: {
        field: 'AllDay',
    },
};

export const holidayRangeTypes = {
    single: {
        field: 'SingleDate',
        i18nLabel: 'quietHours.editor.date',
        id: 'single',
    },
    range: {
        field: 'DateRange',
        i18nLabel: 'quietHours.editor.dateRange',
        id: 'range',
    },
};

export const dailyTimeframeTypes = {
    timeRange: {
        i18nLabel: 'quietHours.editor.timeRange',
        id: 'TimeRange',
    },
    allDay: {
        i18nLabel: 'quietHours.editor.allDay',
        id: 'AllDay',
    },
};
