











































import Vue, { PropType } from 'vue';

// Components
import SessionsData from '@/__new__/features/charging/bucketWizard/SessionsData.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { TranslateResult } from 'vue-i18n';
import { clearSessions } from '@/__new__/services/dno/charging/http/bucketWizard';
import { Session, SESSION_TYPES } from '@/__new__/services/dno/charging/common/bucketWizardHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'BucketWizardSessions',
    components: {
        SessionsData,
        AppMultiselectV3,
        AppButton,
    },
    props: {
        value: {
            type: Object as PropType<Record<string, Session>>,
            required: true,
        },
        targetId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            selectedSessions: [] as { name: string; type: string }[],
            alertButtons: {
                clearSessions: new Button({
                    label: this.$i18n.t('charging.bucketWizard.clearSessions'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,
        };
    },
    computed: {
        sessionOptions(): { name: string; type: string }[] {
            return Object.keys(this.value).reduce((data: any, sessionType: string) => {
                const sessions = Object.keys(this.value[sessionType]).map((sessionName: string) => ({
                    name: sessionName,
                    type: sessionType,
                }));

                return [...data, ...sessions];
            }, []);
        },
        disabledClearSessionsBtn(): boolean {
            return Boolean(this.selectedSessions.length);
        },
    },
    methods: {
        mapSessionTypeToText(type: SESSION_TYPES): TranslateResult {
            switch (type) {
                case SESSION_TYPES?.SESSIONS:
                    return this.$i18n.t('charging.bucketWizard.activeSessions');
                case SESSION_TYPES?.SESSIONS_GX:
                    return this.$i18n.t('charging.bucketWizard.activeGxSessions');
                case SESSION_TYPES?.SESSIONS_SY:
                    return this.$i18n.t('charging.bucketWizard.activeSySessions');
                default:
                    return '';
            }
        },
        onClearSessions(): void {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.clearSessionsConfirm'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.clearSessions],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.clearSessions.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            const sessions = this.getSessionNamesByType(SESSION_TYPES.SESSIONS);
                            const sessionsSy = this.getSessionNamesByType(SESSION_TYPES.SESSIONS_SY);
                            const sessionsGx = this.getSessionNamesByType(SESSION_TYPES.SESSIONS_GX);
                            await clearSessions(this.targetId, this.targetType, sessions, sessionsSy, sessionsGx);
                            this.selectedSessions = [];
                            this.$emit('clearSessions');
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('alertMessage.clearSessionsError'),
                                });
                            },
                        },
                    );
                }
            });
        },
        getSessionNamesByType(sessionType: SESSION_TYPES): string[] {
            return this.selectedSessions.filter(({ type }) => type === sessionType).map(({ name }) => name);
        },
    },
});
