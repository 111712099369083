







































































































































































// components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import SearchExecutionModal from '@/__new__/features/orchestrationengine/SearchExecutionModal.vue';
import ExpandExecutionPauseSidebar from '@/__new__/features/orchestrationengine/ExpandExecutionPauseSidebar.vue';
import ResponseModalOrchestrationEngine from '@/components/partials/ResponseModalOrchestrationEngine.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';

type SelectedExecution = {
    executonId?: string;
    planId?: string;
    lastState?: string;
    startedAt?: string;
    scheduledAt?: string;
    scheduledFor?: string;
    pausedTill?: string;
};

export default {
    name: 'OrchestrationListPageLayout',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        SearchExecutionModal,
        ResponseModalOrchestrationEngine,
        ExpandExecutionPauseSidebar,
    },
    mixins: [FilterTableMixin],
    props: {
        pageTitle: {
            type: String,
            default: '',
            required: true,
        },
        entityType: {
            type: String,
            default: '',
            required: true,
        },
        addNewRouteName: {
            type: String,
            default: '',
        },
        editRouteName: {
            type: String,
            default: '',
        },
        detailsRouteName: {
            type: String,
            default: '',
        },
        displayPaginationForTable: {
            type: Boolean,
            default: false,
        },
        tableColumnsData: {
            type: Array,
            default: () => [],
        },
        keyName: {
            type: String,
            default: 'id',
        },
        editId: {
            type: String,
            default: 'id',
        },
        showAddNewButton: {
            type: Boolean,
            default: false,
        },
        showEditButton: {
            type: Boolean,
            default: true,
        },
        showExpandPauseModalButton: {
            type: Boolean,
            default: false,
        },
        isSearchExecutionButtonEnabled: {
            type: Boolean,
            default: false,
        },
        executionIdIsClickable: {
            type: Boolean,
            default: false,
        },
        planIdIsClickable: {
            type: Boolean,
            default: false,
        },
        apiResponse: {
            type: Object,
            default: () => undefined,
        },
        multiselectRowsEnabled: {
            type: Boolean,
            default: false,
        },
        multiselectIdColumn: {
            type: String,
            default: null,
        },
        multiselectActions: {
            type: Array,
            default: () => [],
        },
        isWritePermissionEnabled: {
            type: Boolean,
            default: false,
        },
        entities: {
            type: Array,
            default: () => undefined,
        },
    },
    data() {
        return {
            searchQueryForTable: '' as string,
            isVisibleExecutionSearchModal: false as boolean,
            responseModalVisible: false as boolean,
            expandPauseModalVisible: false as boolean,
            selectedExecutionForExpandPause: {} as SelectedExecution,

            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
            RouteNames: RouteNames as any,
        };
    },
    computed: {
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entities) || [];
        },
    },
    methods: {
        onAddNewClick(): void {
            this.$router.push({ name: this.addNewRouteName, params: { companyId: this.$route.params.companyId } });
        },
        onMultiselectActionClick(data: { action: any; selectedRows: any }): void {
            this.$emit('multiselectActionClick', data);
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        editWorkflow(workflow: object): void {
            this.$router.push({
                name: this.editRouteName,
                params: {
                    id: workflow[this.editId],
                    companyId: this.$route.params.companyId,
                },
            });
        },
        openDetails(id: string): void {
            if (this.detailsRouteName) {
                this.$router.push({
                    name: this.detailsRouteName,
                    params: { id, companyId: this.$route.params.companyId },
                });
            }
        },
        openSearchExecutionModal(): void {
            this.isVisibleExecutionSearchModal = true;
        },
        closeSearchExecutionModal(): void {
            this.isVisibleExecutionSearchModal = false;
        },
        openExpandExecutionPauseModal(entity: SelectedExecution): void {
            this.expandPauseModalVisible = true;
            this.selectedExecutionForExpandPause = entity;
        },
        closeExpandExecutionPauseModal(): void {
            this.expandPauseModalVisible = false;
        },
        onPauseExpanded(): void {
            this.$emit('pauseExpanded');
            this.closeExpandExecutionPauseModal = false;
        },
        onReachedLastPage(val: object): void {
            if (this.displayPaginationForTable) {
                this.$emit('onReachedLastPage', val);
            }
        },
    },
};
