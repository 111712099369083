import http from '@/http/index';
import { type AxiosPromise } from 'axios';

/**
 * @returns {AxiosPromise}
 */
export function getApplications(): AxiosPromise<any> {
    return http('/v3operateapiorchestration/getapplications');
}

/**
 * @returns {AxiosPromise}
 */
export function getApplicationOwners(): AxiosPromise<any> {
    return http('/v3operateapiorchestration/getapplicationowners');
}

/**
 * @returns {AxiosPromise}
 */
export function getApiProducts(): AxiosPromise<any> {
    return http('/v3operateapiorchestration/getapiproducts');
}

/**
 * @returns {AxiosPromise}
 */
export function getApiProductOrders(): AxiosPromise<any> {
    return http('/v3operateapiorchestration/getapiproductorders');
}

/**
 * @returns {AxiosPromise}
 */
export function createApplication(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapplication',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function createApplicationOwner(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapplicationowner',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function createApiProductOrder(data: Record<string, any>): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3operateapiorchestration/addapiproductorder',
        data,
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getApiTransactions(data: {
    start_timestamp: number;
    end_timestamp: number;
    target_id: string;
    target_type: 1;
}): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingtransaction/getapitransactions',
        data: {
            ...data,
            // Notice: API fails for all other operator names other than 'lotusflaretelecom'
            // @todo Remove once BE does proper implementation.
            operator_name: 'lotusflaretelecom',
        },
    });
}
