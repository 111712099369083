








// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { LABEL_COLOR } from '@/common/labelsHelper';
import { TranslateResult } from 'vue-i18n';
import { DEVICE_STATE } from '@/__new__/services/dno/user/models/Session';

export default {
    name: 'DeviceStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: [Number],
        },
    },
    computed: {
        statusConverter(): TranslateResult {
            if (this.status === DEVICE_STATE.ACTIVE) {
                return this.$i18n.t('generic.stateMap.active');
            }
            if (this.status === DEVICE_STATE.INACTIVE) {
                return this.$i18n.t('generic.inactive');
            }

            return this.$i18n.t('generic.N/A');
        },
        colorConverter(): string | undefined {
            if (this.status === DEVICE_STATE.ACTIVE) {
                return LABEL_COLOR.green;
            }
            if (this.status === DEVICE_STATE.INACTIVE) {
                return LABEL_COLOR.red;
            }

            // fallback to default
            return undefined;
        },
    },
};
