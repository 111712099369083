import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';

interface ConditionParametersApiResponse extends DnoResponseBase {
    condition_parameter_by_id: Record<string, any>;
}

/**
 * Get condition parameters.
 *
 * @returns {AxiosPromise}
 */
export function getConditionParameters(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<ConditionParametersApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getconditionparameters',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add condition parameter.
 *
 * @returns {AxiosPromise}
 */
export function addConditionParameter(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addconditionparameter',
        data,
    });
}

/**
 * Update condition parameter.
 *
 * @returns {AxiosPromise}
 */
export function updateConditionParameter(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updateconditionparameter',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update condition parameter state.
 *
 * @returns {AxiosPromise}
 */
export function updateStateConditionParameter(
    id: string,
    version: number,
    state: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestateconditionparameter',
        data: {
            id,
            version,
            state,
        },
    });
}

interface ConditionParametersDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get condition parameter draft list.
 *
 * @returns {AxiosPromise}
 */
export function getConditionParametersDraftList(): AxiosPromise<ConditionParametersDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getconditionparametersdraftlist',
    });
}

/**
 * Get condition parameter entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getConditionParameterEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getconditionparameterdraft',
        data: {
            id,
        },
    });
}

/**
 * Set condition parameter draft.
 *
 * @returns {AxiosPromise}
 */
type ConditionParameterDraftPayload = {
    id?: string;
    data: object;
};

export function setConditionParameterDraft(data: ConditionParameterDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setconditionparameterdraft',
        data,
    });
}

/**
 * Delete condition parameter draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteConditionParameterDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deleteconditionparameterdraft',
        data: {
            id,
        },
    });
}

export default {
    getConditionParameters,
    addConditionParameter,
    updateConditionParameter,
    updateStateConditionParameter,
    getConditionParametersDraftList,
    getConditionParameterEntityDraft,
    setConditionParameterDraft,
    deleteConditionParameterDraft,
};
