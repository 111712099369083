<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.apiProductOrders')"
        :entityType="'api_product_order'"
        :entityResponse="apiProductOrdersApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApiProductOrder')"
        :writePermission="isUserAllowed('OperateAPIsProductOrdersWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectEntity"
    >
        <template slot="sidebarOverview">
            <OverviewList :lists="detailsSections" />
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';

// HTTP
import { getApiProductOrders } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';

export default {
    name: 'ApiProductOrdersListPage',

    components: {
        OperateApisListPageLayout,
        OverviewList,
    },

    data() {
        return {
            apiProductOrdersApiResponse: {},
            entityData: [],
            addNewRouteName: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_EDITOR,
            selectedApiProductOrder: null,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApiProductOrder ? this.selectedApiProductOrder.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApiProductOrder?.state || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.state'),
                        },
                        {
                            value: this.selectedApiProductOrder?.createOn || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('benefits.creationDate'),
                        },
                        {
                            value: this.selectedApiProductOrder?.completionDate || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('customerCare.userInformation.portingColumns.dateCompletion'),
                        },
                        {
                            value: this.selectedApiProductOrder?.targetApplication || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.targetApplication'),
                        },
                    ],
                },
            ];
        },
    },

    async created() {
        await this.$withProgressBar(
            async () => {
                await getApiProductOrders().then(response => {
                    this.apiProductOrdersApiResponse = response;

                    this.entityData = response.data.map(apiProductOrder => ({
                        id: apiProductOrder?.id || '',
                        description: apiProductOrder?.description || '',
                        state: apiProductOrder?.state || '',
                        createOn: apiProductOrder?.createOn || '',
                        completionDate: apiProductOrder?.completionDate || '',
                        targetApplication: apiProductOrder?.productOrderItem?.productAction?.targetApplication || '',
                    }));
                });
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            [this.selectedApiProductOrder] = this.entityData.filter(data => data.id === entity);
        },
    },
};
</script>
