import { FORM_BUILDER_TYPES } from '@/formBuilderConfig';
import { validateAlphaNumericWithDashAndUnderscore } from '@/common/formatting';
import i18n from '@/i18n';
import Vue from 'vue';

export const MODAL_TYPES = {
    GROUP: 'group',
    PROPERTY: 'property',
    DELETE_GROUP: 'deleteGroup',
};

export const GROUP_TYPES = {
    CUSTOM: 1,
    PRESET_TEMPLATE: 2,
};

export const REFERENCED_ENTITY_TYPES = [
    {
        id: 'csg',
        label: i18n.t('charging.entities.chargingSpecificationsGroup'),
    },
    {
        id: 'tsg',
        label: i18n.t('charging.entities.tariffSpecificationGroup'),
    },
    {
        id: 'voucher',
        label: i18n.t('vouchers.voucherSet'),
    },
    {
        id: 'lottery',
        label: i18n.t('rewards.lottery.lottery'),
    },
];

export const groupModalInitObj = {
    type: GROUP_TYPES.CUSTOM,
    name: '',
    description: '',
    groupInvalid: false,
    groupPresetInvalid: false,
    fields: [],
    id: null,
    preset: null,
};

export const propertyModalInitObj = {
    group: '',
    groupInvalid: false,
    typeInvalid: false,
    externalIdInvalid: false,
    isExternalIdEmpty: false,
    dataType: FORM_BUILDER_TYPES.INPUT,
    data: {},
    showValidation: false,
    id: null,
    mutationFields: {},
    isPropSaved: false,
    externalId: '',
    additionalExternalKey: '',
    defaultValue: '',
};

export default {
    name: 'TemplatesModalMixin',

    data() {
        return {
            isModalVisible: false,
            modalType: MODAL_TYPES.PROPERTY,
            propertyModal: {
                ...propertyModalInitObj,
            },
            groupModal: {
                ...groupModalInitObj,
            },
            deleteGroupModal: {
                deleteAll: false,
                groupMoveTo: null,
                invalid: false,
                id: null,
            },
            GROUP_TYPES,
            REFERENCED_ENTITY_TYPES,
        };
    },

    methods: {
        chooseGroupForInPropModal(groupId) {
            if (groupId !== undefined) {
                this.propertyModal.group = this.formattedGroupsForDnd.find(({ id }) => id === groupId);
            }
        },
        onAddProperty(groupId) {
            this.chooseGroupForInPropModal(groupId);
            this.showPropertyModal();
        },
        showPropertyModal() {
            this.modalType = MODAL_TYPES.PROPERTY;
            this.isModalVisible = true;
        },
        showGroupModal() {
            this.modalType = MODAL_TYPES.GROUP;
            this.isModalVisible = true;
        },
        showDeleteGroupModal() {
            this.modalType = MODAL_TYPES.DELETE_GROUP;
            this.isModalVisible = true;
        },
        clearPresetError() {
            this.groupModal.groupPresetInvalid = false;
        },
        onModalSaveClick() {
            if (this.modalType === MODAL_TYPES.GROUP) {
                if (this.groupModal.type === GROUP_TYPES.PRESET_TEMPLATE) {
                    if (this.groupModal.preset) {
                        if (this.groupModal?.id !== undefined && this.groupModal.id !== null) {
                            this.rewriteGroup(
                                {
                                    ...this.groupModal.preset,
                                    type: this.groupModal.type,
                                },
                                this.groupModal.id,
                            );
                        } else {
                            this.addGroup({
                                ...this.groupModal.preset,
                                type: this.groupModal.type,
                            });

                            this.resetGroupModalData();
                        }
                    } else {
                        this.groupModal.groupPresetInvalid = true;
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.pc.fillRequiredFields'),
                        });
                        return;
                    }
                } else if (this.groupModal.type === GROUP_TYPES.CUSTOM) {
                    if (this.groupModal.name) {
                        const sameNameGroup = Object.values(this.groups).find(
                            group => group.name === this.groupModal.name,
                        );
                        const isNewGroupNameUnique = sameNameGroup && sameNameGroup.id !== this.groupModal.id;
                        if (isNewGroupNameUnique) {
                            this.groupModal.groupInvalid = true;
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('alertMessage.pc.groupNameShouldBeUnique'),
                            });
                            return;
                        }
                    } else {
                        this.groupModal.groupInvalid = true;
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.pc.fillRequiredFields'),
                        });
                        return;
                    }

                    if (this.groupModal?.id !== undefined && this.groupModal.id !== null) {
                        this.rewriteGroup({
                            id: this.groupModal.id,
                            name: this.groupModal.name,
                            description: this.groupModal.description,
                            type: this.groupModal.type,
                        });
                    } else {
                        this.addGroup({
                            name: this.groupModal.name,
                            description: this.groupModal.description,
                            type: this.groupModal.type,
                            fields: [],
                        });

                        this.resetGroupModalData();
                    }
                }
            } else if (this.modalType === MODAL_TYPES.PROPERTY) {
                let propertyInvalid = false;
                let invalidExternalId = false;
                if (!this.propertyModal.group) {
                    this.propertyModal.groupInvalid = true;
                    propertyInvalid = true;
                }

                const { externalId } = this.propertyModal;

                if (this.getIsInvalidExternalId(externalId)) {
                    this.propertyModal.isExternalIdEmpty = true;
                    invalidExternalId = true;
                }

                if (!this.validatePropertyExternalId(this.propertyModal.externalId)) {
                    this.propertyModal.externalIdInvalid = true;
                    return;
                }

                const { invalidKeys } = this.propertyModal.data;

                if (!invalidKeys || invalidKeys.length) {
                    if (externalId) {
                        this.propertyModal.isExternalIdEmpty = false;
                    }
                    this.propertyModal.showValidation = true;
                    propertyInvalid = true;
                }

                if (this.isDropdownType(this.propertyModal.dataType) && this.propertyInvalidIndices.length) {
                    propertyInvalid = true;
                }

                if (propertyInvalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                    return;
                }

                if (invalidExternalId) {
                    return;
                }

                const prop = {
                    ...this.propertyModal?.data?.value,
                    dataType: this.propertyModal.dataType,
                    groupId: this.propertyModal.group.id,
                    isRequired: this.propertyModal.isRequired || false,
                };

                if (this.isDropdownType(this.propertyModal.dataType)) {
                    prop.componentProps = {
                        options: this.propertyModal?.enum.reduce((accum, val) => {
                            accum.push(val.key);
                            return accum;
                        }, []),
                    };

                    prop.schema = {
                        enum: this.propertyModal?.enum.reduce((accum, val) => {
                            accum.push(val.key);
                            return accum;
                        }, []),
                        type: this.propertyModal.dataType,
                    };

                    prop.enumMapping = this.propertyModal?.enum.reduce((accum, val) => {
                        accum[val.key] = val.value;
                        return accum;
                    }, {});

                    if (prop.isRequired) {
                        prop.defaultValue = this.propertyModal.defaultValue;
                    }
                }

                if (this.isReferenceType(this.propertyModal.dataType)) {
                    prop.referencedType = this.propertyModal?.referencedEntityType.id;
                }

                if (this.propertyModal.data.value.name) {
                    const sameNameProperty = Object.values(this.properties).find(
                        property => property.name === this.propertyModal.data.value.name,
                    );
                    const isNewPropertyNameUnique = sameNameProperty && sameNameProperty.id !== this.propertyModal.id;
                    if (isNewPropertyNameUnique) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.pc.propertyNameShouldBeUnique'),
                        });
                        return;
                    }
                }

                if (this.propertyModal.id) {
                    const newId =
                        this.propertyModal.id !== this.propertyModal.externalId ? this.propertyModal.externalId : null;

                    prop.id = this.propertyModal.id;
                    this.rewriteProperty(prop, newId);
                } else {
                    prop.id = externalId;

                    this.addProperty(prop, true);
                    this.resetPropertyModalData();
                }
            } else if (this.modalType === MODAL_TYPES.DELETE_GROUP) {
                const isInvalid = !this.deleteGroupModal.deleteAll && !this.deleteGroupModal?.groupMoveTo;

                if (isInvalid) {
                    this.deleteGroupModal.invalid = true;
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pc.fillRequiredFields'),
                    });
                    return;
                }

                if (!this.deleteGroupModal.deleteAll) {
                    const fieldsToMove = this.formattedGroupsForDnd.find(
                        group => group.id === this.deleteGroupModal.id,
                    )?.fields;

                    fieldsToMove.forEach(({ id }) => {
                        this.moveProperty(id, this.deleteGroupModal.id, this.deleteGroupModal?.groupMoveTo?.id);
                    });
                }

                this.showDeleteGroupAlert(this.deleteGroupModal.id);
            }

            // closing modal
            this.isModalVisible = false;
        },
        cleanGroupModalData(id) {
            if (this.groupModal.id !== id) {
                this.resetGroupModalData();
            }
        },
        cleanPropertyModalData(id) {
            if (this.propertyModal.id !== id) {
                this.resetPropertyModalData();
            }
        },
        resetGroupModalData() {
            this.groupModal = {
                ...groupModalInitObj,
            };
        },
        resetPropertyModalData() {
            this.propertyModal = {
                ...propertyModalInitObj,
            };
        },
        hidePropertyValidation() {
            this.propertyModal.showValidation = false;
        },
        addMutationFields(val) {
            const type = this.propertyModal.dataType;
            const oldEnumId = this.propertyModal.data?.value?.enum;
            const newEnumId = val.value.enum;
            const shouldEnumChange = this.isDropdownType(type) && newEnumId?.length && newEnumId !== oldEnumId;

            this.propertyModal.data = val;

            if (shouldEnumChange) {
                Object.entries(val.value).forEach(([key, value]) => {
                    if (value) {
                        const mutationField = this.propertyModal.mutationFields[key];
                        this.propertyModal.mutationFields[key] = {
                            ...(mutationField || {}),
                            defaultValue: value,
                        };
                    }
                });
                this.propertyModal.mutationFields.enum = {
                    ...this.propertyModal.mutationFields.enum,
                    defaultValue: newEnumId,
                };

                this.setDefaultValueForMutationFields(!oldEnumId);
                this.rebuildMutationFields();
            }
        },
        setDefaultValueForMutationFields(setDefaultVal) {
            const defaultValue = this.propertyModal?.mutationFields?.defaultValue || {};
            if (
                this.propertyModal.dataType === FORM_BUILDER_TYPES.DROPDOWN ||
                this.propertyModal.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE
            ) {
                this.propertyModal.defaultValue = defaultValue.defaultValue;
            } else {
                this.propertyModal.mutationFields.defaultValue = {
                    ...(setDefaultVal ? defaultValue : {}),
                };
            }
        },
        getIsInvalidExternalId(externalId) {
            if (this.propertyModal.id === this.propertyModal.externalId) {
                return false;
            }

            return !externalId || !validateAlphaNumericWithDashAndUnderscore(externalId);
        },
        rebuildMutationFields() {
            this.propertyModal.mutationFields = { ...this.propertyModal.mutationFields };
        },
        hidePropertyGroupValidation() {
            this.propertyModal.groupInvalid = false;
        },
        hidePropertyTypeValidation() {
            this.propertyModal.typeInvalid = false;
        },
        hideGroupNameValidation() {
            this.groupModal.groupInvalid = false;
        },
        hideDeleteGroupValidation() {
            this.deleteGroupModal.invalid = false;
        },
        addMutationFieldsEnums(dataType) {
            if (this.isDropdownType(dataType)) {
                this.propertyModal.mutationFields = {
                    ...this.propertyModal.mutationFields,
                    enum: {
                        ...this.propertyModal.mutationFields.enum,
                        componentProps: {},
                    },
                };
            } else if (this.propertyModal.mutationFields?.enum) {
                delete this.propertyModal.mutationFields.defaultValue;
                delete this.propertyModal.mutationFields.enum;
            }
        },
        onEditGroup(id) {
            const groupData = this.groups[id];
            this.cleanGroupModalData(id);
            if (this.groupModal.id !== id) {
                this.groupModal.id = id;
                this.groupModal.type = groupData.type;
                if (groupData.type === GROUP_TYPES.CUSTOM) {
                    this.groupModal.name = groupData.name;
                    this.groupModal.description = groupData.description;
                } else if (groupData.type === GROUP_TYPES.PRESET_TEMPLATE) {
                    this.groupModal.preset = this.presetTemplatesOptions.find(opt => opt.id === id);
                }
            }
            this.showGroupModal();
        },
        onEditProperty(id) {
            const propData = this.properties[id];
            this.cleanPropertyModalData(id);
            this.propertyModal.id = id;
            this.chooseGroupForInPropModal(propData.groupId);
            this.propertyModal.externalId = id;
            this.propertyModal.dataType = propData.isTextArea ? FORM_BUILDER_TYPES.TEXTAREA : propData.dataType;
            const enumValue = propData.enumMapping
                ? Object.keys(propData.enumMapping).map(key => ({ key, value: propData.enumMapping[key] }))
                : undefined;
            Vue.set(this.propertyModal, 'enum', enumValue);
            this.propertyModal.isPropSaved = propData.isPropSaved;
            this.propertyModal.isRequired = propData.isRequired;
            Vue.set(
                this.propertyModal,
                'referencedEntityType',
                REFERENCED_ENTITY_TYPES.find(el => el.id === propData.referencedType),
            );
            this.propertyModal.mutationFields = Object.entries(propData).reduce((acc, [key, value]) => {
                acc[key] = {
                    defaultValue: value,
                };

                return acc;
            }, {});

            this.addMutationFieldsEnums(propData.dataType);
            this.setDefaultValueForMutationFields(true);
            this.showPropertyModal();
        },
        onDeleteGroup(group) {
            if (group.fields.length) {
                this.deleteGroupModal.id = group.id;
                this.showDeleteGroupModal();
            } else {
                this.showDeleteGroupAlert(group.id);
            }
        },
        onCloseModal() {
            this.isModalVisible = false;
        },
        // rewrtten in TemplateEditor
        validatePropertyExternalId() {
            return true;
        },
    },
};
