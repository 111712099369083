<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>
        <template #content>
            <!-- General section -->
            <div class="editor-section">
                <div class="d-flex align-items-center mb-3">
                    <div
                        v-show="isEditing"
                        class="lf-subtitle mb-0"
                    >
                        {{ $i18n.t('generic.general') }}
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.id') }}: {{ entityId }}</p>
                        <p class="lf-secondary-text mb-0">
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.updateUser') }}: {{ updateName }}</p>
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center justify-content-end">
                        <EntityStatusIndicator
                            v-if="showStatusIndicator"
                            :status="state"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.EDIT)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.VIEW)"
                        />
                        <AppButton
                            v-if="isRevertAvailable"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('productCatalog.editors.revertToPublished')"
                            class="ml-3"
                            @click="resetToPublished"
                        />
                    </div>
                </div>
            </div>
            <AppInputV3
                v-model="name"
                :placeholder="$i18n.t('generic.addName')"
                :label="$i18n.t('generic.name')"
                :invalid="$v.name.$error"
                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                data-test-id="name"
            />
            <div class="mb-5">
                <AppTextareaV3
                    v-model="description"
                    :placeholder="$i18n.t('charging.editor.addDescription')"
                    :label="$i18n.t('generic.description')"
                    :disabled="readonly"
                    data-test-id="description"
                    class="editor-input-largest mb-3"
                />
                <div
                    v-if="!isEmpty(conditionModel)"
                    :key="`condition-model-${conditionModel}`"
                    class="cs-form-wrapper mt-5"
                >
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="condition-label">
                                {{ $i18n.t('charging.chargingSpecifications.editor.condition') }}
                            </div>
                            <div class="condition-text">
                                {{ getTextLabelForConditionType(conditionModel.type) }}
                            </div>
                        </div>
                        <div
                            :class="['remove-condition', { 'disabled-area': readonly }]"
                            @click="removeCondition()"
                        >
                            {{ $i18n.t('generic.remove') }}
                        </div>
                    </div>
                    <div :class="['mb-4 pb-4 condition-wrapper', { 'disabled-area': readonly }]">
                        <Schedule
                            v-if="conditionModel.type === CONDITION_TYPES.SCHEDULE"
                            ref="scheduleCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <TimeInCall
                            v-if="conditionModel.type === CONDITION_TYPES.TIME_IN_CALL"
                            ref="timeInCallCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <RatingGroup
                            v-else-if="conditionModel.type === CONDITION_TYPES.RATING_GROUP"
                            ref="ratingGroupCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <Apn
                            v-else-if="conditionModel.type === CONDITION_TYPES.APN"
                            ref="apnCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <Bearer
                            v-else-if="conditionModel.type === CONDITION_TYPES.BEARER"
                            ref="bearerCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <NetworkSliceIdentifier
                            v-else-if="conditionModel.type === CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER"
                            ref="networkSliceIdentifierCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <Zone
                            v-else-if="conditionModel.type === CONDITION_TYPES.ZONE"
                            ref="zoneCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <SpecialNumber
                            v-else-if="conditionModel.type === CONDITION_TYPES.SPECIAL_NUMBER"
                            ref="specialNumberCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <TrafficType
                            v-else-if="conditionModel.type === CONDITION_TYPES.TRAFFIC_TYPE"
                            ref="trafficTypeCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <OtherPartyRNCondition
                            v-else-if="conditionModel.type === PC_CONDITION_TYPES.OTHER_PARTY_RN"
                            ref="otherPartyRNCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <Flag
                            v-else-if="
                                conditionModel.type === PC_CONDITION_TYPES.SUBSCRIBER_FLAG ||
                                conditionModel.type === PC_CONDITION_TYPES.BUCKET_FLAG
                            "
                            ref="flagCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <OtherPartyNumberLengthCondition
                            v-else-if="conditionModel.type === PC_CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH"
                            ref="otherPartyNumberLengthCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <ServiceType
                            v-else-if="conditionModel.type === PC_CONDITION_TYPES.SERVICE_TYPE"
                            ref="serviceTypeCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <SubscriberLocationCondition
                            v-else-if="conditionModel.type === PC_CONDITION_TYPES.SUBSCRIBER_LOCATION"
                            ref="subscriberLocationCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <OtherPartyNumberCondition
                            v-else-if="conditionModel.type === PC_CONDITION_TYPES.OTHER_PARTY_NUMBER"
                            ref="otherPartyNumberCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <VlrIdCondition
                            v-else-if="conditionModel.type === CONDITION_TYPES.VLR_ID"
                            ref="vlrIdCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <ServiceIdCondition
                            v-else-if="conditionModel.type === CONDITION_TYPES.SERVICE_ID"
                            ref="serviceIdCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <DataBalance
                            v-else-if="conditionModel.type === CONDITION_TYPES.DATA_BALANCE"
                            ref="dataBalanceCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                        <BucketType
                            v-else-if="conditionModel.type === CONDITION_TYPES.BUCKET_TYPE"
                            ref="bucketTypeCondition"
                            v-model="conditionModel"
                            :class="{ conditionError: conditionModel.dataEmpty }"
                        />
                    </div>
                </div>
                <div>
                    <AppMultiselectV3
                        v-if="isEmpty(conditionModel)"
                        v-model="selectedCondition"
                        data-test-id="conditionSelection"
                        :options="conditionOptions()"
                        :error="$v.selectedCondition.$error"
                        :additionalLabel="$i18n.t('charging.chargingSpecifications.editor.chooseConditionType')"
                        :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseConditionType')"
                        :small="true"
                        :showLabels="false"
                        :disabled="readonly"
                        label="label"
                        trackBy="id"
                        class="col-lg-4 col-12 mt-5"
                        @input="createNewCondition(selectedCondition)"
                    />
                </div>
                <AppTextareaV3
                    v-if="!readonly"
                    v-model="remark"
                    :label="$i18n.t('generic.remark')"
                    :disabled="readonly"
                    data-test-id="remark"
                    class="editor-input-largest mt-5"
                />
            </div>
        </template>
        <template
            v-if="readonly"
            #sideBar
        >
            <div class="lf-subtitle m-3">
                {{ $i18n.t('productCatalog.editors.versionHistory') }}
            </div>
            <div
                v-for="(entry, index) in versionsHistory"
                :key="entry.chargingVersion"
            >
                <div
                    class="d-flex lf-primary-text version-item"
                    :class="{ active: entry.chargingVersion === currentVersion }"
                    :data-test-id="`version-item-${index}`"
                    @click="selectVersion(entry)"
                >
                    <AppIcon
                        v-if="entry.chargingVersion === currentVersion"
                        type="check-new"
                        size="1rem"
                        class="icon mr-2"
                    />
                    {{ entry.chargingVersion }} - {{ entry.updateTime }}
                    <div class="version-details card-content-spacing white-card-bg">
                        <div class="info">
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.version')}:` }}
                                </span>
                                {{ entry.chargingVersion }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.lastUpdatedTime')}:` }}
                                </span>
                                {{ entry.updateTime }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.remark')}:` }}
                                </span>
                                {{ entry.remark }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.updateUser')}:` }}
                                </span>
                                {{ entry.userName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modal>
            <MutationDialog
                v-model="showModal"
                :saveButtonEnabled="true"
                :entities="getAffectedEntities()"
                @updateEntity="saveAfterConfirm"
            />
        </template>
        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="readonly || disableSaveButton"
                data-test-id="submit-button"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave"
                @save="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Conditions
import Schedule from '@/__new__/features/charging/conditionParameters/conditions/Schedule.vue';
import TimeInCall from '@/__new__/features/charging/conditionParameters/conditions/TimeInCall.vue';
import RatingGroup from '@/__new__/features/charging/conditionParameters/conditions/RatingGroup.vue';
import Apn from '@/__new__/features/charging/conditionParameters/conditions/Apn.vue';
import Bearer from '@/__new__/features/charging/conditionParameters/conditions/Bearer.vue';
import NetworkSliceIdentifier from '@/__new__/features/charging/conditionParameters/conditions/NetworkSliceIdentifier.vue';
import Zone from '@/__new__/features/charging/conditionParameters/conditions/Zone.vue';
import SpecialNumber from '@/__new__/features/charging/conditionParameters/conditions/SpecialNumber.vue';
import TrafficType from '@/__new__/features/charging/conditionParameters/conditions/TrafficType.vue';
import OtherPartyRNCondition from '@/__new__/features/charging/conditionParameters/conditions/OtherPartyRNCondition.vue';
import Flag from '@/__new__/features/charging/conditionParameters/conditions/Flag.vue';
import OtherPartyNumberLengthCondition from '@/__new__/features/charging/conditionParameters/conditions/OtherPartyNumberLengthCondition.vue';
import ServiceType from '@/__new__/features/charging/conditionParameters/conditions/ServiceType.vue';
import SubscriberLocationCondition from '@/__new__/features/charging/conditionParameters/conditions/SubscriberLocationCondition.vue';
import OtherPartyNumberCondition from '@/__new__/features/charging/conditionParameters/conditions/OtherPartyNumberCondition.vue';
import VlrIdCondition from '@/__new__/features/charging/conditionParameters/conditions/VlrIdCondition.vue';
import ServiceIdCondition from '@/__new__/features/charging/conditionParameters/conditions/ServiceIdCondition.vue';
import DataBalance from '@/__new__/features/charging/conditionParameters/conditions/DataBalance.vue';
import BucketType from '@/__new__/features/charging/conditionParameters/conditions/BucketType.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// HTTP
import { getConditionParametersDraftList } from '@/__new__/services/dno/charging/http/conditionParameters';
import {
    ConditionParametersModel,
    ConditionParametersDraft,
} from '@/__new__/services/dno/charging/models/ConditionParametersModel';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getAffectedEntities, formatMutationDialogEntities, EDITOR_MODE } from '@/common/entities/entityHelper';
import {
    CONDITION_TYPES,
    PC_CONDITION_TYPES,
    allConditionOptions,
    getTextLabelForConditionType,
    getDefaultValueForConditionType,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import * as Utils from '@/common/utils';
import isEmpty from 'lodash/isEmpty';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import pcConditionsParser from '@/__new__/services/dno/charging/common/pcConditionsParser';
import { LABEL_COLOR } from '@/common/labelsHelper';
import Button from '@/common/button/Button';
import { STATUS_CODES } from '@/common/commonHelper';
import cloneDeep from 'lodash/cloneDeep';
import { loadVersionHistory, CHARGING_ENTITY_TYPES } from '@/__new__/services/dno/charging/common/versioningHelper';

export default {
    name: 'ConditionParametersEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppButton,
        MutationDialog,
        AppLabel,
        EditorButtons,
        EntityStatusIndicator,
        AppIcon,
        Schedule,
        AppMultiselectV3,
        TimeInCall,
        RatingGroup,
        Apn,
        Bearer,
        NetworkSliceIdentifier,
        Zone,
        SpecialNumber,
        TrafficType,
        OtherPartyRNCondition,
        Flag,
        OtherPartyNumberLengthCondition,
        ServiceType,
        SubscriberLocationCondition,
        OtherPartyNumberCondition,
        VlrIdCondition,
        ServiceIdCondition,
        DataBalance,
        BucketType,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            name: '',
            description: '',
            remark: '',
            approveOnCreate: false,
            version: null,
            conditionModel: {},
            allConditionOptions,
            entityType: ENTITY_TYPES.CONDITION_PARAMETERS,
            ICON_TYPES,
            BUTTON_TYPES,
            PC_CONDITION_TYPES,
            CONDITION_TYPES,
            selectedCondition: null,
            data: {},

            entityId: null,
            readonly: false,
            updateName: this.$i18n.t('generic.N/A'),
            entityDraft: null,
            isUnpublished: false,
            isOnlyDraft: false,
            updateTime: null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            LABEL_COLOR,
            EDITOR_MODE,
            STATUS_CODES,
            versionsHistory: [],
            currentVersion: null,
            state: EntityStateMapping.UNAPPROVED,
        };
    },
    validations() {
        return {
            name: {
                required,
            },
            selectedCondition: {
                required,
            },
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [
            Getters.GET_CONDITION_PARAMETER_BY_ID,
            Getters.GET_CONDITION_PARAMETER_BY_VERSION,
        ]),
        ...mapGetters(Modules.chargingV2, [Getters.GET_USAGE_COUNTERS]),
        conditionParameters() {
            return this[Getters.GET_USAGE_COUNTERS];
        },
        conditionParameterByVersion() {
            return this[Getters.GET_CONDITION_PARAMETER_BY_VERSION];
        },
        conditionParametersData() {
            return this[Getters.GET_CONDITION_PARAMETER_BY_ID](this.$route.params.id) || {};
        },
        isNewElement() {
            return !this.$route.params.id || this.$route.params.clone;
        },
        alreadyUsedName() {
            const dataForUser = this.conditionParameters;

            if (this.isEditingExistingEntity) {
                if (this.name !== this.conditionParametersData?.name) {
                    return Object.values(dataForUser).some(uc => uc.name === this.name);
                }
                return false;
            }
            return Object.values(dataForUser).some(uc => uc.name === this.name);
        },
        affectedEntities() {
            return getAffectedEntities(this.$attrs.id, this.entityType);
        },
        isEditingExistingEntity() {
            return this.$route.params.id && !this.$route.params.clone;
        },
        isDraft() {
            return this.entityId && this.entityDraft && !this.readonly;
        },
        isPublished() {
            return this.doseEntityIdExistsAndIsReadMode;
        },
        allowEditDraftBtn() {
            return this.doseEntityIdExistsAndIsReadMode && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.isOnlyDraft && !this.readonly;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.isOnlyDraft && !this.readonly;
        },
        doseEntityIdExistsAndIsReadMode() {
            return this.entityId && this.readonly;
        },
        getCurrentVersion() {
            return this.currentVersion?.chargingVersion || null;
        },
        pageTitle() {
            if (this.readonly) {
                return this.$i18n.t('charging.conditionParameters.name');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.conditionParameters.editPc');
            }
            return this.$i18n.t('charging.conditionParameters.addPc');
        },
        showStatusIndicator() {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone, chargingVersion } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                if (chargingVersion) {
                    this.currentVersion = chargingVersion;
                }

                const promises = [this[Actions.REQUEST_CONDITION_PARAMETERS]()];

                if (!clone && !this.readonly) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);

                if (this.entityId) {
                    this.initData(false, !this.currentVersion);
                    if (this.readonly) {
                        this.loadVersionHistory(
                            CHARGING_ENTITY_TYPES.CONDITION_PARAMETER,
                            this.entityId,
                            chargingVersion,
                        );
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        isEmpty,
        getTextLabelForConditionType,
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CONDITION_PARAMETERS,
            Actions.REQUEST_CONDITION_PARAMETER_BY_VERSION,
        ]),
        async initData(forceInitPublished = false, initVersion = true) {
            const cp = this.conditionParametersData;
            let cpData;

            if (initVersion) {
                this.currentVersion = cp?.chargingVersion || null;
            }

            if (this.entityDraft && !forceInitPublished) {
                const draftData = new ConditionParametersDraft({
                    ...this.entityDraft,
                    ...this.entityDraft.data,
                });

                draftData.version = cp.version;
                cpData = draftData;
                this.isOnlyDraft = cp?.state === STATUS_CODES.NA;

                if (cp?.update_time <= cpData?.update_time) {
                    this.isUnpublished = true;
                }
            } else if (this.currentVersion) {
                await this.loadHistoryEntity(this.entityId, this.currentVersion);
                cpData = cloneDeep(this.conditionParameterByVersion);
            } else {
                cpData = cloneDeep(cp);
            }

            let properlyFormattedName = cpData.name;
            if (this.$route.params.clone) {
                properlyFormattedName = `${cpData.name} cloned`;
            }

            this.name = properlyFormattedName;
            this.description = cpData.description;
            this.remark = cpData.remark;
            this.version = cpData.version;
            this.updateTime = cpData.updateTime;
            this.state = cpData.state;

            this.selectedCondition = this.allConditionOptions[cpData?.conditionParameter?.type];
            const parserVal = pcConditionsParser({
                [Utils.uuidV4()]: cpData.conditionParameter,
            });

            this.conditionModel = parserVal?.[0] || {};
            this.validateConditionParameterChildClass();
            this.getUpdateUserName(cpData.updatePortalId);
        },
        conditionOptions() {
            const options = [
                this.allConditionOptions[CONDITION_TYPES.RATING_GROUP],
                this.allConditionOptions[CONDITION_TYPES.APN],
                this.allConditionOptions[CONDITION_TYPES.BEARER],
                this.allConditionOptions[CONDITION_TYPES.ZONE],
                this.allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                this.allConditionOptions[CONDITION_TYPES.SCHEDULE],
                this.allConditionOptions[CONDITION_TYPES.TRAFFIC_TYPE],
                this.allConditionOptions[CONDITION_TYPES.TIME_IN_CALL],
                this.allConditionOptions[CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER],
                this.allConditionOptions[CONDITION_TYPES.SERVICE_TYPE],
                this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_FLAG],
                this.allConditionOptions[CONDITION_TYPES.BUCKET_FLAG],
                this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                this.allConditionOptions[CONDITION_TYPES.VLR_ID],
                this.allConditionOptions[CONDITION_TYPES.SERVICE_ID],
                this.allConditionOptions[CONDITION_TYPES.DATA_BALANCE],
                this.allConditionOptions[CONDITION_TYPES.BUCKET_TYPE],
            ];

            return options.sort((option1, option2) => option1.type.localeCompare(option2.type));
        },
        getAffectedEntities() {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        checkDataBeforeSave() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.conditionParameters.nameMustBeUnique'),
                });
                return false;
            }
            if (this.conditionModel?.dataValid === false) {
                this.conditionModel.dataEmpty = true;
                return false;
            }

            return true;
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.data.name = this.name;
            this.data.description = this.description;
            this.data.remark = this.remark;
            const cpModel = new ConditionParametersModel({});
            const data = cpModel.parseConditionsData(this.conditionModel);
            this.data.condition_parameter = cpModel.makeConditionParameterObj(data);

            await this.saveEntityData(this.isOnlyDraft, this.affectedEntities.condition_parameters || [], isPublish);
        },
        removeCondition() {
            this.selectedCondition = null;
            this.conditionModel = null;
        },
        createNewCondition(conditionOption) {
            this.conditionModel = {
                data: getDefaultValueForConditionType(conditionOption.type),
                dataEmpty: false,
                dataValid: false,
                type: conditionOption.type,
                uuid: Utils.uuidV4(),
            };
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getConditionParametersDraftList(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_CONDITION_PARAMETERS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        validateConditionParameterChildClass() {
            this.$nextTick(() => {
                switch (this.conditionModel.type) {
                    case CONDITION_TYPES.SCHEDULE:
                        this.$refs.scheduleCondition.callValidateFunctionAndEmitData();
                        break;
                    case CONDITION_TYPES.TIME_IN_CALL:
                        this.$refs.timeInCallCondition.updateTime();
                        break;
                    case CONDITION_TYPES.RATING_GROUP:
                        this.$refs.ratingGroupCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.APN:
                        this.$refs.apnCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.BEARER:
                        this.$refs.bearerCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER:
                        this.$refs.networkSliceIdentifierCondition.dataInput();
                        break;
                    case CONDITION_TYPES.ZONE:
                        this.$refs.zoneCondition.emitData();
                        break;
                    case CONDITION_TYPES.SPECIAL_NUMBER:
                        this.$refs.specialNumberCondition.onInput();
                        break;
                    case CONDITION_TYPES.TRAFFIC_TYPE:
                        this.$refs.trafficTypeCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.OTHER_PARTY_RN:
                        this.$refs.otherPartyRNCondition.emitData();
                        break;
                    case CONDITION_TYPES.SUBSCRIBER_FLAG:
                    case CONDITION_TYPES.BUCKET_FLAG:
                        this.$refs.flagCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH:
                        this.$refs.otherPartyNumberLengthCondition.emitData();
                        break;
                    case CONDITION_TYPES.SERVICE_TYPE:
                        this.$refs.serviceTypeCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.SUBSCRIBER_LOCATION:
                        this.$refs.subscriberLocationCondition.emitData();
                        break;
                    case CONDITION_TYPES.OTHER_PARTY_NUMBER:
                        this.$refs.otherPartyNumberCondition.emitData();
                        break;
                    case CONDITION_TYPES.VLR_ID:
                        this.$refs.vlrIdCondition.emitData();
                        break;
                    case CONDITION_TYPES.SERVICE_ID:
                        this.$refs.serviceIdCondition.emitData();
                        break;
                    case CONDITION_TYPES.DATA_BALANCE:
                        this.$refs.dataBalanceCondition.onDataInput();
                        break;
                    case CONDITION_TYPES.BUCKET_TYPE:
                        this.$refs.bucketTypeCondition.onDataInput();
                        break;
                    default:
                        break;
                }
            });
        },
        async loadVersionHistory(entityType, id, initChargingVersion = null) {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        selectVersion(entry) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id, version) {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_CONDITION_PARAMETER_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';

.cs-form-wrapper {
    width: 80%;
}

.remove-condition {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.condition-wrapper {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: solid 1px $blue15;
    border-bottom: solid 1px $blue15;
}

.condition-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}

.condition-text {
    font-size: 0.625rem;
    line-height: 2.17;
    font-weight: 600;
    color: $gray90;
}

.conditionError {
    border: 1px solid $red;
    border-radius: 1rem;
    padding: 1rem;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $blue5;

        .version-details {
            display: block;
        }
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;

    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;

        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}
</style>
