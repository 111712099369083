<template>
    <AbstractListPageWrapper
        :pageTitle="pageTitle"
        :entitiesCount="entitiesCount"
        :isOverviewEnabled="isOverviewEnabled"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template slot="button">
            <ResponseModalButton
                :response="entityResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                v-if="writePermission"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="addButtonLabel"
                data-test-id="add-entity-button"
                @click="onAdd"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                :entities="formattedFilteredEntities"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :columnsData="tableColumnsData"
                :tableKey="`${entityType}-table`"
                :data-test-id="`${entityType}-table`"
                @selectEntity="selectEntity"
            />
        </template>

        <template slot="overview">
            <EntityOverview
                :entityType="entityType"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <div
                    slot="section-1-content"
                    key="1"
                >
                    <slot name="sidebarOverview" />

                    <h2 class="lf-title mb-2 mt-3">
                        {{ $i18n.t('formBuilderTypes.JSON') }}
                    </h2>
                    <VueJsonPretty
                        selectableType="single"
                        :data="selectedEntityJSON"
                        :deep="2"
                        class="state-history-content"
                    />
                </div>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';

export default {
    name: 'OperateApisListPageLayout',

    components: {
        AppTable,
        AppButton,
        AbstractListPageWrapper,
        ResponseModalButton,
        TableFiltersTags,
        FilterTable,
        EntityOverview,
        VueJsonPretty,
    },

    mixins: [FilterTableMixin, ListPageMixin],

    props: {
        writePermission: {
            type: Boolean,
            required: false,
            default: false,
        },
        entityType: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            default: '',
        },
        entityResponse: {
            type: Object,
            default: () => undefined,
        },
        entityData: {
            type: Array,
            default: () => undefined,
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        addNewRouteName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            searchQueryForTable: '',
            isOverviewEnabled: false,
            selectedEntity: null,
            selectedEntityJSON: {},

            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entityData);
        },
        entitiesCount() {
            return this.entityData?.length || 0;
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.entityType === 'api_product_order') {
                columns.unshift({
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            } else {
                columns.unshift({
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return columns;
        },
    },

    methods: {
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        onAdd() {
            this.$router.push({ name: this.addNewRouteName });
        },
        selectEntity(entity) {
            this.selectedEntity = this.entityData.find(pr => pr.id === entity);
            this.isOverviewEnabled = true;
            this.$emit('onSelectEntity', entity);

            // Set value for JSON editor
            this.selectedEntityJSON = this.entityResponse.data.find(obj => obj.id === entity);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}
</style>
