var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters",class:{ 'is-disabled': _vm.isDisabled }},[(!_vm.hideHeader)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"subtitle"},[_vm._v("Create "+_vm._s(_vm.entityType))])]):_vm._e(),_c('div',{staticClass:"filters-table",class:{ 'is-disabled': _vm.isDisabled }},[_vm._l((_vm.conditionInstances),function(conditionInstanceByDefinitionId,conditionInstanceIndex){return _vm._l((conditionInstanceByDefinitionId),function(_,conditionDefinitionId){return _c('FilterComponentV2',{key:conditionDefinitionId + conditionInstanceIndex,staticClass:"filter-component",class:{ 'is-last': conditionInstanceIndex === _vm.conditionInstances.length - 1 },attrs:{"conditionInstanceTopLevelById":conditionInstanceByDefinitionId,"conditionDefinition":_vm.conditionDefinitionsById[conditionDefinitionId],"filtersIcon":_vm.filtersIcon,"changeCallback":_vm.changeCallback,"stats":_vm.stats[conditionInstanceIndex],"topLevelCombinator":_vm.topLevelCombinator,"showStats":_vm.showStats,"entityType":_vm.entityType,"allJsonPathOptions":_vm.allJsonPathOptionsOrNone,"propertyDefinitions":_vm.propertyDefinitionsByConditionDefinitionId[conditionDefinitionId],"conditionName":_vm.conditionNamesByDefinitionId[conditionDefinitionId]},on:{"addPropertyGroup":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.addPropertyGroup.apply(void 0, [ conditionInstanceIndex ].concat( args ));
},"addProperty":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.addProperty.apply(void 0, [ conditionInstanceIndex ].concat( args ));
},"updateProperty":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.updateProperty.apply(void 0, [ conditionInstanceIndex ].concat( args ));
},"deleteProperty":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.deleteProperty.apply(void 0, [ conditionInstanceIndex ].concat( args ));
},"deleteGroup":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.deleteGroup.apply(void 0, [ conditionInstanceIndex ].concat( args ));
}}})})}),_c('div',{staticClass:"add-filter",class:{ 'cursor-not-allowed': _vm.filtersAddingDisabled }},[_c('Dropdown',{staticClass:"choose-filter-dropdown mr-3",class:{ 'pointer-events-none': _vm.filtersAddingDisabled },attrs:{"items":_vm.conditionDefinitionsArray,"invalid":_vm.selectFilterInvalid,"searchable":true,"colorSchema":_vm.Styles.White,"caption":("Choose " + (_vm.EntityDropdownTitle[_vm.entityType])),"disabled":_vm.filtersAddingDisabled,"displayProperty":"name"},on:{"selected":_vm.onFilterSelect}})],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }