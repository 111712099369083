
































































































































































































































// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import BulkUploadHistory from '@/__new__/features/resources/BulkUploadHistory.vue';
import BulkUploadHistorySidebar from '@/__new__/features/resources/BulkUploadHistorySidebar.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// Mixins
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';

// Permissions
import { isUserAllowed, isViewConfig, isViewEnabled } from '@/services/permissions/permissions.service';

// HTTP
import {
    getSignedURL,
    getSignedURLForDownload,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
} from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import { mapGetters } from 'vuex';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// MISC
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import {
    CHANNEL_INDEX,
    COMMERCIAL_OFFER_NAME_INDEX,
    MSISDN_INDEX,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    getAllActionStrings,
    getQodMsisdns,
    getUploadHistory,
} from '@/__new__/services/dno/developerLineAuthorization/developerLineAuthorizationService';
import download from 'downloadjs';
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import { type QodMsisdn } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import {
    FILE_UPLOAD_STATUS_TO_COLOR_MAP,
    formatEventCategory,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';

export default {
    name: 'DeveloperLineAuthorization',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        BulkUploadHistory,
        BulkUploadHistorySidebar,
        AppTable,
        DragDropFileUploader,
        FileUploaderModal,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    mixins: [DownloadDetailsMixin],
    data() {
        return {
            getAllFileUploadStatusStrings,
            FILE_UPLOAD_STATUS_TO_COLOR_MAP,
            ENTITY_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            numberOfFilesToUpload: 0,
            triggerClearPollers: false,
            showErrorModal: false,
            failedLinesErrorMessage: [],
            disabled: !isViewConfig() || !isViewEnabled('DeveloperLineAuthorization'),
            showSearchResults: false,
            searchResults: [],
            msisdnSearchIndex: null,
            msisdnSearchIndexOptions: [
                {
                    id: MSISDN_INDEX,
                    name: this.$t('qodNumberManagement.msisdn'),
                },
                {
                    id: CHANNEL_INDEX,
                    name: this.$t('qodNumberManagement.channel'),
                },
                {
                    id: COMMERCIAL_OFFER_NAME_INDEX,
                    name: this.$t('qodNumberManagement.commercialOfferName'),
                },
            ],
            msisdnSearchString: '',
            showSearchStringError: false,
            searchHistoryColumnData: {
                [DEVICE_LINE_AUTH_API_TYPE.QOD]: [
                    {
                        name: this.$t('qodNumberManagement.msisdn'),
                        key: 'msisdn',
                        field: 'msisdn',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.appClientId'),
                        key: 'appClientId',
                        field: 'appClientId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.channel'),
                        key: 'channel',
                        field: 'channel',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.customerBan'),
                        key: 'customerBan',
                        field: 'customerBan',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.customerName'),
                        key: 'customerName',
                        field: 'customerName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.qodProfiles'),
                        key: 'qodProfiles',
                        field: 'qodProfiles',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.orgIdPartnerId'),
                        key: 'payingCustomerId',
                        field: 'payingCustomerId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.commercialOfferName'),
                        key: 'commercialOfferName',
                        field: 'commercialOfferName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('generic.action'),
                        key: 'actionStr',
                        field: 'actionStr',
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                        limitedOptions: getAllActionStrings(),
                    },
                ],
                [DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS]: [
                    {
                        name: this.$t('qodNumberManagement.msisdn'),
                        key: 'msisdn',
                        field: 'msisdn',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.appClientId'),
                        key: 'appClientId',
                        field: 'appClientId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.channel'),
                        key: 'channel',
                        field: 'channel',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.customerBan'),
                        key: 'customerBan',
                        field: 'customerBan',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.approverName'),
                        key: 'approverName',
                        field: 'approverName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.serviceIds'),
                        key: 'serviceIds',
                        field: 'serviceIds',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.orgId'),
                        key: 'payingCustomerId',
                        field: 'payingCustomerId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('qodNumberManagement.commercialOfferName'),
                        key: 'commercialOfferName',
                        field: 'commercialOfferName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$t('generic.action'),
                        key: 'actionStr',
                        field: 'actionStr',
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                        limitedOptions: getAllActionStrings(),
                    },
                ],
                [DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION]: [
                    {
                        name: this.$i18n.t('qodNumberManagement.msisdn'),
                        key: 'msisdn',
                        field: 'msisdn',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.appClientId'),
                        key: 'appClientId',
                        field: 'appClientId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.channel'),
                        key: 'channel',
                        field: 'channel',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.customerBan'),
                        key: 'customerBan',
                        field: 'customerBan',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.approverName'),
                        key: 'approverName',
                        field: 'approverName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.serviceIds'),
                        key: 'serviceIds',
                        field: 'serviceIds',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.orgId'),
                        key: 'payingCustomerId',
                        field: 'payingCustomerId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('qodNumberManagement.commercialOfferName'),
                        key: 'commercialOfferName',
                        field: 'commercialOfferName',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('generic.action'),
                        key: 'actionStr',
                        field: 'actionStr',
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                        limitedOptions: getAllActionStrings(),
                    },
                ],
            },
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.uploadedBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: entity => entity.createdAt,
                type: 'desc',
            },
            tableSearchQuery: '',
            searchInProgress: false,
            searchApiResponse: {},
            uploadHistory: [] as UploadedFileDetails[],
            uploadHistorySearchQuery: '',
            uploadHistoryDataLoading: false,
            selectedUploadHistory: undefined as UploadedFileDetails | undefined,
            apiType: null,
            apiTypeToPermissions: new Map([
                [
                    DEVICE_LINE_AUTH_API_TYPE.QOD,
                    {
                        read: 'QoDNumberManagementRead',
                        write: 'QoDNumberManagementWrite',
                    },
                ],
                [
                    DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS,
                    {
                        read: 'DeviceStatusNumberManagementRead',
                        write: 'DeviceStatusNumberManagementWrite',
                    },
                ],
                [
                    DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION,
                    {
                        read: 'LocationVerificationNumberManagementRead',
                        write: 'LocationVerificationNumberManagementWrite',
                    },
                ],
            ]),
        };
    },
    computed: {
        ...mapGetters('config', {
            accountData: 'getAccountData',
        }),
        /**
         * Strips out all characters which aren't (alphabet or spaces).
         * This name is used to indicate the user who performed the file upload.
         * This restriction is needed since we're currently including the uploading username
         * in the filename. Strange characters could possibly result in an invalid filename.
         * In the future the goal is to pass the username when getting a signed url instead
         * of using this hacky approach.
         */
        accountNameFormatted() {
            const accountName = this.accountData.name ?? '';
            return accountName.replace(/[^A-Za-z ]/g, '');
        },
        apiTypeOptions() {
            const options = [];
            for (const [api, permissions] of this.apiTypeToPermissions) {
                if (isUserAllowed(permissions.read, permissions.write)) {
                    options.push({
                        id: api,
                        name: this.apiTypeToLabel(api),
                    });
                }
            }
            return options;
        },
        /**
         * Returns true if the user has write access for the currently select API type, returns false otherwise
         * Returns false if view is disabled for tenant
         */
        userHasWriteAccess(): boolean {
            if (this.disabled) {
                return false;
            }
            if (!this.apiType) {
                return false;
            }
            const permissions = this.apiTypeToPermissions.get(this.apiType);
            if (!permissions) {
                return false;
            }
            return isUserAllowed(permissions.write);
        },
        pageTitle() {
            const baseTitle = this.$t('qodNumberManagement.developerLineAuthorization');
            switch (this.apiType) {
                case DEVICE_LINE_AUTH_API_TYPE.QOD:
                    return `${baseTitle} (${this.$t('qodNumberManagement.qodAcronym')})`;
                case DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS:
                    return `${baseTitle} (${this.$i18n.t('qodNumberManagement.deviceStatus')})`;
                case DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION:
                    return `${baseTitle} (${this.$i18n.t('qodNumberManagement.locationVerification')})`;
                default:
                    return baseTitle;
            }
        },
        historySidebarData(): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: this.selectedUploadHistory?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: this.selectedUploadHistory?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: this.selectedUploadHistory?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: this.selectedUploadHistory?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.selectedUploadHistory?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(this.selectedUploadHistory?.eventCategoryCounters || {}).map(
                        ([status, count]) => ({
                            name: formatEventCategory(status),
                            value: `${count}/${this.selectedUploadHistory?.recordCount}`,
                        }),
                    ),
                },
            ];
        },
        /**
         * Purpose: Builds config needed by DragDropFileUploader
         * Note: We wrap service functions so API type context is added
         */
        configFileUploader() {
            return {
                getSignedURL: params => getSignedURL(this.apiType, params),
                getEntityDetailsByCategory: (name, category, page, size) =>
                    getEntityDetailsByCategory(this.apiType, name, category, page, size),
                getEntityDetailsByName: name => getEntityDetailsByName(this.apiType, name),
                getRegisteredEntities: (page, size) => getRegisteredEntities(this.apiType, page, size),
                getSignedURLParams: params => ({
                    name: `${this.accountNameFormatted}-${this.accountData.id}-${params.fileName}`,
                }),
            };
        },
    },
    methods: {
        apiTypeToLabel(api: DEVICE_LINE_AUTH_API_TYPE) {
            switch (api) {
                case DEVICE_LINE_AUTH_API_TYPE.QOD:
                    return this.$t('qodNumberManagement.qod');
                case DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS:
                    return this.$t('qodNumberManagement.deviceStatus');
                case DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION:
                    return this.$i18n.t('qodNumberManagement.locationVerification');
                default:
                    return '';
            }
        },
        updateAmountFilesUpload(numberOfFilesToUpload: number) {
            this.numberOfFilesToUpload = numberOfFilesToUpload;
        },
        displayFailedLines(lines: string, failureEventToLineNumbers: { [event: string]: number[] }) {
            this.failedLinesErrorMessage = Object.entries(failureEventToLineNumbers).map(
                ([event, lineNumbers]) =>
                    `${formatEventCategory(event)}: ${this.$i18n.tc(
                        'qodNumberManagement.line',
                        lineNumbers.length,
                    )} ${lineNumbers.join(', ')}`,
            );
            this.showErrorModal = true;
        },
        onBack() {
            this.triggerClearPollers = !this.triggerClearPollers;
            this.$router.go(-1);
        },
        async onLoadHistory(): Promise<void> {
            await this.$withLoadingSpinner(
                async () => {
                    this.uploadHistoryDataLoading = true;
                    this.uploadHistory = await getUploadHistory(this.apiType);
                    this.uploadHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.uploadHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        async onMsisdnSearch() {
            // Validate fields
            this.showSearchStringError = false;
            if (!this.msisdnSearchString) {
                this.showSearchStringError = true;
                return;
            }
            // Submit search request
            await this.$withLoadingSpinner(
                async () => {
                    this.tableSearchQuery = '';
                    this.showSearchResults = true;
                    this.searchResults = [];
                    this.searchInProgress = true;
                    const { entities, response } = await getQodMsisdns(
                        this.apiType,
                        this.msisdnSearchIndex,
                        this.msisdnSearchString,
                    );
                    this.searchResults = entities;
                    this.searchApiResponse = response;
                    this.searchInProgress = false;
                },
                {
                    errorHandler: () => {
                        this.searchInProgress = false;
                        this.$alert(this.$t('qodNumberManagement.searchFailed'));
                    },
                },
            );
        },
        async onToggleShowDetails(entity: QodMsisdn, detailVisibility: boolean) {
            // Exit if we're not showing details
            if (!detailVisibility) {
                return;
            }
            // Determine name of user that last updated entity IFF it's unknown
            if (entity.lastUpdatedByName) {
                return;
            }
            await this.$withLoadingSpinner(
                async () => {
                    const response = await getUserNameById(Number(entity.lastUpdatedBy));
                    this.$set(entity, 'lastUpdatedByName', response.data);
                },
                {
                    errorHandler: () => {
                        this.$set(entity, 'lastUpdatedByName', this.$t('generic.unknown'));
                    },
                },
            );
        },
        onUploadHistorySelected(entity: UploadedFileDetails): void {
            this.selectedUploadHistory = entity;
        },
        async onDownloadFile(): Promise<void> {
            // Exit if no entity has been selected
            if (!this.selectedUploadHistory) {
                return;
            }
            // Download file
            await this.$withLoadingSpinner(
                async () => {
                    // Get signed URL
                    const uploadedFilename = this.selectedUploadHistory.entityName;
                    const signedUrlRes = await getSignedURLForDownload(this.apiType, uploadedFilename);
                    const downloadUrl = signedUrlRes.data.signed_entity.url;
                    // Download file from signed URL
                    const fileRes = await fetch(downloadUrl);
                    if (!fileRes.ok) {
                        throw new Error(`Fetch to endpoint ${downloadUrl} to download ${uploadedFilename} failed.`);
                    }
                    const file = await fileRes.text();
                    const filename = this.selectedUploadHistory.fileName;
                    // Trigger download from browser
                    download(file, filename, 'text/plain');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('qodNumberManagement.failedToDownloadFile'));
                    },
                },
            );
        },
        onApiTypeChange() {
            // Reset bulk upload history
            this.uploadHistory = [];

            // Reset search results
            this.showSearchResults = false;
            this.searchResults = [];
        },
    },
};
