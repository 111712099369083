








// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { LABEL_COLOR } from '@/common/labelsHelper';
import { TranslateResult } from 'vue-i18n';

export const STATUS_CODES = {
    PENDING: 1,
    SUCCESS: 2,
    FAIL: 3,
    CANCELLED: 4,
    RELEASED: 5,
};

const STATUS_INDICATOR_MAP = new Map([
    [STATUS_CODES.PENDING, LABEL_COLOR.yellow],
    [STATUS_CODES.SUCCESS, LABEL_COLOR.green],
    [STATUS_CODES.FAIL, LABEL_COLOR.red],
    [STATUS_CODES.CANCELLED, LABEL_COLOR.red],
    [STATUS_CODES.RELEASED, LABEL_COLOR.red],
]);

export default {
    name: 'TransactionHistoryStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: Number,
        },
    },
    computed: {
        statusConverter(): TranslateResult | string {
            if (this.status === STATUS_CODES.PENDING) {
                return this.$i18n.t('generic.stateMap.pending');
            }
            if (this.status === STATUS_CODES.SUCCESS) {
                return this.$i18n.t('generic.stateMap.success');
            }
            if (this.status === STATUS_CODES.FAIL) {
                return this.$i18n.t('generic.stateMap.failed');
            }
            if (this.status === STATUS_CODES.CANCELLED || this.status === STATUS_CODES.RELEASED) {
                return this.$i18n.t('generic.stateMap.canceled');
            }

            return '';
        },
        colorConverter(): string | undefined {
            return STATUS_INDICATOR_MAP.get(this.status);
        },
    },
};
