








// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { BULK_EDIT_INDICATOR_MAP } from '@/__new__/features/pc/common/bulkEditStates';

export default {
    name: 'BulkStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        statusKey: {
            required: true,
            type: String,
        },
    },
    computed: {
        statusLabel(): string {
            return this.$i18n.t(this.statusKey);
        },
        labelColor(): string {
            return BULK_EDIT_INDICATOR_MAP.get(this.statusKey);
        },
    },
};
