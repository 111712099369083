<template>
    <AppDialogV2
        :visible="open"
        :title="$t('formBuilderTypes.JSON')"
        @close="$emit('close')"
    >
        <AppJSON
            :value="entities"
            textareaHeight="35rem"
            @input="entities = $event"
            @validation="objectIsValid = $event"
        />
        <template #modalFooter>
            <div class="d-flex justify-content-end mr-3">
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="buttonLabel"
                    :disabled="buttonDisabled"
                    class="mr-3"
                    @click="startImport"
                />
            </div>
        </template>
    </AppDialogV2>
</template>

<script>
import { castArray, compact, filter, isObject } from 'lodash';
import filters from '@/common/filters';
import { entityCreators } from '@/common/importEntitiesHelper';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

import AppJSON from '@/components/partials/AppJSON.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { getErrorMessage } from '@/common/cepHelper';
import { entityTypeToEntityLabel } from '@/common/entities/entityHelper';

export default {
    name: 'ImportEntitiesModal',
    filters,
    components: { AppButton, AppJSON, AppDialogV2 },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        entityType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoading: false,
            entities: null,
            objectIsValid: false,
            BUTTON_TYPES,
        };
    },
    computed: {
        entitiesArray() {
            return filter(castArray(this.entities), isObject);
        },
        buttonLabel() {
            return this.entitiesArray.length > 0
                ? [
                      this.$t('generic.import'),
                      this.$options.filters.pluralFull(
                          entityTypeToEntityLabel[this.entityType] || this.entityType,
                          this.entitiesArray.length,
                      ),
                  ].join(' ')
                : this.$t('generic.import');
        },
        buttonDisabled() {
            return this.entitiesArray.length < 1 || this.isLoading || !this.objectIsValid;
        },
        entityCreateFn() {
            if (!entityCreators[this.entityType]) {
                this.$showErrorAlert({
                    message: this.$t('alerts.entityNotSupported', {
                        entity: this.entityType,
                    }),
                });
            }

            return entityCreators[this.entityType];
        },
    },
    methods: {
        async startImport() {
            let res = [];
            this.$eventBus.$emit('closeAllAlerts');

            if (this.entityCreateFn) {
                this.isLoading = true;

                res = await Promise.all(
                    this.entitiesArray.map(async entity => {
                        try {
                            return await this.entityCreateFn(entity);
                        } catch (e) {
                            return this.$showErrorAlert({
                                message: this.$t('alerts.entitiesImportError', {
                                    entity: this.entityType,
                                    name: entity.name,
                                    message: getErrorMessage(e),
                                }),
                            });
                        }
                    }),
                );

                this.isLoading = false;
            }

            this.handleImportResult(res);
        },

        handleImportResult(res) {
            const successCount = compact(res).length;

            if (successCount < 1) {
                return;
            }

            this.$emit('finish');

            if (successCount === this.entitiesArray.length) {
                this.$showSuccessAlert({
                    message: this.$t('alerts.entitiesImportSuccessAll', {
                        entities: this.$options.filters.plural(this.entityType, 2),
                    }),
                });
                this.entities = null;
                this.$emit('close');
            } else {
                this.$showSuccessAlert({
                    message: this.$t('alerts.entitiesImportSuccessPart', {
                        imported: successCount,
                        total: this.entitiesArray.length,
                        entities: this.$options.filters.plural(this.entityType, successCount),
                    }),
                });
            }
        },
    },
};
</script>
