import AppSpinner from '@/components/partials/AppSpinner.vue';
import SinkConfigEditor from '@/__new__/features/sinkConfigs/SinkConfigEditor.vue';
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters, State } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';
import eventBus from '@/eventBus';
import { SINK_CONFIG_STATUS } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import Button from '@/common/button/Button';
import { entityTypeDisplayName } from '@/common/utils';

export default {
    components: {
        SinkConfigEditor,
        AppSpinner,
    },
    data() {
        return {
            RouteNames,
            ENTITY_TYPES,
        };
    },
    computed: {
        ...mapState(Modules.triggers, { triggerDefinitions: State.TRIGGER_DEFINITIONS_BY_ID }),
        ...mapGetters(Modules.events, { events: Getters.GET_MAPPED_EVENTS }),
    },
    methods: {
        ...mapActions(Modules.triggers, [Actions.LOAD_TRIGGER_DEFINITIONS]),
        ...mapActions(Modules.events, [Actions.LOAD_ALL_EVENTS]),
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_SINK_FORMATTERS]),
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_SINK_TYPE_CASTERS]),
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENTS]),
        fetchSegments() {
            try {
                return this[Actions.FETCH_SEGMENTS]();
            } catch (error) {
                Sentry.captureException(error);
                eventBus.$emit('showAlert', {
                    message: this.$t('segments.filters.alerts.failedToLoadSegment'),
                });
                return Promise.reject(error);
            }
        },
        fetchTriggers() {
            try {
                return this[Actions.LOAD_TRIGGER_DEFINITIONS]();
            } catch (error) {
                Sentry.captureException(error);
                eventBus.$emit('showAlert', {
                    message: this.$i18n.t('triggers.errors.failedToLoadTriggers'),
                });
                return Promise.reject(error);
            }
        },
        fetchEvents() {
            try {
                if (this.events.length) return Promise.resolve();

                return this[Actions.LOAD_ALL_EVENTS]();
            } catch (error) {
                Sentry.captureException(error);
                eventBus.$emit('showAlert', {
                    message: this.$i18n.t('events.alerts.failedToLoadAllEvents'),
                });
                return Promise.reject(error);
            }
        },
        fetchSinkFormatters() {
            try {
                return this[Actions.LOAD_SINK_FORMATTERS]();
            } catch (error) {
                Sentry.captureException(error);
                eventBus.$emit('showAlert', {
                    message: 'Failed to load sink formatters',
                });
                return Promise.reject(error);
            }
        },
        fetchSinkTypeCasters() {
            try {
                return this[Actions.LOAD_SINK_TYPE_CASTERS]();
            } catch (error) {
                Sentry.captureException(error);
                eventBus.$emit('showAlert', {
                    message: this.$t('sinkConfigs.typeCasters.failedToFetchMsg'),
                });
                return Promise.reject(error);
            }
        },
        confirmConfigDeletion(config, deleteFn) {
            this.$alert(
                this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: config?.name || this.$i18n.t('generic.entity'),
                }),
                {
                    type: this.$ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$i18n.t('generic.delete'),
                            alertType: this.$ALERT_TYPES.warning,
                            handler: () => {
                                this.deleteSinkConfig(config, deleteFn);
                            },
                        }),
                    ],
                },
            );
        },

        deleteSinkConfig({ id, version, state }, deleteFn) {
            this.$withProgressBar(
                async () => {
                    await this[deleteFn]({
                        id,
                        version,
                        isInDraftState: state === SINK_CONFIG_STATUS.DRAFT,
                    });

                    this.$alert(
                        this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            entityName: entityTypeDisplayName(this.entityType),
                            action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                        }),
                        {
                            type: this.$ALERT_TYPES.success,
                        },
                    );
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.cep.errorDeletingConfig'));
                    },
                },
            );
        },
    },
};
