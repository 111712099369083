export enum SIM_TYPE {
    PSIM = 'psimProfile',
    ESIM = 'esimProfile',
}

export const RESOURCE_TYPE = {
    [SIM_TYPE.ESIM]: 10,
    [SIM_TYPE.PSIM]: 20,
};

export enum REMOTE_CONFIG_KEYS {
    ESIM_BRANDS = 'esim_brands_list',
    ESIM_CHANNELS = 'esim_channel_tag',
    PSIM_CHANNELS = 'psim_channel_tag',
}
