var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'shrink-width': _vm.sidebarState },attrs:{"data-test-id":"account-order-details"}},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.deliveriesList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var allFilters = ref.allFilters;
            var filteredEntities = ref.filteredEntities;
            var onFilterAdded = ref.onFilterAdded;
            var removeFilter = ref.removeFilter;
            var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"d-flex component-title-um"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex"},[_c('SearchBox',{staticClass:"table-search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.apiResponse,"title":_vm.title}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.deliveriesColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"selectedEntityId":_vm.selectedParentDelivery ? _vm.selectedParentDelivery.deliveryId : '',"innerSearchQuery":_vm.searchQuery,"isSearchEnabled":true,"isDefaultHoverEnabled":true,"isCustomButtonPassed":true,"disableBoxShadow":true,"canSelectColumns":true,"newDesign":true,"enableRowStateControls":false,"columnsData":_vm.deliveriesColumnsData,"tableKey":"user/account-delivery","keyName":"deliveryId"},on:{"selectedEntityModel":function (o) { return [_vm.selectDelivery(o), _vm.toggleModal(true, _vm.sectionKeys.DELIVERIES)]; }},scopedSlots:_vm._u([{key:"status",fn:function(ref){
            var entity = ref.entity;
return [(!_vm.isUndefined(entity.status))?_c('EntityStatusIndicator',{attrs:{"stateMap":_vm.DELIVERY_STATES_TO_STATUS_NAME_MAP,"stateMapColor":_vm.DELIVERY_STATUS_INDICATOR_MAP,"status":entity.status,"isInTable":true}}):_c('i',[_vm._v(_vm._s(_vm.$i18n.t('generic.empty')))])]}}],null,true)})],1)]}}])}),_c('AdditionalSidebarDeliveries',{attrs:{"selectedParentDelivery":_vm.selectedParentDelivery,"showAdditionalSidebar":_vm.showAdditionalSidebar},on:{"closeSidebar":function($event){return _vm.toggleModal($event, _vm.sectionKeys.DELIVERIES)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }