
















// vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
// helpers
import { capitalize } from 'lodash';
import RouteNames from '@/router/routeNames';
import { mapORDConfigActions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import { optionsEnum } from '@/common/filterTable';
import { ORD_CONFIG_STATUS } from '@/common/sinkConfigHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
// models
import { SinkConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';

export default {
    name: 'ORDConfigDashboard',
    components: { SinkConfigDashboard },
    mixins: [SinkConfigEditorMixin],

    data() {
        return {
            Actions,
            RouteNames,
            ENTITY_TYPES,
            isDataLoading: false,
            tableFilters: [
                {
                    column: 'state',
                    condition: {
                        ...optionsEnum.isNot,
                        i18nLabel: optionsEnum.isNot.i18nLabelKey,
                    },
                    values: { value: capitalize(ORD_CONFIG_STATUS.DELETED) },
                },
            ],
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            reportsConfigs: Getters.GET_ORD_CONFIGS,
            reportsConfigsApiResponse: Getters.GET_ORD_CONFIGS_API_RESPONSE,
            reportsUIParams: Getters.GET_ORD_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        reportsConfigsFormatted(): Array<
            SinkConfig & { allowedActionsExternal: ReturnType<typeof mapORDConfigActions> }
        > {
            return this.reportsConfigs.map((rc: SinkConfig) => ({
                ...rc,
                allowedActionsExternal: mapORDConfigActions(rc),
            }));
        },
    },

    created() {
        this.$withProgressBar(
            async () => {
                this.isDataLoading = true;
                const promises = [
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchTriggers(),
                    this[Actions.LOAD_ORD_CONFIGS_SETTINGS](),
                ];

                if (isUserAllowed('SharedFileLocationsRead')) {
                    promises.push(this[Actions.FETCH_SHARED_FILE_LOCATIONS]());
                }

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                await this.fetchConfigs();
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('events.alerts.failedToLoadConfigs'));
                    this.isDataLoading = false;
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_ORD_CONFIGS,
            Actions.LOAD_ORD_CONFIGS_SETTINGS,
            Actions.DELETE_ORD_CONFIG,
        ]),
        ...mapActions(Modules.sharedFileLocations, [Actions.FETCH_SHARED_FILE_LOCATIONS]),
        fetchConfigs() {
            return this[Actions.LOAD_ORD_CONFIGS]();
        },
    },
};
