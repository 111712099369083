import Delivery from '@/__new__/services/dno/orders/models/Delivery';

// Properties taken from swagger and /api/v3/delivery/get_delivery_details response combined
// TODO: Move to Delivery model after converting Delivery model to TS
export interface DeliveryBE {
    operator_name: string;
    delivery_id: string;
    order_id: string;
    target_id: string;
    target_type: number;
    delivery_info: {
        recipient_fullname: string;
        recipient_phone: string;
    };
    delivery_status: number;
    tracking_id: string;
    address: {
        area: string;
        zip_code: string;
        province: string;
        city: string;
        barangay: string;
        street: string;
        village: string;
        unit: string;
    };
    courier: string;
}

export function remapDeliveriesFromBe(deliveries: DeliveryBE[]): { [key: string]: Delivery } {
    if (!deliveries.length) {
        return {};
    }
    return Object.assign(
        {},
        ...deliveries.map(delivery => ({
            [delivery.delivery_id]: new Delivery(Delivery.remapDeliveryFromBe(delivery)),
        })),
    );
}
