import store from '@/store/store';
import i18n from '@/i18n';
import _ from 'lodash';
import localeLibrary from '@/common/locale/localeLibrary';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { Getters } from '@/store/mutation-types';
import { getEntityStateName, EntityStateMapping } from '@/common/commonEntityStateMapper';
import { checkObjectPath } from '@/common/utils';
import {
    isPolicyCounterUsedByPolicyRules,
    isPolicyCounterUsedByCs,
    isRatingGroupUsedByConditionParameters,
    isUsageCounterUsedByCs,
    isUsageCounterUsedByPolicyCounters,
    isUsageCounterUsedByWalletCounters,
    isCsgUsedByPcServices,
    isConditionParameterUsedByCs,
    isConditionParameterUsedByUc,
    isConditionParameterUsedByTs,
    isTariffSpecUsedByTsg,
} from '@/__new__/services/dno/charging/common/entitieInUseByUpperLevelEntities';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { getHistoryMetadata } from '@/__new__/services/dno/pc/http/catalog';
import { parseExpressionsFromBe } from '@/__new__/services/dno/charging/common/expression';

export const entityTypeToEntityLabel = {
    [ENTITY_TYPES.OFFER]: i18n.tc('productCatalog.entities.offer', 1),
    [ENTITY_TYPES.PRODUCT]: i18n.tc('productCatalog.entities.product', 1),
    [ENTITY_TYPES.CATEGORY]: i18n.t('productCatalog.entities.category'),
    [ENTITY_TYPES.SERVICE]: i18n.tc('productCatalog.entities.service', 1),
    [ENTITY_TYPES.RESOURCE]: i18n.tc('productCatalog.entities.resource', 1),
    [ENTITY_TYPES.TEMPLATE]: i18n.tc('productCatalog.entities.template', 1),
    [ENTITY_TYPES.PRESET_TEMPLATE]: i18n.tc('productCatalog.entities.presetTemplate', 1),
    [ENTITY_TYPES.FACET]: i18n.tc('productCatalog.entities.facet', 1),
    [ENTITY_TYPES.VALIDATION_RULE]: i18n.tc('productCatalog.entities.validationRule', 1),
    [ENTITY_TYPES.RATING_GROUP]: 'Rating Group',
    [ENTITY_TYPES.REWARD_RULE]: 'Reward',
    [ENTITY_TYPES.REWARD_PAYOUT]: 'Payout',
    [ENTITY_TYPES.PRIZE]: 'Prize',
    [ENTITY_TYPES.PRIZE_ENGINE]: 'Prize Engine',
    [ENTITY_TYPES.VOUCHER_SET]: 'Voucher Set',
    [ENTITY_TYPES.LOTTERY]: 'Lottery',
    [ENTITY_TYPES.CONTENT_HOSTING_ITEM]: 'Content',
    [ENTITY_TYPES.REMOTE_CONFIG]: 'Remote Config',
    [ENTITY_TYPES.STATIC_FILTER]: 'Static Filter',
    [ENTITY_TYPES.SEGMENT]: 'Segment',
    [ENTITY_TYPES.POLICY_COUNTER]: 'Policy Counter',
    [ENTITY_TYPES.POLICY_RULE]: 'Policy Rule',
    [ENTITY_TYPES.CHARGING_SPECIFICATION]: 'Charging Specification',
    [ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP]: 'Charging Specifications Group',
    [ENTITY_TYPES.USAGE_COUNTER]: 'Usage Counter',
    [ENTITY_TYPES.CONDITION_PARAMETERS]: 'Condition Parameters',
    [ENTITY_TYPES.EVENT]: i18n.t('events.event'),
    [ENTITY_TYPES.CAMPAIGN]: i18n.t('campaigns.campaign'),
    [ENTITY_TYPES.ORD_CONFIG]: i18n.t('sinkConfigs.ORDConfigLabel'),
    [ENTITY_TYPES.REDSHIFT_CONFIG]: i18n.t('sinkConfigs.RedshiftConfigLabel'),
    [ENTITY_TYPES.KAFKA_SINK_CONFIG]: i18n.t('sinkConfigs.KafkaConfigLabel'),
    [ENTITY_TYPES.API_INVOKER_SINK_CONFIG]: i18n.t('sinkConfigs.APIIConfigLabel'),
};

export const EDITOR_MODE = {
    EDIT: 'edit',
    VIEW: 'view',
};

/**
 * @param field
 * @param locale
 * @returns {string|*}
 */
export function getMultiLangFieldValueByLocale(field, locale = 'en', defaultValue = 'N/A') {
    if (field) {
        if (typeof field === 'object') {
            return Object.hasOwnProperty.call(field, locale) ? field[locale] : defaultValue;
        }
        return field;
    }
    return defaultValue;
}

/**
 * @param userIds
 * @returns {object}
 */
export async function getUpdateUserNames(userIds) {
    const promises = userIds.map(id => getUserNameById(Number(id)));
    const names = (await Promise.allSettled(promises)).map(res => res.value?.data);
    return Object.fromEntries(userIds.map((id, index) => [id, names[index]]));
}

/**
 * @param entityType
 * @param id
 * @param properties comma separated string of additional properties to request
 * @returns {array}
 */
export async function loadVersionHistory(entityType, id, properties, includeUsernames = true) {
    const result = await getHistoryMetadata(id, entityType, properties);
    const versionMetadata = result?.data?.entity_version_metadata ?? [];
    const userIds = [...new Set(versionMetadata.map(data => data.portal_id))];
    let userIdToName;
    if (includeUsernames) {
        userIdToName = await getUpdateUserNames(userIds);
    }
    return versionMetadata.map(el => {
        const id = el.portal_id;

        let authorUser = '';
        if (includeUsernames) {
            authorUser = userIdToName[id];
        }

        const versionStr = `${i18n.t('generic.version')} ${el.version}`;
        const authorStr = id ? authorUser : i18n.t('generic.modifiedByApi');
        const dateStr = `${localeLibrary.getFormattedDateAndTime(el.update_time)}`;

        // Build label string:
        // Note: Filter will remove authorStr if it's undefined
        const labelContents = [versionStr, authorStr, dateStr].filter(str => str);
        const label = labelContents.join(' - ');
        return {
            ...el,
            label,
        };
    });
}

// ToDo: get rid of it after all data is migrated to new format
export function getProperlyFormattedMultilangFieldValue(fieldValue) {
    if (fieldValue) {
        if (typeof fieldValue === 'object') {
            return { ...fieldValue };
        }
        return {
            en: fieldValue,
        };
    }
    return {};
}

/**
 * @param element
 * @param list
 */
function pushFormattedEntityToArray(element, list) {
    const state = getEntityStateName(element.state);
    list.push({
        id: element.id,
        name: getMultiLangFieldValueByLocale(element.data.name),
        state,
    });
}

/**
 * @param type
 * @returns {*}
 */
function getEntitiesByType(type) {
    if (type === ENTITY_TYPES.CHARGING_SPECIFICATION) {
        return store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`];
    }
    if (type === ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP) {
        return store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS_GROUPS}`];
    }

    return [];
}

function getAffectedChargingSpecificationsList(id) {
    const affectedChargingSpecificationsList = [];
    const ratingGroup = store.getters[`chargingV2/${Getters.GET_RATING_GROUP_BY_ID}`](id);

    getEntitiesByType(ENTITY_TYPES.CHARGING_SPECIFICATION).forEach(el => {
        if (el.data.service_type === 1) {
            if (checkObjectPath(el, 'data.conditions.parameters')) {
                Object.values(el.data.conditions.parameters).forEach(e => {
                    if (Object.hasOwnProperty.call(e, 'rating_group_numbers')) {
                        e.rating_group_numbers.forEach(number => {
                            if (ratingGroup?.number === number) {
                                pushFormattedEntityToArray(el, affectedChargingSpecificationsList);
                            }
                        });
                    }
                });
            }
        }
    });
    return affectedChargingSpecificationsList;
}

/**
 * function is for getting condition parameter id
 * from condition expresionss in CS and UC
 * @param originalExpr
 * @returns {Array}
 */
function getConditionParametersIds(originalExpr) {
    return originalExpr.reduce((data, current) => {
        const uuids = current.value.map(el => el.conditionUUID);
        return [...new Set([...data, ...uuids])];
    }, []);
}

export function getAffectedByPolicyCounter(id) {
    const policyRules = store.getters[`chargingV2/${Getters.GET_POLICY_RULES}`].filter(
        pr => pr.state !== EntityStateMapping.DELETED,
    );
    const affectedPolicyRules = policyRules.filter(current => {
        const inUse = Object.values(current?.conditions?.parameters).filter(el => el.policy_counter_id === id);
        return inUse.length ? inUse : null;
    });

    const policyCounter = store.getters[`chargingV2/${Getters.GET_POLICY_COUNTER_BY_ID}`](id);
    if (policyCounter?.usageCounterId !== undefined) {
        return [...affectedPolicyRules, policyCounter];
    }

    return affectedPolicyRules;
}

export function isUsageCounterInUse(usageCounterId) {
    const policyCounters = store.getters[`chargingV2/${Getters.GET_POLICY_COUNTERS}`];
    const chargingSpecifications = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`];
    const walletCounters = store.getters[`chargingV2/${Getters.GET_WALLET_COUNTERS}`];
    /* eslint-disable camelcase */
    const affectedByPC = policyCounters.filter(pc => pc?.usageCounterId && pc.usageCounterId === usageCounterId);
    const affectedByCS = chargingSpecifications.filter(
        cs => cs.data.usage_counters && cs.data.usage_counters.includes(usageCounterId),
    );
    const affectedByWC = walletCounters.filter(wc => wc?.usageCounterId === usageCounterId);

    return [...affectedByPC, ...affectedByCS, ...affectedByWC];
}

export function isConditionParameterInUse(cpId) {
    const chargingSpecifications = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`];
    const usageCounters = store.getters[`chargingV2/${Getters.GET_USAGE_COUNTERS}`];

    const affectedByCS = chargingSpecifications.filter(cs => {
        const originalExpr = cs?.data?.condition_expr ? parseExpressionsFromBe(cs.data.condition_expr) : [];
        const ids = getConditionParametersIds(originalExpr);
        return ids.includes(cpId);
    });
    const affectedByUC = usageCounters.filter(pc => {
        const ids = getConditionParametersIds(pc.conditionExpr);
        return ids.includes(cpId);
    });

    return [...affectedByCS, ...affectedByUC];
}

export function isContentTypeInUse(ctId) {
    const chargingSpecifications = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`];
    return chargingSpecifications.filter(cs => {
        return cs?.data?.content_type_id === ctId;
    });
}

function getAffectedCSGFromChargingSpecificationsList(id) {
    const affectedProductsList = [];

    getEntitiesByType(ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP).forEach(element => {
        if (Object.hasOwnProperty.call(element.data, 'charging_specs')) {
            element.data.charging_specs.forEach(ids => {
                if (Array.isArray(id)) {
                    id.forEach(el => {
                        if (ids === el.id) {
                            pushFormattedEntityToArray(element, affectedProductsList);
                        }
                    });
                } else if (ids === id) {
                    pushFormattedEntityToArray(element, affectedProductsList);
                }
            });
        }
    });
    return affectedProductsList;
}

/**
 * Function accepts an entity ID and a Type of entity and returns all entities above it that have that entity used.
 * Function expects Vuex to be populated with entities in order to work properly.
 * @param id
 * @param type
 * @returns {{}}
 */
export function getAffectedEntities(id, type) {
    const affectedEntities = {
        [ENTITY_TYPES.OFFER]: {},
        [ENTITY_TYPES.PRODUCT]: {},
        [ENTITY_TYPES.SERVICE]: {},
        [ENTITY_TYPES.RATING_GROUP]: {},
        [ENTITY_TYPES.POLICY_COUNTER]: {},
        [ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP]: {},
        [ENTITY_TYPES.CHARGING_SPECIFICATION]: {},
        [ENTITY_TYPES.USAGE_COUNTER]: {},
        [ENTITY_TYPES.CONDITION_PARAMETERS]: {},
        [ENTITY_TYPES.POLICY_RULE]: {},
        [ENTITY_TYPES.CONTENT_TYPES]: {},
    };

    if (type === ENTITY_TYPES.RATING_GROUP) {
        affectedEntities[ENTITY_TYPES.CHARGING_SPECIFICATION] = getAffectedChargingSpecificationsList(id);
        affectedEntities[ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP] = getAffectedCSGFromChargingSpecificationsList(
            affectedEntities[ENTITY_TYPES.CHARGING_SPECIFICATION],
        );
    } else if (type === ENTITY_TYPES.POLICY_COUNTER) {
        affectedEntities[ENTITY_TYPES.POLICY_COUNTER] = getAffectedByPolicyCounter(id);
    } else if (type === ENTITY_TYPES.CHARGING_SPECIFICATION) {
        affectedEntities[ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP] = getAffectedCSGFromChargingSpecificationsList(id);
    } else if (type === ENTITY_TYPES.USAGE_COUNTER) {
        affectedEntities[ENTITY_TYPES.USAGE_COUNTER] = isUsageCounterInUse(id);
    } else if (type === ENTITY_TYPES.CONDITION_PARAMETERS) {
        affectedEntities[ENTITY_TYPES.CONDITION_PARAMETERS] = isConditionParameterInUse(id);
    } else if (type === ENTITY_TYPES.CONTENT_TYPES) {
        affectedEntities[ENTITY_TYPES.CONTENT_TYPES] = isContentTypeInUse(id);
    }

    return affectedEntities;
}

export function getEntityConsumers(id, type, data = []) {
    const upperEntities = {
        [ENTITY_TYPES.CATEGORY]: {},
        [ENTITY_TYPES.OFFER]: {},
        [ENTITY_TYPES.PRODUCT]: {},
        [ENTITY_TYPES.CHARGING_SPECIFICATION]: {},
        [ENTITY_TYPES.POLICY_RULE]: {},
        [ENTITY_TYPES.SERVICE]: {},
        [ENTITY_TYPES.USAGE_COUNTER]: {},
        [ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP]: {},
    };

    switch (type) {
        case ENTITY_TYPES.CHARGING_SPECIFICATION:
            upperEntities[ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP] =
                getAffectedCSGFromChargingSpecificationsList(id);
            break;
        case ENTITY_TYPES.POLICY_COUNTER:
            upperEntities[ENTITY_TYPES.CHARGING_SPECIFICATION] = isPolicyCounterUsedByCs(id);
            upperEntities[ENTITY_TYPES.POLICY_RULE] = isPolicyCounterUsedByPolicyRules(id);
            break;
        case ENTITY_TYPES.RATING_GROUP:
            upperEntities[ENTITY_TYPES.CONDITION_PARAMETERS] = isRatingGroupUsedByConditionParameters(id);
            break;
        case ENTITY_TYPES.USAGE_COUNTER:
            upperEntities[ENTITY_TYPES.CHARGING_SPECIFICATION] = isUsageCounterUsedByCs(id);
            upperEntities[ENTITY_TYPES.POLICY_COUNTER] = isUsageCounterUsedByPolicyCounters(id);
            upperEntities[ENTITY_TYPES.WALLET_COUNTERS] = isUsageCounterUsedByWalletCounters(id);
            break;
        case ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP:
            upperEntities[ENTITY_TYPES.SERVICE] = isCsgUsedByPcServices(id, data);
        case ENTITY_TYPES.CONDITION_PARAMETERS:
            upperEntities[ENTITY_TYPES.CHARGING_SPECIFICATION] = isConditionParameterUsedByCs(id);
            upperEntities[ENTITY_TYPES.USAGE_COUNTER] = isConditionParameterUsedByUc(id);
            upperEntities[ENTITY_TYPES.TARIFF_SPECIFICATION] = isConditionParameterUsedByTs(id);
        case ENTITY_TYPES.TARIFF_SPECIFICATION:
            upperEntities[ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP] = isTariffSpecUsedByTsg(id);
        default:
            break;
    }

    return upperEntities;
}

export function getMutationDialogTitleKey(typeOfEntity) {
    const mapper = {
        [ENTITY_TYPES.VOUCHER_SET]: 'productCatalog.entities.plural.voucherSets',
        [ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP]: 'charging.usedBy.chargingSpecificationsGroups',
        [ENTITY_TYPES.CHARGING_SPECIFICATION]: 'charging.usedBy.chargingSpecifications',
        [ENTITY_TYPES.POLICY_RULE]: 'charging.usedBy.policyRules',
        [ENTITY_TYPES.CONDITION_PARAMETERS]: 'charging.usedBy.conditionParameters',
        [ENTITY_TYPES.POLICY_COUNTER]: 'charging.usedBy.policyCounters',
        [ENTITY_TYPES.WALLET_COUNTERS]: 'charging.usedBy.walletCounters',
        [ENTITY_TYPES.SERVICE]: 'charging.usedBy.productCatalogServices',
        [ENTITY_TYPES.USAGE_COUNTER]: 'charging.usedBy.usageCounters',
        [ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP]: 'charging.usedBy.tsg',
    };

    return mapper[typeOfEntity] || 'generic.usedBy';
}

export function formatMutationDialogEntities(entities, filterType) {
    const formatedEntities = {};
    for (const type in entities) {
        if (!filterType || type === filterType) {
            formatedEntities[type] = {
                key: type,
                title: i18n.t(getMutationDialogTitleKey(type)),
                list: entities[type],
            };
        }
    }
    return formatedEntities;
}

export default {};
