import { render, staticRenderFns } from "./NonSensitiveInfoPage.vue?vue&type=template&id=39681422&scoped=true"
import script from "./NonSensitiveInfoPage.vue?vue&type=script&lang=ts"
export * from "./NonSensitiveInfoPage.vue?vue&type=script&lang=ts"
import style0 from "./NonSensitiveInfoPage.vue?vue&type=style&index=0&id=39681422&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39681422",
  null
  
)

export default component.exports