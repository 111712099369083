











































































































// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';

export default {
    name: 'OePlans',
    components: {
        AppTable,
        ExecutionStatusIndicator,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        SearchBox,
        IconButton,
        ResponseModalButton,
    },
    mixins: [OePlansMixin],
    props: {
        targetTypeId: {
            type: String,
            default: '',
        },
        searchData: {
            type: Object,
            default: () => undefined,
        },
        titleIsEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            ICON_TYPES: ICON_TYPES as any,
            RouteNames: RouteNames as any,
            searchQuery: '' as string,
        };
    },
    async created() {
        const data = this.targetTypeId ? { mapper_ids: [this.targetTypeId] } : this.searchData;
        await this.fetchExecutions(data);
    },
};
