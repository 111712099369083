





































// components
import ListDropdownSectionItem from '@/components/partials/entityOverview/ListDropdownSectionItem.vue';
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import Tag from '@/components/partials/inputs/Tag.vue';

// helpers
import { TranslateResult } from 'vue-i18n';

// types
export type TagsOverviewItems = {
    title: TranslateResult;
    tags: string[];
};

export default {
    name: 'TagsOverview',
    components: {
        ListDropdownSectionItem,
        CollapsibleItem,
        Tag,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        /**
         * @param - array of objects like:
         * [{  title: 'any string',
         * tags: ['tag1', 'tag2', 'tag3']
         * ]
         */
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
};
