





























// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import { copyToClipboard } from '@/common/utils';

// Https
import { multiRestartState } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

/* eslint-disable camelcase */
interface PausedExecutionData {
    execution_id: string;
    continue_state: string;
    input?: object;
}
/* eslint-enable camelcase */

export default {
    name: 'PausedExecutionsDataModal',

    components: {
        AppButton,
        AppDialogV2,
        AppJSON,
        IconButton,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        pausedExecutionsCodeData: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,

            pausedExecutionsData: [] as PausedExecutionData[],
            isBatchResumeButtonClicked: false as boolean,
            isPausedExecutionsDataValid: true as boolean,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return (
                isUserAllowed('PausedExecutionsWrite') &&
                permissionsService.orchestrationEnginePausedExecutionsEnabled()
            );
        },
    },

    created() {
        const resumeData = this.pausedExecutionsCodeData.map((execution: any) => ({
            execution_id: execution.executionId,
            continue_state: execution.lastState,
        }));

        this.pausedExecutionsData = resumeData;
    },

    methods: {
        copyToClipboard,
        async onBatchResume(): Promise<any> {
            if (!this.isPausedExecutionsDataValid) {
                return;
            }

            try {
                this.isBatchResumeButtonClicked = true;

                this.$Progress.start();
                await multiRestartState(this.pausedExecutionsData);
                this.$Progress.finish();

                this.$emit('onBatchResumed');
                this.onCloseModal();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.batchResumeSuccess'),
                    type: this.$ALERT_TYPES.success,
                });
            } catch (e: any) {
                this.$alert(this.$i18n.t('orchestrationEngine.batchResumeFailure'));
                this.$Progress.fail();
            } finally {
                this.isBatchResumeButtonClicked = false;
            }
        },
        onCloseModal(): void {
            this.$emit('onCloseModal');
        },
    },
};
