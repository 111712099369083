import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http';

type itemRequestData = {
    key: string;
    value: string;
    visibility: {
        label: string;
        value: number;
    };
};

type ServiceRequestData = {
    labels: Record<string, string>;
};

interface RemoteConfigItem {
    value: string;
    visibility: 0 | 1;
}

interface GetRemoteConfigResponse extends DnoResponseBase {
    data: Record<string, RemoteConfigItem>;
}

export function getRemoteConfig(): AxiosPromise<GetRemoteConfigResponse> {
    return http({
        method: 'POST',
        url: '/remoteconfig/getv3',
    });
}

export function getServices(data: ServiceRequestData): AxiosPromise<{ data: [] | Record<string, never> }> {
    return http({
        method: 'POST',
        url: '/remoteconfig/getservices',
        data,
    });
}

export function updateItemRequest(data: itemRequestData) {
    return http({
        method: 'POST',
        url: '/remoteconfig/updatev3',
        data,
    });
}

export default {
    getRemoteConfig,
    getServices,
    updateItemRequest,
};
