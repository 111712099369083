































































































// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// http
import { addTemplate, getTemplate } from '@/__new__/services/dno/documents/http/templates';
import { previewDocument } from '@/__new__/services/dno/documents/http/documents';

// Helpers
import * as Sentry from '@sentry/vue';
import importMonacoHelper from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import isEmpty from 'lodash/isEmpty';
import RouteNames from '@/router/routeNames';
import Button from '@/common/button/Button';
import {
    DOCUMENT_GENERATE_TYPES,
    DocumentTemplatesUrlData,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';

// helper for error "Property 'monaco'
// does not exist on type 'Window & typeof globalThis'"
declare const window: any;

const MAX_NUMBER_OF_ITERATIONS = 12;

export default {
    name: 'DocumentTemplateEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppButton,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, supportButtonMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            templateName: '' as string,
            inputHtmlString: '' as string,
            htmlEditor: null as any,
            jsonEditor: null as any,
            inputJsonString: '{}' as string,
            isHtmlCodeHighlightEnabled: true as boolean,
            isJsonCodeHighlightEnabled: true as boolean,
            entityType: ENTITY_TYPES.DOCUMENT_TEMPLATES as string,
            documenttPollers: {} as any,
            numberOfIterations: 0 as number,
            isDataLoading: false as boolean,
            isDocGenerated: false as boolean,
            confirmButton: new Button({
                label: this.$t('generic.save'),
                handler: this.saveConfirmed.bind(this),
            }),
        };
    },
    computed: {
        ...mapGetters(Modules.documents, [Getters.GET_DOCUMENT_TEMPLATE_BY_ID]),
        pageTitle(): string {
            return this.isTemplateEdited ? this.$t('documents.editTemplate') : this.$t('documents.addNewTemplate');
        },
        isTemplateEdited(): boolean {
            const { id, clone } = this.$route.params;
            return id && clone === undefined;
        },
        templateNameErrorMessage(): string {
            return this.$v.templateName.validator === false
                ? this.$t('documents.templateNameErrorMessage')
                : this.$t('generic.validations.fieldIsRequired');
        },
        doesDocumentTemplateNameExists(): boolean {
            return Boolean(this.templateName && !isEmpty(this[Getters.GET_DOCUMENT_TEMPLATE_BY_ID](this.templateName)));
        },
    },
    watch: {
        inputHtmlString(val: string, oldVal: string): void {
            if (val !== oldVal) {
                this.isDocGenerated = false;
            }
        },
        isDataLoading(isLoading: boolean): void {
            this.htmlEditor?.updateOptions({ readOnly: isLoading });
            this.jsonEditor?.updateOptions({ readOnly: isLoading });
        },
    },
    created() {
        this.$withLoadingSpinner(
            async (): Promise<any> => {
                await this[Actions.REQUEST_DOCUMENT_TEMPLATES]();
                const { id } = this.$route.params;
                if (id) {
                    await this.renderDocumentTemplate(id);
                }
            },
            {
                errorHandler: () => {
                    this.showSupportAlert(this.$t('alertMessage.somethingWentWrong'));
                },
            },
        );
    },
    async mounted() {
        try {
            await importMonacoHelper.importMonaco();
            this.loadHtmlEditor();
            this.loadJsonEditor();
        } catch (e: any) {
            Sentry.captureException(e);
            this.showSupportAlert(this.$t('alertMessage.somethingWentWrong'));
        }
    },
    validations: {
        templateName: {
            required,
            validator: name => /^[a-zA-Z0-9_]+$/.test(name),
        },
        inputHtmlString: {
            required,
        },
    },
    methods: {
        ...mapActions(Modules.documents, [Actions.REQUEST_DOCUMENT_TEMPLATES]),
        async renderDocumentTemplate(templateId: string): Promise<void> {
            try {
                // eslint-disable-next-line prettier/prettier
                const { data: { data: { template_definition: templateDefinition = '', metadata } }} = await getTemplate(templateId);
                const previewPayload = metadata?.preview_payload;

                this.templateName = this.$route.params.clone ? `${templateId}_cloned` : templateId;

                this.htmlEditor.setValue(templateDefinition);
                if (previewPayload) {
                    this.jsonEditor.setValue(JSON.stringify(previewPayload));
                }
            } catch (e: any) {
                Sentry.captureException(e);
                this.$alert(e?.response?.data?.message ?? this.$t('documents.loadError'));
            }
        },
        loadHtmlEditor(): void {
            this.htmlEditor = window.monaco.editor.create(document.getElementById('htmlEditor'), {
                value: this.inputHtmlString,
                language: 'html',
                automaticLayout: true,
            });
            this.htmlEditor.onDidChangeModelContent(() => {
                this.inputHtmlString = this.htmlEditor.getValue();
            });
        },
        loadJsonEditor(): void {
            this.jsonEditor = window.monaco.editor.create(document.getElementById('jsonEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
            });
            this.jsonEditor.onDidChangeModelContent(() => {
                this.inputJsonString = this.jsonEditor.getValue();
            });
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }
            if (this.doesDocumentTemplateNameExists && !this.isTemplateEdited) {
                this.$alert(this.$t('documents.templateNameUniqueErrorMessage'));
                return false;
            }

            return true;
        },
        onSave(): Promise<void> | undefined {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$alert(this.$t('documents.confirmSave'), {
                type: ALERT_TYPES.warning,
                buttons: [this.confirmButton],
            });
        },
        async saveConfirmed(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    const jsonInput = JSON.parse(this.inputJsonString);
                    this.isDataLoading = true;

                    await addTemplate({
                        templateId: this.templateName,
                        templateDefinition: this.inputHtmlString,
                        previewPayload: !isEmpty(jsonInput) ? jsonInput : undefined,
                    });

                    this.$showSuccessAlert({
                        message: this.isTemplateEdited
                            ? this.$t('documents.templateUpdated')
                            : this.$t('documents.templateAdded'),
                    });

                    this.isDataLoading = false;
                    this.entityEditorMixin.successfullySaved = true;
                    setTimeout(
                        () =>
                            this.$router.push({
                                name: RouteNames.DOCUMENT_TEMPLATES,
                                params: { companyId: this.$route.params.companyId },
                            }),
                        1000,
                    );
                },
                {
                    errorHandler: (e: any) =>
                        this.$alert(e?.response?.data?.details?.error || this.$t('documents.saveError')),
                },
            );
        },
        async generateDocument(): Promise<void> {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$showInfoAlert({ message: this.$t('documents.documentGenerated') });

            this.isDataLoading = true;

            await this.$withProgressBar(
                async () => {
                    this.numberOfIterations += 1;
                    const res = await previewDocument({
                        payload: JSON.parse(this.inputJsonString),
                        fileName: this.templateName,
                        templateDefinition: this.inputHtmlString,
                    });

                    const { status, url_data: urlData = {} as DocumentTemplatesUrlData } = res.data.data;

                    if (status !== DOCUMENT_GENERATE_TYPES.COMPLETED && isEmpty(urlData)) {
                        const poller = setInterval(() => {
                            if (this.numberOfIterations >= MAX_NUMBER_OF_ITERATIONS) {
                                this.clearDocumentInterval();
                                this.$alert(this.$t('documents.maxNumberOfIterations'));
                                return;
                            }

                            this.checkAvailabilityOfDocument();
                        }, 5000);
                        this.$set(this.documenttPollers, this.templateName, poller);
                    } else {
                        this.documentGenerationSucceeded(urlData);
                    }
                },
                {
                    errorHandler: () => {
                        this.isDocGenerated = false;
                        this.clearDocumentInterval();
                    },
                },
            );
        },
        async checkAvailabilityOfDocument(): Promise<void> {
            try {
                this.numberOfIterations += 1;
                const res = await previewDocument({
                    payload: JSON.parse(this.inputJsonString),
                    fileName: this.templateName,
                    templateDefinition: this.inputHtmlString,
                });

                const { status, url_data: urlData = {} as DocumentTemplatesUrlData } = res.data.data;

                if (status === DOCUMENT_GENERATE_TYPES.COMPLETED && !isEmpty(urlData)) {
                    this.documentGenerationSucceeded(urlData);
                }
            } catch (e: any) {
                this.clearDocumentInterval();
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(e?.response?.data?.message ?? this.$t('documents.documentGeneratedError'));
            }
        },
        clearDocumentInterval(): void {
            clearInterval(this.documenttPollers[this.templateName]);
            this.$delete(this.documenttPollers, this.templateName);
            this.isDataLoading = false;
            this.numberOfIterations = 0;
        },
        documentGenerationSucceeded(urlData: DocumentTemplatesUrlData): void {
            this.clearDocumentInterval();
            this.isDocGenerated = true;
            window.open(urlData.signed_url, '_blank');
        },
    },
};
