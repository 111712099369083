var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageTitle)?_c('AppHeader',{attrs:{"pageTitle":_vm.pageTitle}}):_vm._e(),(_vm.basicInfo)?_c('EndUserAuthorizationBanner',{attrs:{"targetType":_vm.USER_MANAGER_HIERARCHY.ACCOUNT,"targetId":_vm.getTargetIdForAuthBanner}}):_vm._e(),_c('Breadcrumbs',{staticClass:"ml-4",attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"anchor-wrapper"},[_c('AppCustomerHeader',{attrs:{"infoData":_vm.infoData}}),(_vm.isCurrentTargetType(_vm.USER_MANAGER_HIERARCHY.USER))?_c('AppTable',{attrs:{"entities":_vm.getUserAssociatedAccounts,"columnsData":_vm.userAssociatedAccountsTableColumnsData,"isDataLoading":_vm.isDataLoading,"isSearchEnabled":true,"tableTitle":_vm.$i18n.t('authorizationPage.associatedAccounts'),"tableKey":"user-associated-accounts"},scopedSlots:_vm._u([{key:"accountId",fn:function(ref){
var entity = ref.entity;
return [_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.CCS_ACCOUNT_PAGE,
                                params: {
                                    id: entity.accountId,
                                },
                            }}},[_vm._v(" "+_vm._s(entity.accountId)+" ")])],1)]}}],null,false,2394579754)}):_vm._e(),(_vm.isCurrentTargetType(_vm.USER_MANAGER_HIERARCHY.ACCOUNT))?_c('div',[_c('AppTable',{attrs:{"entities":_vm.getAccountMembers,"columnsData":_vm.accountMembersTableColumnsData,"isDataLoading":_vm.isDataLoading,"isSearchEnabled":true,"tableTitle":_vm.$i18n.t('authorizationPage.accountMembers'),"tableKey":"account-members"},scopedSlots:_vm._u([{key:"userId",fn:function(ref){
                            var entity = ref.entity;
return [_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                    name: _vm.RouteNames.CCS_USER_PAGE,
                                    params: {
                                        id: entity.userId,
                                    },
                                }}},[_vm._v(" "+_vm._s(entity.userId)+" ")])],1)]}}],null,false,1299872714)}),_c('AppTable',{attrs:{"entities":_vm.getAccountSubscribers,"columnsData":_vm.accountSubscribersTableColumnsData,"isDataLoading":_vm.isDataLoading,"isSearchEnabled":true,"tableTitle":_vm.$i18n.t('authorizationPage.accountSubscribers'),"tableKey":"account-subscribers"},scopedSlots:_vm._u([{key:"subscriberId",fn:function(ref){
                                var entity = ref.entity;
return [_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                    name: _vm.RouteNames.CCS_SUBSCRIBER_PAGE,
                                    params: {
                                        id: entity.subscriberId,
                                    },
                                }}},[_vm._v(" "+_vm._s(entity.subscriberId)+" ")])],1)]}}],null,false,175364682)})],1):_vm._e(),(_vm.isCurrentTargetType(_vm.USER_MANAGER_HIERARCHY.SUBSCRIBER))?_c('AppTable',{attrs:{"entities":_vm.getSubscriberEmails,"columnsData":_vm.subscriberEmailsTableColumnsData,"isDataLoading":_vm.isDataLoading,"isSearchEnabled":true,"tableTitle":_vm.$i18n.t('authorizationPage.ownerEmails'),"tableKey":"subscriber-owner-emails"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }