var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title row",class:{
            'section-title-basic row': _vm.isNewDesignSelected,
            'section-title-capitalized': _vm.capitalizedTitles,
        },on:{"click":function($event){_vm.isCollapsed = !(_vm.isCollapsed || _vm.alwaysExpanded)}}},[_c('div',{staticClass:"col-12 d-flex justify-content-between"},[_c('span',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.section.name)+" "),_vm._t("nameTooltip")],2),_c('img',{staticClass:"arrow",class:[{ collapsed: _vm.isCollapsed }],attrs:{"src":require("../../../assets/icons/arrow-up-dark.svg"),"alt":"arrow"}})])]),_vm._l((_vm.section.properties),function(property,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],key:index,staticClass:"container section-properties"},[_c('div',{staticClass:"section-property row no-gutters",class:{
                'section-property-basic': _vm.isNewDesignSelected,
                'section-property-big-gap': _vm.useBigRowGaps,
                'section-property-error': property.isError,
            }},[(property.value || property.value === 0)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content:
                            property.formatter === _vm.PROPERTY_FORMATTERS.TEMPLATE_PROPERTY
                                ? ((_vm.$i18n.t('generic.externalId')) + ": " + (property.externalId))
                                : '',
                        placement: 'top',
                        autoHide: false,
                        classes: ['tooltip-bold'],
                    }),expression:"{\n                        content:\n                            property.formatter === PROPERTY_FORMATTERS.TEMPLATE_PROPERTY\n                                ? `${$i18n.t('generic.externalId')}: ${property.externalId}`\n                                : '',\n                        placement: 'top',\n                        autoHide: false,\n                        classes: ['tooltip-bold'],\n                    }"}],staticClass:"col-4 label-bold",class:{ 'property-value-name': _vm.isNewDesignSelected }},[_vm._v(" "+_vm._s(property.label)+" ")]),(property.formatter === 'link')?_c('div',{staticClass:"col-8 value link",class:{ disabled: _vm.isDisabled(property.value) }},[_c('a',{staticClass:"hover-underline",on:{"click":function($event){return _vm.changeLocation(property)}}},[_vm._v(" "+_vm._s(property.linkLabel || 'More Info')+" ")])]):_c('div',{staticClass:"col-8 value",class:{ error: property.isError }},[_vm._v(" "+_vm._s(_vm.formatProperty(property.value, property.formatter))+" ")])]:[_c('div',{staticClass:"col-4 label-bold"},[_vm._v(" "+_vm._s(property.label)+" ")]),(typeof property.value === 'boolean')?_c('div',{staticClass:"col-8 value"},[_vm._v(" "+_vm._s(_vm.formatBooleanValue(property.value))+" ")]):_vm._e()]],2)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }