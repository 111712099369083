export type ApplicationDataBe = {
    id: string;
    naas_env_id: string;
    naas_org_id: string;
    naas_parent_org_id: string;
    naas_partner_id: string;
    naas_caller_partner_id: string;
    naas_app_client_id: string;
    app_use_type: string;
    name: string;
    description: string;
    public_key: string;
    api_subscriptions: {
        offer_id: string;
        product_id: string;
        purpose: string;
        order_creation_timestamp: number;
        naas_service_id: string;
    }[];
    api_redirect_uris: string[];
    pending_name: string;
    pending_description: string;
    pending_public_key: string;
    pending_api_subscriptions: string[];
    pending_api_redirect_uris: string[];
    status: number;
    update_timestamp: number;
};

export default class ApplicationData {
    id: string;
    naasEnvId: string;
    naasOrgId: string;
    naasParentOrgId: string;
    naasPartnerId: string;
    naasCallerPartnerId: string;
    naasAppClientId: string;
    appUseType: string;
    name: string;
    description: string;
    publicKey: string;
    apiSubscriptions: {
        offerId: string;
        productId: string;
        purpose: string;
        orderCreationTimestamp: number;
        naasServiceId: string;
    }[];
    apiRedirectUris: string[];
    pendingName: string;
    pendingDescription: string;
    pendingPublicKey: string;
    pendingApiSubscriptions: string[];
    pendingApiRedirectUris: string[];
    status: number;
    updateTimestamp: number;

    constructor(data: ApplicationData | Record<string, never> = {}) {
        this.id = data?.id || '';
        this.naasEnvId = data?.naasEnvId || '';
        this.naasOrgId = data?.naasOrgId || '';
        this.naasParentOrgId = data?.naasParentOrgId || '';
        this.naasPartnerId = data?.naasPartnerId || '';
        this.naasCallerPartnerId = data?.naasCallerPartnerId || '';
        this.naasAppClientId = data?.naasAppClientId || '';
        this.appUseType = data?.appUseType || '';
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.publicKey = data?.publicKey || '';
        this.apiSubscriptions = data?.apiSubscriptions || [];
        this.apiRedirectUris = data?.apiRedirectUris || [];
        this.pendingName = data?.pendingName || '';
        this.pendingDescription = data?.pendingDescription || '';
        this.pendingPublicKey = data?.pendingPublicKey || '';
        this.pendingApiSubscriptions = data?.pendingApiSubscriptions || [];
        this.pendingApiRedirectUris = data?.pendingApiRedirectUris || [];
        this.status = data?.status || 0;
        this.updateTimestamp = data?.updateTimestamp || 0;
    }

    static mapResourceFromBE(app: ApplicationDataBe) {
        return {
            id: app.id,
            naasEnvId: app.naas_env_id,
            naasOrgId: app.naas_org_id,
            naasParentOrgId: app.naas_parent_org_id,
            naasPartnerId: app.naas_partner_id,
            naasCallerPartnerId: app.naas_caller_partner_id,
            naasAppClientId: app.naas_app_client_id,
            appUseType: app.app_use_type,
            name: app.name,
            description: app.description,
            publicKey: app.public_key,
            apiSubscriptions: app.api_subscriptions.map(el => {
                return {
                    offerId: el.offer_id,
                    productId: el.product_id,
                    purpose: el.purpose,
                    orderCreationTimestamp: el.order_creation_timestamp,
                    naasServiceId: el.naas_service_id,
                };
            }),
            apiRedirectUris: app.api_redirect_uris,
            pendingName: app.pending_name,
            pendingDescription: app.pending_description,
            pendingPublicKey: app.pending_public_key,
            pendingApiSubscriptions: app.pending_api_subscriptions,
            pendingApiRedirectUris: app.pending_api_redirect_uris,
            status: app.status,
            updateTimestamp: app.update_timestamp,
        };
    }
}
