import { ACTION, EventCategoryCounters, STATUS } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';

export type PortalId = string;
export type MillisecondsSinceEpoch = number;

export interface Msisdn {
    msisdn: string;
    appClientId: string;
    channel: string;
    customerBan: string;
    payingCustomerId: string;
    commercialOfferName: string;
    action: ACTION;
    actionStr: string;
    status: STATUS;
    statusStr: string;
    lastUpdatedAt: MillisecondsSinceEpoch;
    /**
     * Portal ID of user who last updated this MSISDN
     */
    lastUpdatedBy: PortalId;
    /**
     * Portal name of user who last updated this MSISDN
     */
    lastUpdatedByName?: string;
    bulkUploadId: string;
}

export interface QodMsisdn extends Msisdn {
    customerName: string;
    qodProfiles: string;
}

export interface DeviceStatusMsisdn extends Msisdn {
    approverName: string;
    serviceIds: string;
}

export enum FILE_UPLOAD_STATUS {
    SUCCESS = 1,
    PARTIAL_SUCCESS = 2,
    IN_PROGRESS = 3,
    FAILED = 4, // none of records succeeded
    UNKNOWN = 5, // we couldn't retrieve details from the DNO
    ERROR = 6, // indicates an error on the frontend or backend
}

export interface UploadedFileDetails {
    entityName: string;
    createdAt: number;
    lastUpdated: number;
    fileName?: string;
    bulkUploadId?: string;
    createdBy?: string;
    createdByPortalId?: number;
    recordCount?: number;
    fileUploadStatus?: FILE_UPLOAD_STATUS;
    fileUploadStatusStr?: string;
    eventCategoryCounters?: EventCategoryCounters;
}

export enum DOWNLOAD_FILE_TYPE {
    ORIGINAL = 'original',
    FAILED_RECORDS_ONLY = 'failed_records_only',
}
