

























































// components
import AppJSON from '@/components/partials/AppJSON.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// helpers
import Vue from 'vue';
import { castArray, mapValues, groupBy, partition, isArray, capitalize } from 'lodash';
import { EntityType, EntityTypeLabel } from '@/components/entityTracker/entityTracker';
import { sendTestEvent } from '@/__new__/services/dno/events/http/events';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

export default Vue.extend({
    name: 'EventsEmitter',
    components: {
        AppToggle,
        AppMultiselectV3,
        AppJSON,
        AppButton,
    },

    props: {
        event: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            castArray,
            defaultValues: {
                int: 0,
                long: 0,
                boolean: false,
                array: [],
                object: {},
                number: 0.0,
                string: '',
                integer: 0,
            },
            eventOwners: [],
            eventsPayload: [{}] as any[],
            isValidPayload: true,
            isLoading: false,
            ICON_TYPES,
            BUTTON_TYPES,
            sendToExactSinkValues: [],
            dryRun: false,
        };
    },

    computed: {
        ...mapGetters(Modules.events, [Getters.GET_ENTITY_TRACKER_DATA]),
        sendToExactSinkOptions(): {
            groupName: string;
            groupValues: any[];
        }[] {
            return Object.entries(groupBy(this.eventOwners, el => el.entity_type)).map(([type, values]) => ({
                groupName: EntityTypeLabel[type],
                groupValues: values,
            }));
        },
        submitLabel(): string {
            const label = this.isLoading
                ? `${this.$t('generic.sending')}...`
                : `${this.$t('generic.send')} ${this.eventsPayload.length} ${this.$t('generic.events')}`;
            return capitalize(label);
        },

        isSubmitDisabled(): boolean {
            return !this.isValidPayload || this.isLoading || this.eventsPayload.length < 1;
        },
    },

    created() {
        this.eventOwners = this[Getters.GET_ENTITY_TRACKER_DATA][EntityType.CustomEvent]?.[this.event.id] || [];
        this.eventsPayload = [this.createEmptyEvent()];
    },

    methods: {
        createEmptyEvent() {
            type FieldType = keyof typeof this.defaultValues;
            const payload: Record<string, any> = {};

            this.event.properties.forEach(({ name, type }: { name: string; type: string | string[] }) => {
                const finalType = isArray(type) ? type.find(t => t !== 'null') : type;
                payload[name] = this.defaultValues[finalType as FieldType] ?? '';
            });
            payload.event_type = this.event.eventType;
            payload.epoch_millis = new Date().getTime();

            return payload;
        },

        trySendEvent(): void {
            const sendToCount = this.sendToExactSinkValues.length
                ? this.sendToExactSinkValues.length
                : this.eventOwners.length;
            this.$alert(this.$t('events.alerts.ownersWarning', { count: sendToCount }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.proceed'),
                        handler: this.sendTestEvents,
                    }),
                ],
            });
        },

        async sendTestEvents(): Promise<void> {
            this.isLoading = true;
            const apiPayload = {
                events: this.eventsPayload,
                sinks: mapValues(
                    groupBy(this.sendToExactSinkValues, val => val.entity_type),
                    ownerType => ownerType.map(owner => owner.id),
                ),
                dry_run: this.dryRun,
            };
            const response = await sendTestEvent(apiPayload);

            this.isLoading = false;
            const [successfullySent, failed] = partition(response.data.response, ev => ev.status === 200);

            if (successfullySent.length) {
                this.$showSuccessAlert({
                    message: this.$t('events.alerts.eventsSuccessfullySent', { number: successfullySent.length }),
                    keepSuccessAlertVisible: true,
                });
                if (!failed.length) {
                    this.$emit('close');
                }
            }

            if (failed.length) {
                this.$alert(this.$t('events.alerts.failedToSendEvents', { number: failed.length }));

                const failedIndexes = response.data.response.reduce((arr: any, ev: any, idx: number) => {
                    if (ev.status !== 200) arr.push(idx);
                    return arr;
                }, []);

                this.eventsPayload = this.eventsPayload.filter((_, idx) => failedIndexes.includes(idx));
            }
        },
    },
});
