import ENTITY_TYPES from '@/common/entities/entityTypes';

import Campaign, { CampaignStatus, DeliveryType } from '@/__new__/services/dno/campaigns/models/Campaign';
import { storeCampaign } from '@/__new__/services/dno/campaigns/http/campaigns';

import Segment from '@/__new__/services/dno/segments/models/Segment';
import { createSegment } from '@/__new__/services/dno/segments/http/segments';

import { SinkConfig, type SinkSpecificConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { configColumnsToJson, SinkConfigCol } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';

import {
    upsertReportConfig,
    createRedshiftConfig,
    createKafkaConfig,
    upsertApiInvokerConfig,
} from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';

import { createEvent } from '@/__new__/services/dno/events/http/events';
import { invertGenerationTypeLabelMap } from '@/__new__/features/segments/common/segmentHelper';
import Message from '@/__new__/services/dno/campaigns/models/Message';
import { getMandatoryProps, modifyPropertiesBeforeSend } from '@/__new__/services/dno/events/models/EventProp';
import store, { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { CAMPAIGN_PRIORITIES_LABELS_INVERT } from '@/common/cepHelper';

type SinkConfigJSON = {
    name: string;
    id: string;
    filters?: any[];
    specificConfig?: SinkSpecificConfig;
    version?: number;
    state?: number;
    fieldsConfiguration?: SinkConfigCol[];
    updateTime?: string;
    allowFieldsConfigurationEdit?: boolean;
    allowedActionsExternal?: number[];
    api_control?: any;
    entry_type?: any;
    input_methods?: any;
    properties?: any;
    cohortsData?: Record<string, any>;
};

function jsonToSinkConfig(json: SinkConfigJSON, showSumLatest: boolean) {
    return {
        ...new SinkConfig({
            name: json.name,
            filters: json.filters,
            specificConfig: json.specificConfig,
            fieldsConfiguration: configColumnsToJson(json.fieldsConfiguration, showSumLatest),
            cohortsData: json.cohortsData,
        }).toJson(),
        version: 0, // enforce a new revision to be created
    };
}

export const entityCreators = {
    [ENTITY_TYPES.CAMPAIGN]: (json: Campaign) => {
        const newCampaign = new Campaign(
            undefined,
            json.name,
            json.creationDate,
            json.startTime,
            json.endTime,
            DeliveryType.fromJson(json.deliveryType),
            json.target,
            json.messages.map((msgs: any[]) => msgs.map(Message.fromJson)),
            json.status as CampaignStatus,
            json.segmentIds,
            json.campaignType,
            json.triggerCondition,
            json.lastRunTimestamp,
            json.updateTimestamp,
            json.version,
            Number(CAMPAIGN_PRIORITIES_LABELS_INVERT[json.selectedPriority as string]),
        );
        return storeCampaign(newCampaign);
    },
    [ENTITY_TYPES.SEGMENT]: (json: any) => {
        const newSegment = new Segment(
            json.id,
            json.name,
            json.updateTime,
            json.filtersJson,
            json.description,
            json.segmentIdType,
            invertGenerationTypeLabelMap(json.generationType),
            json.addCondition,
            json.removeCondition,
            json.createdBy,
            json.entryValues || [],
            json.inputMethods,
            json.ranges,
            json.version,
            json.entriesDifferenceData,
        );

        return createSegment(newSegment);
    },

    [ENTITY_TYPES.API_INVOKER_SINK_CONFIG]: (json: SinkConfigJSON) =>
        upsertApiInvokerConfig(jsonToSinkConfig(json, false)).catch(err => {
            throw new Error(err.response.data.message);
        }),

    [ENTITY_TYPES.KAFKA_SINK_CONFIG]: (json: SinkConfigJSON) => createKafkaConfig(jsonToSinkConfig(json, false)),

    [ENTITY_TYPES.REDSHIFT_CONFIG]: (json: SinkConfigJSON) => createRedshiftConfig(jsonToSinkConfig(json, true)),

    [ENTITY_TYPES.ORD_CONFIG]: (json: SinkConfigJSON) =>
        upsertReportConfig(jsonToSinkConfig(json, false)).catch(err => {
            throw new Error(err.response.data.message);
        }),
    // TODO add Event type to json argument
    [ENTITY_TYPES.EVENT]: (json: any) => {
        const dataSources = store.getters[`${Modules.events}/${Getters.GET_EVENT_DATA_SOURCES}`];

        return createEvent({
            data: {
                json_schema: {
                    type: 'object',
                    description: json.description,
                    properties: modifyPropertiesBeforeSend(json.properties, dataSources),
                    title: json.eventType,
                    humanReadableName: json.name,
                    required: getMandatoryProps(json.properties),
                },
                labels: json.labels,
                auth: json.apiControl.auth,
            },
        });
    },
};
