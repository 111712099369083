




























































































































































































import Vue from 'vue';

// components
import AppField from '@/components/partials/AppField.vue';
import AppDropdown from '@/components/partials/AppDropdown.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// helpers
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Order from '@/__new__/services/dno/orders/models/Order';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import { loadVersionHistory } from '@/common/entities/entityHelper';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
// Vuex
import { Modules } from '@/store/store';
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

import { formatDataAmount } from '@/common/formatting';

import localeLibrary from '@/common/locale/localeLibrary';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';

export default Vue.extend({
    name: 'BroadbandNutritionLabelTile',
    components: {
        AppField,
        AppDropdown,
        AppMultiselectV3,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number as USER_MANAGER_HIERARCHY,
        },
    },
    data() {
        return {
            isOpened: false,
            latency: '5ms',
            dataAmount: 'Unlimited',
            // proxy
            ICON_TYPES,
            ENTITY_TYPES,
            TOOLTIP_POSITION,
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            ORDER_ENTITY_STATES,
            historicalLabels: [],
            selectedLabel: null,
        };
    },
    computed: {
        ...mapGetters(Modules.orders, {
            orderMapFromVuex: Getters.GET_ORDERS,
        }),
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]),
        umEmtityId() {
            return this.$route.params.id || '';
        },
        mappedOrders() {
            const mappedOrders = Object.keys(this.orderMapFromVuex).map(
                orderId => new Order(this.orderMapFromVuex[orderId]),
            );
            if (mappedOrders && mappedOrders.length > 0) {
                mappedOrders.sort((a: Order, b: Order) => b.createTimestamp - a.createTimestamp);
            }
            return mappedOrders;
        },
        orderRelatedOffers() {
            const orderRelatedOffers = [];
            if (this.mappedOrders && this.mappedOrders.length > 0) {
                for (const order of this.mappedOrders) {
                    if (order?.entities?.offer) {
                        const offerId =
                            Object.values(order?.entities?.offer).find(
                                (offer: ProductCatalogOfferModel) => offer.contains_base_plan,
                            )?.entity_id || null;
                        if (offerId) {
                            orderRelatedOffers.push(
                                this[Getters.PC_GET_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.OFFER, offerId),
                            );
                        }
                    }
                }

                return orderRelatedOffers;
            }
            return null;
        },
        planName() {
            return this.selectedLabel ? this.selectedLabel.data?.fiber_plan_name : this.$i18n.t('generic.N/A');
        },
        speed() {
            return this.selectedLabel ? this.selectedLabel.data?.fiber_plan_speed : this.$i18n.t('generic.N/A');
        },
        uniquePlanID() {
            return this.selectedLabel ? this.selectedLabel.data?.unique_plan_identifier : this.$i18n.t('generic.N/A');
        },
    },
    async mounted() {
        await this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.orders, [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        ...mapActions(Modules.productcatalog, [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const promises = [
                        this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.umEmtityId),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({
                            entityType: ENTITY_TYPES.OFFER,
                            includeDrafts: false,
                        }),
                    ];
                    await Promise.all(promises);
                    await this.buildVersionHistory();
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            ALERT_TYPES.error,
                        );
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async buildVersionHistory() {
            const historicalLabels = [];
            let activeOrderAdded = false;
            let previousOfferTimestamp = Date.now();
            if (this.orderRelatedOffers && this.mappedOrders) {
                for (const mappedOrder of this.mappedOrders) {
                    if (mappedOrder?.entities?.offer) {
                        const offerId =
                            Object.values(mappedOrder?.entities?.offer).find(
                                (offer: ProductCatalogOfferModel) => offer.contains_base_plan,
                            )?.entity_id || null;
                        // Because collected version histories are required for each order to be iterated on
                        // in serial, this es lint rule is ignored here
                        // eslint-disable-next-line no-await-in-loop
                        const versionHistory = offerId ? await loadVersionHistory('offer', offerId, 'data', false) : [];
                        let previousVersion = null;
                        let currentLabelVersion = null;

                        for (const version of versionHistory) {
                            if (!activeOrderAdded) {
                                previousVersion = version;
                                currentLabelVersion = version;

                                currentLabelVersion.priceFormatted = this.getFormattedPrice(version);
                                currentLabelVersion.version = `${mappedOrder.orderId}-${currentLabelVersion.version}`;
                                currentLabelVersion.label = `${this.$i18n.t(
                                    'customerCareSuite.broadbandFactsTile.orderOrPlanChange',
                                )} - ${localeLibrary.getFormattedDateAndTime(mappedOrder.createTimestamp)}`;

                                historicalLabels.push(currentLabelVersion);
                                activeOrderAdded = true;
                            }
                            if (activeOrderAdded && !previousVersion) {
                                const versionsBeforeOrderDate = versionHistory.filter(innerVersion => {
                                    return innerVersion.update_time < mappedOrder.createTimestamp;
                                });
                                versionsBeforeOrderDate.sort((a: Order, b: Order) => b.update_time - a.update_time);
                                const [versionAsOfOrderDate] = versionsBeforeOrderDate;
                                previousVersion = versionAsOfOrderDate;
                                currentLabelVersion = versionAsOfOrderDate;

                                currentLabelVersion.priceFormatted = this.getFormattedPrice(versionAsOfOrderDate);
                                currentLabelVersion.version = `${mappedOrder.orderId}-${currentLabelVersion.version}`;
                                currentLabelVersion.label = `${this.$i18n.t(
                                    'customerCareSuite.broadbandFactsTile.orderOrPlanChange',
                                )} - ${localeLibrary.getFormattedDateAndTime(mappedOrder.createTimestamp)}`;

                                historicalLabels.push(currentLabelVersion);
                            }
                            if (
                                version.update_time > mappedOrder.createTimestamp &&
                                version.update_time < previousOfferTimestamp
                            ) {
                                if (
                                    previousVersion?.data?.fiber_plan_name !== version?.data?.fiber_plan_name ||
                                    previousVersion?.data?.fiber_plan_speed !== version?.data?.fiber_plan_speed ||
                                    previousVersion?.data?.unique_plan_identifier !==
                                        version?.data?.unique_plan_identifier ||
                                    previousVersion?.data?.amounts?.primary !== version?.data?.amounts?.primary
                                ) {
                                    currentLabelVersion = version;
                                    currentLabelVersion.priceFormatted = this.getFormattedPrice(version);
                                    currentLabelVersion.version = `${offerId}-${currentLabelVersion.version}`;
                                    currentLabelVersion.label = `${this.$i18n.t(
                                        'customerCareSuite.broadbandFactsTile.offerUpdate',
                                    )} - ${localeLibrary.getFormattedDateAndTime(currentLabelVersion.update_time)}`;

                                    historicalLabels.push(currentLabelVersion);
                                }
                                previousVersion = version;
                            }
                        }
                    }
                    previousOfferTimestamp = mappedOrder.createTimestamp;
                }
            }
            this.historicalLabels = historicalLabels;
            [this.selectedLabel] = this.historicalLabels;
        },
        getFormattedPrice(version) {
            const currencyType = version?.data?.amounts?.currency_type || null;
            const price = Number(version?.data?.amounts?.primary);
            return currencyType === currencyTypes.DATA
                ? formatDataAmount(Number(price))
                : localeLibrary.getFormattedAmount(price);
        },
    },
});
