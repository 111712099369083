import { ALERT_TYPES } from '@/common/alerts/Alert';

const AppAlerts = {
    install(Vue) {
        Vue.prototype.$ALERT_TYPES = ALERT_TYPES;

        Vue.prototype.$alert = (message, options) => {
            const { type, buttons, description } = options || {};
            let buttonMap;
            if (buttons) {
                buttonMap = Object.fromEntries(buttons.map(b => [b.id, b]));
                Vue.prototype.$eventBus.$once('buttonClicked', id => {
                    const handler = buttonMap[id]?.handler;
                    if (handler) {
                        handler();
                    }
                });
            }

            Vue.prototype.$eventBus.$emit('showAlert', {
                message,
                type,
                buttons: buttonMap ? Object.values(buttonMap) : undefined,
                description,
            });
        };

        Vue.prototype.$showSuccessAlert = options => {
            Vue.prototype.$eventBus.$emit('showAlert', {
                ...options,
                type: ALERT_TYPES.success,
            });
        };

        Vue.prototype.$showInfoAlert = options => {
            Vue.prototype.$eventBus.$emit('showAlert', {
                ...options,
                type: ALERT_TYPES.info,
            });
        };

        Vue.prototype.$showWarningAlert = options => {
            Vue.prototype.$eventBus.$emit('showAlert', {
                ...options,
                type: ALERT_TYPES.warning,
            });
        };

        Vue.prototype.$showErrorAlert = options => {
            Vue.prototype.$eventBus.$emit('showAlert', {
                ...options,
                type: ALERT_TYPES.error,
            });
        };
    },
};

export default AppAlerts;
