<template>
    <div v-if="isSubscriberIdUrlParamPresent">
        <AppDialog
            v-model="modalVisible"
            :maxWidth="modalMaxWidth"
            maxHeight="100%"
            @close="() => (sensitiveEditing = false)"
        >
            <template>
                <SensitiveEditModal
                    v-if="!sensitiveEditing"
                    @close="closeModal"
                    @proceed="() => (sensitiveEditing = true)"
                />
                <template v-else>
                    <TopupForm
                        v-if="editingContent === MODAL_CONTENT.TOP_UP"
                        :selectedWalletType="selectedWalletType"
                        :userManagerHierarchy="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                        :currentWalletBalanceAmount="walletInfo.amount"
                        @refreshData="onRefreshButtonClicked"
                        @close="closeModal"
                    />
                    <DeductBalanceForm
                        v-else-if="editingContent === MODAL_CONTENT.DEDUCT"
                        :selectedWalletType="selectedWalletType"
                        :userManagerHierarchy="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                        :currentWalletBalanceAmount="walletInfo.amount"
                        @refreshData="onRefreshButtonClicked"
                        @close="closeModal"
                    />
                    <SubscriberCancelTerminateForm
                        v-else-if="editingContent === MODAL_CONTENT.CANCEL_TERMINATE"
                        :action="suspendedServices.action"
                        :accountId="subscriber.accountId"
                        :subscriberId="subscriber.id"
                        @close="closeModal"
                    />
                    <SubscriberSuspendServicesForm
                        v-else-if="editingContent === MODAL_CONTENT.SUSPEND"
                        :action="suspendedServices.action"
                        :accountId="subscriber.accountId"
                        :subscriberId="subscriber.id"
                        @close="closeModal"
                    />
                </template>
            </template>
        </AppDialog>

        <div class="container-fluid wrapper-container background-color">
            <AppHeader :pageTitle="$i18n.t('customerCare.subscriberDetails')">
                <template
                    v-if="isEditEnabled"
                    slot="headerButtons"
                >
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :iconType="ICON_TYPES.EDIT"
                        :label="$i18n.t('customerCare.subscriberTab.editSubscriber')"
                        :disabled="!isEditAllowed"
                        @click="editSubscriber"
                    />
                </template>
                <template
                    v-if="isNewCustomerCareEnabled"
                    slot="headerButtons"
                >
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :iconType="ICON_TYPES.DATA"
                        :label="$i18n.t('customerCareSuite.tryNewCustomerCare')"
                        class="mx-2"
                        @click="newCustomerCare"
                    />
                </template>
            </AppHeader>

            <UserAuthenticationBanner
                v-if="!isEditAllowed"
                :userId="subscriber.userId"
            />

            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="section-layout"
            />

            <AppCustomerHeader :infoData="infoData" />

            <div class="section-layout section-balances">
                <BalanceInfoCard
                    v-if="subscriberWalletTileEnabled && checkIfWalletInfoExists"
                    :title="$i18n.t('customerCare.subscriberTab.wallet')"
                    :activeEpoch="walletInfo.activeEpoch"
                    :graceEpoch="walletInfo.graceEpoch"
                    :amount="walletInfo.amountWithCurrency"
                    :isDeductEnabled="isUserAllowed('UMSubscriberWalletDeductBalanceSubscriberLevel')"
                    :isTopUpEnabled="isUserAllowed('UMSubscriberWalletTopUpSubscriberLevel')"
                    :isEditAllowed="isEditAllowed"
                    data-test-id="balance-info-card"
                    @onDeductAmount="onDeductAmount(WALLET_TYPES.PRIMARY)"
                    @onTopUpAmount="onTopUpAmount(WALLET_TYPES.PRIMARY)"
                />
                <!-- TODO: figure out what to do with it later -->
                <div
                    v-if="isViewEnabled('UMSubscriberServiceBalanceTiles')"
                    class="balances"
                >
                    <div class="table-title mb-2">
                        {{ $i18n.t('customerCare.subscriberTab.balances') }}
                    </div>
                    <div class="balances-wrap">
                        <AppCardView
                            :value="voiceBalanceOverall.scaledAvailableAmount"
                            :count="voiceBalanceOverall.scaledInitialAmount"
                            :label="$i18n.t('generic.serviceTypes.voice')"
                            :unit="$i18n.t('generic.units.min')"
                            circleColor="#335195"
                            class="balance-card"
                        >
                            <template #headerIcon>
                                <img src="@/assets/icons/usermanager/voice.svg" />
                            </template>
                        </AppCardView>

                        <AppCardView
                            :value="dataBalanceOverall.scaledAvailableAmount"
                            :count="dataBalanceOverall.scaledInitialAmount"
                            :label="$i18n.t('generic.serviceTypes.data')"
                            :unit="$i18n.t('generic.units.gb')"
                            circleColor="#37c9aa"
                            class="balance-card"
                        >
                            <template #headerIcon>
                                <img src="@/assets/icons/usermanager/data.svg" />
                            </template>
                        </AppCardView>

                        <AppCardView
                            :value="smsBalanceOverall.availableAmount"
                            :count="smsBalanceOverall.initialAmount"
                            :label="$i18n.t('generic.serviceTypes.sms')"
                            :unit="$i18n.t('generic.units.sms')"
                            circleColor="#ffad20"
                            class="balance-card"
                        >
                            <template #headerIcon>
                                <img src="@/assets/icons/usermanager/sms.svg" />
                            </template>
                        </AppCardView>
                    </div>
                </div>
            </div>

            <SubscriberStateHistory
                v-model="stateHistoryFormatted"
                :initialScrollRight="true"
                class="section-layout"
            />

            <AssociatedRoles
                v-if="isUserLotusFlareAdmin"
                :id="$route.params.id"
                :idType="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                :userOwnerId="subscriber.userId"
                :roles="associatedRoles"
                :apiResponse="subscriberInfoApiResponse"
                class="section-layout"
            />

            <div class="section-layout">
                <div class="component-title-um mb-2">
                    {{ $i18n.t('rolesAndPermissions.rolesPage') }}
                </div>

                <TableFiltersRenderless :entities="accessList">
                    <template
                        #default="{
                            // state
                            allFilters,
                            filteredEntities,
                            // methods
                            onFilterAdded,
                            removeFilter,
                            removeAllFilters,
                        }"
                    >
                        <div>
                            <div class="d-flex align-items-center justify-content-end position-relative">
                                <FilterTable
                                    :columns="tableColumnsAccessTo"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>

                            <TableFiltersTags
                                :filterTableMixin="{ allFilters }"
                                class="mt-1 mb-3"
                                @removeFilter="removeFilter"
                                @removeAllFilters="removeAllFilters"
                            />

                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :newDesign="true"
                                :columnsData="tableColumnsAccessTo"
                                :canSelectColumns="true"
                                tableKey="customer-care-new/roles-management"
                            />
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>

            <div class="section-layout">
                <div class="component-title-um mb-2">
                    {{ $i18n.t('customerCare.subscriberTab.planAndPurchase') }}
                </div>
                <!-- TODO: Implement billing statement tab for p8, p9, p10 and improve tabs component -->
                <MultipleTablesTabs
                    v-model="activeTableId"
                    :tabs="subscriberTables"
                    :isComplexTab="true"
                    class="order-tabs mb-3"
                />

                <BalanceOverview
                    v-if="activeTableId === TABLES_TABS_IDS.BALANCE"
                    :allMappedBalances="mappedBalances"
                    :activeMappedBalances="activeMappedBalances"
                    :apiResponse="balancesApiResponse"
                />

                <OrderDetails
                    v-if="activeTableId === TABLES_TABS_IDS.ORDER"
                    :orders="orders"
                    :offers="offers"
                    :targetTypeId="subscriber.id"
                    :targetType="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                    :isEditAllowed="isEditAllowed"
                    :pageTitle="$i18n.t('customerCare.orderHistory.orderDetails')"
                    :useStickyHeader="true"
                />

                <TransactionHistory
                    v-if="activeTableId === TABLES_TABS_IDS.TRANSACTION"
                    ref="transactionHistory"
                    :lastBillingDate="lastBillingDate"
                    :subscriberId="subscriber.id"
                    :billingDay="account.billingDay"
                    :subscriberRegistrationTimestamp="getTimestampToString(subscriber.activationDate)"
                    :subscriptionType="subscriber.subscriptionType || 0"
                />
            </div>

            <div class="section-layout">
                <h2 class="component-title-um mb-3">
                    {{ $i18n.t('customerCare.userInformation.portingStatus') }}
                </h2>
                <SubscriberPortingTable
                    :subscriber="subscriber"
                    :porting="portingStatus"
                    :loading="portingFetchingData"
                    :portInStatusApiResponse="portInStatusApiResponse"
                    :portOutStatusApiResponse="portOutStatusApiResponse"
                    @portingStatusUpdate="updatePortingStatus"
                />
            </div>

            <NotesSection
                v-if="isAgentNotesEnabled"
                :idType="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                class="section-layout"
            />

            <div
                v-if="isVoucherFeaturesAvailable && subscriber.id"
                class="section-layout"
            >
                <div class="component-title-um mb-2">
                    {{ $i18n.t('customerCare.voucherHistory.voucherHistory') }}
                </div>

                <VoucherHistory
                    :targetId="subscriber.id"
                    :targetType="USER_MANAGER_HIERARCHY.SUBSCRIBER"
                />
            </div>

            <RewardsHistory
                v-if="rewardsHistorySubscriberDetailsEnabled"
                :id="$route.params.id"
                :idType="USER_MANAGER_HIERARCHY.SUBSCRIBER"
            />
        </div>
    </div>
</template>

<script>
// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';
import SubscriberStateHistory from '@/__new__/features/customerCare/subscriber/SubscriberStateHistory.vue';
import AppCardView from '@/components/partials/cards/AppCardView.vue';
import BalanceInfoCard from '@/__new__/features/customerCare/BalanceInfoCard.vue';
import SensitiveEditModal from '@/__new__/features/customerCare/SensitiveEditModal.vue';
import TopupForm from '@/__new__/features/customerCare/TopupForm.vue';
import DeductBalanceForm from '@/__new__/features/customerCare/DeductBalanceForm.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AssociatedRoles from '@/__new__/features/customerCare/AssociatedRoles.vue';
import BalanceOverview from '@/__new__/features/customerCare/BalanceOverview.vue';
import OrderDetails from '@/__new__/features/customerCare/OrderDetails.vue';
import TransactionHistory from '@/__new__/features/customerCare/TransactionHistory.vue';
import RewardsHistory from '@/__new__/features/customerCare/RewardsHistory.vue';
import VoucherHistory from '@/__new__/features/customerCare/VoucherHistory.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import NotesSection from '@/__new__/features/customerCare/NotesSection.vue';
import SubscriberPortingTable from '@/__new__/features/customerCare/subscriber/SubscriberPortingTable.vue';
import SubscriberCancelTerminateForm from '@/__new__/features/customerCare/SubscriberCancelTerminateForm.vue';
import SubscriberSuspendServicesForm from '@/__new__/features/customerCare/subscriber/SubscriberSuspendServicesForm.vue';
import UserAuthenticationBanner from '@/__new__/features/customerCare/UserAuthenticationBanner.vue';

// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';

// HELPERS
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';
import Account from '@/__new__/services/dno/user/models/Account';
import Porting from '@/__new__/services/dno/user/models/Porting';
import moment from 'moment';
import { isEmpty, cloneDeep } from 'lodash';
import { CHARGING_SPECIFICATION_OPTIONS } from '@/common/chargingSpecification';
import { WALLET_TYPES, getScaledAmountsAndFittingUnit } from '@/__new__/features/customerCare/common/ordersHelper';
import { BUCKET_TYPE, mapBuckets } from '@/__new__/features/customerCare/common/balanceHelper';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import * as Sentry from '@sentry/vue';
import { convertSecondsToMinutes } from '@/common/formatting';
import { checkObjectPath } from '@/common/utils';
import permissionsService, {
    getSubscriberDetailFields,
    isUserAllowed,
    isViewEnabled,
    getOperatorConfigValue,
} from '@/services/permissions/permissions.service';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { SUBSCRIBER_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';

import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';
import { transactionsWalletTypes } from '@/__new__/features/customerCare/subscriber/TransactionsFilterPicker.vue';
import {
    handleUserNotFoundError,
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_STATE,
    SUBSCRIBER_PORTING_ACTIONS,
    SUBSCRIBER_PORT_IN_MAP,
    SUBSCRIBER_PORT_OUT_MAP,
    TERMINATION_REASON,
    subscriberStateToText,
    subscriberStateToColor,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import Flag, { FLAG_MAP, FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import { AUTH_ENUM_TOKEN_TYPE } from '@/__new__/features/customerCare/subscriber/common/authHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import luaErrorCodes from '@/common/luaErrors';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import RouteNames from '@/router/routeNames';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

const TABLES_TABS_IDS = {
    BALANCE: 'balance',
    TRANSACTION: 'transaction',
    ORDER: 'order',
    BUCKET: 'bucket',
};

export default {
    name: 'SubscriberDetails',
    components: {
        SubscriberStateHistory,
        AppHeader,
        Breadcrumbs,
        AppButton,
        AppCustomerHeader,
        MultipleTablesTabs,
        AppCardView,
        BalanceInfoCard,
        SensitiveEditModal,
        TopupForm,
        DeductBalanceForm,
        AppDialog,
        AppTable,
        AssociatedRoles,
        BalanceOverview,
        OrderDetails,
        TransactionHistory,
        RewardsHistory,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        IconButton,
        NotesSection,
        SubscriberPortingTable,
        SubscriberCancelTerminateForm,
        SubscriberSuspendServicesForm,
        UserAuthenticationBanner,
        VoucherHistory,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            WALLET_TYPES,
            activeTableId: TABLES_TABS_IDS.BALANCE,
            selectedWalletType: WALLET_TYPES.PRIMARY,
            subscriber: new Subscriber({}),
            balances: null,
            balancesApiResponse: null,
            modalVisible: false,
            MODAL_CONTENT,
            editingContent: 0,
            sensitiveEditing: false, // proceed to updating only after alert confirmation
            offers: [],
            notificationDefaultDays: 30,
            isOrderDetailsEnabled:
                permissionsService.subscriberOrdersEnabled() && isUserAllowed('UMSubscriberOrderDetailsRead'),
            isVoucherFeaturesAvailable:
                permissionsService.subscriberVoucherEnabled() && isUserAllowed('UMSubscriberVoucherHistoryRead'),
            isUserLotusFlareAdmin: permissionsService.isUserLotusFlareAdmin(),
            lastBillingDate: {}, // moment.js entity
            account: new Account({}),
            isEditEnabled:
                permissionsService.editSubscriberDetailsPageEnabled() &&
                isUserAllowed(
                    'UMSubscriberEditSubscriberUpdateFields',
                    'UMSubscriberEditSubscriberCancelUndoCancel',
                    'UMSubscriberEditSubscriberTerminateUndoTermination',
                    'UMSubscriberEditSubscriberSuspendResume',
                    'UMSubscriberEditSubscriberToggleFlag',
                ),
            isAgentNotesEnabled:
                permissionsService.userManagementCRMEnabled() && isUserAllowed('UMSubscriberAgentNotesRead'),
            rewardsHistorySubscriberDetailsEnabled:
                permissionsService.rewardsHistorySubscriberDetailsEnabled() &&
                isUserAllowed('UMSubscriberRewardsHistoryRead'),
            subscriberWalletTileEnabled:
                permissionsService.subscriberWalletTopUpEnabled() && isViewEnabled('UMSubscriberWalletTile'),
            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),
            isNewCustomerCareEnabled: getOperatorConfigValue('customerCareOptIn', false),
            USER_MANAGER_HIERARCHY,
            ICON_TYPES,
            BUTTON_TYPES,
            portingFetchingData: true,
            portingStatus: { portIn: new Porting({}), portOut: new Porting({}) },
            pukIccidData: {},
            referralCode: '',
            TABLES_TABS_IDS,
            simType: null,
            portInStatusApiResponse: null,
            portOutStatusApiResponse: null,
        };
    },
    computed: {
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('userManagementAccount', ['getAccountInfoById']),
        ...mapGetters('userManagementSubscriber', [
            'getSubscriberInfoById',
            'getSubscriberInfoApiResponseById',
            'getStateHistoryByIdAscending',
            'getAccessListById',
            'getSuspendedServiceById',
        ]),
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        ...mapGetters('orders', {
            orders: Getters.GET_PRIMARY_ORDERS,
            redemptionHistory: Getters.GET_REDEMPTION_HISTORY,
        }),
        ...mapGetters('customerCare', [Getters.SUBSCRIBERS_SUMMARY, Getters.GET_CURRENT_SUBSCRIBER_ID]),
        ...mapGetters('charging', [Getters.GET_CHARGING_SPECIFICATIONS]),
        isSubscriberIdUrlParamPresent() {
            return !!this.$route.params.id;
        },
        subscriberInfo() {
            return this.$route.params.id ? this.getSubscriberInfoById(this.$route.params.id) : {};
        },
        subscriberInfoApiResponse() {
            return this.$route.params.id ? this.getSubscriberInfoApiResponseById(this.$route.params.id) : null;
        },
        stateHistory() {
            return this.$route.params.id ? this.getStateHistoryByIdAscending(this.$route.params.id) : [];
        },
        accessList() {
            return this.$route.params.id ? this.getAccessListById(this.$route.params.id) : [];
        },
        accountInfo() {
            return this.subscriber.accountId ? this.getAccountInfoById(this.subscriber.accountId) : {};
        },
        suspendedServices() {
            return this.$route.params.id ? this.getSuspendedServiceById(this.$route.params.id) : {};
        },
        isUserVerified() {
            return this.subscriber.userId ? this.getUserVerificationStatusById(this.subscriber.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        breadcrumbList() {
            const breadcrumbList = [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/user-management',
                },
            ];

            if (this.subscriber.userId) {
                breadcrumbList.push({
                    name: this.$i18n.t('customerCare.breadcrumbs.userProfile'),
                    link: `/customer-care/user/${this.subscriber.userId}`,
                });
            }
            if (this.subscriber.accountId) {
                breadcrumbList.push({
                    name: this.$i18n.t('customerCare.breadcrumbs.account'),
                    link: `/customer-care/account/${this.subscriber.accountId}`,
                });
            }
            breadcrumbList.push({ name: this.$i18n.t('customerCare.breadcrumbs.subscriber') });

            return breadcrumbList;
        },
        stateHistoryFormatted() {
            return this.stateHistory.map(stateData => ({
                state: stateData.state,
                date: this.$localeLibrary.getFormattedDate(stateData.change_time),
                time: this.$localeLibrary.getFormattedTime(stateData.change_time),
            }));
        },
        associatedRoles() {
            const account = new AccessRole({
                targetId: this.subscriber?.accountId,
                targetTypeId: USER_MANAGER_HIERARCHY.ACCOUNT,
            });
            const subscriber = new AccessRole({
                targetId: this.subscriber?.id,
                targetTypeId: USER_MANAGER_HIERARCHY.SUBSCRIBER,
            });

            return [account, subscriber];
        },
        tableColumnsAccessTo() {
            return [
                {
                    name: this.$i18n.t('customerCare.roleName'),
                    key: 'role',
                    field: 'role',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userId'),
                    key: 'caller_id',
                    field: 'caller_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        subscriberTables() {
            const tabs = [
                {
                    id: TABLES_TABS_IDS.BALANCE,
                    title: this.$i18n.t('customerCare.balanceOverview'),
                },
                {
                    id: TABLES_TABS_IDS.TRANSACTION,
                    title: this.$i18n.t('customerCare.transactionHistory.transactionHistory'),
                },
            ];

            if (this.isOrderDetailsEnabled) {
                tabs.push({
                    id: TABLES_TABS_IDS.ORDER,
                    title: this.$i18n.t('customerCare.orderHistory.orderDetails'),
                });
            }

            return tabs;
        },
        infoData() {
            const infoData = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.detailsData,
                    additionalLabels: this.detailsStatusIndicators,
                    initiallyExpanded: true,
                },
                {
                    title: this.$i18n.t('customerCare.userInformation.simAndNumber'),
                    value: this.simAndNumberData,
                },
            ];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.FLAGS) && !isEmpty(this.subscriber.flags)) {
                const flags = {
                    title: this.$i18n.t('customerCare.account.flags'),
                    value: Flag.mapFlagStatus(this.subscriber?.flags),
                    additionalLabels: this.flagStatusIndicators,
                };

                if (flags.value.length) {
                    infoData.push(flags);
                }
            }

            return infoData;
        },
        dataBalanceOverall() {
            return this.calculateOverallValue(CHARGING_SPECIFICATION_OPTIONS.Data.key);
        },
        voiceBalanceOverall() {
            return this.calculateOverallValue(CHARGING_SPECIFICATION_OPTIONS.Voice.key);
        },
        smsBalanceOverall() {
            return this.calculateOverallValue(CHARGING_SPECIFICATION_OPTIONS.SMS.key);
        },
        mappedBalances() {
            if (this.isBalancesDataLoaded) {
                return mapBuckets(this.balances.buckets, this[Getters.GET_CHARGING_SPECIFICATIONS]);
            }
            return {};
        },
        activeMappedBalances() {
            if (!isEmpty(this.mappedBalances)) {
                return this.filterActive(this.mappedBalances);
            }
            return {};
        },
        nonMonetaryBucketsList() {
            if (!isEmpty(this.activeMappedBalances)) {
                return Object.values(this.activeMappedBalances[BUCKET_TYPE.NON_MONETARY]).reduce(
                    (acc, value) => [...acc, ...value],
                    [],
                );
            }
            return [];
        },
        isBalancesDataLoaded() {
            return Boolean(this[Getters.GET_CHARGING_SPECIFICATIONS].length && this.balances);
        },
        walletInfo() {
            if (this.checkIfWalletInfoExists) {
                const { active_epoch: activeEpoch, grace_epoch: graceEpoch, amount } = this.balances.wallets.primary;

                return {
                    activeEpoch: activeEpoch ? this.$localeLibrary.getFormattedDate(activeEpoch) : '',
                    graceEpoch: graceEpoch ? this.$localeLibrary.getFormattedDate(graceEpoch) : '',
                    amountWithCurrency: this.$localeLibrary.getFormattedAmount(amount),
                    amount: Number(amount),
                };
            }
            return {
                activeEpoch: '',
                graceEpoch: '',
                amountWithCurrency: '',
                amount: 0,
            };
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        isSubscriberStateNumber() {
            return Number.isInteger(this.subscriber.state);
        },
        points() {
            if (this.balances && Object.prototype.hasOwnProperty.call(this.balances.wallets, 'coins')) {
                return this.balances.wallets.coins.amount;
            }
            return this.$i18n.t('generic.N/A');
        },
        spins() {
            if (this.balances && Object.prototype.hasOwnProperty.call(this.balances.wallets, 'spins')) {
                return this.balances.wallets.spins.amount;
            }
            return this.$i18n.t('generic.N/A');
        },
        checkIfWalletInfoExists() {
            return checkObjectPath(this.balances, 'wallets.primary');
        },
        isFraudFlag() {
            return (
                this.subscriber.state === SUBSCRIBER_STATE.TERMINATED &&
                (this.subscriber?.flags?.[FLAG_TYPE.IS_FRAUD]?.value ||
                    this.subscriber?.updateReason === TERMINATION_REASON.FRAUD)
            );
        },
        isSuspendFlag() {
            return this.subscriber?.flags?.[FLAG_TYPE.IS_FRAUD];
        },
        isSuspendAllFlag() {
            return this.isSuspendFlag?.originalValue === FLAG_MAP.TRUE;
        },
        modalMaxWidth() {
            return [MODAL_CONTENT.CANCEL_TERMINATE, MODAL_CONTENT.SUSPEND].includes(this.editingContent)
                ? '41.5rem'
                : '47.5rem';
        },
        isSIMTypeESIM() {
            return this.simType === SIM_TYPES_MAP.ESIM;
        },
        showSendQRButton() {
            return this.isSIMTypeESIM && isUserAllowed('UMSubscriberSendESIMActivationQR');
        },
        portInActive() {
            return (
                this.portingStatus.portIn.status &&
                this.portingStatus.portIn.status !== SUBSCRIBER_PORT_IN_MAP.PROVISIONED
            );
        },
        portOutActive() {
            return (
                this.portingStatus.portOut.status &&
                [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_INITIATED, SUBSCRIBER_PORT_OUT_MAP.MANUALLY_APPROVED].includes(
                    this.portingStatus.portOut.status,
                )
            );
        },
        detailsStatusIndicators() {
            const additionalLabels = [];

            if (this.isSubscriberStateNumber) {
                additionalLabels.push({
                    title: subscriberStateToText(this.subscriber.state),
                    color: subscriberStateToColor(this.subscriber.state),
                });
            }

            if (this.portInActive) {
                additionalLabels.push({
                    title: this.$i18n.t('customerCare.userInformation.portIn'),
                    color: LABEL_COLOR.blue,
                });
            }

            if (this.portOutActive) {
                additionalLabels.push({
                    title: this.$i18n.t('customerCare.userInformation.portOut'),
                    color: LABEL_COLOR.blue,
                });
            }

            if (this.isFraudFlag) {
                additionalLabels.push({
                    color: LABEL_COLOR.red,
                    title: this.$i18n.t('customerCare.subscriberTab.fraudulent'),
                });
            }

            return additionalLabels;
        },
        flagStatusIndicators() {
            const additionalLabels = [];
            const hasSuspendedServices = Object.values(this.subscriber.flags).some(Flag.isFlagSuspendingSomeServices);

            if (hasSuspendedServices) {
                additionalLabels.push({
                    title: this.isSuspendAllFlag
                        ? this.$i18n.t('customerCare.suspendServices.allServicesSuspended')
                        : this.$i18n.t('customerCare.suspendServices.someServicesSuspended'),
                    color: LABEL_COLOR.yellow,
                });
            }

            return additionalLabels;
        },
        detailsData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIBER_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.subscriberTab.subscriberID'),
                    value: this.subscriber.id,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ACTIVATION_DATE)) {
                data.push({
                    name: this.$i18n.t('generic.activationDate'),
                    value: this.subscriber.activationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.subscriber.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIPTION_TYPE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.subscriptionType'),
                    value: this.subscriber.subscriptionTypeString,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                data.push({
                    name: this.$i18n.t('customerCare.msisdn'),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.referralCode'),
                    value: this.referralCode ?? '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CARDINALITY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.cardinality'),
                    value: this.subscriber.cardinality,
                });
            }

            return data;
        },
        simAndNumberData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                data.push({
                    name: this.$i18n.t('customerCare.msisdn'),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.IMSI)) {
                data.push({
                    name: this.$i18n.t('customerCare.imsi'),
                    value: this.subscriber.imsi,
                });
            }

            if (!isEmpty(this.pukIccidData)) {
                data.push(
                    {
                        name: this.isSIMTypeESIM
                            ? this.$i18n.t('customerCare.esimICCID')
                            : this.$i18n.t('customerCare.psimICCID'),
                        value: this.pukIccidData.iccid || '',
                    },
                    {
                        name: this.$i18n.t('customerCare.puk'),
                        value: this.pukIccidData.puk || '',
                    },
                );
            }

            if (this.showSendQRButton) {
                data.push({
                    name: this.$i18n.t('customerCare.esimQRCode'),
                    value: this.$i18n.t('customerCare.eSIM.sendActivationQR'),
                    onClick: this.onESIMQRSend,
                });
            }

            return data;
        },
        subscriberDetailFields() {
            return getSubscriberDetailFields();
        },
    },
    async created() {
        try {
            const { id } = this.$route.params;

            if (!id) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }

            if (!isEmpty(this.subscriberInfo)) {
                this.setSubscriber();
            }

            await this.fetchSubscriberById(id);

            const promises = id
                ? [
                      this.fetchProductCatalogEntities(),
                      this.fetchAccessListById(id),
                      this.fetchReferralCode(),
                      this.fetchStateHistoryById(id),
                      this.fetchSubscriberPurchasesRelatedData(),
                      this.fetchChargingSpecs(),
                      this.getSubscriberSimInfo(id),
                  ]
                : [this.fetchProductCatalogEntities(), this.fetchChargingSpecs()];

            await Promise.all(promises);

            if (this.subscriber.accountId) {
                await this.getAccountById(this.subscriber.accountId);
                this.lastBillingDate = this.calculateLastBillingDate(this.account.billingDay);
            }

            if (this.subscriber.userId) {
                this.getUserById(this.subscriber.userId);
            }

            if (this.subscriber.msisdn) {
                await this.fetchSubscriberPortingStatus(id);
            }
        } catch (error) {
            if (handleUserNotFoundError(error)) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.subscriberNotFound'),
                });
            } else {
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        }
    },
    mounted() {
        this.$eventBus.$on('updateEntity', () => {
            this.fetchSubscriberById(this.$route.params.id);
        });

        if (!isEmpty(this.suspendedServices)) {
            this.onSuspendedServices(this.suspendedServices.type);
        }
    },
    destroyed() {
        this.$eventBus.$off('updateEntity');
    },
    methods: {
        ...mapActions('userManagementSubscriber', [
            'getSubscriberInfo',
            'getAccessList',
            'getStateHistory',
            'getSubscriberPortIn',
        ]),
        ...mapActions('userManagementUser', {
            getUser: 'getUserInfo',
        }),
        ...mapActions('userManagementAccount', ['getAccountInfo']),
        ...mapActions('orders', [Actions.LOAD_ALL_ORDERS]),
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('customerCare', [Actions.SET_CURRENT_SUBSCRIBER_ID]),
        ...mapActions('charging', [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        isViewEnabled,
        isUserAllowed,
        editSubscriber() {
            const { id } = this.$route.params;
            this.$router.push({
                name: RouteNames.CCS_SUBSCRIBER_EDIT_PAGE,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        setSubscriber() {
            this.subscriber = new Subscriber(Subscriber.remapUserFromBe(this.subscriberInfo));
            this[Actions.SET_CURRENT_SUBSCRIBER_ID](this.subscriber.id);
        },
        calculateOverallValue(serviceType) {
            let initialAmount = 0;
            let availableAmount = 0;
            if (this.nonMonetaryBucketsList && this.nonMonetaryBucketsList.length) {
                Object.values(this.nonMonetaryBucketsList)
                    .map(val => val)
                    .forEach(mappedBalanceData => {
                        if (
                            mappedBalanceData.service_types.includes(serviceType) &&
                            mappedBalanceData.should_group_balance
                        ) {
                            initialAmount += mappedBalanceData.amount_initial;
                            availableAmount += mappedBalanceData.amount_available;
                        }
                    });
            }

            if (serviceType === CHARGING_SPECIFICATION_OPTIONS.Data.key) {
                // We should display proper units for Data service type
                const scaledAmountsAndFittingUnit = getScaledAmountsAndFittingUnit(initialAmount, availableAmount);
                return {
                    initialAmount,
                    availableAmount,
                    scaledInitialAmount: scaledAmountsAndFittingUnit[0],
                    scaledAvailableAmount: scaledAmountsAndFittingUnit[1],
                    units: scaledAmountsAndFittingUnit[2],
                };
            }
            if (serviceType === CHARGING_SPECIFICATION_OPTIONS.Voice.key) {
                // we present Voice data in minutes and receiving them in seconds, so we need some formatting here
                return {
                    initialAmount,
                    availableAmount,
                    scaledInitialAmount: convertSecondsToMinutes(initialAmount),
                    scaledAvailableAmount: convertSecondsToMinutes(availableAmount),
                };
            }
            // For SMS service type we re turn values without any additional formatting
            return {
                initialAmount,
                availableAmount,
            };
        },
        async fetchProductCatalogEntities() {
            try {
                this.$Progress.start();
                const promises = [
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                ];
                if (this.isOrderDetailsEnabled) {
                    promises.push(
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                    );
                }
                await Promise.allSettled(promises);
                // fetch offers
                this.offers = this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER) || [];
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t(
                        'alertMessage.userManagement.somethingWentWrongFetchingProductCatalogRelatedData',
                    ),
                });
                Sentry.captureException(error);
            }
        },
        async fetchChargingSpecs() {
            try {
                this.$Progress.start();
                await this[Actions.REQUEST_CHARGING_SPECIFICATIONS]();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                });
                Sentry.captureException(error);
            }
        },
        onDeductAmount(walletType) {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = this.MODAL_CONTENT.DEDUCT;
        },
        onTopUpAmount(walletType) {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = this.MODAL_CONTENT.TOP_UP;
        },
        onSuspendedServices(modalType) {
            this.modalVisible = true;
            this.editingContent = modalType;
        },
        onCancelTerminate() {
            this.modalVisible = true;
            this.editingContent = this.MODAL_CONTENT.CANCEL_TERMINATE;
        },
        closeModal() {
            this.modalVisible = false;
            this.editingContent = 0;
            this.sensitiveEditing = false;
        },
        async fetchBalances(targetId) {
            try {
                this.balances = await ordersHTTP.getBalances(targetId, USER_MANAGER_HIERARCHY.SUBSCRIBER);
                this.balancesApiResponse = this.balances;
            } catch (error) {
                this.balancesApiResponse = error.response;
                throw error;
            }
        },
        async fetchReferralCode() {
            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                try {
                    this.$Progress.start();
                    const {
                        data: { referral_code: refCode },
                    } = await customerCareHTTP.getReferralCode(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );
                    this.referralCode = refCode;
                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchSubscriberPurchasesRelatedData() {
            try {
                const { id } = this.$route.params;
                const targetId = id || this.subscriber.id;
                const promises = [this.fetchBalances(targetId)];

                this.$Progress.start();

                // fetch orders
                if (this.isOrderDetailsEnabled) {
                    promises.push(this[Actions.LOAD_ALL_ORDERS](targetId));
                }

                if (this.activeTableId === TABLES_TABS_IDS.TRANSACTION) {
                    promises.push(
                        this.$refs.transactionHistory.fetchTransactionsData({
                            walletType: transactionsWalletTypes.all,
                        }),
                    );
                }

                await Promise.all(promises);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        onRefreshButtonClicked() {
            // we refresh all the data that will be rendered on the page
            Promise.all([
                this.fetchProductCatalogEntities(),
                this.fetchSubscriberPurchasesRelatedData(),
                this.fetchReferralCode(),
            ]).catch(error => {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongRefreshingUserData'),
                });
            });
        },
        filterActive(mappedBalances) {
            const mappedBalancesCopy = cloneDeep(mappedBalances);
            Object.entries(mappedBalances).forEach(([bucketType, bucketsByType]) => {
                Object.values(bucketsByType).forEach(bucketsList => {
                    const csGroupId = bucketsList[0].cs_group_id;
                    const activeBuckets = bucketsList.filter(bucket => bucket.is_active === true);
                    if (activeBuckets.length === 0) {
                        delete mappedBalancesCopy[bucketType][csGroupId];
                    } else {
                        mappedBalancesCopy[bucketType][csGroupId] = activeBuckets;
                    }
                });
            });

            return mappedBalancesCopy;
        },
        calculateLastBillingDate(billingDay) {
            let lastBillingDate;
            const currentDate = moment().startOf('day');

            if (currentDate.date() === Number(billingDay)) {
                lastBillingDate = currentDate.subtract(1, 'month');
            } else if (currentDate.date() > Number(billingDay)) {
                lastBillingDate = currentDate.date(billingDay);
            } else {
                lastBillingDate = currentDate.subtract(1, 'month').date(billingDay);
            }
            return lastBillingDate;
        },
        async fetchSubscriberById(id) {
            try {
                this.$Progress.start();

                await this.getSubscriberInfo(id);
                this.setSubscriber();

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                throw error;
            }
        },
        async getAccountById(id) {
            try {
                this.$Progress.start();

                await this.getAccountInfo(id);

                this.account = new Account(Account.remapAccountFromBe(this.accountInfo));

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async getUserById(id) {
            try {
                this.$Progress.start();

                await this.getUser(id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async fetchAccessListById(id) {
            try {
                this.$Progress.start();

                await this.getAccessList(id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async fetchStateHistoryById(id) {
            try {
                this.$Progress.start();

                await this.getStateHistory(id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        getTimestampToString(str) {
            return str ? moment(str).unix() : 0;
        },
        async fetchSubscriberPortingStatus(id) {
            if (
                [
                    SUBSCRIBER_STATE.ACTIVE,
                    SUBSCRIBER_STATE.GRACE_WITHOUT_SUBSCRIPTION,
                    SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION,
                ].includes(this.subscriber.state)
            ) {
                await Promise.all([this.getPortInStatus(id), this.getPortOutStatus(id)]);
            }
        },
        async getPortInStatus(id) {
            try {
                this.$Progress.start();
                this.portingFetchingData = true;
                const response = await userManagementHTTP.getSubscriberPortingRequest(
                    id,
                    SUBSCRIBER_PORTING_ACTIONS.PORT_IN,
                );
                this.portInStatusApiResponse = response;

                if (!isEmpty(response.data.porting_request)) {
                    this.portingStatus.portIn = new Porting(
                        Porting.remapPortingFromBe(response.data.porting_request, SUBSCRIBER_PORTING_ACTIONS.PORT_IN),
                    );
                }

                this.$Progress.finish();
            } catch (error) {
                this.portInStatusApiResponse = error.response;
                this.portingStatus.portIn.status = false;
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            } finally {
                this.portingFetchingData = false;
            }
        },
        async getPortOutStatus(id) {
            try {
                this.$Progress.start();
                this.portingFetchingData = true;
                const response = await userManagementHTTP.getSubscriberPortingRequest(
                    id,
                    SUBSCRIBER_PORTING_ACTIONS.PORT_OUT,
                );
                this.portOutStatusApiResponse = response;

                if (!isEmpty(response.data.porting_request)) {
                    this.portingStatus.portOut = new Porting(
                        Porting.remapPortingFromBe(response.data.porting_request, SUBSCRIBER_PORTING_ACTIONS.PORT_OUT),
                    );
                }

                this.$Progress.finish();
            } catch (error) {
                this.portOutStatusApiResponse = error.response;
                this.portingStatus.portOut.status = false;
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            } finally {
                this.portingFetchingData = false;
            }
        },
        async updatePortingStatus(actionId) {
            try {
                switch (actionId) {
                    case SUBSCRIBER_PORTING_ACTIONS.PORT_IN:
                        await this.getPortInStatus(this.subscriber.id);
                        break;
                    case SUBSCRIBER_PORTING_ACTIONS.PORT_OUT:
                        await this.getPortOutStatus(this.subscriber.id);
                        break;
                    default:
                        break;
                }

                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.portingSuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async getSubscriberSimInfo(id) {
            if (!this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK_ICCID) || !this.subscriber.msisdn) {
                return;
            }

            try {
                this.$Progress.start();

                const {
                    data: { profile_data: profileData },
                } = await customerCareHTTP.getProfileInfo({
                    targetId: id,
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    tokenType: AUTH_ENUM_TOKEN_TYPE.TENANT,
                });

                // eslint-disable-next-line camelcase
                this.simType = profileData?.sim_type;
                this.pukIccidData = {
                    iccid: profileData?.iccid,
                    puk: profileData?.puk,
                };
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(e);
            }
        },
        async onESIMQRSend() {
            try {
                this.$Progress.start();
                await customerCareHTTP.sendESIMActivationQR(this.$route.params.id);
                this.showSupportAlert(this.$i18n.t('customerCare.eSIM.QRSuccess'), ALERT_TYPES.success);
                this.$Progress.finish();
            } catch (err) {
                this.$Progress.fail();
                Sentry.captureException(err);
                let errorMessage = this.$i18n.t('generic.somethingWentWrong');
                const luaErrorCode = err?.response?.data?.code;
                const luaModule = err?.response?.data?.module;
                if (luaModule === 'SIM') {
                    if (luaErrorCode === luaErrorCodes.SIM.MORE_THAN_ONE_ACTIVE_ICCID.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.moreThanOneICCID');
                    } else if (luaErrorCode === luaErrorCodes.SIM.INTERNAL_ERROR.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.failedToSendEmailQR');
                    }
                } else if (luaModule === 'OSA' && luaErrorCode === luaErrorCodes.OSA.INVALID_EXTERNAL_RESPONSE.code) {
                    errorMessage = this.$i18n.t('customerCare.eSIM.failedToGenerateQR');
                }
                this.showSupportAlert(errorMessage, ALERT_TYPES.error);
            }
        },
        isDetailFieldEnabled(fieldName) {
            return this.subscriberDetailFields.includes(fieldName);
        },
        newCustomerCare() {
            this.$router.push({
                name: RouteNames.CCS_SUBSCRIBER_PAGE,
                params: { id: this.$route.params.id },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/_typography.scss';

$icon-path: '~@/assets/icons/';

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.wallet {
    width: 17.5rem;
    max-width: 100%;
    margin-right: 1.5rem;
    background: $blue;

    @include tablet {
        margin-bottom: 1rem;
    }
}

.balances {
    width: 100%;

    @include tablet {
        flex-direction: column;
    }
}

.balance-card {
    width: calc(33.3% - 1rem);
    max-width: 17.5rem;
    min-height: 9.5rem;
    margin-right: $spacing-xl;

    &:last-child {
        margin-right: 0;
    }

    @include tablet {
        width: 100%;
        margin-bottom: 1rem;
    }
}

.section-balances {
    display: flex;
    justify-content: space-between;

    @include tablet {
        display: block;
    }
}

.balances-wrap {
    display: flex;

    @include tablet {
        display: block;
    }
}

.status {
    height: auto;
}
</style>
