






























































import Vue from 'vue';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppTable from '@/components/partials/AppTable.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { type DocumentAsset } from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import tableColumnType from '@/common/filterTable';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import download from 'downloadjs';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Http
import { getAsset } from '@/__new__/services/dno/documents/http/assets';

export default Vue.extend({
    name: 'DocumentAssets',
    components: {
        AbstractListPageWrapper,
        FilterTable,
        ResponseModalButton,
        AppButton,
        TableFiltersTags,
        AppTable,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            entityType: ENTITY_TYPES.DOCUMENT_ASSETS as string,
            searchQueryForTable: '' as string,
            isDataLoading: false as boolean,
            isEditEnabled: isUserAllowed('DocumentAssetsReadWrite'),
        };
    },
    computed: {
        ...mapGetters(Modules.documents, [Getters.GET_DOCUMENT_ASSETS, Getters.GET_DOCUMENT_ASSETS_API_RESPONSE]),
        documentAssets(): DocumentAsset[] {
            if (!this[Getters.GET_DOCUMENT_ASSETS]) return [];
            const data = [...this[Getters.GET_DOCUMENT_ASSETS]];
            return data.sort((asset1: DocumentAsset, asset2: DocumentAsset) =>
                (asset1.name as string).localeCompare(asset2.name as string),
            );
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_DOCUMENT_ASSETS_API_RESPONSE];
        },
        tableColumnsData(): any[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    width: '100',
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        sortedFilteredEntities(): any[] {
            return this.filteredEntitiesMixin(this.documentAssets);
        },
    },
    async created() {
        try {
            this.$Progress.start();
            this.isDataLoading = true;

            await Promise.all([this[Actions.REQUEST_DOCUMENT_ASSETS]()]);

            this.$Progress.finish();
        } catch (error: any) {
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: error.message,
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions(Modules.documents, [Actions.REQUEST_DOCUMENT_ASSETS]),
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        newDocumentAsset(): void {
            this.$router.push({
                name: RouteNames.DOCUMENT_ASSETS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        async downloadAsset(assetId: string) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('documents.assets.downloadingAsset'),
                type: ALERT_TYPES.warning,
            });

            const response = await getAsset(assetId);
            const { signed_url: signedUrl }: any = response.data.data;

            download(signedUrl);
            this.$eventBus.$emit('closeAllAlerts');
        },
    },
});
