import { isNaN } from 'lodash';
import VueI18n from 'vue-i18n';
import i18n from '@/i18n';
import moment from 'moment';
import { ALERT_TYPES } from '@/common/alerts/Alert';

type StringKeyedTree = { [key: string]: StringKeyedTree | any };

/**
 * Generates string that is formatted by UUIDv4 standard.
 * @returns {string}
 */
export function uuidV4(): string {
    return (1e7 + '-' + 1e3 + '-' + 4e3 + '-' + 8e3 + '-' + 1e11).replace(/[018]/g, (c: string) =>
        // eslint-disable-next-line no-bitwise,no-mixed-operators
        (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
}

/**
 * Function accepts string and returns that same string with only first character capitalized.
 * @param s
 * @returns {string}
 */
export function onlyFirstLetterUppercase(s: string): string {
    if (!s) {
        throw new Error(i18n.t('alertMessage.helpers.invalidStringPassed') as string);
    }
    if (typeof s !== 'string') {
        throw new Error(
            i18n.t('alertMessage.invalidTypePassed', {
                entityType: 'string',
            }) as string,
        );
    }
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

/**
 * Converts internal entity codename to something that is displayable to user.
 * @param name
 * @returns {*}
 */
export function entityTypeDisplayName(name: string): string | undefined {
    const EntityNameInternal: { [key: string]: string } = {
        // PC
        category: 'Category',
        offer: 'Offer',
        product: 'Product',
        service: 'Service',
        resource: 'Resource',
        template: 'Template',
        preset_template: 'Preset Template',
        tag: 'Tag',
        enum: 'Enum',
        facet: 'Facet',
        // CEP
        campaign: 'Campaign',
        event: 'Event',
        segment: 'Segment',
        trigger: 'Trigger',
        operational_report_config: 'Report config',
        redshift_config: 'Redshift config',
        kafka_sink_config: 'Kafka sink config',
        api_invoker_sink_config: 'API Destinations',
        // Pipelines
        object_distributor_config: 'Object Distributor',
        // Charging
        policy_counter: 'Policy Counter',
        policy_rule: 'Policy Rule',
        charging_specification: 'Charging Specification',
        charging_specifications_group: 'Charging Specifications Group',
        rating_group: 'Rating Group',
        usage_counter: 'Usage Counter',
        condition_parameters: 'Condition Parameters',
        wallet_counters: 'Wallet Counters',
        tariff_specification: 'Tarif Specification',
        tariff_specification_group: 'Tarif Specification Group',
        // Rewards
        reward_rule: 'Reward',
        reward_rule_v4: 'Reward',
        role: 'Role',
        prize: 'Prize',
        prize_engine: 'Prize Engine',
        voucher_set: 'Voucher Set',
        promotion: 'Promotion',
        reward_payout_v4: 'Payout',
        shared_file_location: 'Shared File Location',
        lottery: 'Lottery',
        // Pricing
        pricing_rule: 'Pricing Rule',
        // Operate APIs
        application: 'Application',
        application_owner: 'Application Owner',
        api_product: 'API Product',
        api_product_order: 'API Product Order',
        // Others
        user: 'User',
        tenant: 'Tenant',
        report: 'Report',
    };
    return EntityNameInternal[name];
}

/**
 * Function receives object and return all keys with nesting.
 * When passed object has a key with array of nested objects,
 * it can fetch the nested object keys of it's parent array(1lvl deep).
 * @param obj
 * @param depth
 * @returns {array}
 */
export function getDeepKeys(obj: StringKeyedTree, depth = 1): any {
    return depth > 0
        ? Object.keys(obj)
              .filter(
                  key =>
                      obj[key] instanceof Object &&
                      (!Array.isArray(obj[key]) || (Array.isArray(obj[key]) && !Array.isArray(obj[key][0]))),
              )
              .map(key =>
                  getDeepKeys(obj[key], depth - 1).map((k: any) => {
                      if (Array.isArray(obj[key])) {
                          return Object.keys(obj[key][k]).map(nestedKey => `${key}.${nestedKey}`);
                      }
                      return `${key}.${k}`;
                  }),
              )
              .flat(depth - 1)
              .reduce((x, y) => {
                  return x.concat(y);
              }, Object.keys(obj))
        : [];
}

/**
 * Function receives str and returns a number from it
 * even if it's a decimal number
 */
export function parseNumberFromString(str: string): number | null {
    if (typeof str === 'string') {
        return Number(str.replace(/[^\d.]*/g, ''));
    }
    if (isNaN(str)) {
        // doing this so comparing numbers works with "N/A" values
        // because NaN always return the same thing comparing with numbers
        return null;
    }
    return str;
}

/**
 * Function receives str and returns a number from it
 * even if it's a decimal number
 */
export function formatNumberForDisplay(num: number | string | null | undefined): string {
    const isNumber = typeof Number(num) === 'number' && !isNaN(Number(num)) && num !== null;
    if (isNumber) {
        let isNegative;
        let numberString = String(num);
        if (numberString[0] === '-') {
            numberString = String(num).slice(1);
            isNegative = true;
        }
        const stringReverse = (str: string) => str.split('').reverse().join('');
        // after each 3 digits starting from the right
        // add a space
        // ignoring the decimal point digits
        const [digitsBeforeDecimal, digitsAfterDecimal] = numberString.split('.');
        const reversed = stringReverse(digitsBeforeDecimal);
        const chunksOfDigits = [];
        for (let i = 0; i < digitsBeforeDecimal.length; i += 3) {
            chunksOfDigits.push(reversed.substr(i, 3));
        }
        let withSpaces = stringReverse(chunksOfDigits.join(' '));
        if (isNegative) {
            withSpaces = `-${withSpaces}`;
        }
        if (digitsAfterDecimal) {
            return `${withSpaces}.${digitsAfterDecimal}`;
        }
        return withSpaces;
    }
    return i18n.t('generic.N/A') as string;
}

export const checkObjectPath = (obj: StringKeyedTree | null | undefined, path: string): boolean => {
    if (path.includes('.')) {
        if (obj && Object.hasOwnProperty.call(obj, path.split('.')[0])) {
            return checkObjectPath(obj[path.split('.')[0]], path.split('.').slice(1).join('.'));
        }
        return false;
    }
    return Boolean(obj) && Object.hasOwnProperty.call(obj, path);
};

export const isTypeString = (value: any) => typeof value === 'string' || value instanceof String;

export function getUserGMTString(): string {
    return `GMT ${moment(new Date()).format('Z')}`;
}

/**
 * Trigger a function, but only once per use case
 * @param {function} func - Function need to be debounced
 * @param {number} timeout - Debounce time
 */
export function debounceFunction(func: (...args: any[]) => void, timeout = 300): (...args: any[]) => void {
    let timer: number;
    return (...args: any[]) => {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => {
            func(args);
        }, timeout);
    };
}

export function camelCaseToText(value: string): string {
    const result = value.charAt(0).toUpperCase() + value.slice(1);
    return result.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
}

export function snakeToCamelCase(value: string): string {
    return value.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function camelToSnakeCase(value: string): string {
    return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function copyToClipboard(this: { $eventBus: Vue; $i18n: VueI18n }, data: string): void {
    try {
        navigator.clipboard.writeText(data);
        this.$eventBus.$emit('showAlert', {
            message: this.$i18n.t('alertMessage.copySuccess'),
            type: ALERT_TYPES.success,
        });
    } catch (e) {
        this.$eventBus.$emit('showAlert', {
            message: this.$i18n.t('alertMessage.copyFail'),
        });
    }
}

/**
 * Makes all child properties of `T` partial.
 * Source: https://stackoverflow.com/questions/47914536/use-partial-in-nested-property-with-typescript
 */
export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};
