












































































import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// HTTP

import {
    updateBillingAccount,
    getBillingAccountById,
} from '@/__new__/services/portal/postpaid/billingAccount/billingAccount';
import { getDunningProfiles } from '@/__new__/services/dno/billing/dunningProfilesService';
import { getCycleSpecifications as getBillingCycleConfigs } from '@/__new__/services/dno/billing/cycleSpecificationService';
// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { BILLING_ACCOUNT_INVOICE_TEMPLATES } from '@/__new__/features/customerCareSuite/common/billingAccountHelper';

type LabelAndValue = {
    label: string;
    value: string;
};

export default Vue.extend({
    name: 'BillingAccountEditDialog',

    components: {
        AppButton,
        AppDialogV2,
        AppMultiselectV3,
        AppInputV3,
    },
    props: {
        isBillingAccountEditModalVisible: {
            type: Boolean,
            default: false,
        },
        // billingAccount: {
        //     type: Object,
        //     default: null,
        // },
    },
    data() {
        return {
            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            updateBillingAccountTitle: this.$i18n.t('billingAccount.updateBillingAccount'),
            billingAccount: {},
            invoiceTemplates: BILLING_ACCOUNT_INVOICE_TEMPLATES,
            dunningProfiles: [] as Array<LabelAndValue>,
            billingCycleConfigs: [] as Array<LabelAndValue>,
            selectedTemplate: {},
            selectedDunningProfile: {} as LabelAndValue | undefined,
            selectedBillingCycle: {},
        };
    },
    async created() {
        const response = await getBillingAccountById(this.$route.params.id);
        this.billingAccount = response.data;
        this.selectedTemplate = this.invoiceTemplates.find(item => {
            return item.value === this.billingAccount.invoiceTemplateId;
        });

        const dunningProfilesData = await getDunningProfiles();
        this.dunningProfiles = dunningProfilesData.entities.map(profile => ({
            value: profile.id,
            label: profile.name,
        }));
        this.selectedDunningProfile = this.dunningProfiles.find(item => {
            return item.value === this.billingAccount.dunningProfileId;
        });

        const billingCycleConfigsData = await getBillingCycleConfigs();
        this.billingCycleConfigs = billingCycleConfigsData.entities.map(config => ({
            value: config.id,
            label: config.name,
        }));
        this.selectedBillingCycle = this.billingCycleConfigs.find(item => {
            return item.value === this.billingAccount.billingCycleId;
        });
    },
    methods: {
        onSave() {
            this.$withProgressBar(
                async () => {
                    const payload = {
                        name: this.billingAccount.name,
                        creditLimit: this.billingAccount.creditLimit,
                        taxExemption: this.billingAccount.taxExemption,
                        contact: this.billingAccount.contact,
                        invoiceTemplateId: this.selectedTemplate.value,
                        dunningProfileId: this.selectedDunningProfile.value,
                        billingCycleId: this.selectedBillingCycle.value,
                    };
                    await updateBillingAccount(this.billingAccount.id, payload);
                    this.$emit('saveSuccess');
                    this.onCloseUpdateBillingAccountModal();
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        onCloseUpdateBillingAccountModal() {
            this.$emit('closeUpdateBillingAccountModal');
        },
    },
});
