import Vue from 'vue';
import { zipObject, isEmpty } from 'lodash';
import Actions, { Getters, Mutations, State } from '@/store/mutation-types';
import {
    getAllCampaigns,
    getCampaignCarrierApps,
    getCampaignPriorities,
} from '@/__new__/services/dno/campaigns/http/campaigns';
import Campaign from '@/__new__/services/dno/campaigns/models/Campaign';

const state = {
    // Cached campaigns are stored by id. To edit campaigns use it on the top level of the state.
    // Don't mutate objects in array. You should only add or replace entire object in this array
    [State.CACHED_CAMPAIGNS]: {},
    [State.RAW_CAMPAIGNS]: [],
    [State.CAMPAIGN_CARRIER_APPS]: [],
    [State.CAMPAIGN_PRIORITIES]: {},
};

const mutations = {
    [Mutations.SET_CACHED_CAMPAIGN]: (_state, campaign) => {
        Vue.set(_state[State.CACHED_CAMPAIGNS], campaign.id, campaign);
    },

    [Mutations.SET_ALL_CACHED_CAMPAIGNS]: (_state, campaignsArray) => {
        _state[State.CACHED_CAMPAIGNS] = zipObject(
            campaignsArray.map(campaign => campaign.id),
            campaignsArray,
        );
    },

    [Mutations.SET_RAW_CAMPAIGNS]: (_state, campaigns) => {
        _state[State.RAW_CAMPAIGNS] = campaigns;
    },

    [Mutations.SET_CAMPAIGN_CARRIER_APPS]: (_state, carrierApps) => {
        _state[State.CAMPAIGN_CARRIER_APPS] = carrierApps;
    },
    [Mutations.SET_CAMPAIGN_PRIORITIES]: (_state, campaignPriorities) => {
        _state[State.CAMPAIGN_PRIORITIES] = campaignPriorities;
    },
};

const actions = {
    [Actions.FETCH_ALL_CAMPAIGNS]: ({ commit }, payload = {}) =>
        new Promise((resolve, reject) => {
            const { includeDeleted = false } = payload;

            getAllCampaigns(includeDeleted)
                .then(response => {
                    commit(Mutations.SET_RAW_CAMPAIGNS, response);
                    const campaigns = response.data.entities
                        .map(Campaign.fromJson)
                        .filter(camp => !camp.name.startsWith('internal_lf_e2e_'));

                    commit(Mutations.SET_ALL_CACHED_CAMPAIGNS, campaigns);
                    resolve(campaigns);
                })
                .catch(err => {
                    commit(Mutations.SET_RAW_CAMPAIGNS, err.response);
                    reject(err);
                });
        }),
    async [Actions.FETCH_CAMPAIGN_CARRIER_APPS]({ commit }) {
        if (state[State.CAMPAIGN_CARRIER_APPS].length) return;
        const response = await getCampaignCarrierApps();
        const carrierApps = response.data;
        commit(Mutations.SET_CAMPAIGN_CARRIER_APPS, carrierApps);
    },
    async [Actions.FETCH_CAMPAIGN_PRIORITIES]({ commit }) {
        if (!isEmpty(state[State.CAMPAIGN_PRIORITIES])) return;
        const response = await getCampaignPriorities();
        const priorities = response.data.campaign_type_delivery_type_available_priorities;
        commit(Mutations.SET_CAMPAIGN_PRIORITIES, priorities);
    },
};

const getters = {
    [Getters.GET_CACHED_CAMPAIGNS]: _state => _state[State.CACHED_CAMPAIGNS],
    [Getters.GET_CAMPAIGN_PRIORITIES]: _state => _state[State.CAMPAIGN_PRIORITIES],
    [Getters.GET_CACHED_CAMPAIGNS_ARRAY]: (_state, _getters) => Object.values(_getters[Getters.GET_CACHED_CAMPAIGNS]),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
