var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-statement-container"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.billingStatementCyclesForDisplay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var allFilters = ref.allFilters;
            var filteredEntities = ref.filteredEntities;
            var onFilterAdded = ref.onFilterAdded;
            var removeFilter = ref.removeFilter;
            var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.billingStatements'))+" ")]),_c('div',{staticClass:"display-flex"},[_c('ResponseModalButton',{attrs:{"response":_vm.billingCyclesApiResponse,"title":_vm.$i18n.t('customerCare.billingStatements')}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.billingStatementCyclesColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{staticClass:"mb-3 transaction-usage-table",attrs:{"entities":filteredEntities,"isPaginationEnabled":true,"isDefaultHoverEnabled":false,"isSearchEnabled":true,"canSelectColumns":true,"innerSearchQuery":_vm.searchQuery,"isDataLoading":_vm.isDataLoading,"newDesign":true,"columnsData":_vm.billingStatementCyclesColumnsData,"tableTabs":true,"tableKey":"user/transaction-usage"},scopedSlots:_vm._u([{key:"customRowButtons",fn:function(ref){
            var entity = ref.entity;
return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.generateInvoice'),"icon":_vm.ICON_TYPES.PLUS},on:{"iconClick":function($event){return _vm.onGenerateInvoiceButtonClicked(entity)}}}),_c('IconButton',{staticClass:"mr-5",attrs:{"label":_vm.$i18n.t('generic.download'),"icon":_vm.ICON_TYPES.DOWNLOAD},on:{"iconClick":function($event){return _vm.onDownloadButtonClicked(entity)}}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }