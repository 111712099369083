var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"test-id":"gor-payment-history"}},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.paymentList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filteredEntities = ref.filteredEntities;
return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.account.GORPaymentHistory'))+" ")]),_c('div',{staticClass:"display-flex"},[_c('ResponseModalButton',{attrs:{"response":_vm.paymentListAPIResponse,"title":_vm.$i18n.t('customerCare.account.paymentTransactionHistory')}})],1)]),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":true,"isCustomButtonPassed":true,"disableBoxShadow":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.columnsData,"tableKey":"account/transaction-history","innerSearchQuery":_vm.searchQueryHasAccessTable},scopedSlots:_vm._u([{key:"gor_order_id",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(entity.properties.gor_order_id)+" ")]}},{key:"internal_order_id",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(entity.properties.internal_order_id)+" ")]}},{key:"amount",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" ₱"+_vm._s(entity.amount)+" ")]}},{key:"transaction_status",fn:function(ref){
var entity = ref.entity;
return [_c('PaymentStatusIndicator',{attrs:{"status":entity.transaction_status}})]}},{key:"payment_method",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.getPaymentMethod(entity.payment_method))+" ")]}},{key:"create_timestamp",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.create_timestamp))+" ")]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }