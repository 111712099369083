




























































// Components
import EditorManagementLayout from '@/__new__/features/resources/EditorManagementLayout.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';
import Counts from '@/__new__/services/dno/sim/models/counts';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { SIM_TYPE, RESOURCE_TYPE, REMOTE_CONFIG_KEYS } from '@/__new__/features/resources/common/sim';

// HTTP
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    getCounts,
} from '@/__new__/services/dno/sim/http/inventory';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

type SimResourceUpload = {
    brand?: string;
    channel?: string;
    resourceType?: {
        id: (typeof RESOURCE_TYPE)[keyof typeof RESOURCE_TYPE];
        name: string;
    };
};

export default {
    name: 'SimProfileManagement',

    components: {
        EditorManagementLayout,
        AppMultiselectV3,
    },

    data() {
        return {
            utilityTypes: [
                {
                    id: SEGMENT_ID_TYPES.ICCID,
                    label: StaticFilterLabels[SEGMENT_ID_TYPES.ICCID],
                    i18n: 'customerCare.iccid',
                },
            ],
            counts: [] as any[],
            brands: [] as string[],
            channels: { [SIM_TYPE.ESIM]: [], [SIM_TYPE.PSIM]: [] } as Record<SIM_TYPE, string[]>,
            upload: {
                resourceType: undefined,
                brand: undefined,
                channel: undefined,
            } as SimResourceUpload,
            isLoadingConfig: false as boolean,
        };
    },

    computed: {
        configFileUploader() {
            return {
                getSignedURL,
                getEntityDetailsByCategory,
                getEntityDetailsByName,
                getRegisteredEntities,
                // Fallback values for brand/channel needs to be a string in uppercase('NULL').
                getSignedURLParams: (params: { uuid: string; fileName: string; blobName: string }) => ({
                    resourceType: String(this.upload.resourceType?.id),
                    esimBrandName: this.upload.brand || 'NULL',
                    uuid: params.uuid,
                    fileName: params.fileName,
                    name: params.blobName,
                    channelTag: this.upload.channel || 'NULL',
                }),
                customData: {},
            };
        },
        resourceTypes(): Array<SimResourceUpload['resourceType']> {
            return [
                { name: 'e-SIM', id: RESOURCE_TYPE[SIM_TYPE.ESIM] },
                { name: 'p-SIM', id: RESOURCE_TYPE[SIM_TYPE.PSIM] },
            ];
        },
        channelOptions(): string[] {
            return this.isEsim ? this.channels[SIM_TYPE.ESIM] : this.channels[SIM_TYPE.PSIM];
        },
        isEsim(): boolean {
            return this.upload.resourceType?.id === RESOURCE_TYPE[SIM_TYPE.ESIM];
        },
    },

    created() {
        if (isUserAllowed('ViewSIMProfileCounts')) {
            this.loadCounts();
        }
        if (isViewEnabled('EsimBrandsUpload')) {
            this.loadBrands();
        }
    },

    methods: {
        isViewEnabled,
        isUserAllowed,
        loadCounts() {
            this.$withProgressBar(
                async () => {
                    const { data } = await getCounts();
                    const counts = {
                        [SIM_TYPE.PSIM]: new Counts(data?.counts?.psim_profile),
                        [SIM_TYPE.ESIM]: new Counts(data?.counts?.esim_profile),
                    };

                    const columns: Array<keyof Counts> = ['total', 'available', 'reserved', 'used'];
                    this.counts = Object.values(SIM_TYPE).map(key =>
                        counts[key]
                            ? {
                                  title: `${this.$tc(`operator.${key}`)} ${this.$t('operator.counts')}`,
                                  fields: columns.map(column => ({
                                      label: `${this.$t(`operator.${column}`)} ${this.$tc(
                                          `operator.${key}`,
                                          counts[key][column],
                                      )}`,
                                      value: counts[key][column],
                                  })),
                              }
                            : null,
                    );
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        loadBrands() {
            this.$withProgressBar(
                async () => {
                    this.isLoadingConfig = true;
                    // eslint-disable-next-line prettier/prettier
                    const { data: { data } } = await getRemoteConfig();

                    if (data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]) {
                        this.brands = JSON.parse(data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]?.value);
                        [this.upload.brand] = this.brands;
                    }
                    if (data[REMOTE_CONFIG_KEYS.ESIM_CHANNELS]) {
                        this.channels[SIM_TYPE.ESIM] = JSON.parse(data[REMOTE_CONFIG_KEYS.ESIM_CHANNELS]?.value);
                    }
                    if (data[REMOTE_CONFIG_KEYS.PSIM_CHANNELS]) {
                        this.channels[SIM_TYPE.PSIM] = JSON.parse(data[REMOTE_CONFIG_KEYS.PSIM_CHANNELS]?.value);
                    }
                    [this.upload.channel] = this.channelOptions;
                    this.isLoadingConfig = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingConfig = false;
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        onResourceTypeSelect() {
            [this.upload.brand] = this.brands;
            [this.upload.channel] = this.channelOptions;
        },
    },
};
