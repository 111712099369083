import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import { keyBy, map, isEmpty } from 'lodash';

export class SinkTypeCasterOption {
    label: string;
    toType: CDP_PROPERTY_TYPE;
    constructor(label: string, toType: CDP_PROPERTY_TYPE) {
        this.label = label;
        this.toType = toType;
    }
    static fromJson(option: any) {
        return isEmpty(option) ? null : new SinkTypeCasterOption(option.name, option.final_field_type);
    }
}

export class SinkTypeCaster {
    fromType: CDP_PROPERTY_TYPE;
    castingOptions: SinkTypeCasterOption[];
    isRequired?: boolean;

    constructor(data: Partial<SinkTypeCaster> = {}) {
        this.fromType = data.fromType as CDP_PROPERTY_TYPE;
        this.castingOptions = data.castingOptions || [];
        this.isRequired = data.isRequired;
    }
    static fromJson(response: any): Record<CDP_PROPERTY_TYPE, SinkTypeCaster> {
        const { casters } = response.data;
        const mappedCasters = map(casters, (caster, fromType: CDP_PROPERTY_TYPE) => {
            return new SinkTypeCaster({
                fromType,
                castingOptions: caster.casting_options?.map((option: any) => SinkTypeCasterOption.fromJson(option)),
                isRequired: caster.required,
            });
        }) as unknown as SinkTypeCaster[];

        return keyBy(mappedCasters, e => e.fromType) as unknown as Record<CDP_PROPERTY_TYPE, SinkTypeCaster>;
    }
}

export default SinkTypeCaster;
