var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppDialogV2',{attrs:{"title":_vm.$i18n.t('generic.details'),"visible":_vm.value},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"modalFooter",fn:function(){return [(_vm.saveButtonEnabled)?_c('AppButton',{attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"label":_vm.$i18n.t('generic.save')},on:{"click":function($event){return _vm.$emit('updateEntity')}}}):_c('div')]},proxy:true}])},_vm._l((_vm.entitiesRemap),function(entitiesObj,index){return _c('TableFiltersRenderless',{key:index,attrs:{"entities":entitiesObj.list},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var allFilters = ref.allFilters;
var filteredEntities = ref.filteredEntities;
var onFilterAdded = ref.onFilterAdded;
var removeFilter = ref.removeFilter;
var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"display-flex justify-content-between align-items-center mb-2"},[_c('h4',[_vm._v(" "+_vm._s(entitiesObj.title)+" "),_c('span',[_vm._v("("+_vm._s(filteredEntities.length)+")")])]),_c('div',{staticClass:"ml-auto"},[_c('SearchBox',{staticClass:"table-search-box",model:{value:(_vm.searchQuery[entitiesObj.key]),callback:function ($$v) {_vm.$set(_vm.searchQuery, entitiesObj.key, $$v)},expression:"searchQuery[entitiesObj.key]"}})],1),_c('FilterTable',{staticClass:"position-relative",attrs:{"columns":_vm.tableColumnsData(entitiesObj.list)},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('div',{staticClass:"content-table"},[_c('AppTable',{attrs:{"entities":filteredEntities,"isSearchEnabled":true,"innerSearchQuery":_vm.searchQuery[entitiesObj.key],"columnsData":_vm.tableColumnsData(entitiesObj.list)},scopedSlots:_vm._u([{key:"stateId",fn:function(ref){
var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.stateId}})]}}],null,true)})],1)],1)]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }