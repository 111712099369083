








import Vue from 'vue';

// Components
import UserManagement from '@/__new__/features/customerCare/userManager/UserManagement.vue';

// Helpers
import { SEARCH_QUERY_OPTION } from '@/__new__/features/customerCare/common/userInfoHelper';
import { USER_API_KEY_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';

type SearchData = {
    searchValue: string;
    selectedSearchOption: SEARCH_QUERY_OPTION;
};

export default Vue.extend({
    name: 'BucketWizardUserSearch',
    components: {
        UserManagement,
    },
    data() {
        return {
            SEARCH_QUERY_OPTION,
        };
    },
    methods: {
        onUserSearch(res: SearchData) {
            if (res) {
                this.$router.push({
                    name: RouteNames.CHARGING_BUCKET_WIZARD,
                    params: {
                        companyId: this.$route.params.companyId,
                        id: res.searchValue,
                        userType: USER_API_KEY_MANAGER_HIERARCHY[res.selectedSearchOption],
                    },
                });
            }
        },
    },
});
