import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { type TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { displayNaIfNotProvided, getBeautifulBoolean, unixTimestampToFullDateWithTimezone } from '@/common/formatting';
import { LABEL_COLOR } from '@/common/labelsHelper';
import luaErrors from '@/common/luaErrors';

import * as Sentry from '@sentry/vue';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

export enum USER_MANAGER_HIERARCHY {
    SUBSCRIBER = 1,
    ACCOUNT = 2,
    USER = 3,
    ORGANIZATION = 5,
}

export const GROUP_UM_HIERARCHY = 6;

export enum USER_MANAGER_API_KEYS {
    SUBSCRIBER_ID = 'subscriber_id',
    ACCOUNT_ID = 'account_id',
    USER_ID = 'user_id',
    ORGANIZATION_ID = 'organization_id',
}

export const USER_MANAGER_HIERARCHY_API_KEY = {
    [USER_MANAGER_HIERARCHY.SUBSCRIBER]: USER_MANAGER_API_KEYS.SUBSCRIBER_ID,
    [USER_MANAGER_HIERARCHY.ACCOUNT]: USER_MANAGER_API_KEYS.ACCOUNT_ID,
    [USER_MANAGER_HIERARCHY.USER]: USER_MANAGER_API_KEYS.USER_ID,
    [USER_MANAGER_HIERARCHY.ORGANIZATION]: USER_MANAGER_API_KEYS.ORGANIZATION_ID,
};

export const USER_API_KEY_MANAGER_HIERARCHY = {
    [USER_MANAGER_API_KEYS.SUBSCRIBER_ID]: USER_MANAGER_HIERARCHY.SUBSCRIBER,
    [USER_MANAGER_API_KEYS.ACCOUNT_ID]: USER_MANAGER_HIERARCHY.ACCOUNT,
    [USER_MANAGER_API_KEYS.USER_ID]: USER_MANAGER_HIERARCHY.USER,
    [USER_MANAGER_API_KEYS.ORGANIZATION_ID]: USER_MANAGER_HIERARCHY.ORGANIZATION,
};

export enum CHALLENGE_TYPES {
    SMS = 0,
    EMAIL = 2,
}

export enum CHALLENGE_CONTEXT_TYPE {
    REGISTRATION = 0,
    ADD_ASSOCIATED = 1,
    VERIFY_EMAIL = 2,
    SELECT_PRIMARY = 3,
    RESET_SECRET = 4,
    SIGN_IN = 5,
    VERIFY_EMAIL_PER_SUBSCRIBER = 6,
    GENERIC = 7,
    ADD_SUBSCRIBER_TO_GROUP = 8,
    UPDATE_EMAIL = 9,
    MFA_LOGIN = 13,
    REQUEST_PII = 14,
    DELETE_PII = 15,
    EMAIL_OTP_LOGIN = 16,
    MSISDN_OTP_LOGIN = 17,
    VERIFY_EXTERNAL_IDENTIFIER = 18,
}

export const DOCUMENT_TYPE_OPTIONS = {
    PASSPORT: i18n.t('customerCare.userInformation.kycDocumentTypes.passport'),
};

export enum EKYC_STATUS {
    PENDING = 'pending',
    COMPLETED = 'completed',
    FAILED = 'failed',
    WAITING_FOR_CALLBACK = 'waiting_for_callback',
}

export type EkycStatusOption = { key: EKYC_STATUS; label: TranslateResult };

export const EKYC_STATUS_TO_I18N_LABEL = new Map([
    [EKYC_STATUS.PENDING, i18n.t('customerCare.userInformation.kycStatus.pending')],
    [EKYC_STATUS.COMPLETED, i18n.t('customerCare.userInformation.kycStatus.completed')],
    [EKYC_STATUS.FAILED, i18n.t('customerCare.userInformation.kycStatus.failed')],
    [EKYC_STATUS.WAITING_FOR_CALLBACK, i18n.t('customerCare.userInformation.kycStatus.waitingForCallback')],
]);

export const EKYC_DOCUMENT_TYPE_OPTIONS = {
    PASSPORT: i18n.t('customerCare.userInformation.kycDocumentTypes.passport'),
    DRIVING_LICENSE: i18n.t('customerCare.userInformation.kycDocumentTypes.drivingLicence'),
    ID_CARD: i18n.t('customerCare.userInformation.kycDocumentTypes.idCard'),
    VISA: i18n.t('customerCare.userInformation.kycDocumentTypes.visa'),
    UNSUPPORTED: i18n.t('customerCare.userInformation.kycDocumentTypes.unsupported'),
};

export const EKYC_DOCUMENT_SUBTYPE_OPTIONS = {
    PASSPORT: {
        E_PASSPORT: i18n.t('customerCare.userInformation.kycDocumentSubtypes.passport.ePassport'),
    },
    DRIVING_LICENSE: {
        REGULAR_DRIVING_LICENSE: i18n.t('customerCare.userInformation.kycDocumentSubtypes.drivingLicence.regular'),
        LEARNING_DRIVING_LICENSE: i18n.t('customerCare.userInformation.kycDocumentSubtypes.drivingLicence.learning'),
    },
    ID_CARD: {
        NATIONAL_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.national'),
        CONSULAR_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.consular'),
        ELECTORAL_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.electolar'),
        RESIDENT_PERMIT_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.residentPermit'),
        TAX_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.tax'),
        STUDENT_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.student'),
        PASSPORT_CARD_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.passportCard'),
        MILITARY_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.military'),
        PUBLIC_SAFETY_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.publicSafety'),
        HEALTH_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.health'),
        OTHER_ID: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.other'),
        VISA: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.visa'),
        UNKNOWN: i18n.t('customerCare.userInformation.kycDocumentSubtypes.idCard.unknown'),
    },
};

export enum SUBSCRIBER_STATE {
    NOT_AVAILABLE = -1,
    PENDING = 1,
    ACTIVE = 2,
    GRACE_WITHOUT_SUBSCRIPTION = 3,
    GRACE_WITH_SUBSCRIPTION = 4,
    TERMINATING = 5,
    PORTING_OUT = 6,
    TERMINATED = 7,
    BLANK = 8,
}

export enum SUBSCRIBER_STATE_STRING {
    PENDING = 'Pending',
    ACTIVE = 'Active',
    GRACE_WITHOUT_SUBSCRIPTION = 'GraceWithoutSubscription',
    GRACE_WITH_SUBSCRIPTION = 'GraceWithSubscription',
    TERMINATING = 'Terminating',
    PORTING_OUT = 'PortingOut',
    TERMINATED = 'Terminated',
    BLANK = 'Blank',
}

export enum ACCOUNT_STATE_STRING {
    PENDING = 'Pending',
    ACTIVE = 'Active',
    GRACE_WITHOUT_SUBSCRIPTION = 'GraceWithoutSubscription',
    GRACE_WITH_SUBSCRIPTION = 'GraceWithSubscription',
    TERMINATING = 'Terminating',
    TERMINATED = 'Terminated',
}

export function getUserManagerEntityTypeById(id: USER_MANAGER_HIERARCHY) {
    switch (id) {
        case USER_MANAGER_HIERARCHY.SUBSCRIBER:
            return i18n.t('generic.subscriber');
        case USER_MANAGER_HIERARCHY.ACCOUNT:
            return i18n.t('generic.account');
        case USER_MANAGER_HIERARCHY.USER:
            return i18n.t('generic.user');
        case USER_MANAGER_HIERARCHY.ORGANIZATION:
            return i18n.t('generic.organization');
        default:
            return '';
    }
}

export function getUMIdStateFromText(subscriberStateText: SUBSCRIBER_STATE_STRING) {
    switch (subscriberStateText) {
        case SUBSCRIBER_STATE_STRING.PENDING:
            return SUBSCRIBER_STATE.PENDING;
        case SUBSCRIBER_STATE_STRING.ACTIVE:
            return SUBSCRIBER_STATE.ACTIVE;
        case SUBSCRIBER_STATE_STRING.GRACE_WITHOUT_SUBSCRIPTION:
            return SUBSCRIBER_STATE.GRACE_WITHOUT_SUBSCRIPTION;
        case SUBSCRIBER_STATE_STRING.GRACE_WITH_SUBSCRIPTION:
            return SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION;
        case SUBSCRIBER_STATE_STRING.TERMINATING:
            return SUBSCRIBER_STATE.TERMINATING;
        case SUBSCRIBER_STATE_STRING.PORTING_OUT:
            return SUBSCRIBER_STATE.PORTING_OUT;
        case SUBSCRIBER_STATE_STRING.TERMINATED:
            return SUBSCRIBER_STATE.TERMINATED;
        case SUBSCRIBER_STATE_STRING.BLANK:
            return SUBSCRIBER_STATE.BLANK;
        default:
            return '';
    }
}

export function getSubscriberStateText(subscriberState: SUBSCRIBER_STATE) {
    switch (subscriberState) {
        case SUBSCRIBER_STATE.PENDING:
            return 'Pending';
        case SUBSCRIBER_STATE.ACTIVE:
            return 'Active';
        case SUBSCRIBER_STATE.GRACE_WITHOUT_SUBSCRIPTION:
            return 'GraceWithoutSubscription';
        case SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION:
            return 'GraceWithSubscription';
        case SUBSCRIBER_STATE.TERMINATING:
            return 'Terminating';
        case SUBSCRIBER_STATE.PORTING_OUT:
            return 'PortingOut';
        case SUBSCRIBER_STATE.TERMINATED:
            return 'Terminated';
        case SUBSCRIBER_STATE.BLANK:
            return SUBSCRIBER_STATE_STRING.BLANK;
        default:
            return '';
    }
}

export const STATE_TO_KEY_MAP = new Map([
    [SUBSCRIBER_STATE.NOT_AVAILABLE, 'generic.stateMap.noStateHistory'],
    [SUBSCRIBER_STATE.PENDING, 'generic.stateMap.pending'],
    [SUBSCRIBER_STATE.ACTIVE, 'generic.stateMap.active'],
    [SUBSCRIBER_STATE.GRACE_WITHOUT_SUBSCRIPTION, 'generic.stateMap.graceWithoutSubscription'],
    [SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION, 'generic.stateMap.graceWithSubscription'],
    [SUBSCRIBER_STATE.TERMINATING, 'generic.stateMap.terminating'],
    [SUBSCRIBER_STATE.PORTING_OUT, 'generic.stateMap.portingOut'],
    [SUBSCRIBER_STATE.TERMINATED, 'generic.stateMap.terminated'],
    [SUBSCRIBER_STATE.BLANK, 'generic.stateMap.blank'],
]);

export const STATUS_INDICATOR_SUBSCRIBER_MAP = new Map([
    [SUBSCRIBER_STATE.NOT_AVAILABLE, LABEL_COLOR.gray],
    [SUBSCRIBER_STATE.PENDING, LABEL_COLOR.yellow],
    [SUBSCRIBER_STATE.ACTIVE, LABEL_COLOR.green],
    [SUBSCRIBER_STATE.GRACE_WITHOUT_SUBSCRIPTION, LABEL_COLOR.green],
    [SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION, LABEL_COLOR.green],
    [SUBSCRIBER_STATE.TERMINATING, LABEL_COLOR.yellow],
    [SUBSCRIBER_STATE.PORTING_OUT, LABEL_COLOR.green],
    [SUBSCRIBER_STATE.TERMINATED, LABEL_COLOR.red],
    [SUBSCRIBER_STATE.BLANK, LABEL_COLOR.gray],
]);

// subscriber state to text for the new UM
// if I change getSubscriberStateText function, something might break in current UM
// getSubscriberStateText is only used in UM, so when we finish new UM
// we can replace it with this function
// it's the same, only localization added

export function subscriberStateToText(subscriberState: SUBSCRIBER_STATE) {
    const stateKey = STATE_TO_KEY_MAP.get(subscriberState);
    return stateKey && i18n.t(stateKey) ? i18n.t(stateKey) : i18n.t('generic.stateMap.undefined');
}

/**
 *
 * @param {number} status
 * @returns {string | undefined}
 */
export function subscriberStateToColor(status: SUBSCRIBER_STATE) {
    return STATUS_INDICATOR_SUBSCRIBER_MAP.get(status);
}

export function getFormattedStateHistory(stateHistory: any[]) {
    if (stateHistory.length) {
        const sortedHistory = sortBy(stateHistory, 'change_time');
        let activationTime;
        sortedHistory.forEach(el => {
            if (el.state === 'Active') {
                activationTime = el.change_time;
            }
        });
        return {
            lastStatusChangeTimestamp: sortedHistory[sortedHistory.length - 1].change_time,
            activationTimestamp: activationTime,
            stateHistory: sortedHistory.map(element => ({
                state: element.state,
                changeTime: element.change_time,
            })),
        };
    }
    return null;
}

export function parseUsageTransactionProcessType(processType: number) {
    if (processType === 1) {
        return 'Offline';
    }
    if (processType === 2) {
        return 'Online';
    }
    return 'Invalid process type';
}

export const SUBSCRIPTION_TYPE = new Map([
    [0, 'Undefined'],
    [1, 'Not Set'],
    [2, 'Prepaid'],
    [3, 'Postpaid'],
    [4, 'Hybrid'],
]);

export function formatSubscriberData(subscriber: any) {
    return {
        ...subscriber,
        id: displayNaIfNotProvided(subscriber.subscriber_id),
        status: getSubscriberStateText(subscriber.state),
        creationDate: unixTimestampToFullDateWithTimezone(subscriber.created),
        activationDate: unixTimestampToFullDateWithTimezone(subscriber.activationTimestamp),
        title: subscriber.msisdn,
        subscriptionType: SUBSCRIPTION_TYPE.get(subscriber.subscription_type),
        isPrivileged: getBeautifulBoolean(subscriber.is_privileged),
        imsi: subscriber.imsis && subscriber.imsis.length ? subscriber.imsis.join(', ') : '',
        lastStatusChangeDate: unixTimestampToFullDateWithTimezone(subscriber.lastStatusChangeTimestamp),
        fullName: displayNaIfNotProvided(
            subscriber.full_name || `${subscriber.first_name || ''} ${subscriber.last_name || ''}`,
        ),
    };
}

export enum ADDRESS_TYPE {
    UNKNOWN = 1,
    BILLING = 2,
    SHIPPING = 3,
    SERVICE = 4,
    PERMANENT = 5,
    MAILING = 6,
    e911 = 7,
    PPU = 8,
}

export const ADDRESS_TYPE_TO_LABEL_MAP = new Map([
    [ADDRESS_TYPE.UNKNOWN, i18n.t('generic.unknown')],
    [ADDRESS_TYPE.BILLING, i18n.t('customerCare.addressTypeToLabel.billing')],
    [ADDRESS_TYPE.SHIPPING, i18n.t('customerCare.addressTypeToLabel.shipping')],
    [ADDRESS_TYPE.SERVICE, i18n.t('customerCare.addressTypeToLabel.service')],
    [ADDRESS_TYPE.PERMANENT, i18n.t('customerCare.addressTypeToLabel.permanent')],
    [ADDRESS_TYPE.MAILING, i18n.t('customerCare.addressTypeToLabel.mailing')],
    [ADDRESS_TYPE.e911, i18n.t('customerCare.addressTypeToLabel.e911')],
    [ADDRESS_TYPE.PPU, i18n.t('customerCare.addressTypeToLabel.ppu')],
]);

/**
 * Utility function to convert address type to string.
 * If label is not found will return an empty string.
 * @param addressType
 * @returns
 */
export const addressTypeToString = (addressType: ADDRESS_TYPE): string => {
    const translateResult = ADDRESS_TYPE_TO_LABEL_MAP.get(addressType);
    return translateResult ? translateResult.toString() : '';
};

export function formatAddressForDisplaying(address: any) {
    return Object.entries(address)
        .filter(([key, value]) => !!value && key !== 'type' && key !== 'hierarchy' && key !== 'id')
        .map(keyValue => keyValue[1]) // value will be returned
        .join(', ');
}

type FormattedAddress = {
    id: string;
    label: string;
    value: string;
};

export function getFormattedAddressesList(addresses: any) {
    // some used constants declared
    let addressesByType = {};
    const result: FormattedAddress[] = [];

    const addressesWithId = Object.entries(addresses).map(([id, address]: [string, any]) => ({
        ...address,
        id,
    }));

    // grouping addresses by type in order to properly set prefix and index for a certain address
    addressesByType = groupBy(addressesWithId, 'type');

    // formatting all addresses in a proper manner and putting them all into the result array
    Object.values(addressesByType).forEach((addressesWithCertainType: any) => {
        if (addressesWithCertainType.length) {
            let indexForLabel = '';
            addressesWithCertainType.forEach((address: any, index: number) => {
                if (addressesWithCertainType.length > 1) {
                    // if there is more than 1 address of a certain type - we will add this index to its' label
                    indexForLabel = ` ${index + 1}`;
                }
                result.push({
                    label: `${ADDRESS_TYPE_TO_LABEL_MAP.get(address.type)} Address${indexForLabel}`,
                    value: formatAddressForDisplaying(address),
                    id: address.id,
                });
            });
        }
    });

    return result;
}

export enum ADDRESS_FIELDS {
    CITY = 'city',
    HOUSE_NUMBER = 'house_number',
    POST_CODE = 'post_code',
    POSTAL_CODE = 'postal_code',
    POST = 'post',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    PHONE_NUMBER = 'phone_number',
    UNIT_NUMBER = 'unit_number',
    ADDRESS = 'address',
    ZIP = 'zip',
    ZIP_CODE = 'zip_code',
    STATE = 'state',
    COUNTY = 'county',
    COUNTRY = 'country',
    LONGITUDE = 'longitude',
    LATITUDE = 'latitude',
    TAX_AREA_ID = 'tax_area_id',
    ADDRESS_LINE_TWO = 'address_line_2',
    STREET_NAME = 'street_name',
    STREET = 'street',
    PROVINCE = 'province',
    BARANGAY = 'barangay',
    UNIT_NO = 'unit_no',
    EMAIL = 'email',
    CITY_CODE = 'city_code',
    NUMBER = 'number',
    PROVINCE_CODE = 'province_code',
    AREA = 'area',
    VILLAGE = 'village',
    BARANGAY_CODE = 'barangay_code',
    UNIT = 'unit',
    ALTERNATE_PHONE = 'alternate_phone',
    DELIVERY_INSTRUCTIONS = 'delivery_instructions',
    LAT = 'lat',
    LNG = 'lng',
    GOOGLE_PLACE_ID = 'google_place_id',
}

export const getDnoClaimsHeader = (id: string, targetType: TARGET_TYPE) => {
    const dnoClaimsHeader: any = {};

    if (targetType === TARGET_TYPE.SUBSCRIBER) {
        dnoClaimsHeader.subscriber_id = id;
    } else if (targetType === TARGET_TYPE.ACCOUNT) {
        dnoClaimsHeader.account_id = id;
    } else if (targetType === TARGET_TYPE.USER) {
        dnoClaimsHeader.user_id = id;
    } else if (targetType === TARGET_TYPE.ORGANIZATION) {
        dnoClaimsHeader.organization_id = id;
    }

    return dnoClaimsHeader;
};

/**
 * Returns true if address ID/Type is allowed to have geo data (long/lat).
 * @param type
 * @return {boolean}
 */
export const isAddressTypeWithGeoData = (type: ADDRESS_TYPE) => type === ADDRESS_TYPE.SHIPPING;

export enum USER_FIELDS {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    BIRTHDAY = 'birthday',
    DOCUMENT_TYPE = 'document_type',
    DOCUMENT_SUBTYPE = 'document_subtype',
    DOCUMENT_NUMBER = 'document_number',
    EMAIL = 'email',
    NATIONALITY = 'nationality',
    ISSUING_COUNTRY = 'issuing_country',
    EKYC_STATUS = 'status',
    LOCAL_TIES = 'is_locally_tied',
    PHONE = 'phone',
}

export enum CONTEXT_TYPE {
    REGISTRATION = 0,
    ADD_ASSOCIATED = 1,
    VERIFY_EMAIL = 2,
    SELECT_PRIMARY = 3,
    RESET_SECRET = 4,
    SIGN_IN = 5,
    VERIFY_EMAIL_PER_SUBSCRIBER = 6,
    GENERIC = 7,
    ADD_SUBSCRIBER_TO_GROUP = 8,
    UPDATE_EMAIL = 9,
    EMAIL_OTP_LOGIN = 16,
    MSISDN_OTP_LOGIN = 17,
    END_USER_AUTHORIZATION = 19,
}

export enum CHALLENGE_TYPE {
    SMS = 0,
    EMAIL_LINK = 1,
    EMAIL_CODE = 2,
}

export enum TRANSACTION_TYPE {
    FULL_INFO = 'full_info',
    SIM_SWAP = 'sim_swap',
    CHANGE_MSISDN = 'change_msisdn',
    PORT_OUT_PIN = 'port_out_pin',
    UPDATE_SIM_SWAP_AND_PORT_OUT_FLAG = 'update_sim_swap_and_port_out_flag',
}

export const GrantOptions = {
    Account: {
        label: i18n.t('customerCare.userInformation.permissionToAccount')
            ? (i18n.t('customerCare.userInformation.permissionToAccount') as string).toUpperCase()
            : '',
        id: USER_MANAGER_HIERARCHY.ACCOUNT,
    },
    Subscriber: {
        label: i18n.t('customerCare.userInformation.permissionToSubscriber')
            ? (i18n.t('customerCare.userInformation.permissionToSubscriber') as string).toUpperCase()
            : '',
        id: USER_MANAGER_HIERARCHY.SUBSCRIBER,
    },
    Organization: {
        label: i18n.t('customerCare.userInformation.permissionToOrganization')
            ? (i18n.t('customerCare.userInformation.permissionToOrganization') as string).toUpperCase()
            : '',
        id: USER_MANAGER_HIERARCHY.ORGANIZATION,
    },
};

export function handleUserNotFoundError(error: any) {
    if (
        error?.response?.data?.module === 'AUTH' &&
        error?.response?.data?.code === luaErrors.AUTH.INVALID_TARGET_ID.code
    ) {
        return true;
    }

    Sentry.captureException(error);
    return false;
}

export function getBreadcrumbPath(type: USER_MANAGER_HIERARCHY, id: string) {
    switch (type) {
        case USER_MANAGER_HIERARCHY.SUBSCRIBER:
            return {
                link: `/customer-care/subscriber/${id}`,
            };
        case USER_MANAGER_HIERARCHY.ACCOUNT:
            return {
                link: `/customer-care/account/${id}`,
            };
        case USER_MANAGER_HIERARCHY.USER:
            return {
                link: `/customer-care/user/${id}`,
            };
        case USER_MANAGER_HIERARCHY.ORGANIZATION:
            return {
                link: `/customer-care/organization/${id}`,
            };
        default:
            return '';
    }
}

export enum SUBSCRIBER_SUSPEND_SERVICES_ACTIONS {
    BAR = 'BAR',
    UNBAR = 'UNBAR',
}

export enum SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE {
    FRAUD = 1,
    USER_REQUESTED = 2,
    DNO_BAR = 3,
    LOST = 5,
    STOLEN = 6,
}

export enum SUBSCRIBER_SUSPEND_SERVICES_SUBSCRIPTION_TYPE {
    PREPAID = 'PREPAID',
    POSTPAID = 'POSTPAID',
}

export enum SUBSCRIBER_CANCEL_REQUEST_ACTIONS {
    CANCEL = 'CANCEL',
    UNDO_CANCEL = 'UNDO_CANCEL',
}

export enum SUBSCRIBER_CANCEL_REQUEST_REASON_CODE {
    HIGH_COST = 1,
    BAD_NETWORK = 2,
}

export enum SUBSCRIBER_PORTING_ACTIONS {
    PORT_IN = 0,
    PORT_OUT = 1,
}

export enum SUBSCRIBER_PORT_IN_MAP {
    PENDING = 1,
    IN_PROGRESS_WITH_DONOR_OPERATOR = 2,
    USER_ERROR = 3,
    PROVISIONED = 4,
    APPLICATION_UNDER_REVIEW = 5,
    APPLICATION_REJECTED = 6,
    APPLICATION_APPROVED = 7,
    MANUALLY_REJECTED = 8,
    MANUALLY_CANCELLED = 9,
    MANUALLY_APPROVED = 10,
    RESOLUTION_REQUIRED = 11,
}

export const SUBSCRIBER_PORT_IN_LABEL_MAP = {
    [SUBSCRIBER_PORT_IN_MAP.PENDING]: 'pending',
    [SUBSCRIBER_PORT_IN_MAP.IN_PROGRESS_WITH_DONOR_OPERATOR]: 'inProgress',
    [SUBSCRIBER_PORT_IN_MAP.USER_ERROR]: 'error',
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_UNDER_REVIEW]: 'underReview',
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_REJECTED]: 'rejected',
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_APPROVED]: 'pendingApproval',
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_REJECTED]: 'rejected',
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_CANCELLED]: 'canceled',
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_APPROVED]: 'approved',
    [SUBSCRIBER_PORT_IN_MAP.PROVISIONED]: 'finished',
    [SUBSCRIBER_PORT_IN_MAP.RESOLUTION_REQUIRED]: 'resolutionRequired',
};

export const SUBSCRIBER_PORT_IN_COLOR_MAP = new Map([
    [SUBSCRIBER_PORT_IN_MAP.PENDING, LABEL_COLOR.yellow],
    [SUBSCRIBER_PORT_IN_MAP.IN_PROGRESS_WITH_DONOR_OPERATOR, LABEL_COLOR.yellow],
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_UNDER_REVIEW, LABEL_COLOR.yellow],
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_APPROVED, LABEL_COLOR.yellow],
    [SUBSCRIBER_PORT_IN_MAP.USER_ERROR, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_IN_MAP.APPLICATION_REJECTED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_REJECTED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_CANCELLED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_IN_MAP.MANUALLY_APPROVED, LABEL_COLOR.green],
    [SUBSCRIBER_PORT_IN_MAP.PROVISIONED, LABEL_COLOR.gray],
    [SUBSCRIBER_PORT_IN_MAP.RESOLUTION_REQUIRED, LABEL_COLOR.yellow],
]);

export enum SUBSCRIBER_PORT_OUT_MAP {
    PORT_OUT_INITIATED = 1,
    PORT_OUT_SUCCESS = 2,
    PORT_OUT_FAILED = 3,
    MANUALLY_REJECTED = 4,
    MANUALLY_CANCELED = 5,
    MANUALLY_APPROVED = 6,
}

export const SUBSCRIBER_PORT_OUT_LABEL_MAP = {
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_INITIATED]: 'initiated',
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_SUCCESS]: 'finished',
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_FAILED]: 'failed',
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_REJECTED]: 'rejected',
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_CANCELED]: 'canceled',
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_APPROVED]: 'approved',
};

export const SUBSCRIBER_PORT_OUT_COLOR_MAP = new Map([
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_INITIATED, LABEL_COLOR.yellow],
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_FAILED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_CANCELED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_REJECTED, LABEL_COLOR.red],
    [SUBSCRIBER_PORT_OUT_MAP.MANUALLY_APPROVED, LABEL_COLOR.green],
    [SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_SUCCESS, LABEL_COLOR.gray],
]);

/**
 * Cancel/Terminate services
 */
export const CANCEL_TERMINATE_ACTIONS = {
    CANCEL: 'cancel',
    CANCEL_UNDO: 'cancelUndo',
    TERMINATE: 'terminate',
};

export const CANCELATION_REASON = {
    CUSTOMER_REQUESTED: 'customerRequested',
};

// request_termination
export const TERMINATION_REASON = {
    FRAUD: 'fraud',
    USER_REQUESTED: 'user_requested',
};

export const SERVICES_REASON_TAGS_LABEL_MAP = new Map([
    [TERMINATION_REASON.FRAUD, 'customerCare.subscriberTab.isFraud'],
    [CANCELATION_REASON.CUSTOMER_REQUESTED, 'customerCare.cancelTerminate.customerRequested'],
]);

export enum USER_VERIFICATION_ID_TYPES {
    EMAIL = 'email',
    MSISDN = 'msisdn',
}

export enum TRANSACTION_STATUS {
    SUCCESS = 1,
    FAILURE = 2,
    BLOCKED = 3,
}

export const TRANSACTION_STATUS_TO_LABEL = new Map([
    [TRANSACTION_STATUS.SUCCESS, i18n.t('generic.success')],
    [TRANSACTION_STATUS.FAILURE, i18n.t('generic.failure')],
    [TRANSACTION_STATUS.BLOCKED, i18n.t('generic.blocked')],
]);

export const TRANSACTION_STATUS_TO_COLOR = new Map([
    [TRANSACTION_STATUS.SUCCESS, LABEL_COLOR.green],
    [TRANSACTION_STATUS.FAILURE, LABEL_COLOR.red],
    [TRANSACTION_STATUS.BLOCKED, LABEL_COLOR.red],
]);
