import Actions, { Getters, Mutations, State } from '../mutation-types';

import {
    deleteEvent,
    fetchDataSources,
    getAllEvents,
    fetchPropertyTypes,
    getRequiredFields,
} from '@/__new__/services/dno/events/http/events';
import { eventFromJson } from '@/__new__/services/dno/events/models/Event';
import { getAllOwnersOfEntities } from '@/http/entityTrackerHttp';
import { isEmpty } from 'lodash';
import EventDataSource from '@/__new__/services/dno/events/models/EventDataSource';

export default {
    namespaced: true,
    state: {
        [State.EVENT_REQUIRED_PROPERTIES]: {},
        [State.EVENT_PROPERTY_TYPES]: [],
        // please, don't use state for getting data, use GET_MAPPED_EVENTS getter instead
        [State.ALL_EVENTS]: [],
        [State.ALL_EVENTS_API_RESPONSE]: null,
        [State.EVENT_DATA_SOURCES]: [],
        [State.ENTITY_TRACKER_DATA]: {},
    },
    mutations: {
        [Mutations.SET_EVENT_REQUIRED_PROPERTIES]: (_state, fields) => {
            _state[State.EVENT_REQUIRED_PROPERTIES] = fields;
        },
        [Mutations.SET_EVENT_PROPERTY_TYPES]: (_state, types) => {
            _state[State.EVENT_PROPERTY_TYPES] = types;
        },
        [Mutations.SET_ALL_EVENTS]: (_state, entities) => {
            _state[State.ALL_EVENTS] = entities;
        },
        [Mutations.SET_ALL_EVENTS_API_RESPONSE]: (_state, response) => {
            _state[State.ALL_EVENTS_API_RESPONSE] = response;
        },
        [Mutations.SET_EVENT_DATA_SOURCES]: (_state, fields) => {
            _state[State.EVENT_DATA_SOURCES] = fields;
        },
        [Mutations.SET_ENTITY_TRACKER_DATA]: (_state, { type, data }) => {
            _state[State.ENTITY_TRACKER_DATA] = {
                ..._state[State.ENTITY_TRACKER_DATA],
                [type]: data,
            };
        },
    },
    actions: {
        async [Actions.LOAD_EVENT_REQUIRED_PROPERTIES]({ state, commit }) {
            if (!isEmpty(state[State.EVENT_REQUIRED_PROPERTIES])) return;

            const response = await getRequiredFields();
            const requiredProperties = response.data.required_fields;
            commit(Mutations.SET_EVENT_REQUIRED_PROPERTIES, requiredProperties);
        },

        async [Actions.LOAD_EVENT_PROPERTY_TYPES]({ state, commit }) {
            if (state[State.EVENT_PROPERTY_TYPES].length) return;
            const response = await fetchPropertyTypes();
            const propertyTypes = response.data.supported_types;
            commit(Mutations.SET_EVENT_PROPERTY_TYPES, propertyTypes);
        },

        async [Actions.LOAD_ALL_EVENTS]({ commit }) {
            let response;
            try {
                response = await getAllEvents();
                commit(Mutations.SET_ALL_EVENTS_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_ALL_EVENTS_API_RESPONSE, error.response);
                throw error;
            }
            const allEvents = response.data.event_schemas;
            commit(Mutations.SET_ALL_EVENTS, allEvents);
        },

        async [Actions.DELETE_EVENT]({ dispatch }, { id, event_type, version }) {
            await deleteEvent(id, event_type, version);
            await dispatch(Actions.LOAD_ALL_EVENTS);
        },
        async [Actions.LOAD_EVENT_DATA_SOURCES]({ state, commit }) {
            if (state[State.EVENT_DATA_SOURCES].length) return;
            const dataSources = await fetchDataSources();
            commit(
                Mutations.SET_EVENT_DATA_SOURCES,
                dataSources.data.map(ds => new EventDataSource(ds)),
            );
        },

        async [Actions.LOAD_ENTITY_TRACKER_DATA]({ state, commit }, type) {
            if (!isEmpty(state[State.ENTITY_TRACKER_DATA][type])) return;

            const response = await getAllOwnersOfEntities(type);
            commit(Mutations.SET_ENTITY_TRACKER_DATA, {
                type,
                data: response.data?.used_entities_to_owner,
            });
        },
    },
    getters: {
        [Getters.GET_EVENT_REQUIRED_PROPERTIES]: state => state[State.EVENT_REQUIRED_PROPERTIES] || {},
        [Getters.GET_EVENT_PROPERTY_TYPES]: state => state[State.EVENT_PROPERTY_TYPES] || [],
        [Getters.GET_MAPPED_EVENTS]: (state, getters) =>
            state[State.ALL_EVENTS]
                .map(event =>
                    eventFromJson(
                        event,
                        getters[Getters.GET_EVENT_DATA_SOURCES],
                        Object.keys(getters[Getters.GET_EVENT_REQUIRED_PROPERTIES]),
                    ),
                )
                .filter(event => !event.name.startsWith('internal_lf_e2e_')),
        [Getters.GET_ALL_EVENTS_API_RESPONSE]: state => state[State.ALL_EVENTS_API_RESPONSE],
        [Getters.GET_EVENT_DATA_SOURCES]: state => state[State.EVENT_DATA_SOURCES] || [],
        [Getters.GET_EVENT_BY_ID]: (state, getters) => id =>
            eventFromJson(
                state[State.ALL_EVENTS].find(event => event.id === id),
                getters[Getters.GET_EVENT_DATA_SOURCES],
                Object.keys(getters[Getters.GET_EVENT_REQUIRED_PROPERTIES]),
            ),
        [Getters.GET_ENTITY_TRACKER_DATA]: state => {
            return state[State.ENTITY_TRACKER_DATA] || null;
        },
    },
};
