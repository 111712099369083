



































































import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

export default {
    name: 'AppDropdown',
    components: {
        AppIcon,
        AppLabel,
    },
    props: {
        title: {
            required: false,
            type: String,
            default: '',
        },
        initiallyExpanded: {
            required: false,
            type: Boolean,
            default: false,
        },
        alwaysExpanded: {
            type: Boolean,
            default: false,
        },
        arrowRight: {
            type: Boolean,
            default: true,
        },
        disableContentPadding: {
            type: Boolean,
            default: false,
        },
        disableHeaderPadding: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        additionalLabels: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isCollapsed: true,
            startExpanded: false,
        };
    },
    created() {
        if (this.initiallyExpanded) {
            this.isCollapsed = !this.initiallyExpanded;
            this.startExpanded = true;
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.animateHeight();
        });
    },
    updated() {
        // Update max-height when contents change.
        this.animateHeight();
    },
    methods: {
        handleClick(): void {
            this.isCollapsed = !(this.isCollapsed || this.alwaysExpanded);
            this.startExpanded = false;
            this.animateHeight();
        },
        animateHeight(): void {
            if (this.$refs.dropdownContent instanceof Element) {
                // Double call is needed to prevent flickering.
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        if (this.$refs.dropdownContent instanceof Element) {
                            const paddingBottom =
                                getComputedStyle(this.$refs.dropdownContent).getPropertyValue('padding-bottom') ||
                                '0px';
                            this.$refs.dropdownContent.style.maxHeight = !this.isCollapsed
                                ? `calc(${this.$refs.dropdownContent.scrollHeight}px + ${paddingBottom})`
                                : null;
                        }
                    });
                });
            }
        },
    },
};
