










// Components
import AppLabel from '@/components/partials/AppLabel.vue';

// Helpers
import { SINK_CONFIG_STATUS_TO_COLOR_MAP } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';

export default {
    name: 'SinkConfigStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            default: '',
            type: [String, Number],
        },
    },
    computed: {
        colorConverter(): string | undefined {
            return SINK_CONFIG_STATUS_TO_COLOR_MAP[this.status];
        },
    },
};
