import { invert } from 'lodash';

export enum ValidationRuleType {
    SUBSCRIPTION_LIMIT = 'subscription_limit',
    PREREQUISITE = 'prerequisite',
    CONFLICT = 'conflict',
    PURCHASE_LIMIT = 'purchase_limit',
}

export enum ValidationRuleTargetType {
    GLOBAL = 'GLOBAL',
    SUBSCRIBER = 'SUBSCRIBER',
    ACCOUNT = 'ACCOUNT',
    USER = 'USER',
    ORGANIZATION = 'ORGANIZATION',
    UNREGISTERED_ID = 'UNREGISTERED_ID',
    UNREGISTERED_EMAIL = 'UNREGISTERED_EMAIL',
    UNREGISTERED_MSISDN = 'UNREGISTERED_MSISDN',
}

export const TargetTypeMap = {
    [ValidationRuleTargetType.GLOBAL]: 0,
    [ValidationRuleTargetType.SUBSCRIBER]: 1,
    [ValidationRuleTargetType.ACCOUNT]: 2,
    [ValidationRuleTargetType.USER]: 3,
    [ValidationRuleTargetType.ORGANIZATION]: 5,
    [ValidationRuleTargetType.UNREGISTERED_ID]: 100,
    [ValidationRuleTargetType.UNREGISTERED_EMAIL]: 101,
    [ValidationRuleTargetType.UNREGISTERED_MSISDN]: 102,
};

export const TargetTypeMapReverse = invert(TargetTypeMap);

export enum ValidationRuleState {
    UNAPPROVED = 1,
    APPROVED = 2,
    PAUSED = 3,
    DELETED = 4,
}

export enum PrerequisiteEval {
    ALL = 1,
    ANY = 2,
}
