



























































import Vue, { PropType } from 'vue';

// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import { validationMixin } from 'vuelidate';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { SelectorPortal } from '@/__new__/services/dno/pricing/models/pricingPortal';
import { RecursivePartial } from '@/common/utils';

export default Vue.extend({
    name: 'PricingRulesActionSelector',
    components: {
        AppInputV3,
        AppJSON,
        AppTooltip,
        IconButton,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object as PropType<SelectorPortal>,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            PLURALIZATION,
        };
    },
    methods: {
        createSelector() {
            // Adding some condition data for now. Will refine this further as the selector is developed.
            this.$emit('input', {
                variableName: '',
                variableType: 'object',
                condition: {
                    op: '',
                    type: '',
                    args: {
                        value: '',
                        key: '',
                        dataType: '',
                    },
                } as any, // allowing any since our condition is visualized in a JSON editor and undefined is not considered valid JSON.
            } as RecursivePartial<SelectorPortal>);
        },
        deleteSelector() {
            this.$emit('input', undefined);
        },
        showDeleteConfirmationAlert() {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: this.deleteSelector,
            });

            // Deletion Confirmation Alert
            this.$alert(this.$i18n.t('alerts.areYouSureDeleteSelector'), {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
    },
});
