import store from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { getEntityStateName } from '@/common/commonEntityStateMapper';
import { getProperlyFormattedMultilangFieldValue } from '@/common/entities/entityHelper';
import { CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { getAllConditionParametersIds } from '@/__new__/services/dno/charging/common/expression';

function formatPayloadForModal(data) {
    return data.map(el => ({
        name: getProperlyFormattedMultilangFieldValue(el?.data?.name || el.name).en,
        state: getEntityStateName(el.state),
    }));
}

export function isPolicyCounterUsedByPolicyRules(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_POLICY_RULES}`].filter(current => {
        const inUse = Object.values(current?.conditions?.parameters).filter(el => el.policy_counter_id === id);
        return inUse.length ? inUse : null;
    });

    return formatPayloadForModal(data);
}

export function isPolicyCounterUsedByCs(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`].filter(cs =>
        cs?.data?.policy_counter_ids?.includes(id),
    );

    return formatPayloadForModal(data);
}

export function isRatingGroupUsedByConditionParameters(rgNumber) {
    if (!rgNumber) {
        return [];
    }

    const data = store.getters[`charging/${Getters.GET_CONDITION_PARAMETERS}`].filter(
        cp =>
            cp?.conditionParameter?.type === CONDITION_TYPES.RATING_GROUP &&
            cp?.conditionParameter?.rating_group_numbers?.includes(rgNumber),
    );

    return formatPayloadForModal(data);
}

export function isUsageCounterUsedByCs(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`].filter(cs =>
        cs?.data?.usage_counters?.includes(id),
    );

    return formatPayloadForModal(data);
}

export function isUsageCounterUsedByPolicyCounters(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_POLICY_COUNTERS}`].filter(pc => pc?.usageCounterId === id);

    return formatPayloadForModal(data);
}

export function isUsageCounterUsedByWalletCounters(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_WALLET_COUNTERS}`].filter(wc => wc?.usageCounterId === id);
    return formatPayloadForModal(data);
}

export function isCsgUsedByPcServices(id, data) {
    if (!id && !data.length) {
        return [];
    }

    const filterData = data?.filter(service => service?.data?.csg_id === id);
    return formatPayloadForModal(filterData);
}

export function isConditionParameterUsedByCs(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS}`]
        .map(cs => ({
            ...cs,
            cpIds: cs.data?.condition_expr ? getAllConditionParametersIds(cs.data?.condition_expr) : [],
            cpSurchargeIds: cs.data?.surcharges?.condition_expr
                ? getAllConditionParametersIds(cs.data?.surcharges?.condition_expr)
                : [],
        }))
        .filter(cs => cs?.cpIds?.includes(id) || cs?.cpSurchargeIds?.includes(id));

    return formatPayloadForModal(data);
}

export function isConditionParameterUsedByUc(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_USAGE_COUNTERS}`]
        .map(uc => ({
            ...uc,
            ucIds: uc?.conditionExpr ? getAllConditionParametersIds(uc.conditionExpr) : [],
        }))
        .filter(uc => uc?.ucIds?.includes(id));

    return formatPayloadForModal(data);
}

export function isConditionParameterUsedByTs(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_TARIFF_SPECIFICATIONS}`]
        .map(data => ({
            ...data,
            cpIds: data?.chargingRates?.reduce((data, current) => {
                const uuids = getAllConditionParametersIds(current?.conditionExpr, true) || [];
                return [...new Set([...data, ...uuids])];
            }, []),
        }))
        .filter(data => data?.cpIds?.includes(id));

    return formatPayloadForModal(data);
}

export function isTariffSpecUsedByTsg(id) {
    if (!id) {
        return [];
    }

    const data = store.getters[`chargingV2/${Getters.GET_TARIFF_SPECIFICATION_GROUPS}`].filter(data =>
        data?.tariffSpecs?.includes(id),
    );

    return formatPayloadForModal(data);
}

export default {};
