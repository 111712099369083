






























// COMPONENTS
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import AppDropdown from '@/components/partials/AppDropdown.vue';

// HELPERS
import { stringToSnakeCase } from '@/common/formatting';

export type COLUMNS_DATA = [any[], any[], any[]];

export default {
    name: 'AppCustomerHeader',
    components: {
        AppInfoBlock,
        AppDropdown,
    },
    props: {
        /**
         * Array<Object>
         * @param title
         * @param value - details in AppInfoBlock.vue
         */
        infoData: {
            type: Array,
            required: true,
        },
        isViasatDemo: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formattedData(): COLUMNS_DATA {
            // Format data in 3 columns for display
            return this.infoData.reduce(
                (colsData: COLUMNS_DATA, item: any, i: number) => {
                    if (this.isViasatDemo) {
                        // only 1st item goes into 1st column
                        const column = !i ? 0 : ((i + 1) % 2) + 1;
                        colsData[column].push(item);
                    } else {
                        colsData[i % 3].push(item);
                    }

                    return colsData;
                },
                [[], [], []],
            );
        },
    },
    methods: {
        stringToSnakeCase,
    },
};
