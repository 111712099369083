





































































































// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// Helpers
import {
    FLAG_VALUE_OPTIONS,
    IS_NOT_ENUMS,
    NOT_OPTIONS,
    FLAG_VALUE_OPTIONS_TO_LABEL,
    operatorOptionsForDiagramUpdate,
    STATE_TYPES,
} from '@/__new__/features/orchestrationengine/common/orchestrationDiagramUpdateHelper';
import { TranslateResult } from 'vue-i18n';

type Condition = {
    isNotValue?: number;
    operatorValue?: string;
    selectedOperator?: string;
    variable?: string;
};

type OperatorOption = {
    key?: string;
    label?: string | TranslateResult;
    type?: string;
    errorMessage?: string | TranslateResult;
};

export default {
    name: 'OECondition',
    components: {
        AppMultiselectV3,
        AppInputV3,
        AppButton,
    },
    props: {
        stateWithConditions: {
            type: Object,
            required: true,
            default: () => undefined,
        },
        validationResult: {
            type: Object,
            required: true,
            default: () => undefined,
        },
        stateName: {
            type: String,
            default: '',
        },
        index: {
            type: Number,
            default: 0,
        },
        stateType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            BUTTON_TYPES: BUTTON_TYPES as any,
            ICON_TYPES: ICON_TYPES as any,
            FLAG_VALUE_OPTIONS: FLAG_VALUE_OPTIONS as any,
            IS_NOT_ENUMS: IS_NOT_ENUMS as any,
            NOT_OPTIONS: NOT_OPTIONS as any,
            STATE_TYPES: STATE_TYPES as any,
        };
    },
    computed: {
        flagValueOptions(): { id: string; label: string }[] {
            return this.convertMapHelperToOptions(FLAG_VALUE_OPTIONS_TO_LABEL);
        },
        operatorOptions(): OperatorOption[] {
            return Object.values(operatorOptionsForDiagramUpdate);
        },
        flagValueIsNotSimple(): boolean {
            return Boolean(this.stateWithConditions.flagValue !== FLAG_VALUE_OPTIONS.SIMPLE);
        },
    },
    watch: {
        stateWithConditions: {
            handler() {
                this.updateConditionStates();
            },
            deep: true,
        },
    },
    created() {
        // emit to parent component with added new state for conditions
        this.updateConditionStates();
    },
    methods: {
        convertMapHelperToOptions(mapFromHelper: any): { id: string; label: string }[] {
            return [...mapFromHelper.keys()].map(key => ({
                id: key,
                label: mapFromHelper.get(key),
            }));
        },
        checkSelectedFlag(value: string): void {
            // delete all conditions except first one if simple is selected
            const numberOfConditions = this.stateWithConditions.conditions.length;
            if (value === FLAG_VALUE_OPTIONS.SIMPLE && numberOfConditions > 1) {
                this.stateWithConditions.conditions.splice(1, numberOfConditions - 1);
            }

            // add new condition if and, or is selected and number of conditions is 1
            if (
                (value === FLAG_VALUE_OPTIONS.AND || value === FLAG_VALUE_OPTIONS.OR) &&
                this.stateWithConditions.conditions.length === 1
            ) {
                this.stateWithConditions.conditions.push({
                    variable: null,
                    isNotValue: IS_NOT_ENUMS.EMPTY,
                    selectedOperator: Object.values(operatorOptionsForDiagramUpdate)[0].key,
                    operatorValue: '',
                });
            }
        },
        updateConditionStates(): void {
            this.$emit('updatedCondition', { state: this.stateWithConditions, index: this.index });
        },
        addNewCondition(): void {
            this.stateWithConditions.conditions.push({
                variable: null,
                isNotValue: IS_NOT_ENUMS.EMPTY,
                selectedOperator: Object.values(operatorOptionsForDiagramUpdate)[0].key,
                operatorValue: '',
            });
        },
        deleteCondition(index: number): void {
            this.stateWithConditions.conditions.splice(index, 1);
            if (this.stateWithConditions.conditions.length === 1) {
                this.stateWithConditions.flagValue = FLAG_VALUE_OPTIONS.SIMPLE;
            }
        },
        errorMessageForVariable(variable: string): void {
            return variable ? this.$i18n.t('orchestrationEngine.updateDiagramModal.mustUseJSONPath') : '';
        },
        errorMessageForOperatorValue(condition: Condition): string | TranslateResult {
            return condition.operatorValue
                ? operatorOptionsForDiagramUpdate[condition.selectedOperator].errorMessage
                : '';
        },
    },
};
