import { get, keyBy } from 'lodash';
import moment from 'moment';
import i18n from '@/i18n';
import { dateToEpoch } from '@/common/formatting';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import {
    ValidationRuleType,
    ValidationRuleTargetType,
    TargetTypeMapReverse,
    ValidationRuleState,
    PrerequisiteEval,
    TargetTypeMap,
} from '@/__new__/features/pc/validationRules/common/validationRule';

import { isViewEnabled } from '@/services/permissions/permissions.service';

export function getLabel(key: string) {
    return (i18n.t(`productCatalog.validationRules.${key}`) as string) ?? 'Unknown';
}

export function validationTargetsToString(entities?: string[], map?: { id: string; name: string }[]) {
    const offers = keyBy(map, ({ id }) => id);
    return (entities || []).map(id => offers[id]?.name ?? id).join(', ');
}

export interface RawValidationRule {
    id: string;
    version?: number;
    state?: number;
    update_time?: number;
    data: {
        name: { en: string } | string;
        description?: { en: string } | string;
        start_time?: number;
        end_time?: number;
        type: ValidationRuleType;
        entity_type: ENTITY_TYPES;
        entity_ids: string[];
        conflicting_entity_ids?: string[];
        requires_entity_ids?: string[];
        target_type?: keyof typeof TargetTypeMapReverse;
        max_subscription_limit?: {
            maximum: number;
        };
        eval_operation?: PrerequisiteEval;
        max_purchases?: number;
        max_purchases_overall_per_duration?: MaxPurchaseOverallPerDuration;
        max_purchase_per_target?: MaxPurchasePerTarget;
    };
}

type MaxPurchaseOverallPerDuration = {
    max_purchase: number;
    duration_unit: number;
    number_of_duration_units: any;
};

type MaxPurchasePerTarget = {
    [k: number]: {
        max_purchases_per_duration: MaxPurchaseOverallPerDuration;
    };
};

export type ServerValidationRule = RawValidationRule | Omit<RawValidationRule, 'id'>;

export class ValidationRule {
    id: string | null = null;
    version?: number;
    state?: ValidationRuleState;
    update_time?: number;
    name = '';
    description = '';
    start_time: Date;
    end_time: Date;
    type: ValidationRuleType;
    entity_type: ENTITY_TYPES;
    target_type: keyof typeof TargetTypeMapReverse;
    entity_ids: string[];
    conflicting_entity_ids: string[] = [];
    requires_entity_ids: string[] = [];
    max_subscription_limit: {
        maximum: number;
    };
    eval_operation: PrerequisiteEval;
    max_purchases: number;
    max_purchases_overall_per_duration: MaxPurchaseOverallPerDuration;
    max_purchase_per_target: MaxPurchasePerTarget;

    constructor(json: ServerValidationRule) {
        this.id = get(json, 'id', null);
        this.version = json.version;
        this.state = json.state;
        this.update_time = json.update_time;
        this.name = get(json, 'data.name.en', '');
        this.description = get(json, 'data.description.en', '');
        this.start_time = moment.utc(json.data.start_time, 'X').toDate();
        this.end_time = moment.utc(json.data.end_time, 'X').toDate();
        this.type = json.data.type;
        this.entity_type = json.data.entity_type;
        this.target_type = json.data.target_type ?? TargetTypeMap[ValidationRuleTargetType.GLOBAL];
        this.entity_ids = json.data.entity_ids;
        this.conflicting_entity_ids = json.data.conflicting_entity_ids || [];
        this.requires_entity_ids = json.data.requires_entity_ids || [];
        this.max_subscription_limit = json.data.max_subscription_limit || { maximum: 0 };
        this.eval_operation = json.data.eval_operation || PrerequisiteEval.ANY;

        this.max_purchases = json.data.max_purchases || 0;
        this.max_purchases_overall_per_duration = json.data.max_purchases_overall_per_duration || {
            max_purchase: 0,
            duration_unit: 1,
            number_of_duration_units: 1,
        };
        this.max_purchase_per_target = json.data.max_purchase_per_target || {};
    }

    static fromJson(json: RawValidationRule): ValidationRule {
        return new ValidationRule(json);
    }

    static fromDefault(): ValidationRule {
        return new ValidationRule({
            version: 0,
            state: ValidationRuleState.APPROVED,
            update_time: 0,
            data: {
                name: '',
                description: '',
                start_time: moment().unix(),
                end_time: moment().add(1, 'month').unix(),
                type: this.getType(),
                entity_type: ENTITY_TYPES.OFFER,
                target_type: TargetTypeMap[ValidationRuleTargetType.SUBSCRIBER],
                entity_ids: [],
                conflicting_entity_ids: [],
                requires_entity_ids: [],
                max_subscription_limit: { maximum: 0 },
                eval_operation: PrerequisiteEval.ANY,
            },
        });
    }

    static getType() {
        if (isViewEnabled('ValidationRulesGOMO')) {
            return ValidationRuleType.PURCHASE_LIMIT;
        }
        return ValidationRuleType.SUBSCRIPTION_LIMIT;
    }

    toString(entitiesMap: ProductCatalogOfferModel[]): string {
        const targetType = this.target_type ? getLabel(TargetTypeMapReverse[this.target_type]).toLocaleLowerCase() : '';
        const entities = validationTargetsToString(this.entity_ids, entitiesMap) || '(None)';

        switch (this.type) {
            case ValidationRuleType.SUBSCRIPTION_LIMIT:
                const limit = this.max_subscription_limit?.maximum ?? 0;
                return `The ${targetType} can have up to ${limit} of these offer(s): [ ${entities} ]`;
            case ValidationRuleType.PREREQUISITE:
                const evaluationMethod =
                    this.eval_operation === PrerequisiteEval.ANY
                        ? (i18n.t('productCatalog.validationRules.evalMustHaveOne') as string)
                        : (i18n.t('productCatalog.validationRules.evalMustHaveAll') as string);
                const requiredEntities = validationTargetsToString(this.requires_entity_ids, entitiesMap);
                return `The ${targetType} ${evaluationMethod.toLocaleLowerCase()} of the following offer(s): [ ${requiredEntities} ] in order to have any of the following offer(s): [ ${entities} ]`;
            case ValidationRuleType.CONFLICT:
                const conflictingEntities = validationTargetsToString(this.conflicting_entity_ids, entitiesMap);
                return `The ${targetType} may not have any of the following offer(s): [ ${entities} ] if they have any of the following offer(s): [ ${conflictingEntities} ]`;
            default:
                return 'N/A';
        }
    }

    toJson(): ServerValidationRule {
        const { id, version, state, update_time, ...data } = this;
        let validData = {
            ...data,
            start_time: dateToEpoch(data.start_time),
            end_time: dateToEpoch(data.end_time),
            target_type:
                this.target_type === TargetTypeMap[ValidationRuleTargetType.GLOBAL] ? undefined : this.target_type,
        };
        switch (this.type) {
            case ValidationRuleType.SUBSCRIPTION_LIMIT:
                validData = {
                    ...validData,
                    conflicting_entity_ids: undefined,
                    requires_entity_ids: undefined,
                    eval_operation: undefined,
                };
                break;
            case ValidationRuleType.PREREQUISITE:
                validData = {
                    ...validData,
                    conflicting_entity_ids: undefined,
                    max_subscription_limit: undefined,
                };
                break;
            case ValidationRuleType.CONFLICT:
                validData = {
                    ...validData,
                    requires_entity_ids: undefined,
                    eval_operation: undefined,
                    max_subscription_limit: undefined,
                };
                break;
            default:
                break;
        }

        if (this.type === ValidationRuleType.PURCHASE_LIMIT) {
            return {
                id: id ?? '',
                version,
                data: {
                    name: validData.name,
                    description: validData.description,
                    entity_ids: validData.entity_ids,
                    type: validData.type,
                    entity_type: validData.entity_type,
                    max_purchases: validData.max_purchases,
                    max_purchases_overall_per_duration: validData.max_purchases_overall_per_duration,
                    max_purchase_per_target: validData.max_purchase_per_target,
                },
            };
        } else {
            validData = {
                ...validData,
                max_purchases: undefined,
                max_purchases_overall_per_duration: undefined,
                max_purchase_per_target: undefined,
            };
        }

        return { id: id ?? undefined, state: state ?? undefined, version, update_time, data: validData };
    }
}
