import { AxiosPromise } from 'axios';
import http from '@/http/index';
import {
    EntityDetailsByName,
    GetSignedUrlParams,
    QueryMsisdnResponse,
    RegisteredEntities,
    SEARCH_INDEX,
    SignedUrl,
    SignedUrlForDownloadResponse,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    API_TYPE_TO_DATAFLOW_ID,
    DEVICE_LINE_AUTH_API_TYPE,
} from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';

/**
 * Gets signed url for uploading a file
 */
export function getSignedURL(apiType: DEVICE_LINE_AUTH_API_TYPE, params: GetSignedUrlParams): AxiosPromise<SignedUrl> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/signedurl/upload',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name: params.name,
            dataflow_id: API_TYPE_TO_DATAFLOW_ID.get(apiType),
        },
    });
}

/**
 * Gets signed url for downloading a file
 */
export function getSignedURLForDownload(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    filename: string,
): AxiosPromise<SignedUrlForDownloadResponse> {
    return http({
        method: 'POST',
        url: '/v3developerlineauthorization/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            api_type: apiType,
            file_name: filename,
        },
    });
}

// Note: Unfortunately the backend can only provide 500 entites maximum so defaulting the size to 500
export function getRegisteredEntities(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    page = 1,
    size = 500,
): AxiosPromise<RegisteredEntities> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            page,
            size,
            dataflow_id: API_TYPE_TO_DATAFLOW_ID.get(apiType),
        },
    });
}

export function getEntityDetailsByName(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    name: string,
): AxiosPromise<EntityDetailsByName> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: API_TYPE_TO_DATAFLOW_ID.get(apiType),
            name,
        },
    });
}

export function getEntityDetailsByCategory(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    name: string,
    category: string,
    page: number,
    size: number,
): AxiosPromise<object> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: API_TYPE_TO_DATAFLOW_ID.get(apiType),
            name,
            category,
            page,
            size,
        },
    });
}

/**
 * @param msisdn
 * @returns {AxiosPromise}
 */
export function queryMsisdn(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): AxiosPromise<QueryMsisdnResponse> {
    return http({
        method: 'POST',
        url: '/v3developerlineauthorization/querymsisdn',
        data: {
            api_type: apiType,
            search_field: searchIndex,
            search_value: searchString,
        },
    });
}

export default {};
