var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.displayTable)?_c('AbstractTableTile',{attrs:{"entities":_vm.entities,"columnsData":_vm.columnsData,"apiResponse":_vm.apiResponse,"tabs":_vm.tabs,"activeTabId":_vm.activeTab,"tableKey":_vm.tableKey},on:{"tabSelected":function (id) { return _vm.onTabSelected(id); }},scopedSlots:_vm._u([{key:"afterButtons",fn:function(){return [(_vm.isPortInAvailable && _vm.isReSubmitPortInAvailable)?_c('AppButton',{staticClass:"ml-3",attrs:{"label":_vm.$i18n.t('customerCare.userInformation.reSubmitPortIn'),"data-test-id":"re-submit-port-in-button"},on:{"click":_vm.resubmitPortInRequest}}):_vm._e(),(_vm.isPortInAvailable && _vm.isCancelPortInAvailable)?_c('AppButton',{staticClass:"ml-3",attrs:{"label":_vm.$i18n.t('customerCare.userInformation.cancelPortIn'),"data-test-id":"cancel-port-in-button"},on:{"click":_vm.cancelPortInRequest}}):_vm._e(),(_vm.isIssueTemporaryMNPPINAvailable)?_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.isFraud ? _vm.$i18n.t('customerCareSuite.portingTableTile.issueTemporaryMNPPINDisabled') : '',
                classes: ['dark'],
                placement: 'bottom',
                trigger: 'hover click',
                autoHide: false,
            }),expression:"{\n                content: isFraud ? $i18n.t('customerCareSuite.portingTableTile.issueTemporaryMNPPINDisabled') : '',\n                classes: ['dark'],\n                placement: 'bottom',\n                trigger: 'hover click',\n                autoHide: false,\n            }"}],class:['ml-3', { 'allow-pointer-events': _vm.isFraud }],attrs:{"label":_vm.$i18n.t('customerCare.subscriberTab.issueTemporaryMNPPIN'),"disabled":_vm.isFraud,"data-test-id":"issuer-temporary-mnp-pin"},on:{"click":_vm.issueTemporaryMNPPIN}}):_vm._e()]},proxy:true},{key:"status",fn:function(ref){
            var entity = ref.entity;
return [_c('AppLabel',{staticClass:"justify-content-center",attrs:{"title":_vm.getStatusMapping(entity).title || '',"color":_vm.getStatusMapping(entity).color}})]}}],null,false,3539093609)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }