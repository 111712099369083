var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showHierarchy)?_c('div',{staticClass:"associated-entities-tile-wrapper mx-1"},[(_vm.isOnOrganizationPage)?_c('div',{staticClass:"mb-3"},[_c('h3',[_vm._v(_vm._s(_vm.$i18n.t('customerCare.breadcrumbs.organization'))+": "+_vm._s(_vm.organization.name))])]):_vm._e(),_c('div',{staticClass:"d-flex mb-3 pl-3"},[_c('h3',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$i18n.t('generic.user'))+": "+_vm._s(_vm.hierarchy.name))]),_c('router-link',{staticClass:"link-sm",attrs:{"to":{
                name: _vm.RouteNames.CCS_USER_PAGE,
                params: {
                    id: _vm.hierarchy.id,
                },
            }}},[_vm._v(" "+_vm._s(_vm.hierarchy.id)+" ")])],1),_vm._l((_vm.hierarchy.children),function(acc){return _c('div',{key:acc.id,staticClass:"my-2 px-3"},[_c('div',{class:['um-hierarchy-row py-1 pl-3 pr-2 mb-1', { active: _vm.isActive(acc.id) }],attrs:{"data-test-id":"children-accounts"}},[_c('div',{staticClass:"entity-type-label heading-sm"},[_vm._v(_vm._s(_vm.$i18n.t('generic.account'))+":")]),_c('router-link',{staticClass:"id-label link-sm",attrs:{"to":{
                    name: _vm.RouteNames.CCS_ACCOUNT_PAGE,
                    params: {
                        id: acc.id,
                    },
                },"title":acc.id}},[_vm._v(" "+_vm._s(_vm.getAccountLabel(acc))+" ")]),_c('SubscriberStatusIndicator',{staticClass:"status-label",attrs:{"status":acc.state}}),_c('div',{staticClass:"msisdn-label heading-sm"}),_c('div',{staticClass:"role-label heading-sm",attrs:{"title":_vm.getPermissionValue(acc.id)}},[_vm._v(" "+_vm._s(_vm.getPermissionValue(acc.id))+" ")])],1),_vm._l((acc.children),function(sub){return _c('div',{key:sub.id,class:['um-hierarchy-row pl-3 pr-2 py-1 mb-1', { active: _vm.isActive(sub.id) }]},[_c('div',{staticClass:"entity-type-label heading-sm"},[_vm._v(_vm._s(_vm.$i18n.t('generic.subscriber'))+":")]),_c('router-link',{staticClass:"id-label link-sm",attrs:{"to":{
                    name: _vm.RouteNames.CCS_SUBSCRIBER_PAGE,
                    params: {
                        id: sub.id,
                    },
                },"title":sub.id}},[_vm._v(" "+_vm._s(sub.id)+" ")]),_c('SubscriberStatusIndicator',{staticClass:"status-label",attrs:{"status":sub.state}}),_c('div',{staticClass:"msisdn-label heading-sm"},[_vm._v(" "+_vm._s(sub.msisdn)+" ")]),_c('div',{staticClass:"role-label heading-sm",attrs:{"title":_vm.getPermissionValue(sub.id)}},[_vm._v(" "+_vm._s(_vm.getPermissionValue(sub.id))+" ")])],1)})],2)}),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.isViewEnabled('UMGrantPermission'))?_c('AppButton',{staticClass:"mt-4",attrs:{"label":_vm.$i18n.t('customerCare.userInformation.grantPermission'),"buttonType":_vm.BUTTON_TYPES.SECONDARY,"data-test-id":"grant-permission-open-modal-btn"},on:{"click":function($event){return _vm.onToggleModal(true)}}}):_vm._e()],1),(_vm.isModalVisible)?_c('GrantPermissionModal',{staticClass:"mb-2",attrs:{"isModalVisible":_vm.isModalVisible,"userId":_vm.id},on:{"close":function($event){return _vm.onToggleModal(false)}}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }