




















































// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Http
import { createApplicationOwner } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { required } from 'vuelidate/lib/validators';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'ApplicationOwnersEditorPage',

    components: {
        AppHeader,
        AbstractEditPageWrapper,
        AppButton,
        AppInputV3,
    },

    data() {
        return {
            name: '' as string,
            description: '' as string,
            organizationName: '' as string,

            BUTTON_TYPES: BUTTON_TYPES as any,
            ICON_TYPES: ICON_TYPES as any,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OperateAPIsApplicationOwnersWrite');
        },
    },

    validations: {
        name: {
            required,
        },
        description: {
            required,
        },
        organizationName: {
            required,
        },
    },

    methods: {
        async onSave(): Promise<any> {
            try {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                await createApplicationOwner({
                    '@type': 'ApplicationOwner',
                    '@baseType': 'PartyRole',
                    name: this.name,
                    description: this.description,
                    engagedParty: {
                        '@type': 'ApplicationOwnerOrganization',
                        '@baseType': 'Organization',
                        name: this.name,
                        tradingName: 'Dusan organization',
                        isLegalEntity: true,
                        organizationType: 'ForVeryMuchProfitOrganisation',
                        privacyPolicyURL: 'https://www.funtasticgames.com/privacy',
                        taxNumber: '00.623.904/0001-73',
                        organizationIdentification: [
                            {
                                '@type': 'OrganizationIdentification',
                                identificationType: 'CNPJ',
                                identificationId: '00.623.904/0001-73',
                                issuingAuthority: 'Receita Federal',
                            },
                        ],
                        externalReference: [
                            {
                                '@type': 'ChannelPartner',
                                owner: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
                            },
                        ],
                        registeredGeographicAddress: {
                            '@type': 'LightGeographicAddress',
                            '@baseType': 'GeographicAddress',
                            streetNr: '123',
                            streetName: 'Avenida Paulista',
                            locality: 'Para?so',
                            city: 'S?o Paulo',
                            stateOrProvince: 'S?o Paulo',
                            countryCode: {
                                '@type': 'ISO31661Alpha2StandardIdentifier',
                                '@baseType': 'StandardIdentifier',
                                value: 'BR',
                            },
                            postcode: '01311-000',
                            geographicSubAddress: {
                                '@type': 'LightGeographicSubAddress',
                                '@baseType': 'GeographicSubAddress',
                                buildingName: 'Fogo',
                                levelNumber: 'Planta 4',
                            },
                        },
                        contactMedium: [
                            {
                                '@type': 'EmailContactMedium',
                                preferred: true,
                                emailAddress: 'contact@dusanhackgames.com',
                            },
                        ],
                    },
                });

                this.$showSuccessAlert({
                    message: this.$i18n.t('operateAPIs.successfullyCreatedApplicationOwner'),
                });

                this.redirectToListPage();
            } catch {
                this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongCreateApplicationOwner'), {
                    type: ALERT_TYPES.error,
                });
            }
        },
        redirectToListPage(): void {
            this.$router.push({
                name: RouteNames.OPERATE_APIS_APPLICATION_OWNER_LIST_PAGE,
            });
        },
    },
};
