
















// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import OePlans from '@/__new__/features/orchestrationengine/OePlans.vue';

export default {
    name: 'OEMappedExecutionsSearchResult',

    components: {
        AppHeader,
        Breadcrumbs,
        OePlans,
    },
    computed: {
        breadcrumbList(): any[] {
            return [
                {
                    name: this.$i18n.t('generic.search'),
                    link: '/orchestration-engine/executions-search',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.executionsSearch.mappedExecutions'),
                },
            ];
        },
    },
};
