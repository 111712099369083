<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('campaigns.campaigns')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="filteredCampaigns.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="button">
            <div class="d-flex">
                <ResponseModalButton
                    :response="rawCampaignsResponse"
                    :title="$t('campaigns.campaigns')"
                />
                <ImportEntitiesModalButton
                    :entityType="entityType"
                    @finishImport="fetchCampaigns"
                />
            </div>
        </template>

        <template slot="headerButtons">
            <template v-if="shouldDisplaySeparateAddNewCampaignComponent">
                <AppButtonDropdown
                    :iconType="ICON_TYPES.PLUS"
                    :items="campaignTypesEnabled"
                    :label="$t('campaigns.newCampaign')"
                    @selected="item => navigateToAddNewCampaign(item.value)"
                />
            </template>
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                data-test="campaigns-table"
                :entities="filteredCampaigns"
                :selectedEntityId="selectedCampId"
                :columnsData="tableColumnsData"
                :canSelectColumns="true"
                :enableRowStateControls="true"
                :canSort="true"
                :innerSearchQuery="searchQueryForTable"
                :defaultSort="defaultSort"
                tableKey="campaigns"
                :isDataLoading="isDataLoading"
                :isSearchEnabled="true"
                :entityType="entityType"
                @selectEntity="selectCampaign"
                @stop="onStop"
                @start="onStart"
                @edit="goToEditPage"
                @clone="id => goToEditPage(id, true)"
                @delete="confirmCampaignDeletion"
                @details="id => $refs.DetailsJsonModal.display(getCachedCampaigns[id], id)"
            >
                <template #state="{ entity }">
                    <CampaignStatusIndicator :status="entity.status.title" />
                </template>
            </AppTable>
            <DetailsJsonModal ref="DetailsJsonModal" />
        </template>

        <template slot="overview">
            <CampaignOverview
                v-if="isOverviewEnabled && selectedCampaign"
                :campaign="selectedCampaign"
                :deliveryAnalytics="deliveryAnalytics[selectedCampId]"
                @closeOverview="isOverviewEnabled = false"
            />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { ALERT_TYPES } from '@/common/alerts/Alert';
import CampaignOverview from '@/__new__/features/campaigns/CampaignOverview.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AppButtonDropdown from '@/components/partials/inputs/AppButtonDropdown.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import CampaignStatusIndicator from '@/__new__/features/campaigns/CampaignStatusIndicator.vue';
import OperationInProcessMixin from '@/components/partials/OperationInProcessMixin.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import Vue from 'vue';
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import Highlight from 'vue-highlight-text/public/directive.min';
import Button from '@/common/button/Button';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';
import RouteNames from '@/router/routeNames';
import {
    getCampaign,
    removeCampaign,
    startCampaign,
    stopCampaign,
} from '@/__new__/services/dno/campaigns/http/campaigns';
import Campaign, {
    CampaignType,
    CampaignActions,
    CampaignFeaturesByType,
    CampaignTypes,
    CampaignStatusesServerMapping,
    mapCampaignActions,
    CampaignStatuses,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import SegmentStatistics from '@/__new__/services/dno/segments/models/SegmentStatistics';
import { campaignReach } from '@/__new__/services/dno/campaigns/http/analytics';
import * as Sentry from '@sentry/vue';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { capitalizeWord } from '@/common/formatting';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(Modules.campaigns);
const segmentHelpers = createNamespacedHelpers(Modules.segments);

export default {
    name: 'Campaigns',
    components: {
        DetailsJsonModal,
        CampaignOverview,
        AbstractListPageWrapper,
        AppTable,
        FilterTable,
        AppButtonDropdown,
        CampaignStatusIndicator,
        TableFiltersTags,
        ImportEntitiesModalButton,
        ResponseModalButton,
    },
    directives: {
        highlight: Highlight,
    },
    mixins: [FilterTableMixin, OperationInProcessMixin, supportButtonMixin],
    beforeRouteEnter(to, from, next) {
        next(that => to.params?.id && that.selectCampaign(to.params.id));
    },
    data() {
        return {
            ICON_TYPES,
            selectedCampId: null,
            emptyStats: SegmentStatistics.empty(),
            deliveryAnalytics: {}, // by campaign id
            searchQueryForTable: '',
            entityType: ENTITY_TYPES.CAMPAIGN,
            isDataLoading: false,
            isOverviewEnabled: false,
        };
    },
    computed: {
        ...mapState([State.CACHED_CAMPAIGNS, State.RAW_CAMPAIGNS]),
        ...mapGetters([Getters.GET_CACHED_CAMPAIGNS]),
        defaultSort() {
            return {
                sortBy: camp => camp.updateTimestamp,
                type: 'desc',
            };
        },
        segments() {
            return this.$store.state[Modules.segments][State.CACHED_SEGMENTS];
        },
        rawCampaignsResponse() {
            return this[State.RAW_CAMPAIGNS];
        },
        campaigns() {
            const campaigns = Object.values(this[Getters.GET_CACHED_CAMPAIGNS]);
            // put campaigns in order (f.e "running" campaigns are placed at the top of the list)
            campaigns.sort(
                (campaign1, campaign2) =>
                    CampaignStatusesServerMapping[campaign1.status.title] -
                    CampaignStatusesServerMapping[campaign2.status.title],
            );
            return campaigns;
        },
        shouldDisplaySeparateAddNewCampaignComponent() {
            return (
                permissionsService.cepCampaignsWriteEnabled() &&
                isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite', 'BackofficeCampaignsWrite')
            );
        },
        campaignFeatures() {
            return CampaignFeaturesByType[this.selectedCampaignType];
        },
        campaignTypesEnabled() {
            return Object.values(CampaignTypes).filter(type => type.isAllowed());
        },
        selectedCampaignType() {
            return this.selectedCampaign ? this.selectedCampaign.campaignType : null;
        },
        selectedCampaign() {
            return this.selectedCampId && this.campaigns.find(camp => camp.id === this.selectedCampId);
        },
        filteredCampaigns() {
            return this.filteredEntitiesMixin(
                this.campaigns.map(c => ({
                    ...c,
                    campaignType: CampaignTypes[c.campaignType].label || c.campaignType,
                    state: c.status.title,
                    deliveryTypeFilter: c.deliveryType.type,
                    start: moment.unix(c.startTime),
                    end: c.endTime ? moment.unix(c.endTime) : null,
                    channels: this.composeChannelsString(c),
                    allowedActionsExternal: mapCampaignActions(c),
                })),
            );
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.filteredCampaigns.map(e => e.state))),
                },
                {
                    name: this.$i18n.t('campaigns.delivery'),
                    key: 'deliveryTypeFilter',
                    field: 'deliveryTypeFilter',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.filteredCampaigns.map(e => e.deliveryTypeFilter))),
                },
                {
                    name: this.$i18n.t('campaigns.startDate'),
                    key: 'start',
                    field: 'start',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('campaigns.endDate'),
                    key: 'end',
                    field: 'end',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'campaignType',
                    field: 'campaignType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.filteredCampaigns.map(e => e.campaignType))),
                },
                {
                    name: this.$i18n.t('campaigns.channels'),
                    key: 'channels',
                    field: 'channels',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ].filter(column => column);
        },
    },
    created() {
        this.initCampaigns();
    },
    methods: {
        ...mapMutations([Mutations.SET_ALL_CACHED_CAMPAIGNS, Mutations.SET_CACHED_CAMPAIGN]),
        ...mapActions([Actions.FETCH_ALL_CAMPAIGNS]),
        ...segmentHelpers.mapActions([
            Actions.FETCH_SEGMENT_STATISTICS,
            Actions.LOAD_FILTER_DEFINITIONS,
            Actions.FETCH_SEGMENTS,
        ]),
        composeChannelsString(campaign) {
            // create data for channel column in table
            return campaign.messages[0].map(message => capitalizeWord(message.type)).join(', ');
        },
        handleLoadingError(err) {
            Sentry.captureException(err);
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
            });
            this.$Progress.fail();
        },
        async fetchCampaigns() {
            await this[Actions.FETCH_ALL_CAMPAIGNS]();
        },
        async initCampaigns() {
            try {
                this.$Progress.start();
                this.isDataLoading = true;
                const promises = [];

                if (permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
                    promises.push(this[Actions.FETCH_SEGMENTS]());

                    if (permissionsService.dynamicSegmentsEnabled() && isUserAllowed('DynamicSegmentsWrite')) {
                        promises.push(this[Actions.LOAD_FILTER_DEFINITIONS]());
                    }
                }

                await Promise.all(promises);
                await this.fetchCampaigns();

                // Pre-loads triggers definitions for CampaignSummary component
                this.$store.dispatch(`${Modules.triggers}/${Actions.LOAD_TRIGGER_DEFINITIONS}`);
            } catch (err) {
                this.handleLoadingError(err);
            } finally {
                this.$Progress.finish();
                this.isDataLoading = false;
            }
        },
        onStop(campaignId) {
            const campaign = this.campaigns.find(c => c.id === campaignId);
            if (campaign.actionAllowed() !== CampaignActions.Stop) return;

            const { title, description, label } = this.$t('alerts.stopCampaignConfirmation');
            this.$alert(title, {
                description,
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label,
                        handler: () => {
                            this.runOperationIfPossible(async () => {
                                await this.stopCampaign(campaignId);
                                await this.updateCampaignData(campaignId);
                            });
                        },
                    }),
                ],
            });
        },
        onStart(campaignId) {
            const messages = [];
            const campaign = this.campaigns.find(c => c.id === campaignId);
            if (campaign.actionAllowed() !== CampaignActions.Start) return;

            if (campaign.lastRunTimestamp > moment.utc().subtract(24, 'h').unix()) {
                messages.push(this.$t('campaigns.confirmStartRecentlyStarted'));
            }

            if (campaign.startTime < moment.utc().unix()) {
                messages.push(this.$t('campaigns.confirmStartWithStartDateInThePast'));
            }

            if (messages.length > 0) {
                this.$alert(this.$t('campaigns.areYouSureLaunchTheCampaign'), {
                    type: ALERT_TYPES.warning,
                    description: messages.join('\n'),
                    buttons: [
                        new Button({
                            label: this.$i18n.t('generic.proceed'),
                            handler: () => {
                                this.startCampaignAndUpdateItsData(campaignId);
                            },
                        }),
                    ],
                });
                return;
            }

            this.startCampaignAndUpdateItsData(campaignId);
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },

        // todo check campaing type
        navigateToAddNewCampaign(selectedCampaignType) {
            const campaignType = selectedCampaignType != null ? selectedCampaignType : CampaignType.MarketingCampaign;
            this.$router.push({
                name: RouteNames.CAMPAIGNS_ADD,
                params: { campaignType, companyId: this.$route.params.companyId },
            });
        },
        selectCampaign(campaignId) {
            if (campaignId !== this.selectedCampId) {
                this.selectedCampId = campaignId;

                this.fetchAnalytics(campaignId);
                this.onVisibleEntitiesChanged(campaignId);
            }
            this.isOverviewEnabled = true;
        },
        fetchAnalytics(campaignId) {
            const isDraftState =
                this[Getters.GET_CACHED_CAMPAIGNS][campaignId]?.status?.title === CampaignStatuses.Draft.title;
            if (this.campaignFeatures?.deliveryStats.enabled && !isDraftState) {
                campaignReach(campaignId).then(({ data }) => {
                    this.$set(this.deliveryAnalytics, campaignId, data.stats);
                });
            }
        },

        startCampaignAndUpdateItsData(campaignId) {
            this.runOperationIfPossible(async () => {
                await this.startCampaign(campaignId);
                await this.updateCampaignData(campaignId);
            });
        },
        async startCampaign(campaignId) {
            try {
                this.$Progress.start();
                await startCampaign(campaignId);
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('campaigns.campaign'),
                        action: 'started',
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$Progress.fail();
                const message = this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                    action: 'starting',
                    entityName: 'campaign',
                });
                this.$eventBus.$emit('showAlert', { message });
                throw e;
            }
        },
        async stopCampaign(campaignId) {
            try {
                this.$Progress.start();
                await stopCampaign(campaignId);
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('campaigns.campaign'),
                        action: 'stopped',
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'stopping',
                        entityName: 'campaign',
                    }),
                });
                throw e;
            }
        },
        async updateCampaignData(campaignId) {
            try {
                const campaignJson = (await getCampaign(campaignId)).data;
                const updatedCampaign = Campaign.fromJson(campaignJson);
                const campaignIndex = this.campaigns.findIndex(camp => camp.id === updatedCampaign.id);
                Vue.set(this.campaigns, campaignIndex, updatedCampaign);
                this[Mutations.SET_CACHED_CAMPAIGN](updatedCampaign);
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'updating',
                        entityName: 'campaign',
                    }),
                });
            }
        },
        goToEditPage(id, clone = false) {
            this.$router.push({
                name: RouteNames.CAMPAIGNS_EDIT,
                params: { id, clone, companyId: this.$route.params.companyId },
            });
        },
        confirmCampaignDeletion(campaignId) {
            const deleteButton = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            });
            const entityName = this[State.CACHED_CAMPAIGNS][campaignId]?.name || this.$i18n.t('generic.entity');
            const version = this[State.CACHED_CAMPAIGNS][campaignId]?.version;
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName,
                }),
                type: ALERT_TYPES.warning,
                buttons: [deleteButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === deleteButton.id) {
                    this.deleteCampaign(campaignId, version);
                }
            });
        },
        async deleteCampaign(campaignId, version) {
            try {
                this.$Progress.start();
                await removeCampaign(campaignId, version);
                await this[Actions.FETCH_ALL_CAMPAIGNS]();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('campaigns.campaign'),
                        action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                    }),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (err) {
                Sentry.captureException(err);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        entityName: this.$i18n.t('campaigns.campaign'),
                        action: this.$i18n.t('generic.deleting'),
                    }),
                });
            }
        },
        campaignActionIcon(campaignId) {
            const campaign = this.campaigns.find(c => c.id === campaignId);
            if (campaign.actionAllowed() === CampaignActions.Start) {
                return ICON_TYPES.START;
            }
            if (campaign.actionAllowed() === CampaignActions.Stop) {
                return ICON_TYPES.STOP;
            }
            if (campaign.actionAllowed() === CampaignActions.None) {
                return '';
            }
            return '';
        },
        getSegmentByCampaignId(campaignId) {
            const campaign = this.campaigns.find(c => c.id === campaignId);
            return campaign.segmentIds ? campaign.segmentIds[0] : null;
        },
        async onVisibleEntitiesChanged(campaignId) {
            const campaign = this.campaigns.find(c => c.id === campaignId);
            const segmentId = this.getSegmentByCampaignId(campaign.id);

            const segmentStatsPromise = segmentId
                ? this[Actions.FETCH_SEGMENT_STATISTICS]({
                      segmentId,
                      segmentIdType: this.selectedEntity.segmentIdType,
                  })
                : Promise.resolve();

            try {
                await segmentStatsPromise;
            } catch (error) {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.cep.errorFetchingCampaignStatistics', {
                        campaignName: campaign.name,
                    }),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_mixins';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

// workaround to make dropdown look like a button with dropdown options
::v-deep .dropdown {
    .dropdown-input {
        height: 40px;
        background-color: $blue !important;
        border-radius: 1.25rem;
        border: none;

        &::placeholder {
            color: white;
            font-weight: bolder;
            padding-left: 2.375rem;
            font-size: 0.875rem;
        }

        &:hover {
            // TODO: replace this color after this whole hack for the dropdown removed
            background-color: #2c4882 !important;
        }
    }

    .dropdown-arrow {
        display: none;
    }

    .dropdown-menu {
        top: 5px !important;
    }
}
</style>
