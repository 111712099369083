import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import { reduce } from 'lodash';
import { capitalizeWord } from '@/common/formatting';

export class SinkTypeCasterOption {
    readonly label: string;
    readonly toType: CDP_PROPERTY_TYPE;
    readonly isPredefined: boolean;
    constructor(label: string, toType: CDP_PROPERTY_TYPE, isPredefined = false) {
        this.label = label;
        this.toType = toType;
        this.isPredefined = isPredefined;
    }
}

export class SinkTypeCaster {
    fromType: CDP_PROPERTY_TYPE;
    castingOptions: SinkTypeCasterOption[];
    isRequired?: boolean;

    constructor(data: Partial<SinkTypeCaster> = {}) {
        this.fromType = data.fromType as CDP_PROPERTY_TYPE;
        this.castingOptions = data.castingOptions || [];
        this.isRequired = data.isRequired;
    }

    static fromJson(response: any): Record<CDP_PROPERTY_TYPE, SinkTypeCaster> {
        const { casters, required, data_type_to_default_caster } = response.data;

        return reduce(
            casters,
            (acc: any, curVal, toType) => {
                curVal.compatible_original_types.forEach((fromType: CDP_PROPERTY_TYPE) => {
                    if (!!acc[fromType]) {
                        //predefined caster will be shown as the first option in dropdown
                        const isPredefined = data_type_to_default_caster?.[fromType] === toType;
                        const newOption = new SinkTypeCasterOption(
                            curVal.label,
                            toType as CDP_PROPERTY_TYPE,
                            isPredefined,
                        );
                        if (isPredefined) {
                            acc[fromType].castingOptions.unshift(newOption);
                        } else {
                            acc[fromType].castingOptions.push(newOption);
                        }
                    } else {
                        acc[fromType] = new SinkTypeCaster({
                            fromType,
                            castingOptions: [
                                new SinkTypeCasterOption(curVal.label, toType as CDP_PROPERTY_TYPE, false),
                            ],
                            isRequired: required,
                        });
                    }
                });
                return acc;
            },
            {},
        );
    }
}

export default SinkTypeCaster;
