














// components
import SegmentStatistics from '@/__new__/services/dno/segments/models/SegmentStatistics';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
// helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ICON_TYPES } from '@/common/iconHelper';

type SegmentSummaryStats = {
    iconCssClass: string;
    label: string;
    value: string;
    iconLabel: string;
    enabled: boolean;
};

export default {
    name: 'SegmentSummary',
    components: {
        AppButton,
    },
    props: {
        filters: { type: Array, default: () => [] },
        stats: { type: Object as any, default: () => undefined },
        applyFilters: { type: Object, default: () => ({ visible: false, progress: false }) },
    },
    data() {
        return {
            TOOLTIP_POSITION,
            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    computed: {
        summaryStats(): SegmentSummaryStats[] {
            const summaryStats = this.stats ? SegmentStatistics.fromJson(this.stats) : SegmentStatistics.empty();

            return summaryStats.buildCompactStaticsComponents();
        },
        filtersAmount(): number {
            return (this.filters || []).length;
        },
    },
};
