<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template slot="content">
            <div class="wrapper d-flex h-100 position-relative">
                <AppSpinner :isVisible="isDataLoading" />
                <template v-if="!isDataLoading">
                    <template v-if="!hasRules">
                        <div class="d-flex justify-content-center align-items-center container">
                            <span>
                                {{ $i18n.t('quietHours.explanation1') }}
                            </span>
                            <span class="mt-3">
                                {{ $i18n.t('quietHours.explanation2') }}
                            </span>
                            <AppButton
                                id="add-quiet-hours"
                                :buttonType="BUTTON_TYPES.PRIMARY"
                                :label="$i18n.t('quietHours.start')"
                                class="mt-5"
                                @click="createQuietHours"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="title d-flex justify-content-between col-sm-10 col-md-8 col-lg-6 mb-2 mt-4">
                                <span class="ongoing font-weight-bold">
                                    {{ toUpper($i18n.t('quietHours.ongoingRules')) }}
                                </span>
                                <span
                                    v-t="'quietHours.editRules'"
                                    class="edit"
                                    @click="goToEditQuietHoursPage"
                                />
                            </div>
                        </div>
                        <QuietHoursDisplayRules
                            :rules="quietHoursData[0].rules"
                            class="col-sm-10 col-md-8 col-lg-6 ml-4"
                        />
                    </template>
                </template>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Components
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import QuietHoursDisplayRules from './QuietHoursDisplayRules.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';

// HTTP
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';

// Helpers
import toUpper from 'lodash/toUpper';
import { QuietHoursTypes, dayOfWeek, dayRangeTypes, holidayRangeTypes } from './quietHoursHelper';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';

export default {
    name: 'QuietHours',
    components: {
        AppSpinner,
        AppButton,
        QuietHoursDisplayRules,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            toUpper,
            hasRules: null,
            isDataLoading: true,
            quietHoursData: null,
            QuietHoursTypes,
            dayOfWeek,
            dayRangeTypes,
            holidayRangeTypes,
            BUTTON_TYPES,
        };
    },
    computed: {
        pageTitle() {
            return this.$i18n.t('quietHours.quietHoursPage');
        },
    },
    async created() {
        try {
            this.$Progress.start();
            this.isDataLoading = true;
            const qh = await getQuietHours();
            this.quietHoursData = qh.data.length ? [qh.data[0]] : qh.data;
            this.hasRules =
                this.quietHoursData.length && Object.prototype.hasOwnProperty.call(this.quietHoursData[0], 'rules')
                    ? this.quietHoursData[0].rules.length
                    : false;
            this.$Progress.finish();
        } catch (error) {
            Sentry.captureException(error);
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadQuietHoursData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        createQuietHours() {
            this.$router.push({
                name: RouteNames.QUIET_HOURS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        goToEditQuietHoursPage() {
            this.$router.push({
                name: RouteNames.QUIET_HOURS_EDITOR,
                params: { id: this.quietHoursData[0].id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.wrapper {
    flex-direction: column;
}

.container {
    width: 50%;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    font-size: 0.75rem;
    color: $gray60;
}

.title {
    width: 50%;
    margin-left: 1.5rem;
    line-height: 1.625rem;

    .ongoing {
        color: $gray-blue;
        font-size: 0.75rem;
    }

    .edit::before {
        content: url($icon-path + $edit-icon-blue);
        margin-right: 0.25rem;
    }

    .edit {
        font-size: 0.875rem;
        color: $blue;
        font-weight: 600;
        cursor: pointer;
    }
}
</style>
