<script>
import {
    PERIOD_TYPES,
    CHARGING_TYPE,
    LIMIT_OPTIONS,
    TOTAL_LIMIT_OPTIONS,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import permissionsService from '@/services/permissions/permissions.service';

export default {
    name: 'ChargeConfigurationMixin',

    data() {
        return {
            dataCountUnits: ['GB', 'MB', 'KB', 'Bytes'],
            roundCountUnits: ['Bytes'],
            serviceTimeOptions: [
                this.$i18n.t('generic.days'),
                this.$i18n.t('generic.hours'),
                this.$i18n.t('generic.minutes'),
                this.$i18n.t('generic.seconds'),
            ],
            roundServiceTimeUnits: [this.$i18n.t('generic.seconds')],
            requiredErrors: {
                perUnitMoneyAmountError: false,
                perUnitChargePeriodAmountError: false,
                perPeriodMoneyAmountError: false,
                perPeriodPeriodLimitError: false,
                perPeriodTotalLimitError: false,
                quotaCountAmountError: false,
                quotaChargePeriodAmountError: false,
            },
            PERIOD_TYPES,
            CHARGING_TYPE,
            LIMIT_OPTIONS,
            permissionsService,
        };
    },
    computed: {
        chargeValues: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        unlimitedPeriod() {
            if (
                this.chargeValues?.[this.chargeType]?.selectedLimit?.id === LIMIT_OPTIONS.unlimited.id &&
                this.totatlLimitOptions
            ) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.chargeValues[this.chargeType].limitValue = null;
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.chargeValues[this.chargeType].selectedTotalLimit = this.totatlLimitOptions.find(
                    o => o.id === TOTAL_LIMIT_OPTIONS.noTotalLimit.id,
                );
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                return true;
            }
            return false;
        },
        unlimitedTotal() {
            if (
                this.chargeValues[this.chargeType].selectedTotalLimit &&
                this.chargeValues[this.chargeType].selectedTotalLimit.id === TOTAL_LIMIT_OPTIONS.noTotalLimit.id
            ) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.chargeValues[this.chargeType].totalLimitValue = null;
                return true;
            }
            return false;
        },
        roundNumberData() {
            return this.roundCountUnits.includes(this.chargeValues[this.chargeType].countUnit);
        },
        roundNumberVoice() {
            return this.roundServiceTimeUnits.includes(this.chargeValues[this.chargeType].countUnit);
        },
        totatlLimitOptionsFormatted() {
            if (this.unlimitedPeriod) {
                return this.totatlLimitOptions.filter(o => o.id === TOTAL_LIMIT_OPTIONS.noTotalLimit.id);
            }
            return this.totatlLimitOptions;
        },
    },
    watch: {
        requiredError: {
            handler() {
                switch (this.chargeType) {
                    case CHARGING_TYPE.perUnit:
                        if (!this.chargeValues[CHARGING_TYPE.perUnit].moneyAmount) {
                            this.requiredErrors.perUnitMoneyAmountError = true;
                        }
                        if (!this.chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount) {
                            this.requiredErrors.perUnitChargePeriodAmountError = true;
                        }
                        break;
                    case CHARGING_TYPE.quota:
                        if (!this.chargeValues[CHARGING_TYPE.quota].countAmount) {
                            this.requiredErrors.quotaCountAmountError = true;
                        }
                        if (!this.chargeValues[CHARGING_TYPE.quota].chargePeriodAmount) {
                            this.requiredErrors.quotaChargePeriodAmountError = true;
                        }
                        break;
                    case CHARGING_TYPE.perPeriod:
                        if (!this.chargeValues[CHARGING_TYPE.perPeriod].moneyAmount) {
                            this.requiredErrors.perPeriodMoneyAmountError = true;
                        }
                        if (!this.chargeValues[CHARGING_TYPE.perPeriod].limitValue && !this.unlimitedPeriod) {
                            this.requiredErrors.perPeriodPeriodLimitError = true;
                        }
                        if (!this.chargeValues[CHARGING_TYPE.perPeriod].totalLimitValue && !this.unlimitedTotal) {
                            this.requiredErrors.perPeriodTotalLimitError = true;
                        }
                        if (
                            this.chargeValues[CHARGING_TYPE.perPeriod].totalLimitValue <=
                            this.chargeValues[CHARGING_TYPE.perPeriod].limitValue
                        ) {
                            this.requiredErrors.perPeriodPeriodLimitError = true;
                        }
                        break;
                    default:
                        break;
                }
            },
        },
        chargeType: {
            handler() {
                this.clearRequiredErrors();
                this.$emit('clearRequiredError');
            },
        },
    },
    methods: {
        setRequiredErrorVal(key, val) {
            this.requiredErrors[key] = val;
            if (!val) {
                this.$emit('clearRequiredError');
            }
        },
        clearRequiredErrors() {
            for (const key in this.requiredErrors) {
                if (key) {
                    this.requiredErrors[key] = false;
                }
            }
        },
        genErrorMessage(value) {
            if (value === undefined || Number.isNaN(value)) {
                return this.$i18n.t('generic.validations.fieldIsRequired');
            }
            if (value < 1) {
                return this.$i18n.t('generic.validations.valueShouldBeMoreThanZero');
            }
            return this.$i18n.t('generic.validations.periodLimitError');
        },
    },
};
</script>
