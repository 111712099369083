/**
 * Vue Application and framework
 */
import Vue from 'vue';
import App from './App.vue'; // Entry point component

// Router files
import Router from 'vue-router';
import router from './router';

/**
 * Vue store | Vuex
 */
import store from './store/store';

/**
 * Third party packages
 *
 */
import Vuelidate from 'vuelidate';
import VueCookie from 'vue-cookie';
import VueProgressBar from 'vue-progressbar';
import VTooltip from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import 'bootstrap'; // init bootstrap js
import i18n from './i18n';
import FormBuilder from '@/components/FormBuilder';
import FormBuilderConfig from './formBuilderConfig';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import MonacoLoader from '@monaco-editor/loader';

/**
 * Sentry error reporting
 */
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

/**
 * Global event bus
 */
import eventBus from './eventBus';

import AppAlerts from '@/plugins/AppAlerts.plugin';
import ProgressBar from '@/plugins/ProgressBar.plugin';
import AppLoading from '@/plugins/AppLoading.plugin';

/**
 * Global localization functions
 */
import localeLibrary from '@/common/locale/localeLibrary';

/**
 * Enabling sentry only in production mode as it blocks any exception reporting to browser console.
 * Making local development much more difficult. Also reducing the amount of spam reported as well being more gentle on
 * our event quota.
 */
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://4ca41c7628bb49bfbb71c8e0d2fd49b1@sentry.io/1484182',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        ignoreErrors: ['Session has expired'],

        tracesSampleRate: 1.0,
    });
}

/**
 * Use vue plugins
 */
Vue.use(Router);
Vue.use(Vuelidate);
Vue.use(VueCookie);
Vue.use(VTooltip);
Vue.use(VueClipboard);
Vue.use(VueProgressBar, {
    color: '#ff5a27',
    failedColor: '#d32a26',
    thickness: '5px',
    autoFinish: true,
});

Vue.use(AppAlerts);
Vue.use(ProgressBar);
Vue.use(AppLoading);

Vue.use(FormBuilder, FormBuilderConfig);

Vue.use(VueDOMPurifyHTML);

/**
 * Third party libs configuration
 */
Vue.config.productionTip = false;

MonacoLoader.config({
    paths: {
        vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.41.0/min/vs',
    },
});

/**
 * Global event bus
 */
Vue.prototype.$eventBus = eventBus;

/**
 * Global localization functions
 */
Vue.prototype.$localeLibrary = localeLibrary;

new Vue({
    router,
    store,
    i18n,
    render: createElementFn => createElementFn(App),
}).$mount('#app');
