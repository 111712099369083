<template>
    <div>
        <TableFiltersRenderless :entities="messagesFormatted">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="d-flex justify-content-between align-items-center position-relative">
                        <MultipleTablesTabs
                            v-model="activeChannelId"
                            :tabs="channelTabs"
                            :isComplexTab="true"
                        />

                        <div class="d-flex align-items-center">
                            <SearchBox
                                v-model="searchQuery"
                                class="search-box"
                                :smallSearchIcon="true"
                            />

                            <ResponseModalButton v-bind="responseModalData()" />

                            <FilterTable
                                :columns="columsData"
                                :multiselectWidth="{ width: '12rem' }"
                                @filterAdded="onFilterAdded"
                            >
                                <template #button="slotProps">
                                    <IconButton
                                        :label="$i18n.t('generic.filter')"
                                        :icon="ICON_TYPES.FILTER"
                                        @iconClick="slotProps.clickHandler"
                                    />
                                </template>
                            </FilterTable>
                        </div>
                    </div>
                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :columnsData="columsData"
                        tableKey="messageHistory"
                        keyName="messageId"
                        :newDesign="true"
                        :tableTabs="true"
                        :disableBoxShadow="true"
                        :canSelectColumns="true"
                        :columnsSort="true"
                        :isSearchEnabled="true"
                        :innerSearchQuery="searchQuery"
                        :isPaginationEnabled="true"
                        :selectedEntityId="selectedMessageId"
                        @selectEntity="selectMessage"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                v-if="entity.message"
                                :icon="ICON_TYPES.CLONE"
                                class="clone-btn mr-1"
                                @iconClick="copyToClipboard(entity.message)"
                            />
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
        <AppAditionalSidebar
            v-model="showAditionalSidebar"
            class="transaction-sidebar"
        >
            <template
                v-if="selectedMessage"
                #content
            >
                <div class="modal-title">
                    {{ selectedMessage.campaignName }}
                </div>

                <div class="modal-row d-flex">
                    <div class="row-title">
                        {{ $i18n.t('campaigns.campaignName') }}
                    </div>
                    <div class="row-value">
                        {{ selectedMessage.campaignName }}
                    </div>
                </div>

                <div class="modal-row d-flex">
                    <div class="row-title">
                        {{ $i18n.t('campaigns.messages.messageSent') }}
                    </div>
                    <div class="row-value">
                        {{ millisTimestampToDateWithSeconds(selectedMessage.messageSent) }}
                    </div>
                </div>

                <div
                    v-if="selectedMessage.parsedMessage.title"
                    class="modal-row d-flex"
                >
                    <div class="row-title">
                        {{ $i18n.t('campaigns.pushTitle') }}
                    </div>
                    <div class="row-value">
                        <p class="mb-0">
                            {{ selectedMessage.parsedMessage.title }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="selectedMessage.parsedMessage.sender"
                    class="modal-row d-flex"
                >
                    <div class="row-title">
                        {{ $i18n.t('campaigns.sender') }}
                    </div>
                    <div class="row-value">
                        <p class="mb-0">
                            {{ selectedMessage.parsedMessage.sender }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="selectedMessage.parsedMessage.bcc"
                    class="modal-row d-flex"
                >
                    <div class="row-title">
                        {{ $i18n.t('campaigns.BCC') }}
                    </div>
                    <div class="row-value">
                        <p class="mb-0">
                            {{ selectedMessage.parsedMessage.bcc }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="selectedMessage.parsedMessage.subject"
                    class="modal-row d-flex"
                >
                    <div class="row-title">
                        {{ $i18n.t('campaigns.subject') }}
                    </div>
                    <div class="row-value">
                        <p class="mb-0">
                            {{ selectedMessage.parsedMessage.subject }}
                        </p>
                    </div>
                </div>

                <div class="modal-row d-flex">
                    <div class="row-title">
                        {{ $i18n.t('campaigns.messages.messageText') }}
                    </div>
                    <div class="row-value">
                        <p>{{ selectedMessage.parsedMessage.text }}</p>
                        <iframe
                            v-if="selectedMessage.parsedMessage.html"
                            :srcdoc="selectedMessage.parsedMessage.html"
                            frameBorder="0"
                            class="row-iframe"
                            @load="fixIframeHeight"
                        />
                    </div>
                </div>
            </template>
        </AppAditionalSidebar>
    </div>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// helpers
import { getAllCampaigns, getInboxHistory } from '@/__new__/services/dno/campaigns/http/campaigns';
import { MessageTypes, MessageTypesHistoryIntMapping, MessageTypeLabels } from '@/common/CampaignMessage';
import Campaign, { CampaignStatuses } from '@/__new__/services/dno/campaigns/models/Campaign';
import { millisTimestampToDateWithSeconds } from '@/common/formatting';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import { channelTabs, parseMessage } from '@/__new__/features/customerCare/common/messageHelper';

export default {
    name: 'UserMessageHistory',
    components: {
        AppTable,
        IconButton,
        TableFiltersRenderless,
        TableFiltersTags,
        ResponseModalButton,
        SearchBox,
        FilterTable,
        MultipleTablesTabs,
        AppAditionalSidebar,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        idType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.USER,
        },
    },
    data() {
        return {
            messageHistory: [],
            // ToDo: refactor once Campaigns have proper store and actions
            campaigns: [],
            ICON_TYPES,
            millisTimestampToDateWithSeconds,
            activeChannelId: MessageTypes.PUSH,
            channelTabs,
            columsData: [
                {
                    name: this.$i18n.t('campaigns.campaignName'),
                    key: 'campaignName',
                    field: 'campaignName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('campaigns.messages.messageSent'),
                    key: 'messageSent',
                    formatter: millisTimestampToDateWithSeconds,
                    field: 'messageSent',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('campaigns.messages.messageText'),
                    key: 'messageText',
                    field: 'message',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            searchQuery: '',
            selectedMessage: null,
            showAditionalSidebar: false,
            rawResponses: new Map(),
        };
    },
    computed: {
        anyCampaign() {
            return Boolean(this.campaigns.length);
        },
        messagesFormatted() {
            return this.anyCampaign
                ? this.messageHistory.map(this.formatPushDataForDisplaying).sort(this.sortMessagesForDisplaying)
                : [];
        },
        selectedMessageId() {
            return this.selectedMessage?.messageId;
        },
    },
    watch: {
        async id() {
            await this.getMessageHistory();
        },
        async activeChannelId() {
            await this.getMessageHistory();
        },
    },
    async created() {
        await this.getCampaigns();
        await this.getMessageHistory();
    },
    methods: {
        copyToClipboard(data) {
            try {
                navigator.clipboard.writeText(data);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copySuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copyFail'),
                });
            }
        },
        formatPushDataForDisplaying(pushMessage) {
            const relatedCampaign = this.campaigns.find(camp => camp.id === pushMessage?.misc?.campaign_id);

            let campaignName;

            if (relatedCampaign) {
                const relatedCampaignBuilt = Campaign.fromJson(relatedCampaign);
                // for test message we don't have real campaign, so we do this only if real campaign exists
                const deletedLabel = this.$i18n.t('generic.deleted').toUpperCase();
                campaignName =
                    relatedCampaignBuilt.status.title === CampaignStatuses.Deleted.title
                        ? `${relatedCampaignBuilt.name} (${deletedLabel})`
                        : relatedCampaignBuilt.name;
            } else {
                campaignName = this.$i18n.t('campaigns.testMessage');
            }

            const parsedMessageFields = parseMessage(pushMessage);

            // mapping to display
            return {
                messageId: pushMessage.message_id,
                campaignName,
                messageSent: pushMessage.insert_timestamp,
                message: pushMessage.message,
                parsedMessage: parsedMessageFields,
                messageText: parsedMessageFields?.text,
            };
        },
        sortMessagesForDisplaying(fistItem, secondItem) {
            return secondItem.insert_timestamp - fistItem.insert_timestamp;
        },
        async getCampaigns() {
            try {
                this.$Progress.start();
                // TODO: should be refactored to call either vuex action [Actions.FETCH_ALL_CAMPAIGNS] or get single campaign (getCampaign(id))
                this.campaigns = (await getAllCampaigns(true)).data.entities;
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
                Sentry.captureException(error);
            }
        },
        async getMessageHistory() {
            if (!this.id || !this.idType) return;

            this.messageHistory = [];
            const channelType = MessageTypesHistoryIntMapping[this.activeChannelId];
            let response;

            try {
                this.$Progress.start();
                response = await getInboxHistory(this.id, channelType, this.idType);
                this.messageHistory = response.data.flat();
                this.$Progress.finish();
            } catch (error) {
                response = error?.response;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('campaigns.userMessagesHistory'),
                    }),
                });
                this.$Progress.fail();
                Sentry.captureException(error);
            } finally {
                this.rawResponses.set(this.activeChannelId, response);
            }
        },
        selectMessage(messageId) {
            this.selectedMessage = this.messagesFormatted.find(m => m.messageId === messageId);
            this.showAditionalSidebar = true;
        },
        fixIframeHeight(e) {
            const iframe = e.target;
            iframe.contentWindow.document.body.style.margin = 0;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
        },
        responseModalData() {
            return {
                response: this.rawResponses.get(this.activeChannelId),
                title: `${this.$i18n.t('customerCare.messageHistory')} - ${MessageTypeLabels[this.activeChannelId]}`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.search-box {
    width: 15rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    // color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
}

.row-title {
    font-weight: 600;
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

.row-iframe {
    width: 100%;
}
</style>
