


































































import Vue from 'vue';
// components
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
// helpers
import { mapGetters } from 'vuex';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import CredentialChange from '@/__new__/services/dno/user/models/CredentialChange';

export default Vue.extend({
    name: 'UserCredentialChangeHistory',
    components: {
        AppTable,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    data() {
        return {
            ICON_TYPES,
            searchQueryCredentialsChangeHistory: '',
        };
    },
    computed: {
        ...mapGetters('userManagementUser', {
            getCredsHistoryById: 'getCredsHistoryById',
            getCredsHistoryApiResponseById: 'getCredsHistoryApiResponseById',
        }),
        userId(): string {
            return this.$route.params.id || '';
        },
        credsHistory(): CredentialChange[] {
            return this.userId ? this.getCredsHistoryById(this.userId) : [];
        },
        credsHistoryApiResponse(): Record<string, any> | null {
            return this.userId ? this.getCredsHistoryApiResponseById(this.userId) : null;
        },
        credentialsChangeHistoryColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.changeTime'),
                    key: 'changeTimeLabel',
                    sortBy: entity => entity.changeTime,
                    field: 'changeTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.activity'),
                    key: 'activityLabel',
                    field: 'activityLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.activityLabel))],
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.methodOfLogin'),
                    key: 'loginMethodLabel',
                    field: 'loginMethodLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.loginMethodLabel))],
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'statusLabel',
                    field: 'statusLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.statusLabel))],
                },
            ];
        },
    },
});
