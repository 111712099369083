


































































































































































































































































// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import DiagramUpdateModal from '@/__new__/features/orchestrationengine/DiagramUpdateModal.vue';
import Button from '@/common/button/Button';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Validation
import { required } from 'vuelidate/lib/validators';

// Helpers
import {
    addPlan,
    getPlanDetails,
    updatePlan,
    getOETemplates,
    enrichPlan,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import orchestrationEngineVisualizerHelperV2, {
    ACTION_SIDEBAR_TABS,
    STATE_TYPES,
    FIGURE_TYPES,
    SIDEBAR_WIDTHS,
    TEMPLATE_TYPES,
    toBeOverriddenTemplateString,
} from '@/__new__/features/orchestrationengine/common/orchestrationEngineVisualizerHelperV2';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import importMonacoHelper from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { TranslateResult } from 'vue-i18n';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import isEqual from 'lodash/isEqual';

const CANVAS_ID = 'newPlanVisualizerID';

// helper for error "Property 'monaco' does not exist on type 'Window & typeof globalThis'"
declare const window: any;

interface ToolbarAction {
    labelKey: string;
    icon: string;
    actionHandler: () => void;
}

interface EndBlockPosition {
    endLineNumber: number;
    endColumn: number;
}

interface ArrowsCount {
    name: string;
    number: number;
}

interface ResultOfModal {
    addingNewStateWithinChoices?: boolean;
    defaultStateFromModal?: string;
    nextStatesFromModal?: string[];
    selectedTypeFromModal?: string;
    statesWithConditionsFromModal?: any[];
}

interface JsonUpdatedData {
    canvas?: any;
    updatedJSON?: any;
}

/* eslint-disable camelcase */
interface PlanObject {
    plan_id?: string;
    start_at?: string;
    states?: any;
    plan_owner?: any;
    name?: any;
}

interface PlanDefinition {
    name?: string;
    plan_id?: string;
    plan_owner?: string;
    start_at?: string;
    states?: any;
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
interface JsonInput {
    states?: Record<string, any>;
    start_at?: string;
    name?: string;
    plan_id?: string;
    plan_owner?: string;
}
/* eslint-enable camelcase */

interface SidebarTabOption {
    id: number;
    name: TranslateResult;
}

type GroupedTemplates = {
    [templateType: string]: any[];
};

class MissingKeyException {
    static staticType = 'missingKey';

    constructor(message: string) {
        this.message = message;
        this.type = MissingKeyException.staticType;
    }

    message: string;

    type: string;
}

export default {
    name: 'PlanEditor',
    components: {
        AppHeader,
        AppTooltip,
        AppButton,
        IconButton,
        DiagramUpdateModal,
        SearchBox,
    },
    mixins: [supportButtonMixin],
    props: {},
    data() {
        return {
            inputJsonString: '' as string,
            groupedTemplates: {} as GroupedTemplates,
            groupedTemplatesToDisplay: {} as GroupedTemplates,
            version: 1 as number,
            canvas: null as any,
            editor: null as any,
            isCodeHighlightEnabled: true as boolean,
            actionButtonsEnabled: false as boolean,
            selectedTab: null as { content: any; name: string } | null,
            actionsSidebarWidth: SIDEBAR_WIDTHS.ACTIONS_SIDEBAR_COLLAPSED as string,
            actionsSidebarCollapsed: true as boolean,
            actionsSidebarTabOptions: [] as SidebarTabOption[],
            actionsSidebarTabId: ACTION_SIDEBAR_TABS.TEMPLATES as number,
            definitionSidebarCollapsed: true as boolean,
            definitionSidebarWidth: SIDEBAR_WIDTHS.DEFINITION_SIDEBAR_COLLAPSED as string,
            isHold: false as boolean,
            isInvisible: false as boolean,
            latestResolvedPlanJSON: '' as string,
            isFirstIterationForEnrichPlan: true as boolean,
            searchQuery: '' as string,
            updateLoopEnabled: false as boolean,
            updateChoiceEnabled: false as boolean,
            updateCatchEnabled: false as boolean,
            numberOfStates: 0 as number,

            // Definition sidebar resizable
            resizingDefinitionSidebar: false as boolean,
            startXDefinitionSidebar: 0 as number,
            startWidthDefinitionSidebar: 0 as number,

            // Diagram update modal
            showModalForDiagramUpdate: false as boolean,
            addingNewStateWithinChoices: false as boolean,
            beforeUpdateNumberOfArrowsFromState: [],
            beforeUpdateAllArrowsFromDiagram: [],
            connectedToStates: [] as string[],
            optionsForNextStates: [],
            stateForUpdate: '',
            jsonInput: '',
            stateType: '',
            selectedLoopStates: [] as any,
            selectedStatesForEdit: [] as any,
            selectedDefaultStateForChoiceEdit: '' as string,

            toolbarActions: {} as { [key: string]: ToolbarAction },
            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
            CANVAS_ID: CANVAS_ID as any,
            TOOLTIP_POSITION: typeof TOOLTIP_POSITION,
            PLURALIZATION: PLURALIZATION as any,
            ACTION_SIDEBAR_TABS: ACTION_SIDEBAR_TABS as any,
        };
    },
    computed: {
        actionLoopLabel(): TranslateResult {
            return this.updateLoopEnabled
                ? this.$i18n.t('orchestrationEngine.updateDiagramModal.editLoop')
                : this.$i18n.t('orchestrationEngine.updateDiagramModal.addLoop');
        },
        actionChoiceLabel(): TranslateResult {
            return this.updateChoiceEnabled
                ? this.$i18n.t('orchestrationEngine.editChoice')
                : this.$i18n.t('orchestrationEngine.addChoice');
        },
        actionCatchLabel(): TranslateResult {
            return this.updateCatchEnabled
                ? this.$i18n.t('orchestrationEngine.editCatch')
                : this.$i18n.t('orchestrationEngine.addCatch');
        },
        isLoopActionButtonEnabled(): boolean {
            return this.actionButtonsEnabled && !this.updateCatchEnabled && !this.updateChoiceEnabled;
        },
        isChoiceActionButtonEnabled(): boolean {
            return (
                this.actionButtonsEnabled &&
                !this.updateLoopEnabled &&
                !this.updateCatchEnabled &&
                this.numberOfStates >= 3
            );
        },
        isCatchActionButtonEnabled(): boolean {
            return this.actionButtonsEnabled && !this.updateLoopEnabled && !this.updateChoiceEnabled;
        },
        pageTitle(): TranslateResult {
            return this.$route.params.id
                ? this.$i18n.t('orchestrationEngine.editPlan')
                : this.$i18n.t('orchestrationEngine.addNewPlan');
        },
        isTabInHold(): boolean {
            return Boolean(this.selectedTab && this.isHold);
        },
        isTabInvisible(): boolean {
            return Boolean(this.selectedTab && this.isInvisible);
        },
        sortedTemplates(): string[] {
            const types = Object.keys(this.groupedTemplatesToDisplay);
            return types.sort((a, b) => {
                if (a === TEMPLATE_TYPES.Other) return 1;
                if (b === TEMPLATE_TYPES.Other) return -1;
                return a.localeCompare(b);
            });
        },
    },
    watch: {
        searchQuery: {
            handler(val) {
                const filteredTemplates = Object.keys(this.groupedTemplates).reduce((result: any, type: string) => {
                    const templates = this.groupedTemplates[type].filter((template: any) =>
                        template.name.toLowerCase().includes(val.trim()),
                    );
                    if (templates.length > 0) {
                        result[type] = templates;
                    }
                    return result;
                }, {});

                this.groupedTemplatesToDisplay = filteredTemplates;
            },
        },
    },
    async mounted() {
        try {
            await importMonacoHelper.importMonaco();

            this.editor = window.monaco.editor.create(document.getElementById('planEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
            });

            this.editor.getModel().updateOptions({ tabSize: 2 });

            this.editor.onDidChangeModelContent(() => {
                this.inputJsonString = this.editor.getValue();

                // Clear diagram if json is empty
                if (!this.inputJsonString && this.canvas) {
                    this.canvas.clear();
                }
            });

            // Triggers when focus is lost from monaco editor
            this.editor.onDidBlurEditorText(() => {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    // For the first iteration when latestResolvedPlanJSON is empty
                    if (!this.latestResolvedPlanJSON && this.isFirstIterationForEnrichPlan) {
                        this.latestResolvedPlanJSON = this.inputJsonString;
                        this.isFirstIterationForEnrichPlan = false;
                    }

                    if (this.inputJsonString && this.latestResolvedPlanJSON) {
                        try {
                            // Check latest resolved plan json and current input json are they same or not
                            const isSame = isEqual(
                                JSON.parse(this.inputJsonString),
                                JSON.parse(this.latestResolvedPlanJSON),
                            );

                            // If latest resolved plan json and current input json aren't same
                            // (user input something) then call enrich plan to resolve plan for diagram display
                            if (!isSame) {
                                this.onRenderDiagram();
                            }
                        } catch {
                            // do nothing
                        }
                    }
                }
            });
        } catch (e: any) {
            Sentry.captureException(e);
            this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
        }
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                this.actionsSidebarTabOptions = [
                    {
                        id: ACTION_SIDEBAR_TABS.TEMPLATES,
                        name: this.$i18n.tc('orchestrationEngine.templates.template', PLURALIZATION.PLURAL),
                    },
                    {
                        id: ACTION_SIDEBAR_TABS.ACTIONS,
                        name: this.$i18n.t('generic.actions'),
                    },
                ];

                this.toolbarActions = {
                    Undo: {
                        labelKey: 'orchestrationEngine.editor.undo',
                        icon: ICON_TYPES.UNDO,
                        actionHandler: () => {
                            this.editor.getModel().undo();
                        },
                    },
                    Redo: {
                        labelKey: 'orchestrationEngine.editor.redo',
                        icon: ICON_TYPES.REDO,
                        actionHandler: () => {
                            this.editor.getModel().redo();
                        },
                    },
                    FontZoomIn: {
                        labelKey: 'orchestrationEngine.editor.fontZoomIn',
                        icon: ICON_TYPES.FONT_ZOOM_IN,
                        actionHandler: () => {
                            this.editor.getAction('editor.action.fontZoomIn').run();
                        },
                    },
                    FontZoomOut: {
                        labelKey: 'orchestrationEngine.editor.fontZoomOut',
                        icon: ICON_TYPES.FONT_ZOOM_OUT,
                        actionHandler: () => {
                            this.editor.getAction('editor.action.fontZoomOut').run();
                        },
                    },
                    FontZoomReset: {
                        labelKey: 'orchestrationEngine.editor.fontZoomReset',
                        icon: ICON_TYPES.FONT_ZOOM_RESET,
                        actionHandler: () => {
                            this.editor.getAction('editor.action.fontZoomReset').run();
                        },
                    },
                    FoldAll: {
                        labelKey: 'orchestrationEngine.editor.foldAll',
                        icon: ICON_TYPES.ARROW_RIGHT,
                        actionHandler: () => {
                            this.editor.getAction('editor.foldAll').run();
                        },
                    },
                    UnfoldAll: {
                        labelKey: 'orchestrationEngine.editor.unfoldAll',
                        icon: ICON_TYPES.ARROW_DOWN,
                        actionHandler: () => {
                            this.editor.getAction('editor.unfoldAll').run();
                        },
                    },
                    FormatDocument: {
                        labelKey: 'orchestrationEngine.editor.formatCode',
                        icon: ICON_TYPES.FORMATTER,
                        actionHandler: () => {
                            this.editor.getAction('editor.action.formatDocument').run();
                        },
                    },
                    Find: {
                        labelKey: 'orchestrationEngine.editor.find',
                        icon: ICON_TYPES.SEARCH,
                        actionHandler: () => {
                            this.editor.getAction('actions.find').run();
                        },
                    },
                    Replace: {
                        labelKey: 'generic.replace',
                        icon: ICON_TYPES.REPLACE,
                        actionHandler: () => {
                            this.editor.getAction('editor.action.startFindReplaceAction').run();
                        },
                    },
                };

                const templates = await getOETemplates();
                const templateOptions = Object.values(templates.data.orchestration_template_by_id).map(
                    (template: { data: any }) => template.data,
                );

                this.groupedTemplatesToDisplay = templateOptions.reduce((result, obj) => {
                    const type = obj.type || TEMPLATE_TYPES.Other;
                    if (!result[type]) {
                        result[type] = [];
                    }
                    result[type].push(obj);
                    return result;
                }, {});

                // Copied groupedTemplatesToDisplay as a helper for search when user delete search query
                this.groupedTemplates = this.groupedTemplatesToDisplay;

                if (this.$route.params.id) {
                    // API call to get plan details with resolved templates for diagram render
                    const resultForDiagram = await getPlanDetails({ id: this.$route.params.id });

                    // API call to get plan details with unresolved templates for displaying it within JSON editor
                    const resultForJSONEditor = await getPlanDetails({
                        id: this.$route.params.id,
                        dontEnrichWithTemplates: true,
                    });

                    /* eslint-disable camelcase */
                    const diagramData =
                        resultForDiagram?.data?.orchestration_plan_by_id?.[this.$route.params.id]?.data || {};

                    const dataJSON =
                        resultForJSONEditor?.data?.orchestration_plan_by_id?.[this.$route.params.id]?.data || {};
                    /* eslint-enable camelcase */

                    this.renderDiagram(diagramData);

                    this.inputJsonString = JSON.stringify(dataJSON, null, '  ');

                    if (this.editor) {
                        this.editor.setValue(this.inputJsonString);
                    }

                    // eslint-disable-next-line camelcase
                    this.version =
                        resultForDiagram?.data?.orchestration_plan_by_id?.[this.$route.params.id]?.version || 1;
                }
            },
            {
                errorHandler: () => {
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                },
            },
        );
    },
    validations: {
        inputJsonString: {
            required,
            isJsonValid: value => {
                try {
                    JSON.parse(value);
                    return true;
                } catch (e) {
                    return false;
                }
            },
        },
    },
    methods: {
        validate(): void {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
        checkPlanErrors(planObject: PlanObject): void {
            if (!planObject.plan_id) {
                throw new MissingKeyException(this.$i18n.t('orchestrationEngine.alerts.missingPlanIdKey'));
            }
            if (!planObject.start_at) {
                throw new MissingKeyException(this.$i18n.t('orchestrationEngine.alerts.missingStartAtKey'));
            }
            if (!planObject.states) {
                throw new MissingKeyException(this.$i18n.t('orchestrationEngine.alerts.missingStatesKey'));
            }
            if (!planObject.plan_owner) {
                throw new MissingKeyException(this.$i18n.t('orchestrationEngine.alerts.missingPlanOwnerIdKey'));
            }
            if (!planObject.name) {
                throw new MissingKeyException(this.$i18n.t('orchestrationEngine.alerts.missingNameKey'));
            }
        },
        buildStatesErrorList(key: string, statesList: any): void {
            return statesList.reduce((acc, state, i, { length }) => {
                let localAcc = `${acc} ${state}`;
                if (i < length - 1) {
                    localAcc += ',';
                }
                return localAcc;
            }, ` ${key}: `);
        },
        async onSave(): Promise<any> {
            // Validate model
            this.validate();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();

                    const jsonAsObject = JSON.parse(this.inputJsonString);

                    this.checkPlanErrors(jsonAsObject);

                    if (this.$route.params.id) {
                        await updatePlan(this.version, true, jsonAsObject);

                        // Refresh page to stay on editor page
                        setTimeout(() => {
                            this.$router.go();
                        }, 1000);
                    } else {
                        await addPlan(jsonAsObject);

                        // Redirect to added plan page
                        this.$router.push({
                            name: RouteNames.PLAN_EDITOR,
                            params: {
                                id: jsonAsObject.name,
                                companyId: this.$route.params.companyId,
                            },
                        });
                    }

                    this.$Progress.finish();
                } catch (error: any) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    let alertMsg = this.$i18n.t('alertMessage.somethingWentWrong');
                    if (error?.type === MissingKeyException.staticType) {
                        alertMsg = error.message;
                    } else if (error?.response?.data?.msg) {
                        alertMsg = error.response.data.msg;
                    } else if (error?.response?.data?.code === 2) {
                        alertMsg = this.$i18n.t('orchestrationEngine.alerts.invalidPlan');
                    } else if (error?.response?.data?.code === 33) {
                        alertMsg = this.$i18n.t('orchestrationEngine.alerts.planContains');
                        // eslint-disable-next-line camelcase
                        const planErrors = error?.response?.data?.plan_errors;
                        if (planErrors) {
                            let hasUndefinedStates = false;
                            if (planErrors.undefined_states && Array.isArray(planErrors.undefined_states)) {
                                alertMsg += this.buildStatesErrorList(
                                    this.$i18n.t('orchestrationEngine.alerts.undefinedStates').toLowerCase(),
                                    planErrors.undefined_states,
                                );
                                hasUndefinedStates = true;
                            }
                            if (planErrors.unreachable_states && Array.isArray(planErrors.unreachable_states)) {
                                if (hasUndefinedStates) alertMsg += ' and';
                                alertMsg += this.buildStatesErrorList(
                                    this.$i18n.t('orchestrationEngine.alerts.unreachableStates').toLowerCase(),
                                    planErrors.unreachable_states,
                                );
                            }
                        }
                        alertMsg += '.';
                    }
                    if (alertMsg === this.$i18n.t('alertMessage.somethingWentWrong')) {
                        this.showSupportAlert(alertMsg, ALERT_TYPES.error);
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: alertMsg,
                        });
                    }
                }
            }
        },
        onRenderDiagram(): Promise<any> {
            this.$withLoadingSpinner(async () => {
                try {
                    this.$Progress.start();
                    const result = await enrichPlan(JSON.parse(this.inputJsonString));

                    const diagramData = result?.data?.plan || {};
                    this.inputJsonString = JSON.stringify(diagramData, null, '  ');

                    // Set latest resolved plan json in order to compare it later
                    this.latestResolvedPlanJSON = this.inputJsonString;

                    this.inputHandler();

                    this.$Progress.finish();
                } catch {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.alerts.somethingWentWrongWithRenderDiagram'),
                    });

                    // clear diagram
                    if (this.canvas) this.canvas.clear();
                }
            });
        },
        appendTemplate(text: string): void {
            if (this.editor) {
                const selection = this.editor.getSelection();
                this.editor.executeEdits('template', [{ range: selection, text, forceMoveMarkers: true }]);
                this.editor.getAction('editor.action.formatDocument').run();
            }
        },
        onTemplateInput(value: { content: any; name: string; type: string }): void {
            if (value?.type === TEMPLATE_TYPES.State) {
                let jsonInput: JsonInput = {};

                if (this.inputJsonString) {
                    jsonInput = JSON.parse(this.inputJsonString);

                    // if states key exists in current json then just add new state within it
                    if (jsonInput?.states) {
                        // case when state already exists with same name within states
                        if (jsonInput.states[value.name]) {
                            let number = 0;
                            const stateNames = Object.keys(jsonInput.states);

                            for (const name of stateNames) {
                                if (name.includes(value.name)) {
                                    number += 1;
                                }
                            }
                            // add _ and number after name in order to differentiate states
                            jsonInput.states = {
                                ...jsonInput.states,
                                [`${value.name}_${number}`]: value.content,
                            };
                        } else {
                            jsonInput.states = {
                                ...jsonInput.states,
                                [value.name]: value.content,
                            };
                        }
                    } else {
                        // if states key doesn't exist then create states key and add new state within it
                        jsonInput.states = {
                            [value.name]: value.content,
                        };
                    }
                } else {
                    // if json input is empty create all required fields for diagram render and saving plan
                    // and insert state within states with template value
                    jsonInput = {
                        states: { [value.name]: value.content },
                        start_at: value.name,
                        name: toBeOverriddenTemplateString,
                        plan_id: toBeOverriddenTemplateString,
                        plan_owner: toBeOverriddenTemplateString,
                    };
                }

                // set new value
                this.editor.setValue(JSON.stringify(jsonInput));

                // format Json
                this.editor.getAction('editor.action.formatDocument').run();

                // render diagram
                this.onRenderDiagram();
            } else {
                // if string is an empty we won't add new line
                let stringToAppend = '';
                if (this.inputJsonString) {
                    stringToAppend = `,\n${JSON.stringify(value.content, null, '  ')}`;
                } else {
                    stringToAppend = JSON.stringify(value.content, null, '  ');
                }

                this.appendTemplate(stringToAppend);
            }
        },
        inputHandler(): void {
            this.callRerender(this.inputJsonString, this.renderDiagram);
        },
        renderDiagram(planDefinition: PlanDefinition): void {
            this.$nextTick(() => {
                // eslint-disable-next-line camelcase
                if (
                    planDefinition &&
                    planDefinition?.start_at &&
                    typeof planDefinition.start_at === 'string' &&
                    planDefinition?.states &&
                    typeof planDefinition.states === 'object' &&
                    Object.prototype.hasOwnProperty.call(planDefinition.states, planDefinition.start_at)
                ) {
                    this.canvas = orchestrationEngineVisualizerHelperV2.drawPlanDiagram(
                        CANVAS_ID,
                        this.canvas,
                        planDefinition,
                        planDefinition.start_at,
                        orchestrationEngineVisualizerHelperV2.defaultSetup,
                        this.handleCanvasClick,
                    );
                } else if (this.canvas) this.canvas.clear();
            });
        },
        callRerender(inputJsonString: any, renderDiagram: any): void {
            try {
                const parsedInputJSON = JSON.parse(inputJsonString);
                renderDiagram.call(this, parsedInputJSON);
            } catch (e) {
                renderDiagram.call(this, {});
                // ToDo: Add check to define if we get an error connected with user input or other
            }
        },
        handleJsonUpdateBasedOnDiagram({ updatedJSON, canvas }: JsonUpdatedData): void {
            this.jsonInput = updatedJSON;
            const { content } = this.jsonInput;

            const {
                numberOfArrowsFromEachState: afterUpdateNumberOfArrowsFromState,
                figuresNames,
                arrowsFromDiagram,
            } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);

            // Compare arrows before and after change on diagram
            const arrowsAdded = this.checkIsArrowAdded(
                this.beforeUpdateNumberOfArrowsFromState,
                afterUpdateNumberOfArrowsFromState,
            );

            if (arrowsAdded.length) {
                const { name } = arrowsAdded[0];
                const typeOfState = content?.states[name]?.type;

                const targetStatesFromState = [
                    ...new Set(
                        arrowsFromDiagram.filter(arrow => arrow.sourceName === name).map(state => state.targetName),
                    ),
                ];

                // Helper to track does new connected state already exists within choice
                let differenceWithChoices = [];
                if (content?.states[name]?.choices && content?.states[name]?.default) {
                    const choiceStateConnectedTo = [
                        ...content.states[name].choices.map(choice => choice.next),
                        content.states[name].default,
                    ];

                    differenceWithChoices = targetStatesFromState.filter(
                        state => !choiceStateConnectedTo.includes(state),
                    );
                }

                // Show modal when state is connected to two states and it is not type of loop, don't have catch or choices
                if (
                    targetStatesFromState.length === 2 &&
                    typeOfState !== STATE_TYPES.LoopV2 &&
                    !content?.states[name]?.catch &&
                    !content?.states[name]?.choices
                ) {
                    // set data which will be used for modal
                    this.stateForUpdate = name;
                    this.canvas = canvas;
                    this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
                    this.connectedToStates = targetStatesFromState;
                    this.stateType = STATE_TYPES.Choice;

                    this.showModalForDiagramUpdate = true;
                } else if (
                    targetStatesFromState.length > 2 &&
                    typeOfState === STATE_TYPES.Choice &&
                    differenceWithChoices.length
                ) {
                    this.handleUpdateModalForNewAddedChoice(arrowsFromDiagram, canvas, name);
                } else {
                    // Set number of arrows after change as beforeUpdateNumberOfArrowsFromState to compare with it
                    // next time this function is called
                    this.beforeUpdateNumberOfArrowsFromState = afterUpdateNumberOfArrowsFromState;

                    // updating JSON based on final look of diagram
                    const input = JSON.stringify(this.jsonInput.content, null, '  ');
                    this.editor.setValue(input);
                    this.onRenderDiagram();
                }
            }
        },
        handleUpdateModalForNewAddedChoice(arrowsFromDiagram: any[], canvas: any, name: string): void {
            const confirmButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                id: 'confirm-adding-new-state',
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('orchestrationEngine.updateDiagramModal.areYouSureAddNewStateWithinConditions'),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });

            this.$eventBus.$on('alertClosed', () => {
                this.onRenderDiagram();
            });

            this.$eventBus.$once('buttonClicked', (id: string | number) => {
                if (id === confirmButton.id) {
                    this.showModalForDiagramUpdate = true;
                    this.addingNewStateWithinChoices = true;
                    this.stateType = STATE_TYPES.Choice;

                    // get names of target states from choice state before update of diagram
                    const targetNamesBeforeUpdate = this.beforeUpdateAllArrowsFromDiagram
                        .filter(arrow => arrow.sourceName === name)
                        .map(arrow => arrow.targetName);

                    // get names of target states from choice state after update of diagram
                    const targetNamesAfterUpdate = arrowsFromDiagram
                        .filter(arrow => arrow.sourceName === name)
                        .map(arrow => arrow.targetName);

                    // difference between two arrays will be new connected state
                    const newConnectedState = targetNamesAfterUpdate.filter(
                        targetName => !targetNamesBeforeUpdate.includes(targetName),
                    );

                    // Set data which will be used for modal
                    this.stateForUpdate = name;
                    this.canvas = canvas;
                    this.connectedToStates = newConnectedState;
                }
            });
        },
        handleCanvasClick(handleCanvasData: any): void {
            const { arrowsPerState, allArrows, figure, updatedJSON, canvas } = handleCanvasData;
            this.canvas = canvas;

            // check did user click on diagram, if so then make actions buttons visible based on clicked figure
            this.checkActionButtonsVisibility(figure, updatedJSON);

            // Getting arrows on every diagram render
            if (arrowsPerState) {
                this.beforeUpdateNumberOfArrowsFromState = arrowsPerState;
                this.beforeUpdateAllArrowsFromDiagram = allArrows;

                // returning to prevent further execution of function because the function in
                // this case is only called to get latest arrows from diagram when it is rendered
                return;
            }

            if (updatedJSON) {
                this.handleJsonUpdateBasedOnDiagram({
                    updatedJSON,
                    canvas,
                });
            }

            if (
                this.isCodeHighlightEnabled &&
                figure !== null &&
                (figure?.userData?.figureType === orchestrationEngineVisualizerHelperV2.FIGURE_TYPES.Block ||
                    figure?.userData?.figureType === orchestrationEngineVisualizerHelperV2.FIGURE_TYPES.Label)
            ) {
                if (this.editor) {
                    const searchResult = this.editor.getModel().findMatches(`"${figure?.userData?.key}":`);
                    const range = searchResult?.[0]?.range;

                    if (range) {
                        const position = this.getEndOfCodeBlockPosition(range.endLineNumber, range.endColumn);

                        this.editor.setSelection(
                            new window.monaco.Selection(
                                range.startLineNumber,
                                range.startColumn,
                                position.endLineNumber,
                                position.endColumn,
                            ),
                        );
                        this.editor.revealLineInCenter(range.startLineNumber);
                    }
                }
            }
        },
        onSaveModal(resultOfModal: ResultOfModal): void {
            this.showModalForDiagramUpdate = false;
            const { selectedTypeFromModal } = resultOfModal;

            this.jsonInput = orchestrationEngineVisualizerHelperV2.updateJsonBasedOnModalResult({
                canvas: this.canvas,
                planDefinition: this.jsonInput.content,
                modalStateName: this.stateForUpdate,
                selectedTypeFromModal,
                resultOfModal,
            });

            const input = JSON.stringify(this.jsonInput.content, null, '  ');
            this.editor.setValue(input);
            this.onRenderDiagram();

            this.onCloseDiagramUpdateModal();
        },
        checkIsArrowAdded(arrowsBeforeUpdate: ArrowsCount[], arrowsAfterUpdate: ArrowsCount[]): ArrowsCount[] {
            return arrowsAfterUpdate.filter(arrowAfter => {
                const checkingBlock = arrowsBeforeUpdate.filter(arrowBefore => arrowAfter.name === arrowBefore.name);

                return checkingBlock.length && arrowAfter.number !== checkingBlock[0].number ? checkingBlock : false;
            });
        },
        enableActionButtonsAfterEventOnDiagram(stateForUpdate: string, updatedJSON: any): void {
            this.actionsSidebarWidth = SIDEBAR_WIDTHS.ACTIONS_SIDEBAR_COLLAPSED;
            this.actionsSidebarCollapsed = true;
            this.actionsSidebarTabId = ACTION_SIDEBAR_TABS.ACTIONS;
            this.stateForUpdate = stateForUpdate;

            if (updatedJSON?.content?.states) {
                this.numberOfStates = Object.keys(updatedJSON.content.states).length;

                if (this.numberOfStates >= 2) {
                    this.actionButtonsEnabled = true;
                }

                if (this.actionButtonsEnabled) {
                    const { states } = updatedJSON.content;

                    // Logic if state already have loop in order to have edit loop feature
                    if (states[this.stateForUpdate]?.type === STATE_TYPES.LoopV2) {
                        const loopStates: string[] = [];
                        this.updateLoopEnabled = true;
                        let currentState: string = this.stateForUpdate;

                        while (currentState) {
                            loopStates.push(currentState);

                            if (states[currentState] && (states[currentState].next || states[currentState].start_at)) {
                                if (states[currentState].start_at) {
                                    currentState = states[currentState].start_at;
                                } else {
                                    currentState = states[currentState].next;
                                }

                                if (loopStates.includes(currentState)) {
                                    break;
                                }
                            } else {
                                break;
                            }
                        }
                        const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);

                        // Set data for modal
                        this.stateType = STATE_TYPES.LoopV2;
                        this.optionsForNextStates = figuresNames.filter(state => !loopStates.includes(state));
                        this.selectedLoopStates = loopStates.filter(state => state !== this.stateForUpdate);
                    } else if (
                        states[this.stateForUpdate]?.type === STATE_TYPES.Choice &&
                        states[this.stateForUpdate]?.choices
                    ) {
                        this.updateChoiceEnabled = true;
                        const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);

                        // Data for modal
                        this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
                        this.selectedDefaultStateForChoiceEdit = states[this.stateForUpdate]?.default;
                        this.connectedToStates = [
                            ...states[this.stateForUpdate]?.choices.map(choice => choice.next),
                            this.selectedDefaultStateForChoiceEdit,
                        ];
                        this.stateType = STATE_TYPES.Choice;
                        this.selectedStatesForEdit = states[this.stateForUpdate].choices;
                    } else if (states[this.stateForUpdate]?.catch) {
                        this.updateCatchEnabled = true;
                        const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);

                        // Data for modal
                        this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
                        this.stateType = STATE_TYPES.Catch;
                        this.connectedToStates = states[this.stateForUpdate]?.catch.map(cx => cx.next);
                        this.selectedStatesForEdit = states[this.stateForUpdate].catch;
                    }
                }
            }
        },
        disableActionButtonsAfterEventOnDiagram(): void {
            this.actionButtonsEnabled = false;
            this.updateLoopEnabled = false;
            this.updateChoiceEnabled = false;
            this.updateCatchEnabled = false;
            this.numberOfStates = 0;
        },
        checkActionButtonsVisibility(figure: any, updatedJSON: any): void {
            if (
                figure &&
                figure?.userData &&
                (figure?.userData?.figureType === FIGURE_TYPES.Block ||
                    figure?.userData?.figureType === FIGURE_TYPES.Label) &&
                figure?.userData?.key
            ) {
                this.enableActionButtonsAfterEventOnDiagram(figure.userData.key, updatedJSON);
            } else {
                this.disableActionButtonsAfterEventOnDiagram();
            }
        },
        openChoiceModal() {
            this.showModalForDiagramUpdate = true;
            const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);
            this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
            this.stateType = STATE_TYPES.Choice;
        },
        openCatchModal(): void {
            this.showModalForDiagramUpdate = true;
            const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);
            this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
            this.addingNewStateWithinChoices = false;
            this.stateType = STATE_TYPES.Catch;
        },
        openLoopModal(): void {
            this.showModalForDiagramUpdate = true;
            const { figuresNames } = orchestrationEngineVisualizerHelperV2.getDataFromDiagram(this.canvas);
            this.stateType = STATE_TYPES.LoopV2;

            if (!this.updateLoopEnabled) {
                this.optionsForNextStates = figuresNames.filter(state => state !== this.stateForUpdate);
            }
        },
        onCloseDiagramUpdateModal(): void {
            this.showModalForDiagramUpdate = false;
            this.addingNewStateWithinChoices = false;
            this.selectedLoopStates = [];
            this.selectedStatesForEdit = [];
            this.optionsForNextStates = [];
            this.connectedToStates = [];
            this.selectedDefaultStateForChoiceEdit = '';

            // Render diagram when user clicks close modal in order diagram be up to date with json
            this.onRenderDiagram();
        },
        redirectToOrchestrationListPage(): void {
            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_LIST,
                params: { companyId: this.$route.params.companyId },
            });
        },
        getEndOfCodeBlockPosition(startLineNumber: number, startColumn: number): EndBlockPosition {
            const findResults = [];
            findResults.push(
                ...this.editor
                    .getModel()
                    .findMatches('{')
                    .map(el => {
                        el.value = '{';
                        return el;
                    }),
            );
            findResults.push(
                ...this.editor
                    .getModel()
                    .findMatches('}')
                    .map(el => {
                        el.value = '}';
                        return el;
                    }),
            );

            const bracketsByLine = {};
            for (const bracket of findResults) {
                if (!bracketsByLine[bracket.range.endLineNumber]) {
                    bracketsByLine[bracket.range.endLineNumber] = [];
                }
                bracketsByLine[bracket.range.endLineNumber].push({
                    value: bracket.value,
                    lineNumber: bracket.range.endLineNumber,
                    column: bracket.range.endColumn,
                });
            }

            const bracketsArray = [];
            // eslint-disable-next-line guard-for-in
            for (const bracketsByLineKey in bracketsByLine) {
                bracketsArray.push(...bracketsByLine[bracketsByLineKey].sort((a, b) => a.column - b.column));
            }

            const startBracketIndex = bracketsArray.findIndex(
                el => el.lineNumber >= startLineNumber && el.column > startColumn,
            );

            let depth = 1;
            let lastIndex = -1;
            for (let i = startBracketIndex + 1; i < bracketsArray.length; i += 1) {
                if (bracketsArray[i].value === '{') {
                    depth += 1;
                }
                if (bracketsArray[i].value === '}') {
                    depth -= 1;
                }
                if (depth === 0) {
                    lastIndex = i;
                    i = bracketsArray.length;
                }
            }

            if (lastIndex === -1) {
                return {
                    endLineNumber: startLineNumber,
                    endColumn: startColumn,
                };
            }
            return {
                endLineNumber: bracketsArray[lastIndex].lineNumber,
                endColumn: bracketsArray[lastIndex].column,
            };
        },
        onDefinitionSidebarActionClick(): void {
            if (this.definitionSidebarCollapsed) {
                this.$refs.definitionSidebar.style.width = SIDEBAR_WIDTHS.CLOSED_SIDEBAR;
                this.definitionSidebarCollapsed = false;
            } else {
                this.$refs.definitionSidebar.style.width = SIDEBAR_WIDTHS.DEFINITION_SIDEBAR_COLLAPSED;

                this.definitionSidebarCollapsed = true;
            }
        },
        onActionsSidebarActionClick(): void {
            if (this.actionsSidebarCollapsed) {
                this.actionsSidebarWidth = SIDEBAR_WIDTHS.CLOSED_SIDEBAR;
                this.actionsSidebarCollapsed = false;
            } else {
                this.actionsSidebarWidth = SIDEBAR_WIDTHS.ACTIONS_SIDEBAR_COLLAPSED;
                this.actionsSidebarCollapsed = true;
            }
        },
        selectTab(id: number): void {
            this.actionsSidebarTabId = id;
        },
        onZoomIn(): void {
            if (this.canvas) orchestrationEngineVisualizerHelperV2.canvasZoomIn(this.canvas);
        },
        onZoomOut(): void {
            if (this.canvas) orchestrationEngineVisualizerHelperV2.canvasZoomOut(this.canvas);
        },
        dragStart(template: any): void {
            this.selectedTab = template;
            this.isHold = true;
            setTimeout(() => {
                this.isInvisible = true;
            }, 0);
            this.definitionSidebarWidth = SIDEBAR_WIDTHS.DEFINITION_SIDEBAR_COLLAPSED;
            this.definitionSidebarCollapsed = true;
        },
        dragEnd(): void {
            this.isHold = false;
            this.isInvisible = false;
            this.selectedTab = null;
        },
        dragOver(e: any): void {
            e.preventDefault();
        },
        dropElement(): void {
            this.onTemplateInput(this.selectedTab);
            this.selectedTab = null;
        },
        isTemplateInHold(name: string) {
            return this.isTabInHold && this.selectedTab?.name === name ? 'hold' : '';
        },
        isTemplateInvisible(name: string) {
            return this.isTabInvisible && this.selectedTab?.name === name ? 'invisible' : '';
        },
        startResizingDefinitionSidebar(event: { clientX: number }): void {
            this.resizingDefinitionSidebar = true;
            this.startXDefinitionSidebar = event.clientX;
            this.startWidthDefinitionSidebar = this?.$refs?.definitionSidebar?.offsetWidth;
            document.addEventListener('mousemove', this.resizeDefinitionSidebar);
            document.addEventListener('mouseup', this.stopResizingDefinitionSidebar);
        },
        resizeDefinitionSidebar(event: { clientX: number }): void {
            if (this.resizingDefinitionSidebar && this.definitionSidebarCollapsed && this.$refs?.definitionSidebar) {
                const screenWidth = window.innerWidth;
                const newWidth = screenWidth - event.clientX;
                this.$refs.definitionSidebar.style.width = `${newWidth}px`;
            }
        },
        stopResizingDefinitionSidebar(): void {
            this.resizingDefinitionSidebar = false;
            document.removeEventListener('mousemove', this.resizeDefinitionSidebar);
            document.removeEventListener('mouseup', this.stopResizingDefinitionSidebar);
        },
    },
};
