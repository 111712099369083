














import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import {
    MAP_BUCKET_TYPE_TO_OPTION_VALUES,
    BUCKET_TYPE,
} from '@/__new__/services/dno/charging/common/bucketTypeConditionHelper';

export default Vue.extend({
    name: 'BucketTypeValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getValue(val: BUCKET_TYPE): TranslateResult {
            const data = MAP_BUCKET_TYPE_TO_OPTION_VALUES.filter(el => el.id === val);
            return data?.[0]?.label || '';
        },
    },
});
