var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AbstractTableTile',{attrs:{"entities":_vm.transactionHistoriesFormatted,"columnsData":_vm.columnsData,"apiResponse":_vm.transactionHistoryApiResponse,"entityKey":"transactionId"},scopedSlots:_vm._u([{key:"connectedAccount",fn:function(ref){
var entity = ref.entity;
return [(entity.connectedAccount)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                        name: _vm.RouteNames.CCS_ACCOUNT_PAGE,
                        params: {
                            id: entity.connectedAccount,
                        },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.connectedAccount)+" ")])],1):_vm._e()]}},{key:"state",fn:function(ref){
                    var entity = ref.entity;
return [_c('TransactionHistoryStatusIndicator',{attrs:{"status":entity.state}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }