var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableFiltersRenderless',{attrs:{"entities":_vm.outageData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var allFilters = ref.allFilters;
            var filteredEntities = ref.filteredEntities;
            var onFilterAdded = ref.onFilterAdded;
            var removeFilter = ref.removeFilter;
            var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"d-flex position-relative"},[_c('h3',{staticClass:"lf-subtitle"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.outageHistory'))+" ")]),_c('div',{staticClass:"d-flex ml-auto"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.apiResponse,"title":_vm.$i18n.t('customerCare.outageHistory')}}),_c('IconButton',{staticClass:"refresh-btn",attrs:{"label":_vm.$i18n.t('generic.refresh'),"icon":_vm.ICON_TYPES.REFRESH},on:{"iconClick":_vm.fetchOutagesData}}),_c('FilterTable',{attrs:{"columns":_vm.columnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{staticClass:"mb-3",attrs:{"columnsData":_vm.columnsData,"entities":filteredEntities,"selectedEntityId":_vm.selectedEntity.id,"innerSearchQuery":_vm.searchQuery,"isDefaultHoverEnabled":true,"isPaginationEnabled":true,"isSearchEnabled":true,"canSelectColumns":true,"nestedRows":true,"newDesign":true,"noEntitiesMockText":_vm.$i18n.t('customerCare.notesSection.noEntitiesMockText'),"tableKey":"user/outage-history"},on:{"selectedEntityModel":_vm.openSidebar},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
            var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.$localeLibrary.getFormattedDateAndTime(entity.startTime))+" ")]}},{key:"status",fn:function(ref){
            var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.status,"stateMap":_vm.OUTAGE_STATUS_TO_LABEL,"stateMapColor":_vm.OUTAGE_STATUS_TO_COLOR_MAP}})]}}],null,true)})],1)]}}])}),_c('AppAditionalSidebar',{on:{"input":_vm.closeSidebar},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('h2',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.userInformation.outageId'))+": "+_vm._s(_vm.selectedEntity.id)+" ")]),_vm._l((_vm.selectedEntity.nestedRows),function(nestedRow,i){return [_c('h3',{key:("term-" + i),staticClass:"heading-sm mb-0"},[_vm._v(" "+_vm._s(nestedRow.rowCreatedTime)+" ")]),_c('dl',{key:("data-" + i),staticClass:"mb-4"},[_c('dt',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$i18n.t('generic.status')))]),_c('dd',{staticClass:"mb-0"},[_c('EntityStatusIndicator',{attrs:{"status":nestedRow.rowStatus,"stateMap":_vm.OUTAGE_STATUS_TO_LABEL,"stateMapColor":_vm.OUTAGE_STATUS_TO_COLOR_MAP}})],1),_c('dt',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$i18n.t('customerCare.userInformation.outageReason')))]),_c('dd',{staticClass:"mb-0"},[_vm._v(_vm._s(nestedRow.rowReason || _vm.$i18n.t('generic.N/A')))]),_c('dt',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$i18n.t('generic.description')))]),_c('dd',{staticClass:"mb-0"},[_vm._v(_vm._s(nestedRow.rowDescription || _vm.$i18n.t('generic.N/A')))])])]})]},proxy:true}]),model:{value:(_vm.isSidebarVisible),callback:function ($$v) {_vm.isSidebarVisible=$$v},expression:"isSidebarVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }