<template>
    <div class="app-card">
        <div class="count">
            {{ countText }}
        </div>
        <div class="all-caps-text">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppCountCard',
    props: {
        value: {
            type: Number,
            required: true,
            default: 0,
        },
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        countText() {
            const digits = this.value.toString().length;
            const lookup = [
                { value: 1, symbol: '' },
                { value: 1e3, symbol: 'k' },
                { value: 1e6, symbol: 'M' },
                { value: 1e9, symbol: 'B' },
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            const item = lookup
                .slice()
                .reverse()
                .find(({ value }) => this.value >= value);
            return item ? (this.value / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.app-card {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
}

.count {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 2.25rem;
    color: $gray90;
    margin-bottom: 0.25rem;
}
</style>
