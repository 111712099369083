



























































































































































// COMPONENTS
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppDropdown from '@/components/partials/AppDropdown.vue';
// HELPERS
import { isTypeString } from '@/common/utils';
import { displayNaIfNotProvided } from '@/common/formatting';

export default {
    name: 'AppInfoBlock',

    components: {
        AppTooltip,
        AppDropdown,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        explanationText: {
            type: String,
            default: '',
        },
        /**
         * Array<Object>
         * @param name
         * @param value - [String, Array<String>, Array<Objects> with text and link (optional) fields]
         */
        fields: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            TOOLTIP_POSITION,
        };
    },

    methods: {
        isTypeString,
        displayNaIfNotProvided,
    },
};
