var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"no-outline display-flex justify-content-center align-items-center text-nowrap",class:[
        (_vm.buttonType + "-button"),
        _vm.alertType,
        { 'icon-left-button': _vm.iconType && !_vm.isIconRight },
        { 'icon-right-button': _vm.iconType && _vm.isIconRight },
        { 'small-button': _vm.isSmall },
        { clicked: _vm.clicked },
        { disabled: _vm.disabled || _vm.isLoading } ],attrs:{"title":_vm.tooltip,"disabled":_vm.disabled},on:{"click":function($event){return _vm.onClick($event)}}},[_vm._t("default",function(){return [(_vm.iconType && !_vm.isIconRight)?_c('AppIcon',{staticClass:"icon",class:{ 'spinning ': _vm.isLoading },attrs:{"type":_vm.isLoading ? _vm.BUTTON_SPINNERS[_vm.buttonType].icon : _vm.iconType,"color":_vm.isLoading ? _vm.BUTTON_SPINNERS[_vm.buttonType].color : _vm.iconColor}}):_vm._e(),_c('span',{staticClass:"heading-sm",class:{ 'opacity-0': _vm.isLoadingStateWithoutIcon }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),((_vm.iconType && _vm.isIconRight) || _vm.isLoadingStateWithoutIcon)?_c('AppIcon',{staticClass:"icon icon-right",class:{
                spinning: _vm.isLoading,
                'position-absolute': _vm.isLoadingStateWithoutIcon,
            },attrs:{"type":_vm.isLoading ? _vm.ICON_TYPES.LOADER : _vm.iconType,"color":_vm.isLoading ? _vm.ICON_COLORS.BLUE : _vm.iconColor}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }