
















































































import Vue from 'vue';

// Vuex
import { Modules } from '@/store/store';
import { mapGetters } from 'vuex';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import BulkUploadHistory from '@/__new__/features/resources/BulkUploadHistory.vue';
import BulkUploadHistorySidebar from '@/__new__/features/resources/BulkUploadHistorySidebar.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';

// mixins
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';

// HTTP
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
} from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import { getUploadHistory } from '@/__new__/services/dno/developerLineAuthorization/developerLineAuthorizationService';
import { getSignedURLForDownload } from '@/__new__/services/dno/ossmvne/http/ossmvne';

// Helpers
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import { GetSignedUrlParams } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { capitalize } from 'lodash';
import download from 'downloadjs';
import {
    FILE_UPLOAD_STATUS_TO_COLOR_MAP,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import { type TranslateResult } from 'vue-i18n';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/ossmvne/ossmvneHelper';

export default Vue.extend({
    name: 'UploadWps',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        BulkUploadHistory,
        BulkUploadHistorySidebar,
        DragDropFileUploader,
        FileUploaderModal,
    },
    mixins: [DownloadDetailsMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            FILE_UPLOAD_STATUS_TO_COLOR_MAP,
            ENTITY_TYPES,

            apiType: DEVICE_LINE_AUTH_API_TYPE.WPS_BULK as DEVICE_LINE_AUTH_API_TYPE,
            showErrorModal: false,
            failedLinesErrorMessage: [] as TranslateResult[],
            triggerUploadFiles: false,
            triggerClearPollers: false,
            numberOfFilesToUpload: 0,
            showUploadHistoryResults: false,
            uploadHistoryDataLoading: false,
            uploadHistory: [] as UploadedFileDetails[],
            uploadHistorySearchQuery: '',
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            selectedUploadHistory: undefined as UploadedFileDetails | undefined,
        };
    },
    computed: {
        configFileUploader() {
            return {
                getSignedURL: (params: GetSignedUrlParams) => getSignedURL(this.apiType, params),
                getEntityDetailsByCategory: (name: string, category: string, page: number, size: number) =>
                    getEntityDetailsByCategory(this.apiType, name, category, page, size),
                getEntityDetailsByName: (name: string) => getEntityDetailsByName(this.apiType, name),
                getRegisteredEntities: (page: number, size: number) => getRegisteredEntities(this.apiType, page, size),
                getSignedURLParams: (params: { fileName: string }) => ({
                    name: `${this.accountNameFormatted}-${this.accountData.id}-${params.fileName}`,
                }),
            };
        },
        canEndUserUploadFiles(): boolean {
            return !isViewEnabled('UploadWps') || !isUserAllowed('UploadWpsWrite');
        },
        enableUploadBtn(): boolean {
            return !this.numberOfFilesToUpload;
        },
        accountNameFormatted(): string {
            const accountName = this.accountData.name ?? '';
            return accountName.replace(/[^A-Za-z ]/g, '');
        },
        uploadHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ];
        },
        historySidebarData(): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: this.selectedUploadHistory?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: this.selectedUploadHistory?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: this.selectedUploadHistory?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: this.selectedUploadHistory?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.selectedUploadHistory?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(this.selectedUploadHistory?.eventCategoryCounters || {}).map(
                        ([status, count]) => ({
                            name: capitalize(String(status).replace(/_/g, ' ')),
                            value: `${count}/${this.selectedUploadHistory?.recordCount}`,
                        }),
                    ),
                },
            ];
        },
    },
    methods: {
        ...mapGetters(Modules.config, {
            accountData: 'getAccountData',
        }),
        updateAmountFilesUpload(numberOfFilesToUpload: number): void {
            this.numberOfFilesToUpload = numberOfFilesToUpload;
        },
        displayFailedLines(lines: any): void {
            this.failedLinesErrorMessage = [
                this.$i18n.tc(
                    'operator.numberManagement.error.failedLinesMessage',
                    lines.length > 1 ? PLURALIZATION.PLURAL : PLURALIZATION.SINGULAR,
                    {
                        n: lines,
                    },
                ),
            ];
            this.showErrorModal = true;
        },
        onBack(): void {
            this.triggerClearPollers = !this.triggerClearPollers;
            this.$router.go(-1);
        },
        onUpload(): void {
            this.triggerUploadFiles = !this.triggerUploadFiles;
        },
        async onLoadHistory() {
            this.showUploadHistoryResults = true;
            await this.$withProgressBar(
                async () => {
                    this.uploadHistoryDataLoading = true;
                    this.uploadHistory = await getUploadHistory(this.apiType);
                    this.uploadHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.uploadHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        onUploadHistorySelected(entity: UploadedFileDetails): void {
            this.selectedUploadHistory = entity;
        },
        async onDownloadFile() {
            if (!this.selectedUploadHistory) {
                return;
            }

            await this.$withLoadingSpinner(
                async () => {
                    const uploadedFilename = this.selectedUploadHistory?.entityName as string;
                    const signedUrlRes = await getSignedURLForDownload(DATAFLOW_API_TYPE.WPS_BULK, uploadedFilename);
                    const downloadUrl = signedUrlRes.data?.signed_entity?.url;

                    const fileRes = await fetch(downloadUrl);
                    if (!fileRes.ok) {
                        throw new Error(`Fetch to endpoint ${downloadUrl} to download ${uploadedFilename} failed.`);
                    }

                    const file = await fileRes.text();
                    const filename = this.selectedUploadHistory?.fileName;

                    download(file, filename, 'text/plain');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('qodNumberManagement.failedToDownloadFile'));
                    },
                },
            );
        },
        onDownloadTemplate(): void {
            this.downloadOffersCSV(
                {
                    fields: ['MSISDN', 'newWPS'],
                    data: [['7064097571', '50001000']],
                },
                'BulkUpdateWPS',
            );
        },
    },
});
