















































































































































// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

interface FeatureStateChangeEvent {
    keys: Array<{ viewId: string; permissionKey: string }>;
    value: boolean;
    permissionKey: string;
    view: any;
}

export default {
    name: 'ViewPermissionsTree',
    components: {
        AppIcon,
        AppCheckbox,
    },
    props: {
        view: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            isOpen: false,
            // Proxy imports
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    computed: {
        children() {
            return this.view.views;
        },
        numChildren() {
            return typeof this?.view?.views === 'object' && !Array.isArray(this.view.views)
                ? Object.values(this.view.views).length
                : 0;
        },
        permissions() {
            return this.view.permissions;
        },
        numPermissions() {
            return typeof this?.view?.permissions === 'object' && !Array.isArray(this.view.permissions)
                ? Object.values(this.view.permissions).length
                : 0;
        },
        isToggleChecked(): boolean {
            return this.isToggleAllChecked(this.view, false);
        },
    },
    methods: {
        emitFeatureStateChange(value: boolean, permissionKey: string) {
            if (value) {
                this.isOpen = true;
            }
            this.$emit('change', {
                keys: [
                    {
                        viewId: this.view.id,
                        permissionKey,
                    },
                ],
                value,
                permissionKey,
                view: this.view,
            });
        },
        toggleChildren(view: any, value: boolean) {
            if (view?.views && Object.keys(view.views).length > 0) {
                for (const innerView of Object.values(view.views)) {
                    this.toggleChildren(innerView, value);
                }
            }

            if (view.permissions?.length > 0) {
                for (const permission of view.permissions) {
                    this.$emit('change', {
                        keys: [
                            {
                                viewId: view.id,
                                permissionKey: permission.key,
                            },
                        ],
                        value,
                        permissionKey: permission.key,
                        view,
                    });
                }
            }
        },
        emitFeatureTreeChange(event: FeatureStateChangeEvent) {
            this.$emit('change', {
                keys: [
                    {
                        viewId: this.view.id,
                    },
                    ...event.keys,
                ],
                value: event.value,
                view: event.view,
                permissionKey: event.permissionKey,
            });
        },
        hasNestedPermissionsOrViews(view: any) {
            return view.permissions?.length > 0 || (view?.views && Object.keys(view.views).length > 0);
        },
        isToggleAllChecked(view: any, isInnerView: boolean): boolean {
            const toggleAll = [];

            if (view.permissions?.length > 0) {
                toggleAll[view.id] = view.permissions.every((item: any) => item.enabled);
            }

            if (isInnerView) {
                return toggleAll[view.id];
            }

            if (view.views) {
                for (const innerView of Object.values(view.views) as any) {
                    if (innerView.enabled) {
                        toggleAll[innerView.id] = this.isToggleAllChecked(innerView, true);
                    }
                }
            }

            return Object.values(toggleAll).every(Boolean);
        },
    },
};
