import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { chain, compact, difference, fromPairs, invert, map, omit, without } from 'lodash';
import { uuidV4 } from '@/common/utils';
import { getBeautifulBoolean } from '@/common/formatting';
import values from 'lodash/values';
import { CollapsibleListItemRow } from '@/common/AppCollapsibleListHelper';
import EventDataSource from '@/__new__/services/dno/events/models/EventDataSource';

export default class EventProp {
    id: string;
    name: string;
    doc: string;
    type: CDP_PROPERTY_TYPE;
    sourceType: CDP_PROPERTY_TYPE;
    mandatory: boolean;
    forbiddenToEdit: boolean;
    dataSource?: TranslateResult;
    isVisible?: boolean;
    rawSchema?: any;
    isNullable?: boolean;

    constructor(data: Partial<EventProp> = {}) {
        this.id = data.id || uuidV4();
        this.name = data.name || '';
        this.doc = data.doc || '';
        this.type = data.type as CDP_PROPERTY_TYPE;
        this.sourceType = data.sourceType as CDP_PROPERTY_TYPE;
        this.mandatory = data.mandatory || false;
        this.forbiddenToEdit = data.forbiddenToEdit || false;
        this.dataSource = data.dataSource || i18n.t('generic.none');
        this.isVisible = 'isVisible' in data ? data.isVisible : true;
        this.rawSchema = data.rawSchema;
        this.isNullable = data.isNullable;
    }
}

export enum CDP_PROPERTY_TYPE {
    TIMESTAMP = 'timestamp',
    STRING = 'string',
    DATE = 'date',
    BOOLEAN = 'boolean',
    INTEGER = 'integer',
    NUMBER = 'number',
    OBJECT = 'object',
    ARRAY = 'array',
    MIXED = 'mixed',
    INT4 = 'INT4',
    INT8 = 'INT8',
    FLOAT4 = 'FLOAT4',
    FLOAT8 = 'FLOAT8',
    VARCHAR = 'VARCHAR',
}

export const CDP_PROPERTY_TYPES = {
    TIMESTAMP: CDP_PROPERTY_TYPE.TIMESTAMP,
    STRING: CDP_PROPERTY_TYPE.STRING,
    DATE: CDP_PROPERTY_TYPE.DATE,
    BOOLEAN: CDP_PROPERTY_TYPE.BOOLEAN,
    INTEGER: CDP_PROPERTY_TYPE.INTEGER,
    NUMBER: CDP_PROPERTY_TYPE.NUMBER,
    OBJECT: CDP_PROPERTY_TYPE.OBJECT,
    ARRAY: CDP_PROPERTY_TYPE.ARRAY,
    MIXED: CDP_PROPERTY_TYPE.MIXED,
    INT4: CDP_PROPERTY_TYPE.INT4,
    INT8: CDP_PROPERTY_TYPE.INT8,
    FLOAT4: CDP_PROPERTY_TYPE.FLOAT4,
    FLOAT8: CDP_PROPERTY_TYPE.FLOAT8,
    VARCHAR: CDP_PROPERTY_TYPE.VARCHAR,
};
export const CDP_PROPERTY_TYPES_INVERTED = invert(CDP_PROPERTY_TYPES);

type CDP_NULLABLE_TYPE = (CDP_PROPERTY_TYPE | 'null')[];

export const CDP_COMPATIBLE_TYPES: { [key in CDP_PROPERTY_TYPE]?: CDP_PROPERTY_TYPE[] } = {
    [CDP_PROPERTY_TYPE.STRING]: [CDP_PROPERTY_TYPE.STRING, CDP_PROPERTY_TYPE.VARCHAR],
};

export const CDP_PROPERTY_JSON_PATH_TYPES = without(values(CDP_PROPERTY_TYPE), CDP_PROPERTY_TYPE.MIXED);
export const CDP_PROPERTY_COMPLEX_TYPES = [CDP_PROPERTY_TYPE.OBJECT, CDP_PROPERTY_TYPE.ARRAY, CDP_PROPERTY_TYPE.MIXED];

function getPropertyRawSchema(prop: any) {
    const mandatoryPropFields = ['description', 'type', 'datasource'];
    const allPropFields = Object.keys(prop);
    return difference(allPropFields, mandatoryPropFields).reduce((acc: Record<string, any>, el: string) => {
        acc[el] = prop[el];
        return acc;
    }, {});
}
export function propTypeFromJson(
    type: CDP_PROPERTY_TYPE | CDP_PROPERTY_TYPE[] | undefined | CDP_NULLABLE_TYPE,
): CDP_PROPERTY_TYPE | CDP_NULLABLE_TYPE {
    if (!type) {
        return CDP_PROPERTY_TYPE.MIXED;
    }
    if (Array.isArray(type)) {
        if (eventPropTypeIsNullable(type)) {
            return type.filter(el => el !== 'null').toString() as CDP_PROPERTY_TYPE;
        }

        if (eventPropTypeIsMultiple(type as CDP_PROPERTY_TYPE[])) {
            return type;
        }
        return CDP_PROPERTY_TYPE.MIXED;
    }
    return type;
}
function eventPropTypeIsNullable(propType: CDP_PROPERTY_TYPE | undefined | CDP_NULLABLE_TYPE): boolean {
    return Array.isArray(propType) && propType.includes('null');
}
export function eventPropTypeIsMultiple(propType: CDP_PROPERTY_TYPE | CDP_PROPERTY_TYPE[]) {
    return (
        Array.isArray(propType) && CDP_PROPERTY_TYPES_INVERTED[propType[0]] && CDP_PROPERTY_TYPES_INVERTED[propType[1]]
    );
}

export function eventPropFromJson(
    jsonSchema: any,
    dataSources: EventDataSource[],
    LFRequiredPropsNames: string[] = [],
) {
    return map(jsonSchema.properties, (prop, name) => {
        return new EventProp({
            name,
            doc: prop.description,
            type: propTypeFromJson(prop.type) as CDP_PROPERTY_TYPE,
            mandatory: jsonSchema.required.includes(name),
            forbiddenToEdit: LFRequiredPropsNames.includes(name), // will be overwritten in the component
            dataSource: dataSources.find((ds: EventDataSource) => prop.datasource === ds.dataSourceName)?.label,
            isVisible: true,
            rawSchema: getPropertyRawSchema(prop),
            sourceType: propTypeFromJson(prop.type) as CDP_PROPERTY_TYPE,
            isNullable: eventPropTypeIsNullable(prop.type),
        });
    });
}

export function modifyPropertiesBeforeSend(properties: EventProp[], dataSources: EventDataSource[] = []) {
    const dts = fromPairs(dataSources.map(ds => [ds.label, ds.dataSourceName]));
    return chain(
        properties.map(prop => ({
            name: prop.name,
            description: prop.doc,
            ...(prop.type !== CDP_PROPERTY_TYPE.MIXED && { type: prop.isNullable ? [prop.type, 'null'] : prop.type }),
            datasource: dts[prop.dataSource as string],
            ...prop.rawSchema,
        })),
    )
        .keyBy(prop => prop.name)
        .mapValues(prop => omit(prop, 'name'))
        .value();
}
export function getMandatoryProps(properties: EventProp[]) {
    return properties.filter(prop => prop.mandatory).map(prop => prop.name);
}
export function mapPropertyRowsForOverview(property: EventProp): CollapsibleListItemRow[] {
    return compact([
        {
            name: i18n.t('generic.name'),
            value: property.name,
        },
        {
            name: i18n.t('generic.type'),
            value: property.type,
        },
        {
            name: i18n.t('generic.mandatory'),
            value: getBeautifulBoolean(property.mandatory),
        },
        {
            name: i18n.t('events.dataSource'),
            value: property.dataSource || i18n.t('generic.none'),
        },
        {
            name: i18n.t('events.nullable'),
            value: getBeautifulBoolean(property.isNullable || false),
        },
        property.doc.length > 0
            ? {
                  name: i18n.t('generic.description'),
                  value: property.doc,
                  maxHeight: 35,
              }
            : null,
    ]);
}
