var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListPageLayout',{attrs:{"pageTitle":_vm.pageTitle,"addButtonLabel":_vm.addButtonLabel,"editRouteName":_vm.editRouteName,"entityType":_vm.entityType,"entities":_vm.entities,"entitiesApiResponse":_vm.entitiesApiResponse,"entityKeyDepth":2,"listViewColumns":_vm.listViewColumnsFormatted,"excludeBaseColumns":_vm.excludeBaseColumns,"overviewContentBuilder":_vm.overviewContentBuilder,"multiselectEnabled":_vm.isMultiselectEnabled,"multiselectIdColumn":'id',"multiselectActions":_vm.bulkEditActions,"searchKeys":['id', 'name', 'description'],"userGuideArticleId":_vm.userGuideArticleId,"writePermission":_vm.writePermission},on:{"multiselectActionClick":_vm.onMultiselectActionClick},scopedSlots:_vm._u([{key:"statusIndicator",fn:function(ref){
var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.state}})]}},{key:"versionIndicator",fn:function(ref){
var entity = ref.entity;
return [_c('EntityStatusIndicator',{attrs:{"status":entity.entityVersion}})]}},{key:"operations",fn:function(ref){
var entity = ref.entity;
return [_vm._t("operations",null,{"entity":entity})]}},{key:"modals",fn:function(ref){
var selectedEntity = ref.selectedEntity;
return [_vm._t("modals",null,{"selectedEntity":selectedEntity,"entities":_vm.entities})]}},{key:"overviewButtons",fn:function(ref){
var selectedEntity = ref.selectedEntity;
return [_vm._t("overviewButtons",null,{"selectedEntity":selectedEntity})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }