






















// Generic
import Vue, { PropType } from 'vue';

// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// helpers
import RouteNames from '@/router/routeNames';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

// Mixins
import EndUserAuthorizationMixin from '@/__new__/features/customerCareSuite/components/EndUserAuthorizationMixin.vue';

export default Vue.extend({
    name: 'EndUserAuthorizationBanner',
    components: {
        AppIcon,
    },
    mixins: [EndUserAuthorizationMixin],
    props: {
        targetType: {
            type: Number as PropType<TARGET_TYPE>,
            required: true,
        },
        targetId: {
            type: String,
            required: true,
        },
    },
    methods: {
        onClick(): void {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetType: this.targetType,
                    targetId: this.targetId,
                },
            });
        },
    },
});
