var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.filteredSubscribers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var allFilters = ref.allFilters;
        var filteredEntities = ref.filteredEntities;
        var onFilterAdded = ref.onFilterAdded;
        var removeFilter = ref.removeFilter;
        var removeAllFilters = ref.removeAllFilters;
        var filteredEntitiesMixin = ref.filteredEntitiesMixin;
return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.subscriberTab.subscribers'))+" ")]),_c('div',{staticClass:"display-flex"},[_c('SearchBox',{staticClass:"table-search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.subscribersSearchQuery),callback:function ($$v) {_vm.subscribersSearchQuery=$$v},expression:"subscribersSearchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.subscriberInfosApiResponse,"title":_vm.$i18n.t('customerCare.subscriberTab.subscribers')}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.columnData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('div',[_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"innerSearchQuery":_vm.subscribersSearchQuery,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"isSearchEnabled":true,"newDesign":true,"columnsData":_vm.columnData,"canSelectColumns":true,"tableKey":"customer-care-new/account/subscribers"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
        var entity = ref.entity;
return [_c('SubscriberStatusIndicator',{attrs:{"status":entity.status}})]}},{key:"subscriberIdLink",fn:function(ref){
        var entity = ref.entity;
return [(entity.subscriberId)?_c('router-link',{staticClass:"table-link",attrs:{"to":_vm.subscriberIdRoute(entity.subscriberId)}},[_vm._v(" "+_vm._s(entity.subscriberId)+" ")]):_c('i',[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")])]}}],null,true)})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }