

























































import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import {
    SUBSCRIBER_PORT_IN_COLOR_MAP,
    SUBSCRIBER_PORT_IN_LABEL_MAP,
    SUBSCRIBER_PORT_IN_MAP,
    SUBSCRIBER_PORT_OUT_COLOR_MAP,
    SUBSCRIBER_PORT_OUT_LABEL_MAP,
    SUBSCRIBER_PORTING_ACTIONS,
    USER_MANAGER_HIERARCHY,
    TRANSACTION_STATUS_TO_LABEL,
    TRANSACTION_STATUS_TO_COLOR,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/vue';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { FLAG_MAP, FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import RouteNames from '@/router/routeNames';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import {
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
    CHANNEL,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';

// Models
import Porting from '@/__new__/services/dno/user/models/Porting';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';

// eslint-disable-next-line no-shadow
enum PORTING_TYPES {
    PORT_IN = 'portIn',
    PORT_OUT = 'portOut',
    TRANSACTION_HISTORY = 'transactionHistory',
    TRANSACTION_VERIFICATION_HISTORY = 'transactionVerificationHistory',
}

export default Vue.extend({
    name: 'PortingTableTile',
    components: {
        AbstractTableTile,
        AppLabel,
        AppButton,
    },
    data() {
        return {
            displayTable: true,
            portInData: {},
            portInStatusApiResponse: {},
            portOutData: {},
            portOutStatusApiResponse: {},
            transactionHistory: [],
            transactionHistoryApiResponse: {},
            transactionVerificationHistory: [],
            transactionVerificationHistoryApiResponse: {},
            activeTab: PORTING_TYPES.PORT_IN,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.$route.params.id,
            });
        },
        isFraud() {
            return this.subscriberData?.flags?.[FLAG_TYPE.IS_FRAUD]?.value;
        },
        tabs() {
            const tabs = [
                {
                    id: PORTING_TYPES.PORT_IN,
                    title: this.$i18n.t('customerCare.userInformation.portIn'),
                },
                {
                    id: PORTING_TYPES.PORT_OUT,
                    title: this.$i18n.t('customerCare.userInformation.portOut'),
                },
            ];
            if (this.isTransactionHistoryAvailable) {
                tabs.push({
                    id: PORTING_TYPES.TRANSACTION_HISTORY,
                    title: this.$i18n.t('customerCare.userInformation.transactionHistory'),
                });
                tabs.push({
                    id: PORTING_TYPES.TRANSACTION_VERIFICATION_HISTORY,
                    title: this.$i18n.t('customerCare.userInformation.transactionVerificationHistory'),
                });
            }
            return tabs;
        },
        isIssueTemporaryMNPPINAvailable() {
            return (
                !(this.subscriberData?.flags?.[FLAG_TYPE.PORT_OUT_INITIATED]?.originalValue === FLAG_MAP.TRUE) &&
                isUserAllowed('UMSubscriberEditSubscriberGenerateMNPPIN')
            );
        },
        tableKey() {
            return `porting-table-${this.activeTab}`;
        },
        isPortInAvailable() {
            return isUserAllowed('UMSubscriberPortingTablePortIn');
        },
        isTransactionHistoryAvailable() {
            return isUserAllowed('UMSubscriberPortingTablePortingHistory');
        },
        isReSubmitPortInAvailable() {
            return this.portInData?.status === SUBSCRIBER_PORT_IN_MAP.MANUALLY_CANCELLED;
        },
        isCancelPortInAvailable() {
            return (
                this.portInData?.status &&
                (this.portInData.status === SUBSCRIBER_PORT_IN_MAP.APPLICATION_APPROVED ||
                    this.portInData.status === SUBSCRIBER_PORT_IN_MAP.RESOLUTION_REQUIRED)
            );
        },
        isPortIn() {
            return this.activeTab === PORTING_TYPES.PORT_IN;
        },
        isPortOut() {
            return this.activeTab === PORTING_TYPES.PORT_OUT;
        },
        isTransactionHistory() {
            return this.activeTab === PORTING_TYPES.TRANSACTION_HISTORY;
        },
        isTransactionVerificationHistory() {
            return this.activeTab === PORTING_TYPES.TRANSACTION_VERIFICATION_HISTORY;
        },
        entities() {
            if (this.isPortIn && !isEmpty(this.portInData)) {
                return [this.portInData];
            }
            if (this.isPortOut && !isEmpty(this.portOutData)) {
                return [this.portOutData];
            }
            if (this.isTransactionHistory && !isEmpty(this.transactionHistory)) {
                return this.transactionHistory;
            }
            if (this.isTransactionVerificationHistory && !isEmpty(this.transactionVerificationHistory)) {
                return this.transactionVerificationHistory;
            }
            return [];
        },
        columnsData() {
            if (this.isPortIn) {
                return this.columnsDataPortIn;
            }
            if (this.isPortOut) {
                return this.columnsDataPortOut;
            }
            if (this.isTransactionHistory) {
                return this.columnsDataHistory;
            }
            if (this.isTransactionVerificationHistory) {
                return this.columnsDataVerificationHistory;
            }
            return [];
        },
        columnsDataPortIn() {
            return [
                {
                    key: 'msisdn',
                    name: this.$i18n.t('customerCare.msisdn'),
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'requestDateFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.requestDate),
                    sortBy: entity => entity.requestDate,
                    field: 'requestDate',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'accountId',
                    name: this.$i18n.t('customerCare.accountId'),
                },
                {
                    key: 'portInRequestId',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.portInRequestId'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'resultDescription',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.resultDescription'),
                },
            ];
        },
        columnsDataPortOut() {
            return [
                {
                    key: 'msisdn',
                    name: this.$i18n.t('customerCare.msisdn'),
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'requestDateFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.requestDate),
                    sortBy: entity => entity.requestDate,
                    field: 'requestDate',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'accountId',
                    name: this.$i18n.t('customerCare.accountId'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'validationResultDescription',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.validationResultDescription'),
                },
            ];
        },
        columnsDataHistory() {
            return [
                {
                    name: this.$i18n.t('generic.startTime'),
                    key: 'startTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    key: 'endTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'targetEntity',
                    name: this.$i18n.t('generic.targetEntity'),
                },
            ];
        },
        columnsDataVerificationHistory() {
            return [
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                },
                {
                    key: 'method',
                    name: this.$i18n.t('generic.method'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'id',
                    name: this.$i18n.t('generic.id'),
                },
                {
                    key: 'userId',
                    name: this.$i18n.t('customerCare.userId'),
                },
                {
                    key: 'portalUserId',
                    name: this.$i18n.t('generic.portalUserId'),
                },
            ];
        },
        apiResponse() {
            if (this.isPortIn) {
                return this.portInStatusApiResponse;
            }
            if (this.isPortOut) {
                return this.portOutStatusApiResponse;
            }
            if (this.isTransactionHistory) {
                return this.transactionHistoryApiResponse;
            }
            if (this.isTransactionVerificationHistory) {
                return this.transactionVerificationHistoryApiResponse;
            }
            return {};
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const dataRequests = [
                        this.getPortInStatus(this.$route.params.id),
                        this.getPortOutStatus(this.$route.params.id),
                    ];
                    if (this.isTransactionHistoryAvailable) {
                        dataRequests.push(this.getTransactionsHistory(this.$route.params.id));
                        dataRequests.push(this.getTransactionVerificationHistory());
                    }
                    await Promise.all(dataRequests);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        async getPortInStatus(id) {
            try {
                const response = await userManagementHTTP.getSubscriberPortingRequest(
                    id,
                    SUBSCRIBER_PORTING_ACTIONS.PORT_IN,
                );
                this.portInStatusApiResponse = response;

                if (!isEmpty(response.data.porting_request)) {
                    this.portInData = new Porting(
                        Porting.remapPortingFromBe(response.data.porting_request, SUBSCRIBER_PORTING_ACTIONS.PORT_IN),
                    );
                }
            } catch (error) {
                this.portInStatusApiResponse = error.response;
                this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'));
                Sentry.captureException(error);
            }
        },
        async getPortOutStatus(id) {
            try {
                const response = await userManagementHTTP.getSubscriberPortingRequest(
                    id,
                    SUBSCRIBER_PORTING_ACTIONS.PORT_OUT,
                );
                this.portOutStatusApiResponse = response;

                if (!isEmpty(response.data.porting_request)) {
                    this.portOutData = new Porting(
                        Porting.remapPortingFromBe(response.data.porting_request, SUBSCRIBER_PORTING_ACTIONS.PORT_OUT),
                    );
                }
            } catch (error) {
                this.portOutStatusApiResponse = error.response;
                this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingPortingData'));
                Sentry.captureException(error);
            }
        },
        async getTransactionsHistory(id) {
            try {
                const response = await customerCareHttp.getSensitiveTransactionHistory(
                    id,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    SENSITIVE_TRANSACTIOS_USE_CASE.GENERATE_MNP_PIN,
                );
                this.transactionHistoryApiResponse = response;
                const transactions = !isEmpty(response?.data?.transactions) ? response.data.transactions : [];
                this.transactionHistory = transactions.map(el => {
                    return {
                        startTime: el.start_time,
                        endTime: el.end_time,
                        channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(el.channel),
                        status: el.status,
                        targetEntity: el.target_id,
                    };
                });
            } catch (error) {
                this.transactionHistoryApiResponse = error?.response;
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        async getTransactionVerificationHistory() {
            try {
                const response = await customerCareHttp.fetchServiceBlockerHistory(
                    this.subscriberData?.userId,
                    USER_MANAGER_HIERARCHY.USER,
                    SERVICE_TYPE.END_USER_AUTHORIZATION,
                );
                this.transactionVerificationHistoryApiResponse = response;
                const otpAttempts = !isEmpty(response?.data?.otp_attempts) ? response.data.otp_attempts : [];
                this.transactionVerificationHistory = otpAttempts
                    .filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.GENERATE_MNP_PIN)
                    .map(el => {
                        return {
                            time: el.event_time,
                            channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                                el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                            ),
                            method: el?.properties?.method || '',
                            status: el.event_type,
                            id: el?.properties?.external_id || '',
                            userId: el?.identifier || '',
                            portalUserId: el?.properties?.portal_id || '',
                        };
                    });
            } catch (error) {
                this.transactionVerificationHistoryApiResponse = error?.response;
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        resubmitPortInRequest() {
            this.$withProgressBar(
                async () => {
                    const response = await userManagementHTTP.getSubscriberPortingRequest(
                        this.$route.params.id,
                        SUBSCRIBER_PORTING_ACTIONS.PORT_IN,
                    );

                    const portingData = response?.data?.porting_request;

                    if (portingData) {
                        userManagementHTTP.initiatePortIn(this.$route.params.id, {
                            port_in_external_id: portingData?.msisdn,
                            port_in_subscription_type: portingData?.subscription_type,
                            customer_data: {
                                account_password: portingData?.execution_data?.account_password,
                                account_number: portingData?.execution_data?.account_number,
                                name: portingData?.execution_data?.name,
                                billing_address: {
                                    street1: portingData?.execution_data?.billing_address?.street1,
                                    street2: portingData?.execution_data?.billing_address?.street2,
                                    city: portingData?.execution_data?.billing_address?.city,
                                    state: portingData?.execution_data?.billing_address?.state,
                                    zip: portingData?.execution_data?.billing_address?.zip,
                                },
                            },
                        });
                    } else {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        cancelPortInRequest() {
            this.$withProgressBar(
                async () => {
                    const portingRequestId = this.portInData?.portInRequestId;
                    if (portingRequestId) {
                        await userManagementHTTP.cancelPortIn(this.$route.params.id, portingRequestId);
                        this.$alert(
                            this.$i18n.t('customerCare.subscriberTab.cancelPortInRequestSuccess', {
                                id: portingRequestId,
                            }),
                            {
                                type: ALERT_TYPES.success,
                            },
                        );
                    } else {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.cancelPortInRequestFailed'));
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.cancelPortInRequestFailed'));
                    },
                },
            );
        },
        getStatusMapping(entity) {
            if (this.isPortIn) {
                return this.portInStatus();
            }
            if (this.isPortOut) {
                return this.portOutStatus();
            }
            if (this.isTransactionHistory || this.isTransactionVerificationHistory) {
                return this.transactionHistoryStatus(entity);
            }
            return {};
        },
        portInStatus() {
            return {
                title: this.$i18n.t(`generic.stateMap.${SUBSCRIBER_PORT_IN_LABEL_MAP[this.portInData.status]}`),
                color: SUBSCRIBER_PORT_IN_COLOR_MAP.get(this.portInData.status),
            };
        },
        portOutStatus() {
            return {
                title: this.$i18n.t(`generic.stateMap.${SUBSCRIBER_PORT_OUT_LABEL_MAP[this.portOutData.status]}`),
                color: SUBSCRIBER_PORT_OUT_COLOR_MAP.get(this.portOutData.status),
            };
        },
        transactionHistoryStatus(entity) {
            return {
                title: TRANSACTION_STATUS_TO_LABEL.get(entity.status),
                color: TRANSACTION_STATUS_TO_COLOR.get(entity.status),
            };
        },
        onTabSelected(id) {
            this.activeTab = id;
            this.forceRerender();
        },
        async forceRerender() {
            // Seems like table sets columns only on created or so
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
        issueTemporaryMNPPIN() {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.subscriberData?.accountId || '',
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    operationType: SENSITIVE_OPERATION_TYPES.ISSUE_TEMPORARY_MNP_PIN,
                    operationTargetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    operationTargetId: this.$route.params.id,
                },
            });
        },
    },
});
