







































import Vue, { PropType } from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AdditionalSidebarForSubscriptions from '@/__new__/features/customerCareSuite/components/AdditionalSidebarForSubscriptions.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// helpers
import {
    SUBSCRIPTION_STATUS_INDICATOR_MAP,
    SUBSCRIPTION_STATES_TO_STATUS_NAME_MAP,
    SUBSCRIPTION_STATE_DECORATORS_INDICATOR_MAP,
    SUBSCRIPTION_STATE_DECORATORS_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/subscriptionStateHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';

// Http
import subscriptionsHTTP from '@/__new__/services/dno/subscriptions/http/subscriptions';
import offerHTTP from '@/__new__/services/dno/pc/http/offer';
import Subscription from '@/__new__/services/dno/subscriptions/models/Subscription';

export default Vue.extend({
    name: 'SubscriptionsTile',
    components: {
        AbstractTableTile,
        AdditionalSidebarForSubscriptions,
        EntityStatusIndicator,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            entities: [],
            apiResponse: {},
            showSidebar: false,
            selectedEntityId: '' as string,

            // proxy
            RouteNames,
            SUBSCRIPTION_STATES_TO_STATUS_NAME_MAP,
            SUBSCRIPTION_STATUS_INDICATOR_MAP,
            SUBSCRIPTION_STATE_DECORATORS_INDICATOR_MAP,
            SUBSCRIPTION_STATE_DECORATORS_TO_STATUS_NAME_MAP,
        };
    },
    computed: {
        // Table related properties
        selectedEntity() {
            return this.selectedEntityId
                ? this.entities.find((subscription: Subscription) => subscription.id === this.selectedEntityId)
                : {};
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.subscriptionId'),
                    key: 'id',
                    forbidHideColumn: true,
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerId'),
                    key: 'offerId',
                    field: 'offerId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.serviceTransactionsTile.offerName'),
                    key: 'offerName',
                    field: 'offerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map((entity: Subscription) => entity.state))),
                },
                {
                    name: this.$i18n.t('customerCareSuite.serviceTransactionsTile.stateDecorator'),
                    key: 'stateDecorator',
                    field: 'stateDecorator',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.entities.map((entity: Subscription) => entity.stateDecorator)),
                    ),
                },
                {
                    name: this.$i18n.t('generic.recurring'),
                    key: 'recurring',
                    field: 'recurring',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map((entity: Subscription) => entity.recurring))),
                },
            ];
        },
    },
    async mounted() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await subscriptionsHTTP.getSubscriptions(
                        this.$route.params.id,
                        this.userManagerHierarchy,
                    );

                    this.apiResponse = response;

                    if (response?.data?.subscriptions) {
                        this.entities = Object.values(response.data.subscriptions).map(el => {
                            return new Subscription(Subscription.mapSubscriptionsFromBE(el));
                        });

                        const offerIds = this.entities.map(({ offerId }) => offerId);
                        const { data } = await offerHTTP.getOffers(offerIds);
                        const offers = data?.offer_by_id || [];

                        this.entities = this.entities.map(el => ({
                            ...el,
                            offerName: offers?.[el?.offerId]?.data.name || '',
                            recurring: offers?.[el?.offerId]?.data.subscription_type || '',
                        }));
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            ALERT_TYPES.error,
                        );
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        onShowSidebar(id: string) {
            this.selectedEntityId = id;
            this.showSidebar = true;
        },
        onCloseSidebar() {
            this.selectedEntityId = '';
            this.showSidebar = false;
        },
    },
});
