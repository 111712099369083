






















import Vue from 'vue';

// Components
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// http
import customerCareAJAX from '@/__new__/services/dno/user/http/customer-care';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';
import * as Sentry from '@sentry/vue';
import Button from '@/common/button/Button';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';

export default Vue.extend({
    name: 'SimSwapModalMVNE',
    components: {
        CustomTextarea,
    },
    mixins: [validationMixin],
    props: {
        targetId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            notesTxt: '',
            profileData: {},
            isESim: false,
            BUTTON_TYPES,
        };
    },
    validations() {
        return {
            notesTxt: {
                required,
            },
        };
    },
    async created() {
        await this.$withProgressBar(
            async () => {
                const profileInfo = await customerCareAJAX.getProfileInfo({
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    targetId: this.targetId,
                });
                this.profileData = profileInfo.data?.profile_data;
                this.isESim = this.profileData?.sim_type === 10;
                if (!this.isESim) {
                    this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwapDisabledForNonESim'), {
                        type: ALERT_TYPES.error,
                    });
                }
            },
            {
                errorHandler: error => {
                    Sentry.captureException(error);
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        onSave() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                this.$alert(this.$i18n.t('customerCare.subscriberTab.areYouSureYouWantToSimSwap'), {
                    type: ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$i18n.t('generic.confirm'),
                            alertType: ALERT_TYPES.warning,
                            handler: () => {
                                this.swapSim();
                                this.setAgentNote();
                            },
                        }),
                    ],
                });
            }
        },
        async swapSim() {
            await this.$withProgressBar(
                async () => {
                    // Swap can be performed only from eSIM to eSIM, sim_type - 10
                    await customerCareAJAX.swapSIM({
                        target_id: this.targetId,
                        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        sim_type_old: this.profileData?.sim_type,
                        iccid_old: this.profileData?.iccid,
                        sim_type_new: this.profileData?.sim_type,
                    });
                    this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwapRequestSuccessful'), {
                        type: ALERT_TYPES.success,
                    });
                    this.$emit('isSensitiveOperationCompleted', true);
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        targetId: this.targetId,
                    });
                },
                {
                    errorHandler: error => {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwapRequestFail'), {
                            type: ALERT_TYPES.error,
                        });
                        Sentry.captureException(error);
                    },
                },
            );
        },
        async setAgentNote() {
            await this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.targetId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.simSwap')],
                    }),
                {
                    errorHandler: () =>
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'), {
                            type: ALERT_TYPES.error,
                        }),
                },
            );
        },
    },
});
