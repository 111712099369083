






import Vue from 'vue';

// Vuex
// import { mapGetters } from 'vuex';
// import { Modules } from '@/store/store';
// import { Getters } from '@/store/mutation-types';

// Components
import AppPostpaidHeader from '@/components/partials/AppPostpaidHeader.vue';

// Helpers
import permissionsService, {
    getUserConsentsDefault,
    getUserDetailFields,
} from '@/services/permissions/permissions.service';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import { BILLING_ACCOUNT_INVOICE_TEMPLATES } from '@/__new__/features/customerCareSuite/common/billingAccountHelper';

// HTTP

import { getBillingAccountById } from '@/__new__/services/portal/postpaid/billingAccount/billingAccount';
import { getDunningProfiles } from '@/__new__/services/dno/billing/dunningProfilesService';
import { getCycleSpecifications as getBillingCycleConfigs } from '@/__new__/services/dno/billing/cycleSpecificationService';

export default Vue.extend({
    name: 'BillingAccountPageAnchor',

    components: {
        AppPostpaidHeader,
    },

    data() {
        return {
            billingAccount: {} as any,
            userDetailFields: getUserDetailFields(),
            consentsDefault: getUserConsentsDefault(),
            consents: [] as Array<any>,
            invoiceTemplates: BILLING_ACCOUNT_INVOICE_TEMPLATES,
            dunningProfileName: '',
            billingCycleConfigName: '',
            // permissions related stuff
            isEkycEnabled: permissionsService.userManagementEKYCEnabled(),
            consentsEnabled: permissionsService.userManagementConsentsEnabled(),
        };
    },
    computed: {
        viewedEntityId() {
            return this.$route.params.id || '';
        },
        summaryInfo() {
            const data = [];

            data.push({
                name: this.$i18n.t('billingAccount.id'),
                value: this.billingAccount.id,
            });

            data.push({
                name: this.$i18n.t('billingAccount.name'),
                value: this.billingAccount.name,
            });

            data.push({
                name: this.$i18n.t('billingAccount.accountType'),
                value: 'Post Paid',
            });

            data.push({
                name: this.$i18n.t('billingAccount.type'),
                value: 'Individual',
            });

            data.push({
                name: this.$i18n.t('billingAccount.lastModified'),
                value: '2024-01-01',
            });

            data.push({
                name: this.$i18n.t('billingAccount.contact'),
                value: this.billingAccount.contact,
            });

            return data;
        },
        financialInfo() {
            const data = [];

            // data.push({
            //     name: this.$i18n.t('billingAccount.billingAccount'),
            //     value: 'Account 123',
            // });

            data.push({
                name: this.$i18n.t('billingAccount.paymentStatus'),
                value: 'Paid',
            });
            data.push({
                name: this.$i18n.t('billingAccount.creditLimit'),
                value: formatAmountBasedOnCurrency(this.billingAccount.creditLimit),
            });

            data.push({
                name: this.$i18n.t('billingAccount.financialAccount'),
                value: 'AR-123',
            });
            data.push({
                name: this.$i18n.t('billingAccount.defaultPaymentMethod'),
                value: 'Direct Debit',
            });
            data.push({
                name: this.$i18n.t('billingAccount.taxExemption'),
                value: this.billingAccount.taxExemption,
            });
            data.push({
                name: this.$i18n.t('billingAccount.outstandingBalance'),
                value: formatAmountBasedOnCurrency(this.billingAccount.outstandingBalance),
            });
            data.push({
                name: this.$i18n.t('billingAccount.uninvoicedBalance'),
                value: formatAmountBasedOnCurrency(this.billingAccount.uninvoicedBalance),
            });

            return data;
        },
        billInfo() {
            const data = [];

            data.push({
                name: this.$i18n.t('billingAccount.billTemplate'),
                value: this.invoiceTemplates.find(item => item.value === this.billingAccount.invoiceTemplateId)?.label,
            });

            data.push({
                name: this.$i18n.t('billingAccount.billingCycle'),
                value: this.billingCycleConfigName,
            });
            data.push({
                name: this.$i18n.t('billingAccount.dunningProfile'),
                value: this.dunningProfileName,
            });
            data.push({
                name: this.$i18n.t('billingAccount.nextBillingDate'),
                value: '2024-01-01',
            });

            return data;
        },
        infoData() {
            const result = [
                {
                    title: this.$i18n.t('billingAccount.summary'),
                    value: this.summaryInfo,
                    initiallyExpanded: true,
                    additionalLabels: [],
                },
            ];

            result.push({
                title: this.$i18n.t('billingAccount.financialInformation'),
                value: this.financialInfo,
                initiallyExpanded: true,
                additionalLabels: [],
            });

            result.push({
                title: this.$i18n.t('billingAccount.billDetails'),
                value: this.billInfo,
                initiallyExpanded: true,
                additionalLabels: [],
            });
            return result;
        },
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.$withProgressBar(
                async () => {
                    const [billingAccountResponse, dunningProfilesData, billingCycleConfigsData] = await Promise.all([
                        getBillingAccountById(this.viewedEntityId),
                        getDunningProfiles(),
                        getBillingCycleConfigs(),
                    ]);

                    this.billingAccount = billingAccountResponse.data;
                    this.dunningProfileName =
                        dunningProfilesData.entities.find(
                            profile => profile.id === this.billingAccount.dunningProfileId,
                        )?.name ?? '';

                    this.billingCycleConfigName =
                        billingCycleConfigsData.entities.find(
                            config => config.id === this.billingAccount.billingCycleId,
                        )?.name ?? '';
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
    },
});
